// system.module.js - Module declaration for Admin System area

'use strict';

angular
  .module('CfcCharity.admin.system', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])

  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    config
  ]);

function config(
  $stateProvider,
  $urlRouterProvider,
  HateoasInterceptorProvider,
  USER_ROLES,
  SYSTEM_USER_SECONDARY_ROLES
) {
  $stateProvider.state('emailTemplates', {
    url: '/email-templates/:emailTemplateId',
    views: {
      main: {
        template: require('./email-templates/email-templates.html'),
        controller: 'EmailTemplatesCtrl',
        controllerAs: 'vm'
      }
    },
    data: {
      pageTitle: 'Email Templates',
      requireAuthentication: true,
      authorizedRoles: [USER_ROLES.system_user],
      authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin]
    },
    param: {
      emailTemplateId: null
    }
  }),
    $stateProvider.state('emailTemplate', {
      url: '/email-template/:emailTemplateId',
      views: {
        main: {
          template: require('./email-templates/email-template.html'),
          controller: 'EmailTemplateCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        pageTitle: 'Edit Email Templates',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin]
      },
      param: {
        emailTemplateId: null
      }
    }),
    $stateProvider.state('motd', {
      url: '/motd',
      views: {
        main: {
          template: require('./motd/motd.html'),
          controller: 'motdCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        pageTitle: 'Message of the Day',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [
          SYSTEM_USER_SECONDARY_ROLES.opm_admin,
          SYSTEM_USER_SECONDARY_ROLES.opm_staff
        ]
      },
      param: {}
    }),
    $stateProvider.state('cms-list', {
      url: '/cms-list/:contentId',
      views: {
        main: {
          template: require('./cms/cms-list.html'),
          controller: 'CmsListCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        pageTitle: 'CMS List',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin]
      },
      param: { contentId: null }
    }),
    $stateProvider.state('cms-detail', {
      url: '/cms-detail/:contentId',
      views: {
        main: {
          template: require('./cms/cms-detail.html'),
          controller: 'CmsDetailCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        pageTitle: 'CMS Detail',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin]
      },
      param: {
        param: { contentId: null }
      }
    }),
    $stateProvider.state('irs-master-file-search', {
      url: '/irs-master-file-search',
      views: {
        main: {
          template: require('./irs-master-file/irs-master-file-search.html'),
          controller: 'IrsMasterFileSearchCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        pageTitle: 'IRS Master File Search',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [
          SYSTEM_USER_SECONDARY_ROLES.opm_admin,
          SYSTEM_USER_SECONDARY_ROLES.opm_staff,
          SYSTEM_USER_SECONDARY_ROLES.opm_auditor
        ]
      }
    }),
    $stateProvider.state('admin-upload-region-files', {
      url: '/admin-upload-region-files',
      views: {
        main: {
          template: require('./upload/upload-region-files.html'),
          controller: 'UploadRegionFilesCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        pageTitle: 'Upload Region Fiels',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [
          SYSTEM_USER_SECONDARY_ROLES.opm_admin,
          SYSTEM_USER_SECONDARY_ROLES.opm_staff,
          SYSTEM_USER_SECONDARY_ROLES.opm_auditor
        ]
      }
    });
}
