// redirect.service.js - Common $state Redirect Service for the application

'use strict';

angular.module('CfcCharity.common').factory('RedirectService', RedirectService);

RedirectService.$inject = ['BrowserEventService', '$state'];

function RedirectService(BrowserEventService, $state) {
  var service = {
    goToCharityListing: goToCharityListing,
    goToFederationListing: goToFederationListing
  };
  return service;

  function goToCharityListing(pledgeYear, cfcCode, applicationType) {
    if (pledgeYear && pledgeYear != null && pledgeYear !== '') {
      BrowserEventService.setPledgeYear(pledgeYear);
      BrowserEventService.setAppType(applicationType);
      $state.go('charitylisting', {
        charityDashboardCfcCode: cfcCode,
        pledgeYear: pledgeYear,
        appType: applicationType
      });
    }
  }

  function goToFederationListing(pledgeYear, federationCfcCode) {
    if (pledgeYear && pledgeYear != null && pledgeYear !== '') {
      BrowserEventService.setPledgeYear(pledgeYear);
      $state.go('federationlisting', {
        charityDashboardCfcCode: federationCfcCode,
        pledgeYear: pledgeYear
      });
    }
  }
}
