// analytics.service.js - common analytics Service calls for the application

'use strict';

angular
  .module('CfcCharity.common.analytics', [])
  .factory('AnalyticsService', AnalyticsService);

AnalyticsService.$inject = ['$http', 'BrowserEventService'];

function AnalyticsService($http, BrowserEventService) {
  return {
    addUserPageActivity: addUserPageActivity,
    sendAnalyticsPageLoadEvents: sendAnalyticsPageLoadEvents
  };

  function addUserPageActivity(
    nextState,
    previousState,
    $http,
    BrowserEventService,
    toParams
  ) {
    var userTrack = null;

    if (nextState.name !== previousState.name) {
      angular.forEach(userPageActivityTrackingPages, function(obj) {
        if (nextState.name.toLowerCase() === obj.stateName.toLowerCase()) {
          userTrack = obj;
        }
      });
      var type = null;
      if (BrowserEventService != null) {
        type = BrowserEventService.getAppType();
      }
      if (type === undefined) {
        type = toParams.appType;
      }

      if (userTrack !== null) {
        var desc = userTrack.description;
        if (
          type &&
          (nextState.name === 'charitylisting' ||
            nextState.name === 'applicationDashboard')
        ) {
          type = convertUpperCaseToCapitalize(type);
          desc = type + ' ' + userTrack.description;
        }
        var url = '/CfcCharity/api/private/admin/users/adduserpageactivity';
        $http.post(url, {
          stateName: nextState.name,
          description: desc,
          relativePath: '/app/index.html#' + userTrack.url
        });
      }
    }
  }

  // CCA-1416
  function sendAnalyticsPageLoadEvents(nextState) {
    // conditionally checks for function(s), as some browsers auto block Google Analytics, like Firefox
    if (typeof gas !== 'undefined') {
      // send page view event to DAP
      gas('send', 'pageview', '#' + nextState.url, nextState.data.pageTitle);
    }
    if (typeof gtag !== 'undefined') {
      // update the config of page info for Google Tag Manager/Analytics (TASC)
      gtag('config', 'UA-111945916-1', {
        page_title: nextState.data.pageTitle,
        page_location: document.location.origin + document.location.pathname,
        page_path: '#' + nextState.url,
        send_page_view: false
      });
      // send page view event to Google Tag Manager/Analytics (TASC)
      gtag('event', 'page_view');
    }
  }

  var userPageActivityTrackingPages = [
    //Admin pages
    {
      stateName: 'administrationcenter',
      description: 'Admin Home',
      url: '/administrationcenter'
    },
    {
      stateName: 'zonecenter',
      description: 'Zone Dashboard',
      url: '/zonecenter'
    },
    {
      stateName: 'zoneApplications',
      description: 'Zone Applications',
      url: '/zoneApplications'
    },
    {
      stateName: 'activezoneUsers',
      description: 'Manage Users',
      url: '/activezoneUsers'
    },
    {
      stateName: 'addzoneUser',
      description: 'Add User To Zone',
      url: '/addzoneUser'
    },
    {
      stateName: 'editzoneUser',
      description: 'Edit Zone User',
      url: '/editzoneUser'
    },
    {
      stateName: 'deletezoneuserroleconfirmation',
      description: 'Delete Zone User',
      url: '/deletezoneuserroleconfirmation'
    },
    {
      stateName: 'admin-zone-listing-overview',
      description: 'Admin Zone Listing Overview',
      url: '/admin-zone-listing-overview'
    },
    {
      stateName: 'admin-zone-listing-printorder',
      description: 'Admin Zone Listing Print Order',
      url: '/admin-zone-listing-printorder'
    },
    {
      stateName: 'admin-zone-listing-printorder-history',
      description: 'Admin Zone Listing Print Order History',
      url: '/admin-zone-listing-printorder-history'
    },
    {
      stateName: 'singleZoneAppsByYear',
      description: 'Single Zone Apps By User',
      url: '/singleZoneAppsByYear'
    },
    {
      stateName: 'systemmanagement',
      description: 'System Management',
      url: '/systemmanagement'
    },
    {
      stateName: 'appSummaryByZone',
      description: 'Applications Summary By Zone',
      url: '/appSummaryByZone'
    },

    //System Management Pages
    {
      stateName: 'addcampaign',
      description: 'Add Campaign',
      url: '/addcampaign'
    },
    {
      stateName: 'editcampaign',
      description: 'Edit Campaign',
      url: '/editcampaign'
    },
    {
      stateName: 'irsmasterfilehist',
      description: 'Irs Master File History',
      url: '/irsmasterfilehist'
    },
    {
      stateName: 'activesystemusers',
      description: 'System Users',
      url: '/activesystemusers'
    },
    {
      stateName: 'addsystemuser',
      description: 'Add System User',
      url: '/addsystemuser'
    },
    {
      stateName: 'editsystemuser',
      description: 'Edit System User',
      url: '/editsystemuser'
    },
    {
      stateName: 'deletesystemuserroleconfirmation',
      description: 'Delete System User',
      url: '/deletesystemuserroleconfirmation'
    },
    {
      stateName: 'zoneAdmin',
      description: 'Zone Admin',
      url: '/zoneAdmin'
    },
    {
      stateName: 'addZone',
      description: 'Add Zone',
      url: '/addZone'
    },
    {
      stateName: 'editZone',
      description: 'Edit Zone',
      url: '/editZone'
    },

    //Dashboard Pages
    {
      stateName: 'userDashboard',
      description: 'User DashBoard',
      url: '/userDashboard'
    },
    {
      stateName: 'charityDashboard',
      description: 'Charity DashBoard',
      url: '/charityDashboard'
    },
    {
      stateName: 'applicationDashboard',
      description: 'Application DashBoard',
      url: '/applicationDashboard'
    },
    {
      stateName: 'charitylisting',
      description: 'Charity Listing',
      url: '/charitylisting'
    },
    {
      stateName: 'requestAccessToCharity',
      description: 'Request Access To Charity',
      url: '/requestAccessToCharity'
    },
    {
      stateName: 'findCharityWithCFC',
      description: 'Charity or Federation Lookup',
      url: '/findCharityWithCFC'
    },
    {
      stateName: 'userApplications',
      description: 'Applications',
      url: '/userApplications'
    },
    {
      stateName: 'accessInvitations',
      description: 'Charity Access Invitations and Requests',
      url: '/accessInvitations'
    },
    {
      stateName: 'editCharityAddress',
      description: 'Edit Charity Address',
      url: '/charityDashboard'
    },
    {
      stateName: 'editCharityBankDetails',
      description: 'Edit Charity Bank Details',
      url: '/charityDashboard'
    },
    {
      stateName: 'editCharityContactDetails',
      description: 'Edit Charity Contact Details',
      url: '/charityDashboard'
    },
    {
      stateName: 'federationapplication',
      description: 'Federation Application Package',
      url: '/federationapplication'
    },
    {
      stateName: 'memberapplication',
      description: 'Member Charities Applications',
      url: '/memberapplication'
    },

    //Federation DashBoard pages
    {
      stateName: 'federationDashboard',
      description: 'Federation Application Dashboard',
      url: '/federationDashboard'
    },
    {
      stateName: 'federationapplicationfee',
      description: 'Federation Application Package Payment',
      url: '/federationapplicationfee'
    },
    {
      stateName: 'disbandfederationapplicationconfirmation',
      description: 'Disband Application',
      url: '/federationapplicationfee'
    },
    {
      stateName: 'withdrawfederationapplicationconfirmation',
      description: 'Withdraw Application',
      url: '/withdrawfederationapplicationconfirmation'
    },
    {
      stateName: 'federationReview',
      description: 'Edit and Review',
      url: '/federationReview'
    },
    {
      stateName: 'signAndUnsignFederation',
      description: 'Electronic Signature',
      url: '/signAndUnsignFederation'
    },
    {
      stateName: 'federationlisting',
      description: 'Federation Listing Package',
      url: '/federationlisting'
    },
    {
      stateName: 'federationmemberlistings',
      description: 'Member Charity Listings',
      url: '/federationmemberlistings'
    },
    {
      stateName: 'federationlistingfee',
      description: 'Federation Listing Package Payment',
      url: '/federationlistingfee'
    },
    {
      stateName: 'federationappachpaymentcharityuser',
      description: 'Federation Package Payment',
      url: '/federationappachpaymentcharityuser'
    },
    {
      stateName: 'federationStep1',
      description: 'Step 1 Federation Contact Information',
      url: '/federationStep1'
    },
    {
      stateName: 'federationStep2',
      description: 'Step 2 Application Type',
      url: '/federationStep2'
    },
    {
      stateName: 'federationStep3',
      description: 'Step 3 Attachment A',
      url: '/federationStep3'
    },
    {
      stateName: 'federationStep4',
      description: 'Step 4 IRS Determination Letter',
      url: '/federationStep4'
    },
    {
      stateName: 'federationStep5',
      description: 'Step 5 Human Health & Welfare Services',
      url: '/federationStep5'
    },
    {
      stateName: 'federationStep6',
      description: 'Step 6 GAAP',
      url: '/federationStep6'
    },
    {
      stateName: 'federationStep7',
      description: 'Step 7 IRS Form 990 or Pro Forma Form 990',
      url: '/federationStep7'
    },
    {
      stateName: 'federationStep8',
      description: 'Step 8 Fundraising Rate',
      url: '/federationStep8'
    },
    {
      stateName: 'federationStep9',
      description: 'Step 9 Governing Body',
      url: '/federationStep9'
    },
    {
      stateName: 'federationStep10',
      description: 'Step 10 Verifying Statements',
      url: '/federationStep10'
    },

    //Charity Dashboard pages
    {
      stateName: 'step1',
      description: 'Step 1 Organization Contact Information',
      url: '/step1'
    },
    {
      stateName: 'step2',
      description: 'Step 2 Application Type',
      url: '/step2'
    },
    {
      stateName: 'step3',
      description: 'Step 3 Human Health & Welfare Services',
      url: '/step3'
    },
    {
      stateName: 'step4',
      description: 'Step 4 Areas of Service',
      url: '/step4'
    },
    {
      stateName: 'step5',
      description: 'Step 5 Exemption Status',
      url: '/step5'
    },
    {
      stateName: 'step6',
      description: 'Step 6 IRS Determination Letter',
      url: '/step6'
    },
    {
      stateName: 'step7',
      description: 'Step 7 Revenue',
      url: '/step7'
    },
    {
      stateName: 'step8',
      description: 'Step 8 IRS Form 990 or Pro Forma Form 990',
      url: '/step8'
    },
    {
      stateName: 'step9',
      description: 'Step 9 Fundraising Rate',
      url: '/step9'
    },
    {
      stateName: 'step10',
      description: 'Step 10 Governing Body',
      url: '/step10'
    },
    {
      stateName: 'step11',
      description: 'Step 11 Verifying Statements',
      url: '/step11'
    },
    {
      stateName: 'signAndunsign',
      description: 'Electronic Signature',
      url: '/signAndunsign'
    },
    {
      stateName: 'memberapplication',
      description: 'Member Charities Applications',
      url: '/memberapplication'
    },
    {
      stateName: 'withdrawnApplication',
      description: 'Withdraw Application',
      url: '/withdrawnApplication'
    },
    {
      stateName: 'manageCharityUsers',
      description: 'Manage Users',
      url: '/manageCharityUsers'
    },
    {
      stateName: 'inviteUserToCharity',
      description: 'Invite A User',
      url: '/inviteUserToCharity'
    }
  ];

  function convertUpperCaseToCapitalize(str) {
    if (str === 'MEMBER') {
      return 'Member';
    } else if (str === 'INDEPENDENT') {
      return 'Independent';
    } else {
      return str;
    }
  }
}
