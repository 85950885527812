// overview.controller.js - Controller for the Admin Zone Listing Overview view

'use strict';

angular
  .module('CfcCharity.admin.zone.listing')
  .controller('AdminZoneListingOverviewCtl', AdminZoneListingOverviewCtl);

AdminZoneListingOverviewCtl.$inject = [
  '$scope',
  'BrowserEventService',
  'AdminZoneListingService',
  '$log',
  '$state',
  'parameterData',
  'DownloadService',
  'CharityService',
  'RedirectService',
  'UserService',
  'SYSTEM_USER_SECONDARY_ROLES',
  'CubeSvc'
];

function AdminZoneListingOverviewCtl(
  $scope,
  BrowserEventService,
  AdminZoneListingService,
  $log,
  $state,
  parameterData,
  DownloadService,
  CharityService,
  RedirectService,
  UserService,
  SYSTEM_USER_SECONDARY_ROLES,
  CubeSvc
) {
  $scope.moduleName = 'CfcCharity.admin.zone.listing';
  $scope.zoneCharityListingInfo = null;
  $scope.zoneId = null;
  $scope.pledgeYear = null;
  $scope.zoneUserRole = null;
  $scope.selectedListingCampaignId = null;
  $scope.listingStatus = null;
  $scope.btnDisable = true;

  init();

  function init() {
    if (parameterData) {
      $scope.zoneId = parameterData.zoneId;
      $scope.pledgeYear = parameterData.pledgeYear;
      $scope.zoneUserRole = parameterData.zoneUserRole;
    }

    $scope.isZoneManagerOrReviewerOrCoordinator = false;

    if (
      UserService.hasSystemRole([
        SYSTEM_USER_SECONDARY_ROLES.zone_manager,
        SYSTEM_USER_SECONDARY_ROLES.reviewer,
        SYSTEM_USER_SECONDARY_ROLES.zone_coordinator
      ])
    ) {
      $scope.isZoneManagerOrReviewerOrCoordinator = true;
    }

    if ($scope.zoneId && $scope.zoneId !== '' && $scope.pledgeYear) {
      BrowserEventService.setZoneId($scope.zoneId);
      BrowserEventService.setPledgeYear($scope.pledgeYear);
      BrowserEventService.setZoneUserRole($scope.zoneUserRole);

      // Retrieve the charity listing summary & detail information
      AdminZoneListingService.getZoneCharityListingInfo(
        $scope.zoneId,
        $scope.pledgeYear
      )
        .then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            // Set the appropriate default selected year
            angular.forEach(result.listingCampaignPeriods, function(
              value,
              key
            ) {
              if (value.year.toString() === $scope.pledgeYear.toString()) {
                $scope.selectedListingCampaignId = value.campaignId.toString();
              }
            });

            $scope.zoneCharityListingInfo = result;

            // Now that we have the campaignId retrieve the charity listing details
            AdminZoneListingService.getZoneCharityListingDetails(
              $scope.selectedListingCampaignId,
              $scope.zoneId
            )
              .then(function(resp) {
                var result = resp.data;
                if (result !== null && result !== '') {
                  $scope.zoneCharityListingDetails = result;
                }
              })
              .catch(function(err) {
                $log.error(
                  'Error retrieving charity listing info in AdminZoneListingService.getZoneCharityListingDetails'
                );
              });
          }
        })
        .catch(function(er) {
          $log.error(
            'Error retrieving charity listing info in AdminZoneListingService.getZoneCharityListingInfo'
          );
        });
    }
  }

  $scope.gotoZoneCenter = function() {
    BrowserEventService.setZoneUserRole($scope.zoneUserRole);
    BrowserEventService.setZoneId($scope.zoneId);
    $state.go('zoneCenter', {
      zoneId: $scope.zoneId,
      zoneUserRole: $scope.zoneUserRole
    });
  };

  $scope.goToCharityListing = function(pledgeYear, cfcCode, applicationType) {
    RedirectService.goToCharityListing(pledgeYear, cfcCode, applicationType);
  };

  $scope.goToFederationListing = function(pledgeYear, federationCfcCode) {
    RedirectService.goToFederationListing(pledgeYear, federationCfcCode);
  };

  $scope.downloadCharityListingInfoByZone = function() {
    var url =
      '/CfcCharity/api/private/admin/zone/listingDataZoneReportDownload/' +
      $scope.selectedListingCampaignId +
      '/' +
      $scope.zoneId;
    DownloadService.downloadFile(url);
  };

  $scope.selectChangedCampaignId = function() {
    // Use the campaignId to get the pledge year
    var campaignYear = null;
    angular.forEach(
      $scope.zoneCharityListingInfo.listingCampaignPeriods,
      function(value, key) {
        if (value.campaignId.toString() === $scope.selectedListingCampaignId) {
          campaignYear = value.year.toString();
        }
      }
    );

    $state.go('admin-zone-listing-overview', {
      pledgeYear: parseInt(campaignYear),
      zoneId: $scope.zoneId,
      zoneUserRole: $scope.zoneUserRole
    });
  };

  var url = '/CfcCharity/api/private/admin/getRandomizeJobStatus';
  CubeSvc.get(url, null, false).then(function(res) {
    $scope.job = res.data.value;
    if ($scope.job.status === 'Running' || $scope.job.status === 'Failed') {
      $scope.btnDisable = true;
    } else {
      $scope.btnDisable = false;
    }
  });

  $scope.gotoPrintorder = function(zoneName, zoneCode) {
    BrowserEventService.setCampaignId($scope.selectedListingCampaignId);
    BrowserEventService.setZoneId($scope.zoneId);
    BrowserEventService.setPledgeYear($scope.pledgeYear);
    BrowserEventService.setZoneUserRole($scope.zoneUserRole);
    $state.go('admin-zone-listing-printorder', {
      zone: {
        name: zoneName,
        code: zoneCode,
        id: $scope.zoneId
      },
      campaignId: $scope.selectedListingCampaignId,
      pledgeYear: $scope.pledgeYear,
      zoneUserRole: $scope.zoneUserRole
    });
  };
}
