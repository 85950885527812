'use strict';

angular
  .module('CfcCharity.applicationDashboard')
  .factory('AppStepsService', AppStepsService);

AppStepsService.$inject = ['$http', '$log', 'DownloadService', 'CubeSvc'];

function AppStepsService($http, $log, DownloadService, CubeSvc) {
  var service = {
    getApplicationStatus: getApplicationStatus,
    getFederationApplicationStatus: getFederationApplicationStatus,
    getChecklistPDF: getChecklistPDF
  };
  return service;

  function getApplicationStatus(appId) {
    return CubeSvc.get(
      '/CfcCharity/api/private/application/status/overall/' + appId,
      null,
      false
    );
  }

  function getFederationApplicationStatus(year, cfcCode) {
    return CubeSvc.get(
      '/CfcCharity/api/private/users/federation/application/' +
        year +
        '/' +
        cfcCode
    );
  }

  function getChecklistPDF(checklistType, fysaFyspWfr) {
    var fileUrl =
      '/CfcCharity/api/private/application/checklist_pdf?checklistType=' +
      checklistType +
      '&fysaFyspWfr=' +
      fysaFyspWfr;
    return DownloadService.getFileDownload(fileUrl);
  }
}
