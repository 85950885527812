'use strict';

(function() {
  angular
    .module('CfcCharity.admin.system')
    .factory('IrsMasterFileSearchService', IrsMasterFileSearchService);

  IrsMasterFileSearchService.$inject = ['$http', '$q', 'CubeSvc'];

  function IrsMasterFileSearchService($http, $q, CubeSvc) {
    function getIrsMasterFileSearchResults(
      pageNumber,
      searchType,
      irsSearchValue
    ) {
      var url =
        '/CfcCharity/api/private/admin/searchIrsMasterFile/' + pageNumber;
      return CubeSvc.get(
        url,
        { params: { search: irsSearchValue, searchType: searchType } },
        true
      );
    }

    return {
      getIrsMasterFileSearchResults: getIrsMasterFileSearchResults
    };
  }
})();
