angular
  .module('CfcCharity.home', [
    'ui.router',
    'CfcCharity.security',
    'hateoas',
    'ngAria',
    'CfcCharity.common',
    'angular-uuid'
  ])
  .config(function myAppConfig(
    $stateProvider,
    $ariaProvider,
    $compileProvider
  ) {
    $ariaProvider.config({});
    if (process.env.NODE_ENV !== 'production') {
      $compileProvider.debugInfoEnabled(true);
    } else {
      $compileProvider.debugInfoEnabled(false);
    }
    $stateProvider.state('home', {
      url: '/home',
      views: {
        main: {
          template: require('../views/public/home.html'),
          controller: 'HomeCtrl'
        }
      },
      data: { pageTitle: 'CFC Charity Home Page' },
      breadcrumb: {
        displayName: 'Home',
        base: true
      }
    });
    $stateProvider.state('termsofUse', {
      url: '/termsofUse/:fromPage',
      views: {
        main: {
          template: require('../views/public/termsofUse.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'CFC Charity Conditions of Use Page' },
      breadcrumb: { displayName: 'Terms Of Use' }
    });
    $stateProvider.state('item1', {
      url: '/item1/:fromPage',
      views: {
        main: {
          template: require('../views/public/item1.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'CFC Charity Privacy Notice Page' }
    });
    $stateProvider.state('item2', {
      url: '/item2/:fromPage',
      views: {
        main: {
          template: require('../views/public/item2.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'CFC Charity Privacy Notice Page' }
    });
    $stateProvider.state('item3', {
      url: '/item3/:fromPage',
      views: {
        main: {
          template: require('../views/public/item3.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'CFC Charity Privacy Notice Page' }
    });
    $stateProvider.state('privacyNotice', {
      url: '/privacyNotice/:fromPage',
      views: {
        main: {
          template: require('../views/public/privacyNotice.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'CFC Charity Privacy Notice Page' }
    });
    $stateProvider.state('aboutus', {
      url: '/aboutus/:fromPage',
      views: {
        main: {
          template: require('../views/public/aboutus.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'CFC Charity About us Page' },
      breadcrumb: {
        displayName: 'About Us'
      }
    });
    $stateProvider.state('applforCharities', {
      url: '/applicationForCharities/:fromPage',
      views: {
        main: {
          template: require('../views/public/appInstructions.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'Applications for Charities' },
      breadcrumb: {
        displayName: 'Applications for Charities'
      }
    });
    $stateProvider.state('contactus', {
      url: '/contactus:fromPage',
      views: {
        main: {
          template: require('../views/public/contactCfc.html'),
          controller: 'TermsCtrl'
        }
      },
      data: { pageTitle: 'Contact Us' },
      breadcrumb: {
        displayName: 'Contact Us'
      }
    });
    $stateProvider.state('freqAskedQues', {
      url: '/freqAskedQues:search',
      views: {
        main: {
          template: require('../views/public/freqAskedQues.html'),
          controller: 'freqAskedQuesCtrl'
        }
      },
      data: { pageTitle: 'Frequently Asked Questions' },
      resolve: {
        FaqDetailsData: [
          '$log',
          '$http',
          '$stateParams',
          function($log, $http, $stateParams) {
            var url = '/CfcCharity/api/public/faqDetails';
            return $http.get(url).then(function(res) {
              $log.info(
                '/FAQ details data success: ' + JSON.stringify(res.data)
              );
              return res.data;
            });
          }
        ]
      },
      breadcrumb: {
        displayName: 'FAQ'
      }
    });
  })
  .controller('HomeCtrl', function(
    $log,
    $state,
    $scope,
    $http,
    AnnouncerService,
    CommonService,
    toastrService,
    cmsService,
    $filter,
    $sce,
    AppService,
    blockUI,
    uuid
  ) {
    $scope.setModuleName('home');
    $scope.serviceError = '';
    $scope.errorMsg = '';
    $scope.checkServiceStatus = function() {
      if ($scope.serviceError.length > 0) {
        return true;
      } else {
        return false;
      }
    };
    CommonService.getServiceStatus()
      .then(function(res) {
        if (!res.data) {
          $scope.serviceError = 'Service is currently unavailable.';
        }
      })
      .catch(function(err) {
        $scope.serviceError = 'Service is currently unavailable.';
      });

    $scope.$on('$viewContentLoaded', AnnouncerService.announce_view_loaded());
    $scope.conditionsOfUse = function() {
      $state.go('termsofUse', { fromPage: $state.current.name });
    };
    $scope.privacyNotice = function() {
      $state.go('privacyNotice', { fromPage: $state.current.name });
    };
    $scope.showErrorMessage = function(error) {
      toastrService.error(error);
    };

    function init() {
      cmsService.getContent('HOMEPAGE_VIDEO_LINK').then(function(response) {
        $scope.youtubeUrl = $sce.trustAsResourceUrl(
          $filter('removeHTMLTags')(response.data.content)
        );
      });
    }

    init();
  })
  .controller('TermsCtrl', function($state, $scope) {
    $scope.backButtonClicked = function() {
      if ($state.params.fromPage === 'login') {
        $state.go('login');
      } else {
        if (
          !$scope.isAuthenticated ||
          !$scope.getCurrentUser() ||
          ($scope.isAuthenticated && $scope.isHome())
        ) {
          $state.go('home');
        } else if (
          $scope.isAuthenticated &&
          (!$scope.isHome() && !$scope.isAdminUser())
        ) {
          $state.go('userDashboard', { userId: $scope.getCurrentUser().id });
        } else if ($scope.isAuthenticated && $scope.isAdminUser()) {
          $state.go('administrationcenter', { userId: $scope.currentUser.id });
        } else {
          $state.go('home');
        }
      }
    };
  })
  .controller('freqAskedQuesCtrl', function($state, $scope, FaqDetailsData) {
    $scope.setModuleName('CfcCharity.home');
    $scope.state = [];

    if (FaqDetailsData != null && FaqDetailsData !== 'undefined') {
      $scope.FaqDetailsData = FaqDetailsData;
      $scope.faqDetailsBeanList = [];
      $scope.faqDetailsBeanList = $scope.faqDetailsBeanList.concat(
        FaqDetailsData
      );
      $scope.faqDetailsBeanListLimit = $scope.faqDetailsBeanList.length;
      $scope.loadMoreFaqs = function() {
        if (
          $scope.faqDetailsBeanListLimit <= $scope.faqDetailsBeanList.length
        ) {
          $scope.faqDetailsBeanListLimit += 100;
        }
      };
    }
    $scope.search = $state.params.search;

    $scope.toggle = function(index) {
      $scope.state[index] = !$scope.state[index];
    };
  });
