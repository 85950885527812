(function($) {
  'use strict';

  angular
    .module('CfcCharity.admin.system')
    .controller('EmailTemplatesCtrl', EmailTemplatesCtrl);

  EmailTemplatesCtrl.$inject = [
    '$log',
    'toastrService',
    'emailTemplatesService',
    'blockUI',
    '$state',
    '$stateParams',
    '$scope'
  ];

  function EmailTemplatesCtrl(
    $log,
    toastrService,
    emailTemplatesService,
    blockUI,
    $state,
    $stateParams,
    $scope
  ) {
    var vm = this;
    vm.emailTemplateData = {};

    vm.sortType = 'campaignYear';
    vm.sortReverse = true;
    vm.filterText = '';
    vm.activeFilterFlag = null;
    vm.editedTemplateId = null;

    vm.editedEmailTemplateId = $stateParams.emailTemplateId || -1;

    var templates;
    (templates = function() {
      emailTemplatesService
        .getEmailTemplates()
        .then(function(resp) {
          // success
          blockUI.start();
          vm.templates = resp.data.value;
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Request failed.');
        })
        .finally(function() {
          var found = false;
          if (scrollToEventTriggeredSection) {
            angular.forEach(vm.templates, function(temp) {
              if (temp.id.toString() === vm.editedEmailTemplateId.toString()) {
                found = true;
              }
            });
            if (found) {
              vm.emailTemplateData.applicationStatusEmailTabPaneActive = true;
              vm.emailTemplateData.eventTriggeredEmailTabPaneActive = false;
            } else {
              vm.retrieveEventTriggeredEmail();
            }
            scrollToEventTriggeredSection = false;
          } else {
            vm.emailTemplateData.applicationStatusEmailTabPaneActive = true;
            vm.emailTemplateData.eventTriggeredEmailTabPaneActive = false;
            scrollToEventTriggeredSection = false;
          }
          blockUI.stop();
        });
    })();

    vm.onEditTemplate = function(emailTemplateId) {
      $state.go('emailTemplate', {
        emailTemplateId: emailTemplateId
      });
    };

    vm.activeFilter = function(template) {
      switch (vm.activeFilterFlag) {
        case null: // all
          return true;
        case true: // active
          return template.active === true;
        case false: // inactive
          return template.active === false;
        default:
          return true;
      }
    };

    vm.retrieveAppStatusEmail = function() {
      templates();
      vm.filterText = '';
      vm.activeFilterFlag = null;
    };

    vm.retrieveEventTriggeredEmail = function() {
      emailTemplatesService
        .getTriggeredEmailTemplates()
        .then(function(resp) {
          // success
          blockUI.start();
          vm.templates = resp.data.value;
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Request failed.');
        })
        .finally(function() {
          // finally
          vm.emailTemplateData.applicationStatusEmailTabPaneActive = false;
          vm.emailTemplateData.eventTriggeredEmailTabPaneActive = true;
          vm.activeFilterFlag = null;
          vm.filterText = '';
          blockUI.stop();
        });
    };

    var scrollToEventTriggeredSection = false;
    $scope.$on('$locationChangeSuccess', function(evt, newUrl, oldUrl) {
      if (oldUrl.indexOf('email-template') > -1) {
        var id = oldUrl.split('/');
        if (id.length > 0) {
          vm.editedEmailTemplateId = id[id.length - 1];
        }
        scrollToEventTriggeredSection = true;
      }
    });
  }
})(jQuery);
