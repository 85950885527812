'use strict';

angular
  .module('CfcCharity.donations')
  .controller('MemberDonationsCtrl', MemberDonationsCtrl);

MemberDonationsCtrl.$inject = [
  '$scope',
  'DonationsService',
  'DownloadService',
  'BrowserEventService',
  '$state',
  '$log',
  '$http',
  'DonationsReportsDefn',
  'CharityService',
  'PledgeService',
  'blockUIConfig',
  'blockUI',
  'CubeSvc'
];

function MemberDonationsCtrl(
  $scope,
  DonationsService,
  DownloadService,
  BrowserEventService,
  $state,
  $log,
  $http,
  DonationsReportsDefn,
  CharityService,
  PledgeService,
  blockUIConfig,
  blockUI,
  CubeSvc
) {
  $scope.moduleName = 'CfcCharity.donations';
  $scope.isAuthorized = false;
  $scope.parentCfcCode = $state.params.parentCfcCode;
  $scope.campaignId = $state.params.campaignId;
  $scope.pledgeYear = $state.params.pledgeYear;
  $scope.federationName = null;

  blockUIConfig.autoBlock = false;

  /* download Member Donations Data Report */
  $scope.downloadMemberDonationData = function() {
    var url =
      '/CfcCharity/api/private/charity/application/donation/download/reports/' +
      DonationsReportsDefn.FedMembersDonationBalances +
      '/' +
      $scope.parentCfcCode +
      '/' +
      $scope.pledgeYear +
      '/' +
      $scope.campaignId;
    DownloadService.getFileDownload(url);
  };

  $scope.gotoCharityDashboard = function() {
    $state.go('charitydashboard', {
      charityDashboardCfcCode: $scope.parentCfcCode
    });
  };

  function init() {
    $scope.isAuthorized = isAuthorized();

    setFederationName();

    $scope.memberDonations = [];
    $scope.memberDonationsLoadStatus = 'LOADING';
    $scope.downloadButtonDisabled = true;

    var url =
      '/CfcCharity/api/private/charity/application/donation/member/summary/' +
      $scope.campaignId +
      '/' +
      $scope.parentCfcCode;

    CubeSvc.get(url)
      .then(function(res) {
        $scope.memberDonations = res.data.value;
        //data for report is available so enable download button
        $scope.downloadButtonDisabled = false;
        $scope.memberDonationsLoadStatus = 'SUCCESS';
      })
      .catch(function(err) {
        $log.error(err);
        $scope.memberDonationsLoadStatus = 'ERROR';
      });

    // re-enable block UI when leaving this page
    $scope.$on('$destroy', function() {
      // it has to be on $scope, since we can hook into $destroy; otherwise wouldn't need or inject $scope
      blockUIConfig.autoBlock = true;
    });
  }

  function setFederationName() {
    $scope.federationName = 'federation name';
    CharityService.findCharityWithCFC($scope.parentCfcCode).then(function(res) {
      var charity = res.data;
      $scope.federationName = charity.charityName;
    });
  }

  var isAuthorized = function() {
    var yn = false;
    var user = BrowserEventService.getCurrentUser(); //$scope.getCurrentUser(); // JSON.parse(sessionStorage.getItem('USER_OBJ'));

    if (
      (user.secondaryRoleList &&
        Array.isArray(user.secondaryRoleList) &&
        user.secondaryRoleList.length > 0 &&
        user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0) ||
      user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
      user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
      user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
      user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_1') >= 0 ||
      user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >= 0 ||
      user.secondaryRoleList.indexOf('FEDERATION_OWNER') >= 0 ||
      user.secondaryRoleList.indexOf('FEDERATION_EDITOR') >= 0 ||
      user.secondaryRoleList.indexOf('GB_FINANCE') >= 0 ||
      user.secondaryRoleList.indexOf('GB_FINANCE_ADMIN') >= 0
    ) {
      yn = true;
    } else {
      CharityService.getCharityUserRole($scope.parentCfcCode)
        .then(function(res) {
          if (res.data) {
            return res.data.userRole.toUpperCase();
          }
        })
        .then(function(role) {
          if (
            role === 'CHARITY_OWNER' ||
            role === 'OWNER' ||
            role === 'CHARITY_EDITOR' ||
            role === 'EDITOR'
          ) {
            yn = true;
            $scope.isAuthorized = yn;
          }
        })
        .catch(function(err) {
          $log.error(err);
        });
    }

    return yn;
  };

  init();
}
