(function() {
  'use strict';

  angular
    .module('CfcCharity.admin.system')
    .controller('CmsListCtrl', CmsListCtrl);

  CmsListCtrl.$inject = [
    '$state',
    '$stateParams',
    '$log',
    'toastrService',
    'cmsService',
    'blockUI'
  ];

  function CmsListCtrl(
    $state,
    $stateParams,
    $log,
    toastrService,
    cmsService,
    blockUI
  ) {
    var vm = this;

    vm.editedContentId = $stateParams.contentId || -1;
    vm.filterText = '';
    vm.selectedCategory = 'All Categories';
    vm.categoryFilter = {};

    function init() {
      cmsService
        .getList()
        .then(function(resp) {
          // success
          blockUI.start();
          vm.list = resp.data;
          return cmsService.getCategories();
        })
        .then(function(resp) {
          vm.categories = resp.data;
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Request failed.');
        })
        .finally(function() {
          // finally
          blockUI.stop();
        });
    }

    vm.onEdit = function(contentId, e) {
      e.preventDefault();
      $state.go('cms-detail', {
        contentId: contentId
      });
    };

    vm.onCategoryFilter = function(category, e) {
      e.preventDefault();
      vm.selectedCategory = category;
      if (
        vm.categoryFilter.contentCategory === category ||
        category === 'All Categories'
      ) {
        vm.categoryFilter = {};
      } else {
        vm.categoryFilter.contentCategory = category;
      }
    };

    init();
  }
})();
