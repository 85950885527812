angular
  .module('CfcCharity.accessrequestdenied', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('accessrequestdenied', {
        url: '/accessRequestDenied',
        views: {
          main: {
            template: require('../views/private/charity/accessrequestdenied.html'),
            controller: 'AccessRequestDeniedCtrl'
          }
        },
        data: {
          pageTitle: 'Reason for Denied/Declined Access',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: { accessRequestDeniedId: null },
        resolve: {
          accessRequestDeniedData: function($http, $stateParams) {
            if ($stateParams.accessRequestDeniedId != null) {
              var url =
                '/CfcCharity/api/private/users/accessrequestdenied/' +
                $stateParams.accessRequestDeniedId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
    }
  ])
  .controller('AccessRequestDeniedCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'accessRequestDeniedData',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      accessRequestDeniedData,
      $http
    ) {
      $scope.accessRequestDeniedData = accessRequestDeniedData;
    }
  ]);
