angular
  .module('CfcCharity.administrationcenter', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('administrationcenter', {
        url: '/administrationcenter',
        views: {
          main: {
            template: require('../views/private/admin/administrationcenter.html'),
            controller: 'AdministrationCenterCtrl'
          }
        },
        data: {
          pageTitle: 'Administration Center',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          userId: null,
          firstTimeUser: null
        },
        resolve: {
          administrationCenterData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            BrowserEventService
          ) {
            BreadcrumbTokenDataService.clearTokenData();
            var userId = $stateParams.userId
              ? $stateParams.userId
              : BrowserEventService.getCurrentUser().id;
            if (userId != null) {
              var url = '/CfcCharity/api/private/admin';
              return $http.get(url).then(function(res) {
                if (res.data) {
                  var data = res.data;
                  data.firstTimeUser = $stateParams.firstTimeUser;
                  BreadcrumbTokenDataService.setTokenData({
                    userId: userId,
                    firstTimeUser: $stateParams.firstTimeUser
                  });
                  return data;
                } else {
                  return null;
                }
              });
            }
          }
        }
      });
      $stateProvider.state('userSearchResults', {
        url: '/userSearchResults',
        views: {
          main: {
            template: require('../views/private/admin/userSearchResults.html'),
            controller: 'AdministrationCenterUserSearchCtrl'
          }
        },
        data: {
          pageTitle: 'Find a User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: { userId: null }
      });
      $stateProvider.state('accessDenied', {
        url: '/accessDenied',
        views: {
          main: {
            template: require('../views/private/admin/accessDenied.html'),
            controller: 'AccessDeniedCtrl'
          }
        },
        data: {
          pageTitle: 'Access Denied',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: { userId: null }
      });
    }
  ])
  .service('ListingService', [
    '$http',
    function($http) {
      this.getCampaignInformation = function(campaignId) {
        var url = '/CfcCharity/api/private/admin/editcampaign/' + campaignId;
        return $http.get(url);
      };
    }
  ])
  .service('AdministrationCenterService', [
    '$http',
    function($http) {
      this.getUserByUserEmail = function(user_email) {
        return $http.post('/CfcCharity/api/private/user/userByEmail', {
          username: user_email
        });
      };
      this.getUserById = function(userId) {
        var url = '/CfcCharity/api/private/users/' + userId;
        return $http.get(url);
      };
      this.getUserDataById = function(userId) {
        var url = '/CfcCharity/api/private/users/userdata/' + userId;
        return $http.get(url);
      };
      this.getUserRole = function(userId) {
        var url = '/CfcCharity/api/private/getUserRole/' + userId;
        return $http.get(url);
      };
      this.getSystemUserRole = function(userId) {
        var url = '/CfcCharity/api/private/getSystemUserRole/' + userId;
        return $http.get(url);
      };
      this.getUserSessionLogs = function(userId) {
        var url = '/CfcCharity/api/private/getUserSessionLogs/' + userId;
        return $http.get(url);
      };
      this.updateUserStatus = function(userId, newStatus) {
        var url =
          '/CfcCharity/api/private/updateUserStatus/' +
          userId +
          '/' +
          newStatus;
        return $http.post(url);
      };

      this.requestUpdateUserEmailFromSettings = function(id, username) {
        var url = '/CfcCharity/api/private/requestUpdateUsername';
        return $http.post(url, { id, username });
      };
      this.updateUserEmailFromSettings = function(id, newUsernameAuthCode) {
        var url = '/CfcCharity/api/public/updateUserEmail';
        return $http.post(url, { id, newUsernameAuthCode });
      };
      this.updateUserName = function(userId, firstName, lastName) {
        var url = '/CfcCharity/api/private/updateUserName/' + userId;
        return $http.post(url, {
          firstName: firstName,
          lastName: lastName
        });
      };
      this.getemailIDUpdateHistory = function(userId) {
        var url = '/CfcCharity/api/private/userEmailUpdHistory/' + userId;
        return $http.get(url);
      };
      this.downloadFileByURL = function(url) {
        var form = document.getElementById('downloadDataForm');
        var downloadURL = url;
        form.setAttribute('action', downloadURL);
        form.setAttribute('method', 'post');
        form.setAttribute('target', '_blank');
        document.documentElement.appendChild(form);
        form.submit();
      };
    }
  ])
  .controller('AdministrationCenterCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'administrationCenterData',
    'BrowserEventService',
    'AdministrationCenterService',
    'CharityService',
    'ZoneCenterService',
    'blockUI',
    'motdService',
    'toastrService',
    'EnvironmentInfoService',
    'CubeSvc',
    'blockUIConfig',
    'DownloadService',
    'fileUpload',
    '$window',
    function(
      $log,
      $state,
      $scope,
      $location,
      $rootScope,
      administrationCenterData,
      BrowserEventService,
      AdministrationCenterService,
      CharityService,
      ZoneCenterService,
      blockUI,
      motdService,
      toastrService,
      EnvironmentInfoService,
      CubeSvc,
      blockUIConfig,
      DownloadService,
      fileUpload,
      $window
    ) {
      blockUI.start();
      $scope.setModuleName('CfcCharity.administrationcenter');
      $scope.dataAvailable = true;
      $scope.isOpmAdmin = false;
      $scope.timeLimitReached = true;
      EnvironmentInfoService.getBasicEnvironmentInfo()
        .then(function(res) {
          if (res && res.data) {
            $scope.basicEnvironmentObj = res.data;
          }
        })
        .catch(function(err) {
          $log.error(err);
        });
      if (
        administrationCenterData != null &&
        administrationCenterData !== 'undefined'
      ) {
        $scope.administrationCenterData = administrationCenterData;
        if (
          administrationCenterData.pledgeYear !== null &&
          administrationCenterData.pledgeYear !== 'undefined'
        ) {
          BrowserEventService.setPledgeYear(
            administrationCenterData.pledgeYear
          );
          sessionStorage.setItem(
            'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR',
            administrationCenterData.pledgeYear
          );
        } else {
          $scope.dataAvailable = false;
        }

        if (
          administrationCenterData &&
          administrationCenterData.pledgeStartDate &&
          Date.parse(administrationCenterData.pledgeStartDate) <
            Date.parse(new Date().toDateString())
        ) {
          $scope.activePledgeYear = sessionStorage.getItem(
            'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
          );
          $scope.activePledgeCampaignId =
            administrationCenterData.currentCampaignId;
        } else {
          $scope.activePledgeYear =
            sessionStorage.getItem('CREATE_APPLICATION_CURRENT_PLEDGE_YEAR') -
            1;
          $scope.activePledgeCampaignId =
            administrationCenterData.previousCampaignId;
        }
        if (
          administrationCenterData &&
          administrationCenterData.applicationStartDate &&
          Date.parse(administrationCenterData.applicationStartDate) <=
            Date.parse(new Date().toDateString())
        ) {
          $scope.activeApplicationYear = sessionStorage.getItem(
            'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
          );
        } else {
          $scope.activeApplicationYear =
            sessionStorage.getItem('CREATE_APPLICATION_CURRENT_PLEDGE_YEAR') -
            1;
        }

        var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        $scope.isOpmAdminOrStaffOrAuditor = false;
        if (
          userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
        ) {
          $scope.isOpmAdminOrStaffOrAuditor = true;
        }

        if (userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0) {
          $scope.isOpmAdmin = true;
        }

        $scope.downloadTemplate = function() {
          return DownloadService.getFileDownload(
            '/CfcCharity/api/private/admin/downloadCountryTemplate'
          );
        };

        $scope.closeCountryList = function() {
          $('#countryList').val('');
          $scope.supportingFile = null;
        };

        $scope.openModal = function(event) {
          $('#uploadCountryList').modal();
        };

        $scope.uploadCountryList = function() {
          $scope.successMsg = [];
          $scope.errorMsg = null;
          $scope.country = {};

          if ($scope.supportingFile != null) {
            $scope.country.supportingFile = $scope.supportingFile;
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/admin/uploadCountryFile',
                $scope.country
              )
              .then(function(res) {
                var data = res.data.value;
                if (
                  data.status.statusCode !== undefined &&
                  data.status.statusCode === 'ERROR'
                ) {
                  $scope.errorMsg = data.status.message;
                  $('#countryList').val('');
                  $scope.supportingFile = null;
                } else {
                  $('#countryList').val('');
                  $scope.supportingFile = null;
                  //add the success message
                  var msg = data.status.message.split('<br>');
                  $scope.successMsg = msg;
                }
              })
              .catch(function(err) {
                /*Todo: Log error*/
              });
          } else {
            $scope.errorMsg = 'Please upload a file.';
          }
        };

        //get randomize job status
        if ($scope.isOpmAdmin) {
          var jobDetails;
          (jobDetails = function() {
            var url = '/CfcCharity/api/private/admin/getRandomizeJobStatus';
            CubeSvc.get(url, null, false).then(function(res) {
              $scope.job = res.data.value;
              if (
                $scope.job.status === 'Running' ||
                $scope.job.status === 'Failed'
              ) {
                $scope.btnDisable = true;
              } else {
                $scope.btnDisable = false;
              }
              $scope.user = $scope.job.user;
              $scope.timeLimitReached = $scope.job.timeLimitReached;
            });
          })();

          $scope.continueRandomization = function() {
            $scope.job.status = 'Running';
            $scope.timeLimitReached = true;
            blockUIConfig.autoBlock = false;
            var url = '/CfcCharity/api/private/admin/randomizeallfailedzones';
            CubeSvc.get(url, null, false).then(function(res) {
              $scope.btnDisable = res.data.value;
            });
          };

          $scope.randomizeAllZones = function() {
            $scope.job.status = 'Running';
            $scope.timeLimitReached = true;
            blockUIConfig.autoBlock = false;
            var url = '/CfcCharity/api/private/admin/randomizeallzones';
            CubeSvc.get(url, null, false).then(function(res) {
              $scope.btnDisable = res.data.value;
            });
          };

          $scope.checkJobStatus = function() {
            jobDetails();
            $('#randomizationDetail').modal();
          };
        }

        $scope.setSystemUserSecondLevelRoleList(
          administrationCenterData.userRoleBean.secondLevelRoleList
        );
        if ($scope.administrationCenterData.firstTimeUser === true) {
          var user = $scope.getCurrentUser();
          var userRoles = $scope.userRoles;
          if (
            user.roles.indexOf(userRoles.system_user) >= 0 &&
            user.roles.indexOf(userRoles.charity_user) >= 0
          ) {
            $scope.setAdminAndCharityUser(true);
            $scope.setAdminUser(false);
            $scope.setLeftSideNavbarMenuItemSelected('userhomepage');
            $scope.setModuleName('CfcCharity.userdashboard');
            $state.go('userDashboard', { userId: user.id });
          } else if (user.roles.indexOf(userRoles.system_user) >= 0) {
            $scope.setAdminUser(true);
            $scope.setAdminAndCharityUser(false);
            $scope.setModuleName('CfcCharity.administrationcenter');
            $state.go('administrationcenter', { userId: user.id });
          } else if (user.roles.indexOf(userRoles.charity_user) >= 0) {
            $scope.setAdminAndCharityUser(false);
            $scope.setAdminUser(false);
            $scope.setSystemUserSecondLevelRoleList(null);
            $scope.setModuleName('CfcCharity.userdashboard');
            $state.go('userDashboard', { userId: user.id });
          }
        }
      }

      motdService
        .getMOTD()
        .then(function(response) {
          var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
          if (userOb.motdAcknowledged) {
            return;
          }
          if (response && response.data && response.data.messageText) {
            toastrService.info(
              response.data.messageText,
              'Message of the Day',
              {
                timeOut: 0,
                extendedTimeOut: 0,
                autoDismiss: false,
                closeButton: true,
                positionClass: 'toast-top-full-width',
                onHidden: acknowledgeDisplayBanner()
              }
            );
          }
        })
        .catch(function(err) {
          $log.error(
            err.data ||
              'displaySystemMessage() failed with status ' + err.status,
            err
          );
        });

      function acknowledgeDisplayBanner() {
        var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        userOb.motdAcknowledged = true;
        sessionStorage.setItem('USER_OBJ', JSON.stringify(userOb));
      }
      blockUI.stop();
      $scope.findUserByAdmin = function(user_email_or_id) {
        $scope.noResultsErrorMessage = '';
        var reInt = /^\d+$/; // a sequence of numbers only
        if (reInt.test(user_email_or_id)) {
          var userId = user_email_or_id;
          AdministrationCenterService.getUserById(userId).then(function(res) {
            var user = res.user;
            $log.info(user);
            if (null !== user && '' !== user) {
              $state.go('userSearchResults', { userId: userId });
            } else {
              $log.info('No User Found !!!');
              $scope.noResultsErrorMessage = 'No Results Found';
            }
          });
        } else {
          // uses previous logic, assumes email check, if fails out, no user found
          var user_email = user_email_or_id;
          AdministrationCenterService.getUserByUserEmail(user_email).then(
            function(res) {
              var user = res.data;
              if (user !== null && user !== '') {
                $state.go('userSearchResults', { userId: user.id });
              } else {
                $log.info('No User Found !!!');
                $scope.noResultsErrorMessage = 'No Results Found';
              }
            }
          );
        }
      };
      $scope.gotoZoneCenter = function(zoneId, role) {
        BrowserEventService.setZoneUserRole(role);
        BrowserEventService.setZoneId(zoneId);
        $state.go('zoneCenter', {
          zoneId: zoneId,
          zoneUserRole: role
        });
      };
    }
  ])
  .controller('AdministrationCenterUserSearchCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    'AdministrationCenterService',
    'CharityService',
    'ZoneCenterService',
    'ValidationService',
    function(
      $log,
      $state,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      AdministrationCenterService,
      CharityService,
      ZoneCenterService,
      ValidationService
    ) {
      $scope.setModuleName('CfcCharity.administrationcenter');
      var userId = $state.params.userId;
      if (userId) {
        $scope.loggedInUserId = $scope.getCurrentUser().id;
        $scope.userId = userId;
        $scope.userLevelAssignments = '';
        $scope.charityUserLevel = '';
        $scope.zoneUserLevel = '';
        $scope.systemUserLevel = '';
        $scope.userSearchResults = {};
        $scope.userData = null;
        $scope.userFirstName = '';
        $scope.userLastName = '';
        $scope.emailUpdateHistoryList = null;
        $scope.userUpdateErrorMessage = '';
        $scope.userUpdateSuccessMessage = '';
        $scope.newEmailAddress = '';
        $scope.newEmailConfirm = '';
        $scope.disableUpdateEmailSubmit = false;
        $scope.updateUserEmailErrors = '';
        $scope.updateUserEmailMessage = '';
        AdministrationCenterService.getUserRole(userId).then(function(res) {
          var role = res.data;
          if (role !== null && role !== '') {
            if (role.firstLevelRoleList.indexOf('ROLE_CHARITY_USER') >= 0) {
              $scope.userLevelAssignments = 'CHARITY_USER';
              CharityService.getCharitiesByUser(userId).then(function(resp) {
                var charities = resp.data;
                $scope.userCharityList = charities;
                if (
                  $scope.userCharityList &&
                  $scope.userCharityList.length > 0
                ) {
                  $scope.charityUserLevel = 'CHARITY_USER';
                } else {
                  $scope.charityUserLevel = '';
                }
              });
            }
            if (role.firstLevelRoleList.indexOf('ROLE_SYSTEM_USER') >= 0) {
              if (
                role.secondLevelRoleList.indexOf('OPM_ADMIN') >= 0 ||
                role.secondLevelRoleList.indexOf('OPM_STAFF') >= 0 ||
                role.secondLevelRoleList.indexOf('CUSTOMER_SERVICE') >= 0 ||
                role.secondLevelRoleList.indexOf('OPM_AUDITOR') >= 0
              ) {
                $scope.userLevelAssignments = 'SYSTEM_USER';
                $scope.systemUserLevel = 'SYSTEM_USER';
                $scope.systemRole = role.secondLevelRoleList[0];
              }
              if (
                role.secondLevelRoleList.indexOf('ZONE_MANAGER') >= 0 ||
                role.secondLevelRoleList.indexOf('REVIEWER') >= 0 ||
                role.secondLevelRoleList.indexOf('ZONE_COORDINATOR') >= 0
              ) {
                $scope.userLevelAssignments = 'ZONE_USER';
                ZoneCenterService.getZonesByUser(userId).then(function(res) {
                  var zones = res.data;
                  $scope.zoneList = zones;
                  if ($scope.zoneList && $scope.zoneList.length > 0) {
                    $scope.zoneUserLevel = 'ZONE_USER';
                  } else {
                    $scope.zoneUserLevel = '';
                  }
                });
              }
            }
            AdministrationCenterService.getUserSessionLogs(userId).then(
              function(res) {
                var userSessionLogs = res.data;
                $scope.userSessionLogs = userSessionLogs;
              }
            );
          }
        });
        AdministrationCenterService.getUserDataById($scope.userId).then(
          function(res) {
            var user = res.data;
            $scope.userData = user;
            $scope.userSearchResults.status = $scope.userData.status;
            $scope.oldStatus = $scope.userSearchResults.status;
            $scope.userEmailID = user.userEmail;
            $scope.userFirstName = user.firstName;
            $scope.userLastName = user.lastName;
          }
        );
      }
      $scope.viewFullHistory = function() {
        $('#userLoginHistory').modal();
      };
      $scope.updateUserAuthStatus = function() {
        var newStatus = $scope.userSearchResults.status;
        var userId = $scope.userId;
        AdministrationCenterService.updateUserStatus(userId, newStatus)
          .then(function(res) {
            var data = res.data;
            if (data.statusCode && data.statusCode === 'ERROR') {
              $scope.userUpdateSuccessMessage = '';
              $scope.userUpdateErrorMessage = data.message;
            } else {
              $scope.userUpdateSuccessMessage =
                'User status has been successfully updated.';
              $scope.userUpdateErrorMessage = '';
            }
          })
          .catch(function(err) {
            $scope.userUpdateSuccessMessage = '';
            $scope.userUpdateErrorMessage = 'Error while updating User status.';
            $log.error('Error while updating User status.');
          });
      };
      $scope.updateUserEmail = function() {
        $scope.disableUpdateEmailSubmit = true;
        $scope.updateUserEmailErrors = '';
        var userId = $scope.userId;
        var email = $scope.newEmailAddress;
        var emailConfirm = $scope.newEmailConfirm;
        if (email !== emailConfirm) {
          $scope.updateUserEmailErrors = 'Email addresses do not match';
          $scope.disableUpdateEmailSubmit = false;
          return;
        }
        if (!email || ValidationService.validateEmail(null, email)) {
          $scope.updateUserEmailErrors = 'Invalid Email';
          $scope.disableUpdateEmailSubmit = false;
          return;
        }
        AdministrationCenterService.requestUpdateUserEmailFromSettings(
          userId,
          email.trim()
        )
          .then(function(res) {
            var data = res.data;
            if (data.statusCode === 'ERROR') {
              $scope.updateUserEmailErrors = data.message;
              $scope.disableUpdateEmailSubmit = false;
            } else {
              $scope.updateUserEmailMessage =
                'Verification Code Sent To New Primary Email';
            }
          })
          .catch(function(err) {
            var data = err.data;
            if (data && data.message) {
              $scope.updateUserEmailErrors = data.message;
            } else {
              $scope.updateUserEmailErrors =
                'There was an issue updating your email. Please try again later';
            }
            $scope.disableUpdateEmailSubmit = false;
          });
      };
      $scope.updateUserName = function() {
        var firstName = $scope.userFirstName;
        var lastName = $scope.userLastName;
        var userData = $scope.userData;
        AdministrationCenterService.updateUserName(
          userData.id,
          firstName,
          lastName
        )
          .then(function(res) {
            var data = res.data;
            if (data.username && data.username != null) {
              $scope.userEmailID = data.username;
              $scope.userFirstName = data.firstName;
              $scope.userLastName = data.lastName;
              $scope.userData = data;
              $scope.userUpdateSuccessMessage =
                'First Name and Last Name has been successfully updated.';
              $scope.userUpdateErrorMessage = '';
            } else if (data.statusCode && data.statusCode === 'ERROR') {
              $scope.userUpdateSuccessMessage = '';
              $scope.userUpdateErrorMessage = data.message;
            }
          })
          .catch(function(err) {
            $scope.userUpdateSuccessMessage = '';
            $scope.userUpdateErrorMessage =
              'Error while updating First Name and Last Name.';
            $log.error('Error while updating User Name.');
          });
      };
      $scope.viewEmailIDUpdateHistory = function() {
        var userData = $scope.userData;
        AdministrationCenterService.getemailIDUpdateHistory(userData.id)
          .then(function(res) {
            var data = res.data;
            $scope.emailUpdateHistoryList = data;
            $('#userEmailHistory').modal();
          })
          .catch(function(err) {
            $log.error('Error fetching Email ID Update History.');
          });
      };
      $scope.showResetUserOptions = function() {
        $('#resetAccount').modal();
      };
      $scope.showChangeEmail = function() {
        $scope.newEmailAddress = '';
        $scope.newEmailConfirm = '';
        $scope.updateUserEmailErrors = '';
        $scope.updateUserEmailMessage = '';
        $scope.disableUpdateEmailSubmit = false;
        $('#changeEmail').modal();
      };
      $scope.resetUserAccount = function() {
        var userData = $scope.userData;
        var email = $scope.userEmailID;
        AdministrationCenterService.resetUserAccountByAdmin(email)
          .then(function(res) {
            var data = res.data;
            $scope.emailUpdateHistoryList = data;
            $('#resetUserAccountCancelBtn').click();
          })
          .catch(function(err) {
            $log.error('Error Resetting user account.');
          });
      };
    }
  ])
  .controller('AccessDeniedCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    function($log, $state, $scope, $location, $rootScope) {
      $log.info('userId :: ' + $state.params.userId);
    }
  ]);
