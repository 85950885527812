// download.service.js - Common File Download Service for the application

'use strict';

angular.module('CfcCharity.common').factory('DownloadService', DownloadService);

DownloadService.$inject = ['TokenStorage', '$http', '$q', 'blockUI'];

/*
 * improved Angular File Download service
 * v1.0.0
 * created by Eric McCormick
 * inspired by:
 * https://github.com/Vovke/angular-file-downloader/blob/master/angular-file-downloader.js
 *
 * preferred use: .then(successHandler, errorHandler) as opposed to
 * .sucess().error(), as they're not supported >= 1.6.0,
 * ref: https://code.angularjs.org/1.5.10/docs/api/ng/service/$http#deprecation-notice
 * refer to Angular 1.x Promise API:
 * https://docs.angularjs.org/api/ng/service/$q#the-promise-api
 */
function DownloadService(TokenStorage, $http, $q, blockUI) {
  // default MIME type
  var contentMimeType = 'text/plain';
  var defaultFileName = 'file';

  // pulls MIME from Content-Type header in response
  function getMimeType(response) {
    return response.headers('Content-Type') || contentMimeType;
  }

  // pulls file name from Content-Disposition header in response
  function getFilenameFromContentDisposition(response) {
    var re = /filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/i;
    if (response.headers('Content-Disposition')) {
      return (
        (response.headers('Content-Disposition').match(re)[1] || '').replace(
          /\"/gi,
          ''
        ) || defaultFileName
      );
    } else {
      return defaultFileName;
    }
  }

  // resolves a handle on the file download, via DOM creation of anchor tag
  function angularElementFileResolver(response) {
    var file = new Blob([response.data], { type: getMimeType(response) });
    var url = window.URL.createObjectURL(file);
    var fileName = getFilenameFromContentDisposition(response);

    if (window.navigator.msSaveOrOpenBlob) {
      // for IE support
      window.navigator.msSaveBlob(file, fileName);
    } else {
      // for *real* browsers, FF/Chrome/etc.
      var anchor = angular.element('<a/>');
      angular.element('body').append(anchor); // FF needs it appended to the body/document for .click
      anchor
        .attr({
          href: url,
          target: '_blank',
          download: fileName
        })[0]
        .click();
      anchor.remove();
    }
    return response;
  }

  function errorHandlingShim() {
    return $q.reject('error response received');
  }

  // utility function to build out default and passed headers
  function configBuilder(headers) {
    var config = {};
    config.responseType = 'arraybuffer';
    if (headers) {
      config.headers = headers;
    } else {
      config.headers = {};
    }
    // includes required authentication headers
    config.headers.xAuthToken = TokenStorage.retrieve();
    return config;
  }

  // performs a GET request
  function getFileDownload(url, mime, additionalHeaders) {
    blockUI.start();
    if (mime) {
      contentMimeType = mime;
    }
    return $http
      .get(url, configBuilder(additionalHeaders))
      .then(angularElementFileResolver, errorHandlingShim)
      .finally(blockUI.stop);
  }

  // performs a POST with no body content, to maintain internal API comaptibility
  function downloadFile(url, mime, additionalHeaders) {
    blockUI.start();
    if (mime) {
      contentMimeType = mime;
    }
    // no POST body this way
    return $http
      .post(url, {}, configBuilder(additionalHeaders))
      .then(angularElementFileResolver, errorHandlingShim)
      .finally(blockUI.stop);
  }

  // return the exposed methods
  return {
    getFileDownload: getFileDownload, // GET
    downloadFile: downloadFile // POST
  };
}
