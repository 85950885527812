(function() {
  'use strict';

  angular
    .module('CfcCharity.admin.zone.appeals')
    .factory('ZoneAppealsService', ZoneAppealsService);

  ZoneAppealsService.$inject = ['CubeSvc', '$http'];

  function ZoneAppealsService(CubeSvc, $http) {
    var getApplicationsInAppeal = function(zoneId, pledgeYear) {
      var url =
        '/CfcCharity/api/private/admin/zone/appeals/' +
        pledgeYear +
        '/' +
        zoneId;
      return CubeSvc.get(url, null, false);
    };

    var updateAppeals = function(appeals, status) {
      return $http.post(
        '/CfcCharity/api/private/charity/appeal/status/bulkUpdate',
        {
          appeals: appeals,
          status: status,
          reason: 'Bulk update'
        }
      );
    };

    return {
      getApplicationsInAppeal: getApplicationsInAppeal,
      updateAppeals: updateAppeals
    };
  }
})();
