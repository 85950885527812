angular
  .module('CfcCharity.systemusers', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      // retrieve active system users
      $stateProvider.state('activesystemusers', {
        url: '/activesystemusers',
        views: {
          main: {
            template: require('../views/private/admin/systemusers.html'),
            controller: 'ActiveSystemUsersCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'System Management',
              crumbRoute: 'systemmanagement',
              crumbParams: '{userId: %userId%}'
            }
          ],
          pageTitle: 'Active System Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          userId: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            if ($stateParams.userId != null) {
              var userId = $stateParams.userId;
              var url =
                '/CfcCharity/api/private/admin/system/users/active/' + userId;
              return $http.get(url).then(function(res) {
                return res.data;
              }); // end function
            } // end if $stateParams.userId not null
          } // end function
        } // end resolve
      }); // end state activesystemusers

      $stateProvider.state('invitedsystemusers', {
        url: '/invitedsystemusers',
        views: {
          main: {
            template: require('../views/private/admin/systemusers.html'),
            controller: 'InvitedSystemUsersCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'System Management',
              crumbRoute: 'systemmanagement',
              crumbParams: '{userId: %userId%}'
            }
          ],
          pageTitle: 'Invited System Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          userId: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            if ($stateParams.userId != null) {
              var userId = $stateParams.userId;
              var url =
                '/CfcCharity/api/private/admin/system/users/invited/' + userId;
              return $http.get(url).then(function(res) {
                return res.data;
              }); // end function
            } // end if $stateParams.userId not null
          } // end function
        } // end resolve
      }); // end state invitedsystemusers
      $stateProvider.state('deletesystemuserroleconfirmation', {
        url: '/deletesystemuserroleconfirmation',
        views: {
          main: {
            template: require('../views/private/admin/deletesystemuserroleconfirmation.html'),
            controller: 'DeleteSystemUserRoleConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Delete System User Role Confirmation',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          userId: null,
          userSystemRoleId: null,
          userFirstName: null,
          userLastName: null,
          role: null
        },
        resolve: {
          deleteSystemUserRoleConfirmationData: function($http, $stateParams) {
            var deleteSystemUserRoleConfirmationData = null;
            if ($stateParams.userId != null) {
              deleteSystemUserRoleConfirmationData = {
                userId: $stateParams.userId,
                userSystemRoleId: $stateParams.userSystemRoleId,
                userFirstName: $stateParams.userFirstName,
                userLastName: $stateParams.userLastName,
                role: $stateParams.role
              };
            } // end if $stateParams.userId not null

            return deleteSystemUserRoleConfirmationData;
          } // end function
        } // end resolve
      }); // end state deletesystemuserconfirmation

      $stateProvider.state('deletesystemuser', {
        url: '/deletesystemuser',
        views: {
          main: {
            template: require('../views/private/admin/systemusers.html'),
            controller: 'ActiveSystemUsersCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'System Management',
              crumbRoute: 'systemmanagement',
              crumbParams: '{userId: %userId%}'
            }
          ],
          pageTitle: 'Active System Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          systemUserAdminData: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            var systemUserAdminData = null;
            if ($stateParams.systemUserAdminData != null) {
              systemUserAdminData = $stateParams.systemUserAdminData;
            } // end if $stateParams.userId not null

            return systemUserAdminData;
          } // end function
        } // end resolve
      }); // end state deletesystemuser

      $stateProvider.state('deletesystemuserinvitationconfirmation', {
        url: '/deletesystemuserinvitationconfirmation',
        views: {
          main: {
            template: require('../views/private/admin/deletesystemuserinvitationconfirmation.html'),
            controller: 'DeleteSystemUserInvitationConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Delete System User Invitation',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          userId: null,
          systemUserInvitationId: null,
          invitedUserId: null,
          invitedUserName: null,
          role: null
        },
        resolve: {
          deleteSystemUserInvitationConfirmationData: function(
            $http,
            $stateParams
          ) {
            var deleteSystemUserInvitationConfirmationData = null;
            if ($stateParams.systemUserInvitationId != null) {
              deleteSystemUserInvitationConfirmationData = {
                userId: $stateParams.userId,
                systemUserInvitationId: $stateParams.systemUserInvitationId,
                invitedUserId: $stateParams.invitedUserId,
                invitedUserName: $stateParams.invitedUserName,
                role: $stateParams.role
              };
            } // end if
            return deleteSystemUserInvitationConfirmationData;
          } // end function
        } // end resolve
      }); // end state deletesystemuserinvitationconfirmation

      $stateProvider.state('deletesystemuserinvitation', {
        url: '/deletesystemuserinvitation',
        views: {
          main: {
            template: require('../views/private/admin/systemusers.html'),
            controller: 'InvitedSystemUsersCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'System Management',
              crumbRoute: 'systemmanagement',
              crumbParams: '{userId: %userId%}'
            }
          ],
          pageTitle: 'Invited System Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          systemUserAdminData: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            var systemUserAdminData = null;
            if ($stateParams.systemUserAdminData != null) {
              systemUserAdminData = $stateParams.systemUserAdminData;
            } // end if
            return systemUserAdminData;
          } // end function
        } // end resolve
      }); // end state deletesystemuserinvitation

      $stateProvider.state('resendsysteminvitation', {
        url: '/resendsystemuserinvitation',
        views: {
          main: {
            template: require('../views/private/admin/resendsysteminvitationsuccess.html'),
            controller: 'ResendSystemUserInvitationCtrl'
          }
        },
        data: {
          pageTitle: 'Invited System Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          userId: null,
          systemUserInvitationId: null,
          invitedUserId: null,
          invitedUserName: null,
          role: null
        },
        resolve: {
          systemUserInvitationData: function($http, $stateParams) {
            var systemUserInvitationData = null;
            if ($stateParams.systemUserInvitationId != null) {
              systemUserInvitationData = {
                userId: $stateParams.userId,
                systemUserInvitationId: $stateParams.systemUserInvitationId,
                invitedUserId: $stateParams.invitedUserId,
                invitedUserName: $stateParams.invitedUserName,
                role: $stateParams.role
              };
            } // end if
            return systemUserInvitationData;
          } // end function
        } // end resolve
      }); // end state resendsysteminvitation

      $stateProvider.state('resendsysteminvitationsuccess', {
        url: '/resendsysteminvitationsuccess',
        views: {
          main: {
            template: require('../views/private/admin/systemusers.html'),
            controller: 'InvitedSystemUsersCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'System Management',
              crumbRoute: 'systemmanagement',
              crumbParams: '{userId: %userId%}'
            }
          ],
          pageTitle: 'Invited System Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          systemUserAdminData: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            var systemUserAdminData = null;
            if ($stateParams.systemUserAdminData != null) {
              systemUserAdminData = $stateParams.systemUserAdminData;
            } // end if
            return systemUserAdminData;
          } // end function
        } // end resolve
      }); // end state resendsysteminvitationsuccess
    } // end function myApp
  ])
  .service('SystemUsersService', [
    '$http',
    function($http) {
      // Inject this service SystemUsersServicein resolve
      this.getActiveSystemUsers = function(userId) {
        return $http.get(
          '/CfcCharity/api/private/admin/system/users/active/' + userId
        );
      };

      this.getInvitedSystemUsers = function(userId) {
        return $http.get(
          '/CfcCharity/api/private/admin/system/users/invited/' + userId
        );
      };

      // service for the add system user role
      this.addSystemUser = function(systemUser) {
        var addSystemUserURI = '/CfcCharity/api/private/admin/system/user/add';
        return $http.post(addSystemUserURI, systemUser);
      };

      // service for the delete system user role
      this.deleteSystemUser = function(deleteSystemUserData) {
        var deleteSystemUserURI =
          '/CfcCharity/api/private/admin/system/user/delete';
        return $http.post(deleteSystemUserURI, deleteSystemUserData);
      };

      // service for the delete system user invitation
      this.deleteSystemUserInvitation = function(
        deleteSystemUserInvitationData
      ) {
        var deleteSystemUserInvitationURI =
          '/CfcCharity/api/private/admin/system/user/invitation/delete';
        return $http.post(
          deleteSystemUserInvitationURI,
          deleteSystemUserInvitationData
        );
      };

      // service for the resend system user invitation
      this.resendSystemUserInvitation = function(systemUserInvitationData) {
        var resendSystemUserInvitationURI =
          '/CfcCharity/api/private/admin/system/user/invitation/resend';
        return $http.post(
          resendSystemUserInvitationURI,
          systemUserInvitationData
        );
      };
    }
  ])
  .controller('ActiveSystemUsersCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$log',
    'systemUserAdminData',
    'SystemUsersService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $log,
      systemUserAdminData,
      SystemUsersService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.systemusers');

      $scope.activeSystemUsersSelected = true;
      $scope.invitedSystemUsersSelected = false;

      if (systemUserAdminData) {
        $scope.systemUserAdminData = systemUserAdminData;
        //  systemUserAdminData.activeSystemUserBeanList =  $filter('orderBy')(systemUserAdminData.activeSystemUserBeanList, "firstName");
      } // end if

      $scope.retrieveActiveSystemUsers = function() {
        var userId = $scope.currentUser.id;
        $scope.activeSystemUsersSelected = true;
        $scope.invitedSystemUsersSelected = false;

        SystemUsersService.getActiveSystemUsers(userId)
          .then(function(res) {
            $scope.systemUserAdminData = res.data;
          })
          .catch(function() {
            toastrService.error(
              'Error while retrieving the active system users '
            );
          });
      }; // end retrieveActiveSystemUsers function

      $scope.retrieveInvitedSystemUsers = function() {
        var userId = $scope.currentUser.id;
        $scope.activeSystemUsersSelected = false;
        $scope.invitedSystemUsersSelected = true;

        SystemUsersService.getInvitedSystemUsers(userId)
          .then(function(res) {
            $scope.systemUserAdminData = res.data;
            $scope.invitedSystemUsersListLimit =
              $scope.systemUserAdminData.invitedSystemUserBeanList.length;
          })
          .catch(function() {
            toastrService.error(
              'Error while retrieving the inivited system users '
            );
          });
      }; // end retrieveInvitedSystemUsers function

      $scope.activeSystemUsersListLimit =
        $scope.systemUserAdminData.activeSystemUserBeanList.length;
      $scope.loadMoreActiveSystemUsers = function() {
        if (
          $scope.activeSystemUsersListLimit <
          $scope.systemUserAdminData.activeSystemUserBeanList.length
        ) {
          $scope.activeSystemUsersListLimit += 100;
        }
      };

      if ($scope.systemUserAdminData.activeSystemUserBeanList) {
      }
      if ($scope.systemUserAdminData.invitedSystemUserBeanList) {
      }
      $scope.invitedSystemUsersListLimit =
        $scope.systemUserAdminData.invitedSystemUserBeanList.length;
      $scope.loadMoreInvitedSystemUsers = function() {
        if (
          $scope.invitedSystemUsersListLimit <
          $scope.systemUserAdminData.invitedSystemUserBeanList.length
        ) {
          $scope.invitedSystemUsersListLimit += 100;
        }
      };

      // Validate and add a system user [also send invitation email to the added user in the server side code]
      $scope.addSystemUser = function() {
        $state.go('addsystemuser', {
          userId: $scope.getCurrentUser().id
        });
      };
    } // end function
  ])
  .controller('InvitedSystemUsersCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$log',
    'systemUserAdminData',
    'SystemUsersService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $log,
      systemUserAdminData,
      SystemUsersService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.systemusers');

      $scope.activeSystemUsersSelected = false;
      $scope.invitedSystemUsersSelected = true;

      if (systemUserAdminData) {
        $scope.systemUserAdminData = systemUserAdminData;
      } // end if

      $scope.retrieveActiveSystemUsers = function() {
        var userId = $scope.currentUser.id;
        $scope.activeSystemUsersSelected = true;
        $scope.invitedSystemUsersSelected = false;

        SystemUsersService.getActiveSystemUsers(userId)
          .then(function(res) {
            var data = res.data;
            $scope.systemUserAdminData = data;
          })
          .catch(function() {
            toastrService.error(
              'Error while retrieving the active system users '
            );
          });
      }; // end retrieveActiveSystemUsers function

      $scope.retrieveInvitedSystemUsers = function() {
        var userId = $scope.currentUser.id;
        $scope.activeSystemUsersSelected = false;
        $scope.invitedSystemUsersSelected = true;

        SystemUsersService.getInvitedSystemUsers(userId)
          .then(function(res) {
            var data = res.data;
            $scope.systemUserAdminData = data;
          })
          .catch(function(err) {
            toastrService.error(
              'Error while retrieving the inivited system users '
            );
          });
      }; // end retrieveInvitedSystemUsers function

      // Validate and add a system user [also send invitation email to the added user in the server side code]
      $scope.addSystemUser = function() {
        $state.go('addsystemuser', {
          userId: $scope.getCurrentUser().id
        });
      };
    } // end function;
  ])
  .controller('DeleteSystemUserRoleConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'deleteSystemUserRoleConfirmationData',
    'SystemUsersService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      deleteSystemUserRoleConfirmationData,
      SystemUsersService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.systemusers');

      $scope.deleteSystemUserRoleConfirmationData = deleteSystemUserRoleConfirmationData;

      $scope.deleteSystemUser = function() {
        //  var userId = deleteSystemUserConfirmationData.userId;
        //   var userSystemRoleId = deleteSystemUserConfirmationData.userSystemRoleId;

        var deleteSystemUserData = {
          userId: $scope.deleteSystemUserRoleConfirmationData.userId,
          userSystemRoleId:
            $scope.deleteSystemUserRoleConfirmationData.userSystemRoleId
        };
        SystemUsersService.deleteSystemUser(deleteSystemUserData)
          .then(function(res) {
            var data = res.data;

            var deleteSystemUserResponse = data;

            if (
              deleteSystemUserResponse.error === true ||
              deleteSystemUserResponse.statusCode === 'ERROR'
            ) {
              $scope.showDeleteSystemUserErrorMessage(
                deleteSystemUserResponse.errorResponse.errorCode +
                  ' : ' +
                  deleteSystemUserResponse.errorResponse.errorMessage +
                  '. Please try again later!'
              );
              return;
            }

            // if no errors - display active system users list
            var systemUserAdminData =
              deleteSystemUserResponse.systemUserAdminBean;
            $state.go('deletesystemuser', {
              systemUserAdminData: systemUserAdminData
            });
          })
          .catch(function(err) {
            $scope.showDeleteSystemUserErrorMessage(
              'Error while deleting a system user. Please try again later!'
            );
          });
      }; // end deleteSystemUser function

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      $scope.showDeleteSystemUserErrorMessage = function(error) {
        $scope.deleteSystemUserErrors = error;
      };
    } // end function
  ])
  .controller('DeleteSystemUserInvitationConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'deleteSystemUserInvitationConfirmationData',
    'SystemUsersService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      deleteSystemUserInvitationConfirmationData,
      SystemUsersService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.systemusers');

      $scope.deleteSystemUserInvitationConfirmationData = deleteSystemUserInvitationConfirmationData;

      $scope.deleteSystemUserInvitation = function() {
        var deleteSystemUserInvitationData = {
          userId: $scope.deleteSystemUserInvitationConfirmationData.userId,
          systemUserInvitationId:
            $scope.deleteSystemUserInvitationConfirmationData
              .systemUserInvitationId
        };
        SystemUsersService.deleteSystemUserInvitation(
          deleteSystemUserInvitationData
        )
          .then(function(res) {
            var data = res.data;

            var deleteSystemUserInvitationResponse = data;

            if (
              deleteSystemUserInvitationResponse.error === true ||
              deleteSystemUserInvitationResponse.statusCode === 'ERROR'
            ) {
              $scope.showDeleteSystemUserInvitationErrorMessage(
                deleteSystemUserInvitationResponse.errorResponse.errorCode +
                  ' : ' +
                  deleteSystemUserInvitationResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
              return;
            }

            // if no errors - display invited system users list
            var systemUserAdminData =
              deleteSystemUserInvitationResponse.systemUserAdminBean;
            $state.go('deletesystemuserinvitation', {
              systemUserAdminData: systemUserAdminData
            });
          })
          .catch(function(err) {
            $scope.showDeleteSystemUserErrorMessage(
              'Error while deleting a system user invitation. Please try again later'
            );
          });
      }; // end deleteSystemUserInvitation function

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      $scope.showDeleteSystemUserInvitationErrorMessage = function(error) {
        $scope.deleteSystemUserInvitationErrors = error;
      };
    } // end function
  ])
  .controller('ResendSystemUserInvitationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'systemUserInvitationData',
    'SystemUsersService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      systemUserInvitationData,
      SystemUsersService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.systemusers');

      $scope.systemUserInvitationData = systemUserInvitationData;

      if (
        $scope.systemUserInvitationData !== null &&
        $scope.systemUserInvitationData !== undefined &&
        $scope.systemUserInvitationData.systemUserInvitationId !== null &&
        $scope.systemUserInvitationData.systemUserInvitationId !== undefined
      ) {
        SystemUsersService.resendSystemUserInvitation(
          $scope.systemUserInvitationData
        )
          .then(function(res) {
            var data = res.data;

            var resendSystemUserInvitationResponse = data;

            if (
              resendSystemUserInvitationResponse.error === true ||
              resendSystemUserInvitationResponse.statusCode === 'ERROR'
            ) {
              $scope.showResendSystemUserInvitationErrorMessage(
                resendSystemUserInvitationResponse.errorResponse.errorCode +
                  ' : ' +
                  resendSystemUserInvitationResponse.errorResponse
                    .errorMessage +
                  '. Please try later'
              );
              //   return;
            } else {
              // if no errors - display invited system users list
              $scope.systemUserAdminData =
                resendSystemUserInvitationResponse.systemUserAdminBean;
            }
          })
          .catch(function(err) {
            $scope.showResendSystemUserInvitationErrorMessage(
              'Error while resending a system user invitation. Please try again later'
            );
          });
      } // end if

      $scope.displayInvitedSystemUsers = function(systemUserAdminData) {
        if (systemUserAdminData) {
          $state.go('resendsysteminvitationsuccess', {
            systemUserAdminData: systemUserAdminData
          });
        } else {
          $state.go('invitedsystemusers', { userId: $scope.currentUser.id });
        }
      }; // end displayInvitedSystemUsers function

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      // change the show to set
      $scope.showResendSystemUserInvitationErrorMessage = function(error) {
        $scope.resendSystemUserInvitationErrors = error;
      };
    } // end function
  ]);
