'use strict';
(function() {
  angular
    .module('CfcCharity.admin.emailDashboard', [])
    .config([
      '$stateProvider',
      'USER_ROLES',
      'SYSTEM_USER_SECONDARY_ROLES',
      config
    ])
    .controller('EmailDashboardController', EmailDashboardController);

  function config($stateProvider, USER_ROLES, SYSTEM_USER_SECONDARY_ROLES) {
    $stateProvider.state('charity-email-dashboard', {
      url: '/charity-email-dashboard',
      views: {
        main: {
          template: require('./emailDashboard.html'),
          controller: 'EmailDashboardController'
        }
      },
      data: {
        pageTitle: 'Admin Email Dashboard',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [
          SYSTEM_USER_SECONDARY_ROLES.opm_admin,
          SYSTEM_USER_SECONDARY_ROLES.opm_staff
        ]
      },
      params: {
        cfcCode: null
      },
      resolve: {
        parameterData: function($stateParams) {
          return {
            cfcCode: $stateParams.cfcCode
          };
        }
      }
    });
  }

  EmailDashboardController.$inject = [
    '$log',
    '$scope',
    'blockUI',
    'parameterData',
    'CharityEmailDashboardService',
    'charityEmailType',
    'toastrService'
  ];

  function EmailDashboardController(
    $log,
    $scope,
    blockUI,
    parameterData,
    CharityEmailDashboardService,
    charityEmailType,
    toastrService
  ) {
    function init() {
      getCharityEmailDashboardData();
    }

    $scope.viewCharityEmail = function(charityEmail) {
      $scope.selectedCharityEmail = charityEmail;
      angular.element('#viewCharityEmail').modal('show');
    };

    $scope.resendCharityEmail = function() {
      CharityEmailDashboardService.resendCharityEmail(
        $scope.selectedCharityEmail.id
      ).then(function(response) {
        if (response.data === true) {
          toastrService.success('Charity email resent successfully');
          getCharityEmailDashboardData();
        }
      });

      dispose();
    };

    $scope.cancelResendCharityEmail = function() {
      dispose();
    };

    function dispose() {
      $scope.selectedCharityEmail = null;
      angular.element('#viewCharityEmail').modal('hide');
    }

    function getCharityEmailDashboardData() {
      blockUI.start();
      CharityEmailDashboardService.getCharity(parameterData.cfcCode)
        .then(function(response) {
          $scope.charity = response.data;
          CharityEmailDashboardService.getCharityEmailsByType(
            $scope.charity.cfcCode,
            charityEmailType.InvalidBankingInfo
          )
            .then(function(response) {
              $scope.charityEmails = response.data;
            })
            .catch(function(er) {
              $log.error(er);
            });
        })
        .catch(function(er) {
          $log.error(er);
        })
        .finally(function() {
          blockUI.stop();
        });
    }

    init();
  }
})();
