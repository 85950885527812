/*
 * donations.module.js - Module declaration for Donations page (and summary in service)
 *
 * warning: there is specific authorization logic per pledgeYear/campaign and CFC
 * this is due to the change in fed/member/ind between years and individual access
 */

'use strict';

(function() {
  angular
    .module('CfcCharity.donations', [
      'ui.router',
      'CfcCharity.security',
      'hateoas',
      'CfcCharity.common'
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      'HateoasInterceptorProvider',
      'USER_ROLES',
      'SYSTEM_USER_SECONDARY_ROLES',
      config
    ]);

  function config(
    $stateProvider,
    $urlRouterProvider,
    HateoasInterceptorProvider,
    USER_ROLES,
    SYSTEM_USER_SECONDARY_ROLES
  ) {
    $stateProvider
      .state('charityDonations', {
        url: '/charityDonations',
        views: {
          main: {
            template: require('./donations.html'),
            controller: 'DonationsCtl',
            controllerAs: 'vm'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Charity #%cfcCode% Dashboard',
              crumbRoute: 'charitydashboard',
              crumbParams:
                '{charityDashboardCfcCode: %cfcCode%, pledgeYear: %pledgeYear%}'
            }
          ],
          pageTitle: 'Donations',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          appType: null,
          cfcCode: null,
          charityName: null,
          pledgeYear: null
        },
        resolve: {
          parameterData: function($stateParams, $state) {
            return {
              appType: $stateParams.appType, // INDEPENDENT/MEMBER/FEDERATION
              cfcCode: $stateParams.cfcCode,
              charityName: $stateParams.charityName,
              pledgeYear: $stateParams.pledgeYear,
              previousPage: {
                name: $state.current.name,
                params: $state.params
              }
            };
          },
          tokenData: function($stateParams, BreadcrumbTokenDataService) {
            return BreadcrumbTokenDataService.setTokenData({
              cfcCode: $stateParams.cfcCode,
              pledgeYear: $stateParams.pledgeYear
            });
          }
        }
      })
      .state('memberDonations', {
        url: '/memberDonations',
        views: {
          main: {
            template: require('./member/memberDonations.html'),
            controller: 'MemberDonationsCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Charity #%cfcCode% Dashboard',
              crumbRoute: 'charitydashboard',
              crumbParams:
                '{charityDashboardCfcCode: %cfcCode%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: 'Federation Donations',
              crumbRoute: 'charityDonations',
              crumbParams:
                "{cfcCode: %parentCfcCode%, pledgeYear: %pledgeYear%, appType:'Federation', charityName: '%parentFedName%'}"
            }
          ],
          pageTitle: 'Member Donations',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          parentCfcCode: null,
          parentFedName: null,
          pledgeYear: null, // 4-digit year
          campaignId: null
        },
        resolve: {
          tokenData: function($stateParams, BreadcrumbTokenDataService) {
            return BreadcrumbTokenDataService.setTokenData({
              parentCfcCode: $stateParams.parentCfcCode,
              pledgeYear: $stateParams.pledgeYear,
              parentFedName: $stateParams.parentFedName
            });
          }
        }
      })
      .state('disbursement', {
        url: '/disbursement',
        views: {
          main: {
            template: require('./disbursement/disbursement.html'),
            controller: 'DisbursementCtl',
            controllerAs: 'vm'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Charity #%cfcCode% Dashboard',
              crumbRoute: 'charitydashboard',
              crumbParams:
                '{charityDashboardCfcCode: %cfcCode%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: 'Donations',
              crumbRoute: 'charityDonations',
              crumbParams:
                "{cfcCode: %cfcCode%, pledgeYear: %pledgeYear%, appType: '%appType%', charityName: '%charityName%'}"
            }
          ],
          pageTitle: 'Disbursement',
          requireAuthentication: true,
          // requires additional set of checks for authorization to access each pledgeYear's donations
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          appType: null,
          cfcCode: null,
          year: null,
          month: null,
          campaignId: null,
          disbursementDate: null,
          disbursementAmount: null,
          isFederation: null,
          charityName: null
        },
        resolve: {
          tokenData: function($stateParams, BreadcrumbTokenDataService) {
            BreadcrumbTokenDataService.setIsFederation(
              $stateParams.isFederation
            );
            return BreadcrumbTokenDataService.setTokenData({
              cfcCode: $stateParams.cfcCode,
              pledgeYear: $stateParams.pledgeYear,
              appType: $stateParams.appType,
              charityName: $stateParams.charityName
            });
          }
        }
      });
  }
})();
