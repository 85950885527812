'use strict';

angular
  .module('CfcCharity.admin.system')
  .controller('SystemJobsHistoryCtl', SystemJobsHistoryCtl);

SystemJobsHistoryCtl.$inject = [
  '$scope',
  'AdminJobsService',
  '$stateParams',
  'blockUI'
];

function SystemJobsHistoryCtl($scope, AdminJobsService, $stateParams, blockUI) {
  function init() {
    $scope.jobId = $stateParams.jobId;
    $scope.campaignYear = $stateParams.campaignYear;
    $scope.displayName = $stateParams.displayName;
    getJobHistory($scope.jobId);
  }

  function getJobHistory(jobId) {
    blockUI.start();
    AdminJobsService.transferCharityDataHistory(jobId)
      .then(function(res) {
        $scope.jobHistory = res.data;
      })
      .finally(function() {
        blockUI.stop();
      });
  }

  $scope.calcDuration = function(startTime, endTime) {
    var duration = endTime - startTime;
    var seconds = parseInt((duration / 1000) % 60),
      minutes = parseInt((duration / (1000 * 60)) % 60),
      hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
  };

  init();
}
