// appeal.module.js - Module declaration for Admin Appeal Dashboard page

'use strict';

(function() {
  angular
    .module('CfcCharity.admin.review.appeal', [
      'ui.router',
      'CfcCharity.security',
      'hateoas',
      'CfcCharity.review.appeal'
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      'HateoasInterceptorProvider',
      'USER_ROLES',
      'SYSTEM_USER_SECONDARY_ROLES',
      config
    ])
    .constant('DeterminationLevels', {
      CFCSpecialist: 'CFC_SPECIALIST',
      CFCDirector: 'CFC_DIRECTOR',
      OPMDirector: 'OPM_DIRECTOR'
    })
    .filter('determinationStatusToLabel', [
      '$filter',
      function($filter) {
        return function(input) {
          input = input || '';
          var nwVal = input.replace(/_/g, ' '); // remove underscores
          var tmpAr = nwVal.split(' '); // on spaces (we care about first one)
          var nwAr = [];
          nwAr.push(tmpAr[0]);
          for (var i = 1; i < tmpAr.length; i++) {
            // iterate all but first (CFC)
            var tmp = (tmpAr[i] || '').toLowerCase(); // enforce lower case
            var nwTmp = tmp.substring(0, 1).toUpperCase() + tmp.substring(1); // capitalize first letter
            nwAr.push(nwTmp);
          }

          return nwAr.join(' '); // put it all back together into a single string
        };
      }
    ]);

  function config(
    $stateProvider,
    $urlRouterProvider,
    HateoasInterceptorProvider,
    USER_ROLES,
    SYSTEM_USER_SECONDARY_ROLES
  ) {
    $stateProvider
      .state('admin-appeal-dashboard', {
        url: '/admin-appeal-dashboard',
        views: {
          main: {
            template: require('./appeal.html'),
            controller: 'AdminAppealCtl',
            controllerAs: 'vm'
          }
        },
        data: {
          breadcrumbs: function(ApplicationService, isFed, type) {
            if (isFed === 'false') {
              if (
                ApplicationService.getApplication().federationId === null ||
                ApplicationService.getApplication().federationNumber === null ||
                type === 'INDEPENDENT'
              ) {
                return [
                  {
                    crumbName: '%zoneCode% Applications',
                    crumbRoute: 'zoneApplications',
                    crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                  },
                  {
                    crumbName: 'reviewLevel',
                    crumbRoute: 'zonecompletenessreview',
                    crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  },
                  {
                    crumbName: 'Application Review',
                    crumbRoute: 'charityAuditDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName: '%zoneCode% Applications',
                    crumbRoute: 'zoneApplications',
                    crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                  },
                  {
                    crumbName: 'reviewLevel',
                    crumbRoute: 'zonecompletenessreview',
                    crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName: '%pledgeYear% #%cfcCode% Member Application',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  },
                  {
                    crumbName: 'Application Review',
                    crumbRoute: 'charityAuditDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            } else {
              if (isFed !== undefined) {
                return [
                  {
                    crumbName: '%zoneCode% Applications',
                    crumbRoute: 'zoneApplications',
                    crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                  },
                  {
                    crumbName: 'reviewLevel',
                    crumbRoute: 'zonecompletenessreview',
                    crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Federation Application',
                    crumbRoute: 'federationDashboard',
                    crumbParams: '{federationId: %applicationId%}'
                  },
                  {
                    crumbName: 'Application Review',
                    crumbRoute: 'federationapplicationreview',
                    crumbParams: '{federationApplicationId: %applicationId%}'
                  }
                ];
              } else {
                return null;
              }
            }
          },
          pageTitle: '',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          applicationType: null,
          applicationId: null
        },
        resolve: {
          parameterData: function($stateParams) {
            return {
              applicationType: $stateParams.applicationType,
              applicationId: $stateParams.applicationId
            };
          },
          tokenData: function($stateParams, BreadcrumbTokenDataService) {
            var isFed =
              'FEDERATION' ===
              ($stateParams.applicationType || '').toUpperCase();
            BreadcrumbTokenDataService.setIsFederation(isFed);
            return BreadcrumbTokenDataService.getReviewLevel(
              $stateParams.applicationId,
              isFed
            ).then(function(res1) {
              if (isFed) {
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  $stateParams.applicationId
                );
              } else {
                return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                  $stateParams.applicationId
                );
              }
            });
          }
        }
      })
      .state('admin-appeal-dashboard.full', {
        url: '/full',
        views: {
          embed: {
            template: require('../../../review/appeal.html'),
            controller: 'AppealCtrl',
            controllerAs: 'vm'
          }
        },
        data: {
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          isFederation: null,
          applicationId: null
        },
        resolve: {
          parameterData: function($stateParams) {
            return {
              showAdminAppealControls: true,
              isFederation: $stateParams.isFederation,
              applicationId: $stateParams.applicationId || null
            };
          },
          tokenData: function($stateParams, BreadcrumbTokenDataService) {
            var isFed = $stateParams.isFederation === 'true';
            BreadcrumbTokenDataService.setIsFederation(isFed);
            return BreadcrumbTokenDataService.getReviewLevel(
              $stateParams.applicationId,
              isFed
            ).then(function(res1) {
              if (isFed) {
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  $stateParams.applicationId
                );
              } else {
                return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                  $stateParams.applicationId
                );
              }
            });
          }
        }
      });
  }
})();
