(function() {
  var moduleName = 'CfcCharity.common.authorizationHelper';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    module = angular.module(moduleName, []);
  }

  module.service('AuthorizationHelper', [
    '$log',
    'BrowserEventService',
    function($log, BrowserEventService) {
      function userHasAnyRole(rolesArray) {
        if (rolesArray == null || !Array.isArray(rolesArray)) {
          $log.error(
            'AuthorizationHelper.userHasRole() - param was null or not an array.'
          );
          return false;
        }

        var currentUserRoles = getCurrentUserRoles();

        var result = false;
        angular.forEach(rolesArray, function(role) {
          if (result) {
            return;
          }
          result = currentUserRoles.indexOf(role) !== -1;
        });

        return result;
      }

      function userHasAllRoles(rolesToValidateAgainst) {
        if (
          rolesToValidateAgainst == null ||
          !Array.isArray(rolesToValidateAgainst)
        ) {
          $log.error(
            'AuthorizationHelper.userContainsAllRoles() - param was not an array.'
          );
          return false;
        }
        if (rolesToValidateAgainst.length === 0) {
          return false;
        }

        var currentUserRoles = getCurrentUserRoles();

        return rolesToValidateAgainst.every(function(value) {
          return currentUserRoles.indexOf(value) !== -1;
        });
      }

      // private
      function getCurrentUserRoles() {
        var user = BrowserEventService.getCurrentUser();

        if (user === null) {
          $log.error('BrowserEventService.getCurrentUser() returned null.');
          return [];
        }

        var currentUserRoles = [];

        if (Array.isArray(user.roles)) {
          angular.forEach(user.roles, function(role) {
            currentUserRoles.push(role);
          });
        }

        if (Array.isArray(user.secondaryRoleList)) {
          angular.forEach(user.secondaryRoleList, function(role) {
            currentUserRoles.push(role);
          });
        }

        return currentUserRoles;
      }

      return {
        userHasAnyRole: userHasAnyRole,
        userHasAllRoles: userHasAllRoles
      };
    }
  ]);
})();
