(function() {
  var moduleName = 'CfcCharity.common.zoneService';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    module = angular.module(moduleName, []);
  }

  module.service('ZoneService', [
    '$log',
    '$http',
    '$q',
    function($log, $http, $q) {
      function getListingOverrideZones(charityType, year) {
        if (!charityType) {
          return $q.reject([]);
        }
        var deferred = $q.defer();
        var getZones = getYearsAndZonesActList()
          .then(function(res) {
            var zoneBeanList = res.data.zoneBeanList;
            var zones = [];
            angular.forEach(zoneBeanList, function(czData) {
              angular.forEach(czData.pledgeYear, function(cz) {
                if (cz.toString() === year.toString()) {
                  zones.push(czData);
                }
              });
            });
            return zones.filter(function(zone) {
              switch (charityType) {
                case 'LOCAL':
                  switch (zone.zoneCode) {
                    case 'DOD':
                    case 'NAT':
                    case 'INT':
                    case 'FED':
                      return false;
                    default:
                      return true;
                  }
                case 'DOD':
                  switch (zone.zoneCode) {
                    case 'NAT':
                    case 'INT':
                    case 'FED':
                      return false;
                    default:
                      return true;
                  }
                case 'NATIONAL':
                case 'INTERNATIONAL':
                  return false;
                default:
                  return false;
              }
            });
          })
          .catch(function(err) {
            $log.error(
              err.data || 'Request failed with status ' + err.status,
              err
            );
            deferred.reject();
          });

        deferred.resolve(getZones);
        return deferred.promise;
      }

      function getYearsAndZonesActList() {
        var url = '/CfcCharity/api/private/admin/zone/yearsAndZonesAct';
        return $http.get(url);
      }

      return {
        getListingOverrideZones: getListingOverrideZones,
        getYearsAndZonesActList: getYearsAndZonesActList
      };
    }
  ]);
})();
