angular
  .module('CfcCharity.creditcardtransactions', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('creditcardtransactions', {
        url: '/creditcardtransactions',
        views: {
          main: {
            template: require('../views/private/finance/creditcardtransactions.html'),
            controller: 'CreditCardTransactionsCtrl'
          }
        },
        data: {
          pageTitle: 'Credit Card Transactions',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          creditCardTransactionsData: null
        }
      });
    }
  ])
  .service('CreditCardTransactionsService', [
    '$http',
    '$filter',
    function($http, $filter) {
      this.getCreditCardTransactionDetails = function(userId) {
        var url =
          '/CfcCharity/api/private/finance/creditcardtransactiondetails' +
          userId;
        return $http.get(url);
      }; // end getCreditCardTransactionDetails function

      this.downloadCreditCardTransactionsCreateActivity = function(
        financialTransactionsRequest
      ) {
        var url =
          '/CfcCharity/api/private/finance/downloadcreditcardtransactions/activity/create';
        return $http.post(url, financialTransactionsRequest);
      }; // end downloadCreditCardTransactionsCreateActivity function

      this.prepareCreditCardTransactionsForDownload = function(
        creditCardTransactionsResultsBeanListForDownload
      ) {
        var downloadCreditCardTransactionsResultsBeanList = creditCardTransactionsResultsBeanListForDownload;
        if (downloadCreditCardTransactionsResultsBeanList) {
          for (
            var i = 0;
            i < downloadCreditCardTransactionsResultsBeanList.length;
            i++
          ) {
            delete downloadCreditCardTransactionsResultsBeanList[i].id;
            delete downloadCreditCardTransactionsResultsBeanList[i].type;
            delete downloadCreditCardTransactionsResultsBeanList[i].$$hashKey;
            downloadCreditCardTransactionsResultsBeanList[
              i
            ].transactionDate = $filter('date')(
              downloadCreditCardTransactionsResultsBeanList[i].transactionDate,
              'MM/dd/yyyy HH:mm:ss'
            );
            downloadCreditCardTransactionsResultsBeanList[i].cfcFee = $filter(
              'currency'
            )(downloadCreditCardTransactionsResultsBeanList[i].cfcFee, '$');
            downloadCreditCardTransactionsResultsBeanList[
              i
            ].processingFee = $filter('currency')(
              downloadCreditCardTransactionsResultsBeanList[i].processingFee,
              '$'
            );
            downloadCreditCardTransactionsResultsBeanList[
              i
            ].totalAmount = $filter('currency')(
              downloadCreditCardTransactionsResultsBeanList[i].totalAmount,
              '$'
            );

            if (
              downloadCreditCardTransactionsResultsBeanList[i].paymentSuccessful
            ) {
              downloadCreditCardTransactionsResultsBeanList[
                i
              ].paymentSuccessful = 'Pass';
            } else if (
              !downloadCreditCardTransactionsResultsBeanList[i]
                .paymentSuccessful
            ) {
              downloadCreditCardTransactionsResultsBeanList[
                i
              ].paymentSuccessful = 'Fail';
            } // end else

            if (
              downloadCreditCardTransactionsResultsBeanList[i]
                .ccNumberLastFourDigits
            ) {
              downloadCreditCardTransactionsResultsBeanList[
                i
              ].ccNumberLastFourDigits = downloadCreditCardTransactionsResultsBeanList[
                i
              ].ccNumberLastFourDigits.toString();
            }
          } // end for loop
        } // end if
        return downloadCreditCardTransactionsResultsBeanList;
      }; // end prepareCreditCardTransactionsForDownload function
    } // end CreditCardTransactionsService function
  ])
  .controller('CreditCardTransactionsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'CreditCardTransactionsService',
    'FinanceTransactionsService',
    'BrowserEventService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      CreditCardTransactionsService,
      FinanceTransactionsService,
      BrowserEventService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.creditcardtransactions');
      $('.modal-backdrop').remove();

      $scope.creditCardTransactionsData =
        $state.params.creditCardTransactionsData;

      if ($scope.creditCardTransactionsData) {
        BrowserEventService.setCreditCardTransactionsData(
          $scope.creditCardTransactionsData
        );
        $scope.creditCardTransactionsData.downloadCreditCardTransactionsFileName =
          '';

        $scope.currentDate = $filter('date')(new Date(), 'MMddyyyy');

        if (
          $scope.creditCardTransactionsData.listingsSelected &&
          $scope.creditCardTransactionsData.applicationsSelected
        ) {
          $scope.creditCardTransactionsData.downloadCreditCardTransactionsFileName =
            'CreditCard_All_Transactions_' + $scope.currentDate + '.csv';
        } else if (
          $scope.creditCardTransactionsData.listingsSelected &&
          !$scope.creditCardTransactionsData.applicationsSelected
        ) {
          $scope.creditCardTransactionsData.downloadCreditCardTransactionsFileName =
            'CreditCard_Listings_Transactions_' + $scope.currentDate + '.csv';
        } else if (
          !$scope.creditCardTransactionsData.listingsSelected &&
          $scope.creditCardTransactionsData.applicationsSelected
        ) {
          $scope.creditCardTransactionsData.downloadCreditCardTransactionsFileName =
            'CreditCard_Applications_Transactions_' +
            $scope.currentDate +
            '.csv';
        }

        $scope.creditCardTransactionsData.downloadCreditCardTransactionDetailBeanList = {};

        if (
          $scope.creditCardTransactionsData
            .creditCardTransactionsResultsBeanList
        ) {
          var creditCardTransactionsResultsBeanListForDownload = angular.copy(
            $scope.creditCardTransactionsData
              .creditCardTransactionsResultsBeanList
          );
          $scope.downloadCreditCardTransactionsResultsBeanList = CreditCardTransactionsService.prepareCreditCardTransactionsForDownload(
            creditCardTransactionsResultsBeanListForDownload
          );
        } // end if $scope.creditCardTransactionsData.creditCardTransactionsResultsBeanList
      } // end if $scope.creditCardTransactionsData

      var error = '';
      var userId = $scope.getCurrentUser().id;

      if (!$scope.creditCardTransactionsData) {
        error =
          'Cannot retrieve the credit card transactions for the user id : ' +
          userId +
          'Please try later';
        $scope.showCreditCardTransactionsErrorMessage(error);
      }

      $scope.creditCardTransactionsLimit = 100;
      $scope.loadMoreCreditCardTransactions = function() {
        if (
          $scope.creditCardTransactionsData &&
          $scope.creditCardTransactionsData
            .creditCardTransactionsResultsBeanList &&
          $scope.creditCardTransactionsLimit <
            $scope.creditCardTransactionsData
              .creditCardTransactionsResultsBeanList.length
        ) {
          $scope.creditCardTransactionsLimit += 100;
        }
      }; // end loadMoreCreditCardTransactions function

      $scope.gotToUserResults = function(paidBy) {
        $('.modal-backdrop').remove();
        $state.go('userSearchResults', {
          userId: paidBy
        });
      };
      $scope.gotoCharityDashboard = function(cfcCode) {
        $('.modal-backdrop').remove();
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };

      $scope.downloadCreditCardTransactionsCreateActivity = function() {
        var financialTransactionsBean = {
          userId: userId
        };

        var financialTransactionsRequest = {
          financialTransactionsBean: financialTransactionsBean
        };

        if (financialTransactionsRequest) {
          var error =
            'Error occured while download the credit card transactions create activity';
          CreditCardTransactionsService.downloadCreditCardTransactionsCreateActivity(
            financialTransactionsRequest
          )
            .then(function(res) {
              var data = res.data;
              var downloadCreditCardTransactionsCreateActivityResponse = data;

              if (
                downloadCreditCardTransactionsCreateActivityResponse &&
                (downloadCreditCardTransactionsCreateActivityResponse.error ||
                  downloadCreditCardTransactionsCreateActivityResponse.statusCode ===
                    'ERROR')
              ) {
                $scope.showDownloadCreditCardTransactionsCreateActivityErrorMessage(
                  error
                );
              } else if (
                downloadCreditCardTransactionsCreateActivityResponse &&
                downloadCreditCardTransactionsCreateActivityResponse.statusCode ===
                  'SUCCESS'
              ) {
                $scope.showDownloadCreditCardTransactionsCreateActivityErrorMessage(
                  ''
                );
              }
            })
            .catch(function(err) {
              $scope.showDownloadCreditCardTransactionsCreateActivityErrorMessage(
                error
              );
            }); // end error
        } else {
          $scope.showDownloadCreditCardTransactionsCreateActivityErrorMessage(
            error
          );
        } // end else
      }; // end downloadCreditCardTransactionsCreateActivity function

      $scope.showCreditCardTransactionsErrorMessage = function(error) {
        $scope.creditCardTransactionsnError = error;
      };

      $scope.retrieveCCPaymentDetails = function(
        userId,
        creditCardTransactionDetail
      ) {
        if (creditCardTransactionDetail) {
          var financialTransactionsBean = {
            userId: userId,
            id: creditCardTransactionDetail.id,
            type: creditCardTransactionDetail.type,
            feeType: creditCardTransactionDetail.feeType
          };

          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };

          FinanceTransactionsService.retrieveCCPaymentDetails(
            financialTransactionsRequest
          )
            .then(function(res) {
              var data = res.data;
              var ccPaymentDetailsResponse = data;

              var error =
                'Error occured while retrieving the Credit Card payment details ';
              if (
                ccPaymentDetailsResponse &&
                (ccPaymentDetailsResponse.error ||
                  ccPaymentDetailsResponse.statusCode === 'ERROR')
              ) {
                /*   $scope.showCCPaymentDetailsErrorMessage(ccPaymentDetailsResponse.errorResponse.errorCode + " : " + ccPaymentDetailsResponse.errorResponse.errorMessage + ". Please try later");
                 */

                $scope.showCCPaymentDetailsErrorMessage(error);
              } else if (
                ccPaymentDetailsResponse &&
                ccPaymentDetailsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.showCCPaymentDetailsErrorMessage('');
                $scope.creditCardTransactionDetailsData =
                  ccPaymentDetailsResponse.financialTransactionsBean.creditCardPaymentDetailsBean;
                $scope.creditCardTransactionDetailsData.paymentType =
                  'Credit Card';
              }
              $('#ccPaymentDetails').modal();
            })
            .catch(function(err) {
              $scope.showCCPaymentDetailsErrorMessage(error);
            }); // end error
        } else {
          var error =
            'Could not retrieve the Credit Card payment details. Please try later';
          $scope.showCCPaymentDetailsErrorMessage(error);
        } // end else
      }; // end retrieveCCPaymentDetails function

      $scope.showCCPaymentDetailsErrorMessage = function(error) {
        $scope.ccPaymentDetailsError = error;
      };

      $scope.showDownloadCreditCardTransactionsCreateActivityErrorMessage = function(
        error
      ) {
        $scope.downloadCreditCardTransactionsCreateActivityError = error;
      };
    } // end CreditCardTransactionsCtrl controller
  ]);
