// system.service.js - Services for the Admin System module

'use strict';

angular
  .module('CfcCharity.admin.system')
  .factory('AdminJobsService', AdminJobsService);

AdminJobsService.$inject = ['$http'];

function AdminJobsService($http) {
  var service = {
    transferCharityData: transferCharityData,
    transferCharityDataStatus: transferCharityDataStatus,
    transferCharityDataHistory: transferCharityDataHistory,
    transferCharityDataTypes: transferCharityDataTypes,
    transferCharityDataSetStatus: transferCharityDataSetStatus,
    getUserById: getUserById,
    getLastCompletedDate: getLastCompletedDate,
    getLastCompletedDateTime: getLastCompletedDateTime
  };

  return service;

  function transferCharityData(id, runFromDate) {
    var url =
      '/CfcCharity/api/private/ena/integration/transferCharityData/' + id;
    return $http.post(url, { runFromDate: runFromDate });
  }

  function transferCharityDataStatus(id) {
    var url =
      '/CfcCharity/api/private/ena/integration/transferCharityData/status/' +
      id;
    return $http.get(url);
  }

  function transferCharityDataHistory(id) {
    var url =
      '/CfcCharity/api/private/ena/integration/transferCharityData/history/' +
      id;
    return $http.get(url);
  }

  function transferCharityDataTypes() {
    var url = '/CfcCharity/api/private/ena/integration/jobs/types';
    return $http.get(url);
  }

  function transferCharityDataSetStatus(jobHistoryId, status) {
    var url =
      '/CfcCharity/api/private/jobs/history/update/' +
      jobHistoryId +
      '/' +
      status;
    return $http.post(url);
  }

  function getUserById(userId) {
    var url = '/CfcCharity/api/private/users/' + userId;
    return $http.get(url);
  }

  function getLastCompletedDate(jobId) {
    return $http({
      method: 'GET',
      url: '/CfcCharity/api/private/jobs/history/lastcompleted/' + jobId,
      transformResponse: function(response) {
        return response;
      }
    });
  }

  function getLastCompletedDateTime(jobTypeId) {
    return $http({
      method: 'GET',
      url:
        '/CfcCharity/api/private/jobs/history/lastcompleteddatetime/' +
        jobTypeId,
      transformResponse: function(response) {
        return response;
      }
    });
  }
}
