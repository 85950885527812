(function() {
  angular.module('CfcCharity.common').factory('toastrService', toastrService);

  toastrService.$inject = [];

  function toastrService() {
    var defaultOptions = {
      extendedTimeOut: 1000,
      positionClass: 'toast-bottom-right',
      timeOut: 5000, // Set timeOut and extendedTimeOut to 0 to make it sticky
      progressBar: true
    };

    var success = function(message, title, options) {
      if (!options || angular.equals({}, options)) {
        options = defaultOptions;
      }
      title = title || '';
      toastr.success(message, title, options);
    };

    var error = function(message, title, options) {
      if (!options || angular.equals({}, options)) {
        options = defaultOptions;
      }
      title = title || '';
      options.timeOut = 0;
      options.closeButton = true;
      options.positionClass = 'toast-top-full-width';
      toastr.error(message, title, options);
    };

    var info = function(message, title, options) {
      if (!options || angular.equals({}, options)) {
        options = defaultOptions;
      }
      title = title || '';
      toastr.info(message, title, options);
    };

    var warning = function(message, title, options) {
      if (!options || angular.equals({}, options)) {
        options = defaultOptions;
      }
      title = title || '';
      toastr.warning(message, title, options);
    };

    return {
      success: success,
      error: error,
      info: info,
      warning: warning
    };
  }
})();
