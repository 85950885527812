(function() {
  var moduleName = 'CfcCharity.directives.aosAppeal';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcAosAppeal', [
    function() {
      return {
        template: require('./aosAppeal.html'),
        restrict: 'E',
        scope: {
          reviewStatus: '@',
          mode: '@',
          charityType: '@',
          pledgeYear: '@',
          charityName: '@',
          displayForPriorYears: '=',
          isAppealAOS: '=',
          aosDetail: '=',
          step4Detail: '=',
          charity: '=',
          states: '=',
          countries: '=',
          useNewAddress: '='
        }
      };
    }
  ]);
})();
