(function() {
  'use strict';

  angular.module('CfcCharity.admin.system').factory('motdService', motdService);

  motdService.$inject = ['$http'];

  function motdService($http) {
    var getMOTD = function() {
      return $http({
        method: 'GET',
        url: '/CfcCharity/api/private/home/motd',
        transformResponse: function(response) {
          if (null !== response && '' !== response) {
            var responseObj = JSON.parse(response);
            return responseObj;
          } else {
            return response;
          }
        }
      });
    };

    var saveMOTD = function(motd) {
      return $http.post('/CfcCharity/api/private/home/motd', {
        messageText: motd
      });
    };

    var deleteMOTD = function(motd) {
      return $http.put('/CfcCharity/api/private/home/motd', {
        messageText: motd
      });
    };

    return {
      getMOTD: getMOTD,
      saveMOTD: saveMOTD,
      deleteMOTD: deleteMOTD
    };
  }
})();
