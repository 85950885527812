angular
  .module('CfcCharity.federationlistingthankyou', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('federationlistingthankyou', {
        url: '/federationlistingthankyou',
        views: {
          main: {
            template: require('../views/private/federation/federationlistingthankyou.html'),
            controller: 'FederationListingThankYouCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Listing Thank You',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          federationListingCfcCode: null,
          paymentid: null,
          paymenttype: null,
          paymentfeeType: null,
          paymentMethod: null,
          paymentUserid: null,
          paymentYear: null
        },
        resolve: {
          federationListingData: function($stateParams) {
            var federationListingData = null;
            if ($stateParams.federationListingCfcCode != null) {
              var cfcCode = $stateParams.federationListingCfcCode;
              federationListingData = {
                cfcCode: cfcCode,
                paymentid: $stateParams.paymentid,
                paymenttype: $stateParams.paymenttype,
                paymentfeeType: $stateParams.paymentfeeType,
                paymentMethod: $stateParams.paymentMethod,
                paymentUserid: $stateParams.paymentUserid,
                paymentYear: $stateParams.paymentYear
              };
            }
            return federationListingData;
          }
        }
      });
    }
  ])
  .controller('FederationListingThankYouCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'federationListingData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      federationListingData,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.federationlistingthankyou');
      $scope.federationListingData = federationListingData;
      $scope.continueToNext = function() {
        var userId = $scope.getCurrentUser().id;
        var year = BrowserEventService.getUserApplicationDataYear();
        $state.go('federationlisting', {
          charityDashboardCfcCode: federationListingData.cfcCode,
          pledgeYear: BrowserEventService.getPledgeYear()
        });
      };
    }
  ]);
