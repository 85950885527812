angular
  .module('CfcCharity.confirmUpdateUsername', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider
    ) {
      $stateProvider.state('confirmUpdateUsername', {
        url: '/confirmUpdateUsername/:userId',
        views: {
          main: {
            template: require('../views/public/confirmUpdateUsername.html'),
            controller: 'ConfirmUpdateUsernameCtrl'
          }
        },
        data: {
          pageTitle: 'Confirm Update Username Page'
        }
      });
    }
  ])
  .controller('ConfirmUpdateUsernameCtrl', [
    '$scope',
    'AdministrationCenterService',
    'AuthService',
    '$state',
    function($scope, AdministrationCenterService, AuthService, $state) {
      $scope.setModuleName('CfcCharity.confirmUpdateUsername');
      $scope.pin = '';
      $scope.errorMessage = '';
      $scope.success = false;

      $scope.submitEnabled = function() {
        if ($scope.pin.length === 8) {
          return true;
        }
        return false;
      };

      $scope.submitCode = function() {
        AdministrationCenterService.updateUserEmailFromSettings(
          $state.params.userId,
          $scope.pin
        )
          .then(function(res) {
            var data = res.data;
            if (data.statusCode === 'ERROR') {
              $scope.errorMessage = data.message;
            } else {
              $scope.success = true;
              if (AuthService.isAuthenticated()) {
                $scope.logoutStayOnPage();
              }
            }
          })
          .catch(function(err) {
            var data = err.data;
            if (data && data.message) {
              $scope.errorMessage = data.message;
            } else {
              $scope.errorMessage =
                'There was an issue updating your email. Please try again later';
            }
          });
      };
    }
  ]);
