/**
 * @ngdoc Module
 * @author Sarveswar (MS)
 * @date Mar 16, 2016
 * @description This module will take care of all actions on the User Dashboard page
 *
 */
angular
  .module('CfcCharity.gbadminDashboard', ['ui.router', 'CfcCharity.security'])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'USER_ROLES',
    function myAppConfig($stateProvider, $urlRouterProvider, USER_ROLES) {
      $stateProvider.state('gbadmin_dashboard', {
        url: '/gbadmin_dashboard',
        views: {
          main: {
            template: require('../views/private/gbadmin/dashboard.html'),
            controller: 'GbAdminDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'CFC User Home Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.gb_admin]
        }
      });
    }
  ])
  .controller('GbAdminDashboardCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    function($state, $scope, $location, $rootScope) {}
  ]);
