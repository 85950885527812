(function() {
  var moduleName = 'CfcCharity.directives.charitySupportingDocumentation';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }
  module.service('CharitySupportingDocumentationService', [
    '$http',
    'DownloadService',
    'CubeSvc',
    function($http, DownloadService, CubeSvc) {
      function getCharityReviewDocuments(
        campaignId,
        cfcCode,
        applicationId,
        appType
      ) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/review/charityReviewDocuments/' +
            campaignId +
            '/' +
            cfcCode +
            '/' +
            applicationId +
            '/' +
            appType,
          null,
          true
        );
      }

      function deleteCharityReviewDocument(documentId) {
        return $http.post(
          '/CfcCharity/api/private/application/review/deleteCharityReviewDocument/' +
            documentId
        );
      }

      function uploadCharityReviewDocument(
        charityReviewDocument,
        supportingFile,
        xAuthToken
      ) {
        var fd = new FormData();
        fd.append('supportingFile', supportingFile);
        fd.append(
          'charityReviewDocument',
          angular.toJson(charityReviewDocument)
        );
        var url =
          '/CfcCharity/api/private/application/review/uploadReviewDocument';
        return $http.post(url, fd, {
          transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
            'X-AUTH-TOKEN': xAuthToken
          }
        });
      }

      function getfileCategories() {
        return $http.get('/CfcCharity/api/private/lookup/REVIEW_FILE_CATEGORY');
      }
      return {
        getCharityReviewDocuments: getCharityReviewDocuments,
        deleteCharityReviewDocument: deleteCharityReviewDocument,
        uploadCharityReviewDocument: uploadCharityReviewDocument,
        getfileCategories: getfileCategories
      };
    }
  ]);
  module.directive('cfcCharitySupportingDocumentation', [
    '$log',
    'CharitySupportingDocumentationService',
    'blockUI',
    '$window',
    'UserService',
    'SYSTEM_USER_SECONDARY_ROLES',
    'DownloadService',
    function(
      $log,
      CharitySupportingDocumentationService,
      blockUI,
      $window,
      UserService,
      SYSTEM_USER_SECONDARY_ROLES,
      DownloadService
    ) {
      return {
        restrict: 'E',
        template: require('./charitySupportingDocumentation.html'),
        scope: {
          applicationId: '@',
          campaignId: '@',
          cfcCode: '@',
          applicationType: '@'
        },
        controller: function($scope) {
          $scope.fileCategory = {};
          $scope.otherFileCategory = {};
          $scope.isOpmAdminOrStaff = false;
          $scope.hasDownloadAccess = false;
          $scope.charityReviewDocument = {};

          if (UserService.isSystemUser()) {
            if (
              UserService.hasSystemRole([
                SYSTEM_USER_SECONDARY_ROLES.opm_admin,
                SYSTEM_USER_SECONDARY_ROLES.opm_staff,
                SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
                SYSTEM_USER_SECONDARY_ROLES.zone_manager,
                SYSTEM_USER_SECONDARY_ROLES.reviewer,
                SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
                SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
                SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2,
                SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
                SYSTEM_USER_SECONDARY_ROLES.gb_finance,
                SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
              ])
            ) {
              $scope.hasDownloadAccess = true;
            }
            if (
              UserService.hasSystemRole([
                SYSTEM_USER_SECONDARY_ROLES.opm_admin,
                SYSTEM_USER_SECONDARY_ROLES.opm_staff
              ])
            ) {
              $scope.isOpmAdminOrStaff = true;
            }
          }

          function forceClearBackdrop() {
            blockUI.stop();
            $('.modal-backdrop').hide();
          }

          function getReviewDocuments(
            campaignId,
            cfcCode,
            applicationId,
            applicationType
          ) {
            CharitySupportingDocumentationService.getCharityReviewDocuments(
              campaignId,
              cfcCode,
              applicationId,
              applicationType
            )
              .then(function(res) {
                if (res.data.value) {
                  $scope.charityReviewDocuments = res.data.value;
                }
              })
              .catch(function(error) {
                $log.error(error.data.errorMessage);
              });
          }

          $scope.mapTitle = function(categoryType) {
            return $scope.fileCategories.filter(
              category => category.lookupCd === categoryType
            )[0].lookupName;
          };

          function init() {
            CharitySupportingDocumentationService.getfileCategories().then(
              res => {
                $scope.fileCategories = res.data;
                return getReviewDocuments(
                  $scope.campaignId,
                  $scope.cfcCode,
                  $scope.applicationId,
                  $scope.applicationType
                );
              }
            );
          }

          init();

          $scope.viewPopup = function(popupId) {
            CharitySupportingDocumentationService.getfileCategories().then(
              function(res) {
                var result = res.data;
                if (result) {
                  $scope.fileCategories = result;
                  $scope.fileCategories.splice(0, 0, {
                    lookupCd: 'Select One',
                    lookupName: 'Select One'
                  });
                  $scope.fileCategory = $scope.fileCategories[0];
                  $('#' + popupId).modal();
                }
              }
            );
          };

          $scope.resetData = function() {
            document.getElementById('reviewFileUpload').value = '';
            $scope.otherFileCategory.fileName = '';
            $scope.supportingFile = null;
            $scope.addFileErrors = [];
          };

          $scope.downloadPDFAndDocuments = function() {
            var downloadURL =
              '/CfcCharity/api/public/downloadreviewdocuments/' +
              $scope.applicationId +
              '/' +
              $scope.applicationType +
              '/' +
              $scope.campaignId +
              '/' +
              $scope.cfcCode;
            DownloadService.downloadFile(downloadURL);
          };

          $scope.submitReviewFile = function() {
            blockUI.start();
            $scope.fileUploadStatusMessage = '';
            $scope.addFileErrors = [];
            if (!$scope.supportingFile) {
              $scope.addFileErrors.push(
                'A valid file must be uploaded in PDF format.'
              );
            }
            if (
              $scope.fileCategory.lookupCd === 'Other' &&
              !$scope.otherFileCategory.fileName
            ) {
              $scope.addFileErrors.push(
                'File name is required with Other File Category.'
              );
            }
            if (
              !$scope.fileCategory.lookupCd ||
              $scope.fileCategory.lookupCd === 'Select One'
            ) {
              $scope.addFileErrors.push(
                'A valid file category must be selected.'
              );
            }
            if ($scope.supportingFile) {
              if ($scope.supportingFile.type !== 'application/pdf') {
                $scope.addFileErrors.push(
                  'A valid file must be uploaded in PDF format.'
                );
              }
            }
            if ($scope.addFileErrors.length === 0) {
              $scope.charityReviewDocument.applicationId = $scope.applicationId;
              $scope.charityReviewDocument.cfcCode = $scope.cfcCode;
              $scope.charityReviewDocument.campaignId = $scope.campaignId;
              $scope.charityReviewDocument.fileCategory =
                $scope.fileCategory.lookupCd;
              $scope.charityReviewDocument.applicationType =
                $scope.applicationType;
              $scope.charityReviewDocument.otherCategoryFileName =
                $scope.otherFileCategory.fileName;

              CharitySupportingDocumentationService.uploadCharityReviewDocument(
                $scope.charityReviewDocument,
                $scope.supportingFile,
                $scope.xAuthToken
              )
                .then(function(res) {
                  var data = res.data;

                  if (res.message && res.message.type === 'ERROR') {
                    $('input[type="file"]').val(null);
                    uploadFailed();
                    return;
                  }

                  if (data && data.message.type === 'FAILURE') {
                    for (var i in data.validationMessageList) {
                      $scope.addFileErrors.push(
                        data.validationMessageList[i].variableMessage
                      );
                    }
                  } else if (data && data.message.type === 'SUCCESS') {
                    $scope.fileUploadStatusMessage =
                      'File uploaded successfully.';
                    $('#fileUploadStatusMessage').modal();
                    $scope.resetData();
                    $('#addFile').modal('toggle');
                    getReviewDocuments(
                      $scope.campaignId,
                      $scope.cfcCode,
                      $scope.applicationId,
                      $scope.applicationType
                    );
                  }
                })
                .catch(function(err) {
                  uploadFailed();
                });
            }
            forceClearBackdrop();
          };

          function uploadFailed() {
            $scope.fileUploadStatusMessage =
              'There is a problem with your file. Your file may be corrupted or be password protected. Please check and fix your file and upload it again.';
            $('#fileUploadStatusMessage').modal();
            $scope.resetData();
            $('#addFile').modal('toggle');
            forceClearBackdrop();
          }

          $scope.downloadReviewDocument = function(
            documentId,
            isReviewDocument,
            step,
            isFed,
            fileName
          ) {
            CharitySupportingDocumentationService.downloadCharityReviewDocument(
              documentId,
              isReviewDocument,
              step,
              isFed,
              fileName
            );
          };

          function confirmDeleting() {
            return $window.confirm(
              'Are you sure you want to Delete this file?'
            );
          }

          $scope.deleteReviewDocument = function(documentId) {
            if (confirmDeleting()) {
              CharitySupportingDocumentationService.deleteCharityReviewDocument(
                documentId
              )
                .then(function(res) {
                  var data = res.data;
                  if (data) {
                    $scope.fileUploadStatusMessage =
                      'File deleted successfully.';
                    $('#fileUploadStatusMessage').modal();
                  }
                  getReviewDocuments(
                    $scope.campaignId,
                    $scope.cfcCode,
                    $scope.applicationId,
                    $scope.applicationType
                  );
                })
                .catch(function(err) {
                  $scope.fileUploadStatusMessage =
                    'An unexpected error has occurred during file deletion.  Please contact customer care for support.';
                  $('#fileUploadStatusMessage').modal();
                  forceClearBackdrop();
                });
              forceClearBackdrop();
            }
          };
        }
      };
    }
  ]);
})();
