angular
  .module('CfcCharity.financetransactions', [
    'ui.router',
    'CfcCharity.security',
    'hateoas',
    'ngSanitize',
    'ngCsv'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('financetransactions', {
        url: '/financetransactions',
        views: {
          main: {
            template: require('../views/private/finance/financetransactions.html'),
            controller: 'FinanceTransactionsCtrl'
          }
        },
        data: {
          pageTitle: 'Financial Transactions',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          financialTransactionsData: null
        }
      });

      $stateProvider.state('achtransactions', {
        url: '/achtransactions',
        views: {
          main: {
            template: require('../views/private/finance/achtransactions.html'),
            controller: 'ACHTransactionsCtrl'
          }
        },
        data: {
          pageTitle: 'ACH Transactions',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          achTransactionsData: null
        }
      });
    }
  ])
  .service('FinanceTransactionsService', [
    '$http',
    '$filter',
    'CubeSvc',
    function($http, $filter, CubeService) {
      this.getFinanceTransactionsDetails = function(userId) {
        var url = '/CfcCharity/api/private/finance/transactions' + userId;
        return $http.get(url);
      }; // end getFinanceTransactionDetails function

      this.retrieveCCPaymentDetails = function(financialTransactionsRequest) {
        var url = '/CfcCharity/api/private/finance/paymentdetails/creditcard';
        return $http.post(url, financialTransactionsRequest);
      }; // end retrieveCCPaymentDetails function

      this.adjustPaymentTransaction = function(adjustedTransactionsRequest) {
        var url = '/CfcCharity/api/private/admin/adjustPayment';
        return CubeService.post(url, adjustedTransactionsRequest);
      };

      this.retrieveACHPaymentDetails = function(financialTransactionsRequest) {
        var url = '/CfcCharity/api/private/finance/paymentdetails/ach';
        return $http.post(url, financialTransactionsRequest);
      }; // end retrieveACHPaymentDetails

      this.prepareAllFinancialTransactionsForDownload = function(
        financialTransactionDetailBeanListForDownload
      ) {
        var downloadFinancialTransactionDetailBeanList = financialTransactionDetailBeanListForDownload;
        if (downloadFinancialTransactionDetailBeanList) {
          for (
            var i = 0;
            i < downloadFinancialTransactionDetailBeanList.length;
            i++
          ) {
            delete downloadFinancialTransactionDetailBeanList[i].id;
            delete downloadFinancialTransactionDetailBeanList[i].type;
            delete downloadFinancialTransactionDetailBeanList[i].$$hashKey;
            downloadFinancialTransactionDetailBeanList[
              i
            ].transactionDate = $filter('date')(
              downloadFinancialTransactionDetailBeanList[i].transactionDate,
              'MM/dd/yyyy HH:mm:ss'
            );
            downloadFinancialTransactionDetailBeanList[
              i
            ].totalTransactionAmount = $filter('currency')(
              downloadFinancialTransactionDetailBeanList[i]
                .totalTransactionAmount,
              '$'
            );
            downloadFinancialTransactionDetailBeanList[i].feeAmount = $filter(
              'currency'
            )(downloadFinancialTransactionDetailBeanList[i].feeAmount, '$');
            downloadFinancialTransactionDetailBeanList[
              i
            ].processingFeeAmount = $filter('currency')(
              downloadFinancialTransactionDetailBeanList[i].processingFeeAmount,
              '$'
            );
          } // end for loop
        } // end if
        return downloadFinancialTransactionDetailBeanList;
      }; // end prepareAllFinancialTransactionsForDownload function

      this.downloadAllTransactionsCreateActivity = function(
        financialTransactionsRequest
      ) {
        var url =
          '/CfcCharity/api/private/finance/downloadallfinancialtransactionsdetails/activity/create';
        return $http.post(url, financialTransactionsRequest);
      }; // end downloadAllTransactionsCreateActivity function

      this.downloadAllFinancialTransactions = function(
        financialTransactionsRequest
      ) {
        var url = '/CfcCharity/api/public/finance/alltransactions/download';
        var form = document.getElementById('downloadDataForm');
        form.setAttribute('action', url);
        form.setAttribute('method', 'POST');
        form.setAttribute('target', '_blank');

        for (var key in financialTransactionsRequest.financialTransactionsBean) {
          if (
            financialTransactionsRequest.financialTransactionsBean.hasOwnProperty(
              key
            )
          ) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            var value =
              financialTransactionsRequest.financialTransactionsBean[key];
            hiddenField.setAttribute('value', value);
            form.appendChild(hiddenField);
          }
        }

        //document.body.appendChild(form);
        form.submit();
      }; // end downloadAllFinancialTransactions function

      this.getACHTransactionDetails = function(userId) {
        var url =
          '/CfcCharity/api/private/finance/transactiondetails/ach' + userId;
        return $http.get(url);
      }; // end getACHTransactionDetails function

      this.downloadACHTransactionsCreateActivity = function(
        financialTransactionsRequest
      ) {
        var url =
          '/CfcCharity/api/private/finance/downloadachtransactions/activity/create';
        return $http.post(url, financialTransactionsRequest);
      }; // end downloadACHTransactionsCreateActivity function

      this.prepareACHTransactionsForDownload = function(
        achTransactionsResultsBeanListForDownload
      ) {
        var downloadACHTransactionsResultsBeanList = achTransactionsResultsBeanListForDownload;
        if (downloadACHTransactionsResultsBeanList) {
          for (
            var i = 0;
            i < downloadACHTransactionsResultsBeanList.length;
            i++
          ) {
            delete downloadACHTransactionsResultsBeanList[i].id;
            delete downloadACHTransactionsResultsBeanList[i].type;
            delete downloadACHTransactionsResultsBeanList[i].$$hashKey;
            downloadACHTransactionsResultsBeanList[i].transactionDate = $filter(
              'date'
            )(
              downloadACHTransactionsResultsBeanList[i].transactionDate,
              'MM/dd/yyyy HH:mm:ss'
            );
            downloadACHTransactionsResultsBeanList[i].cfcFee = $filter(
              'currency'
            )(downloadACHTransactionsResultsBeanList[i].cfcFee, '$');
            downloadACHTransactionsResultsBeanList[i].processingFee = $filter(
              'currency'
            )(downloadACHTransactionsResultsBeanList[i].processingFee, '$');
            downloadACHTransactionsResultsBeanList[i].totalAmount = $filter(
              'currency'
            )(downloadACHTransactionsResultsBeanList[i].totalAmount, '$');

            downloadACHTransactionsResultsBeanList[
              i
            ].federationCfcFee = $filter('currency')(
              downloadACHTransactionsResultsBeanList[i].federationCfcFee,
              '$'
            );

            downloadACHTransactionsResultsBeanList[
              i
            ].federationProcessingFee = $filter('currency')(
              downloadACHTransactionsResultsBeanList[i].federationProcessingFee,
              '$'
            );

            downloadACHTransactionsResultsBeanList[
              i
            ].totalFederationApplicationCharges = $filter('currency')(
              downloadACHTransactionsResultsBeanList[i]
                .totalFederationApplicationCharges,
              '$'
            );

            downloadACHTransactionsResultsBeanList[
              i
            ].totalFederationApplicationAmount = $filter('currency')(
              downloadACHTransactionsResultsBeanList[i]
                .totalFederationApplicationAmount,
              '$'
            );
            downloadACHTransactionsResultsBeanList[i].memberCFCFee = $filter(
              'currency'
            )(downloadACHTransactionsResultsBeanList[i].memberCFCFee, '$');
            downloadACHTransactionsResultsBeanList[
              i
            ].memberProcessingFee = $filter('currency')(
              downloadACHTransactionsResultsBeanList[i].memberProcessingFee,
              '$'
            );
            downloadACHTransactionsResultsBeanList[
              i
            ].totalMemberCharges = $filter('currency')(
              downloadACHTransactionsResultsBeanList[i].totalMemberCharges,
              '$'
            );
          } // end for loop
        } // end if
        return downloadACHTransactionsResultsBeanList;
      }; // end prepareACHTransactionsForDownload function

      this.retrieveACHTransactionPaymentDetails = function(
        financialTransactionsRequest
      ) {
        var url =
          '/CfcCharity/api/private/finance/transactionpaymentdetails/ach';
        return $http.post(url, financialTransactionsRequest);
      }; // end retrieveACHTransactionPaymentDetails
    } // end FinanceTransactionsService function
  ])
  .controller('FinanceTransactionsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'FinanceTransactionsService',
    'BrowserEventService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      FinanceTransactionsService,
      BrowserEventService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.financetransactions');
      $('.modal-backdrop').remove();

      $scope.financialTransactionsData =
        $state.params.financialTransactionsData;

      if ($scope.financialTransactionsData) {
        BrowserEventService.setFinancialTransactionsData(
          $scope.financialTransactionsData
        );

        $scope.financialTransactionsData.downloadFinancialTransactionDetailBeanList = {};

        if (
          $scope.financialTransactionsData.financialTransactionDetailBeanList
        ) {
          var financialTransactionDetailBeanListForDownload = angular.copy(
            $scope.financialTransactionsData.financialTransactionDetailBeanList
          );
          $scope.downloadFinancialTransactionDetailBeanList = FinanceTransactionsService.prepareAllFinancialTransactionsForDownload(
            financialTransactionDetailBeanListForDownload
          );
        }
      }
      var error = '';
      var userId = $scope.getCurrentUser().id;

      if (!$scope.financialTransactionsData) {
        error =
          'Cannot retrieve the financial transactions for the user id : ' +
          userId +
          ' Please try later';
        $scope.showFinancialTransactionsErrorMessage(error);
      }

      $scope.downloadAllTransactions = function(financialTransactionsRequest) {
        FinanceTransactionsService.downloadAllFinancialTransactions(
          financialTransactionsRequest
        );
      }; // end downloadAllTransactions function

      $scope.downloadAllTransactionsCreateActivity = function(
        financialTransactionsRequest
      ) {
        FinanceTransactionsService.downloadAllTransactionsCreateActivity(
          financialTransactionsRequest
        )
          .then(function(res) {
            var financialTransactionsResponse = res.data;
            if (
              financialTransactionsResponse &&
              (financialTransactionsResponse.error ||
                financialTransactionsResponse.statusCode === 'ERROR')
            ) {
              var error =
                'Error creating the activity for downloading the financial transactions for the start date  ' +
                "'" +
                $scope.financialTransactionsRequest.start +
                "'" +
                ' and end date ' +
                "'" +
                $scope.financialTransactionsRequest.end +
                "'" +
                '. Please try later';
              $scope.downloadAllTransactionsCreateActivityErrors.push(error);
            } else if (
              financialTransactionsResponse &&
              financialTransactionsResponse.statusCode === 'SUCCESS'
            ) {
              $scope.downloadAllTransactionsCreateActivityErrors.push('');
            } // end else
          })
          .catch(function(err) {
            var error =
              'Error downloading the financial transactions for the start date  ' +
              "'" +
              $scope.financialTransactionsRequest.start +
              "'" +
              ' and end date ' +
              "'" +
              $scope.financialTransactionsRequest.end +
              "'" +
              '. Please try later';
            $scope.downloadAllFinancialTransactionsErrors.push(error);
          });
      }; // end downloadAllTransactions function

      $scope.allFinancialTransactionDetailBeanListLimit = 100;
      $scope.loadMoreAllFinancialTransactionsDetails = function() {
        if (
          $scope.financialTransactionsData &&
          $scope.financialTransactionsData.financialTransactionDetailBeanList &&
          $scope.allFinancialTransactionDetailBeanListLimit <
            $scope.financialTransactionsData.financialTransactionDetailBeanList
              .length
        ) {
          $scope.allFinancialTransactionDetailBeanListLimit += 100;
        }
      }; // end loadMoreAllFinancialTransactionsLimit function
      $scope.retrieveCCPaymentDetails = function(
        userId,
        financialTransactionDetail
      ) {
        if (financialTransactionDetail) {
          var financialTransactionsBean = {
            userId: userId,
            id: financialTransactionDetail.id,
            type: financialTransactionDetail.type,
            feeType: financialTransactionDetail.feeType
          };

          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };

          FinanceTransactionsService.retrieveCCPaymentDetails(
            financialTransactionsRequest
          )
            .then(function(res) {
              var ccPaymentDetailsResponse = res.data;

              if (
                ccPaymentDetailsResponse &&
                (ccPaymentDetailsResponse.error ||
                  ccPaymentDetailsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error occured while retrieving the Credit Card payment details ';
                $scope.showCCPaymentDetailsErrorMessage(error);
              } else if (
                ccPaymentDetailsResponse &&
                ccPaymentDetailsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.showCCPaymentDetailsErrorMessage('');
                $scope.creditCardTransactionDetailsData =
                  ccPaymentDetailsResponse.financialTransactionsBean.creditCardPaymentDetailsBean;
                $scope.creditCardTransactionDetailsData.paymentType =
                  'Credit Card';
              }
            })
            .catch(function(err) {
              var error =
                'Error occured while retrieving the Credit Card payment details ';
              $scope.showCCPaymentDetailsErrorMessage(error);
            }); // end error
        } else {
          var error =
            'Could not retrieve the Credit Card payment details. Please try again later';
          $scope.showCCPaymentDetailsErrorMessage(error);
        } // end else

        $('#ccPaymentDetails').modal();
      }; // end retrieveCCPaymentDetails function

      $scope.retrieveACHPaymentDetails = function(
        userId,
        financialTransactionDetail
      ) {
        if (financialTransactionDetail) {
          var financialTransactionsBean = {
            userId: userId,
            id: financialTransactionDetail.id,
            type: financialTransactionDetail.type,
            feeType: financialTransactionDetail.feeType,
            paymentType: financialTransactionDetail.paymentMethod
          };

          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };

          FinanceTransactionsService.retrieveACHPaymentDetails(
            financialTransactionsRequest
          )
            .then(function(res) {
              var achPaymentDetailsResponse = res.data;

              if (
                achPaymentDetailsResponse &&
                (achPaymentDetailsResponse.error ||
                  achPaymentDetailsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error occured while retrieving the ACH payment details ';
                $scope.showACHPaymentDetailsErrorMessage(error);
              } else if (
                achPaymentDetailsResponse &&
                achPaymentDetailsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.showACHPaymentDetailsErrorMessage('');
                $scope.achTransactionDetailsData =
                  achPaymentDetailsResponse.financialTransactionsBean.achPaymentDetailBean;
                $scope.achTransactionDetailsData.paymentType =
                  financialTransactionDetail.paymentMethod;
              }
            })
            .catch(function(err) {
              var error =
                'Error occured while retrieving the ACH payment details ';
              $scope.showACHPaymentDetailsErrorMessage(error);
            }); // end error
        } else {
          var error =
            'Could not retrieve the ACH payment details. Please try later';
          $scope.showACHPaymentDetailsErrorMessage(error);
        } // end else

        $('#achPaymentDetails').modal();
      }; // end retrieveACHPaymentDetails function

      $scope.gotToUserResults = function(paidBy) {
        $('.modal-backdrop').remove();
        $state.go('userSearchResults', {
          userId: paidBy
        });
      };
      $scope.gotoCharityDashboard = function(cfcCode) {
        $('.modal-backdrop').remove();
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };

      $scope.showFinancialTransactionsErrorMessage = function(error) {
        $scope.financialTransactionsError = error;
      };

      $scope.showCCPaymentDetailsErrorMessage = function(error) {
        $scope.ccPaymentDetailsError = error;
      };

      $scope.showACHPaymentDetailsErrorMessage = function(error) {
        $scope.achPaymentDetailsError = error;
      };
    } // end FinanceTransactionsCtrl controller
  ])
  .controller('ACHTransactionsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'FinanceTransactionsService',
    'BrowserEventService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      FinanceTransactionsService,
      BrowserEventService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.achtransactions');
      $('.modal-backdrop').remove();

      $scope.achTransactionsData = $state.params.achTransactionsData;

      if ($scope.achTransactionsData) {
        //  BrowserEventService.setACHTransactionsData($scope.achTransactionsData);
        $scope.achTransactionsData.downloadACHTransactionsFileName = '';

        $scope.currentDate = $filter('date')(new Date(), 'MMddyyyy');

        if (
          $scope.achTransactionsData.listingsSelected &&
          $scope.achTransactionsData.applicationsSelected
        ) {
          $scope.achTransactionsData.downloadACHTransactionsFileName =
            'ACH_All_Transactions_' + $scope.currentDate + '.csv';
        } else if (
          $scope.achTransactionsData.listingsSelected &&
          !$scope.achTransactionsData.applicationsSelected
        ) {
          $scope.achTransactionsData.downloadACHTransactionsFileName =
            'ACH_Listings_Transactions_' + $scope.currentDate + '.csv';
        } else if (
          !$scope.achTransactionsData.listingsSelected &&
          $scope.achTransactionsData.applicationsSelected
        ) {
          $scope.achTransactionsData.downloadACHTransactionsFileName =
            'ACH_Applications_Transactions_' + $scope.currentDate + '.csv';
        }

        $scope.achTransactionsData.downloadACHTransactionDetailBeanList = {};

        if ($scope.achTransactionsData.achTransactionsResultsBeanList) {
          var achTransactionsResultsBeanListForDownload = angular.copy(
            $scope.achTransactionsData.achTransactionsResultsBeanList
          );
          $scope.downloadACHTransactionsResultsBeanList = FinanceTransactionsService.prepareACHTransactionsForDownload(
            achTransactionsResultsBeanListForDownload
          );
        } // end if $scope.achTransactionsData.achTransactionsResultsBeanList
      } // end if $scope.achTransactionsData

      var error = '';
      var userId = $scope.getCurrentUser().id;

      if (!$scope.achTransactionsData) {
        error =
          'Cannot retrieve the ACH transactions for the user id : ' +
          userId +
          'Please try later';
        $scope.showACHTransactionsErrorMessage(error);
      }

      $scope.achTransactionsLimit = 100;
      $scope.loadMoreACHTransactions = function() {
        if (
          $scope.achTransactionsData &&
          $scope.achTransactionsData.achTransactionsResultsBeanList &&
          $scope.achTransactionsLimit <
            $scope.achTransactionsData.achTransactionsResultsBeanList.length
        ) {
          $scope.achTransactionsLimit += 100;
        }
      }; // end loadMoreACHTransactions function

      $scope.downloadACHTransactionsCreateActivity = function() {
        var financialTransactionsBean = {
          userId: userId
        };

        var financialTransactionsRequest = {
          financialTransactionsBean: financialTransactionsBean
        };

        if (financialTransactionsRequest) {
          FinanceTransactionsService.downloadACHTransactionsCreateActivity(
            financialTransactionsRequest
          )
            .then(function(res) {
              var downloadACHTransactionsCreateActivityResponse = res.data;
              var error =
                'Error occured while download the credit card transactions create activity';

              if (
                downloadACHTransactionsCreateActivityResponse &&
                (downloadACHTransactionsCreateActivityResponse.error ||
                  downloadACHTransactionsCreateActivityResponse.statusCode ===
                    'ERROR')
              ) {
                $scope.showDownloadACHTransactionsCreateActivityErrorMessage(
                  error
                );
              } else if (
                downloadACHTransactionsCreateActivityResponse &&
                downloadACHTransactionsCreateActivityResponse.statusCode ===
                  'SUCCESS'
              ) {
                $scope.showDownloadACHTransactionsCreateActivityErrorMessage(
                  ''
                );
              }
            })
            .catch(function(err) {
              $scope.showDownloadACHTransactionsCreateActivityErrorMessage(
                error
              );
            }); // end error
        } else {
          $scope.showDownloadACHTransactionsCreateActivityErrorMessage(error);
        } // end else
      }; // end downloadACHTransactionsCreateActivity function

      $scope.showACHTransactionsErrorMessage = function(error) {
        $scope.achTransactionsnError = error;
      };

      $scope.retrieveACHTransactionPaymentDetails = function(
        userId,
        achTransactionDetail
      ) {
        if (achTransactionDetail) {
          var financialTransactionsBean = {
            userId: userId,
            id: achTransactionDetail.id,
            type: achTransactionDetail.type,
            feeType: achTransactionDetail.feeType
          };

          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };

          FinanceTransactionsService.retrieveACHTransactionPaymentDetails(
            financialTransactionsRequest
          )
            .then(function(res) {
              var achTransactionPaymentDetailsResponse = res.data;

              if (
                achTransactionPaymentDetailsResponse &&
                (achTransactionPaymentDetailsResponse.error ||
                  achTransactionPaymentDetailsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error occured while retrieving the ACH transaction payment details ';
                $scope.showACHTransactionPaymentDetailsErrorMessage(error);
              } else if (
                achTransactionPaymentDetailsResponse &&
                achTransactionPaymentDetailsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.showACHTransactionPaymentDetailsErrorMessage('');
                $scope.achTransactionDetailsData =
                  achTransactionPaymentDetailsResponse.financialTransactionsBean.achTransactionDetailsBean;
              }
              $('#achTransactionPaymentDetails').modal();
            })
            .catch(function(err) {
              var error =
                'Error occured while retrieving the ACH transaction payment details ';
              $scope.showACHTransactionPaymentDetailsErrorMessage(error);
            }); // end error
        } else {
          var error =
            'Could not retrieve the ACH transaction payment details. Please try later';
          $scope.showACHTransactionPaymentDetailsErrorMessage(error);
        } // end else
      }; // end retrieveACHTransactionPaymentDetails function

      $scope.showACHTransactionPaymentDetailsErrorMessage = function(error) {
        $scope.achTransactionPaymentDetailsError = error;
      };

      $scope.showDownloadACHTransactionsCreateActivityErrorMessage = function(
        error
      ) {
        $scope.downloadACHTransactionsCreateActivityError = error;
      };
    } // end ACHTransactionsCtrl controller
  ]);
