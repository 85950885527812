'use strict';

(function() {
  angular
    .module('CfcCharity.review.email', [
      'ui.router',
      'CfcCharity.security',
      'hateoas',
      'CfcCharity.common',
      'textAngular'
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      'HateoasInterceptorProvider',
      'USER_ROLES',
      'SYSTEM_USER_SECONDARY_ROLES',
      config
    ])
    .service('EmailService', EmailService)
    .controller('EmailCtrl', EmailCtrl);

  function config(
    $stateProvider,
    $urlRouterProvider,
    HateoasInterceptorProvider,
    USER_ROLES,
    SYSTEM_USER_SECONDARY_ROLES
  ) {
    $stateProvider.state('email-dashboard', {
      url: '/email-dashboard',
      views: {
        main: {
          template: require('./Email.html'),
          controller: 'EmailCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        breadcrumbs: function(ApplicationService, isFed) {
          if (!isFed) {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'reviewLevel',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'reviewLevel',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: 'Member Applications',
                  crumbRoute: 'memberapplication',
                  crumbParams:
                    '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          } else {
            return [
              {
                crumbName: '%zoneCode% Applications',
                crumbRoute: 'zoneApplications',
                crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
              },
              {
                crumbName: 'reviewLevel',
                crumbRoute: 'zonecompletenessreview',
                crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
              },
              {
                crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
              },
              {
                crumbName: 'Federation Application',
                crumbRoute: 'federationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              },
              {
                crumbName: 'Application Review',
                crumbRoute: 'federationapplicationreview',
                crumbParams: '{federationApplicationId: %applicationId%}'
              }
            ];
          }
        },
        pageTitle: 'Review Email Dashboard',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [
          SYSTEM_USER_SECONDARY_ROLES.opm_admin,
          SYSTEM_USER_SECONDARY_ROLES.opm_staff,
          SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
          SYSTEM_USER_SECONDARY_ROLES.zone_manager,
          SYSTEM_USER_SECONDARY_ROLES.reviewer,
          SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
          SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
          SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
        ]
      },
      params: {
        isFederation: null,
        applicationId: null,
        sourcePageName: null,
        sourcePageParams: null,
        charityType: null
      },
      resolve: {
        parameterData: function($stateParams) {
          return {
            isFederation: $stateParams.isFederation,
            applicationId: $stateParams.applicationId || null,
            sourcePageName: $stateParams.sourcePageName || null,
            sourcePageParams: $stateParams.sourcePageParams || null,
            charityType: $stateParams.charityType || null
          };
        },
        applicationReview: function(CubeSvc, $stateParams) {
          var applicationId =
            $stateParams.applicationId || ApplicationService.getApplicationId();
          var url =
            '/CfcCharity/api/private/application/review/getall/' +
            applicationId;
          return CubeSvc.get(url, null, false);
        },
        tokenData: function($stateParams, BreadcrumbTokenDataService) {
          BreadcrumbTokenDataService.setIsFederation($stateParams.isFederation);
          return BreadcrumbTokenDataService.getReviewLevel(
            $stateParams.applicationId,
            $stateParams.isFederation
          ).then(function(res1) {
            if ($stateParams.isFederation) {
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                $stateParams.applicationId
              );
            } else {
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                $stateParams.applicationId
              );
            }
          });
        }
      }
    });
  }

  EmailService.$inject = ['$http', '$q'];

  function EmailService($http, $q) {
    var vm = this;

    function getEmailTemplates(campaignId) {
      var url = '/CfcCharity/api/private/email/templateTypes/' + campaignId;
      return $http.get(url);
    }

    function generateEmail(
      cfcCode,
      campaignId,
      applicationId,
      applicationType,
      templateTypeId
    ) {
      if (!applicationId || !templateTypeId || !cfcCode || !campaignId) {
        return $q.reject('Missing required param.');
      }
      var url = '/CfcCharity/api/private/email/generate/';
      var body = {
        templateId: templateTypeId,
        cfcCode: cfcCode,
        applicationId: applicationId,
        applicationType: applicationType,
        campaignId: campaignId
      };
      return $http.post(url, body);
    }

    function getEmailHistory(cfcCode, applicationId) {
      if (!applicationId || !cfcCode) {
        return $q.reject('Missing required param.');
      }
      var url =
        '/CfcCharity/api/private/email/history/' +
        cfcCode +
        '/' +
        applicationId;

      return $http.get(url);
    }

    function getEmailById(id) {
      return $http.get('/CfcCharity/api/private/email/' + id);
    }

    function saveEmail(emailOb) {
      return $http.put(
        '/CfcCharity/api/private/email/update',
        getSanitizedEmailObject(emailOb)
      );
    }

    function deleteEmail(id) {
      return $http.put('/CfcCharity/api/private/email/delete/' + id, {});
    }

    function sendEmail(emailOb) {
      return $http.put(
        '/CfcCharity/api/private/email/send',
        getSanitizedEmailObject(emailOb)
      );
    }

    function cloneEmail(emailOb) {
      return $http.get('/CfcCharity/api/private/email/clone/' + emailOb.id);
    }

    // performs send of the newly duplicated, clone happens separately above
    function resendEmail(emailOb) {
      return sendEmail(getSanitizedEmailObject(emailOb));
    }

    /*
     * private, ensures update/save and send endpoints receive well-formed object
     * if we include all props, canEdit, etc., it won't fit the model used on the back-end
     */
    function getSanitizedEmailObject(emailOb) {
      return {
        id: emailOb.id,
        to: emailOb.to,
        cc: emailOb.cc,
        subject: emailOb.subject,
        body: emailOb.body
      };
    }

    return {
      getEmailTemplates: getEmailTemplates,
      generateEmail: generateEmail,
      getEmailHistory: getEmailHistory,
      getEmail: getEmailById,
      saveEmail: saveEmail,
      deleteEmail: deleteEmail,
      sendEmail: sendEmail,
      resendEmail: resendEmail,
      cloneEmail: cloneEmail
    };
  }

  EmailCtrl.$inject = [
    '$state',
    '$log',
    '$q',
    '$scope',
    '$filter',
    'parameterData',
    'BrowserEventService',
    'ZoneCenterService',
    'EmailService',
    'ApplicationWrapperService',
    'FederationApplicationReviewService',
    'applicationReview'
  ];

  function EmailCtrl(
    $state,
    $log,
    $q,
    $scope,
    $filter,
    parameterData,
    BrowserEventService,
    ZoneCenterService,
    EmailService,
    ApplicationWrapperService,
    FederationApplicationReviewService,
    applicationReview
  ) {
    // using controllerAs syntax, both here and in html, `vm` = this context
    var vm = this;
    var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
    function loadInitialStateValues() {
      vm.canEdit = false;
      vm.hasAccess = false;
      vm.appType = null;
      vm.canGenerateEmails = false;
      vm.canEditStatus = false;
      clearEmailEditFields();
    }
    $scope.applicationReview = applicationReview.data.value;
    vm.review = $scope.applicationReview.charityApplicationReview;
    $scope.viewPopup = function(popupId) {
      $('#' + popupId).modal();
    };
    // async prepares parameter data for use
    function loadStateParams() {
      return $q(function(resolve, reject) {
        if (parameterData) {
          // load from resolved parameterData
          vm.isFederation = parameterData.isFederation;
          vm.applicationId = parameterData.applicationId;
          vm.sourcePageName = parameterData.sourcePageName;
          vm.sourcePageParams = parameterData.sourcePageParams;
          vm.charityType = parameterData.charityType;

          ApplicationWrapperService.getAppData(
            vm.applicationId,
            vm.isFederation
          )
            .then(function(res) {
              // set to vm
              vm.appDash = res.data.value;
              if (vm.isFederation) {
                vm.appType = 'FEDERATION';
                //storing the status in temp variable so that on the update cancel function it can use the original status
                vm.appStatus = res.data.value.applicationStatus;
                var federationDashboardData = {};
                // since pledgeYear and charity name don't come in the federation dashboard response, grab it
                FederationApplicationReviewService.getFederationDashboardData(
                  vm.applicationId
                ).then(function(res) {
                  var data = res.data.value;
                  federationDashboardData.federationDetails = data;
                  vm.appDash.pledgeYear =
                    federationDashboardData.federationDetails.pledgeYear;
                  vm.appDash.name =
                    federationDashboardData.federationDetails.federationName;
                  vm.appDash.applicationStatus = $filter('titleCaseWithSpaces')(
                    vm.appDash.applicationStatus
                  ).toUpperCase();
                });
              } else {
                //storing the status in temp variable so that on the update cancel function it can use the original status
                vm.appStatus = res.data.value.status;
                if (
                  vm.appDash.federationNumber !== null &&
                  vm.appDash.federationNumber !== ''
                ) {
                  vm.appType = 'MEMBER';
                } else {
                  vm.appType = 'INDEPENDENT';
                }
              }
              initGenerateEmailModal();
              resolve(
                'successfully loaded state params and dependent app review'
              );
              getEmailHistoryData(vm.appDash.cfcCode, vm.applicationId);
              resolve('successfully loaded email history data');
            })
            .catch(function(err) {
              reject(err);
            });
        } else {
          reject('failed loading state parameter data');
        }
      });
    }

    function toUTCDate(date) {
      var _utc = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );
      return _utc;
    }

    if (parameterData.applicationId) {
      if (parameterData.isFederation) {
        FederationApplicationReviewService.getFederationReviewDetails(
          parameterData.applicationId
        ).then(function(res) {
          var data = res.data;
          var federationApplicationReviewResponse = data;
          $scope.fedReviewdata = data;
          $scope.reviewLogs =
            federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
        });
      } else {
        ZoneCenterService.getApplicationReviewLogs(
          parameterData.applicationId
        ).then(function(res) {
          var reviewLogs = res.data.value;
          $log.debug('fetching Review comments...');
          $scope.reviewLogs = reviewLogs;
        });
      }
    } else {
      $log.error("Yikes! Can't determine applicationId");
    }
    $scope.addReviewComment = function(comment) {
      $scope.addReviewerCommentErrors = [];
      if (
        !comment ||
        ((comment && comment.trim().length < 1) || comment.length > 2000)
      ) {
        $scope.addReviewerCommentErrors.push(
          'Please enter a comment between 1 and 2000 characters.'
        );
      } else if (comment && (comment.length > 0 && comment.length <= 2000)) {
        if (parameterData.isFederation) {
          FederationApplicationReviewService.addReviewerComment(
            parameterData.applicationId,
            $scope.fedReviewdata.federationApplicationReviewBean
              .zoneIdForReview,
            comment
          )
            .then(function(res) {
              $scope.comments = '';
              $scope.addReviewerCommentErrors = [];
              $('#comment').modal('toggle');
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setAddReviewerCommentErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.resetReviewerComment();
                $scope.reviewLogs =
                  $scope.federationApplicationReviewCommentBeanList;
              }
            })
            .catch(function(err) {
              var error =
                'Error adding the reviewer comment for the federation application Id : ' +
                federationApplicationId +
                ' and the zone id ' +
                zoneIdForReview;
              $scope.setAddReviewerCommentErrorMessage(error);
            });

          FederationApplicationReviewService.getFederationReviewDetails(
            parameterData.applicationId
          ).then(function(res) {
            var federationApplicationReviewResponse = res.data;
            $scope.fedReviewdata = res.data;
            $scope.reviewLogs =
              federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
          });
        } else {
          ZoneCenterService.addReviewComment(
            parameterData.applicationId,
            comment
          ).then(function() {
            $scope.comments = '';
            $scope.addReviewerCommentErrors = [];
            $('#comment').modal('toggle');
            ZoneCenterService.getApplicationReviewLogs(
              parameterData.applicationId
            ).then(function(resp) {
              var reviewLogs = resp.data.value;
              $scope.reviewLogs = reviewLogs;
            });
          });
        }
      }
    };

    $scope.resetReviewerComment = function() {
      $scope.comments = '';
      $scope.addReviewerCommentErrors = [];
    };

    //added a parameter to differentiate between fed and ind/mem applicaitons
    $scope.goToDenialReasonsPage = function(isFed) {
      // review-denial-reasons
      $state.go('review-denial-reasons', {
        isFederation: isFed,
        applicationId: parameterData.applicationId,
        sourcePageName: $state.current.name,
        sourcePageParams: $state,
        charityType: parameterData.charityType
      });
    };

    $scope.resetIndependentApplicationReviewStatus = function() {
      $scope.updateIndependentApplicationReviewStatusErrors = [];
      //storing back the original status when cancelled
      vm.appDash.status = vm.appStatus;
    };

    $scope.updateApplicationReview = function(reviewStatus, review) {
      $scope.updateIndependentApplicationReviewStatusErrors = [];
      if (!reviewStatus) {
        $scope.updateIndependentApplicationReviewStatusErrors.push(
          'Please select the application status.'
        );
      } else if (reviewStatus && reviewStatus === 'In Review') {
        $scope.updateIndependentApplicationReviewStatusErrors.push(
          'Please select the application status.'
        );
      } else if (
        reviewStatus &&
        review &&
        reviewStatus === review.applicationReviewStatus
      ) {
        $scope.updateIndependentApplicationReviewStatusErrors.push(
          "The selected application status is same as the existing 'Application Status'. Please select the different application status."
        );
      } else {
        review.applicationId = vm.applicationId;
        review.applicationReviewStatus = reviewStatus;
        vm.appStatus = review.applicationReviewStatus;
        review.applicationReviewStatusUpdatedBy = $scope.getCurrentUser().id;
        review.applicationReviewStatusUpdatedDate = toUTCDate(new Date());
        ZoneCenterService.updateApplicationReview(
          vm.applicationId,
          'APPLICATION_REVIEW',
          review
        ).then(function(res) {
          var ureview = res.data.value;
          $scope.review = ureview;
          $scope.appStatusLastUpdatedDate =
            review.applicationReviewStatusUpdatedDate;
          if (ureview.applicationReviewStatusUpdatedBy != null) {
            ZoneCenterService.getSystemUserById(
              ureview.applicationReviewStatusUpdatedBy
            ).then(function(resp) {
              var user = resp.data;
              if (user !== null && user !== '') {
                $scope.userDetails = user;
                $scope.reviewStatus = ureview.applicationReviewStatus;
                ZoneCenterService.getApplicationReviewLogs(
                  vm.applicationId
                ).then(function(response) {
                  var reviewLogs = response.data.value;
                  $scope.reviewLogs = reviewLogs;
                });
              }
            });
          }
        });
        $('#charityStatus').modal('toggle');
      } // end else
    };

    $scope.resetFederationApplicationReviewStatus = function() {
      //storing back the original status when cancelled
      vm.appDash.applicationStatus = vm.appStatus;
      vm.appDash.federationApplicationReviewStatus =
        vm.appDash.applicationStatus;
      vm.appDash.updateFederationApplicationReviewStatusErrors = [];
    };
    $scope.updateFederationApplicationReviewStatus = function() {
      vm.appDash.updateFederationApplicationReviewStatusErrors = [];
      if (!vm.appDash.applicationStatus) {
        vm.appDash.updateFederationApplicationReviewStatusErrors.push(
          'Please select the application status.'
        );
      } else if (vm.appDash.applicationStatus) {
        var newFederationApplicationReviewStatus = $filter(
          'titleCaseWithSpaces'
        )(
          vm.appDash.applicationStatus === 'SUBMITTED'
            ? 'IN REVIEW'
            : vm.appDash.applicationStatus
        );

        FederationApplicationReviewService.updateFederationApplicationReviewStatus(
          vm.applicationId,
          newFederationApplicationReviewStatus
        )
          .then(
            function(res) {
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                vm.appDash.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                vm &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $('#applicationReviewStatus').modal('toggle');
                vm.appDash.applicationStatus = federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatus.toUpperCase();
                vm.appStatus = vm.appDash.applicationStatus;
                vm.appDash.applicationReviewStatusBy =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatusBy;
                vm.appDash.feapplicationReviewStatusDate =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatusDate;
                vm.appDash.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.reviewLogs =
                  vm.appDash.federationApplicationReviewCommentBeanList;
                vm.appDash.updateFederationApplicationReviewStatusErrors = []; //  $('#applicationReviewStatus').modal().hide();
                //  $('.modal-backdrop').remove();
              }
            },
            function(err) {
              var error =
                'Error updating the Federation Application Review status for the federation application ID : ' +
                vm.applicationId;
              vm.appDash.showFederationApplicationReviewErrorMessage(error);
              reject(err);
            }
          )
          .catch(function(err) {
            reject(err);
          });
      }
    };

    function performRoleChecks() {
      return $q(function(resolve, reject) {
        vm.user = BrowserEventService.getCurrentUser();
        if (
          vm.user.secondaryRoleList &&
          Array.isArray(vm.user.secondaryRoleList)
        ) {
          if (
            vm.user.secondaryRoleList.includes('OPM_ADMIN') ||
            vm.user.secondaryRoleList.includes('OPM_STAFF')
          ) {
            vm.hasAccess = true;
            vm.canEdit = true;
            vm.canGenerateEmails = true;
            vm.canEditStatus = true;
            resolve('has access');
          } else if (vm.user.secondaryRoleList.includes('OPM_AUDITOR')) {
            vm.hasAccess = true;
            vm.canEdit = false;
            resolve('has access');
          } else if (
            vm.user.secondaryRoleList.includes('ZONE_MANAGER') ||
            vm.user.secondaryRoleList.includes('REVIEWER') ||
            vm.user.secondaryRoleList.includes('CUSTOMER_SERVICE_MANAGER') ||
            vm.user.secondaryRoleList.includes('CUSTOMER_SERVICE_TIER_1') ||
            vm.user.secondaryRoleList.includes(
              'CUSTOMER_SERVICE_TIER_2_FINANCE'
            )
          ) {
            // check for respective zone!
            ZoneCenterService.findCharityAccessEligibility(
              vm.user.id,
              vm.appDash.cfcCode
            ).then(
              function(res) {
                if (res.data === true) {
                  vm.hasAccess = true;
                  vm.canEdit = true;
                  resolve('has access');
                } else {
                  vm.hasAccess = false;
                  vm.canEdit = false;
                  reject('no access');
                }
              },
              function(err) {
                $log.error('error establishing user access for this zone', err);
                vm.hasAccess = false;
                vm.canEdit = false;
                reject('no access');
              }
            );
          } else {
            reject('no access');
          }
        } else {
          reject('no access');
        }
      });
    }

    // consistent 'send back' function
    vm.exitToPreviousPage = function() {
      $state.go(vm.sourcePageName, vm.sourcePageParams);
    };

    vm.goToCharityDashboard = function() {
      var dest = 'charitydashboard';
      var opts = {};

      opts.charityDashboardCfcCode = vm.appDash.cfcCode;
      opts.pledgeYear = vm.appDash.pledgeYear;

      $state.go(dest, opts);
    };

    vm.goToApplicationDashboard = function() {
      var dest = null;
      var opts = {};
      if (vm.isFederation) {
        dest = 'federationDashboard';
        opts.federationId = vm.applicationId;
      } else {
        dest = 'applicationDashboard';
        opts.applicationId = vm.applicationId;
      }
      $state.go(dest, opts);
    };

    function getInitials() {
      var initials = '';
      if (userOb && userOb.firstName && userOb.firstName.length >= 1) {
        initials = userOb.firstName.substring(0, 1).toUpperCase();
      }
      if (userOb && userOb.lastName && userOb.lastName.length >= 1) {
        initials += userOb.lastName.substring(0, 1).toUpperCase();
      }
      return initials;
    }

    // initialization sequence
    function init() {
      loadInitialStateValues();
      clearEmailEditFields();
      loadStateParams().then(
        function(success) {
          // role check
          performRoleChecks().then(
            function(success) {},
            function(err) {
              // no access
              vm.exitToPreviousPage();
            }
          );
        },
        function(fail) {
          $log.error(fail);
        }
      );
    }

    // let's begin
    init();

    /* 1871 */
    function initGenerateEmailModal() {
      EmailService.getEmailTemplates(vm.appDash.campaignId).then(
        function(res) {
          $scope.emailTemplates = res.data;
          $scope.emailTemplates.splice(0, 0, {
            templateTypeId: '0',
            displayText: 'Choose:'
          });
          $scope.selectedTemplate = $scope.emailTemplates[0];
        },
        function(error) {
          $log.error(error.data.errorMessage);
        }
      );
    }

    $scope.GenerateEmail = function() {
      EmailService.generateEmail(
        vm.appDash.cfcCode,
        vm.appDash.campaignId,
        vm.applicationId,
        vm.appType,
        $scope.selectedTemplate.templateTypeId
      ).then(
        function(res) {
          // CCA-1872 generating a new email returns the id, passes it through
          getEmailHistoryData(
            vm.appDash.cfcCode,
            vm.applicationId,
            res.data.id
          );
        },
        function(error) {
          $log.error(error.data.errorMessage);
        }
      );
      angular.element('#generateEmail').modal('toggle');
    };

    $scope.CancelGenerateEmail = function() {
      angular.element('#generateEmail').modal('toggle');
    };
    /* 1873 */
    function getEmailHistoryData(cfcCode, applicationId, nwId) {
      EmailService.getEmailHistory(cfcCode, applicationId)
        .then(function(res) {
          $scope.emailHistoryResponse = res.data;
          // CCA-1872 optionally do something for newly created entry from GenerateEmail modal
          if (undefined !== nwId) {
            var matchingEmailHistoryOb = $scope.emailHistoryResponse.find(
              function(el) {
                return el.id === nwId;
              }
            );
            if (undefined !== matchingEmailHistoryOb) {
              vm.loadEmailForViewOrEdit(matchingEmailHistoryOb);
              $('#editEmailModal').modal();
            }
          }
        })
        .catch(function(error) {
          // probably should have gotten a 200, but 404 is being returned for successfully retrieving no records
          if (404 === error.status) {
            $scope.emailHistoryResponse = [];
          } else {
            $log.error(error.data.errorMessage);
          }
        });
    }

    /*
     * CCA-1872
     */

    // clears/inits the fields for vm.emailData, which is used in viewing/editing/sending emails
    function clearEmailEditFields() {
      vm.emailData = {
        body: '',
        to: '',
        cc: '',
        subject: ''
      };
      vm.emailDataLoadError = false;
      vm.emailTypeVerbiage = null;
      vm.emailTypeTitle = null;
      vm.emailEditPlaceholder = null;
      vm.emailDataSelected = null;
    }

    // after load, sets title and verbiage (if any) for the edit/view modal
    function setEmailEditVerbiageAndTitle(emailOb) {
      var res = null;
      switch (emailOb.emailType) {
        case 'Approval':
          vm.emailTypeVerbiage = null;
          vm.emailTypeTitle = 'Send Approval Email to Charity';
          break;
        case 'Approval with Warnings':
          // TODO: verify this, it's not in the mockup, not in the JIRA story
          vm.emailTypeVerbiage = null;
          vm.emailTypeTitle = 'Send Approval Email to Charity';
          break;
        case 'Denial':
          vm.emailTypeVerbiage =
            'If you need to update denial reasons save or delete this draft then click to edit denial reasons. When complete generate a new email.';
          vm.emailTypeTitle = 'Send Denial Email To Charity';
          break;
        case 'Appeal Overturned':
          vm.emailTypeVerbiage = null;
          vm.emailTypeTitle = 'Send Approval Email to Charity';
          break;
        case 'Appeal Denied':
          vm.emailTypeVerbiage =
            'If you need to change the denial reason, delete this draft then click the "View History" button to add new denial reason. This denial reason will not be saved, please copy from below to paste and edit.';
          vm.emailTypeTitle = 'Send Appeal Denial Email to Charity';
          break;
        case 'Custom':
          vm.emailTypeVerbiage = null;
          vm.emailTypeTitle = 'Send Custom Email to Charity';
          break;
        default:
          $log.debug('unknown emailType');
          break;
      }
    }

    // prepares data for an email for viewing or editing, now async (shouldn't change existing behavior)
    vm.loadEmailForViewOrEdit = function(emailHistOb) {
      vm.emailDataSelected = emailHistOb;
      $scope.canSendEmail = true;
      if (
        $scope.selectedTemplate.displayText &&
        (vm.appDash.status || vm.appDash.applicationStatus)
      ) {
        if (
          ($scope.selectedTemplate.displayText === 'Denial (Local)' ||
            $scope.selectedTemplate.displayText ===
              'Denial (National/International)') &&
          (vm.appDash.status
            ? vm.appDash.status.trim().toUpperCase() !== 'DENIED'
            : vm.appDash.applicationStatus.trim().toUpperCase() !== 'DENIED')
        ) {
          $scope.canSendEmail = false;
        }
      }
      return $q(function(resolve, reject) {
        EmailService.getEmail(emailHistOb.id)
          .then(function(res) {
            setEmailEditVerbiageAndTitle(emailHistOb);
            vm.emailData = res.data;
            resolve(res.data);
          })
          .catch(function(err) {
            $log.error(err);
            vm.emailDataLoadError = true;
            reject(err);
          });
      });
    };

    vm.saveEditedEmail = function() {
      EmailService.saveEmail(vm.emailData)
        .then(function(res) {
          // success, nothing further needed
        })
        .catch(function(err) {
          $log.error(err);
        })
        .finally(function() {
          clearEmailEditFields();
          getEmailHistoryData(vm.appDash.cfcCode, vm.applicationId);
        });
    };

    vm.deleteEmailDraft = function() {
      EmailService.deleteEmail(vm.emailData.id)
        .then(function(res) {
          // success, nothing further needed
        })
        .catch(function(err) {
          $log.error(err);
        })
        .finally(function() {
          clearEmailEditFields();
          getEmailHistoryData(vm.appDash.cfcCode, vm.applicationId);
        });
    };

    vm.cancelEditingEmail = function() {
      clearEmailEditFields();
    };

    vm.sendEmail = function() {
      EmailService.sendEmail(vm.emailData)
        .then(function(res) {
          // success, nothing further needed
          vm.error = null;
        })
        .catch(function(err) {
          $log.error(err);
          vm.error = err;
        })
        .finally(function() {
          getEmailHistoryData(vm.appDash.cfcCode, vm.applicationId);
        });
    };

    vm.resendEmail = function() {
      EmailService.cloneEmail(vm.emailData)
        .then(function(resp) {
          var clonedEmailId = resp.data.id;
          EmailService.getEmail(clonedEmailId)
            .then(function(resp) {
              var clonedEmail = resp.data;
              EmailService.sendEmail(clonedEmail)
                .then(function() {})
                .catch(function(err) {
                  $log.error(err);
                })
                .finally(function() {
                  EmailService.getEmailHistory(
                    vm.appDash.cfcCode,
                    vm.applicationId
                  ).then(function(res) {
                    $scope.emailHistoryResponse = res.data;
                  });
                });
            })
            .catch(function(err) {
              $log.error(err);
            });
        })
        .catch(function(err) {
          $log.error(err);
        });
    };
  }
})();
