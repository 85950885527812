'use strict';
(function() {
  angular
    .module('CfcCharity.incompleteMemberListings', [])
    .config([
      '$stateProvider',
      'USER_ROLES',
      function myAppConfig($stateProvider, USER_ROLES) {
        $stateProvider.state('incompleteMemberListings', {
          url: '/incompleteMemberListings',
          views: {
            main: {
              template: require('./incompleteMemberListings.html'),
              controller: 'IncompleteMemberListingsCtrl'
            }
          },
          data: {
            pageTitle: 'Incomplete Member Listings',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            cfcCode: null,
            pledgeYear: null,
            federationName: null
          }
        });
      }
    ])
    .controller('IncompleteMemberListingsCtrl', [
      '$state',
      '$scope',
      'blockUI',
      'FederationListingDashboardService',
      function($state, $scope, blockUI, FederationListingDashboardService) {
        $scope.pledgeYear = $state.params.pledgeYear;
        $scope.cfcCode = $state.params.cfcCode;
        $scope.federationName = $state.params.federationName;
        $scope.orderByField = null;
        $scope.reverseSort = false;

        var init = function() {
          blockUI.start();
          FederationListingDashboardService.getFedMemberValidListingFee(
            $scope.pledgeYear,
            $scope.cfcCode
          )
            .then(function(response) {
              $scope.invalidListings = response.data.invalidListings;
            })
            .catch(function() {})
            .finally(function() {
              blockUI.stop();
            });
        };

        init();
      }
    ]);
})();
