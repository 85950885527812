(function() {
  var moduleName = 'CfcCharity.directives.applicationAppealAlert';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcApplicationAppealAlert', [
    '$state',
    'AppealService',
    'AppealStatus',
    '$log',
    'BrowserEventService',
    function($state, AppealService, AppealStatus, $log, BrowserEventService) {
      return {
        restrict: 'E',
        template: require('./applicationAppealAlert.html'),
        scope: {
          applicationId: '@',
          isFed: '@',
          applicationType: '@',
          cfcCode: '@'
        },
        controller: function($scope, $state, BreadcrumbTokenDataService) {
          $scope.showAppeal = false;
          $scope.showDetermination = false;
          $scope.actionText = 'Appeal Now';
          $scope.appealDueDate = null;
          var secondaryRoleList = BrowserEventService.getCurrentUser()
            .secondaryRoleList;

          function init() {
            AppealService.getApplicationAppeal(
              $scope.applicationType,
              $scope.applicationId,
              $scope.cfcCode
            )
              .then(function(res) {
                var appeal = res.data;

                if (!appeal) {
                  return;
                }
                if ($scope.isFed === 'true') {
                  BreadcrumbTokenDataService.setIsFederation(true);
                  BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                    $scope.applicationId
                  );
                } else {
                  BreadcrumbTokenDataService.setApplicationReviewTokenData(
                    $scope.applicationId
                  );
                }
                $scope.appeal = appeal;
                $scope.appealDueDate = AppealService.toUTCDate(
                  new Date(appeal.appealDueDate)
                );
                $scope.showAppeal =
                  appeal.appealStatus === AppealStatus.NotStarted ||
                  appeal.appealStatus === AppealStatus.Pending;
                $scope.showDetermination =
                  appeal.appealStatus !== AppealStatus.NotStarted;
                $scope.actionText =
                  $scope.showAppeal &&
                  appeal.appealStatus !== AppealStatus.NotStarted
                    ? 'View Appeal'
                    : 'Appeal Now';
              })
              .catch(function(err) {
                if (err.data) {
                  $log.error('error message: ', err.data);
                }
                $log.error(
                  'Error code: ' +
                    err.statusText +
                    ' Error getApplicationAppeal(' +
                    $scope.applicationType +
                    '/' +
                    $scope.applicationId +
                    '/' +
                    $scope.cfcCode +
                    ')'
                );
              });
          }

          $scope.goToAppealPage = function() {
            if (
              secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
              secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
              secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
            ) {
              $state.go('admin-appeal-dashboard', {
                applicationType: $scope.applicationType,
                applicationId: $scope.applicationId
              });
            } else {
              $state.go('appeal-dashboard', {
                isFederation: $scope.isFed,
                applicationId: $scope.applicationId
              });
            }
          };

          init();
        }
      };
    }
  ]);
})();
