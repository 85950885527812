// pledges.service.js - Service for the Admin Zone Listing module

'use strict';

angular
  .module('CfcCharity.admin.zone.pledges')
  .factory('AdminZonePledgesService', AdminZonePledgesService);

AdminZonePledgesService.$inject = ['$http'];

function AdminZonePledgesService($http) {
  var service = {
    getYearsAndZonesList: getYearsAndZonesList,
    getZoneCharityPledgesInfo: getZoneCharityPledgesInfo,
    getZoneCharityPledgesDetails: getZoneCharityPledgesDetails
  };
  return service;

  function getYearsAndZonesList() {
    var url = '/CfcCharity/api/private/admin/zone/yearsAndZonesAct';
    return $http.get(url);
  }

  function getZoneCharityPledgesInfo(campaignId, zoneId) {
    var url =
      '/CfcCharity/api/private/admin/zone/getZoneCharityPledgesSummaryInfo/' +
      campaignId +
      '/' +
      zoneId;
    return $http.post(url);
  }

  function getZoneCharityPledgesDetails(campaignId, zoneId) {
    var url =
      '/CfcCharity/api/private/admin/zone/getZoneCharityPledgesDetailedInfo/' +
      campaignId +
      '/' +
      zoneId;
    return $http.post(url);
  }
}
