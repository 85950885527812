angular
  .module('CfcCharity.addzoneuser', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('addzoneuser', {
        url: '/addzoneuser',
        views: {
          main: {
            template: require('../views/private/admin/addzoneuser.html'),
            controller: 'AddZoneUserCtrl'
          }
        },
        data: {
          pageTitle: 'Add A Zone User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          userId: null,
          zoneId: null,
          zoneName: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          inviteRoleListData: function($http, $stateParams) {
            if ($stateParams.userId != null && $stateParams.zoneId != null) {
              var userId = $stateParams.userId;
              var zoneId = $stateParams.zoneId;
              var url =
                '/CfcCharity/api/private/admin/zone/users/inviteroles/' +
                userId +
                '/' +
                zoneId;
              return $http.get(url).then(function(res) {
                roleListData = { roles: res.data };
                return roleListData;
              });
            }
          }
        }
      });
      $stateProvider.state('addzoneusersuccess', {
        url: '/addzoneusersuccess',
        views: {
          main: {
            template: require('../views/private/admin/addzoneusersuccess.html'),
            controller: 'AddZoneUserSuccessCtrl'
          }
        },
        data: {
          pageTitle: 'Successful Adding A Zone User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          addSystemUserResponse: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          addSystemUserResponse: function($http, $stateParams) {
            if (
              $stateParams.addSystemUserResponse != null &&
              ($stateParams.systemUserSecondLevelRoleList != null &&
                $stateParams.availableSystemUserSecondLevelRoleList != null &&
                ($stateParams.systemUserSecondLevelRoleList.indexOf(
                  $stateParams.availableSystemUserSecondLevelRoleList.gb_admin
                ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .opm_admin
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .opm_staff
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .customer_service_manager
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .zone_manager
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .gb_finance
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .gb_finance_admin
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .customer_service_tier_1
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .customer_service_tier_2_finance
                  ) >= 0))
            ) {
              return $stateParams.addSystemUserResponse;
            }
          }
        }
      });
      $stateProvider.state('addzoneuserchangeroleconfirmation', {
        url: '/addzoneuserchangeroleconfirmation',
        views: {
          main: {
            template: require('../views/private/admin/addzoneuserchangeroleconfirmation.html'),
            controller: 'AddZoneUserChangeRoleConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Change Zone User Role Confirmation',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager
          ]
        },
        params: {
          addSystemUserResponse: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          addSystemUserResponse: function($http, $stateParams) {
            if (
              $stateParams.addSystemUserResponse != null &&
              ($stateParams.systemUserSecondLevelRoleList != null &&
                $stateParams.availableSystemUserSecondLevelRoleList != null &&
                ($stateParams.systemUserSecondLevelRoleList.indexOf(
                  $stateParams.availableSystemUserSecondLevelRoleList.gb_admin
                ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .opm_admin
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .opm_staff
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .customer_service_manager
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .zone_manager
                  ) >= 0))
            ) {
              return $stateParams.addSystemUserResponse;
            }
          }
        }
      });
    }
  ])
  .service('AddZoneUserService', [
    '$http',
    function($http) {
      this.addSystemUser = function(addSystemUserData) {
        var addSystemUserURI = '/CfcCharity/api/private/admin/zone/user/add';
        return $http.post(addSystemUserURI, addSystemUserData);
      };
    }
  ])
  .controller('AddZoneUserCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'inviteRoleListData',
    'AddZoneUserService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      inviteRoleListData,
      AddZoneUserService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.addzoneuser');
      $scope.zoneId = $state.params.zoneId;
      $scope.zoneName = BrowserEventService.getZoneName();
      $scope.inviteRoleListData = inviteRoleListData;
      $scope.addZoneUser = function() {
        if (
          $scope.addZoneUserData === null ||
          $scope.addZoneUserData === undefined ||
          $scope.addZoneUserData.userName === null ||
          $scope.addZoneUserData.userName === undefined
        ) {
          $scope.showAddSystemUserErrorMessage('User Name is required');
          return;
        }
        if (
          $scope.addZoneUserData !== null &&
          $scope.addZoneUserData !== undefined &&
          $scope.addZoneUserData.userName !== null &&
          $scope.addZoneUserData.userName !== undefined &&
          $scope.addZoneUserData.userName.length > 50
        ) {
          $scope.showAddSystemUserErrorMessage(
            'User Name length should be less than 50 characters'
          );
          return;
        }
        if (
          $scope.addZoneUserData === null ||
          $scope.addZoneUserData === undefined ||
          $scope.addZoneUserData.emailId === null ||
          $scope.addZoneUserData.emailId === undefined
        ) {
          $scope.showAddSystemUserErrorMessage('Email is required');
          return;
        }
        if (
          $scope.addZoneUserData !== null &&
          $scope.addZoneUserData !== undefined &&
          $scope.addZoneUserData.emailId !== null &&
          $scope.addZoneUserData.emailId !== undefined &&
          $scope.addZoneUserData.emailId.length > 60
        ) {
          $scope.showAddSystemUserErrorMessage(
            'Email ID length should be between 5 & 60 characters'
          );
          return;
        }
        if (
          $scope.addZoneUserData === null ||
          $scope.addZoneUserData === undefined ||
          $scope.addZoneUserData.role === null ||
          $scope.addZoneUserData.role === undefined ||
          $scope.addZoneUserData.role === 'Select a Role'
        ) {
          $scope.showAddSystemUserErrorMessage('Please select a role');
          return;
        }
        $scope.showAddSystemUserErrorMessage('');
        var addZoneUserData = $scope.addZoneUserData;
        addZoneUserData.invitingUserId = $scope.getCurrentUser().id;
        addZoneUserData.zoneId = $scope.zoneId;
        addZoneUserData.addZoneUser = true;
        AddZoneUserService.addSystemUser(addZoneUserData)
          .then(function(res) {
            var data = res.data;
            var addZoneUserResponse = data;
            if (
              addZoneUserResponse.error ||
              addZoneUserResponse.statusCode === 'ERROR'
            ) {
              $scope.showAddSystemUserErrorMessage(
                addZoneUserResponse.errorResponse.errorCode +
                  ' : ' +
                  addZoneUserResponse.errorResponse.errorMessage +
                  ' Please try again later!'
              );
              return;
            }
            if (
              addZoneUserResponse.statusCode ===
                'SYSTEM_USER_INVITATION_EXIST' ||
              addZoneUserResponse.statusCode === 'USER_DOES_NOT_EXIST' ||
              addZoneUserResponse.statusCode ===
                'USER_SYSTEM_ROLE_DOES_NOT_EXIST' ||
              addZoneUserResponse.statusCode === 'USER_SYSTEM_ROLE_INACTIVE' ||
              addZoneUserResponse.statusCode === 'USER_ACCOUNT_DISABLED' ||
              addZoneUserResponse.statusCode === 'USER_ACCOUNT_SUSPENDED' ||
              addZoneUserResponse.statusCode === 'USER_ACCOUNT_BLOCKED'
            ) {
              $state.go('addzoneusersuccess', {
                addSystemUserResponse: addZoneUserResponse,
                systemUserSecondLevelRoleList:
                  $scope.systemUserSecondLevelRoleList,
                availableSystemUserSecondLevelRoleList:
                  $scope.availableSystemUserSecondLevelRoleList
              });
            } else if (
              addZoneUserResponse.statusCode === 'USER_SYSTEM_ROLE_ACTIVE'
            ) {
              $state.go('addzoneuserchangeroleconfirmation', {
                addSystemUserResponse: addZoneUserResponse,
                systemUserSecondLevelRoleList:
                  $scope.systemUserSecondLevelRoleList,
                availableSystemUserSecondLevelRoleList:
                  $scope.availableSystemUserSecondLevelRoleList
              });
            } else if (
              addZoneUserResponse.statusCode ===
              'USER_SYSTEM_ROLE_ZONE_MANAGER_ACTIVE'
            ) {
              $state.go('addzoneuserchangeroleconfirmation', {
                addSystemUserResponse: addZoneUserResponse,
                systemUserSecondLevelRoleList:
                  $scope.systemUserSecondLevelRoleList,
                availableSystemUserSecondLevelRoleList:
                  $scope.availableSystemUserSecondLevelRoleList
              });
            } else if (
              addZoneUserResponse.statusCode === 'USER_SYSTEM_ROLE_SAME_ROLE'
            ) {
              $state.go('addzoneuserchangeroleconfirmation', {
                addSystemUserResponse: addZoneUserResponse,
                systemUserSecondLevelRoleList:
                  $scope.systemUserSecondLevelRoleList,
                availableSystemUserSecondLevelRoleList:
                  $scope.availableSystemUserSecondLevelRoleList
              });
            } else {
              $state.go('activezoneusers', {
                zoneId: BrowserEventService.getZoneId(),
                zoneName: BrowserEventService.getZoneName(),
                systemUserSecondLevelRoleList:
                  $scope.systemUserSecondLevelRoleList,
                availableSystemUserSecondLevelRoleList:
                  $scope.availableSystemUserSecondLevelRoleList
              });
            }
          })
          .catch(function(err) {
            $scope.showAddSystemUserErrorMessage(
              'Error while adding a system user'
            );
          });
      };
      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };
      $scope.showAddSystemUserErrorMessage = function(error) {
        $scope.addSystemUserErrors = error;
      };
    }
  ])
  .controller('AddZoneUserSuccessCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'addSystemUserResponse',
    'AddSystemUserService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      addSystemUserResponse,
      AddSystemUserService,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.addzoneuser');
      if (addSystemUserResponse != null) {
        $scope.addSystemUserResponse = addSystemUserResponse;
      }
      $scope.gotoZoneActiveUsers = function() {
        $state.go('activezoneusers', {
          zoneId: BrowserEventService.getZoneId(),
          zoneName: BrowserEventService.getZoneName(),
          systemUserSecondLevelRoleList: $scope.systemUserSecondLevelRoleList,
          availableSystemUserSecondLevelRoleList:
            $scope.availableSystemUserSecondLevelRoleList
        });
      };
    }
  ])
  .controller('AddZoneUserChangeRoleConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'addSystemUserResponse',
    'AddSystemUserService',
    'BrowserEventService',
    'UpdateSystemUserService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      addSystemUserResponse,
      AddSystemUserService,
      BrowserEventService,
      UpdateSystemUserService
    ) {
      $scope.setModuleName('CfcCharity.addzoneuser');
      $scope.zoneId = BrowserEventService.getZoneId();
      if (addSystemUserResponse != null) {
        $scope.addSystemUserResponse = addSystemUserResponse;
        if (
          $scope.isSecondaryRoleOPMStaff &&
          addSystemUserResponse.addSystemUserBean &&
          addSystemUserResponse.addSystemUserBean.existingRoleList.length > 0
        ) {
          if (
            addSystemUserResponse.addSystemUserBean.existingRoleList[0] ===
              'OPM Admin' ||
            addSystemUserResponse.addSystemUserBean.existingRoleList[0] ===
              'GB Admin'
          ) {
            $scope.StaffChangeAdminRole = true;
          }
        }
      }
      $scope.gotoZoneActiveUsers = function() {
        $state.go('activezoneusers', {
          zoneId: BrowserEventService.getZoneId(),
          zoneName: BrowserEventService.getZoneName(),
          systemUserSecondLevelRoleList: $scope.systemUserSecondLevelRoleList,
          availableSystemUserSecondLevelRoleList:
            $scope.availableSystemUserSecondLevelRoleList
        });
      };
      $scope.changeSystemUser = function() {
        var updateZoneUserData = {};
        updateZoneUserData.invitingUserId =
          $scope.addSystemUserResponse.addSystemUserBean.invitingUserId;
        updateZoneUserData.invitedUserId =
          $scope.addSystemUserResponse.addSystemUserBean.invitedUserId;
        updateZoneUserData.userSystemRoleId =
          $scope.addSystemUserResponse.addSystemUserBean.userSystemRoleId;
        updateZoneUserData.zoneId = $scope.zoneId;
        updateZoneUserData.role =
          $scope.addSystemUserResponse.addSystemUserBean.role;
        updateZoneUserData.emailId =
          $scope.addSystemUserResponse.addSystemUserBean.emailId;
        updateZoneUserData.invitedUserSystemUser = true;
        UpdateSystemUserService.updateSystemUser(updateZoneUserData)
          .then(function(res) {
            var data = res.data;
            var systemUserAdminData = data.systemUserAdminBean;
            $state.go('updatezoneuser', {
              zoneId: $scope.zoneId,
              zoneName: $scope.zoneName,
              systemUserAdminData: systemUserAdminData,
              systemUserSecondLevelRoleList:
                $scope.systemUserSecondLevelRoleList,
              availableSystemUserSecondLevelRoleList:
                $scope.availableSystemUserSecondLevelRoleList
            });
          })
          .catch(function(err) {
            $scope.showUpdateSystemUserErrorMessage(
              'Error while updating a system user. Please try again later'
            );
          });
      };
    }
  ]);
