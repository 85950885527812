(function() {
  var moduleName = 'CfcCharity.directives.datepicker';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcDatepicker', function() {
    return {
      require: 'ngModel',
      link: function(scope, el, attr, ngModel) {
        var options = {
          constrainInput: true,
          dateFormat: 'mm/dd/yy',
          onSelect: function(dateText) {
            scope.$apply(function() {
              ngModel.$setViewValue(dateText);
            });
          }
        };
        $(el)
          .datepicker(options)
          .val();
      }
    };
  });
})();
