angular
  .module('CfcCharity.optOut', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',    
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider
    ) {
      $stateProvider.state('optOut', {
        url: '/optOut',
        views: {
          main: {
            template: require('../views/public/optOut.html'),
            controller: 'OptOutCtrl'
          }
        },
        data: {
          pageTitle: 'OptOut page'
        },  
        resolve: {
          OptOutSuccess: [
            '$log',
            '$http',
            '$stateParams',
            function($log, $http, $stateParams) {             
            }
          ]
        },      
        params: {
            sid: null
        }       
      });
      $stateProvider.state('optOutSuccess', {
        url: '/optOutSuccess',
        views: {
          main: {
            template: require('../views/public/optOutSuccess.html'),
            controller: 'OptOutCtrl'
          }
        },
        data: {
          pageTitle: 'OptOut Success page'
        },
        resolve: {
          OptOutSuccess: [
            '$log',
            '$http',
            '$stateParams',
            function($log, $http, $stateParams) {
              var url = '/CfcCharity/api/public/subscription/optout/' + $stateParams.sid;
              return $http.put(url).then(function(res) {                
                return res.data.value;
              });
            }
          ]
        },
        params: {
            sid: null
        }       
      });
    }
  ])  
  .controller('OptOutCtrl', [
    '$state',
    '$scope',
    '$location',
    '$http',
    'Session',
    'TokenStorage',
    'BrowserEventService',    
    'OptOutSuccess',
    function(
      $state,
      $scope,
      $location,
      $http,
      Session,
      TokenStorage,
      BrowserEventService,
      OptOutSuccess
    ) {
      $scope.setModuleName('CfcCharity.optOut');
      $scope.paramValue = $location.search().sid;      
      $scope.success = OptOutSuccess; 
    }
  ])
  