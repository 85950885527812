/**
 * @ngdoc Module
 * @author Sarveswar (MS)
 * @date Mar 16, 2016
 * @description This module will take care of all actions on the User Dashboard page
 *
 */
angular
  .module('CfcCharity.opmadminDashboard', ['ui.router', 'CfcCharity.security'])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'USER_ROLES',
    function myAppConfig($stateProvider, $urlRouterProvider, USER_ROLES) {
      $stateProvider.state('opmadmin_dashboard', {
        url: '/opmadmin_dashboard',
        views: {
          main: {
            template: require('../views/private/opmadmin/dashboard.html'),
            controller: 'OpmAdminDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'CFC OPM Admin Dashboard Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.opm_admin]
        }
      });
    }
  ])
  .controller('OpmAdminDashboardCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    function($state, $scope, $location, $rootScope) {}
  ]);
