angular
  .module('CfcCharity.systemmanagement', [
    'ui.router',
    'CfcCharity.security',
    'hateoas',
    'textAngular',
    'angularSpectrumColorpicker',
    'ui.bootstrap.dropdownToggle',
    'CfcCharity.common'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('systemmanagement', {
        url: '/systemmanagement',
        views: {
          main: {
            template: require('../views/private/admin/systemmanagement.html'),
            controller: 'SystemManagementCtrl'
          }
        },
        data: {
          pageTitle: 'System Management',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          userId: null
        },
        resolve: {
          systemManagementData: function(
            $http,
            $stateParams,
            BrowserEventService
          ) {
            var userId = $stateParams.userId
              ? $stateParams.userId
              : BrowserEventService.getCurrentUser().id;
            if (userId != null) {
              var url = '/CfcCharity/api/private/admin/systemmanagement';
              return $http.get(url).then(function(res) {
                return res.data.value;
              });
            }
          }
        }
      }),
        $stateProvider.state('irsmasterfilehist', {
          url: '/irsmasterfilehist',
          views: {
            main: {
              template: require('../views/private/admin/irsMasterFileHistory.html'),
              controller: 'IrsMasterFileHistCtrl'
            }
          },
          data: {
            pageTitle: 'IRS Master Charity File History',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
            ]
          },
          params: {
            userId: null
          },
          resolve: {
            IrsMasterFileHistData: function($http, $stateParams) {
              var url = '/CfcCharity/api/private/admin/irsMasterFileHist';
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }),
        $stateProvider.state('faqadmin', {
          url: '/faqadmin',
          views: {
            main: {
              template: require('../views/private/admin/faqadmin.html'),
              controller: 'faqadminCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Frequently Asked Questions',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
            ]
          },
          resolve: {
            FaqDetailsData: function($http, $stateParams) {
              var url = '/CfcCharity/api/private/admin/faqAdmin';
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }),
        $stateProvider.state('help', {
          url: '/help',
          views: {
            main: {
              template: require('../views/private/admin/help.html'),
              controller: 'helpCtrl'
            }
          },
          data: {
            pageTitle: 'Help',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user, USER_ROLES.charity_user]
          },
          resolve: {
            FaqDetailsData: function($http, $stateParams) {
              var url = '/CfcCharity/api/private/admin/faqHelpDetails';
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }),
        $stateProvider.state('addcampaign', {
          url: '/addcampaign',
          views: {
            main: {
              template: require('../views/private/admin/addcampaign.html'),
              controller: 'AddCampaignCtrl'
            }
          },
          data: {
            pageTitle: 'Add Campaign Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
            ]
          }
        }),
        $stateProvider.state('admin-system-jobs', {
          url: '/admin-system-jobs',
          views: {
            main: {
              template: require('./app/admin/system/jobs/jobs.html'),
              controller: 'SystemJobsCtl'
            }
          },
          data: {
            pageTitle: 'Jobs',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin]
          }
        }),
        $stateProvider.state('admin-system-jobs-history', {
          url: '/admin-system-jobs-history',
          views: {
            main: {
              template: require('./app/admin/system/jobs/jobs-history.html'),
              controller: 'SystemJobsHistoryCtl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: 'Jobs',
                crumbRoute: 'admin-system-jobs',
                crumbParams: ''
              }
            ],
            pageTitle: 'Jobs History',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin]
          },
          params: {
            jobId: null,
            campaignYear: null,
            displayName: null
          }
        }),
        $stateProvider.state('system-banner', {
          url: '/system-banner',
          views: {
            main: {
              template: require('./app/admin/system/banner/banner.html'),
              controller: 'BannerCtl',
              controllerAs: 'vm'
            }
          },
          data: {
            pageTitle: 'Banner',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin]
          }
        }),
        $stateProvider.state('editcampaign', {
          url: '/editcampaign',
          views: {
            main: {
              template: require('../views/private/admin/editcampaign.html'),
              controller: 'EditCampaignCtrl'
            }
          },
          data: {
            pageTitle: 'Edit Campaign Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
            ]
          },
          params: {
            campaignId: null
          },
          resolve: {
            campaignBeanData: function($http, $stateParams) {
              if ($stateParams.campaignId != null) {
                var campaignId = $stateParams.campaignId;
                var url =
                  '/CfcCharity/api/private/admin/editcampaign/' + campaignId;
                return $http.get(url).then(function(res) {
                  return res.data;
                });
              }
            }
          }
        }),
        $stateProvider.state('denialreasonsperyear', {
          url: '/denialreasonsperyear',
          views: {
            main: {
              template: require('../views/private/admin/denialreasonsperyear.html'),
              controller: 'DenialReasonsPerYearCtrl'
            }
          },
          data: {
            pageTitle: 'Denial Reasons Per Year',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
            ]
          },
          params: {
            campaignId: null
          },
          resolve: {
            denialReasonBeanData: function(CubeSvc, $stateParams) {
              if ($stateParams.campaignId != null) {
                var campaignId = $stateParams.campaignId;
                var url =
                  '/CfcCharity/api/private/admin/denialreason/' + campaignId;
                return CubeSvc.get(url).then(function(res) {
                  return res.data.value;
                });
              }
            }
          }
        });
    }
  ])
  .service('SystemManagementListingService', ['$http', function($http) {}])
  .controller('SystemManagementCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$log',
    'systemManagementData',
    'fileUpload',
    'blockUI',
    'blockUIConfig',
    'toastrService',
    'BrowserEventService',
    'SystemManagementListingService',
    'CubeSvc',
    'moment',
    'ValidationService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $log,
      systemManagementData,
      fileUpload,
      blockUI,
      blockUIConfig,
      toastrService,
      BrowserEventService,
      SystemManagementListingService,
      CubeSvc,
      moment,
      ValidationService
    ) {
      $scope.setModuleName('CfcCharity.systemmanagement');
      $('#transferFinishedSuccess').hide();
      $('#transferFinishedFail').hide();
      $scope.btnDisable = true;
      $scope.timeLimitReached = true;
      $scope.sendDate = null;
      $scope.isChanged = false;
      var currentDate = new Date();
      currentDate = currentDate.setDate(currentDate.getDate() + 1);
      currentDate = new Date(currentDate);
      $scope.emailError = null;
      $scope.customEmail = null;
      $scope.isAuditorOrAdmin = false;
      $scope.isAuditor = false;
      $scope.newRec = false;

      $('#sendDate').datepicker({
        minDate: currentDate,
        dateFormat: 'mm/dd/yy'
      });

      $('#editScheduledDate').datepicker({
        minDate: currentDate,
        dateFormat: 'mm/dd/yy'
      });

      $scope.IRSMaster = {};
      if ($scope.getCurrentUser() !== null) {
        BrowserEventService.setUserId($scope.getCurrentUser().id);
      }

      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;

      $scope.secondaryRoleList = secondaryRoleList;

      /* check if user is opm admin */
      if (secondaryRoleList.indexOf('OPM_ADMIN') >= 0) {
        $scope.isOpmAdmin = true;
      }
      if (
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
      ) {
        $scope.isAuditorOrAdmin = true;
      }
      if (secondaryRoleList.indexOf('OPM_AUDITOR') >= 0) {
        $scope.isAuditor = true;
      }

      if (
        systemManagementData != null &&
        systemManagementData !== 'undefined'
      ) {
        $scope.systemManagementData = systemManagementData;
        $scope.email =
          $scope.systemManagementData.eventTriggeredEmailTypesAndAudience;
        if ($scope.email != null && $scope.email !== 'undefined') {
          $scope.email.emailTypes.splice(0, 0, {
            templateTypeId: 0,
            name: '--',
            subject: '',
            body: ''
          });
          $scope.email.audience.splice(0, 0, {
            audienceId: 0,
            name: '--'
          });

          $scope.selectedTemplate = $scope.email.emailTypes[0];
          $scope.selectedAudience = $scope.email.audience[0];
        }
      }
      $scope.lastCampaignId =
        $scope.systemManagementData.campaignBeanList[
          $scope.systemManagementData.campaignBeanList.length - 1
        ].campaignId;
      $scope.campaignBeanListLimit = 100;
      $scope.loadMoreCampaigns = function() {
        if (
          $scope.campaignBeanListLimit <
          $scope.systemManagementData.campaignBeanList.length
        ) {
          $scope.campaignBeanListLimit += 100;
        }
      };

      //This method is invoked to upload IRS Master Charity data file and to parse and Upload to Database.
      $scope.uploadIRSMasterFile = function() {
        blockUI.start();
        var userId = $scope.getCurrentUser().id;
        if ($scope.IRSMaster.supportingFile) {
          var url = '/CfcCharity/api/private/admin/uploadIrsMasterFile';
          fileUpload
            .createFileData(url, $scope.IRSMaster)
            .then(function(res) {
              var data = res.data;
              if (
                data.statusCode !== undefined &&
                data.statusCode === 'ERROR'
              ) {
                blockUI.stop();
                $scope.errorMsg = data.message;
              } else {
                blockUI.stop();
                $('#IRSMasterList').val('');
                $('#IrsMasterFileUploadRedirect').modal();
              }
            })
            .catch(function(err) {
              /*Todo: Log error*/
            });
        } else {
          blockUI.stop();
        }
      };

      //This method is invoked to update the site-wide banner message
      $scope.updateSystemBanner = function() {};

      $scope.showTemplates = function() {
        var id = $scope.selectedTemplate.templateTypeId;
        angular.forEach($scope.email.emailTypes, function(e) {
          if (e.templateTypeId.toString() === id.toString()) {
            $scope.selectedTemplateName = e.name;
            $scope.selectedSubject = e.subject;
            $scope.selectedBody = e.body;
          }
        });
      };

      $scope.cancel = function() {
        $scope.clearAllFields();
      };

      $scope.saveAsDraft = function() {
        $scope.errorMsg = null;
        if ($scope.emailDetails != null || $scope.emailDetails != undefined) {
          if ($scope.newRec) {
            if ($scope.customEmail != null || $scope.customEmail != '') {
              var validateEmail = ValidationService.validateMultipleEmails(
                'Custom Email',
                $scope.customEmail
              );
              //get only valid emails
              var validEmailList = ValidationService.sendValidateEmails(
                'Custom Email',
                $scope.customEmail
              );
              if (validEmailList) {
                $scope.customEmail = validEmailList;
              } else {
                $scope.customEmail = null;
              }
              $scope.emailDetails.emailTo = $scope.customEmail;
              var url =
                '/CfcCharity/api/private/admin/systemmanagement/event/triggered/createemail/0';
              CubeSvc.post(url, $scope.emailDetails)
                .then(function(res) {
                  $scope.systemManagementData = res.data.value;
                  $scope.clearAllFields();
                })
                .catch(function(err) {
                  if (err.status === 504) {
                    $scope.errorMsg =
                      'When selecting a large audience, it may take several minutes to process and appear in the list below. Please reload this page to view that your email was properly generated.';
                  }
                });
            }
          } else {
            if (
              $scope.editSelectedEmailTo != null ||
              $scope.editSelectedEmailTo != ''
            ) {
              var validateEmail = ValidationService.validateMultipleEmails(
                'Custom Email',
                $scope.editSelectedEmailTo
              );

              //get only valid emails
              var validEmailList = ValidationService.sendValidateEmails(
                'Custom Email',
                $scope.editSelectedEmailTo
              );
              if (validEmailList) {
                $scope.editSelectedEmailTo = validEmailList;
              } else {
                $scope.editSelectedEmailTo = null;
              }
              $scope.emailDetails.emailTo = $scope.editSelectedEmailTo;
              var url =
                '/CfcCharity/api/private/admin/systemmanagement/event/triggered/createemail/0';
              CubeSvc.post(url, $scope.emailDetails)
                .then(function(res) {
                  $scope.systemManagementData = res.data.value;
                  $scope.clearAllFields();
                })
                .catch(function(err) {
                  if (err.status === 504) {
                    $scope.errorMsg =
                      'When selecting a large audience, it may take several minutes to process and appear in the list below. Please reload this page to view that your email was properly generated.';
                  }
                });
            }
          }
        }
      };

      $scope.viewEditModal = function(mode, template, audience, id) {
        $scope.mode = mode;
        $scope.newRec = false;
        $scope.errorMsg = null;
        $scope.editSelectedTemplateName = template;
        $scope.selectedId = id;
        angular.forEach(
          $scope.systemManagementData.eventTriggeredEmail,
          function(em) {
            if (em.id.toString() === id.toString()) {
              $scope.editSelectedEmailTo = em.emailTo;
              $scope.editSelectedTemplate = em.templateTypeId;
              $scope.editSelectedScheduledDate = em.scheduledDate;
              $scope.editSelectedSubject = em.subject;
              $scope.editSelectedBody = em.body;
            }
          }
        );
        angular.forEach($scope.email.audience, function(em) {
          if (em.name === audience) {
            $scope.editSelectedAudience = em;
          }
        });
        $('#viewEmail').modal();
      };

      $scope.createEmail = function(id) {
        $scope.newRec = true;
        $scope.errorMsg = null;
        $scope.fieldsMissing = [];
        var emailDetails = {};
        emailDetails.templateTypeId = $scope.selectedTemplate.templateTypeId;
        emailDetails.displayName = $scope.selectedTemplateName;
        emailDetails.emailSubject = $scope.selectedSubject;
        emailDetails.emailBody = $scope.selectedBody;
        emailDetails.audienceId = $scope.selectedAudience.audienceId;
        if ($scope.sendDate != null || $scope.sendDate != undefined) {
          var sendDate = moment(new Date($scope.sendDate).toISOString());
          emailDetails.scheduledDate = sendDate;
        }
        if ($scope.customEmail != null || $scope.customEmail != '') {
          var validateEmail = ValidationService.validateMultipleEmails(
            'Custom Email',
            $scope.customEmail
          );

          if (validateEmail) {
            $scope.fieldsMissing.push(validateEmail);
            $scope.emailDetails = emailDetails;
            $('#missingInfo').modal();
          } else {
            var validEmailList = ValidationService.sendValidateEmails(
              'Custom Email',
              $scope.customEmail
            );
            emailDetails.emailTo = validEmailList;

            $scope.emailDetails = emailDetails;
            var url =
              '/CfcCharity/api/private/admin/systemmanagement/event/triggered/createemail/' +
              id;
            CubeSvc.post(url, $scope.emailDetails)
              .then(function(res) {
                $scope.systemManagementData = res.data.value;
                $scope.clearAllFields();
              })
              .catch(function(err) {
                if (err.status === 504) {
                  $scope.errorMsg =
                    'When selecting a large audience, it may take several minutes to process and appear in the list below. Please reload this page to view that your email was properly generated.';
                }
              });
          }
        } else {
          $scope.emailError = null;
          var validEmailList = ValidationService.sendValidateEmails(
            'Custom Email',
            $scope.customEmail
          );
          emailDetails.emailTo = validEmailList;

          $scope.emailDetails = emailDetails;
          var url =
            '/CfcCharity/api/private/admin/systemmanagement/event/triggered/createemail/' +
            id;
          CubeSvc.post(url, $scope.emailDetails)
            .then(function(res) {
              $scope.systemManagementData = res.data.value;
              $scope.clearAllFields();
            })
            .catch(function(err) {
              if (err.status === 504) {
                $scope.errorMsg =
                  'When selecting a large audience, it may take several minutes to process and appear in the list below. Please reload this page to view that your email was properly generated.';
              }
            });
        }
      };

      $scope.editEmail = function(type, id) {
        $scope.fieldsMissing = [];
        var emailDetails = {};
        $scope.errorMsg = null;
        emailDetails.templateTypeId = $scope.editSelectedTemplate;
        emailDetails.displayName = $scope.editSelectedTemplateName;
        emailDetails.emailSubject = $scope.editSelectedSubject;
        emailDetails.emailBody = $scope.editSelectedBody;
        emailDetails.id = id;
        emailDetails.audienceId = $scope.editSelectedAudience.audienceId;
        if (
          $scope.editSelectedScheduledDate != null ||
          $scope.editSelectedScheduledDate != undefined
        ) {
          var sendDate = moment(
            new Date($scope.editSelectedScheduledDate).toISOString()
          );
          emailDetails.scheduledDate = sendDate;
        }
        if (
          $scope.editSelectedEmailTo != null ||
          $scope.editSelectedEmailTo != ''
        ) {
          var validateEmail = ValidationService.validateMultipleEmails(
            'Custom Email',
            $scope.editSelectedEmailTo
          );

          if (validateEmail) {
            $scope.fieldsMissing.push(validateEmail);
            $scope.emailDetails = emailDetails;
            $('#missingInfo').modal();
          } else {
            $scope.emailError = null;
            var validEmailList = ValidationService.sendValidateEmails(
              'Custom Email',
              $scope.editSelectedEmailTo
            );
            emailDetails.emailTo = validEmailList;

            $scope.emailDetails = emailDetails;
            var url =
              '/CfcCharity/api/private/admin/systemmanagement/event/triggered/createemail/' +
              type;
            CubeSvc.post(url, $scope.emailDetails)
              .then(function(res) {
                $scope.systemManagementData = res.data.value;
                $scope.clearAllFields();
              })
              .catch(function(err) {
                if (err.status === 504) {
                  $scope.errorMsg =
                    'When selecting a large audience, it may take several minutes to process and appear in the list below. Please reload this page to view that your email was properly generated.';
                }
              });
          }
        } else {
          var validEmailList = ValidationService.sendValidateEmails(
            'Custom Email',
            $scope.editSelectedEmailTo
          );
          emailDetails.emailTo = validEmailList;

          $scope.emailDetails = emailDetails;
          var url =
            '/CfcCharity/api/private/admin/systemmanagement/event/triggered/createemail/' +
            type;
          CubeSvc.post(url, $scope.emailDetails)
            .then(function(res) {
              $scope.systemManagementData = res.data.value;
              $scope.clearAllFields();
            })
            .catch(function(err) {
              if (err.status === 504) {
                $scope.errorMsg =
                  'When selecting a large audience, it may take several minutes to process and appear in the list below. Please reload this page to view that your email was properly generated.';
              }
            });
        }
      };

      $scope.deleteEmail = function(id) {
        $scope.errorMsg = null;
        var url =
          '/CfcCharity/api/private/admin/systemmanagement/event/triggered/deleteemail/' +
          id;
        CubeSvc.post(url)
          .then(function(res) {
            $scope.systemManagementData = res.data.value;
            $scope.clearAllFields();
          })
          .catch(function(err) {
            if (err.status === 504) {
              $scope.errorMsg =
                'When selecting a large audience, it may take several minutes to process and appear in the list below. Please reload this page to view that your email was properly generated.';
            }
          });
      };

      $scope.clearAllFields = function() {
        $scope.selectedTemplate = $scope.email.emailTypes[0];
        $scope.selectedAudience = $scope.email.audience[0];
        $scope.sendDate = null;
        $scope.fieldsMissing = [];
        $scope.editSelectedEmailTo = null;
        $scope.customEmail = null;
        $scope.errorMsg = null;
      };

      //get randomize job status
      if ($scope.isOpmAdmin) {
        var jobDetails;
        (jobDetails = function() {
          var url = '/CfcCharity/api/private/admin/getRandomizeJobStatus';
          CubeSvc.get(url, null, false).then(function(res) {
            $scope.job = res.data.value;
            if (
              $scope.job.status === 'Running' ||
              $scope.job.status === 'Failed'
            ) {
              $scope.btnDisable = true;
            } else {
              $scope.btnDisable = false;
            }
            $scope.user = $scope.job.user;
            $scope.timeLimitReached = $scope.job.timeLimitReached;
          });
        })();

        $scope.openModal = function(event) {
          $scope.buttonSelector = '#' + event.target.id;
          $('#confirmRandomization').modal();
        };

        $scope.continueRandomization = function() {
          $scope.job.status = 'Running';
          $scope.timeLimitReached = true;
          var url = '/CfcCharity/api/private/admin/randomizeallfailedzones';
          blockUIConfig.autoBlock = false;
          CubeSvc.get(url, null, false).then(function(res) {
            $scope.btnDisable = res.data.value;
          });
        };

        $scope.randomizeAllZones = function() {
          $scope.job.status = 'Running';
          $scope.timeLimitReached = true;
          var randomize = angular.element($scope.buttonSelector);
          $(randomize).prop('disabled', true);
          var url = '/CfcCharity/api/private/admin/randomizeallzones';
          blockUIConfig.autoBlock = false;
          CubeSvc.get(url, null, false).then(function(res) {
            $scope.btnDisable = res.data.value;
          });
        };

        $scope.checkJobStatus = function() {
          jobDetails();
          $('#randomizationDetail').modal();
        };
      }

      /*transferPledgeDataFromEna()*/
      $scope.transferConfirmation = function() {
        $('#transferConfirmationModal').modal();
      };

      $scope.closeAlert = function(alertId) {
        $('#' + alertId).hide();
      };
      $scope.goToCharitySearchPageByCfcOrEin = function() {
        if (!!$scope.memberApplicationParentCfcCode) {
          $scope.setMemberApplicationParentCfcCode(null);
        }
        if (!!$scope.myMultiSearchTerm) {
          $scope.myMultiSearchTerm = null;
        }
        $state.go('findCharityWithCFCorEIN');
      };
    } //This method is invoked to fetch the IRS Master Charity data file upload history.
  ])
  .controller('IrsMasterFileHistCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$http',
    'IrsMasterFileHistData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $http,
      IrsMasterFileHistData,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.systemmanagement');
      $('.modal-backdrop').remove();
      if (
        IrsMasterFileHistData != null &&
        IrsMasterFileHistData !== 'undefined'
      ) {
        $scope.IrsFileHistData = IrsMasterFileHistData;
        $scope.irsMasterBeanList = [];
        $scope.irsMasterBeanList = $scope.irsMasterBeanList.concat(
          $scope.IrsFileHistData
        );
        $scope.irsMasterBeanListLimit = $scope.irsMasterBeanList.length;
        $scope.loadMoreZones = function() {
          if (
            $scope.irsMasterBeanListLimit <= $scope.irsMasterBeanList.length
          ) {
            $scope.irsMasterBeanListLimit += 100;
          }
        };
      }
    }
  ])
  .controller('faqadminCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$http',
    'FaqDetailsData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $http,
      FaqDetailsData,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.systemmanagement');
      $scope.state = [];
      if (FaqDetailsData != null && FaqDetailsData !== 'undefined') {
        $scope.FaqDetailsData = FaqDetailsData;
        $scope.faqDetailsBeanList = [];
        $scope.faqDetailsBeanList = $scope.faqDetailsBeanList.concat(
          $scope.FaqDetailsData
        );
        $scope.faqDetailsBeanListLimit = $scope.faqDetailsBeanList.length;
        $scope.loadMoreFaqs = function() {
          if (
            $scope.faqDetailsBeanListLimit <= $scope.faqDetailsBeanList.length
          ) {
            $scope.faqDetailsBeanListLimit += 100;
          }
        };
      }
      $scope.cancelAddFaq = function() {
        $('.modal-backdrop').remove();
        $state.reload();
      };
      $scope.change = function(index, oldOrderNumber, orderNumber) {
        if (orderNumber === oldOrderNumber) {
          $scope.state[index] = false;
        } else {
          $scope.state[index] = true;
        }
        return $scope.state[index];
      };
      $scope.addFaqView = function() {
        $http
          .get('/CfcCharity/api/private/admin/maxOrderNum')
          .then(function(res) {
            var data = res.data;
            $scope.addFaq.status = 'Active';
            $scope.addFaq.orderNumber = data;
            $scope.oldOrderNumber = data;
            $('#addFAQ').modal();
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.editFaqView = function(faqId) {
        $http
          .get('/CfcCharity/api/private/admin/getFaq/' + faqId)
          .then(function(res) {
            var data = res.data;
            $scope.editFaq.id = data.id;
            $scope.editFaq.question = data.question;
            $scope.editFaq.answer = data.answer;
            if (data.status === true) {
              $scope.editFaq.status = 'Active';
            } else if (data.status === false) {
              $scope.editFaq.status = 'inactive';
            }
            $scope.editFaq.orderNumber = data.orderNumber;
            $scope.firstName = data.createdBy.firstName;
            $scope.lastName = data.createdBy.lastName;
            $scope.formattedDate = data.formattedDate;
            $scope.oldOrderNumber = data.orderNumber;
            $('#editFAQ').modal();
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.addFaq = function(oldOrderNumber) {
        var userId = $scope.getCurrentUser().id;
        var status = false;
        if ($scope.addFaq.status === 'Active') {
          status = true;
        }
        $http
          .post('/CfcCharity/api/private/admin/addFaq', {
            question: $scope.addFaq.question,
            answer: $scope.addFaq.answer,
            status: status,
            orderNumber: $scope.addFaq.orderNumber,
            oldOrderNumber: oldOrderNumber,
            createdBy: userId
          })
          .then(function(res) {
            $('.modal-backdrop').remove();
            $state.reload();
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.editFaq = function(userId, faqId, oldOrderNumber) {
        var status = false;
        if ($scope.editFaq.status === 'Active') {
          status = true;
        }
        $http
          .post('/CfcCharity/api/private/admin/editFaq', {
            id: faqId,
            question: $scope.editFaq.question,
            answer: $scope.editFaq.answer,
            status: status,
            orderNumber: $scope.editFaq.orderNumber,
            oldOrderNumber: oldOrderNumber,
            createdBy: userId
          })
          .then(function(res) {
            $('.modal-backdrop').remove();
            $state.reload();
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.editFaqOrder = function(
        userId,
        faqId,
        oldOrderNumber,
        orderNumber
      ) {
        $http
          .post('/CfcCharity/api/private/admin/editFaqOrder', {
            id: faqId,
            orderNumber: orderNumber,
            oldOrderNumber: oldOrderNumber,
            createdBy: userId
          })
          .then(function(res) {
            $('.modal-backdrop').remove();
            $state.reload();
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
    }
  ])
  .controller('helpCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$http',
    'FaqDetailsData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $http,
      FaqDetailsData,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.systemmanagement');
      $scope.state = [];
      if (FaqDetailsData != null && FaqDetailsData !== 'undefined') {
        $scope.FaqDetailsData = FaqDetailsData;
        $scope.faqDetailsBeanList = [];
        $scope.faqDetailsBeanList = $scope.faqDetailsBeanList.concat(
          FaqDetailsData
        );
        $scope.faqDetailsBeanListLimit = $scope.faqDetailsBeanList.length;
        $scope.loadMoreFaqs = function() {
          if (
            $scope.faqDetailsBeanListLimit <= $scope.faqDetailsBeanList.length
          ) {
            $scope.faqDetailsBeanListLimit += 100;
          }
        };
      }
      $scope.toggle = function(index) {
        $scope.state[index] = !$scope.state[index];
      };
    }
  ])
  .controller('DenialReasonsPerYearCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$http',
    'CubeSvc',
    'toastrService',
    'denialReasonBeanData',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $http,
      CubeSvc,
      toastrService,
      denialReasonBeanData
    ) {
      $scope.setModuleName('CfcCharity.systemmanagement');
      var user = $scope.getCurrentUser();
      $scope.denialBean = denialReasonBeanData;
      $scope.denialReasons = $scope.denialBean.denialReasons;
      $scope.campaignActive = $scope.denialBean.campaignActive;
      $scope.avialableSteps = $scope.denialBean.steps.filter(s => s != 'ANY');
      $scope.appTypes = $scope.denialBean.appTypes;
      $scope.pledgeYears = $scope.denialBean.pledgeYears;
      $scope.selectedStep = $scope.avialableSteps[0];
      $scope.selectedAppType = $scope.appTypes[0];
      $scope.campaignId = $scope.pledgeYears[0].campaignId;
      $scope.reasonText = '';
      $scope.issueText = '';
      $scope.errorMsg = '';
      $scope.denial = {};

      $scope.denialBeanListLimit =
        $scope.denialReasons === undefined ? 0 : $scope.denialReasons.length;

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      $scope.getSelectedYearData = function(campaignId) {
        var url = '/CfcCharity/api/private/admin/denialreason/' + campaignId;
        CubeSvc.get(url).then(function(res) {
          $scope.denialBean = res.data.value;
          $scope.denialReasons = $scope.denialBean.denialReasons;
          $scope.denialBeanListLimit =
            $scope.denialReasons === undefined
              ? 0
              : $scope.denialReasons.length;
          $scope.campaignActive = $scope.denialBean.campaignActive;
        });
      };

      $scope.addDenialReason = function() {
        $scope.fieldsMissing = [];
        $scope.error = false;

        if (!$scope.selectedStep) {
          $scope.fieldsMissing.push('Step is missing');
        }
        if (!$scope.issueText) {
          $scope.fieldsMissing.push('Issue is missing');
        }
        if (!$scope.reasonText) {
          $scope.fieldsMissing.push('Reason is missing');
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.openmodal = '#editDenialReason';
          $('#missingInfo').modal();
        } else {
          var url = '/CfcCharity/api/private/admin/adddenialreason/';
          CubeSvc.post(url, {
            step: $scope.selectedStep,
            issue: $scope.issueText,
            reason: $scope.reasonText,
            appType: $scope.selectedAppType,
            campaignId: $scope.campaignId
          })
            .then(function(res) {
              $scope.denialReasons = res.data.value;
              $scope.denialBeanListLimit =
                $scope.denialReasons === undefined
                  ? 0
                  : $scope.denialReasons.length;
            })
            .catch(function(err) {});
        }
      };

      $scope.goBack = function() {
        $($scope.openmodal).modal();
      };

      $scope.editDenialreason = function() {
        $scope.fieldsMissing = [];
        $scope.error = false;

        if (!$scope.selectedStep) {
          $scope.fieldsMissing.push('step');
        }
        if (!$scope.issueText) {
          $scope.fieldsMissing.push('issue');
        }
        if (!$scope.reasonText) {
          $scope.fieldsMissing.push('reason');
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.openmodal = '#editDenialReason';
          $('#missingInfo').modal();
        } else {
          var url = '/CfcCharity/api/private/admin/updatedenialreason/';
          CubeSvc.post(url, {
            step: $scope.selectedStep,
            issue: $scope.issueText,
            reason: $scope.reasonText,
            appType: $scope.selectedAppType,
            campaignId: $scope.campaignId,
            id: $scope.selectedId
          })
            .then(function(res) {
              $scope.denialReasons = res.data.value;
              $scope.denialBeanListLimit =
                $scope.denialReasons === undefined
                  ? 0
                  : $scope.denialReasons.length;
              $scope.reasonText = '';
              $scope.issueText = '';
            })
            .catch(function(err) {});
        }
      };

      $scope.cancel = function() {
        $scope.reasonText = '';
        $scope.issueText = '';
      };

      $scope.getModal = function(id) {
        $scope.successMsg = [];
        $scope.selectedId = id;
        $('#deleteDenialReason').modal();
      };

      $scope.getEditModal = function(
        id,
        applicationStep,
        issueName,
        reason,
        applicationType
      ) {
        $scope.successMsg = [];
        $scope.selectedId = id;
        $scope.selectedStep = applicationStep;
        $scope.issueText = issueName;
        $scope.reasonText = reason;
        $scope.selectedAppType = applicationType;
        $('#editDenialReason').modal();
      };

      $scope.deleteDenialReason = function() {
        var url =
          '/CfcCharity/api/private/admin/deletedenialreason/' +
          $scope.selectedId;
        CubeSvc.get(url)
          .then(function(res) {
            $scope.denialReasons = res.data.value;
            $scope.denialBeanListLimit =
              $scope.denialReasons === undefined
                ? 0
                : $scope.denialReasons.length;
          })
          .catch(function(err) {
            $scope.errorMsg =
              'Something went wrong while deleting this zipcode, please try again.';
          });
      };
    }
  ])
  .controller('AddCampaignCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$http',
    'CubeSvc',
    'BrowserEventService',
    'DateFormatService',
    '$filter',
    'toastrService',
    'PLEDGE_YEARS',
    function(
      $log,
      $state,
      $scope,
      $location,
      $rootScope,
      $http,
      CubeSvc,
      BrowserEventService,
      DateFormatService,
      $filter,
      toastrService,
      PLEDGE_YEARS
    ) {
      $scope.setModuleName('CfcCharity.systemmanagement');
      var user = $scope.getCurrentUser();
      $scope.campaignPeriodBean = {};
      $scope.campaignFeeBean = {};
      $scope.campaignPeriodBean.campaignActive = true;
      $scope.displayAppRegDate2023AndBeyond = false;
      $scope.displayAppRegDate2024AndBeyond = false;
      $http
        .post('/CfcCharity/api/private/admin/loadprevcampaign')
        .then(function(res) {
          var data = res.data;
          if (data != null) {
            $scope.getPreCampData = data;
            $scope.campaignFeeBean = data.campaignFeeBean;
            $scope.campaignPeriodBean = data.campaignPeriodBean;
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            var pledgeYear = $scope.campaignPeriodBean.year;
            if (pledgeYear && pledgeYear >= PLEDGE_YEARS.PLEDGE_YEAR_2023) {
              $scope.displayAppRegDate2023AndBeyond = true;
            }
            if (pledgeYear && pledgeYear >= PLEDGE_YEARS.PLEDGE_YEAR_2024) {
              $scope.displayAppRegDate2024AndBeyond = true;
            }
          } else {
            $state.go('systemmanagement', {
              userId: user.id
            });
          }
        })
        .catch(function(err) {
          $scope.campaignPeriodBean = $filter('dateFormatFilter')(
            $scope.campaignPeriodBean
          );
        });

      $http
        .get('/CfcCharity/api/private/admin/loadCampaignGroups')
        .then(function(res) {
          $scope.campaignGroups = res.data;
        })
        .catch(function(err) {
          $log.error(err);
        });

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };
      $scope.addCampaign = function() {
        var addedCampaignPeriodBean = $scope.campaignPeriodBean;
        var addedFee = $scope.campaignFeeBean;

        if (
          $scope.displayAppRegDate2024AndBeyond &&
          (!addedFee.localIndependentApplicationLateFee ||
            !addedFee.localMemberApplicationLateFee ||
            !addedFee.localFederationApplicationLateFee ||
            !addedFee.natIntIndependentApplicationLateFee ||
            !addedFee.natIntMemberApplicationLateFee ||
            !addedFee.natIntFederationApplicationLateFee)
        ) {
          $scope.showErrorMessage('Please enter Application late fee fields');
          return;
        }

        if (
          addedCampaignPeriodBean.year >= 0 &&
          addedFee.localIndependentApplicationFee >= 0 &&
          addedFee.localMemberApplicationFee >= 0 &&
          addedFee.localFederationApplicationFee >= 0 &&
          addedFee.natIntIndependentApplicationFee >= 0 &&
          addedFee.natIntMemberApplicationFee >= 0 &&
          addedFee.natIntFederationApplicationFee >= 0 &&
          addedFee.processingIndFeePercent >= 0 &&
          addedFee.processingMemFeePercent >= 0 &&
          addedFee.processingFedFeePercent >= 0 &&
          addedFee.achProcessingIndFeePercent >= 0 &&
          addedFee.achProcessingMemFeePercent >= 0 &&
          addedFee.achProcessingFedFeePercent >= 0 &&
          addedCampaignPeriodBean.applicationStartDate &&
          addedCampaignPeriodBean.applicationEndDate
        ) {
          addedCampaignPeriodBean = DateFormatService.formatDate(
            $scope.campaignPeriodBean
          );

          if (
            addedCampaignPeriodBean.listingDeadLineDate === null ||
            addedCampaignPeriodBean.listingEndDate >=
              addedCampaignPeriodBean.listingDeadLineDate
          ) {
            $scope.showErrorMessage(
              'Late Deadline should be after Regulatory Deadline '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            addedCampaignPeriodBean.applicationStartDate >
            addedCampaignPeriodBean.applicationEndDate
          ) {
            $scope.showErrorMessage(
              'Application end date should be after Application start date '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            addedCampaignPeriodBean.listingStartDate >=
              addedCampaignPeriodBean.listingEndDate ||
            addedCampaignPeriodBean.listingStartDate >=
              addedCampaignPeriodBean.listingDeadLineDate
          ) {
            $scope.showErrorMessage(
              'Listing Start Date should be before Regulatory Deadline and Late Deadline '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            addedCampaignPeriodBean.applicationEndDate === null ||
            addedCampaignPeriodBean.applicationRegulatoryEndDate >
              addedCampaignPeriodBean.applicationEndDate
          ) {
            $scope.showErrorMessage(
              'Application End Date should be after Regulatory Deadline '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          CubeSvc.post('/CfcCharity/api/private/admin/addcampaign', {
            campaignPeriodBean: addedCampaignPeriodBean,
            campaignFeeBean: $scope.campaignFeeBean
          })
            .then(function(res) {
              var data = res.data;
              if (data.message.type !== CubeSvc.messageType.SUCCESS) {
                $scope.campaignPeriodBean = $filter('dateFormatFilter')(
                  $scope.campaignPeriodBean
                );
              } else {
                $state.go('systemmanagement', {
                  userId: user.id
                });
              }
            })
            .catch(function(err) {
              $scope.campaignPeriodBean = $filter('dateFormatFilter')(
                $scope.campaignPeriodBean
              );
            });
        } else if (!$scope.campaignPeriodBean.year) {
          $scope.showErrorMessage('Please enter Campaign Year');
          return;
        } else if (
          !$scope.campaignPeriodBean.listingDeadLineDate &&
          $scope.campaignPeriodBean.showLateDeadLineData
        ) {
          $scope.showErrorMessage('Please enter Late DeadLine Date');
          return;
        } else if (
          !$scope.campaignPeriodBean.applicationStartDate ||
          !$scope.campaignPeriodBean.applicationEndDate
        ) {
          $scope.showErrorMessage(
            'Please enter Application start date and Application end date'
          );
          return;
        } else {
          $scope.showErrorMessage('Please enter Application fee fields');

          return;
        }
      };
    }
  ])
  .controller('EditCampaignCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$http',
    'CubeSvc',
    'campaignBeanData',
    'BrowserEventService',
    'DateFormatService',
    '$filter',
    'toastrService',
    'PLEDGE_YEARS',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $http,
      CubeSvc,
      campaignBeanData,
      BrowserEventService,
      DateFormatService,
      $filter,
      toastrService,
      PLEDGE_YEARS
    ) {
      $scope.setModuleName('CfcCharity.systemmanagement');
      var user = $scope.getCurrentUser();
      if (campaignBeanData) {
        $scope.campaignPeriodBean = $filter('dateFormatFilter')(
          campaignBeanData.campaignPeriodBean
        );
        $scope.campaignPeriodBean.campaignGroup =
          campaignBeanData.campaignPeriodBean.campaignGroup;
        $scope.campaignFeeBean = campaignBeanData.campaignFeeBean;
      }
      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      $scope.displayAppRegDate2023AndBeyond = false;
      $scope.displayAppRegDate2024AndBeyond = false;
      var pledgeYear = $scope.campaignPeriodBean.year;
      if (pledgeYear && pledgeYear >= PLEDGE_YEARS.PLEDGE_YEAR_2023) {
        $scope.displayAppRegDate2023AndBeyond = true;
      }
      if (pledgeYear && pledgeYear >= PLEDGE_YEARS.PLEDGE_YEAR_2024) {
        $scope.displayAppRegDate2024AndBeyond = true;
      }

      $scope.updateCampaign = function() {
        if (
          $scope.displayAppRegDate2024AndBeyond &&
          (!$scope.campaignFeeBean.localIndependentApplicationLateFee ||
            !$scope.campaignFeeBean.localMemberApplicationLateFee ||
            !$scope.campaignFeeBean.localFederationApplicationLateFee ||
            !$scope.campaignFeeBean.natIntIndependentApplicationLateFee ||
            !$scope.campaignFeeBean.natIntMemberApplicationLateFee ||
            !$scope.campaignFeeBean.natIntFederationApplicationLateFee)
        ) {
          $scope.showErrorMessage('Please enter Application late fee fields');
          return;
        }

        if (
          $scope.campaignPeriodBean.year &&
          $scope.campaignFeeBean.localIndependentApplicationFee !== undefined &&
          $scope.campaignFeeBean.localMemberApplicationFee !== undefined &&
          $scope.campaignFeeBean.localFederationApplicationFee !== undefined &&
          $scope.campaignFeeBean.natIntIndependentApplicationFee !==
            undefined &&
          $scope.campaignFeeBean.natIntMemberApplicationFee !== undefined &&
          $scope.campaignFeeBean.natIntFederationApplicationFee !== undefined &&
          $scope.campaignFeeBean.processingIndFeePercent !== undefined &&
          $scope.campaignFeeBean.processingMemFeePercent !== undefined &&
          $scope.campaignFeeBean.processingFedFeePercent !== undefined &&
          $scope.campaignFeeBean.achProcessingIndFeePercent !== undefined &&
          $scope.campaignFeeBean.achProcessingMemFeePercent !== undefined &&
          $scope.campaignFeeBean.achProcessingFedFeePercent !== undefined
        ) {
          if (
            (!$scope.campaignPeriodBean.listingDeadLineDate &&
              $scope.campaignPeriodBean.showLateDeadLineData) ||
            !$scope.campaignPeriodBean.applicationStartDate ||
            !$scope.campaignPeriodBean.applicationEndDate ||
            (!$scope.campaignPeriodBean.applicationRegulatoryEndDate &&
              $scope.displayAppRegDate2023AndBeyond) ||
            !$scope.campaignPeriodBean.reviewStartDate ||
            !$scope.campaignPeriodBean.appealsStartDate ||
            !$scope.campaignPeriodBean.appealsEndDate ||
            !$scope.campaignPeriodBean.listingStartDate ||
            !$scope.campaignPeriodBean.listingEndDate ||
            !$scope.campaignPeriodBean.pledgeStartDate ||
            !$scope.campaignPeriodBean.pledgeEndDate ||
            !$scope.campaignPeriodBean.donationStartDate ||
            !$scope.campaignPeriodBean.donationEndDate ||
            !$scope.campaignPeriodBean.donationDataAvailable ||
            !$scope.campaignPeriodBean.donorDataAvailable
          ) {
            $scope.showErrorMessage('Please make sure all dates are added ');
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          var updatedCampaignPeriodBean = DateFormatService.formatDate(
            $scope.campaignPeriodBean
          );

          if (
            updatedCampaignPeriodBean.applicationStartDate >
            updatedCampaignPeriodBean.applicationEndDate
          ) {
            $scope.showErrorMessage(
              'Application end date should be after Application start date '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            updatedCampaignPeriodBean.applicationRegulatoryEndDate >
            updatedCampaignPeriodBean.applicationEndDate
          ) {
            $scope.showErrorMessage(
              'Application End Date should be after Regulatory Deadline '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            updatedCampaignPeriodBean.applicationStartDate >=
            updatedCampaignPeriodBean.applicationRegulatoryEndDate
          ) {
            $scope.showErrorMessage(
              'Application Regulatory Deadline should be after Application start date '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            updatedCampaignPeriodBean.listingStartDate >=
              updatedCampaignPeriodBean.listingEndDate ||
            updatedCampaignPeriodBean.listingStartDate >=
              updatedCampaignPeriodBean.listingDeadLineDate
          ) {
            $scope.showErrorMessage(
              'Listing Start Date should be before Regulatory Deadline and Late Deadline '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            updatedCampaignPeriodBean.showLateDeadLineData &&
            updatedCampaignPeriodBean.listingEndDate >=
              updatedCampaignPeriodBean.listingDeadLineDate
          ) {
            $scope.showErrorMessage(
              'Late Deadline should be after Regulatory Deadline '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }

          if (
            updatedCampaignPeriodBean.appealsStartDate >
            updatedCampaignPeriodBean.appealsEndDate
          ) {
            $scope.showErrorMessage(
              'Appeals end date should be after Appeals start date '
            );
            $scope.campaignPeriodBean = $filter('dateFormatFilter')(
              $scope.campaignPeriodBean
            );
            return;
          }
          // example implementation of CubeSvc, reads like $http.post, because that's what it does under the hood
          CubeSvc.post('/CfcCharity/api/private/admin/updatecampaign', {
            campaignPeriodBean: updatedCampaignPeriodBean,
            campaignFeeBean: $scope.campaignFeeBean
          })
            .then(function(res) {
              var data = res.data;
              if (data.message.type === CubeSvc.messageType.SUCCESS) {
                $state.go('systemmanagement', {
                  userId: user.id
                });
              } else {
                $scope.campaignPeriodBean = $filter('dateFormatFilter')(
                  $scope.campaignPeriodBean
                );
              }
            })
            .catch(function(err) {
              /*Todo: Log error*/
              $scope.campaignPeriodBean = $filter('dateFormatFilter')(
                $scope.campaignPeriodBean
              );
            });
        } else if (!$scope.campaignPeriodBean.year) {
          $scope.showErrorMessage('Please enter Campaign Year');
          return;
        } else if (
          !$scope.campaignPeriodBean.applicationStartDate ||
          !$scope.campaignPeriodBean.applicationEndDate
        ) {
          $scope.showErrorMessage(
            'Please enter Application start date and Application end date'
          );
          return;
        } else {
          $scope.showErrorMessage('Please enter Application fee fields');
          return;
        }
      };
    }
  ])

  .service('DateFormatService', function() {
    this.formatDate = function(campaignPeriodBean) {
      if (campaignPeriodBean.applicationStartDate) {
        campaignPeriodBean.applicationStartDate = new Date(
          campaignPeriodBean.applicationStartDate
        );
      }
      if (campaignPeriodBean.applicationEndDate) {
        campaignPeriodBean.applicationEndDate = new Date(
          campaignPeriodBean.applicationEndDate
        );
      }
      if (campaignPeriodBean.applicationRegulatoryEndDate) {
        campaignPeriodBean.applicationRegulatoryEndDate = new Date(
          campaignPeriodBean.applicationRegulatoryEndDate
        );
      }
      if (campaignPeriodBean.reviewStartDate) {
        campaignPeriodBean.reviewStartDate = new Date(
          campaignPeriodBean.reviewStartDate
        );
      }
      if (campaignPeriodBean.listingStartDate) {
        campaignPeriodBean.listingStartDate = new Date(
          campaignPeriodBean.listingStartDate
        );
      }
      if (campaignPeriodBean.listingEndDate) {
        campaignPeriodBean.listingEndDate = new Date(
          campaignPeriodBean.listingEndDate
        );
      }
      if (campaignPeriodBean.listingDeadLineDate) {
        campaignPeriodBean.listingDeadLineDate = new Date(
          campaignPeriodBean.listingDeadLineDate
        );
      }
      if (campaignPeriodBean.appealsStartDate) {
        campaignPeriodBean.appealsStartDate = new Date(
          campaignPeriodBean.appealsStartDate
        );
      }
      if (campaignPeriodBean.appealsEndDate) {
        campaignPeriodBean.appealsEndDate = new Date(
          campaignPeriodBean.appealsEndDate
        );
      }
      if (campaignPeriodBean.pledgeStartDate) {
        campaignPeriodBean.pledgeStartDate = new Date(
          campaignPeriodBean.pledgeStartDate
        );
      }
      if (campaignPeriodBean.pledgeEndDate) {
        campaignPeriodBean.pledgeEndDate = new Date(
          campaignPeriodBean.pledgeEndDate
        );
      }
      if (campaignPeriodBean.donationStartDate) {
        campaignPeriodBean.donationStartDate = new Date(
          campaignPeriodBean.donationStartDate
        );
      }
      if (campaignPeriodBean.donationEndDate) {
        campaignPeriodBean.donationEndDate = new Date(
          campaignPeriodBean.donationEndDate
        );
      }
      if (campaignPeriodBean.donationDataAvailable) {
        campaignPeriodBean.donationDataAvailable = new Date(
          campaignPeriodBean.donationDataAvailable
        );
      }
      if (campaignPeriodBean.donorDataAvailable) {
        campaignPeriodBean.donorDataAvailable = new Date(
          campaignPeriodBean.donorDataAvailable
        );
      }
      return campaignPeriodBean;
    };
  })
  .filter('dateFormatFilter', [
    '$filter',
    function($filter) {
      return function(campaignPeriodBean) {
        if (campaignPeriodBean.applicationStartDate) {
          campaignPeriodBean.applicationStartDate = $filter('date')(
            campaignPeriodBean.applicationStartDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.applicationEndDate) {
          campaignPeriodBean.applicationEndDate = $filter('date')(
            campaignPeriodBean.applicationEndDate,
            'MM/dd/yyyy'
          );
        }

        if (campaignPeriodBean.applicationRegulatoryEndDate) {
          campaignPeriodBean.applicationRegulatoryEndDate = $filter('date')(
            campaignPeriodBean.applicationRegulatoryEndDate,
            'MM/dd/yyyy'
          );
        }

        if (campaignPeriodBean.reviewStartDate) {
          campaignPeriodBean.reviewStartDate = $filter('date')(
            campaignPeriodBean.reviewStartDate,
            'MM/dd/yyyy'
          );
        }

        if (campaignPeriodBean.listingStartDate) {
          campaignPeriodBean.listingStartDate = $filter('date')(
            campaignPeriodBean.listingStartDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.listingEndDate) {
          campaignPeriodBean.listingEndDate = $filter('date')(
            campaignPeriodBean.listingEndDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.listingDeadLineDate) {
          campaignPeriodBean.listingDeadLineDate = $filter('date')(
            campaignPeriodBean.listingDeadLineDate,
            'MM/dd/yyyy'
          );
        }

        if (campaignPeriodBean.appealsStartDate) {
          campaignPeriodBean.appealsStartDate = $filter('date')(
            campaignPeriodBean.appealsStartDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.appealsEndDate) {
          campaignPeriodBean.appealsEndDate = $filter('date')(
            campaignPeriodBean.appealsEndDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.pledgeStartDate) {
          campaignPeriodBean.pledgeStartDate = $filter('date')(
            campaignPeriodBean.pledgeStartDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.pledgeEndDate) {
          campaignPeriodBean.pledgeEndDate = $filter('date')(
            campaignPeriodBean.pledgeEndDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.donationStartDate) {
          campaignPeriodBean.donationStartDate = $filter('date')(
            campaignPeriodBean.donationStartDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.donationEndDate) {
          campaignPeriodBean.donationEndDate = $filter('date')(
            campaignPeriodBean.donationEndDate,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.donationDataAvailable) {
          campaignPeriodBean.donationDataAvailable = $filter('date')(
            campaignPeriodBean.donationDataAvailable,
            'MM/dd/yyyy'
          );
        }
        if (campaignPeriodBean.donorDataAvailable) {
          campaignPeriodBean.donorDataAvailable = $filter('date')(
            campaignPeriodBean.donorDataAvailable,
            'MM/dd/yyyy'
          );
        }
        return campaignPeriodBean;
      };
    }
  ]);
