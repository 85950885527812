(function() {
  'use strict';
  var moduleName = 'CfcCharity.directives.approvalWithWarningMessage';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }
  module.directive('cfcApprovalWithWarningMessage', [
    '$http',
    '$log',
    'CubeSvc',
    'BrowserEventService',
    function($http, $log, CubeSvc, BrowserEventService) {
      return {
        restrict: 'E',
        template: require('./approvalWithWarningMessage.html'),
        scope: {
          campaignId: '@',
          cfcCode: '@'
        },
        controller: function($scope) {
          function init() {
            $scope.hasPriorYearWarning = false;
            $scope.priorYearWarningNote = '';
            var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            if (
              userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
              userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0
            ) {
              if ($scope.campaignId === null || $scope.campaignId === '') {
                $scope.campaignId = BrowserEventService.getCampaignId();
              }
              CubeSvc.get(
                '/CfcCharity/api/private/application/prior/approval/status/' +
                  $scope.cfcCode +
                  '/' +
                  $scope.campaignId,
                null,
                false
              )
                .then(function(resp) {
                  // success
                  if (resp && resp.data) {
                    $scope.warningMessages = resp.data.value;
                  }
                })
                .catch(function(err) {
                  // error
                  $log.error(
                    err.data ||
                      'Previous application status request failed. ' +
                        err.status,
                    err
                  );
                })
                .finally(function() {
                  // finally
                });
            }
          }

          init();
        }
      };
    }
  ]);
})();
