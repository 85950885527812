angular
  .module('CfcCharity.financeadministration', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES,
      FinanceAdministrationService
    ) {
      $stateProvider.state('financeadministration', {
        url: '/financeadministration',
        views: {
          main: {
            template: require('../views/private/finance/financeadministration.html'),
            controller: 'FinanceAdministrationCtrl'
          }
        },
        data: {
          pageTitle: 'Finance Administration',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: { userId: null },
        resolve: {
          financeAdminResponse: function(
            $http,
            $stateParams,
            FinanceAdministrationService,
            BrowserEventService
          ) {
            var userId = $stateParams.userId
              ? $stateParams.userId
              : BrowserEventService.getCurrentUser().id;
            if (userId != null) {
              var url = '/CfcCharity/api/private/finance/' + userId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('financepaymentsearchach', {
        url: '/financepaymentsearchach',
        views: {
          main: {
            template: require('../views/private/finance/paymentDetailsACH.html'),
            controller: 'FinancePaymentSearchACHCtrl'
          }
        },
        data: {
          pageTitle: 'Financial Payment Search',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          type: null,
          value: null,
          financePaymentAchSearchData: null
        }
      });
      $stateProvider.state('financepaymentsearch', {
        url: '/financepaymentsearch',
        views: {
          main: {
            template: require('../views/private/finance/financepaymentsearch.html'),
            controller: 'FinancePaymentSearchCtrl'
          }
        },
        data: {
          pageTitle: 'Financial Payment Search',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          financePaymentSearchData: null,
          type: null,
          value: null
        },
        resolve: {
          financePaymentSearchData: function(
            $http,
            $stateParams,
            FinanceAdministrationService
          ) {
            var userId = 0;
            if (
              !$stateParams.financePaymentSearchData &&
              $stateParams.type &&
              $stateParams.value
            ) {
              var url =
                '/CfcCharity/api/private/finance/paymentsearch/' +
                userId +
                '/' +
                $stateParams.type +
                '/' +
                $stateParams.value;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            } // end if
            else if ($stateParams.financePaymentSearchData) {
              return $stateParams.financePaymentSearchData;
            } // end else
            //    return finPaymentSearchData;
          } // end function
        }
      });
    }
  ])
  .service('FinanceAdministrationService', [
    '$http',
    '$filter',
    function($http, $filter) {
      this.getFinanceAdministrationDetails = function(userId) {
        var url = '/CfcCharity/api/private/finance/' + userId;
        return $http.get(url);
      };
      this.retrieveAllFinancialTransactions = function(
        financialTransactionsRequest
      ) {
        var url = '/CfcCharity/api/private/finance/alltransactions';
        return $http.post(url, financialTransactionsRequest);
      };
      this.retrieveAllFinancialTransactionsDetails = function(
        financialTransactionsRequest
      ) {
        var url =
          '/CfcCharity/api/private/finance/allfinancialtransactionsdetails';
        return $http.post(url, financialTransactionsRequest);
      };
      this.retrieveCreditCardTransactions = function(
        financialTransactionsRequest
      ) {
        var url = '/CfcCharity/api/private/finance/creditcardtransactions';
        return $http.post(url, financialTransactionsRequest);
      };
      this.retrieveACHTransactions = function(financialTransactionsRequest) {
        var url = '/CfcCharity/api/private/finance/achtransactions';
        return $http.post(url, financialTransactionsRequest);
      };
      this.getFinancePaymentSearch = function(userId, type, value) {
        var url =
          '/CfcCharity/api/private/finance/paymentsearch/' +
          userId +
          '/' +
          type +
          '/' +
          value;
        return $http.get(url);
      };
      this.getFinancePaymentSearchACH = function(userId, type, value) {
        var url =
          '/CfcCharity/api/private/finance/paymentsearchACHTransaction/' +
          userId +
          '/' +
          type +
          '/' +
          value;
        return $http.get(url);
      };
      this.preparePaymentSearchBeanListACHForDownload = function(
        paymentSearchBeanListForDownload
      ) {
        var downloadPaymentSearchBeanList = paymentSearchBeanListForDownload;
        if (downloadPaymentSearchBeanList) {
          for (var i = 0; i < downloadPaymentSearchBeanList.length; i++) {
            delete downloadPaymentSearchBeanList[i].cfcCode;
            delete downloadPaymentSearchBeanList[i].paidBy;
          }
        }
        return downloadPaymentSearchBeanList;
      };
      // end preparePaymentSearchBeanListACHForDownload function
      this.preparePaymentSearchBeanListForDownload = function(
        paymentSearchBeanListForDownload
      ) {
        var downloadPaymentSearchBeanList = paymentSearchBeanListForDownload;
        if (downloadPaymentSearchBeanList) {
          for (var i = 0; i < downloadPaymentSearchBeanList.length; i++) {
            delete downloadPaymentSearchBeanList[i].id;
            delete downloadPaymentSearchBeanList[i].$$hashKey;
            delete downloadPaymentSearchBeanList[i].paidByUserId;

            downloadPaymentSearchBeanList[i].paidFor = '';

            if (downloadPaymentSearchBeanList[i].charityName) {
              downloadPaymentSearchBeanList[i].paidFor +=
                downloadPaymentSearchBeanList[i].charityName;
            }

            if (
              downloadPaymentSearchBeanList[i].charityName &&
              downloadPaymentSearchBeanList[i].cfcCode
            ) {
              downloadPaymentSearchBeanList[i].paidFor += ' ';
            }

            if (downloadPaymentSearchBeanList[i].cfcCode) {
              downloadPaymentSearchBeanList[i].paidFor +=
                downloadPaymentSearchBeanList[i].cfcCode;
            }

            delete downloadPaymentSearchBeanList[i].charityName;

            //  delete downloadPaymentSearchBeanList[i].paidFor;
            downloadPaymentSearchBeanList[i].paymentDate = $filter('date')(
              downloadPaymentSearchBeanList[i].paymentDate,
              'MM/dd/yyyy'
            );
            downloadPaymentSearchBeanList[i].cfcFee = $filter('currency')(
              downloadPaymentSearchBeanList[i].cfcFee,
              '$'
            );
            downloadPaymentSearchBeanList[i].processingFee = $filter(
              'currency'
            )(downloadPaymentSearchBeanList[i].processingFee, '$');
            downloadPaymentSearchBeanList[i].totalAmount = $filter('currency')(
              downloadPaymentSearchBeanList[i].totalAmount,
              '$'
            );
            if (downloadPaymentSearchBeanList[i].paymentSuccessful) {
              downloadPaymentSearchBeanList[i].paymentSuccessful = 'Pass';
            } else if (!downloadPaymentSearchBeanList[i].paymentSuccessful) {
              downloadPaymentSearchBeanList[i].paymentSuccessful = 'Fail';
            } // end else
          } // end for loop
        }
        // end if
        return downloadPaymentSearchBeanList;
      };
      // end preparePaymentSearchBeanListForDownload function
      this.downloadPaymentSearchBeanListCreateActivityACH = function(
        transNumber
      ) {};
      // end downloadPaymentSearchBeanListCreateActivity function
      this.downloadPaymentSearchBeanListCreateActivity = function(
        financeAdministrationRequest
      ) {
        var url =
          '/CfcCharity/api/private/finance/downloadpaymentsearch/activity/create';
        return $http.post(url, financeAdministrationRequest);
      }; // end downloadPaymentSearchBeanListCreateActivity function
    }
  ])
  .controller('FinanceAdministrationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'FinanceAdministrationService',
    'financeAdminResponse',
    'BrowserEventService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      FinanceAdministrationService,
      financeAdminResponse,
      BrowserEventService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.financeadministration');
      $scope.financeAdministrationData = {};
      var financeAdministrationResponse = {};
      var userId = $state.params.userId
        ? $state.params.userId
        : BrowserEventService.getCurrentUser().id;
      financeAdministrationResponse.errorResponse = {};
      var error = '';
      if (userId != null && financeAdminResponse) {
        financeAdministrationResponse = financeAdminResponse;
      } else {
        financeAdministrationResponse.errorResponse.errorCode = '5000 : ';
        financeAdministrationResponse.errorResponse.errorMessage =
          'Error retrieving the finance administration details'; // financeAdministrationResponse.errorResponse.errorCode = '5000 : ';
        //  financeAdministrationResponse.errorResponse.errorMessage = 'Error retrieving the finance administration details for the user id : ' + userId;
      }
      if (
        financeAdministrationResponse &&
        (financeAdministrationResponse.error ||
          financeAdministrationResponse.statusCode === 'ERROR')
      ) {
        error =
          financeAdministrationResponse.errorResponse.errorCode +
          ' : ' +
          financeAdministrationResponse.errorResponse.errorMessage +
          '. Please try later';
        //    $scope.showFinanceAdministrationErrorMessage(financeAdministrationResponse.errorResponse.errorCode + ' : ' + // financeAdministrationResponse.errorResponse.errorMessage + '. Please try again later!');
        //   $scope.showFinanceAdministrationErrorMessage(error);
        $scope.financeAdministrationError = error;
      } else if (
        financeAdministrationResponse &&
        financeAdministrationResponse.statusCode === 'SUCCESS'
      ) {
        $scope.financeAdministrationData =
          financeAdministrationResponse.financeAdministrationBean;
        $scope.financeAdministrationAllTransactions = {};
        $scope.financeAdministrationCreditCardTransactions = {};
        $scope.financeAdministrationACHTransactions = {};
      }
      $scope.showFinanceAdministrationErrorMessage = function(error) {
        $scope.financeAdministrationError = error;
      };
      $scope.resetAllFinancialTransactions = function() {
        if ($scope.financeAdministrationAllTransactions) {
          $scope.financeAdministrationAllTransactions.applications = true;
          $scope.financeAdministrationAllTransactions.listings = true;
          $scope.financeAdministrationAllTransactions.start = '';
          $scope.financeAdministrationAllTransactions.end = '';
          $scope.retrieveAllFinancialTransactionsErrors = '';
        }
      };
      $scope.retrieveAllFinancialTransactions = function() {
        $scope.retrieveAllFinancialTransactionsErrors = [];
        if (!$scope.financeAdministrationAllTransactions) {
          $scope.retrieveAllFinancialTransactionsErrors.push(
            'Applications or Listings is required.'
          );
          $scope.retrieveAllFinancialTransactionsErrors.push(
            'start and end dates are required.'
          );
        } else if (
          !$scope.financeAdministrationAllTransactions.applications &&
          !$scope.financeAdministrationAllTransactions.listings
        ) {
          $scope.retrieveAllFinancialTransactionsErrors.push(
            'Applications or Listings is required.'
          );
        } else if (!$scope.financeAdministrationAllTransactions.start) {
          $scope.retrieveAllFinancialTransactionsErrors.push(
            'From date is required.'
          );
        } else if (!$scope.financeAdministrationAllTransactions.end) {
          $scope.retrieveAllFinancialTransactionsErrors.push(
            'To date is required.'
          );
        } else if (
          $scope.financeAdministrationAllTransactions.start &&
          $scope.financeAdministrationAllTransactions.end &&
          new Date($scope.financeAdministrationAllTransactions.start) >
            new Date($scope.financeAdministrationAllTransactions.end)
        ) {
          $scope.retrieveAllFinancialTransactionsErrors.push(
            'From date should be less than To date.'
          );
        } else if (
          $scope.financeAdministrationAllTransactions.applications ||
          ($scope.financeAdministrationAllTransactions.listings &&
            ($scope.financeAdministrationAllTransactions.start &&
              $scope.financeAdministrationAllTransactions.end))
        ) {
          var userId = $scope.getCurrentUser().id;
          var applicationsSelected =
            $scope.financeAdministrationAllTransactions.applications;
          var listingsSelected =
            $scope.financeAdministrationAllTransactions.listings;
          var start = new Date(
            $scope.financeAdministrationAllTransactions.start
          );
          var end = new Date($scope.financeAdministrationAllTransactions.end);
          var financialTransactionsBean = {
            userId: userId,
            applicationsSelected: applicationsSelected,
            listingsSelected: listingsSelected,
            start: start,
            end: end
          };
          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };
          FinanceAdministrationService.retrieveAllFinancialTransactionsDetails(
            financialTransactionsRequest
          )
            .then(function(res) {
              var financialTransactionsResponse = res.data;
              if (
                financialTransactionsResponse &&
                (financialTransactionsResponse.error ||
                  financialTransactionsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error retrieving the financial transactions details for the start date  ' +
                  "'" +
                  $scope.financeAdministrationAllTransactions.start +
                  "'" +
                  ' and end date ' +
                  "'" +
                  $scope.financeAdministrationAllTransactions.end +
                  "'" +
                  '. Please try later';
              } else if (
                financialTransactionsResponse &&
                financialTransactionsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.financialTransactionsData =
                  financialTransactionsResponse.financialTransactionsBean;
                $scope.financialTransactionsData.financialTransactionsRequest = financialTransactionsRequest;
                $state.go('financetransactions', {
                  financialTransactionsData: $scope.financialTransactionsData
                });
                $('#findAllTransactions')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                'Error retrieving the financial transactions for the start date  ' +
                "'" +
                $scope.financeAdministrationAllTransactions.start +
                "'" +
                ' and end date ' +
                "'" +
                $scope.financeAdministrationAllTransactions.end +
                "'" +
                '. Please try later';
              $scope.retrieveAllFinancialTransactionsErrors.push(err.data);
            });
        }
      };
      $scope.resetCreditCardFinancialTransactions = function() {
        if ($scope.financeAdministrationCreditCardTransactions) {
          $scope.financeAdministrationCreditCardTransactions.applications = true;
          $scope.financeAdministrationCreditCardTransactions.listings = true;
          $scope.financeAdministrationCreditCardTransactions.start = '';
          $scope.financeAdministrationCreditCardTransactions.end = '';
          $scope.retrieveCreditCardFinancialTransactionsErrors = '';
        }
      };
      $scope.retrieveCreditCardFinancialTransactions = function() {
        $scope.retrieveCreditCardFinancialTransactionsErrors = [];
        if (!$scope.financeAdministrationCreditCardTransactions) {
          $scope.retrieveCreditCardFinancialTransactionsErrors.push(
            'Applications or Listings is required.'
          );
          $scope.retrieveCreditCardFinancialTransactionsErrors.push(
            'From and To dates are required.'
          );
        } else if (
          !$scope.financeAdministrationCreditCardTransactions.applications &&
          !$scope.financeAdministrationCreditCardTransactions.listings
        ) {
          $scope.retrieveCreditCardFinancialTransactionsErrors.push(
            'Applications or Listings is required.'
          );
        } else if (!$scope.financeAdministrationCreditCardTransactions.start) {
          $scope.retrieveCreditCardFinancialTransactionsErrors.push(
            'From date is required.'
          );
        } else if (!$scope.financeAdministrationCreditCardTransactions.end) {
          $scope.retrieveCreditCardFinancialTransactionsErrors.push(
            'To date is required.'
          );
        } else if (
          $scope.financeAdministrationCreditCardTransactions.start &&
          $scope.financeAdministrationCreditCardTransactions.end &&
          new Date($scope.financeAdministrationCreditCardTransactions.start) >
            new Date($scope.financeAdministrationCreditCardTransactions.end)
        ) {
          $scope.retrieveCreditCardFinancialTransactionsErrors.push(
            'From date should be less than To date.'
          );
        } else if (
          $scope.financeAdministrationCreditCardTransactions.applications ||
          ($scope.financeAdministrationCreditCardTransactions.listings &&
            ($scope.financeAdministrationCreditCardTransactions.start &&
              $scope.financeAdministrationCreditCardTransactions.end))
        ) {
          var userId = $scope.getCurrentUser().id;
          var applicationsSelected =
            $scope.financeAdministrationCreditCardTransactions.applications;
          var listingsSelected =
            $scope.financeAdministrationCreditCardTransactions.listings;
          var start = new Date(
            $scope.financeAdministrationCreditCardTransactions.start
          );
          var end = new Date(
            $scope.financeAdministrationCreditCardTransactions.end
          );
          var financialTransactionsBean = {
            userId: userId,
            applicationsSelected: applicationsSelected,
            listingsSelected: listingsSelected,
            start: start,
            end: end
          };
          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };
          FinanceAdministrationService.retrieveCreditCardTransactions(
            financialTransactionsRequest
          )
            .then(function(res) {
              var creditCardTransactionsResponse = res.data;
              if (
                creditCardTransactionsResponse &&
                (creditCardTransactionsResponse.error ||
                  creditCardTransactionsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error retrieving the financial transactions for the start date  ' +
                  "'" +
                  $scope.financeAdministrationCreditCardTransactions.start +
                  "'" +
                  ' and end date ' +
                  "'" +
                  $scope.financeAdministrationCreditCardTransactions.end +
                  "'" +
                  '. Please try later';
                $scope.retrieveCreditCardFinancialTransactionsErrors.push(
                  error
                );
              } else if (
                creditCardTransactionsResponse &&
                creditCardTransactionsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.creditCardTransactionsData =
                  creditCardTransactionsResponse.financialTransactionsBean;
                $state.go('creditcardtransactions', {
                  creditCardTransactionsData: $scope.creditCardTransactionsData
                });
                $('#findAllCreditCardFinancialTransactions')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                'Error retrieving the financial transactions for the start date  ' +
                "'" +
                $scope.financeAdministrationCreditCardTransactions.start +
                "'" +
                ' and end date ' +
                "'" +
                $scope.financeAdministrationCreditCardTransactions.end +
                "'" +
                '. Please try later';
              $scope.retrieveCreditCardFinancialTransactionsErrors.push(error);
            });
        }
      };
      $scope.resetACHFinancialTransactions = function() {
        if ($scope.financeAdministrationACHTransactions) {
          $scope.financeAdministrationACHTransactions.applications = true;
          $scope.financeAdministrationACHTransactions.listings = true;
          $scope.financeAdministrationACHTransactions.start = '';
          $scope.financeAdministrationACHTransactions.end = '';
          $scope.retrieveACHFinancialTransactionsErrors = '';
        }
      };
      $scope.retrieveACHFinancialTransactions = function() {
        $scope.retrieveACHFinancialTransactionsErrors = [];
        if (!$scope.financeAdministrationACHTransactions) {
          $scope.retrieveACHFinancialTransactionsErrors.push(
            'Applications or Listings is required.'
          );
          $scope.retrieveACHFinancialTransactionsErrors.push(
            'From and To dates are required.'
          );
        } else if (
          !$scope.financeAdministrationACHTransactions.applications &&
          !$scope.financeAdministrationACHTransactions.listings
        ) {
          $scope.retrieveACHFinancialTransactionsErrors.push(
            'Applications or Listings is required.'
          );
        } else if (!$scope.financeAdministrationACHTransactions.start) {
          $scope.retrieveACHFinancialTransactionsErrors.push(
            'From date is required.'
          );
        } else if (!$scope.financeAdministrationACHTransactions.end) {
          $scope.retrieveACHFinancialTransactionsErrors.push(
            'To date is required.'
          );
        } else if (
          $scope.financeAdministrationACHTransactions.start &&
          $scope.financeAdministrationACHTransactions.end &&
          new Date($scope.financeAdministrationACHTransactions.start) >
            new Date($scope.financeAdministrationACHTransactions.end)
        ) {
          $scope.retrieveACHFinancialTransactionsErrors.push(
            'From date should be less than To date.'
          );
        } else if (
          $scope.financeAdministrationACHTransactions.applications ||
          ($scope.financeAdministrationACHTransactions.listings &&
            $scope.financeAdministrationACHTransactions.start &&
            $scope.financeAdministrationACHTransactions.end)
        ) {
          var userId = $scope.getCurrentUser().id;
          var applicationsSelected =
            $scope.financeAdministrationACHTransactions.applications;
          var listingsSelected =
            $scope.financeAdministrationACHTransactions.listings;
          var start = new Date(
            $scope.financeAdministrationACHTransactions.start
          );
          var end = new Date($scope.financeAdministrationACHTransactions.end);
          var financialTransactionsBean = {
            userId: userId,
            applicationsSelected: applicationsSelected,
            listingsSelected: listingsSelected,
            start: start,
            end: end
          };
          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };
          FinanceAdministrationService.retrieveACHTransactions(
            financialTransactionsRequest
          )
            .then(function(res) {
              var achTransactionsResponse = res.data;
              if (
                achTransactionsResponse &&
                (achTransactionsResponse.error ||
                  achTransactionsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error retrieving the ACH financial transactions for the start date  ' +
                  "'" +
                  $scope.financeAdministrationACHTransactions.start +
                  "'" +
                  ' and end date ' +
                  "'" +
                  $scope.financeAdministrationACHTransactions.end +
                  "'" +
                  '. Please try later';
                $scope.retrieveACHFinancialTransactionsErrors.push(error);
              } else if (
                achTransactionsResponse &&
                achTransactionsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.achTransactionsData =
                  achTransactionsResponse.financialTransactionsBean;
                $state.go('achtransactions', {
                  achTransactionsData: $scope.achTransactionsData
                });
                $('#findAllACHFinancialTransactions')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                'Error retrieving the financial transactions for the start date  ' +
                "'" +
                $scope.financeAdministrationACHTransactions.start +
                "'" +
                ' and end date ' +
                "'" +
                $scope.financeAdministrationACHTransactions.end +
                "'" +
                '. Please try later';
              $scope.retrieveACHFinancialTransactionsErrors.push(error);
            });
        }
      };
      $scope.showFinancialTransactionsErrorMessage = function(error) {
        $scope.retrieveAllFinancialTransactionsErrors = error;
      };
      $scope.showCreditCardTransactionsErrorMessage = function(error) {
        $scope.retrieveCreditCardFinancialTransactionsErrors = error;
      };
      $scope.showACHTransactionsErrorMessage = function(error) {
        $scope.retrieveACHFinancialTransactionsErrors = error;
      };
      $scope.paymentSearchACH = function(type, value) {
        var userId = $scope.getCurrentUser().id;
        FinanceAdministrationService.getFinancePaymentSearchACH(
          userId,
          type,
          value
        ).then(function(res) {
          var data = res.data;
          if (data.statusText === 'Record Not Found') {
            if ('ACH' === type) {
              $scope.paymentSearchErrorMessage =
                'We could not find any transactions with the Transaction Number provided by you';
            }
          } else if (data && data.achtransctionNumberResultList.length > 0) {
            $state.go('financepaymentsearchach', {
              type: type,
              value: value,
              financePaymentAchSearchData: data
            });
          }
        });
      };
      $scope.paymentSearch = function(type, value) {
        if (value) {
          FinanceAdministrationService.getFinancePaymentSearch(
            $scope.getCurrentUser().id,
            type,
            value
          ).then(function(res) {
            var data = res.data;
            if (!data || data.length === 0) {
              if ('CREDIT_CARD' === type) {
                $scope.paymentSearchErrorMessage =
                  'We could not find any transactions with the credit card number ' +
                  value +
                  ' provided by you';
              } else {
                $scope.paymentSearchErrorMessage =
                  'We could not find any transactions with the authorization code ' +
                  value +
                  ' provided by you';
              }
            } else if (data && data.length > 0) {
              var financePaymentSearchInputData = {
                type: type,
                value: value
              };
              BrowserEventService.setFinancePaymentSearchInputData(
                financePaymentSearchInputData
              );
              $state.go('financepaymentsearch', {
                financePaymentSearchData: data,
                type: type,
                value: value
              });
            }
          });
        } else if (!value) {
          if ('CREDIT_CARD' === type) {
            $scope.paymentSearchErrorMessage =
              'We could not find any transactions with the credit card number provided by you';
          } else {
            $scope.paymentSearchErrorMessage =
              'We could not find any transactions with the authorization code provided by you';
          }
        }
      }; // end paymentSearch function
    }
  ])
  .controller('FinancePaymentSearchCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'financePaymentSearchData',
    'FinanceAdministrationService',
    'BrowserEventService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      financePaymentSearchData,
      FinanceAdministrationService,
      BrowserEventService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.financetransactions');
      // var financePaymentSearchData = $state.params.financePaymentSearchData;
      var type = $state.params.type;
      var value = $state.params.value;
      $scope.type = $state.params.type;
      $scope.value = $state.params.value;
      if ($scope.type) {
        BrowserEventService.setType($scope.type);
        BrowserEventService.setValue($scope.value);
      }
      var financePaymentSearchInputData = {};
      if (type && value) {
        financePaymentSearchInputData = {
          type: type,
          value: value
        };
        BrowserEventService.setFinancePaymentSearchInputData(
          financePaymentSearchInputData
        );
      }
      if (
        !type &&
        !value &&
        BrowserEventService.getFinancePaymentSearchInputData()
      ) {
        financePaymentSearchInputData = BrowserEventService.getFinancePaymentSearchInputData();
        type = financePaymentSearchInputData.type;
        value = financePaymentSearchInputData.value;
      }
      if (financePaymentSearchData && financePaymentSearchData.length > 0) {
        $scope.paymentSearchBeanList = financePaymentSearchData;
        if ('CREDIT_CARD' === type) {
          $scope.paymentSearchValue = 'Credit Card # ' + value;
          $scope.paymentSearchType = 'DOWNLOAD_CREDIT_CARD_SEARCH_RESULTS';
        } else {
          $scope.paymentSearchValue = 'Authorization Code ' + value;
          $scope.paymentSearchType =
            'DOWNLOAD_AUTHORIZATION_CODE_SEARCH_RESULTS';
        }
        if ($scope.paymentSearchBeanList) {
          $scope.downloadPaymentSearchBeanListFileName = '';
          $scope.currentDate = $filter('date')(new Date(), 'MMddyyyy');
          $scope.downloadPaymentSearchBeanListFileName =
            $scope.paymentSearchValue +
            '_Transactions_' +
            $scope.currentDate +
            '.csv';
          $scope.downloadPaymentSearchBeanList = {};
          var paymentSearchBeanListForDownload = angular.copy(
            $scope.paymentSearchBeanList
          );
          $scope.downloadPaymentSearchBeanList = FinanceAdministrationService.preparePaymentSearchBeanListForDownload(
            paymentSearchBeanListForDownload
          );
        } // end if paymentSearchBeanList
      }
      // end if financePaymentSearchData > 0
      $scope.downloadPaymentSearchCreateActivity = function() {
        var userId = $scope.getCurrentUser().id;
        var financeAdministrationBean = {
          userId: userId,
          paymentSearchType: $scope.paymentSearchType
        };
        var financeAdministrationRequest = {
          financeAdministrationBean: financeAdministrationBean
        };
        var error = '';
        if (financeAdministrationRequest) {
          error =
            'Error occured while download the payment search list create activity';
          FinanceAdministrationService.downloadPaymentSearchBeanListCreateActivity(
            financeAdministrationRequest
          )
            .then(function(res) {
              var downloadPaymentSearchBeanListCreateActivityResponse =
                res.data;
              if (
                downloadPaymentSearchBeanListCreateActivityResponse &&
                (downloadPaymentSearchBeanListCreateActivityResponse.error ||
                  downloadPaymentSearchBeanListCreateActivityResponse.statusCode ===
                    'ERROR')
              ) {
                $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
                  error
                );
              } else if (
                downloadPaymentSearchBeanListCreateActivityResponse &&
                downloadPaymentSearchBeanListCreateActivityResponse.statusCode ===
                  'SUCCESS'
              ) {
                $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
                  ''
                );
              }
            })
            .catch(function(err) {
              $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
                error
              );
            }); // end error
        } else {
          $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
            error
          );
        } // end else
      };
      // end downloadCreditCardTransactionsCreateActivity function
      $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage = function(
        error
      ) {
        $scope.downloadPaymentSearchBeanListCreateActivityErrorMessage = error;
      };
      $scope.paymentDetailsPopup = function(paymentSearch) {
        $scope.paymentSearch = paymentSearch;
        $('#paymentDetails').modal();
      };
      $scope.gotToUserResults = function(paidBy) {
        $('.modal-backdrop').remove();
        $state.go('userSearchResults', { userId: paidBy });
      };
      $scope.gotToCharityDashboard = function(cfcCode) {
        $('.modal-backdrop').remove();
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
    }
  ])
  .controller('FinancePaymentSearchACHCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'FinanceAdministrationService',
    'BrowserEventService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      FinanceAdministrationService,
      BrowserEventService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.financetransactions');
      var type = $state.params.type;
      var value = $state.params.value;
      $scope.type = $state.params.type;
      $scope.value = $state.params.value;
      $scope.financePaymentAchSearchData =
        $state.params.financePaymentAchSearchData;
      if ($scope.type) {
        BrowserEventService.setType($scope.type);
        BrowserEventService.setValue($scope.value);
      }
      if ($scope.financePaymentAchSearchData) {
        $scope.paymentSearchBeanList = $scope.financePaymentAchSearchData;
        $scope.downloadPaymentSearchBeanListFileName = '';
        $scope.currentDate = $filter('date')(new Date(), 'MMddyyyy');
        $scope.downloadPaymentSearchBeanListFileName =
          'ACH_Transactions_' + $scope.currentDate + '.csv';
        $scope.downloadPaymentSearchBeanList = {};
        if ($scope.paymentSearchBeanList) {
          var paymentSearchBeanListForDownload = angular.copy(
            $scope.paymentSearchBeanList.achtransctionNumberResultList
          );
          $scope.downloadPaymentSearchBeanList = FinanceAdministrationService.preparePaymentSearchBeanListACHForDownload(
            paymentSearchBeanListForDownload
          );
        }
      } else {
        //Download Start
        FinanceAdministrationService.getFinancePaymentSearchACH(
          $scope.getCurrentUser().id,
          type,
          value
        ).then(function(res) {
          $scope.paymentSearchBeanList = res.data;
          $scope.downloadPaymentSearchBeanListFileName = '';
          $scope.currentDate = $filter('date')(new Date(), 'MMddyyyy');
          $scope.downloadPaymentSearchBeanListFileName =
            'ACH_Transactions_' + $scope.currentDate + '.csv';
          $scope.downloadPaymentSearchBeanList = {};
          if ($scope.paymentSearchBeanList) {
            var paymentSearchBeanListForDownload = angular.copy(
              $scope.paymentSearchBeanList.achtransctionNumberResultList
            );
            $scope.downloadPaymentSearchBeanList = FinanceAdministrationService.preparePaymentSearchBeanListACHForDownload(
              paymentSearchBeanListForDownload
            );
          }
        }); //Download End
      }
      $scope.paymentDetailsPopupACH = function(paymentSearchACH) {
        $scope.paymentSearchACH = paymentSearchACH;
        $('#paymentDetails').modal();
      };
      $scope.gotToCharityDashboard = function(cfcCode) {
        $('.modal-backdrop').remove();
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
      //Added for Paid By
      $scope.gotToUserResults = function(paidBy) {
        $('.modal-backdrop').remove();
        $state.go('userSearchResults', { userId: paidBy });
      };
      //For Download Function  Start---
      $scope.downloadPaymentSearchBeanListCreateActivityACH = function() {
        var userId = $scope.getCurrentUser().id;
        var transNumber = $scope.value;
        $scope.paymentSearchType = 'DOWNLOAD_ACH_TRANSACTIONS';
        var financeAdministrationBean = {
          userId: userId,
          paymentSearchType: $scope.paymentSearchType
        };
        var financeAdministrationRequest = {
          financeAdministrationBean: financeAdministrationBean
        };
        var error = '';
        if (financeAdministrationRequest) {
          error =
            'Error occured while download the payment search list create activity';
          FinanceAdministrationService.downloadPaymentSearchBeanListCreateActivity(
            financeAdministrationRequest
          )
            .then(function(res) {
              var downloadPaymentSearchBeanListCreateActivityResponse =
                res.data;
              if (
                downloadPaymentSearchBeanListCreateActivityResponse &&
                (downloadPaymentSearchBeanListCreateActivityResponse.error ||
                  downloadPaymentSearchBeanListCreateActivityResponse.statusCode ===
                    'ERROR')
              ) {
                $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
                  error
                );
              } else if (
                downloadPaymentSearchBeanListCreateActivityResponse &&
                downloadPaymentSearchBeanListCreateActivityResponse.statusCode ===
                  'SUCCESS'
              ) {
                $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
                  ''
                );
              }
            })
            .catch(function(err) {
              $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
                error
              );
            }); // end error
        } else {
          $scope.showDownloadPaymentSearchBeanListCreateActivityErrorMessage(
            error
          );
        } // end else
      }; // end downloadCreditCardTransactionsCreateActivity function
      //End Download
    }
  ]);
