angular
  .module('CfcCharity.paymenthistory', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('paymenthistory', {
        url: '/paymenthistory',
        views: {
          main: {
            template: require('../views/private/finance/paymenthistory.html'),
            controller: 'PaymentHistoryCtrl'
          }
        },
        data: {
          pageTitle: 'Payment History',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          userId: null,
          cfcCode: null,
          charityName: null
        },
        resolve: {
          paymentHistoryRes: function($http, $stateParams) {
            if ($stateParams.userId && $stateParams.cfcCode) {
              var url =
                '/CfcCharity/api/private/finance/payment/history/confirmed/' +
                $stateParams.userId +
                '/' +
                $stateParams.cfcCode;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            } // end if
          }, // end function
          paymentHistoryFed: function(CubeSvc, $stateParams) {
            if ($stateParams.userId && $stateParams.cfcCode) {
              var url =
                '/CfcCharity/api/private/charity/getFederationListingPaymentInfo/all' +
                '/' +
                $stateParams.cfcCode;
              return CubeSvc.get(url).then(function(res1) {
                return res1.data.value;
              });
            } // end if
          } // end function
        } // end resolve
      });
    }
  ])
  .service('PaymentHistoryService', [
    '$http',
    '$filter',
    'CubeSvc',
    function($http, $filter, CubeSvc) {
      this.retrieveConfirmedPayments = function(userId, cfcCode, charityName) {
        var url =
          '/CfcCharity/api/private/finance/payment/history/confirmed/' +
          userId +
          '/' +
          cfcCode;
        return $http.get(url);
      }; // end retrieveConfirmedPayments function

      this.retrieveFailedPayments = function(userId, cfcCode, charityName) {
        var url =
          '/CfcCharity/api/private/finance/payment/history/failed/' +
          userId +
          '/' +
          cfcCode;
        return $http.get(url);
      }; // end retrieveConfirmedPayments function

      this.getFederationListingPaymentInfo = function(cfcCode, pledgeYear) {
        var url =
          '/CfcCharity/api/private/charity/getFederationListingPaymentInfo/' +
          pledgeYear +
          '/' +
          cfcCode;
        return CubeSvc.get(url);
      };
    } // end PaymentHistoryService function
  ])
  .controller('PaymentHistoryCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'paymentHistoryRes',
    'paymentHistoryFed',
    'PaymentHistoryService',
    'BrowserEventService',
    'FinanceTransactionsService',
    'DownloadService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      paymentHistoryRes,
      paymentHistoryFed,
      PaymentHistoryService,
      BrowserEventService,
      FinanceTransactionsService,
      DownloadService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.paymenthistory');
      $scope.paymentHistoryData = {};
      $scope.hasAccess = false;
      var paymentHistoryResponse = {};
      var userId = null;
      paymentHistoryResponse.errorResponse = {};
      var error = '';

      $scope.showPaymentHistoryErrorMessage = function(error) {
        $scope.paymentHistoryError = error;
      };
      if (paymentHistoryRes) {
        userId = $state.params.userId;
        var userRole = $scope.getCurrentUser().secondaryRoleList;
        if (
          userRole.indexOf('OPM_ADMIN') >= 0 ||
          userRole.indexOf('GB_FINANCE_ADMIN') >= 0
        ) {
          $scope.hasAccess = true;
        }
        paymentHistoryResponse = paymentHistoryRes;
        if (paymentHistoryFed.length > 0) {
          for (var i = 0; i < paymentHistoryFed.length; i++) {
            for (
              var j = 0;
              j <
              paymentHistoryResponse.paymentHistoryBean.paymentBeanList.length;
              j++
            ) {
              if (
                paymentHistoryResponse.paymentHistoryBean.paymentBeanList[j]
                  .year === paymentHistoryFed[i].year &&
                paymentHistoryFed[i].totalAmountDue != null
              )
                paymentHistoryResponse.paymentHistoryBean.paymentBeanList[
                  j
                ].amountDue = paymentHistoryFed[i].totalAmountDue;
            }
          }
        }
      } else {
        paymentHistoryResponse.errorResponse.errorCode = '5000 : ';
        paymentHistoryResponse.errorResponse.errorMessage =
          'Error retrieving the pending payments';
      }

      if (
        paymentHistoryResponse &&
        (paymentHistoryResponse.error ||
          paymentHistoryResponse.statusCode === 'ERROR')
      ) {
        error =
          paymentHistoryResponse.errorResponse.errorCode +
          ' : ' +
          paymentHistoryResponse.errorResponse.errorMessage +
          '. Please try later';

        $scope.showPaymentHistoryErrorMessage(error);
      } else if (
        paymentHistoryResponse &&
        paymentHistoryResponse.statusCode === 'SUCCESS'
      ) {
        $scope.paymentHistoryData = paymentHistoryResponse.paymentHistoryBean;
        var paymentHistoryRequestData = {
          userId: $scope.paymentHistoryData.userId,
          cfcCode: $scope.paymentHistoryData.cfcCode,
          charityName: $scope.paymentHistoryData.charityName
        };
        BrowserEventService.setPaymentHistoryRequestData(
          paymentHistoryRequestData
        );

        $scope.paymentHistoryData.confirmedPaymentsTabPaneActive = true;
        $scope.paymentHistoryData.failedPaymentsTabPaneActive = false;
      }

      $scope.showPaymentHistoryErrorMessage = function(error) {
        $scope.paymentHistoryError = error;
      };

      $scope.paymentBeanListLimit = 100;
      $scope.loadMorePayments = function() {
        if (
          $scope.paymentHistoryData &&
          $scope.paymentHistoryData.paymentBeanList &&
          $scope.paymentBeanListLimit <
            $scope.paymentHistoryData.paymentBeanList.length
        ) {
          $scope.paymentBeanListLimit += 100;
        }
      };

      $scope.retrieveConfirmedPayments = function(
        userId,
        cfcCode,
        charityName
      ) {
        if (cfcCode) {
          PaymentHistoryService.retrieveConfirmedPayments(
            userId,
            cfcCode,
            charityName
          )
            .then(function(res) {
              var data = res.data;

              var paymentHistoryResponse = data;

              if (
                paymentHistoryResponse &&
                (paymentHistoryResponse.error ||
                  paymentHistoryResponse.statusCode === 'ERROR')
              ) {
                error =
                  paymentHistoryResponse.errorResponse.errorCode +
                  ' : ' +
                  paymentHistoryResponse.errorResponse.errorMessage +
                  '. Please try later';
                //  error = "Error occured while retrieving the Credit Card payment details ";
                // $scope.paymentHistoryError = error;
                $scope.showPaymentHistoryErrorMessage(error);
              } else if (
                paymentHistoryResponse &&
                paymentHistoryResponse.statusCode === 'SUCCESS'
              ) {
                $scope.paymentHistoryData =
                  paymentHistoryResponse.paymentHistoryBean;
                $scope.paymentHistoryData.confirmedPaymentsTabPaneActive = true;
                $scope.paymentHistoryData.failedPaymentsTabPaneActive = false;
              }
            })
            .catch(function(err) {
              /*  var error = "Error retrieving the confirmed payment details for the user id " + userId + " and the cfc code " + cfcCode;  */
              error = 'Error occured while retrieving the confirmed payments';
              // $scope.paymentHistoryError = error;
              $scope.showPaymentHistoryErrorMessage(error);
            }); // end error
        } else {
          error =
            'cfcCode is undefined. Could not retrieve the pending payments';
          // $scope.paymentHistoryError = error;
          $scope.showPaymentHistoryErrorMessage(error);
        } // end else
      }; // end retrieveConfirmedPayments function

      $scope.retrieveFailedPayments = function(userId, cfcCode, charityName) {
        if (cfcCode) {
          PaymentHistoryService.retrieveFailedPayments(
            userId,
            cfcCode,
            charityName
          )
            .then(function(res) {
              var data = res.data;

              var paymentHistoryResponse = data;

              if (
                paymentHistoryResponse &&
                (paymentHistoryResponse.error ||
                  paymentHistoryResponse.statusCode === 'ERROR')
              ) {
                error =
                  paymentHistoryResponse.errorResponse.errorCode +
                  ' : ' +
                  paymentHistoryResponse.errorResponse.errorMessage +
                  '. Please try later';
                //  error = "Error occured while retrieving the Credit Card payment details ";
                // $scope.paymentHistoryError = error;
                $scope.showPaymentHistoryErrorMessage(error);
              } else if (
                paymentHistoryResponse &&
                paymentHistoryResponse.statusCode === 'SUCCESS'
              ) {
                $scope.paymentHistoryData =
                  paymentHistoryResponse.paymentHistoryBean;
                $scope.paymentHistoryData.confirmedPaymentsTabPaneActive = false;
                $scope.paymentHistoryData.failedPaymentsTabPaneActive = true;
              }
            })
            .catch(function(err) {
              /*  var error = "Error retrieving the confirmed payment details for the user id " + userId + " and the cfc code " + cfcCode;  */
              error = 'Error occured while retrieving the failed payments';
              // $scope.paymentHistoryError = error;
              $scope.showPaymentHistoryErrorMessage(error);
            }); // end error
        } else {
          error =
            'cfcCode is undefined. Could not retrieve the pending payments';
          // $scope.paymentHistoryError = error;
          $scope.showPaymentHistoryErrorMessage(error);
        } // end else
      }; // end retrieveFailedPayments function

      $scope.retrieveCCPaymentDetails = function(
        userId,
        id,
        type,
        feeType,
        year
      ) {
        if (userId && id && type && feeType && year) {
          var financialTransactionsBean = {
            userId: userId,
            id: id,
            type: type,
            feeType: feeType
          };

          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };

          FinanceTransactionsService.retrieveCCPaymentDetails(
            financialTransactionsRequest
          )
            .then(function(res) {
              var data = res.data;

              var ccPaymentDetailsResponse = data;

              if (
                ccPaymentDetailsResponse &&
                (ccPaymentDetailsResponse.error ||
                  ccPaymentDetailsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error occured while retrieving the Credit Card payment details ';
                $scope.showCCPaymentDetailsErrorMessage(error);
              } else if (
                ccPaymentDetailsResponse &&
                ccPaymentDetailsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.showCCPaymentDetailsErrorMessage('');
                $scope.creditCardTransactionDetailsData =
                  ccPaymentDetailsResponse.financialTransactionsBean.creditCardPaymentDetailsBean;
                $scope.creditCardTransactionDetailsData.paymentType =
                  'Credit Card';
              }
            })
            .catch(function(err) {
              var error =
                'Error occured while retrieving the Credit Card payment details ';
              $scope.showCCPaymentDetailsErrorMessage(error);
            }); // end error

          // get fee related details for clicked transaction
          PaymentHistoryService.getFederationListingPaymentInfo(
            $scope.paymentHistoryData.cfcCode,
            year
          )
            .then(function(res) {
              if (res) {
                $scope.federationListingFeeData = res.data.value;
                if (
                  $scope.federationListingFeeData.applicationType ===
                  'FEDERATION'
                ) {
                  $scope.isFederation = true;
                } else {
                  $scope.isFederation = false;
                }
              }
            })
            .catch(function(err) {
              $scope.isFederation = false;
            });
        } else {
          var error =
            'Could not retrieve the Credit Card payment details. Please try again later';
          $scope.showCCPaymentDetailsErrorMessage(error);
        } // end else

        $('#ccPaymentDetails').modal();
      }; // end retrieveCCPaymentDetails function

      $scope.retrieveACHPaymentDetails = function(
        userId,
        id,
        type,
        feeType,
        paymentMethod,
        year
      ) {
        if (userId && id && type && feeType && year) {
          var financialTransactionsBean = {
            userId: userId,
            id: id,
            type: type,
            feeType: feeType,
            paymentType: paymentMethod
          };

          var financialTransactionsRequest = {
            financialTransactionsBean: financialTransactionsBean
          };

          FinanceTransactionsService.retrieveACHPaymentDetails(
            financialTransactionsRequest
          )
            .then(function(res) {
              var data = res.data;

              var achPaymentDetailsResponse = data;

              if (
                achPaymentDetailsResponse &&
                (achPaymentDetailsResponse.error ||
                  achPaymentDetailsResponse.statusCode === 'ERROR')
              ) {
                var error =
                  'Error occured while retrieving the ACH payment details ';
                $scope.showACHPaymentDetailsErrorMessage(error);
              } else if (
                achPaymentDetailsResponse &&
                achPaymentDetailsResponse.statusCode === 'SUCCESS'
              ) {
                $scope.showACHPaymentDetailsErrorMessage('');
                $scope.achTransactionDetailsData =
                  achPaymentDetailsResponse.financialTransactionsBean.achPaymentDetailBean;

                $scope.achTransactionDetailsData.paymentType = paymentMethod;
              }
            })
            .catch(function(err) {
              var error =
                'Error occured while retrieving the ACH payment details ';
              $scope.showACHPaymentDetailsErrorMessage(error);
            }); // end error

          // get fee related details for clicked transaction
          PaymentHistoryService.getFederationListingPaymentInfo(
            $scope.paymentHistoryData.cfcCode,
            year
          )
            .then(function(res) {
              if (res.data) {
                $scope.federationListingFeeData = res.data.value;
                if (
                  $scope.federationListingFeeData.applicationType ===
                  'FEDERATION'
                ) {
                  $scope.isFederation = true;
                } else {
                  $scope.isFederation = false;
                }
              }
            })
            .catch(function(err) {
              $scope.isFederation = false;
            });
        } else {
          var error =
            'Could not retrieve the ACH payment details. Please try later';
          $scope.showACHPaymentDetailsErrorMessage(error);
        } // end else

        $('#achPaymentDetails').modal();
      }; // end retrieveACHPaymentDetails function

      $scope.viewPaymentAdjustment = function(
        userId,
        id,
        type,
        feeType,
        paymentMethod,
        year
      ) {
        if (paymentMethod === 'Credit Card') {
          if (userId && id && type && feeType && year) {
            var financialTransactionsBean = {
              userId: userId,
              id: id,
              type: type,
              feeType: feeType
            };

            var financialTransactionsRequest = {
              financialTransactionsBean: financialTransactionsBean
            };

            FinanceTransactionsService.retrieveCCPaymentDetails(
              financialTransactionsRequest
            ).then(function(res) {
              var data = res.data;
              $scope.paymentAdjustment =
                data.financialTransactionsBean.creditCardPaymentDetailsBean;
              $scope.paymentAdjustment.paymentType = paymentMethod;
            });
          }
        } else {
          if (userId && id && type && feeType && year) {
            var financialTransactionsBean = {
              userId: userId,
              id: id,
              type: type,
              feeType: feeType,
              paymentType: paymentMethod
            };

            var financialTransactionsRequest = {
              financialTransactionsBean: financialTransactionsBean
            };

            FinanceTransactionsService.retrieveACHPaymentDetails(
              financialTransactionsRequest
            ).then(function(res) {
              var data = res.data;
              $scope.paymentAdjustment =
                data.financialTransactionsBean.achPaymentDetailBean;
              $scope.paymentAdjustment.paymentType = paymentMethod;
            });
          }
        }
        $('#paymentAdjustment').modal();
      };

      $scope.goBack = function() {
        $('#paymentAdjustment').modal();
      };

      $scope.validatePaymentAdjustment = function() {
        $scope.fieldsMissing = [];
        $scope.error = false;
        // if (!$scope.paymentAdjustment.adjustedPaymentType) {
        //   $scope.fieldsMissing.push('Adjustment Payment Type');
        // }
        if (!$scope.paymentAdjustment.adjustmentTransactionNumber) {
          $scope.fieldsMissing.push('Adjustment Transaction Number');
        }
        if (!$scope.paymentAdjustment.adjustmentDate) {
          $scope.fieldsMissing.push('Adjustment Date');
        }
        if (!$scope.paymentAdjustment.adjustmentAmount) {
          $scope.fieldsMissing.push('Adjustment Amount');
        }
        if (!$scope.paymentAdjustment.adjustmentNotes) {
          $scope.fieldsMissing.push('Adjustment Notes');
        }
        if (
          $scope.paymentAdjustment.type === 'INDEPENDENT' &&
          $scope.paymentAdjustment.adjustedPaymentType === 'ACH_PULL'
        ) {
          $scope.fieldsMissing.push(
            'Cannot choose ACH Pull for independent application/listing'
          );
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.saveAdjustedAmount();
        }
      };

      $scope.saveAdjustedAmount = function() {
        if ($scope.paymentAdjustment.paymentType === 'Credit Card') {
          $scope.paymentAdjustment.paymentType = 'CREDIT_CARD';
        } else if ($scope.paymentAdjustment.paymentType === 'ACH Pull') {
          $scope.paymentAdjustment.paymentType = 'ACH_PULL';
        } else {
          $scope.paymentAdjustment.paymentType = 'ACH_PUSH';
        }
        var adjustedTransactionsBean = {
          id: $scope.paymentAdjustment.id,
          adjustmentTransactionNumber:
            $scope.paymentAdjustment.adjustmentTransactionNumber,
          feeType: $scope.paymentAdjustment.feeType,
          paymentMethod: $scope.paymentAdjustment.paymentType,
          applicationType: $scope.paymentAdjustment.type,
          adjustmentAmount: $scope.paymentAdjustment.adjustmentAmount,
          adjustmentDate: new Date(
            $scope.paymentAdjustment.adjustmentDate.valueOf()
          ),
          notes: $scope.paymentAdjustment.adjustmentNotes,
          cfcCode: $scope.paymentHistoryData.cfcCode,
          pledgeYear: $scope.paymentAdjustment.year
        };

        FinanceTransactionsService.adjustPaymentTransaction(
          adjustedTransactionsBean
        ).then(function(res) {
          var data = res.data;
          $scope.retrieveConfirmedPayments(
            $scope.paymentHistoryData.userId,
            $scope.paymentHistoryData.cfcCode,
            $scope.paymentHistoryData.charityName
          );
        });
      };

      $scope.showPaymentHistoryErrorMessage = function(error) {
        $scope.paymentHistoryError = error;
      };

      $scope.showCCPaymentDetailsErrorMessage = function(error) {
        $scope.ccPaymentDetailsError = error;
      };

      $scope.showACHPaymentDetailsErrorMessage = function(error) {
        $scope.achPaymentDetailsError = error;
      };

      $scope.gotoCharityDashboard = function(cfcCode) {
        $('.modal-backdrop').remove();
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };

      // download trasaction details csv
      $scope.listingFeeDetailsReportDownload = function() {
        var url =
          '/CfcCharity/api/private/federation/listing/payment/fee-details/' +
          $scope.federationListingFeeData.campaignId +
          '/' +
          $scope.federationListingFeeData.cfcCode +
          '/' +
          $scope.federationListingFeeData.fedApplicationId;
        DownloadService.downloadFile(url);
      };

      $scope.memberFeeDetailsReportDownload = function() {
        var url =
          '/CfcCharity/api/private/users/federation/members/feedetails/' +
          $scope.federationListingFeeData.fedApplicationId;
        DownloadService.downloadFile(url);
      };
    } // end PaymentHistoryCtrl controller
  ]);
