const { default: Auth } = require('@aws-amplify/auth');

(function() {
  var moduleName = 'CfcCharity.common.appService';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist$F so create one
    module = angular.module(moduleName, []);
  }

  module.service('AppService', [
    '$http',
    '$log',
    '$location',
    function($http, $log, $location) {
      var userPoolDomain = '';
      var cognitoClientId = '';

      if ($location.host().indexOf('localhost') < 0) {
        var loginRedirectUrl = `https://${$location.host()}/authRedirect`;
        var logoutUrl = `https://${$location.host()}`;
      } else {
        var loginRedirectUrl = `http://${$location.host()}:${$location.port()}/authRedirect`;
        var logoutUrl = `http://${$location.host()}:${$location.port()}`;
      }

      var initializeAmplify = function(resolve) {
        var url = '/CfcCharity/api/public/cognitoMeta';
        $http.get(url).then(res => {
          cognitoClientId = res.data.CHARITY_UI_CLIENT_ID;
          userPoolDomain = res.data.USER_POOL_DOMAIN;

          const encodedRedirectUrl = encodeURI(loginRedirectUrl);
          const encodedLogoutUrl = encodeURI(logoutUrl);

          const config = {
            Auth: {
              region: res.data.AWS_REGION,
              userPoolId: res.data.USER_POOL_ID,
              userPoolWebClientId: cognitoClientId,
              mandatorySignIn: true,
              oauth: {
                domain: `${
                  res.USER_POOL_DOMAIN
                }.auth.us-east-1.amazoncognito.com`,
                scope: ['email', 'openid'],
                redirectSignIn: encodedRedirectUrl,
                redirectSignOut: encodedLogoutUrl,
                responseType: 'code'
              }
            }
          };

          Auth.configure(config);
          if (resolve) {
            resolve();
          }
        });
      };

      var getAuth = function() {
        return Auth;
      };

      function getUserPoolDomain() {
        return userPoolDomain;
      }
      function getLogoutUrl() {
        return logoutUrl;
      }
      function getLoginRedirectUrl() {
        return loginRedirectUrl;
      }
      function getCognitoClientId() {
        return cognitoClientId;
      }

      initializeAmplify();

      return {
        initializeAmplify,
        getAuth,
        getUserPoolDomain,
        getLogoutUrl,
        getLoginRedirectUrl,
        getCognitoClientId
      };
    }
  ]);
})();
