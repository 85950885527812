(function() {
  var moduleName = 'CfcCharity.directives.areaOfServiceReviewService';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist so create one
    module = angular.module(moduleName, []);
  }

  // Area of Service
  module.service('AreaOfServiceReviewService', [
    'CubeSvc',
    '$http',
    function(CubeSvc, $http) {
      var getApplicationStep2 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/step2/' + applicationId
        );
      };

      var getAppReviewPermission = function(applicationId, applicationType) {
        return CubeSvc.post(
          '/CfcCharity/api/private/application/review/permission',
          { applicationId: applicationId, applicationType: applicationType }
        );
      };

      var getStep4ReviewDetail = function(applicationId, step4DetailId) {
        return CubeSvc.post('/CfcCharity/api/private/step4/detail/review', {
          applicationId: applicationId,
          step4DetailId: step4DetailId
        });
      };

      var saveStep4ReviewDetail = function(areaOfServiceReview) {
        return CubeSvc.post(
          '/CfcCharity/api/private/step4/detail/savereview',
          JSON.stringify(areaOfServiceReview)
        );
      };

      function getStep4ReviewStatus(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/step4/getReviewStatus/' + applicationId
        );
      }

      function setStep4ReviewStatus(applicationId, status) {
        return $http({
          method: 'PUT',
          url: '/CfcCharity/api/private/step4/setReviewStatus/' + applicationId,
          data: status,
          headers: {
            'Content-Type': 'text/plain'
          }
        });
      }

      var getDeterminationCodes = function() {
        return CubeSvc.get(
          '/CfcCharity/api/private/lookup/REVIEW1_DETERMINATION'
        );
      };

      return {
        getApplicationStep2: getApplicationStep2,
        getAppReviewPermission: getAppReviewPermission,
        getStep4ReviewDetail: getStep4ReviewDetail,
        saveStep4ReviewDetail: saveStep4ReviewDetail,
        getDeterminationCodes: getDeterminationCodes,
        getStep4ReviewStatus: getStep4ReviewStatus,
        setStep4ReviewStatus: setStep4ReviewStatus
      };
    }
  ]);
})();
