angular
  .module('CfcCharity', [
    'angularUtils.directives.dirPagination',
    'pageSizeModule',
    'PageSizeUserDashBoardModule',
    'CfcCharity.home',
    'CfcCharity.login',
    'CfcCharity.loginredirect',
    'CfcCharity.userdashboard',
    'CfcCharity.common',
    'CfcCharity.common.analytics',
    'CfcCharity.review',
    'CfcCharity.review.denial',
    'CfcCharity.review.email',
    'CfcCharity.review.appeal',
    'CfcCharity.charitydashboard',
    'CfcCharity.donations',
    'CfcCharity.federationlisting',
    'CfcCharity.accessrequestdenied',
    'CfcCharity.updateuserinfo',
    'CfcCharity.optOut',
    'CfcCharity.federationapplicationfee',
    'CfcCharity.federationapplicationthankyou',
    'CfcCharity.federationDashboard',
    'CfcCharity.federationapplicationreview',
    'CfcCharity.zonereviewlevel',
    'CfcCharity.zonememberapplications',
    'CfcCharity.financeadministration',
    'CfcCharity.financetransactions',
    'CfcCharity.paymenthistory',
    'CfcCharity.paymentreceipt',
    'CfcCharity.creditcardtransactions',
    'CfcCharity.charity',
    'CfcCharity.charitylistingpayment',
    'CfcCharity.invitations',
    'CfcCharity.manageCharityUser',
    'CfcCharity.gbadminDashboard',
    'CfcCharity.opmadminDashboard',
    'creditCardInput',
    'CfcCharity.security',
    'CfcCharity.application',
    'CfcCharity.applicationDashboard',
    'CfcCharity.federation_application',
    'CfcCharity.systemmanagement',
    'CfcCharity.systemusers',
    'CfcCharity.addsystemuser',
    'CfcCharity.grantusercharityrole',
    'CfcCharity.editsystemuser',
    'CfcCharity.administrationcenter',
    'CfcCharity.zoneadmin',
    'CfcCharity.zonecenter',
    'CfcCharity.zoneusers',
    'CfcCharity.addzoneuser',
    'CfcCharity.editzoneuser',
    'CfcCharity.federationlistingfee',
    'CfcCharity.federationlistingthankyou',
    'CfcCharity.recordachpayment',
    'CfcCharity.applicationValidations',
    'CfcCharity.admin.system',
    'CfcCharity.admin.zone.listing',
    'CfcCharity.admin.zone.pledges',
    'ngMessages',
    'ui.router',
    'blockUI',
    'ngAria',
    'CfcCharity.directives.breadcrumbs',
    'CfcCharity.directives.adminReviewSection',
    'CfcCharity.directives.adminReviewSectionStepDetermination',
    'CfcCharity.directives.adminReviewStatus',
    'CfcCharity.directives.adminReviewDenialReasons',
    'CfcCharity.common.appealService',
    'CfcCharity.directives.applicationAppealAlert',
    'CfcCharity.directives.applicationStatus',
    'CfcCharity.directives.applicationAppealButton',
    'CfcCharity.admin.review.appeal',
    'CfcCharity.directives.approvalWithWarningMessage',
    'CfcCharity.environmentInfoService',
    'CfcCharity.directives.datepicker',
    'CfcCharity.directives.cms',
    'CfcCharity.directives.charityCard',
    'CfcCharity.directives.federationCard',
    'CfcCharity.directives.currencyInput',
    'angularMoment',
    'CfcCharity.directives.charitySupportingDocumentation',
    'CfcCharity.directives.areaOfServiceReviewService',
    'CfcCharity.directives.areaOfServiceReview',
    'CfcCharity.directives.areaOfServiceReviewStatus',
    'CfcCharity.directives.adminReviewComments',
    'CfcCharity.common.appService',
    'CfcCharity.common.appealService',
    'CfcCharity.common.zoneService',
    'CfcCharity.common.authorizationHelper',
    'CfcCharity.admin.emailDashboard',
    'CfcCharity.incompleteMemberListings',
    'CfcCharity.admin.zone.appeals',
    'CfcCharity.directives.videoLink',
    'CfcCharity.directives.aosAppeal',
    'CfcCharity.directives.charityIrsStatus',
    'CfcCharity.confirmUpdateUsername'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    '$ariaProvider',
    'paginationTemplateProvider',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      $ariaProvider,
      paginationTemplateProvider
    ) {
      $ariaProvider.config({});
      $urlRouterProvider.when('/', '/home');
      $urlRouterProvider.otherwise('/home');
      paginationTemplateProvider.setString(
        require('../views/private/template/dirPagination.tpl.html')
      );
    }
  ])
  .constant('reviewLevelConstants', {
    Completeness: 'bdfe59d6-1f5f-4269-b475-6ee016e22f00',
    StaffEligibility: '2b8539cc-14ab-4530-83b1-cc36f8ec0f79',
    PreliminaryEligibility: 'b6b7231f-4df1-48b3-9bf1-13ebb214aecd',
    FinalEligibility: '98042629-c2bd-49e0-ae60-2bf9d6cdbf37'
  })
  .constant('applicationTypes', {
    Independent: 'Independent',
    Member: 'Member',
    Federation: 'Federation'
  })
  .constant('charityEmailType', {
    InvalidBankingInfo: 'INVALID_BANKING_INFO'
  })
  .constant('PLEDGE_YEARS', {
    PLEDGE_YEAR_2022: '2022',
    PLEDGE_YEAR_2023: '2023',
    PLEDGE_YEAR_2024: '2024'
  })
  .run([
    '$log',
    '$rootScope',
    '$location',
    '$state',
    '$http',
    'BrowserEventService',
    'AnalyticsService',
    'AppService',
    'notificationService',
    'BannerService',
    'CubeSvc',
    function(
      $log,
      $rootScope,
      $location,
      $state,
      $http,
      BrowserEventService,
      AnalyticsService,
      AppService,
      notificationService,
      BannerService,
      CubeSvc
    ) {
      // sets config for gtag (TASC Google Tag Manager/Analytics)
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        dataLayer.push(arguments);
      };
      gtag('js', new Date());
      // send_page_view is false, since we manually invoke that event in userPageActivity
      gtag('config', 'UA-111945916-1', { send_page_view: false });
      $rootScope.$on('$stateChangeStart', function() {
        CubeSvc.setStateTransition(true); // start
      });
      var appVersion = 1;
      $rootScope.appVersion = 1;
      $rootScope.isBannerMsgRetrieved = false;
      $rootScope.$on('$locationChangeSuccess', function() {
        CubeSvc.setStateTransition(false);

        $rootScope.actualLocation = $location.path();
        if (!$rootScope.isBannerMsgRetrieved) {
          BannerService.getBannerMessage()
            .then(function(res) {
              if (res.data) {
                var result = res.data;
                $rootScope.bannerMessage = result.messageText;
                $rootScope.isBannerMsgRetrieved = true;
              }
            })
            .catch(function(err) {
              $rootScope.isBannerMsgRetrieved = false;
            });
        }
        notificationService.subscribe($rootScope, function somethingChanged(
          evt,
          data
        ) {
          $rootScope.bannerMessage = data;
        });
      });
      $rootScope.$on('$stateChangeSuccess', function(
        event,
        toState,
        toParams,
        fromState,
        fromParams
      ) {
        $rootScope.title = toState.data.pageTitle;
        AnalyticsService.addUserPageActivity(
          toState,
          fromState,
          $http,
          BrowserEventService,
          toParams
        );
        AnalyticsService.sendAnalyticsPageLoadEvents(toState);
      });
      $rootScope.$watch(
        function() {
          return $location.path();
        },
        function(newLocation, oldLocation) {
          if ($rootScope.actualLocation === newLocation) {
            if (newLocation === '/charityDashboard') {
              $state.go('charitydashboard', {
                charityDashboardCfcCode: BrowserEventService.getCfcCode()
              });
            } else if (newLocation === '/charitylisting') {
              $state.go('charitylisting', {
                charityDashboardCfcCode: BrowserEventService.getCfcCode(),
                pledgeYear: BrowserEventService.getPledgeYear()
              });
            } else if (newLocation === '/federationlisting') {
              $state.go('federationlisting', {
                charityDashboardCfcCode: BrowserEventService.getFedCfcCode(),
                pledgeYear: BrowserEventService.getPledgeYear()
              });
            } else if (newLocation === '/admin-zone-listing-overview') {
              var zoneId = null;
              if (BrowserEventService.getZoneId()) {
                zoneId = BrowserEventService.getZoneId();
              }
              if (BrowserEventService.getZone()) {
                zoneId = BrowserEventService.getZone().id;
              }
              $state.go('admin-zone-listing-overview', {
                pledgeYear: BrowserEventService.getPledgeYear(),
                zoneId: zoneId,
                zoneUserRole: BrowserEventService.getZoneUserRole()
              });
            } else if (newLocation === '/admin-zone-listing-printorder') {
              $state.go('admin-zone-listing-printorder', {
                zone: BrowserEventService.getZone(),
                campaignId: BrowserEventService.getCampaignId(),
                pledgeYear: BrowserEventService.getPledgeYear(),
                zoneUserRole: BrowserEventService.getZoneUserRole()
              });
            } else if (
              newLocation === '/admin-zone-listing-printorder-history'
            ) {
              $state.go('admin-zone-listing-printorder-history', {
                zone: BrowserEventService.getZone(),
                campaignId: BrowserEventService.getCampaignId(),
                pledgeYear: BrowserEventService.getPledgeYear(),
                zoneUserRole: BrowserEventService.getZoneUserRole()
              });
            } else if (newLocation === '/federationmemberlistings') {
              $state.go('federationmemberlistings', {
                charityDashboardCfcCode: BrowserEventService.getFedCfcCode(),
                pledgeYear: BrowserEventService.getPledgeYear()
              });
            } else if (newLocation === '/accessRequestDenied') {
              $state.go('accessrequestdenied', {
                accessRequestDeniedId: BrowserEventService.getAccessRequestDeniedId()
              });
            } else if (newLocation === '/updateUserInfo') {
              $state.go('updateuserinfo');
            } else if (newLocation === '/confirmUpdateUsername') {
              $state.go('confirmUpdateUsername');
            } else if (newLocation === '/optOut') {
              $state.go('optOut');
            } else if (newLocation === '/manageCharityUsers') {
              $state.go('manageCharityUsers', {
                cfcCode: BrowserEventService.getCfcCode(),
                charityName: BrowserEventService.getCharityName()
              });
            } else if (newLocation === '/inviteUserToCharity') {
              $state.go('inviteUserToCharity', {
                charityDashboardCfcCode: BrowserEventService.getCfcCode(),
                adminWithCharityEditAccess: BrowserEventService.getAdminWithCharityEditAccess()
              });
            } else if (newLocation === '/userDashboard') {
              $state.go('userDashboard', {
                userId: BrowserEventService.getUserId()
              });
            } else if (newLocation === '/userCharities') {
              $state.go('userCharities', {
                userId: BrowserEventService.getUserId()
              });
            } else if (newLocation === '/userApplications') {
              $state.go('userApplications', {
                year: BrowserEventService.getUserApplicationDataYear()
              });
            } else if (newLocation === '/userListings') {
              $state.go('userListings', {
                year: BrowserEventService.getUserApplicationDataYear()
              });
            } else if (newLocation === '/userDonations') {
              $state.go('userDonations', {
                year: BrowserEventService.getUserApplicationDataYear()
              });
            } else if (newLocation === '/accessInvitations') {
              $state.go('accessInvitations');
            } else if (newLocation === '/accessRequests') {
              $state.go('accessRequests');
            } else if (
              newLocation === '/federationapplication' ||
              newLocation === '/withdrawfederationapplicationconfirmation'
            ) {
              $state.go('federationapplication', {
                year: BrowserEventService.getUserApplicationDataYear(),
                cfcCode: BrowserEventService.getCfcCode()
              });
            } else if (
              newLocation === '/memberapplication' ||
              newLocation === '/deletememberapplicationconfirmation'
            ) {
              $state.go('memberapplication', {
                year: BrowserEventService.getUserApplicationDataYear(),
                cfcCode: BrowserEventService.getCfcCode()
              });
            } else if (
              newLocation === '/withdrawfederationapplication' ||
              newLocation === '/disbandfederationapplication'
            ) {
              $state.go('userDashboard', {
                userId: BrowserEventService.getUserId()
              });
            } else if (newLocation === '/federationDashboard') {
              $state.go('federationDashboard', {
                federationId: BrowserEventService.getFederationId()
              });
            } else if (newLocation === '/systemmanagement') {
              $log.info('Location = ' + newLocation);
              $state.go('systemmanagement', {
                userId: BrowserEventService.getCurrentUser().id
              });
            } else if (newLocation === '/zoneAdmin') {
              $state.go('zoneadmin', {
                userId: BrowserEventService.getUserId()
              });
            } else if (newLocation === '/zipAdmin') {
              $state.go('zipadmin', {
                zoneId: BrowserEventService.getZoneId()
              });
            } else if (newLocation === '/activezoneusers') {
              $state.go('activezoneusers', {
                zoneId: BrowserEventService.getZoneId(),
                zoneName: BrowserEventService.getZoneName()
              });
            } else if (newLocation === '/editzoneuser') {
              $state.go('editzoneuser', {
                userId: BrowserEventService.getCurrentUser().id,
                invitedUserId: BrowserEventService.getInvitedUserId,
                zoneId: BrowserEventService.getZoneId()
              });
            } else if (newLocation === '/zoneCenter') {
              $state.go('zoneCenter', {
                zoneId: BrowserEventService.getZoneId(),
                zoneUserRole: BrowserEventService.getZoneUserRole()
              });
            } else if (newLocation === '/zoneApplications') {
              $state.go('zoneApplications', {
                pledgeYear: BrowserEventService.getPledgeYear(),
                zoneId: BrowserEventService.getZoneId(),
                zoneUserRole: BrowserEventService.getZoneUserRole()
              });
            } else if (newLocation === '/zoneApplicationStatus') {
              $state.go('zoneApplicationStatus', {
                pledgeYear: BrowserEventService.getPledgeYear(),
                zoneId: BrowserEventService.getZoneId(),
                status: BrowserEventService.getStatusName(),
                einVal: BrowserEventService.getEinVal()
              });
            } else if (newLocation === '/administrationcenter') {
              $state.go('administrationcenter', {
                userId: BrowserEventService.getCurrentUser().id,
                firstTimeUser: false
              });
            } else if (newLocation === '/activesystemusers') {
              $state.go('activesystemusers', {
                userId: BrowserEventService.getCurrentUser().id
              });
            } else if (newLocation === '/federationapplicationreview') {
              $log.info('Location = ' + newLocation);
              $state.go('federationapplicationreview', {
                federationApplicationId: BrowserEventService.getFederationApplicationReviewFederationId()
              });
            } else if (newLocation === '/zonecompletenessreview') {
              $log.info('Location = ' + newLocation);
              $state.go('zonecompletenessreview', {
                zoneId: BrowserEventService.getZoneCompletenessReviewZoneId()
              });
            } else if (newLocation === '/zonereviewlevel1') {
              $log.info('Location = ' + newLocation);
              $state.go('zonereviewlevel1', {
                zoneId: BrowserEventService.getZoneCompletenessReviewZoneId()
              });
            } else if (newLocation === '/zonereviewlevel2') {
              $log.info('Location = ' + newLocation);
              $state.go('zonereviewlevel2', {
                zoneId: BrowserEventService.getZoneCompletenessReviewZoneId()
              });
            } else if (newLocation === '/zonereviewlevel3') {
              $log.info('Location = ' + newLocation);
              $state.go('zonereviewlevel3', {
                zoneId: BrowserEventService.getZoneCompletenessReviewZoneId()
              });
            } else if (newLocation === '/zonestaffeligibilityreview') {
              $log.info('Location = ' + newLocation);
              $state.go('zonestaffeligibilityreview', {
                zoneId: BrowserEventService.getZoneStaffEligibilityReviewZoneId()
              });
            } else if (newLocation === '/zonereview1') {
              $log.info('Location = ' + newLocation);
              $state.go('zonereview1', {
                zoneId: BrowserEventService.getZoneReview1ZoneId()
              });
            } else if (newLocation === '/zonememberapplications') {
              $log.info('Location = ' + newLocation);
              $state.go('zonememberapplications', {
                federationApplicationId: BrowserEventService.getFederationApplicationReviewFederationId(),
                zoneId: BrowserEventService.getZoneMemberApplicationsZoneId()
              });
            } else if (newLocation === '/paymenthistory') {
              $log.info('Location = ' + newLocation);
              var paymentHistoryRequestData = BrowserEventService.getPaymentHistoryRequestData();
              if (paymentHistoryRequestData) {
                var userId = paymentHistoryRequestData.userId;
                var cfcCode = paymentHistoryRequestData.cfcCode;
                var charityName = paymentHistoryRequestData.charityName;
                $state.go('paymenthistory', {
                  userId: userId,
                  cfcCode: cfcCode,
                  charityName: charityName
                });
              }
            } else if (newLocation === '/paymentreceipt') {
              $log.info('Location = ' + newLocation);
              var paymentReceiptRequestData = BrowserEventService.getPaymentReceiptRequestData();
              if (paymentReceiptRequestData) {
                var userId = paymentReceiptRequestData.userId;
                var id = paymentReceiptRequestData.id;
                var type = paymentReceiptRequestData.type;
                var feeType = paymentReceiptRequestData.feeType;
                var paymentMethod = paymentReceiptRequestData.paymentMethod;
                var year = paymentReceiptRequestData.year;
                $state.go('paymentreceipt', {
                  userId: userId,
                  id: id,
                  type: type,
                  feeType: feeType,
                  paymentMethod: paymentMethod,
                  year: year
                });
              }
            } else if (newLocation === '/financetransactions') {
              $log.info('Location = ' + newLocation);
              $state.go('financetransactions', {
                financialTransactionsData: BrowserEventService.getFinancialTransactionsData()
              });
            } else if (newLocation === '/financeadministration') {
              $log.info('Location = ' + newLocation);
              $state.go('financeadministration', {
                userId: BrowserEventService.getCurrentUser().id
              });
            } else if (newLocation === '/creditcardtransactions') {
              $log.info('Location = ' + newLocation);
              $state.go('creditcardtransactions', {
                creditCardTransactionsData: BrowserEventService.getCreditCardTransactionsData()
              });
            } else if (newLocation === '/charitylistingpayment') {
              $log.info('Location = ' + newLocation);
              $state.go('charitylistingpayment', {
                charityListingId: BrowserEventService.getListingId()
              });
            } else if (newLocation === '/financepaymentsearch') {
              $log.info('Location = ' + newLocation);
              var financePaymentSearchInputData = BrowserEventService.getFinancePaymentSearchInputData();
              if (financePaymentSearchInputData) {
                $state.go('financepaymentsearch', {
                  financePaymentSearchData: null,
                  type: financePaymentSearchInputData.type,
                  value: financePaymentSearchInputData.value
                });
              }
            } else if (newLocation === '/applicationPayment') {
              $log.info('Location = ' + newLocation);
              $state.go('applicationPayment', {
                charityApplicationId: BrowserEventService.getApplicationId()
              });
            } else if (newLocation === '/federationapplicationfee') {
              $log.info('Location = ' + newLocation);
              $state.go('federationapplicationfee', {
                federationId: BrowserEventService.getFederationId()
              });
            } else if (newLocation === '/federationlistingfee') {
              $log.info('Location = ' + newLocation);
              $state.go('federationlistingfee', {
                cfcCode: BrowserEventService.getFedCfcCode(),
                pledgeYear: BrowserEventService.getPledgeYear()
              });
            } else if (newLocation === '/financepaymentsearch') {
              $log.info('Location = ' + newLocation);
              $state.go('financepaymentsearch', {
                type: BrowserEventService.getType(),
                value: BrowserEventService.getValue()
              });
            } else if (newLocation === '/financepaymentsearchach') {
              $log.info('Location = ' + newLocation);
              $state.go('financepaymentsearchach', {
                type: BrowserEventService.getType(),
                value: BrowserEventService.getValue(),
                financePaymentAchSearchData: null
              });
            } else if (newLocation === '/federationlistingachpayment') {
              $log.info('Location = ' + newLocation);
              $state.go('federationlistingachpayment', {
                cfcCode: BrowserEventService.getFedCfcCode(),
                listingId: BrowserEventService.getListingId(),
                federationName: BrowserEventService.getFederationName(),
                totalMemberListingFee: BrowserEventService.getTotalMemberListingFee(),
                campaignId: BrowserEventService.getCampaignId()
              });
            } else if (newLocation === '/federationapplicationachpayment') {
              $log.info('Location = ' + newLocation);
              $state.go('federationapplicationachpayment', {
                cfcCode: BrowserEventService.getFedCfcCode(),
                federationName: BrowserEventService.getFederationName(),
                applicationId: BrowserEventService.getApplicationId(),
                totalMemberApplicationsFee: BrowserEventService.getTotalMemberAppFee(),
                campaignId: BrowserEventService.getCampaignId()
              });
            } else if (newLocation === '/charityapplicationachpayment') {
              $log.info('Location = ' + newLocation);
              $state.go('charityapplicationachpayment', {
                campaignId: BrowserEventService.getCampaignId(),
                applicationId: BrowserEventService.getApplicationId(),
                cfcCode: BrowserEventService.getFedCfcCode(),
                charityName: BrowserEventService.getCharityName()
              });
            } else if (newLocation === '/charitylistingachpayment') {
              $log.info('Location = ' + newLocation);
              $state.go('charitylistingachpayment', {
                cfcCode: BrowserEventService.getFedCfcCode(),
                charityName: BrowserEventService.getCharityName(),
                campaignId: BrowserEventService.getCampaignId(),
                listingId: BrowserEventService.getListingId()
              });
            } else if (newLocation === '/federationappachpaymentcharityuser') {
              $log.info('Location = ' + newLocation);
              $state.go('federationappachpaymentcharityuser', {
                cfcCode: BrowserEventService.getFedCfcCode(),
                federationName: BrowserEventService.getFederationName(),
                applicationId: BrowserEventService.getApplicationId(),
                totalMemberApplications: BrowserEventService.getTotalMemberApplications(),
                campaignId: BrowserEventService.getCampaignId(),
                memberApplicationFee: BrowserEventService.getMemberApplicationFee(),
                federationApplicationFee: BrowserEventService.getFederationApplicationFee()
              });
            } else if (
              newLocation === '/federationlistingachpaymentcharityuser'
            ) {
              $log.info('Location = ' + newLocation);
              $state.go('federationlistingachpaymentcharityuser', {
                cfcCode: BrowserEventService.getFedCfcCode(),
                federationName: BrowserEventService.getFederationName(),
                listingId: BrowserEventService.getListingId(),
                federationId: BrowserEventService.getApplicationId(),
                totalMemberListings: BrowserEventService.getTotalMemberListings(),
                campaignId: BrowserEventService.getCampaignId(),
                listingIdList: BrowserEventService.getListingIdList()
              }); // charity application review
            } else if (newLocation === '/charityAuditDashboard') {
              $log.info('Location = ' + newLocation);
              $state.go('charityAuditDashboard', {
                applicationId: BrowserEventService.getIndependentApplicationReviewApplicationId()
              });
            } else if (newLocation === '/userSearchResults') {
              $state.go('userSearchResults', {
                userId: BrowserEventService.getSearchUserId()
              });
            } else if (newLocation === '/signAndUnsignFederation') {
              $state.go('signAndUnsignFederation', {
                applicationId: BrowserEventService.getFederationId(),
                pledgeYear: BrowserEventService.getPledgeYear()
              });
            } else if (newLocation === '/signAndunsign') {
              $state.go('signAndunsign', {
                applicationId: BrowserEventService.getApplicationId()
              });
            } else if (newLocation === '/zoneApplicationsReview') {
              $log.info('Location = ' + newLocation);

              $state.go('zoneApplicationsReview', {
                zoneId: BrowserEventService.getZoneId(),
                pledgeYear: BrowserEventService.getPledgeYear()
              });
            }
          }
        }
      );
    }
  ])
  .service('AnnouncerService', [
    '$window',
    'toastrService',
    function($window, toastrService) {
      var site_title = 'CFC Charity - ';
      var announce_timeout = null;
      this.announce_view_loaded = function() {
        var page_title, page_title_el;
        page_title_el = document.querySelector('[data-page-title]');
        if (page_title_el !== null) {
          page_title =
            page_title_el.getAttribute('data-page-title') ||
            page_title_el.innerText;
          page_title =
            page_title === 'true' ? page_title_el.innerText : page_title;
        } else {
          page_title = 'page title not set';
        }
        return set_title(page_title);
      };

      function set_title(page_title) {
        page_title = site_title + page_title;
        $window.document.title = page_title;
        announce(page_title + ' page loaded', 'polite');
      }

      function announce(message, manners) {
        var announcer, clear_announcer;
        manners = manners || 'polite';
        announcer = document.getElementById('a11y_announcer');
        if (announcer) {
          announcer.setAttribute('aria-live', 'off');
          clear_announcer = function() {
            announcer.innerHTML = '';
            announce_timeout = null;
            return announcer;
          };
          announcer.setAttribute('aria-live', manners);
          announcer.innerHTML = message;
          clearTimeout(announce_timeout);
          announce_timeout = setTimeout(clear_announcer, 500);
          return announcer;
        } else {
          toastrService.info('a11y_announcer not found', 'a11y Announcer', {
            timeOut: 0,
            extendedTimeOut: 0,
            autoDismiss: false,
            closeButton: true,
            positionClass: 'toast-top-full-width'
          });
        }
      }
    }
  ])
  .service('BrowserEventService', function() {
    var browserEventService = {};
    browserEventService.getUserDashboardCurrentPage = function() {
      return browserEventService.userDashboardCurrentPage;
    };
    browserEventService.setUserDashboardCurrentPage = function(
      userDashboardCurrentPage
    ) {
      browserEventService.userDashboardCurrentPage = userDashboardCurrentPage;
    };
    browserEventService.getCharityLocation = function() {
      return browserEventService.cfcCharityLocation;
    };
    browserEventService.setCharityLocation = function(cfcCharityLocation) {
      browserEventService.cfcCharityLocation = cfcCharityLocation;
    };
    browserEventService.getCurrentUser = function() {
      return browserEventService.currentUser;
    };
    browserEventService.setCurrentUser = function(currentUser) {
      browserEventService.currentUser = currentUser;
    };
    browserEventService.getSystemUserSecondLevelRoleList = function() {
      return browserEventService.systemUserSecondLevelRoleList;
    };
    browserEventService.setSystemUserSecondLevelRoleList = function(
      systemUserSecondLevelRoleList
    ) {
      browserEventService.systemUserSecondLevelRoleList = systemUserSecondLevelRoleList;
    };
    browserEventService.getAvailableSystemUserSecondLevelRoleList = function() {
      return browserEventService.availableSystemUserSecondLevelRoleList;
    };
    browserEventService.setAvailableSystemUserSecondLevelRoleList = function(
      availableSystemUserSecondLevelRoleList
    ) {
      browserEventService.availableSystemUserSecondLevelRoleList = availableSystemUserSecondLevelRoleList;
    };
    browserEventService.getUserId = function() {
      return browserEventService.userId;
    };
    browserEventService.setUserId = function(userId) {
      browserEventService.userId = userId;
    };
    browserEventService.getCfcCode = function() {
      return browserEventService.cfcCode;
    };
    browserEventService.setCfcCode = function(cfcCode) {
      browserEventService.cfcCode = cfcCode;
    };
    browserEventService.getFedCfcCode = function() {
      return browserEventService.fedCfcCode;
    };
    browserEventService.setFedCfcCode = function(fedCfcCode) {
      browserEventService.fedCfcCode = fedCfcCode;
    };
    browserEventService.getPledgeYear = function() {
      return browserEventService.pledgeYear;
    };
    browserEventService.setPledgeYear = function(pledgeYear) {
      browserEventService.pledgeYear = pledgeYear;
    };
    browserEventService.getStatus = function() {
      return browserEventService.statusName;
    };
    browserEventService.setStatus = function(statusName) {
      browserEventService.statusName = statusName;
    };
    browserEventService.getStatusName = function() {
      return browserEventService.status;
    };
    browserEventService.setStatusName = function(status) {
      browserEventService.status = status;
    };
    browserEventService.getAccessRequestDeniedId = function() {
      return browserEventService.accessRequestDeniedId;
    };
    browserEventService.setAccessRequestDeniedId = function(
      accessRequestDeniedId
    ) {
      browserEventService.accessRequestDeniedId = accessRequestDeniedId;
    };
    browserEventService.getUpdateInfoUserId = function() {
      return browserEventService.updateInfoUserId;
    };
    browserEventService.setUpdateInfoUserId = function(userId) {
      browserEventService.updateInfoUserId = userId;
    };
    browserEventService.getCharityName = function() {
      return browserEventService.charityName;
    };
    browserEventService.setCharityName = function(charityName) {
      browserEventService.charityName = charityName;
    };
    browserEventService.getUserApplicationDataYear = function() {
      return browserEventService.userApplicationDataYear;
    };
    browserEventService.setUserApplicationDataYear = function(year) {
      browserEventService.userApplicationDataYear = year;
    };
    browserEventService.setFederationId = function(federationId) {
      browserEventService.federationId = federationId;
    };
    browserEventService.getFederationId = function() {
      return browserEventService.federationId;
    };
    browserEventService.setZoneId = function(zoneId) {
      browserEventService.zoneId = zoneId;
    };
    browserEventService.getZoneId = function() {
      return browserEventService.zoneId;
    };
    browserEventService.setZoneName = function(zoneName) {
      browserEventService.zoneName = zoneName;
    };
    browserEventService.getZoneName = function() {
      return browserEventService.zoneName;
    };
    browserEventService.setZoneCode = function(zoneCode) {
      browserEventService.zoneCode = zoneCode;
    };
    browserEventService.getZoneCode = function() {
      return browserEventService.zoneCode;
    };
    browserEventService.setInvitedUserId = function(invitedUserId) {
      browserEventService.invitedUserId = invitedUserId;
    };
    browserEventService.getInvitedUserId = function() {
      return browserEventService.invitedUserId;
    };
    browserEventService.setZoneUserRole = function(role) {
      browserEventService.zoneUserRole = role;
    };
    browserEventService.getZoneUserRole = function() {
      return browserEventService.zoneUserRole;
    };
    browserEventService.getFederationApplicationReviewFederationId = function() {
      return browserEventService.federationApplicationReviewFederationId;
    };
    browserEventService.setFederationApplicationReviewFederationId = function(
      federationApplicationReviewFederationId
    ) {
      browserEventService.federationApplicationReviewFederationId = federationApplicationReviewFederationId;
    };
    browserEventService.getZoneCompletenessReviewZoneId = function() {
      return browserEventService.zoneCompletenessReviewZoneId;
    };
    browserEventService.setZoneCompletenessReviewZoneId = function(
      zoneCompletenessReviewZoneId
    ) {
      browserEventService.zoneCompletenessReviewZoneId = zoneCompletenessReviewZoneId;
    };
    browserEventService.getZoneStaffEligibilityReviewZoneId = function() {
      return browserEventService.zoneStaffEligibilityReviewZoneId;
    };
    browserEventService.setZoneStaffEligibilityReviewZoneId = function(
      zoneStaffEligibilityReviewZoneId
    ) {
      browserEventService.zoneStaffEligibilityReviewZoneId = zoneStaffEligibilityReviewZoneId;
    };
    browserEventService.getZoneReview1ZoneId = function() {
      return browserEventService.zoneReview1ZoneId;
    };
    browserEventService.setZoneReview1ZoneId = function(zoneReview1ZoneId) {
      browserEventService.zoneReview1ZoneId = zoneReview1ZoneId;
    };
    browserEventService.getIndependentApplicationReviewApplicationId = function() {
      return browserEventService.independentApplicationReviewApplicationId;
    };
    browserEventService.setIndependentApplicationReviewApplicationId = function(
      independentApplicationReviewApplicationId
    ) {
      browserEventService.independentApplicationReviewApplicationId = independentApplicationReviewApplicationId;
    };
    browserEventService.getZoneMemberApplicationsZoneId = function() {
      return browserEventService.zoneMemberApplicationsZoneId;
    };
    browserEventService.setZoneMemberApplicationsZoneId = function(
      zoneMemberApplicationsZoneId
    ) {
      browserEventService.zoneMemberApplicationsZoneId = zoneMemberApplicationsZoneId;
    };
    browserEventService.getListingId = function() {
      return browserEventService.listingId;
    };
    browserEventService.setListingId = function(listingId) {
      browserEventService.listingId = listingId;
    };
    browserEventService.getApplicationId = function() {
      return browserEventService.applicationId;
    };
    browserEventService.setApplicationId = function(applicationId) {
      browserEventService.applicationId = applicationId;
    };
    browserEventService.getType = function() {
      return browserEventService.type;
    };
    browserEventService.setType = function(type) {
      browserEventService.type = type;
    };
    browserEventService.getValue = function() {
      return browserEventService.value;
    };
    browserEventService.setValue = function(value) {
      browserEventService.value = value;
    };
    browserEventService.getTotalMemberListingFee = function() {
      return browserEventService.totalMemberListingFee;
    };
    browserEventService.setTotalMemberListingFee = function(
      totalMemberListingFee
    ) {
      browserEventService.totalMemberListingFee = totalMemberListingFee;
    };
    browserEventService.getTotalMemberAppFee = function() {
      return browserEventService.totalMemberAppFee;
    };
    browserEventService.setTotalMemberAppFee = function(totalMemberAppFee) {
      browserEventService.totalMemberAppFee = totalMemberAppFee;
    };
    browserEventService.getCampaignId = function() {
      return browserEventService.campaignId;
    };
    browserEventService.setCampaignId = function(campaignId) {
      browserEventService.campaignId = campaignId;
    };
    browserEventService.getFederationName = function() {
      return browserEventService.federationName;
    };
    browserEventService.setFederationName = function(federationName) {
      browserEventService.federationName = federationName;
    };
    browserEventService.getPaymentHistoryRequestData = function() {
      return browserEventService.paymentHistoryRequestData;
    };
    browserEventService.setPaymentHistoryRequestData = function(
      paymentHistoryRequestData
    ) {
      browserEventService.paymentHistoryRequestData = paymentHistoryRequestData;
    };
    browserEventService.getPaymentReceiptRequestData = function() {
      return browserEventService.paymentReceiptRequestData;
    };
    browserEventService.setPaymentReceiptRequestData = function(
      paymentReceiptRequestData
    ) {
      browserEventService.paymentReceiptRequestData = paymentReceiptRequestData;
    };
    browserEventService.getFinancialTransactionsData = function() {
      return browserEventService.financialTransactionsData;
    };
    browserEventService.setFinancialTransactionsData = function(
      financialTransactionsData
    ) {
      browserEventService.financialTransactionsData = financialTransactionsData;
    };
    browserEventService.getCreditCardTransactionsData = function() {
      return browserEventService.creditCardTransactionsData;
    };
    browserEventService.setCreditCardTransactionsData = function(
      creditCardTransactionsData
    ) {
      browserEventService.creditCardTransactionsData = creditCardTransactionsData;
    };
    browserEventService.getAdminWithCharityEditAccess = function() {
      return browserEventService.adminWithCharityEditAccess;
    };
    browserEventService.setAdminWithCharityEditAccess = function(
      adminWithCharityEditAccess
    ) {
      browserEventService.adminWithCharityEditAccess = adminWithCharityEditAccess;
    };
    browserEventService.getTotalMemberApplications = function() {
      return browserEventService.totalMemberApplications;
    };
    browserEventService.setTotalMemberApplications = function(
      totalMemberApplications
    ) {
      browserEventService.totalMemberApplications = totalMemberApplications;
    };
    browserEventService.getTotalMemberListings = function() {
      return browserEventService.totalMemberListings;
    };
    browserEventService.setTotalMemberListings = function(totalMemberListings) {
      browserEventService.totalMemberListings = totalMemberListings;
    };
    browserEventService.getFinancePaymentSearchInputData = function() {
      return browserEventService.financePaymentSearchInputData;
    };
    browserEventService.setFinancePaymentSearchInputData = function(
      financePaymentSearchInputData
    ) {
      browserEventService.financePaymentSearchInputData = financePaymentSearchInputData;
    };
    browserEventService.getListingIdList = function() {
      return browserEventService.listingIdList;
    };
    browserEventService.setListingIdList = function(listingIdList) {
      browserEventService.listingIdList = listingIdList;
    };
    browserEventService.getAppType = function() {
      return browserEventService.appType;
    };
    browserEventService.setAppType = function(appType) {
      browserEventService.appType = appType;
    };
    browserEventService.getSearchUserId = function() {
      return browserEventService.searchUserId;
    };
    browserEventService.setSearchUserId = function(userId) {
      browserEventService.searchUserId = userId;
    };
    browserEventService.getMode = function() {
      return browserEventService.mode;
    };
    browserEventService.setMode = function(mode) {
      browserEventService.mode = mode;
    };
    browserEventService.getEinVal = function() {
      return browserEventService.einVal;
    };
    browserEventService.setEinVal = function(einVal) {
      browserEventService.einVal = einVal;
    };
    browserEventService.getZone = function() {
      return browserEventService.zone;
    };
    browserEventService.setZone = function(zone) {
      browserEventService.zone = zone;
    };

    browserEventService.getMemberApplicationFee = function() {
      return browserEventService.memberApplicationFee;
    };
    browserEventService.setMemberApplicationFee = function(
      memberApplicationFee
    ) {
      browserEventService.memberApplicationFee = memberApplicationFee;
    };

    browserEventService.getFederationApplicationFee = function() {
      return browserEventService.federationApplicationFee;
    };
    browserEventService.setFederationApplicationFee = function(
      federationApplicationFee
    ) {
      browserEventService.federationApplicationFee = federationApplicationFee;
    };

    return browserEventService;
  })
  .controller('AppCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$timeout',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    'UserService',
    'AuthService',
    'ApplicationService',
    'FederationApplicationService',
    'BrowserEventService',
    'BannerService',
    'notificationService',
    'EnvironmentInfoService',
    'toastrService',
    '$rootScope',
    'TokenStorage',
    '$http',
    'AppService',
    '$window',
    function AppCtrl(
      $log,
      $state,
      $scope,
      $location,
      $timeout,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES,
      UserService,
      AuthService,
      ApplicationService,
      FederationApplicationService,
      BrowserEventService,
      BannerService,
      notificationService,
      EnvironmentInfoService,
      toastrService,
      $rootScope,
      TokenStorage,
      $http,
      AppService,
      $window
    ) {
      $scope.appVisibility = {
        viewMode: null
      };

      $scope.stateParams = function() {
        return angular.copy($state.params);
      };

      $scope.getEnvironmentInfo = function() {
        EnvironmentInfoService.getEnvironmentInfo()
          .then(function(res) {
            if (res && res.data) {
              $scope.environmentObj = res.data;
              $scope.isProd = false;
            } else {
              $scope.isProd = true;
            }
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.isProd = true;
      $scope.getEnvironmentInfo();
      $scope.conditionsOfUse = function() {
        $state.go('termsofUse', { fromPage: $state.current.name });
      };
      $scope.privacyNotice = function() {
        $state.go('privacyNotice', { fromPage: $state.current.name });
      };
      $scope.searchValue = '';
      $scope.goToFAQ = function() {
        $state.go('freqAskedQues', {
          search: document.getElementById('FAQsearch').value
        });
      };
      $scope.appNavbar = {
        pledgeYear: null,
        cfcCode: null,
        charityName: null,
        step: null,
        fysa: null
      };
      $scope.federationNavbar = {
        pledgeYear: null,
        cfcCode: null,
        charityName: null,
        step: null
      };
      $scope.userDashboardNavbar = {
        user: null,
        charityDashboardUserId: null,
        charityDashboardCfcCode: null
      };
      $scope.leftSideNavbar = {
        userId: null,
        year: null
      };
      $scope.currentUser = null;
      $scope.moduleName = 'home';
      $scope.appPledgeYear = null;
      $scope.appCfcCode = null;
      $scope.userRoles = USER_ROLES;
      $scope.availableSystemUserSecondLevelRoleList = SYSTEM_USER_SECONDARY_ROLES;
      BrowserEventService.setAvailableSystemUserSecondLevelRoleList(
        $scope.availableSystemUserSecondLevelRoleList
      );
      $scope.systemUserSecondaryRoleList = SYSTEM_USER_SECONDARY_ROLES;
      $scope.isAuthorized = AuthService.isAuthorized();
      $scope.isAuthenticated = AuthService.isAuthenticated;
      $scope.bannerMessage = null;
      $scope.expDateMonths = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
      ];

      $scope.setCurrentUser = function(user) {
        $scope.currentUser = user;
        BrowserEventService.setCurrentUser(user);
      };
      $scope.getCurrentUser = function() {
        return $scope.currentUser;
      };
      $scope.setModuleName = function(module) {
        $scope.moduleName = module;
      };
      $scope.isApplication = function() {
        return $scope.moduleName === 'CfcCharity.application';
      };
      $scope.isFederationApplication = function() {
        return $scope.moduleName === 'CfcCharity.federation_application';
      };
      $scope.isSystemUser = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          currentUser &&
          currentUser.roles &&
          currentUser.roles.indexOf($scope.userRoles.system_user) >= 0
        );
      };
      $scope.isSystemUserSecondaryRoleList = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUser() &&
          currentUser.secondaryRoleList &&
          currentUser.secondaryRoleList.length > 0
        );
      };
      $scope.isSecondaryRoleGBAdmin = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.gb_admin
          ) >= 0
        );
      };
      $scope.isSecondaryRoleSystemAdminUser = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          (currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.gb_admin
          ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.opm_admin
            ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.opm_staff
            ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.customer_service_manager
            ) >= 0)
        );
      };
      $scope.isSecondaryRoleFinanceUser = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          (currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.gb_finance
          ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.gb_finance_admin
            ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.customer_service_tier_2_finance
            ) >= 0)
        );
      };
      $scope.isSecondaryRoleGbFinanceUser = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          (currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.gb_finance
          ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.gb_finance_admin
            ) >= 0)
        );
      };
      $scope.isSecondaryRoleGbFinanceAdmin = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.gb_finance_admin
          ) >= 0
        );
      };
      $scope.disableUserEdit = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          (currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.gb_finance
          ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.gb_finance_admin
            ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.opm_auditor
            ) >= 0)
        );
      };
      $scope.isSecondaryRoleCustomerServiceTier2Finance = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.customer_service_tier_2_finance
          ) >= 0
        );
      };
      $scope.isSecondaryRoleCSTier1 = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.customer_service_tier_1
          ) >= 0
        );
      };
      $scope.isSecondaryRoleCustomerServiceManager = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.customer_service_manager
          ) >= 0
        );
      };
      $scope.isSecondaryRoleOPMAuditor = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.opm_auditor
          ) >= 0
        );
      };
      $scope.isSecondaryRoleOPMStaff = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.opm_staff
          ) >= 0
        );
      };
      $scope.isSecondaryRoleOPMAdmin = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.opm_admin
          ) >= 0
        );
      };
      $scope.isSecondaryRoleZoneManager = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.zone_manager
          ) >= 0
        );
      };
      $scope.isSecondaryRoleReviewer = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.reviewer
          ) >= 0
        );
      };
      $scope.isSecondaryRoleZoneCoordinator = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.zone_coordinator
          ) >= 0
        );
      };
      $scope.isSecondaryRoleZoneLevelUser = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          (currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.zone_manager
          ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.reviewer
            ) >= 0)
        );
      };
      $scope.isSecondaryRoleNationalCampaignManager = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.national_campaign_manager
          ) >= 0
        );
      };
      $scope.setAdminAndCharityUser = function(blnAdminAndCharityUser) {
        $scope.adminAndCharityUser = blnAdminAndCharityUser;
      };
      $scope.isSecondaryRoleSystemAdminUserOnly = function() {
        var currentUser = $scope.getCurrentUser();
        return (
          $scope.isSystemUserSecondaryRoleList &&
          (currentUser.secondaryRoleList.indexOf(
            $scope.systemUserSecondaryRoleList.gb_admin
          ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.opm_admin
            ) >= 0 ||
            currentUser.secondaryRoleList.indexOf(
              $scope.systemUserSecondaryRoleList.opm_staff
            ) >= 0)
        );
      };
      $scope.isAdminAndCharityUser = function() {
        return $scope.adminAndCharityUser;
      };
      $scope.setAdminUser = function(blnAdminUser) {
        $scope.adminUser = blnAdminUser;
      };
      $scope.isAdminUser = function() {
        return $scope.adminUser;
      };
      $scope.isUserDashboard = function() {
        return $scope.moduleName === 'CfcCharity.userdashboard';
      };
      $scope.setSystemUserSecondLevelRoleList = function(
        systemUserSecondLevelRoleList
      ) {
        $scope.systemUserSecondLevelRoleList = systemUserSecondLevelRoleList;
        BrowserEventService.setSystemUserSecondLevelRoleList(
          systemUserSecondLevelRoleList
        );
      };
      $scope.isCharityDashboard = function() {
        return $scope.moduleName === 'CfcCharity.charitydashboard';
      };
      $scope.isCharityAuditDashboard = function() {
        return $scope.moduleName === 'CfcCharity.charityAuditDashboard';
      };
      $scope.setUserDashboardNavbarData = function(user) {
        BrowserEventService.setUpdateInfoUserId(user.id);
        $scope.userDashboardNavbar.user = user;
      };
      $scope.setLeftSideNavbarData = function(userId, year) {
        BrowserEventService.setUserApplicationDataYear(year);
        $scope.leftSideNavbar.userId = userId;
        $scope.leftSideNavbar.year = year;
      };
      $scope.setLeftSideNavbarMenuItemSelected = function(menuItemSelected) {
        $scope.leftSideNavbarMenuItemSelected = menuItemSelected;
      };
      $scope.setMemberApplicationType = function(memberApplicationType) {
        $scope.memberApplicationType = memberApplicationType;
      };
      $scope.setMemberApplicationParentCfcCode = function(
        memberApplicationParentCfcCode
      ) {
        $scope.memberApplicationParentCfcCode = memberApplicationParentCfcCode;
      };
      $scope.setMemberApplicationParentCharityType = function(
        memberApplicationParentCharityType
      ) {
        $scope.memberApplicationParentCharityType = memberApplicationParentCharityType;
      };
      $scope.setFromMemberCharitiesApplication = function(
        fromMemberCharitiesApplication
      ) {
        $scope.fromMemberCharitiesApplication = fromMemberCharitiesApplication;
      };
      $scope.isHome = function() {
        return $scope.moduleName === 'home';
      };
      $scope.setAppPledgeYear = function(appPledgeYear) {
        $scope.appNavbar.pledgeYear = appPledgeYear;
      };
      $scope.setAppCfcCode = function(appCfcCode) {
        $scope.appNavbar.cfcCode = appCfcCode;
      };
      $scope.setAppStep = function(step) {
        $scope.appNavbar.step = step;
        $scope.federationNavbar.step = null;
      };
      $scope.isStepEqualTo = function(step) {
        return step === $scope.appNavbar.step;
      };
      $scope.isFysa = function() {
        return $scope.appNavbar.fysa;
      };
      $scope.setFysa = function(fysa) {
        $scope.appNavbar.fysa = fysa;
      };
      $scope.setFederationAppPledgeYear = function(federationPledgeYear) {
        $scope.federationNavbar.pledgeYear = federationPledgeYear;
      };
      $scope.setFederationAppCfcCode = function(federationCfcCode) {
        $scope.federationNavbar.cfcCode = federationCfcCode;
      };
      $scope.setFederationAppStep = function(step) {
        $scope.federationNavbar.step = step;
        $scope.appNavbar.step = null;
      };
      $scope.isFederationStepEqualTo = function(step) {
        return step === $scope.federationNavbar.step;
      };
      $scope.logout = function() {
        $scope.sessionTimedOut = false;
        UserService.setUser({});
        AuthService.logout();
        $rootScope.$broadcast('logout');
      };
      $scope.logoutStayOnPage = function() {
        $scope.sessionTimedOut = false;
        UserService.setUser({});
        AuthService.logoutStayOnPage();
        $rootScope.$broadcast('logout');
      };
      $scope.transmitStep = function(step) {
        $scope.$broadcast('appStepChangeEvent', step);
      };

      $scope.sessionTimedOut = false;
      $scope.sessionTimeOutCounter = 60;
      $scope.sessionTimeOutDialog = function() {
        toastrService.error(
          'The session is about to expire. Your session will be inactivated and you will be logged out.',
          'Session Inactive',
          {
            closeButton: false,
            preventDuplicates: true,
            positionClass: 'toast-top-full-width'
          },
          {
            onHidden: function() {
              $scope.resetSessionTimeOut();
            }
          }
        );
      };
      $rootScope.sessionTimeOutDialog = $scope.sessionTimeOutDialog;
      $scope.resetSessionTimeOut = function() {
        $scope.stopTimer();
        $scope.sessionTimedOut = false;
      };
      $scope.sessionTimeOutClose = function() {
        $scope.stopTimer();
        if (toastr) {
          toastr.clear();
        }
        $scope.sessionTimedOut = true;
      };
      $rootScope.sessionTimeOutClose = $scope.sessionTimeOutClose;
      var mytimeout = null;
      $scope.onTimeout = function() {
        if ($scope.sessionTimeOutCounter === 0) {
          $scope.$broadcast('timer-stopped', 0);
          $timeout.cancel(mytimeout);
          return;
        }
        $scope.sessionTimeOutCounter--;
        mytimeout = $timeout($scope.onTimeout, 1000);
      };
      $scope.startTimer = function() {
        mytimeout = $timeout($scope.onTimeout, 1000);
      };
      $scope.stopTimer = function() {
        $scope.$broadcast('timer-stopped', $scope.sessionTimeOutCounter);
        $scope.sessionTimeOutCounter = 60;
        $timeout.cancel(mytimeout);
      };
      $scope.$on('timer-stopped', function(event, remaining) {
        if (remaining === 0) {
        }
      });
      $scope.setInvitationsCount = function(count) {
        $scope.invitationsCount = count;
      };
      $scope.setRequestsCount = function(count) {
        $scope.requestsCount = count;
      };
      $scope.gotoApplicationDashboard = function() {
        var applicationId = ApplicationService.getApplication().id;
        ApplicationService.reset();
        $state.go('applicationDashboard', {
          applicationId: applicationId
        });
      };
      $scope.gotoFederationDashboard = function() {
        var applicationId = FederationApplicationService.getApplication().id;
        FederationApplicationService.reset();
        $state.go('federationDashboard', {
          federationId: applicationId
        });
      };
      $scope.gotoUserDashboard = function() {
        if ($location.path() === '/userDashboard') {
          $state.reload();
        } else {
          $state.go('userDashboard', {
            userId: $scope.getCurrentUser().id
          });
        }
      };

      $scope.login = async function() {
        var url = '/CfcCharity/api/public/cognitoMeta';
        $http.get(url).then(res => {
          if ($location.host().indexOf('localhost') < 0) {
            var loginRedirectUrl = `https://${$location.host()}/authRedirect`;
          } else {
            var loginRedirectUrl = `http://${$location.host()}:${$location.port()}/authRedirect`;
          }

          cognitoClientId = res.data.CHARITY_UI_CLIENT_ID;
          userPoolDomain = res.data.USER_POOL_DOMAIN;

          const encodedRedirectUrl = encodeURI(loginRedirectUrl);

          if (userPoolDomain) {
            const loginGovUrl = `https://${userPoolDomain}.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri=${encodedRedirectUrl}&response_type=code&client_id=${cognitoClientId}&identity_provider=LoginGovCharity&scope=email%20openid`;
            window.location.href = loginGovUrl;
          } else {
            $('#retryLogin').modal();
          }
        });
      };

      $scope.navigateToReports = function() {
        $window.location.href = '/reports';
      };

      // when current user is not set (on first page load)
      // attempt to load from session
      if (!$scope.currentUser) {
        const user = UserService.getUser();
        if (user) {
          $scope.setCurrentUser(user);
        }
        const scopeParams = JSON.parse(sessionStorage.getItem('SCOPE_PARAMS'));
        if (scopeParams) {
          $scope.setAdminUser(scopeParams.adminUser);
          $scope.setAdminAndCharityUser(scopeParams.adminAndCharityUser);
        }
      }
    }
  ])
  .directive('inputFocus', [
    '$timeout',
    function($timeout) {
      return {
        scope: {
          trigger: '@focus'
        },
        link: function(scope, element) {
          scope.$watch('trigger', function(value) {
            $timeout(function() {
              element[0].focus();
            });
          });
        }
      };
    }
  ])
  .directive('onlyDigits', function() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            var digits = val.replace(/[^0-9]/g, '');
            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }
            return parseInt(digits, 10);
          }
          return undefined;
        }
        ctrl.$parsers.push(inputValue);
      }
    };
  })
  .directive('onEnter', function() {
    var linkFn = function(scope, element, attrs) {
      element.bind('keypress', function(event) {
        if (event.which === 13) {
          scope.$apply(function() {
            scope.$eval(attrs.onEnter);
          });
          event.preventDefault();
        }
      });
    };

    return {
      link: linkFn
    };
  })
  .directive('spinnerMask', function() {
    // adapted off of https://ankursethi.in/2013/07/20/loading-spinners-with-angularjs-and-spin-js/
    return {
      restrict: 'A',
      replace: true,
      transclude: true,
      scope: {
        status: '=spinnerMask'
      },
      template: require('./app/common/spinnerMask.html'),
      link: function(scope, element, attrs) {
        scope.clearMyError = false;
        scope.enforceTooltips = function() {
          // enforce bootstrap tooltips following DOM manipulation
          angular.element('[data-toggle="tooltip"]').tooltip();
        };
      }
    };
  })
  .directive('ngReallyClick', [
    '$window',
    function($window) {
      /*
       * ngReallyClick : directive declaration for confirmations (with message) prior to click events
       * ref: https://codepen.io/edm00se/pen/NBYaeW?editors=1010
       *
       * - instead of directly binding via `ng-click`, use `ng-really-click`
       * - a confirmation message/question is available with `ng-really-message`
       * - a cancel message is available with `ng-really-canceled-message`
       */
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          element.bind('click', function() {
            var message = attrs.ngReallyMessage;
            if (message && $window.confirm(message)) {
              scope.$apply(attrs.ngReallyClick);
            } else {
              $window.alert(attrs.ngReallyCanceledMessage);
            }
          });
        }
      };
    }
  ])
  .directive('phoneInput', function($filter, $browser) {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function($scope, $element, $attrs, ngModelCtrl) {
        var listener = function() {
          var value = $element.val().replace(/[^0-9]/g, '');
          $element.val($filter('tel')(value, false));
        };

        // This runs when we update the text field
        ngModelCtrl.$parsers.push(function(viewValue) {
          return viewValue.replace(/[^0-9]/g, '').slice(0, 10);
        });

        // This runs when the model gets updated on the scope directly and keeps our view in sync
        ngModelCtrl.$render = function() {
          $element.val($filter('tel')(ngModelCtrl.$viewValue, false));
        };

        $element.bind('change', listener);
        $element.bind('keydown', function(event) {
          var key = event.keyCode;
          // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
          // This lets us support copy and paste too
          if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
            return;
          }
          $browser.defer(listener); // Have to do this or changes don't get picked up properly
        });

        $element.bind('paste cut', function() {
          $browser.defer(listener);
        });
      }
    };
  })
  .filter('tel', function() {
    return function(tel) {
      if (!tel) {
        return '';
      }

      var value = tel
        .toString()
        .trim()
        .replace(/^\+/, '');

      if (value.match(/[^0-9]/)) {
        return tel;
      }

      var country, city, number;

      switch (value.length) {
        case 1:
        case 2:
        case 3:
          city = value;
          break;

        default:
          city = value.slice(0, 3);
          number = value.slice(3);
      }

      if (number) {
        if (number.length > 3) {
          number = number.slice(0, 3) + '-' + number.slice(3, 7);
        } else {
          number = number;
        }

        return ('(' + city + ') ' + number).trim();
      } else {
        return '(' + city;
      }
    };
  })
  .filter('zipcode', function() {
    return function(input) {
      if (!input) {
        return input;
      }
      if (input.toString().length === 9) {
        return input.toString().slice(0, 5) + '-' + input.toString().slice(5);
      } else if (input.toString().length === 5) {
        return input.toString();
      } else {
        return input;
      }
    };
  })
  .filter('creditCardTypeFilter', function() {
    return function(cardNumber) {
      if (
        cardNumber.substring(0, 2) === '34' ||
        cardNumber.substring(0, 2) === '37'
      ) {
        return 'American Express';
      } else if (cardNumber.substring(0, 1) === '4') {
        return 'Visa';
      } else if (
        cardNumber.substring(0, 1) === '5' ||
        cardNumber.substring(0, 1) === '2'
      ) {
        return 'MasterCard';
      } else if (cardNumber.substring(0, 1) === '6') {
        return 'Discover';
      } else {
        return null;
      }
    };
  })
  .filter('reviewCommentLevelFilter', function() {
    return function(comment) {
      if (comment.indexOf('-') > 0) {
        return comment.substring(0, comment.indexOf('-'));
      } else {
        return '';
      }
    };
  })
  .filter('reviewCommentFilter', function() {
    return function(comment) {
      // Per MarkN no longer strip leading text before "-"
      return comment;
      // if(comment.indexOf("-") < 0){
      //     return comment;
      // }else{
      //   return comment.substring(comment.indexOf("-")+1);
      // }
    };
  })
  .filter('titleCase', function() {
    return function(input) {
      input = input || '';
      return input.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };
  })
  .filter('titleCaseWithSpaces', function() {
    return function(input) {
      input = input || '';
      return input.replace(/_/g, ' ').replace(/\w\S*/g, function(txt) {
        return (
          txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        ).replace(/_/g, ' ');
      });
    };
  })
  .filter('removeHTMLTags', function() {
    return function(text) {
      return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
  })
  .run([
    '$rootScope',
    '$anchorScroll',
    function($rootScope, $anchorScroll) {
      $rootScope.appVersion = 1;
      $rootScope.$on('$stateChangeSuccess', function() {
        $anchorScroll();
      });
    }
  ]);
