// banner.controller.js - This is the controller logic for the system banner management
'use strict';
(function() {
  angular
    .module('CfcCharity.systemmanagement')
    .config(function($provide) {
      $provide.decorator('taOptions', [
        'taRegisterTool',
        '$delegate',
        function(taRegisterTool, taOptions) {
          taRegisterTool('fontSize', {
            display:
              "<span class='bar-btn-dropdown dropdown'>" +
              "<button class='btn btn-blue dropdown-toggle' type='button' ng-disabled='showHtml()' style='padding-top: 4px'><i class='fa fa-text-height'></i><i class='fa fa-caret-down'></i></button>" +
              "<ul class='dropdown-menu'><li ng-repeat='o in options'><button class='btn btn-blue checked-dropdown' style='border-style:solid; border-width:1px; border-color:black; font-size: {{o.css}}; width: 100%' type='button' ng-click='action($event, o.value)'><i ng-if='o.active' class='fa fa-check'></i> {{o.name}}</button></li></ul>" +
              '</span>',
            action: function(event, size) {
              //Ask if event is really an event.
              if (!!event.stopPropagation) {
                //With this, you stop the event of textAngular.
                event.stopPropagation();
                //Then click in the body to close the dropdown.
                $('body').trigger('click');
              }
              return this.$editor().wrapSelection('fontSize', parseInt(size));
            },
            options: [
              { name: 'xx-small', css: 'xx-small', value: 1 },
              { name: 'x-small', css: 'x-small', value: 2 },
              { name: 'small', css: 'small', value: 3 },
              { name: 'medium', css: 'medium', value: 4 },
              { name: 'large', css: 'large', value: 5 },
              { name: 'x-large', css: 'x-large', value: 6 },
              { name: 'xx-large', css: 'xx-large', value: 7 }
            ]
          });
          taRegisterTool('fontColor', {
            display:
              "<spectrum-colorpicker trigger-id='{{trigger}}' ng-model='color' on-change='!!color && action(color)' format='\"hex\"' options='options'></spectrum-colorpicker>",
            action: function(color) {
              var me = this;
              if (!this.$editor().wrapSelection) {
                setTimeout(function() {
                  me.action(color);
                }, 100);
              } else {
                return this.$editor().wrapSelection('foreColor', color);
              }
            },
            options: {
              replacerClassName: 'fa fa-font',
              showButtons: false
            },
            color: '#000'
          });

          // add the button to the default toolbar definition
          taOptions.toolbar[1].push('backgroundColor', 'fontColor');
          taOptions.defaultTagAttributes.a.target = '_blank';
          return taOptions;
        }
      ]);
    })
    .controller('BannerCtl', BannerCtl)
    .factory('BannerService', BannerService);

  BannerCtl.$inject = [
    '$log',
    'BannerService',
    'toastrService',
    'notificationService',
    'blockUI',
    '$state',
    'BrowserEventService'
  ];
  function BannerCtl(
    $log,
    BannerService,
    toastrService,
    notificationService,
    blockUI,
    $state,
    BrowserEventService
  ) {
    var vm = this;

    function init() {
      vm.messageChanged = false;
      vm.lastMessage = null;
      vm.ready = false;

      BannerService.getBannerMessage()
        .then(function(response) {
          blockUI.start();
          vm.lastMessage = response.data.messageText;
          vm.systemMessage = response.data.messageText;
          vm.ready = true;
        })
        .catch(function(err) {
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Request failed.');
        })
        .finally(function() {
          blockUI.stop();
        });
    }

    vm.updateSystemBanner = function() {
      BannerService.setBannerMessage({ message: vm.systemMessage })
        .then(function(res) {
          notificationService.notify(vm.systemMessage);
          vm.messageChanged = false;
          $state.go('systemmanagement', {
            userId: BrowserEventService.getCurrentUser().id
          });
        })
        .catch(function(err) {
          toastrService.error('Save failed');
          $log.error(err);
        });
    };

    vm.clearMessage = function() {
      BannerService.clearBannerMessage()
        .then(function(res) {
          blockUI.start();
          notificationService.notify('');
          vm.lastMessage = null;
          vm.systemMessage = null;
          vm.messageChanged = false;
        })
        .catch(function(err) {
          toastrService.error('Clear Failed');
          $log.error(err);
        })
        .finally(function() {
          blockUI.stop();
        });
    };

    vm.cancelChanges = function() {
      vm.systemMessage = vm.lastMessage;
      vm.messageChanged = false;
      $state.go('systemmanagement', {
        userId: BrowserEventService.getCurrentUser().id
      });
    };

    init();
  }

  BannerService.$inject = ['$http'];
  function BannerService($http) {
    function getBannerMessage() {
      var url = '/CfcCharity/api/public/home/banner';
      return $http.get(url);
    }

    // Pass message as object
    function setBannerMessage(message) {
      var url = '/CfcCharity/api/private/home/banner';
      return $http.post(url, { messageText: message.message });
    }

    function clearBannerMessage() {
      var url = '/CfcCharity/api/private/home/banner';
      return $http.put(url, {});
    }

    return {
      setBannerMessage: setBannerMessage,
      getBannerMessage: getBannerMessage,
      clearBannerMessage: clearBannerMessage
    };
  }
})();
