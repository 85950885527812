var app = angular.module('PageSizeUserDashBoardModule', [
  'angularUtils.directives.dirPagination'
]);

app.controller('PageSizeUserDashBoardController', [
  '$rootScope',
  '$scope',
  '$location',
  'BrowserEventService',

  function($rootScope, $scope, $location, BrowserEventService) {
    var myCurrentPage = BrowserEventService.getUserDashboardCurrentPage();
    if (angular.isUndefined(myCurrentPage)) {
      $scope.currentPage = 1;
    } else {
      $scope.currentPage = myCurrentPage;
    }

    $scope.pageSizeOptions = [
      {
        value: '5',
        id: 1
      },
      {
        value: '10',
        id: 2
      },
      {
        value: '25',
        id: 3
      },
      {
        value: '50',
        id: 4
      }
    ];
    $scope.selectedPageSize = $scope.pageSizeOptions[1];
    $scope.resetPage = function() {
      $scope.currentPage = 1;
    };

    $scope.pageChanged = function(newPageNumber) {
      $scope.currentPage = newPageNumber;
      BrowserEventService.setUserDashboardCurrentPage($scope.currentPage);
    };
  }
]);
