(function() {
  var moduleName = 'CfcCharity.directives.adminReviewSection';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }
  module.service('AdminReviewSectionService', [
    '$http',
    'CubeSvc',
    function($http, CubeSvc) {
      var zoneDetails = function(applicationId, isFed) {
        if (!isFed) {
          return retrieveZoneData(applicationId);
        } else {
          return retrieveZoneDataFed(applicationId);
        }
      };

      function retrieveZoneDataFed(applicationId) {
        var data = {
          zoneId: null,
          zoneCode: null
        };
        return CubeSvc.get(
          '/CfcCharity/api/private/federation/' + applicationId
        ).then(function(response) {
          var responseValue = response.data.value;
          data.zoneCode = responseValue.federationZone.zoneCode;
          data.zoneId = responseValue.federationZone.zoneId;
          return data;
        });
      }

      function retrieveZoneData(applicationId) {
        var data = {
          zoneId: null,
          zoneCode: null
        };
        return $http
          .get(
            '/CfcCharity/api/private/application/getZoneDetails?applicationId=' +
              applicationId
          )
          .then(function(response) {
            data.zoneCode = response.data.zoneCode;
            data.zoneId = response.data.zoneId;
            return data;
          });
      }

      return { zoneDetails: zoneDetails };
    }
  ]);
  module.directive('cfcAdminReviewSection', [
    '$state',
    '$rootScope',
    '$filter',
    '$log',
    'AdminReviewSectionService',
    'CharityDashboardService',
    'FederationApplicationService',
    'ApplicationService',
    'ZoneCenterService',
    'AdminReviewSectionStepDeterminationSessionService',
    'AdminReviewSectionStepDeterminationService',
    'reviewLevelConstants',
    'BrowserEventService',
    'CharityService',
    'ApplicationDashboardService',
    'applicationTypes',
    'reviewLevelService',
    'CubeSvc',
    'UserService',
    'SYSTEM_USER_SECONDARY_ROLES',
    '$http',
    function(
      $state,
      $rootScope,
      $filter,
      $log,
      AdminReviewSectionService,
      CharityDashboardService,
      FederationApplicationService,
      ApplicationService,
      ZoneCenterService,
      AdminReviewSectionStepDeterminationSessionService,
      AdminReviewSectionStepDeterminationService,
      reviewLevelConstants,
      BrowserEventService,
      CharityService,
      ApplicationDashboardService,
      applicationTypes,
      reviewLevelService,
      CubeSvc,
      UserService,
      SYSTEM_USER_SECONDARY_ROLES,
      $http
    ) {
      return {
        restrict: 'E',
        template: require('../../../views/private/admin/directives/adminReviewSection.html'),
        scope: true,
        link: function($scope, $element, $attrs) {},
        controller: function($scope, $element, $attrs) {
          $scope.show = false;
          var hasPriorStep = false;
          var isFedApp = $scope.federationNavbar.step != null;
          $scope.isFedApp = isFedApp;
          $scope.currentStep =
            $scope.appNavbar.step || $scope.federationNavbar.step;
          $scope.pledgeYear = determinePledgeYear();
          $scope.campaignId = BrowserEventService.getCampaignId();
          $scope.cfcCode = isFedApp
            ? FederationApplicationService.getCfcCode()
            : ApplicationService.getCfcCode();
          $scope.DBAnotRequested = true;
          $scope.disableNextBtn = false;
          $scope.isOPMAuditor = false;
          $scope.validRevenue = true;
          $scope.showMsg = false;
          function init() {
            $scope.$on('reloadCfcAdminReview', function() {
              if ($scope.currentStep === 6 && !isFedApp) {
                fetchCharityListingObject(
                  $scope.pledgeYear,
                  $scope.$parent.applicationStep6.cfcCode
                );
              } else if ($scope.currentStep === 4 && isFedApp) {
                fetchCharityListingObject(
                  $scope.pledgeYear,
                  $scope.$parent.applicationStep4.cfcCode
                );
              }
            });

            var user = $scope.getCurrentUser();
            if (
              user.secondaryRoleList &&
              Array.isArray(user.secondaryRoleList)
            ) {
              $scope.showAdminStepReview =
                user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
                user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
                user.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0;
            }

            if (user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0) {
              $scope.isOPMAuditor = true;
            }

            hasPriorStep =
              $scope.currentStep > 1 || $scope.currentStep === '2a';
            hasNextStep = hasNextStep();
            // DBA Name methods live in CharityDashboardService
            if ($scope.currentStep === 6 && !isFedApp) {
              $log.info('Firing Charity step6 logic...');
              fetchCharityListingObject(
                $scope.pledgeYear,
                $scope.$parent.applicationStep6.cfcCode
              );
            }

            if ($scope.currentStep === 4 && isFedApp) {
              $log.info('Firing Federation step4 logic...');
              fetchCharityListingObject(
                $scope.pledgeYear,
                $scope.$parent.cfcCode
              );
            }

            // CCA-1719 display Review Comments
            if (isFedApp) {
              $scope.applicationId = FederationApplicationService.getApplicationId();
              $scope.federationId = $scope.applicationId;
              $scope.federationCfc = $scope.cfcCode;
              $scope.applicationType = applicationTypes.Federation;
              if ($scope.applicationId != null) {
                var url =
                  '/CfcCharity/api/private/federation/' + $scope.applicationId;
                CubeSvc.get(url).then(function(res) {
                  $scope.result = res.data.value;
                  $scope.appStatus = $scope.result.applicationStatus;
                  if (
                    $scope.currentStep === 10 &&
                    ($scope.appStatus === 'DISBANDED' ||
                      $scope.appStatus === 'WITHDRAWN')
                  ) {
                    $scope.disableNextBtn = true;
                  }
                });
              } else if (
                $scope.applicationId === null &&
                $scope.year &&
                $scope.cfcCode
              ) {
                url =
                  '/CfcCharity/api/private/federationapplication/' +
                  $scope.year +
                  '/' +
                  $scope.cfcCode;
                CubeSvc.get(url).then(function(res) {
                  $scope.result = res.data.value;
                  $scope.appStatus = $scope.result.applicationStatus;
                  if (
                    $scope.currentStep === 10 &&
                    ($scope.appStatus === 'DISBANDED' ||
                      $scope.appStatus === 'WITHDRAWN')
                  ) {
                    $scope.disableNextBtn = true;
                  }
                });
              }
            } else {
              $scope.applicationId = ApplicationService.getApplicationId();
              $scope.app = ApplicationService.getApplication();
              ApplicationDashboardService.applicationDetailsById(
                $scope.applicationId
              ).then(function(resp) {
                var res = resp.data.value;
                $scope.federationId = res.federationNumber;
                $scope.federationCfc = res.federationCfcCode;
                $scope.appStatus = res.status;
                if (
                  ($scope.currentStep === 11 || $scope.currentStep === '2a') &&
                  $scope.appStatus === 'WITHDRAWN'
                ) {
                  $scope.disableNextBtn = true;
                }
                if ($scope.federationId != null) {
                  $scope.applicationType = applicationTypes.Member;
                } else {
                  $scope.applicationType = applicationTypes.Independent;
                }
              });

              //revenue mismatch check
              if ($scope.currentStep === 7 || $scope.currentStep === 9) {
                $scope.showMsg = true;
                CubeSvc.get(
                  '/CfcCharity/api/private/application/revenuedetails/' +
                    $scope.applicationId,
                  null,
                  false
                ).then(function(res) {
                  $scope.validRevenue = res.data.value;
                });
              }
            }
            if ($scope.applicationId) {
              if (
                UserService.hasSystemRole([
                  SYSTEM_USER_SECONDARY_ROLES.opm_admin,
                  SYSTEM_USER_SECONDARY_ROLES.opm_staff,
                  SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
                  SYSTEM_USER_SECONDARY_ROLES.zone_manager,
                  SYSTEM_USER_SECONDARY_ROLES.reviewer // zone
                ])
              ) {
                ZoneCenterService.getApplicationReviewLogs(
                  $scope.applicationId
                ).then(function(res) {
                  var reviewLogs = res.data.value;
                  $log.debug('fetching Review comments...');
                  $scope.reviewLogs = reviewLogs;
                });
              }
            } else {
              $log.error("Yikes! Can't determine applicationId");
            }
            if (
              AdminReviewSectionStepDeterminationService.isAuthorized() === true
            ) {
              AdminReviewSectionService.zoneDetails(
                $scope.applicationId,
                isFedApp
              ).then(function(result) {
                $scope.zoneId = result.zoneId;
                $scope.zoneCode = result.zoneCode;
              });
              AdminReviewSectionStepDeterminationService.getReviewLevelData(
                $scope.applicationId,
                $scope.currentStep,
                isFedApp
              ).then(function(result) {
                $scope.reviewDetails = result;
                var sessionReviewLevel = reviewLevelService.getCurrentReviewLevel(
                  $scope.reviewDetails
                );
                if (sessionReviewLevel != null) {
                  switch (sessionReviewLevel.displayText) {
                    case 'Staff Eligibility':
                      $scope.reviewLevel = 'Staff Eligibility';
                      break;
                    case 'Preliminary Eligibility':
                      $scope.reviewLevel = 'Preliminary Eligibility';
                      break;
                    case 'Final Eligibility':
                      $scope.reviewLevel = 'Final Eligibility';
                      break;
                    case 'Completeness':
                    default:
                      $scope.reviewLevel = 'Completeness';
                      break;
                  }
                } else {
                  var reviewLevel = result.reviewLevels;
                  if (reviewLevel.completenessReviewStatus != null) {
                    $scope.reviewLevel = 'Completeness';
                  } else if (reviewLevel.staffReviewStatus != null) {
                    $scope.reviewLevel = 'Staff Eligibility';
                  } else if (reviewLevel.preliminaryReviewStatus != null) {
                    $scope.reviewLevel = 'Preliminary Eligibility';
                  } else if (reviewLevel.finalReviewStatus != null) {
                    $scope.reviewLevel = 'Final Eligibility';
                  } else {
                    $scope.reviewLevel = 'Completeness';
                  }
                }
              });
            }
          }

          $scope.goToPriorStep = function(parFedAppId) {
            if (isFedApp) {
              goToPriorFedAppStep();
            } else {
              goToPriorAppStep(parFedAppId);
            }
          };

          $scope.goToNextStep = function(parFedAppId) {
            if (isFedApp) {
              goToNextFedAppStep();
            } else {
              goToNextAppStep(parFedAppId);
            }
          };

          $scope.goToRD = function() {
            if (isFedApp) {
              if (
                $scope.appStatus !== 'DISBANDED' &&
                $scope.appStatus !== 'WITHDRAWN'
              ) {
                $state.go('federationapplicationreview', {
                  federationApplicationId: $scope.applicationId
                });
              }
              return;
            } else {
              if ($scope.appStatus !== 'WITHDRAWN') {
                $state.go('charityAuditDashboard', {
                  applicationId: $scope.applicationId
                });
              }
              return;
            }
          };

          $scope.goToZoneApplications = function() {
            $state.go('zoneApplications', {
              zoneId: $scope.zoneId,
              pledgeYear: $scope.pledgeYear
            });
          };

          $scope.goToDashBoard = function() {
            if (isFedApp) {
              $state.go('federationDashboard', {
                federationId: $scope.applicationId
              });
              return;
            } else {
              $state.go('applicationDashboard', {
                applicationId: $scope.applicationId
              });
              return;
            }
          };

          $scope.goToDenialReasonsPage = function() {
            // review-denial-reasons
            $state.go('review-denial-reasons', {
              isFederation: isFedApp,
              applicationId: $scope.applicationId,
              sourcePageName: $state.current.name,
              sourcePageParams: $state,
              charityType: $scope.reviewDetails.charityType
            });
          };

          $scope.goToFederationAppPackage = function() {
            // review-denial-reasons
            $state.go('federationapplication', {
              year: $scope.pledgeYear,
              cfcCode: $scope.federationCfc,
              fedAppId: $scope.federationId
            });
          };

          $scope.goToMemberApplications = function() {
            // review-denial-reasons
            $state.go('memberapplication', {
              userId: $scope.userId,
              year: $scope.pledgeYear,
              cfcCode: $scope.federationCfc
            });
          };

          $scope.goToReviewLevelApplications = function() {
            switch ($scope.reviewLevel) {
              case 'Review Completeness':
                $state.go('zonecompletenessreview', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              case 'Staff Eligibility':
                $state.go('zonereviewlevel1', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              case 'Preliminary Eligibility':
                $state.go('zonereviewlevel2', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              case 'Final Eligibility':
                $state.go('zonereviewlevel3', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              default:
                $state.go('zonecompletenessreview', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
            }
          };

          $scope.goToReviewLevel = function() {
            if (
              $scope.appStatus !== 'DISBANDED' &&
              $scope.appStatus !== 'WITHDRAWN'
            ) {
              switch ($scope.reviewLevel) {
                case 'Review Completeness':
                  if (isFedApp) {
                    $state.go('federationCompletenessReview', {
                      federationApplicationId: $scope.applicationId
                    });
                    return;
                  } else {
                    $state.go('reviewCompleteness', {
                      applicationId: $scope.applicationId
                    });
                    return;
                  }
                  break;
                case 'Staff Eligibility':
                  if (isFedApp) {
                    $state.go('federationStaffEligibilityReview', {
                      federationApplicationId: $scope.applicationId
                    });
                    return;
                  } else {
                    $state.go('staffEligibility', {
                      applicationId: $scope.applicationId
                    });
                    return;
                  }
                  break;
                case 'Preliminary Eligibility':
                  if (isFedApp) {
                    $state.go('federationPreliminaryEligibilityReview', {
                      federationApplicationId: $scope.applicationId
                    });
                    return;
                  } else {
                    $state.go('preliminaryEligibility', {
                      applicationId: $scope.applicationId
                    });
                    return;
                  }
                  break;
                case 'Final Eligibility':
                  if (isFedApp) {
                    $state.go('federationFinalEligibilityReview', {
                      federationApplicationId: $scope.applicationId
                    });
                    return;
                  } else {
                    $state.go('finalEligibility', {
                      applicationId: $scope.applicationId
                    });
                    return;
                  }
                  break;
                default:
                  if (isFedApp) {
                    $state.go('federationCompletenessReview', {
                      federationApplicationId: $scope.applicationId
                    });
                    return;
                  } else {
                    $state.go('reviewCompleteness', {
                      applicationId: $scope.applicationId
                    });
                    return;
                  }
              }
            }
          };

          $scope.viewPopup = function(popupId) {
            $('#' + popupId).modal();
          };

          init();

          function hasNextStep() {
            // fed app
            if (isFedApp) {
              return $scope.currentStep < 10;
            }

            return $scope.currentStep < 11;
          }

          function goToPriorFedAppStep() {
            if (hasPriorStep) {
              var nextStep = 'federationStep' + --$scope.currentStep;
              $state.go(nextStep, { mode: $scope.appVisibility.viewMode });
              return;
            }
            goToReviewLevelDashboard();
          }

          function goToPriorAppStep(parFedAppId) {
            if (hasPriorStep) {
              if ($scope.currentStep === '2a') {
                $state.go('step2', {
                  mode: $scope.appVisibility.viewMode,
                  parentFedAppId: parFedAppId
                });
                return;
              }
              var nextStep = 'step' + --$scope.currentStep;
              $state.go(nextStep, {
                mode: $scope.appVisibility.viewMode,
                parentFedAppId: parFedAppId
              });
              return;
            }
            goToReviewLevelDashboard();
          }

          function goToNextFedAppStep() {
            if (hasNextStep) {
              var nextStep = 'federationStep' + ++$scope.currentStep;
              $state.go(nextStep, { mode: $scope.appVisibility.viewMode });
              return;
            }
            goToReviewLevelDashboard();
          }

          function goToNextAppStep(parFedAppId) {
            if (hasNextStep) {
              if (
                $scope.currentStep === 2 &&
                $scope.applicationStep2.fysaFyspWfr
              ) {
                $state.go('step2a', {
                  mode: $scope.mode,
                  parentFedAppId: parFedAppId
                });
                return;
              }

              var nextStep = 'step' + ++$scope.currentStep;
              $state.go(nextStep, {
                mode: $scope.appVisibility.viewMode,
                parentFedAppId: parFedAppId
              });
              return;
            }
            goToReviewLevelDashboard();
          }

          function determinePledgeYear() {
            var pledgeYear = null;
            if (isFedApp) {
              pledgeYear = FederationApplicationService.getPledgeYear();
            } else {
              pledgeYear = ApplicationService.getPledgeYear();
            }
            if (pledgeYear == null) {
              pledgeYear = $scope.$parent.pledgeYear;
            }
            if (pledgeYear == null) {
              pledgeYear = $scope.$parent.appNavbar.pledgeYear;
            }
            if (pledgeYear == null) {
              pledgeYear = '2018';
            }
            $log.debug('returning PledgeYear: ' + pledgeYear);
            return pledgeYear;
          }

          $scope.showDBANameInfo = function() {
            if (isFedApp && $scope.currentStep === 4) {
              return true;
            }
            if (!isFedApp && $scope.currentStep === 6) {
              return true;
            }
          };

          function goToReviewLevelDashboard() {
            var sessionReviewLevel = AdminReviewSectionStepDeterminationSessionService.getCurrentReviewLevel();
            switch (sessionReviewLevel) {
              case reviewLevelConstants.Completeness:
                if (isFedApp) {
                  $state.go('federationCompletenessReview', {
                    federationApplicationId: $scope.applicationId
                  });
                  return;
                } else {
                  $state.go('reviewCompleteness', {
                    applicationId: $scope.applicationId
                  });
                  return;
                }
                break;
              case reviewLevelConstants.StaffEligibility:
                if (isFedApp) {
                  $state.go('federationStaffEligibilityReview', {
                    federationApplicationId: $scope.applicationId
                  });
                  return;
                } else {
                  $state.go('staffEligibility', {
                    applicationId: $scope.applicationId
                  });
                  return;
                }
                break;
              case reviewLevelConstants.PreliminaryEligibility:
                if (isFedApp) {
                  $state.go('federationPreliminaryEligibilityReview', {
                    federationApplicationId: $scope.applicationId
                  });
                  return;
                } else {
                  $state.go('preliminaryEligibility', {
                    applicationId: $scope.applicationId
                  });
                  return;
                }
                break;
              case reviewLevelConstants.FinalEligibility:
                if (isFedApp) {
                  $state.go('federationFinalEligibilityReview', {
                    federationApplicationId: $scope.applicationId
                  });
                  return;
                } else {
                  $state.go('finalEligibility', {
                    applicationId: $scope.applicationId
                  });
                  return;
                }
                break;
              default:
                if (isFedApp) {
                  $state.go('federationapplicationreview', {
                    federationApplicationId: $scope.applicationId
                  });
                  return;
                } else {
                  $state.go('charityAuditDashboard', {
                    applicationId: $scope.applicationId
                  });
                  return;
                }
                break;
            }
          }

          $scope.showDBANameInfo = function() {
            if (isFedApp && $scope.currentStep === 4) {
              return true;
            }
            if (!isFedApp && $scope.currentStep === 6) {
              return true;
            }
            return false;
          };

          $scope.changeDbaNameApprovalStatus = function(status, reason) {
            if ($scope.charityListingObject == null) {
              fetchCharityListingObject(
                $scope.pledgeYear,
                $scope.$parent.cfcCode
              );
            }
            CharityDashboardService.changeDbaNameApprovalStatusService(
              $scope.charityListingObject.cfcCode,
              $scope.charityListingObject.campaignId,
              status,
              reason
            )
              .then(function(res) {
                $log.info('DBA Name status changed to: ' + status);
                fetchCharityListingObject(
                  $scope.pledgeYear,
                  $scope.$parent.cfcCode
                );
              })
              .catch(function(err) {
                $log.error(
                  'Yikes! unable to change DBA Name status ' + err.data
                );
              });
          };

          $scope.onChangeDBADenial = function(status) {
            if (!$scope.charityListingViewObject) {
              return;
            }
            if (status === 'Denied') {
              angular.element('#DBAdenial').modal('show');
            } else if (
              $scope.charityListingViewObject.dbanameChangeStatus ===
                'Denied' &&
              status === 'Denied'
            ) {
              return;
            } else {
              $scope.changeDbaNameApprovalStatus(status);
            }
          };

          $scope.cancelDBADenial = function() {
            if (!isFedApp) {
              fetchCharityListingObject(
                $scope.pledgeYear,
                $scope.$parent.applicationStep6.cfcCode
              );
            } else {
              fetchCharityListingObject(
                $scope.pledgeYear,
                $scope.$parent.applicationStep4.cfcCode
              );
            }
            angular.element('#DBAdenial').modal('hide');
          };

          $scope.toggleDbaNameOnly = function(useOnlyDbaFlag) {
            $log.info("Setting 'Use DBA Name only' to: " + !useOnlyDbaFlag);
            CharityDashboardService.toggleUseDBAOnlyFlag(
              $scope.charityListingObject.cfcCode,
              $scope.charityListingObject.campaignId,
              !useOnlyDbaFlag
            )
              .then(function(res) {
                fetchCharityListingObject(
                  $scope.pledgeYear,
                  $scope.$parent.cfcCode
                );
              })
              .catch(function(err) {
                $log.error(
                  'Yikes! unable to toggle Use DBA Name only:  ' + err.data
                );
              });
          };

          $scope.addReviewComment = function(comment) {
            $scope.addReviewerCommentErrors = [];
            if (
              !comment ||
              ((comment && comment.trim().length < 1) || comment.length > 2000)
            ) {
              $scope.addReviewerCommentErrors.push(
                'Please enter a comment between 1 and 2000 characters.'
              );
            } else if (
              comment &&
              (comment.length > 0 && comment.length <= 2000)
            ) {
              ZoneCenterService.addReviewComment(
                $scope.applicationId,
                comment
              ).then(function() {
                $scope.comments = '';
                $scope.addReviewerCommentErrors = [];
                $('#comment').modal('toggle');
                ZoneCenterService.getApplicationReviewLogs(
                  $scope.applicationId
                ).then(function(res) {
                  var reviewLogs = res.data.value;
                  $scope.reviewLogs = reviewLogs;
                });
              });
            }
          };

          $scope.resetReviewerComment = function() {
            $scope.comments = '';
            $scope.addReviewerCommentErrors = [];
          };

          function fetchCharityListingObject(pledgeYear, cfcCode) {
            $log.debug(
              'Fetching CharityListing object for ' +
                cfcCode +
                ' and pledgeYear ' +
                pledgeYear
            );
            CharityDashboardService.getCharityListingObject(pledgeYear, cfcCode)
              .then(function(res) {
                var result = res.data.value;
                if (result !== null && result !== '') {
                  $scope.$parent.charityListingObject = result;
                  $rootScope.$on('editDbaName', function(event, res) {
                    $scope.$parent.charityListingObject = result;
                    $scope.DBAnotRequested = true;
                    $scope.$parent.charityListingObject.charityDBAName =
                      res.charityDBAName;
                  });
                  if ($scope.$parent.charityListingObject.charityDBAName) {
                    $scope.DBAnotRequested = false;
                  }
                  $scope.$parent.charityListingViewObject = angular.copy(
                    $scope.$parent.charityListingObject
                  );
                  $log.debug('Refreshed Charity listing obj');
                  fetchDbaNameApprovalHistory();
                }
              })
              .catch(function(err) {
                $log.debug(
                  'Yikes! unable to fetch CharityListing object!' + err.data
                );
              });
          }

          function fetchDbaNameApprovalHistory() {
            if ($scope.showAdminStepReview) {
              $log.debug('Fetching history for DBA Name status');
              CharityDashboardService.getDbaNameApprovalHistory(
                $scope.charityListingObject.campaignId,
                $scope.charityListingObject.cfcCode
              )
                .then(function(res) {
                  var result = res.data;
                  if (result.message.type === CubeSvc.messageType.SUCCESS) {
                    var history = result.value;
                    $scope.dbaNameApprovalHistory = history;
                    if (history && history.length > 0) {
                      $log.debug('Refreshing history for DBA Name status...');
                      $scope.latestHistory = $filter('orderBy')(
                        history,
                        'dbaNameApprovalDate',
                        true
                      )[0];
                    } else {
                      $log.debug('No history for DBA Name status.');
                    }
                  }
                })
                .catch(function(err) {
                  $log.error(
                    'Yikes! unable to fetch DBA Name status history!' + err.data
                  );
                });
            }
          }
        }
      };
    }
  ]);
})();
