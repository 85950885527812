(function() {
  var moduleName = 'CfcCharity.directives.charityIrsStatus';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcCharityIrsStatus', [
    '$http',
    'CharityDashboardService',
    'AuthorizationHelper',
    'AnalyticsService',
    'CubeSvc',
    'SYSTEM_USER_SECONDARY_ROLES',
    function(
      $http,
      CharityDashboardService,
      AuthorizationHelper,
      AnalyticsService,
      CubeSvc,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      return {
        template: require('./charityIrsStatus.html'),
        restrict: 'E',
        scope: {
          cfcCode: '@',
          charityStatus: '@'
        },
        controller: function($scope) {
          $scope.canEditCharityIrsStatus = false;
          $scope.showHistoryButton = false;
          $scope.charityInformation = null;

          // CCA-1834 edit charity status is only for OPM Admin
          $scope.canEditCharityIrsStatus = AuthorizationHelper.userHasAnyRole([
            SYSTEM_USER_SECONDARY_ROLES.opm_admin
          ]);

          $scope.showHistoryButton = AuthorizationHelper.userHasAnyRole([
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
          ]);

          $scope.viewStatusHistory = function() {
            //get the status history
            CharityDashboardService.getCharityStatusHistory(
              $scope.cfcCode
            ).then(function(res) {
              $scope.limit = 5;
              $scope.viewStatusHistoryList = res.data.value;
              $('#statusHistory').modal('show');
            });
          };

          $scope.setLimit = function(lim, items) {
            $scope.limit = lim <= 0 ? items.length : lim;
          };

          $scope.editCharityIrsStatus = function() {
            var toState = {
              name: 'editCharityStatus'
            };
            AnalyticsService.addUserPageActivity(toState, '', $http);
            CharityDashboardService.findCharityWithCFC($scope.cfcCode).then(
              function(res) {
                var result = res.data;
                if (result !== null && result !== '') {
                  $scope.charityInformation = result;
                  if ($scope.charityInformation.charityStatus === null) {
                    $scope.charityInformation.charityStatus = 'ACTIVE';
                  }
                  // for remaining characters in maxlength for appeal reason
                  var maxLength = 2000;
                  $('textarea').keyup(function() {
                    var length = $(this).val().length;
                    length = maxLength - length;
                    $('#chars').text(length);
                  });
                  $('#changeIrsStatus').modal('show');
                }
              }
            );
          };

          /**
           * Update Charity address to update charity address in charity table
           */
          $scope.updateCharityAddress = function(
            charityInformation,
            updateType
          ) {
            CharityDashboardService.updateCharity(
              charityInformation,
              updateType
            ).then(function(res) {
              CubeSvc.performManualCacheRelease();
              var result = res.data;
              if (result.message.type !== CubeSvc.messageType.FAILURE) {
                $scope.isCharityUpdated = true;
                $scope.charityStatus = charityInformation.charityStatus;
                $('#statusCancelBtn').click();
                var toState = {
                  name: 'charityDashboard'
                };
                AnalyticsService.addUserPageActivity(toState, '', $http);
              }
            });
          };
        }
      };
    }
  ]);
})();
