(function() {
  'use strict';
  angular
    .module('CfcCharity.admin.system')
    .factory('uploadRegionFilesService', uploadRegionFilesService);

  uploadRegionFilesService.$inject = ['$q', 'CubeSvc'];

  function uploadRegionFilesService($q, CubeSvc) {
    return {
      getRegionFiles: getRegionFiles,
      uploadRegionFiles: uploadRegionFiles,
      deleteRegionFile: deleteRegionFile
    };

    function getRegionFiles() {
      var url = '/CfcCharity/api/private/admin/getIRSMasterRegionFiles';
      return CubeSvc.get(url, null, true);
    }

    function uploadRegionFiles(file) {
      var url = '/CfcCharity/api/private/admin/uploadIrsRegionFile';
      var fd = new FormData();
      for (var key in file) {
        if (key.indexOf('$') < 0) {
          if (null != file[key]) {
            fd.append(key, file[key]);
          }
        }
      }
      return CubeSvc.post(url, fd, {
        transformRequest: angular.identity,
        headers: {
          'Content-Type': undefined
        }
      });
    }

    function deleteRegionFile(file) {
      var url =
        '/CfcCharity/api/private/admin/deleteIRSMasterRegionFile/' + file;
      return CubeSvc.delete(url, null, true);
    }
  }
})();
