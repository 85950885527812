// printorder.controller.js - Controller for the Admin Zone Listing Print Order view

'use strict';

angular
  .module('CfcCharity.admin.zone.listing')
  .controller('AdminZoneListingPrintorderCtl', AdminZoneListingPrintorderCtl);

AdminZoneListingPrintorderCtl.$inject = [
  '$scope',
  'BrowserEventService',
  'AdminZoneListingService',
  '$log',
  '$state',
  'parameterData',
  'DownloadService',
  'RedirectService'
];

function AdminZoneListingPrintorderCtl(
  $scope,
  BrowserEventService,
  AdminZoneListingService,
  $log,
  $state,
  parameterData,
  DownloadService,
  RedirectService
) {
  $scope.moduleName = 'CfcCharity.admin.zone.listing';
  $scope.zoneRandomizationOrderBeanData = null;
  $scope.zone = null;
  $scope.pledgeYear = null;
  $scope.zoneUserRole = null;
  $scope.campaignId = null;
  $scope.showRandomizeButtons = false;
  $scope.dataPrintIncludeNoPrint = false;

  init();

  function init() {
    if (parameterData) {
      $scope.zone = parameterData.zone;
      $scope.pledgeYear = parameterData.pledgeYear;
      $scope.campaignId = parameterData.campaignId;
      $scope.zoneUserRole = parameterData.zoneUserRole;
    }

    if ($scope.zone && $scope.zone !== '' && $scope.pledgeYear) {
      BrowserEventService.setZone($scope.zone);
      BrowserEventService.setCampaignId($scope.campaignId);
      BrowserEventService.setZoneId($scope.zone.id);
      BrowserEventService.setPledgeYear($scope.pledgeYear);
      BrowserEventService.setZoneUserRole($scope.zoneUserRole);
      AdminZoneListingService.getRandomizedZoneFederations(
        $scope.campaignId,
        $scope.zone.id
      ).then(function(res) {
        var result = res.data;
        if (result) {
          $scope.zoneRandomizationOrderBeanData = result;

          // Now set randomization access based on role and randomization results
          AdminZoneListingService.getRandomizationAccess(
            $scope.campaignId,
            $scope.zone.id,
            $scope.zoneRandomizationOrderBeanData.length
          ).then(function(resp) {
            var result = resp.data;
            if (result) {
              $scope.showRandomizeButtons = result;
            }
          });

          $scope.disableRandomizeAndHistoryButtons = false;
          if (
            $scope.$parent.currentUser.secondaryRoleList[0].indexOf(
              'ZONE_COORDINATOR'
            ) >= 0
          ) {
            $scope.disableRandomizeAndHistoryButtons = true;
          }
        }
      });
    }
  }

  $scope.randomizeZoneFederations = function(isRefresh) {
    AdminZoneListingService.randomizeZoneFederations(
      $scope.campaignId,
      $scope.zone.id,
      $scope.zoneRandomizationOrderBeanData.length,
      isRefresh
    )
      .then(function(res) {
        var result = res.data.value;
        if (result) {
          $scope.zoneRandomizationOrderBeanData = result;
        }
      })
      .catch(function(err) {
        //cube service handles the error with a toastr
        if (err.status === 504) {
          $scope.timeOutmessage =
            'Randomization for this zone is currently in progress. Please navigate away from this page and come back in sometime later to view the randomized list.';
        }
      });
  };

  $scope.sortRandomizedZoneFederations = function() {
    AdminZoneListingService.sortRandomizedZoneFederations(
      $scope.campaignId,
      $scope.zone.id
    )
      .then(function(res) {
        var result = res.data.value;
        if (result) {
          $scope.zoneRandomizationOrderBeanData = result;
        }
      })
      .catch(function(err) {
        //cube service handles the error with a toastr
      });
  };

  $scope.goToCharityDashboard = function(cfcCode) {
    $state.go('charitydashboard', {
      charityDashboardCfcCode: cfcCode
    });
  };

  $scope.goToFederationMemberListings = function(cfcCode) {
    $state.go('federationmemberlistings', {
      charityDashboardCfcCode: cfcCode,
      pledgeYear: $scope.pledgeYear
    });
  };

  $scope.downloadCharityListingInfoByZone = function() {
    var url =
      '/CfcCharity/api/private/admin/zone/listingDataZoneReportDownload/' +
      $scope.campaignId +
      '/' +
      $scope.zone.id;
    DownloadService.downloadFile(url);
  };

  $scope.downloadRandomizationData = function() {
    var url =
      '/CfcCharity/api/private/admin/zone/randomizationDataReportDownload/' +
      $scope.campaignId +
      '/' +
      $scope.zone.id;
    DownloadService.downloadFile(url);
  };

  $scope.downloadPrintAllRandomizationData = function() {
    var url =
      '/CfcCharity/api/private/admin/zone/randomizationPrintDataReportDownload/' +
      $scope.campaignId +
      '?includeNoPrint=' +
      $scope.dataPrintIncludeNoPrint;
    DownloadService.downloadFile(url);
    $scope.dataPrintIncludeNoPrint = false;
  };

  $scope.downloadPrintZoneRandomizationData = function() {
    var url =
      '/CfcCharity/api/private/admin/zone/listingGiving/' +
      $scope.campaignId +
      '/' +
      $scope.zone.id +
      '?includeNoPrint=' +
      $scope.dataPrintIncludeNoPrint +
      '&zoneCode=' +
      $scope.zone.code +
      '&zoneName=' +
      $scope.zone.name;
    DownloadService.downloadFile(url);
    $scope.dataPrintIncludeNoPrint = false;
  };

  $scope.gotoZoneCenter = function() {
    BrowserEventService.setZoneUserRole($scope.zoneUserRole);
    BrowserEventService.setZoneId($scope.zone.id);
    $state.go('zoneCenter', {
      zoneId: $scope.zone.id,
      zoneUserRole: $scope.zoneUserRole
    });
  };

  $scope.gotoPrintorderHistory = function() {
    BrowserEventService.setZone($scope.zone);
    BrowserEventService.setCampaignId($scope.campaignId);
    BrowserEventService.setZoneId($scope.zone.id);
    BrowserEventService.setPledgeYear($scope.pledgeYear);
    BrowserEventService.setZoneUserRole($scope.zoneUserRole);
    $state.go('admin-zone-listing-printorder-history', {
      zone: $scope.zone,
      campaignId: $scope.campaignId,
      pledgeYear: $scope.pledgeYear,
      zoneUserRole: $scope.zoneUserRole
    });
  };
}
