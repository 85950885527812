(function() {
  'use strict';

  angular
    .module('CfcCharity.admin.system')
    .factory('emailTemplatesService', emailTemplatesService);

  emailTemplatesService.$inject = ['$http'];

  function emailTemplatesService($http) {
    var getEmailTemplates = function() {
      return $http.get('/CfcCharity/api/private/email/templates');
    };

    var getTriggeredEmailTemplates = function() {
      return $http.get('/CfcCharity/api/private/triggered/email/templates');
    };

    var getEmailTemplate = function(id) {
      return $http.get('/CfcCharity/api/private/email/template/' + id);
    };

    var saveEmailTemplate = function(emailTemplate) {
      return $http.put(
        '/CfcCharity/api/private/email/template/' + emailTemplate.id,
        emailTemplate
      );
    };

    return {
      getEmailTemplates: getEmailTemplates,
      getTriggeredEmailTemplates: getTriggeredEmailTemplates,
      getEmailTemplate: getEmailTemplate,
      saveEmailTemplate: saveEmailTemplate
    };
  }
})();
