// pledge.controller.js - Controller for the Admin System Jobs view

'use strict';

angular
  .module('CfcCharity.charitydashboard')
  .controller('CharityPledgeCtl', CharityPledgeCtl);

CharityPledgeCtl.$inject = [
  '$scope',
  'PledgeService',
  'DownloadService',
  'BrowserEventService',
  '$state',
  '$log',
  'urlAccess',
  'AuthorizationHelper',
  'SYSTEM_USER_SECONDARY_ROLES',
  'CharityService',
  '$location',
  'CharityDashboardService'
];

function CharityPledgeCtl(
  $scope,
  PledgeService,
  DownloadService,
  BrowserEventService,
  $state,
  $log,
  urlAccess,
  AuthorizationHelper,
  SYSTEM_USER_SECONDARY_ROLES,
  CharityService,
  $location,
  CharityDashboardService
) {
  init();
  $scope.activePageNumber = 1;
  $scope.searchResultsPerPage = 50;

  /* get pledge data by year/cfcCode for charity*/
  if (!$scope.isFederation && !$scope.isMemberData) {
    PledgeService.getCharityDashboardData($scope.cfcCode, $scope.pledgeYear)
      .then(function(res) {
        if (res) {
          $scope.charityPledgeData = res.data;
        }
      })
      .catch(function(err) {
        $('#serverError').show();
      });
  }

  $scope.closeAlert = function(alertId) {
    $('#' + alertId).hide();
  };

  /* fet federation members pledge data*/
  if ($scope.isMemberData) {
    PledgeService.getMembersDashboardData($scope.cfcCode, $scope.pledgeYear)
      .then(function(res) {
        if (res) {
          $scope.membersPledgeDashboardData = res.data;
        }
      })
      .catch(function(err) {
        $('#memberServerError').show();
      });
  }

  $scope.pageChanged = function(newPage) {
    if ($scope.activePageNumber !== newPage) {
      $scope.activePageNumber = newPage;
      PledgeService.getMemberNewpageData(
        $scope.cfcCode,
        $scope.pledgeYear,
        newPage - 1
      )
        .then(function(res) {
          if (res) {
            $scope.membersPledgeDashboardData.memberPledges =
              res.data.memberPledges;
          }
        })
        .finally(function() {
          window.scroll(0, 0);
        })
        .catch(function(err) {
          $('#memberServerError').show();
        });
    }
  };

  /* get pledge data by year/cfcCode for federation*/
  if ($scope.isFederation) {
    PledgeService.getFederationDashboardData($scope.cfcCode, $scope.pledgeYear)
      .then(function(res) {
        if (res) {
          $scope.federationPledgeData = res.data;
        }
      })
      .catch(function(err) {
        $('#serverErrorFederation').show();
      });
  }

  //check the access for the donor data report button
  if ($scope.pledgeYear !== null && $scope.cfcCode !== null) {
    urlAccess
      .getDonorDataDownloadAccess($scope.pledgeYear, $scope.cfcCode)
      .then(function(res) {
        $scope.showDownloadDonorDataBtn = res;
      });
  }

  /* download Donor Data Report */
  $scope.downloadDonorData = function(campaignYear, cfcCode, includeMembers) {
    if (includeMembers === undefined || includeMembers == null) {
      includeMembers = false;
    }
    var url =
      '/CfcCharity/api/private/admin/reports/pledge/charityDonors/' +
      campaignYear +
      '/' +
      cfcCode +
      '/' +
      includeMembers;
    DownloadService.getFileDownload(url, 'application/ms-excel')
      .then(function(response) {
        // console.log("Yippee! it worked");
      })
      .catch(function(err) {
        if (!$scope.isFederation && !$scope.isMemberData) {
          $('#serverError').show();
        }
        if ($scope.isFederation) {
          $('#serverErrorFederation').show();
        }
        if ($scope.isMemberData) {
          $('#memberServerError').show();
        }
      });
  };

  $scope.downloadAnonymizedFederationPledgeData = function() {
    var url =
      '/CfcCharity/api/private/federation/reports/anonymizedFederationPledgeData/' +
      $scope.pledgeYear +
      '/' +
      $scope.cfcCode;

    DownloadService.getFileDownload(url, 'application/ms-excel')
      .then(function() {})
      .catch(function() {
        $('#serverErrorFederation').show();
      });
  };

  $scope.downloadAnonymizedRawPledgeDataIndMem = function() {
    var url =
      '/CfcCharity/api/private/reports/pledge/anonymizedRaw/indMem/' +
      $scope.pledgeYear +
      '/' +
      $scope.cfcCode +
      '/' +
      $scope.applicationType;

    DownloadService.getFileDownload(url, 'application/ms-excel')
      .then(function() {})
      .catch(function() {
        $('#serverError').show();
      });
  };

  function init() {
    $scope.moduleName = 'CfcCharity.charitydashboard';
    $('#serverError').hide();
    $('#memberServerError').hide();
    $('#serverErrorFederation').hide();
    $scope.pledgeYear = $state.params.pledgeYear;
    $scope.cfcCode = $state.params.cfcCode;
    $scope.isFederation = $state.params.isFederation;
    $scope.charityName = $state.params.charityName;
    $scope.isMemberData = $state.params.isMemberData || false;
    $scope.showDownloadDonorDataBtn = false;
    $scope.showDownloadAnonymizedFederationPledgeData = false;
    $scope.showDownloadAnonRawPledgeDataIndMemBtn = false;
    $scope.applicationType = $state.params.applicationType;

    if (
      ($location.path() === '/federationPledgeDashboard' &&
        !$scope.showDownloadAnonymizedFederationPledgeData) ||
      ($location.path() === '/charityPledgeDashboards' &&
        !$scope.showDownloadAnonRawPledgeDataIndMemBtn)
    ) {
      if (
        AuthorizationHelper.userHasAnyRole([
          SYSTEM_USER_SECONDARY_ROLES.opm_admin,
          SYSTEM_USER_SECONDARY_ROLES.opm_staff,
          SYSTEM_USER_SECONDARY_ROLES.opm_auditor
        ])
      ) {
        $scope.showDownloadAnonymizedFederationPledgeData = true;
        $scope.showDownloadAnonRawPledgeDataIndMemBtn = true;
        return;
      }
      CharityService.getCharityUserRole($scope.cfcCode).then(function(res) {
        if (res && res.data) {
          var userRole = res.data.userRole
            ? res.data.userRole.toUpperCase()
            : null;
          if (userRole) {
            if (userRole === 'CHARITY_OWNER' || userRole === 'CHARITY_EDITOR') {
              $scope.showDownloadAnonymizedFederationPledgeData = true;
              $scope.showDownloadAnonRawPledgeDataIndMemBtn = true;
            }
          }
        } else {
          CharityDashboardService.getCharityFedMemberAccessForYear(
            $scope.pledgeYear,
            $scope.cfcCode
          )
            .then(function(res) {
              var isFedOwnerEditor = 'true' === res.data;
              if (isFedOwnerEditor) {
                $scope.showDownloadAnonRawPledgeDataIndMemBtn = true;
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
      });
    }
  }
}
