(function() {
  'use strict';

  angular
    .module('CfcCharity.admin.system')
    .controller('UploadRegionFilesCtrl', UploadRegionFilesCtrl);

  UploadRegionFilesCtrl.$inject = [
    '$state',
    '$log',
    'toastrService',
    'blockUI',
    'uploadRegionFilesService',
    'fileUpload',
    '$interval',
    'blockUIConfig',
    '$rootScope',
    'AdminJobsService'
  ];

  function UploadRegionFilesCtrl(
    $state,
    $log,
    toastrService,
    blockUI,
    uploadRegionFilesService,
    fileUpload,
    $interval,
    blockUIConfig,
    $rootScope,
    AdminJobsService
  ) {
    var vm = this;
    vm.IRSMaster = {};
    blockUIConfig.autoBlock = false;
    var currentdate = new Date();

    function init() {
      uploadRegionFilesService
        .getRegionFiles()
        .then(function(res) {
          if (res) {
            vm.totalRecords = 0;
            vm.uploadedRegionFilesData = res.data.value;
            angular.forEach(vm.uploadedRegionFilesData, function(value, key) {
              vm.totalRecords += value.recordCount;
            });
          }
        })
        .catch(function(err) {
          $('#serverError').show();
        });
    }

    var intId = null;

    // start polling the init every interval
    vm.startTimer = function() {
      var timer = 0;
      intId = $interval(function() {
        timer++;
        //after 4 refreshs stop the refreshing of the page
        if (timer <= 3) {
          init();
        } else {
          $interval.cancel(intId);
        }
      }, 5000);
    };

    vm.cancelUploading = function() {
      vm.IRSMaster.supportingFile = null;
      $('#regionFile').val('');
      $('#regionFile').blur();
    };

    // stop job types polling
    vm.stopTimer = function() {
      $interval.cancel(intId);
    };

    // register event to stop polling if user navigates away. Also re-enable block UI
    $rootScope.$on('$destroy', function() {
      vm.stopTimer();
      blockUIConfig.autoBlock = true;
    });

    //This method is invoked to upload IRS Master Charity data file and to parse and Upload to Database.
    vm.uploadIRSRegionFile = function() {
      blockUI.start();
      if (vm.IRSMaster.supportingFile) {
        var promise = uploadRegionFilesService.uploadRegionFiles(vm.IRSMaster);
        promise
          .then(function(res) {
            var data = res.data;
            init();
            vm.startTimer();
            if (data.statusCode !== undefined && data.statusCode === 'ERROR') {
              blockUI.stop();
              $scope.errorMsg = data.message;
            } else {
              blockUI.stop();
            }
          })
          .catch(function(err) {
            $log.error('error uploading file', err);
          });
      } else {
        blockUI.stop();
      }
    };

    vm.deleteFile = function(file) {
      uploadRegionFilesService
        .deleteRegionFile(file)
        .then(function(res) {
          if (res) {
            vm.uploadedRegionFilesData = res.data.value;
            init();
          }
        })
        .catch(function(err) {
          $log.error('error deleting file', err);
        });
    };

    vm.gotoJobs = function() {
      $state.go('admin-system-jobs');
      // vm.scrollTo('IRSMAsterFileJobRun');
    };

    vm.showHistory = function() {
      //get all the job types first
      AdminJobsService.transferCharityDataTypes()
        .then(function(res) {
          vm.jobTypes = res.data;
          //find the IRS master file type
          angular.forEach(vm.jobTypes, function(value, key) {
            if (value.displayName === 'IRS Master File') {
              vm.displayName = value.displayName;
              //get the record for the current year
              angular.forEach(value.activeJobs, function(value1, key1) {
                vm.jobId = value1.jobId;
              });
            }
          });
          $state.go('admin-system-jobs-history', {
            jobId: vm.jobId,
            campaignYear: currentdate.getFullYear(),
            displayName: vm.displayName
          });
        })
        .catch(function(err) {
          $log.error('error getting all jobs', err);
        });
    };

    init();
  }
})();
