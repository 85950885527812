angular
  .module('CfcCharity.manageCharityUser', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('manageCharityUsers', {
        url: '/manageCharityUsers',
        views: {
          main: {
            template: require('../views/private/charity/manageCharityUsers.html'),
            controller: 'managecharityUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Manage Charity Users Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          cfcCode: null,
          charityName: null
        }
      }),
        $stateProvider.state('editCharityUser', {
          url: '/editCharityUser',
          views: {
            main: {
              template: require('../views/private/charity/editCharityUser.html'),
              controller: 'editCharityUserCtrl'
            }
          },
          data: {
            pageTitle: 'Manage Charity Users Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        });
    }
  ])
  .constant(
    'USER_ACTIONS',
    (function() {
      return {
        EDIT: 'Edit',
        DELETE: 'Delete',
        APPROVE: 'Approve',
        REJECT: 'Reject'
      };
    })()
  )
  .controller('managecharityUsersCtrl', [
    '$state',
    '$scope',
    '$location',
    'CharityUsersService',
    '$rootScope',
    'USER_ACTIONS',
    'Session',
    'BrowserEventService',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      CharityUsersService,
      $rootScope,
      USER_ACTIONS,
      session,
      BrowserEventService,
      CubeSvc
    ) {
      $scope.charityApplicationOwner = false;
      $scope.activeUsersClicked = true;
      $scope.requestsUsersClicked = false;
      $scope.sendInvitations = function() {
        $state.go('inviteUserToCharity', {
          charityDashboardCfcCode: $scope.cfcCode,
          adminWithCharityEditAccess: CharityUsersService.getAdminWithCharityEditAccess()
        });
      };
      $scope.gotoCharity = function() {
        $state.go('charitydashboard', {
          charityDashboardCfcCode: $scope.cfcCode
        });
      };
      $scope.activeUsersTabClicked = function() {
        $scope.activeUsersClicked = true;
        $scope.requestsUsersClicked = false;
      };
      $scope.requestsUsersTabClicked = function() {
        $scope.requestsUsersClicked = true;
        $scope.activeUsersClicked = false;
      };
      var init = function() {
        $scope.cfcCode = $state.params.cfcCode;
        $scope.charityName = $state.params.charityName;
        if ($scope.cfcCode == null || $scope.cfcCode === 'undefined') {
          return;
        }
        $scope.loginUser = session.userId;
        var user = $scope.getCurrentUser();
        var roles = user.roles;
        if (roles && roles.length > 0) {
          for (var j = 0; j < roles.length; j++) {
            var roleValue = roles[j];
            if (
              roleValue === 'SYSTEM_USER' &&
              !(
                $scope.isSecondaryRoleOPMAdmin() ||
                $scope.isSecondaryRoleOPMStaff()
              )
            ) {
              $scope.adminWithCharityEditAccess = false;
              $scope.isCustomerServiceManager = $scope.isSecondaryRoleCustomerServiceManager();
              CharityUsersService.setAdminWithCharityEditAccess(false);
              BrowserEventService.setAdminWithCharityEditAccess(false);
              CharityUsersService.setIsCustomerServiceManager(
                $scope.isCustomerServiceManager
              );
            } else {
              $scope.adminWithCharityEditAccess = true;
              CharityUsersService.setAdminWithCharityEditAccess(true);
              BrowserEventService.setAdminWithCharityEditAccess(true);
            }
          }
        }
        CharityUsersService.setCfcCode($scope.cfcCode);
        CharityUsersService.setCharityName($scope.charityName);
        CharityUsersService.getCharityUsers($scope.cfcCode)
          .then(function(res) {
            CubeSvc.performManualCacheRelease();
            var data = res.data.value;
            $scope.charityAccessUsers = data;
            $scope.accessUsersLength = $scope.charityAccessUsers.length;
            if (roles && roles.length > 0) {
              for (var j = 0; j < roles.length; j++) {
                var roleValue = roles[j];
                if (
                  roleValue === 'SYSTEM_USER' &&
                  $scope.charityAccessUsers.length > 0
                ) {
                  for (var k = 0; k < $scope.charityAccessUsers.length; k++) {
                    if (
                      $scope.charityAccessUsers[k].userId === user.id &&
                      $scope.charityAccessUsers[k].role === 'CHARITY_OWNER'
                    ) {
                      $scope.adminWithCharityEditAccess = true;
                      CharityUsersService.setAdminWithCharityEditAccess(true);
                      BrowserEventService.setAdminWithCharityEditAccess(true);
                      break;
                    }
                  }
                }
              }
            }
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
        CharityUsersService.getCharityPendingUsers($scope.cfcCode)
          .then(function(res) {
            $scope.charityAccessPendingUsers = res.data;
            $scope.pendingUsersLength = $scope.charityAccessPendingUsers.length;
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.editUserForCharity = function(userCharityRole) {
        CharityUsersService.setSelectedCharityUser(userCharityRole);
        CharityUsersService.setUserAction(USER_ACTIONS.EDIT);
        $state.go('editCharityUser');
      };
      $scope.deleteUserForCharity = function(userCharityRole) {
        CharityUsersService.setUserAction(USER_ACTIONS.DELETE);
        CharityUsersService.setSelectedCharityUser(userCharityRole);
        $state.go('editCharityUser');
      };
      $scope.approveCharityPendingUser = function(charityAccessRequest) {
        CharityUsersService.setUserAction(USER_ACTIONS.APPROVE);
        CharityUsersService.setSelectedPendingUser(charityAccessRequest);
        $state.go('editCharityUser');
      };
      $scope.rejectCharityPendingUser = function(charityAccessRequest) {
        CharityUsersService.setUserAction(USER_ACTIONS.REJECT);
        CharityUsersService.setSelectedPendingUser(charityAccessRequest);
        $state.go('editCharityUser');
      };
      init();
    }
  ])
  .controller('editCharityUserCtrl', [
    '$state',
    '$scope',
    '$location',
    'CharityUsersService',
    '$rootScope',
    'USER_ACTIONS',
    function(
      $state,
      $scope,
      $location,
      CharityUsersService,
      $rootScope,
      USER_ACTIONS
    ) {
      $scope.editUserGridVisible = false;
      $scope.deleteUserGridVisible = false;
      $scope.approveUserGridVisible = false;
      $scope.rejectUserGridVisible = false;
      $scope.adminWithCharityEditAccess = CharityUsersService.getAdminWithCharityEditAccess();
      $scope.isCustomerServiceManager = CharityUsersService.getIsCustomerServiceManager();
      $scope.approveDropDownVal = 'CHARITY_EDITOR';
      $scope.rejectComments = '';
      $scope.selectedCharity = CharityUsersService.getSelectedCharityUser();
      $scope.selectedPendingCharity = CharityUsersService.getSelectedPendingUser();
      if ($scope.selectedCharity != null) {
        $scope.editDropDownVal = $scope.selectedCharity.role;
      }
      if (CharityUsersService.getUserAction() === USER_ACTIONS.EDIT) {
        $scope.editUserGridVisible = true;
      }
      if (CharityUsersService.getUserAction() === USER_ACTIONS.DELETE) {
        $scope.deleteUserGridVisible = true;
      }
      if (CharityUsersService.getUserAction() === USER_ACTIONS.APPROVE) {
        $scope.approveUserGridVisible = true;
      }
      if (CharityUsersService.getUserAction() === USER_ACTIONS.REJECT) {
        $scope.rejectUserGridVisible = true;
      }
      $scope.cancel = function() {
        $state.go('manageCharityUsers', {
          cfcCode: CharityUsersService.getCfcCode(),
          charityName: CharityUsersService.getCharityName()
        });
      };
      $scope.saveSelectedCharity = function(userCharityRole) {
        CharityUsersService.saveCharityUser(
          userCharityRole.userId,
          userCharityRole.cfcCode,
          $scope.editDropDownVal
        )
          .then(function(res) {
            $state.go('manageCharityUsers', {
              cfcCode: CharityUsersService.getCfcCode(),
              charityName: CharityUsersService.getCharityName()
            });
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.deleteSelectedCharity = function(userCharityRole) {
        CharityUsersService.deleteCharityUser(
          userCharityRole.userId,
          userCharityRole.cfcCode
        )
          .then(function(res) {
            $state.go('manageCharityUsers', {
              cfcCode: CharityUsersService.getCfcCode(),
              charityName: CharityUsersService.getCharityName()
            });
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.approveSelectedCharity = function(charityAccessRequest) {
        CharityUsersService.approveCharityUser(
          charityAccessRequest.id,
          charityAccessRequest.cfcCode,
          $scope.approveDropDownVal
        )
          .then(function(data) {
            $state.go('manageCharityUsers', {
              cfcCode: CharityUsersService.getCfcCode(),
              charityName: CharityUsersService.getCharityName()
            });
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.rejectSelectedCharity = function(charityAccessRequest) {
        CharityUsersService.rejectCharityUser(
          charityAccessRequest.id,
          charityAccessRequest.cfcCode,
          $scope.rejectComments
        )
          .then(function(res) {
            $state.go('manageCharityUsers', {
              cfcCode: CharityUsersService.getCfcCode(),
              charityName: CharityUsersService.getCharityName()
            });
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
    }
  ])
  .filter('userRoleFilter', function() {
    return function(role) {
      if (role === 'CHARITY_OWNER' || role === 'OWNER') {
        return 'Owner';
      } else if (role === 'CHARITY_EDITOR' || role === 'EDITOR') {
        return 'Editor';
      }
    };
  })
  .factory('CharityUsersService', [
    '$http',
    'Session',
    'TokenStorage',
    'CubeSvc',
    function($http, Session, TokenStorage, CubeSvc) {
      var charityUsersService = {};
      charityUsersService.setCfcCode = function(cfcCode) {
        charityUsersService.cfcCode = cfcCode;
      };
      charityUsersService.getCfcCode = function() {
        return charityUsersService.cfcCode;
      };
      charityUsersService.setCharityName = function(charityName) {
        charityUsersService.charityName = charityName;
      };
      charityUsersService.getCharityName = function() {
        return charityUsersService.charityName;
      };
      charityUsersService.setUserAction = function(userAction) {
        charityUsersService.userAction = userAction;
      };
      charityUsersService.getUserAction = function() {
        return charityUsersService.userAction;
      };
      charityUsersService.setSelectedCharityUser = function(
        selectedCharityUser
      ) {
        charityUsersService.selectedCharityUser = selectedCharityUser;
      };
      charityUsersService.getSelectedCharityUser = function() {
        return charityUsersService.selectedCharityUser;
      };
      charityUsersService.setSelectedPendingUser = function(
        selectedPendingUser
      ) {
        charityUsersService.selectedPendingUser = selectedPendingUser;
      };
      charityUsersService.getSelectedPendingUser = function() {
        return charityUsersService.selectedPendingUser;
      };
      charityUsersService.setAdminWithCharityEditAccess = function(
        adminWithCharityEditAccess
      ) {
        charityUsersService.adminWithCharityEditAccess = adminWithCharityEditAccess;
      };
      charityUsersService.getAdminWithCharityEditAccess = function() {
        return charityUsersService.adminWithCharityEditAccess;
      };
      charityUsersService.setIsCustomerServiceManager = function(
        isCustomerServiceManager
      ) {
        charityUsersService.isCustomerServiceManager = isCustomerServiceManager;
      };
      charityUsersService.getIsCustomerServiceManager = function() {
        return charityUsersService.isCustomerServiceManager;
      };
      charityUsersService.saveCharityUser = function(
        user_id,
        cfc_code,
        selected_role
      ) {
        return $http({
          method: 'POST',
          url: '/CfcCharity/api/private/manageUsers/updateCharityAccess',
          params: {
            userId: user_id,
            cfcCode: cfc_code,
            selectedRole: selected_role
          }
        });
      };
      charityUsersService.deleteCharityUser = function(user_id, cfc_code) {
        return $http({
          method: 'POST',
          url: '/CfcCharity/api/private/manageUsers/deleteCharityAccess',
          params: {
            userId: user_id,
            cfcCode: cfc_code
          }
        });
      };
      charityUsersService.approveCharityUser = function(id, cfc_code, role) {
        return $http.get(
          '/CfcCharity/api/private/manageUsers/approvePendingRequest',
          {
            params: {
              id: id,
              cfcCode: cfc_code,
              role: role
            }
          }
        );
      };
      charityUsersService.rejectCharityUser = function(id, cfc_code, comments) {
        return $http.get(
          '/CfcCharity/api/private/manageUsers/rejectCharityAccess',
          {
            params: {
              id: id,
              cfcCode: cfc_code,
              comments: comments
            }
          }
        );
      };
      charityUsersService.getCharityUsers = function(cfc_code) {
        return CubeSvc.get(
          '/CfcCharity/api/private/manageUsers/getUsersWithCharityAccess',
          { params: { cfcCode: cfc_code } },
          false
        );
      };
      charityUsersService.getCharityPendingUsers = function(cfc_code) {
        return $http.get(
          '/CfcCharity/api/private/manageUsers/getUsersWithPendingAccess',
          { params: { cfcCode: cfc_code } }
        );
      };
      return charityUsersService;
    }
  ]);
