/*
UserListingsCtrl--Controller for User Listing Tab in User Home Page @Author Saju

*/
angular
  .module('CfcCharity.userdashboard', [
    'ui.router',
    'CfcCharity.security',
    'hateoas',
    'infinite-scroll'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('userDashboard', {
        url: '/userDashboard',
        views: {
          main: {
            template: require('../views/private/charity/userdashboard.html'),
            controller: 'UserDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'CFC User Home Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          userId: null
        },
        resolve: {
          userDashboardData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService
          ) {
            if ($stateParams.userId === null) {
              var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));
              if (user !== null) {
                $stateParams.userId = user.id;
              }
            }
            var url = '/CfcCharity/api/private/users/userdashboard';
            return $http.get(url).then(function(res) {
              return res.data;
            });
          }
        }
      });
      $stateProvider.state('userCharities', {
        url: '/userCharities',
        views: {
          main: {
            template: require('../views/private/charity/usercharities.html'),
            controller: 'UserCharitiesCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Charities Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          userId: null
        },
        resolve: {
          userCharitiesData: function($http, $stateParams) {
            if ($stateParams.userId != null) {
              var userId = $stateParams.userId;
              var url = '/CfcCharity/api/private/users/usercharities/' + userId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('userApplications', {
        url: '/userApplications',
        views: {
          main: {
            template: require('../views/private/charity/userapplications.html'),
            controller: 'UserApplicationsCtrl'
          }
        },
        data: {
          pageTitle: 'CFC User Applications',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null
        },
        resolve: {
          userApplicationsData: function($http, $stateParams) {
            if ($stateParams.year != null) {
              var year = $stateParams.year;
              var url =
                '/CfcCharity/api/private/users/' + year + '/applications';
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('userListings', {
        url: '/userListings',
        views: {
          main: {
            template: require('../views/private/charity/userlistings.html'),
            controller: 'UserListingsCtrl'
          }
        },
        data: {
          pageTitle: 'CFC User Listings Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null
        },
        resolve: {
          userListingData: function($http, $stateParams) {
            var year = $stateParams.year;
            if (year == null) {
              year = '2017';
            }
            var url = '/CfcCharity/api/private/users/userlistings/' + year;
            return $http.get(url).then(function(res) {
              return res.data;
            });
          }
        }
      });
      $stateProvider.state('userDonations', {
        url: '/userDonations',
        views: {
          main: {
            template: require('../views/private/charity/userdonations.html'),
            controller: 'UserDonationsCtrl'
          }
        },
        data: {
          pageTitle: 'CFC User Donations Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        }
      });
      $stateProvider.state('accessInvitations', {
        url: '/accessInvitations',
        views: {
          main: {
            template: require('../views/private/charity/accessinvitations.html'),
            controller: 'AccessInvitationsCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Invitations Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        }
      });
      $stateProvider.state('accessRequests', {
        url: '/accessRequests',
        views: {
          main: {
            template: require('../views/private/charity/accessrequests.html'),
            controller: 'AccessRequestsCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Requests Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        }
      });
      $stateProvider.state('newUserWelcome', {
        url: '/newUserWelcome',
        views: {
          main: {
            template: require('../views/private/charity/newUserWelcome.html'),
            controller: 'UserDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Charity - New User Welcome',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          userId: null
        },
        resolve: {
          userDashboardData: function($http) {
            var url = '/CfcCharity/api/private/users/userdashboard';
            return $http.get(url).then(function(res) {
              return res.data;
            });
          }
        }
      });
    }
  ])
  .service('DashboardService', [
    '$http',
    function($http) {
      this.acceptedInvitation = function(inviteUser) {
        return $http.post(
          '/CfcCharity/api/private/charity/acceptInvitation',
          inviteUser
        );
      };
      this.declinedInvitation = function(inviteUser) {
        return $http.post(
          '/CfcCharity/api/private/charity/declineInvitation',
          inviteUser
        );
      };
      this.setEnrollmentAvailable = function(isEnrollmentAvailable) {
        this.isEnrollmentAvailable = isEnrollmentAvailable;
      };
      this.getEnrollmentAvailable = function() {
        return this.isEnrollmentAvailable;
      };
      this.setListingAvailable = function(isListingAvailable) {
        this.isListingAvailable = isListingAvailable;
      };
      this.getListingAvailable = function() {
        return this.isListingAvailable;
      };
    }
  ])
  .service('InvitationCountService', [
    '$http',
    function($http) {
      this.getAccessInvitationsCount = function() {
        return $http.get(
          '/CfcCharity/api/private/users/accessinvitationscount'
        );
      };
      this.accessInvitationsData = function() {
        return $http.get('/CfcCharity/api/private/users/accessinvitations');
      };
      this.accessRequestsData = function() {
        return $http.get('/CfcCharity/api/private/users/accessrequests');
      };
    }
  ])
  .service('ListingService', [
    '$http',
    function($http) {
      this.getCampaignInformation = function(campaignId) {
        var url = '/CfcCharity/api/private/admin/editcampaign/' + campaignId;
        return $http.get(url);
      };
    }
  ])
  .controller('UserDashboardCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'userDashboardData',
    'DashboardService',
    'ListingService',
    'CharityService',
    'ApplicationService',
    'FederationApplicationService',
    'UserCharityRole',
    'BrowserEventService',
    '$http',
    '$log',
    'CommonService',
    'RedirectService',
    'toastrService',
    'motdService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      userDashboardData,
      DashboardService,
      ListingService,
      CharityService,
      ApplicationService,
      FederationApplicationService,
      UserCharityRole,
      BrowserEventService,
      $http,
      $log,
      CommonService,
      RedirectService,
      toastrService,
      motdService
    ) {
      $scope.isListingAvailable = false;
      $scope.isListingTableHeaderAvailable = false;
      var user = $scope.getCurrentUser();
      if ($state.params.userId === null || user === null) {
        user = JSON.parse(sessionStorage.getItem('USER_OBJ'));

        var scopeParams = JSON.parse(sessionStorage.getItem('SCOPE_PARAMS'));
        $scope.setCurrentUser(user);
        $scope.setAdminUser(scopeParams.adminUser);
        $scope.setAdminAndCharityUser(scopeParams.adminAndCharityUser);
      }
      $scope.setModuleName('CfcCharity.userdashboard');
      $scope.dashboardData = userDashboardData;
      $scope.isNationalCampaignManagerOnly = false;
      $scope.isNationalCampaignManager = false;
      if (userDashboardData && userDashboardData.pledgeYear) {
        BrowserEventService.setPledgeYear(userDashboardData.pledgeYear);
        sessionStorage.setItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR',
          userDashboardData.pledgeYear
        );
      }

      $scope.userCharityList = [];
      var userCharityListTemp = [];
      $scope.statusValues = [];
      $scope.matchedStatus = undefined;
      var userId =
        $state.params.userId !== null && $state.params.userId !== ''
          ? $state.params.userId
          : user.id;
      if ($scope.dashboardData != null) {
        if (
          $scope.dashboardData.userRoleBean &&
          $scope.dashboardData.userRoleBean.secondLevelRoleList
        ) {
          $scope.setSystemUserSecondLevelRoleList(
            $scope.dashboardData.userRoleBean.secondLevelRoleList
          );
          $scope.userCharityTempList = $scope.userCharityList.concat(
            $scope.dashboardData.userCharityRoleBeanList
          );
          angular.forEach($scope.userCharityTempList, function(ch) {
            if (ch.cfcCode.length === 5) {
              $scope.userCharityList.push(ch);
            }
          });
          if (
            $scope.userCharityList &&
            $scope.userCharityList.length === 0 &&
            userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
              'NATIONAL_CAMPAIGN_MANAGER'
            ) >= 0
          ) {
            $scope.isNationalCampaignManagerOnly = true;
            $scope.isNationalCampaignManager = true;
          }
          if ($scope.userCharityList && $scope.userCharityList.length > 0) {
            if (
              userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                'NATIONAL_CAMPAIGN_MANAGER'
              ) >= 0
            ) {
              $scope.isNationalCampaignManagerOnly = false;
              $scope.isNationalCampaignManager = true;
            }
            for (i = 0; i < $scope.userCharityList.length; i++) {
              // Set correct statuses
              if (
                $scope.statusValues.indexOf(
                  $scope.userCharityList[i].applicationStatus
                ) === -1
              ) {
                $scope.statusValues.push(
                  $scope.userCharityList[i].applicationStatus
                );
              }

              // Set listing security
              var userCharity = $scope.userCharityList[i];
              userCharity.isListingTableItemAvailable = false;
              if (
                userCharity.role.toUpperCase() === 'OWNER' ||
                userCharity.role.toUpperCase() === 'CHARITY_OWNER' ||
                userCharity.role.toUpperCase() === 'EDITOR' ||
                userCharity.role.toUpperCase() === 'CHARITY_EDITOR' ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'OPM_ADMIN'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'OPM_STAFF'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'ZONE_MANAGER'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'REVIEWER'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'CUSTOMER_SERVICE_TIER_1'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'CUSTOMER_SERVICE_TIER_2_FINANCE'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'CUSTOMER_SERVICE_MANAGER'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'OPM_AUDITOR'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'GB_ADMIN'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'GB_FINANCE_ADMIN'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'GB_FINANCE'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'FEDERATION_EDITOR'
                ) >= 0 ||
                userDashboardData.userRoleBean.secondLevelRoleList.indexOf(
                  'FEDERATION_OWNER'
                ) >= 0
              ) {
                userCharity.isListingTableItemAvailable = true;
              } else {
                userCharity.isListingTableItemAvailable = false;
              }
              userCharityListTemp.push(userCharity);
            }
            $scope.userCharityList = userCharityListTemp;
          }

          // Now that each userCharityList.isListingTableItemAvailable flag has been set then set the flag for the table header
          if ($scope.userCharityList && $scope.userCharityList.length > 0) {
            for (i = 0; i < $scope.userCharityList.length; i++) {
              var userCharity = $scope.userCharityList[i];
              if (userCharity.isListingTableItemAvailable === true) {
                $scope.isListingTableHeaderAvailable = true;
                break;
              }
            }
          }
        }

        $scope.setInvitationsCount(
          $scope.dashboardData.totalPendingInvitations
        );
        $scope.setRequestsCount($scope.dashboardData.totalAccessRequests);
        $scope.setLeftSideNavbarData(userId, $scope.dashboardData.pledgeYear);
        $scope.setLeftSideNavbarMenuItemSelected('userhomepage');
        sessionStorage.setItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR',
          $scope.dashboardData.pledgeYear
        );
      }

      BrowserEventService.setUserId(user.id);
      $scope.setUserDashboardNavbarData(user);
      $scope.charityDetail = function(userId, cfcCode) {
        BrowserEventService.setCfcCode(cfcCode);
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
      $scope.isEnrollmentAvailable = false;
      CharityService.findEnrollmentStatus().then(function(res) {
        $scope.isEnrollmentAvailable = res.data;
        DashboardService.setEnrollmentAvailable($scope.isEnrollmentAvailable);
      });

      CommonService.getListingPeriodStatus().then(function(res) {
        if (res.data) {
          $scope.isListingAvailable = true;
        } else {
          $scope.isListingAvailable = false;
        }

        DashboardService.setListingAvailable($scope.isListingAvailable);
      });

      $scope.populateListingsModal = function() {
        $('#listingModal').modal();
      };
      $scope.isRegPeriodAvailable = function() {
        if ($scope.isEnrollmentAvailable || $scope.isListingAvailable) {
          return true;
        }
        return false;
      };

      $scope.gotoDashboard = function(applicationType, cfcCode, applicationId) {
        BrowserEventService.setCfcCode(cfcCode);

        if (applicationType === 'Federation') {
          $state.go('federationapplication', {
            year: $scope.dashboardData.pledgeYear,
            cfcCode: cfcCode,
            fedAppId: applicationId
          });
        } else if (
          applicationType === 'Member' ||
          applicationType === 'Independent'
        ) {
          $state.go('applicationDashboard', {
            applicationId: applicationId
          });
        }
      };

      $scope.goToCharityListing = function(
        pledgeYear,
        cfcCode,
        applicationType
      ) {
        RedirectService.goToCharityListing(
          pledgeYear,
          cfcCode,
          applicationType
        );
      };

      $scope.goToFederationListing = function(pledgeYear, federationCfcCode) {
        RedirectService.goToFederationListing(pledgeYear, federationCfcCode);
      };

      $scope.goToFederationSearchPage = function() {
        if ($scope.memberApplicationParentCfcCode) {
          $scope.setMemberApplicationParentCfcCode(null);
        }
        $state.go('findCharityWithCFC');
      };

      $scope.goToCharitySearchPageByCfcOrEin = function() {
        if (!!$scope.memberApplicationParentCfcCode) {
          $scope.setMemberApplicationParentCfcCode(null);
        }
        if (!!$scope.myMultiSearchTerm) {
          $scope.myMultiSearchTerm = null;
        }
        $state.go('findCharityWithCFCorEIN');
      };

      $scope.startApplication = function(cfcCode) {
        ApplicationService.reset();
        FederationApplicationService.reset();
        var currentPledgeYear = sessionStorage.getItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
        );
        var prevYrOb = {};
        CharityService.getPreApplicationDetails(cfcCode, currentPledgeYear)
          .then(function(res) {
            var data = res.data.value;
            ApplicationService.setPreApplicationDetails(data);
            FederationApplicationService.setPreApplicationDetails(data);
            $scope.applicationType = data.applicationType;
            prevYrOb = data;
          })
          .catch(function(err) {
            $log.error(err);
            prevYrOb = {
              hasPrevYearApplication: false,
              applicationType: null,
              parentFederationCfcCode: null
            };
          })
          .finally(function() {
            $state.go('applicationTypeHome', { prevYearObj: prevYrOb });
          });
      };
      $scope.createApplication = function() {
        $('.modal-backdrop').remove();
        var userId = user.id;
        var campaignId = ApplicationService.getPreApplicationDetails()
          .campaignId;
        var pledgeYear = ApplicationService.getPreApplicationDetails()
          .pledgeYear;
        var cfcCode = ApplicationService.getPreApplicationDetails().cfcCode;
        $scope.setModuleName('CfcCharity.application');
        ApplicationService.setPledgeYear(pledgeYear);
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setCampaignId(campaignId);
        $scope.setAppPledgeYear(pledgeYear);
        $scope.setAppCfcCode(cfcCode);
        var application = ApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.campaignId = campaignId;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        application.createdBy = userId;
        application.createdDate = ApplicationService.toUTCDate(new Date());
        var userSession = ApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        userSession.userId = userId;
        application.$save(
          function(response) {
            userSession.applicationId = response.id;
            ApplicationService.setApplicationId(response.id);
            ApplicationService.setParentFederationApplicationId(
              response.federationId || null
            );
            var role = UserCharityRole.get(
              {
                userId: userId,
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                  new Date()
                );
                userSession.$save(
                  function(response) {
                    $state.go('step1', {
                      applicationId: ApplicationService.getApplicationId(),
                      mode: 'EDIT',
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  },
                  function(failedResponse) {
                    /*Todo: Log error*/
                  }
                );
              }
            );
          },
          function(failedResponse) {
            /*Todo: Log error*/
          }
        );
      };

      $scope.createFederationApplication = function() {
        $('.modal-backdrop').remove();
        var userId = user.id;
        var campaignId = FederationApplicationService.getPreApplicationDetails()
          .campaignId;
        var pledgeYear = FederationApplicationService.getPreApplicationDetails()
          .pledgeYear;
        var cfcCode = FederationApplicationService.getPreApplicationDetails()
          .cfcCode;
        $scope.setModuleName('CfcCharity.federation_application');
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        FederationApplicationService.setCampaignId(campaignId);
        $scope.setFederationAppPledgeYear(pledgeYear);
        $scope.setFederationAppCfcCode(cfcCode);
        var application = FederationApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.campaignId = campaignId;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        application.createdBy = userId;
        application.createdDate = FederationApplicationService.toUTCDate(
          new Date()
        );
        var userSession = FederationApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        userSession.userId = userId;
        application.$save(
          function(response) {
            userSession.applicationId = response.id;
            FederationApplicationService.setApplicationId(response.id);
            var role = UserCharityRole.get(
              {
                userId: userId,
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
                  new Date()
                );
                userSession.$save(
                  function(response) {
                    FederationApplicationService.setUserSession(response);
                    $state.go('federationStep1');
                  },
                  function(failedResponse) {
                    /*Todo: Log error*/
                  }
                );
              }
            );
          },
          function(failedResponse) {
            /*Todo: Log error*/
          }
        );
      };

      function init() {
        displaySystemMessage();
      }

      function displayBanner() {
        var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        userOb.motdAcknowledged = true;
        sessionStorage.setItem('USER_OBJ', JSON.stringify(userOb));
      }

      function displaySystemMessage() {
        motdService
          .getMOTD()
          .then(function(response) {
            var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            if (userOb.motdAcknowledged) {
              return;
            }
            if (response && response.data && response.data.messageText) {
              toastrService.info(
                response.data.messageText,
                'Message of the Day',
                {
                  timeOut: 0,
                  extendedTimeOut: 0,
                  autoDismiss: false,
                  closeButton: true,
                  positionClass: 'toast-top-full-width',
                  onHidden: displayBanner()
                }
              );
            }
          })
          .catch(function(err) {
            $log.error(
              err.data ||
                'displaySystemMessage() failed with status ' + err.status,
              err
            );
          });
      }

      init();
    }
  ])
  .config([
    '$logProvider',
    function($logProvider) {
      $logProvider.debugEnabled(false);
    }
  ])
  .controller('UserCharitiesCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'userCharitiesData',
    'DashboardService',
    'CharityService',
    'BrowserEventService',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      userCharitiesData,
      DashboardService,
      CharityService,
      BrowserEventService,
      $http
    ) {
      $scope.setModuleName('CfcCharity.userdashboard');
      var user = $scope.getCurrentUser();
      BrowserEventService.setUserId(user.id);
      $scope.userCharitiesData = userCharitiesData;
      $scope.setLeftSideNavbarMenuItemSelected('usercharities');
      $scope.isEnrollmentAvailable = false;
      CharityService.findEnrollmentStatus().then(function(res) {
        $scope.isEnrollmentAvailable = res.data;
        DashboardService.setEnrollmentAvailable($scope.isEnrollmentAvailable);
      });
      $scope.userCharityList = [];
      $scope.userCharityList = $scope.userCharityList.concat(
        $scope.userCharitiesData
      );
      $scope.userCharityListLimit = $scope.userCharitiesData.length;
      $scope.loadMoreUserCharities = function() {
        if ($scope.userCharityListLimit <= $scope.userCharitiesData.length) {
          $scope.userCharityListLimit += 100;
        }
      };
      $scope.charityDetail = function(userId, cfcCode) {
        $scope.setModuleName('CfcCharity.charitydashboard');
        BrowserEventService.setUserId(userId);
        BrowserEventService.setCfcCode(cfcCode);
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
    }
  ])
  .controller('UserApplicationsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'userApplicationsData',
    'BrowserEventService',
    'DashboardService',
    'CharityService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      userApplicationsData,
      BrowserEventService,
      DashboardService,
      CharityService
    ) {
      $scope.setModuleName('CfcCharity.userdashboard');
      var user = $scope.getCurrentUser();
      $scope.setLeftSideNavbarMenuItemSelected('userapplications');
      BrowserEventService.setUserId(user.id);
      if (
        userApplicationsData != null &&
        userApplicationsData !== 'undefined'
      ) {
        BrowserEventService.setUserApplicationDataYear(
          userApplicationsData.pledgeYear
        );
        $scope.applicationsData = userApplicationsData;
        $scope.federationCharityApplicationBeanList = [];
        $scope.federationCharityApplicationBeanList = $scope.federationCharityApplicationBeanList.concat(
          $scope.applicationsData.federationApplicationBeanList
        );
        $scope.federationCharityApplicationBeanListLimit =
          $scope.applicationsData.federationApplicationBeanList.length;
        $scope.loadMoreFederationCharityApplications = function() {
          if (
            $scope.federationCharityApplicationBeanListLimit <=
            $scope.applicationsData.federationApplicationBeanList.length
          ) {
            $scope.federationCharityApplicationBeanListLimit += 100;
          }
        };
        $scope.memberCharityApplicationBeanList = [];
        $scope.memberCharityApplicationBeanList = $scope.memberCharityApplicationBeanList.concat(
          $scope.applicationsData.memberApplicationBeanList
        );
        $scope.memberCharityApplicationBeanListLimit =
          $scope.applicationsData.memberApplicationBeanList.length;
        $scope.loadMoreMemberCharityApplications = function() {
          if (
            $scope.memberCharityApplicationBeanListLimit <=
            $scope.applicationsData.memberApplicationBeanList.length
          ) {
            $scope.memberCharityApplicationBeanListLimit += 100;
          }
        };
        $scope.independentCharityApplicationBeanList = [];
        $scope.independentCharityApplicationBeanList = $scope.independentCharityApplicationBeanList.concat(
          $scope.applicationsData.independentApplicationBeanList
        );
        $scope.independentCharityApplicationBeanListLimit =
          $scope.applicationsData.independentApplicationBeanList.length;
        $scope.loadMoreIndependentCharityApplications = function() {
          if (
            $scope.independentCharityApplicationBeanListLimit <=
            $scope.applicationsData.independentApplicationBeanList.length
          ) {
            $scope.independentCharityApplicationBeanListLimit += 100;
          }
        };
      }
      $scope.isEnrollmentAvailable = false;
      CharityService.findEnrollmentStatus().then(function(res) {
        $scope.isEnrollmentAvailable = res.data;
        DashboardService.setEnrollmentAvailable($scope.isEnrollmentAvailable);
      });
      $scope.applicationDetail = function(userId, cfcCode) {
        $scope.setModuleName('CfcCharity.applicationDashboard');
      };
    }
  ])
  .controller('UserListingsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'userListingData',
    'DashboardService',
    'CharityService',
    'BrowserEventService',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      userListingData,
      DashboardService,
      CharityService,
      BrowserEventService,
      $http
    ) {
      $scope.setModuleName('CfcCharity.userdashboard');
      $scope.userListingData = userListingData;
      $scope.charityDetail = function(userId, cfcCode) {
        $scope.setModuleName('CfcCharity.charitydashboard');
        BrowserEventService.setUserId(userId);
        BrowserEventService.setCfcCode(cfcCode);
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
      $scope.goToCharityListing = function(
        userId,
        cfcCode,
        pledgeYear,
        appType
      ) {
        BrowserEventService.setAppType(appType);
        $state.go('charitylisting', {
          charityDashboardCfcCode: cfcCode,
          pledgeYear: pledgeYear
        });
      };
      $scope.goToFederationListing = function(
        userId,
        federationCfcCode,
        pledgeYear
      ) {
        $scope.setModuleName('CfcCharity.charitydashboard');
        $state.go('federationlisting', {
          charityDashboardCfcCode: federationCfcCode,
          pledgeYear: pledgeYear
        });
      };
      var user = $scope.getCurrentUser();
      $scope.setLeftSideNavbarMenuItemSelected('userlistings');
    }
  ])
  .controller('UserDonationsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'DashboardService',
    'CharityService',
    'BrowserEventService',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      DashboardService,
      CharityService,
      BrowserEventService,
      $http
    ) {
      $scope.setModuleName('CfcCharity.userdashboard');
      var user = $scope.getCurrentUser();
      $scope.setLeftSideNavbarMenuItemSelected('userdonations');
    }
  ])
  .controller('AccessInvitationsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'DashboardService',
    'CharityService',
    'BrowserEventService',
    '$http',
    'InvitationCountService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      DashboardService,
      CharityService,
      BrowserEventService,
      $http,
      InvitationCountService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.userdashboard');
      var user = $scope.getCurrentUser();
      var userId =
        $state.params.userId !== null && $state.params.userId !== ''
          ? $state.params.userId
          : user.id;
      $scope.setLeftSideNavbarMenuItemSelected('accessinvitations');
      BrowserEventService.setUserId(user.id);
      InvitationCountService.accessInvitationsData().then(function(res) {
        $scope.accessInvitationsData = res.data;
      });
      InvitationCountService.accessRequestsData().then(function(res) {
        $scope.accessRequestsData = res.data;
      });
      var invite = {};
      $scope.acceptClicked = function(cfcCode, charityName) {
        // $('.modal-backdrop').remove();
        $scope.cfcCode = cfcCode;
        $scope.charityName = charityName;
        $scope.acceptInvite = true;
        $('#acceptInvite').modal();
      };
      $scope.declineClicked = function(cfcCode, charityName) {
        //$('.modal-backdrop').remove();
        $scope.cfcCode = cfcCode;
        $scope.charityName = charityName;
        $('#declineInvite').modal();
      };
      $scope.acceptInvitation = function() {
        $scope.accessInvitationsData = $scope.accessInvitationsData;
        invite.cfcCode = $scope.cfcCode;
        invite.charityName = $scope.charityName;
        invite.username = $scope.getCurrentUser().username;
        $('.modal-backdrop').remove();
        DashboardService.acceptedInvitation(invite)
          .then(function(res) {
            $state.reload();
            InvitationCountService.getAccessInvitationsCount().then(function(
              resp
            ) {
              $scope.setInvitationsCount(resp.data);
            });
          })
          .catch(function() {
            toastrService.error('Error while processing!');
          });
      };
      $scope.declineInvitation = function() {
        $scope.accessInvitationsData = $scope.accessInvitationsData;
        invite.cfcCode = $scope.cfcCode;
        invite.username = $scope.getCurrentUser().username;
        invite.charityName = $scope.charityName;
        $('.modal-backdrop').remove();
        DashboardService.declinedInvitation(invite)
          .then(function(res) {
            $state.reload();
            InvitationCountService.getAccessInvitationsCount().then(function(
              resp
            ) {
              $scope.setInvitationsCount(resp.data);
            });
          })
          .catch(function() {
            toastrService.error('Error while processing!');
          });
      };
      $scope.accessRequestDenied = function(accessRequestDeniedId) {
        BrowserEventService.setAccessRequestDeniedId(accessRequestDeniedId);
        $scope.displayDeclinedReason = true;
        var url =
          '/CfcCharity/api/private/users/accessrequestdenied/' +
          accessRequestDeniedId;
        $http
          .get(url)
          .then(function(res) {
            $scope.accessRequestDeniedData = res.data;
            $('#declineReason').modal();
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.requestAccessToCharity = function() {
        $('.modal-backdrop').remove();
        $state.go('requestAccessToCharity');
      };
    }
  ])
  .controller('AccessRequestsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'accessRequestsData',
    'DashboardService',
    'CharityService',
    'BrowserEventService',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      accessRequestsData,
      DashboardService,
      CharityService,
      BrowserEventService,
      $http
    ) {
      $scope.setModuleName('CfcCharity.userdashboard');
      $scope.setLeftSideNavbarMenuItemSelected('accessrequests');
      var user = $scope.getCurrentUser();
      BrowserEventService.setUserId(user.id);
      $scope.accessRequestsData = accessRequestsData;
      $scope.isEnrollmentAvailable = false;
      CharityService.findEnrollmentStatus().then(function(res) {
        $scope.isEnrollmentAvailable = res.data;
        DashboardService.setEnrollmentAvailable($scope.isEnrollmentAvailable);
      });
      $scope.displayDeclinedReason = false;
      $scope.accessRequestDenied = function(accessRequestDeniedId) {
        BrowserEventService.setAccessRequestDeniedId(accessRequestDeniedId);
        $scope.displayDeclinedReason = true;
        var url =
          '/CfcCharity/api/private/users/accessrequestdenied/' +
          accessRequestDeniedId;
        $http
          .get(url)
          .then(function(res) {
            $scope.accessRequestDeniedData = res.data;
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.accessRequestDeniedClose = function() {
        $scope.displayDeclinedReason = false;
      };
    }
  ]);
