angular
  .module('CfcCharity.editzoneuser', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('editzoneuser', {
        url: '/editzoneuser',
        views: {
          main: {
            template: require('../views/private/admin/editzoneuser.html'),
            controller: 'EditZoneUserCtrl'
          }
        },
        data: {
          pageTitle: 'Edit A Zone User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          userId: null,
          invitedUserId: null,
          zoneId: null
        },
        resolve: {
          editZoneUserData: function($http, $stateParams) {
            if (
              $stateParams.userId != null &&
              $stateParams.invitedUserId != null
            ) {
              var userId = $stateParams.userId;
              var invitedUserId = $stateParams.invitedUserId;
              var zoneId = $stateParams.zoneId;
              var url =
                '/CfcCharity/api/private/admin/zone/user/' +
                userId +
                '/' +
                invitedUserId +
                '/' +
                zoneId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('updatezoneuser', {
        url: '/updatezoneuser',
        views: {
          main: {
            template: require('../views/private/admin/zoneusers.html'),
            controller: 'ActiveZoneUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Active Zone Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          zoneId: null,
          zoneName: null,
          systemUserAdminData: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            if (
              $stateParams.systemUserAdminData != null &&
              ($stateParams.systemUserSecondLevelRoleList != null &&
                $stateParams.availableSystemUserSecondLevelRoleList != null &&
                ($stateParams.systemUserSecondLevelRoleList.indexOf(
                  $stateParams.availableSystemUserSecondLevelRoleList.gb_admin
                ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .opm_admin
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .opm_staff
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .customer_service_manager
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .zone_manager
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .gb_finance
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .gb_finance_admin
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .customer_service_tier_1
                  ) >= 0 ||
                  $stateParams.systemUserSecondLevelRoleList.indexOf(
                    $stateParams.availableSystemUserSecondLevelRoleList
                      .customer_service_tier_2_finance
                  ) >= 0))
            ) {
              return $stateParams.systemUserAdminData;
            }
          }
        }
      });
    }
  ])
  .service('UpdateSystemZoneUserService', [
    '$http',
    function($http) {
      this.updateSystemUser = function(updateSystemUserData) {
        var updateSystemUserURI =
          '/CfcCharity/api/private/admin/zone/user/update';
        return $http.post(updateSystemUserURI, updateSystemUserData);
      };
    }
  ])
  .controller('EditZoneUserCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'editZoneUserData',
    'UpdateSystemZoneUserService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      editZoneUserData,
      UpdateSystemZoneUserService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.editzoneuser');
      $scope.zoneId = BrowserEventService.getZoneId();
      $scope.zoneName = BrowserEventService.getZoneName();
      $scope.editZoneUserData = editZoneUserData;
      if (editZoneUserData.editSystemUserBean !== null) {
        $scope.updatedRole = editZoneUserData.editSystemUserBean.role;
      }
      $scope.updateZoneUser = function() {
        if (
          $scope.editZoneUserData === null ||
          $scope.editZoneUserData.editSystemUserBean === undefined ||
          $scope.editZoneUserData.editSystemUserBean.role === null ||
          $scope.editZoneUserData.editSystemUserBean.role === undefined ||
          $scope.editZoneUserData.editSystemUserBean.role === 'Select a Role'
        ) {
          $scope.showUpdateSystemUserErrorMessage('Please select a role');
          return;
        }
        if ($scope.updatedRole === editZoneUserData.editSystemUserBean.role) {
          $scope.showUpdateSystemUserErrorMessage(
            'User is already assigned to this role, Please select a different role'
          );
          return;
        }
        $scope.showUpdateSystemUserErrorMessage('');
        var updateZoneUserData = {};
        updateZoneUserData.invitingUserId =
          $scope.editZoneUserData.editSystemUserBean.invitingUserId;
        updateZoneUserData.invitedUserId =
          $scope.editZoneUserData.editSystemUserBean.invitedUserId;
        updateZoneUserData.userSystemRoleId =
          $scope.editZoneUserData.editSystemUserBean.userSystemRoleId;
        updateZoneUserData.zoneId =
          $scope.editZoneUserData.editSystemUserBean.zoneId;
        updateZoneUserData.role = $scope.updatedRole;
        updateZoneUserData.emailId =
          $scope.editZoneUserData.editSystemUserBean.emailId;
        updateZoneUserData.firstName =
          $scope.editZoneUserData.editSystemUserBean.firstName;
        updateZoneUserData.lastName =
          $scope.editZoneUserData.editSystemUserBean.lastName;
        UpdateSystemZoneUserService.updateSystemUser(updateZoneUserData)
          .then(function(res) {
            var data = res.data;
            var systemUserAdminData = data.systemUserAdminBean;
            $state.go('updatezoneuser', {
              zoneId: $scope.zoneId,
              zoneName: $scope.zoneName,
              systemUserAdminData: systemUserAdminData,
              systemUserSecondLevelRoleList:
                $scope.systemUserSecondLevelRoleList,
              availableSystemUserSecondLevelRoleList:
                $scope.availableSystemUserSecondLevelRoleList
            });
          })
          .catch(function(err) {
            $scope.showUpdateSystemUserErrorMessage(
              'Error while updating a system user. Please try again later'
            );
          });
      };
      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };
      $scope.showUpdateSystemUserErrorMessage = function(error) {
        $scope.updateZoneUserErrors = error;
      };
    }
  ]);
