(function() {
  var moduleName = 'CfcCharity.directives.charityCard';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcCharityCard', [
    'CharityService',
    '$log',
    '$q',
    'SYSTEM_USER_SECONDARY_ROLES',
    'CubeSvc',
    function(CharityService, $log, $q, SYSTEM_USER_SECONDARY_ROLES, CubeSvc) {
      return {
        restrict: 'E',
        template: require('./charityCard.html'),
        scope: {
          cfcCode: '@',
          applicationId: '@'
        },
        controller: function($scope) {
          function init() {
            // Charity Owner / Editor,
            // Federation Owner / Editor of the charity if it is a member federation at any time in the past of that federation,
            // Zone Manager/Reviewer of any Listing or Review Zone that the charity has been a member of in the past,
            // OPM Admin, OPM Staff, or Auditor
            // GB Finance, GB Finance Admin
            // Customer Service 1, 2 or Manager
            CharityService.getCharityUserRole($scope.cfcCode)
              .then(function(res) {
                return res.data && res.data.userRole
                  ? res.data.userRole.toUpperCase()
                  : null;
              })
              .then(function(role) {
                switch (role) {
                  case 'CHARITY_OWNER':
                  case SYSTEM_USER_SECONDARY_ROLES.zone_manager:
                  case SYSTEM_USER_SECONDARY_ROLES.reviewer:
                  case SYSTEM_USER_SECONDARY_ROLES.opm_admin:
                  case SYSTEM_USER_SECONDARY_ROLES.opm_staff:
                  case SYSTEM_USER_SECONDARY_ROLES.opm_auditor:
                  case SYSTEM_USER_SECONDARY_ROLES.gb_finance:
                  case SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin:
                  case SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1:
                  case SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance:
                  case SYSTEM_USER_SECONDARY_ROLES.customer_service_manager:
                  case 'CHARITY_EDITOR':
                  case SYSTEM_USER_SECONDARY_ROLES.zone_coordinator:
                    return true;
                  default:
                    return false;
                }
              })
              .then(function(isUserAuthorized) {
                if (!isUserAuthorized) {
                  isUserAuthorized = userAuthorization();
                  if (!isUserAuthorized) {
                    // is user the federation owner or editor for this member

                    CubeSvc.get(
                      '/CfcCharity/api/private/isMemberFederationOwner/' +
                        $scope.cfcCode,
                      null,
                      false
                    ).then(function(res) {
                      var isMemberFederationOwner = res;
                      $scope.userAuthorized = isMemberFederationOwner;
                      if (!isMemberFederationOwner) {
                        return;
                      }
                      getData();
                    });
                  } else {
                    $scope.userAuthorized = isUserAuthorized;
                    getData();
                  }
                } else {
                  $scope.userAuthorized = isUserAuthorized;
                  getData();
                }
              })
              .catch(function(err) {
                $log.error(err);
              });
          }

          function getData() {
            $scope.isMember = false;
            if ($scope.applicationId) {
              getApplicationInformation($scope.applicationId).then(function(
                responses
              ) {
                var totalInfo = responses[0].data.value;
                $scope.applicationInformation = Object.assign(
                  {},
                  totalInfo.charityApplicationStep2,
                  totalInfo.application
                );
                if ($scope.applicationInformation.listingPeriodNotStarted) {
                  if ($scope.applicationInformation.status === 'DENIED') {
                    $scope.applicationInformation.status = 'IN_REVIEW';
                  }
                }
                $scope.pledgeYear = $scope.applicationInformation.pledgeYear;
                $scope.isMember =
                  $scope.applicationInformation.federationCfcCode == null
                    ? false
                    : true;
                $scope.parentCfcCode =
                  $scope.applicationInformation.parentCfcCode;
                $scope.federationApplicationId =
                  $scope.applicationInformation.federationNumber;
                $scope.applicationPaymentStatus = $scope.applicationInformation
                  .applicationPaid
                  ? 'Paid'
                  : 'Unpaid';

                $scope.listAndZoneInformation = Object.assign(
                  {},
                  totalInfo.charityListingPageInfo,
                  totalInfo.charityListing,
                  totalInfo.reviewCfcZone
                );

                if (
                  totalInfo.reviewCfcZone != null ||
                  totalInfo.reviewCfcZone != undefined
                ) {
                  $scope.reviewZone =
                    totalInfo.reviewCfcZone.zoneCode +
                    ', ' +
                    totalInfo.reviewCfcZone.zoneName;
                }
                if (
                  totalInfo.listingCfcZone != null ||
                  totalInfo.listingCfcZone != undefined
                ) {
                  $scope.listingZone =
                    totalInfo.listingCfcZone.zoneCode +
                    ', ' +
                    totalInfo.listingCfcZone.zoneName;
                }

                if (totalInfo.cfcCharityMaster) {
                  $scope.generalInformation = totalInfo.cfcCharityMaster;
                  $scope.generalInformation.contactEmailAddress =
                    $scope.generalInformation.contactEmailAddress == null
                      ? []
                      : $scope.generalInformation.contactEmailAddress.split(
                          ';'
                        );
                }

                if (totalInfo.legalName) {
                  $scope.legalName =
                    totalInfo.legalName.chairtyIrsName != null
                      ? totalInfo.legalName.chairtyIrsName
                      : totalInfo.legalName.charityName;
                }

                if (!$scope.isMember) {
                  return;
                }

                if (!$scope.isMember) {
                  return;
                }
                // parent/fedreration info
                $q(function(resolv, reje) {
                  var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
                  if (userOb.secondaryRoleList.length > 0) {
                    if (
                      userOb.secondaryRoleList[0] !==
                        SYSTEM_USER_SECONDARY_ROLES.opm_admin &&
                      userOb.secondaryRoleList[0] !==
                        SYSTEM_USER_SECONDARY_ROLES.opm_auditor &&
                      userOb.secondaryRoleList[0] !==
                        SYSTEM_USER_SECONDARY_ROLES.opm_staff
                    ) {
                      CharityService.getCharityUserRole($scope.parentCfcCode)
                        .then(function(res) {
                          if (res.data != null) {
                            resolv();
                          }
                        })
                        .catch(function(err) {
                          $log.error(
                            err.data ||
                              'Request failed with status ' + err.status,
                            err
                          );
                          reje(err);
                        });
                    } else {
                      resolv();
                    }
                  } else {
                    CharityService.getCharityUserRole($scope.parentCfcCode)
                      .then(function(res) {
                        if (res.data != null) {
                          resolv();
                        }
                      })
                      .catch(function(err) {
                        $log.error(
                          err.data ||
                            'Request failed with status ' + err.status,
                          err
                        );
                        reje(err);
                      });
                  }
                }).then(function() {
                  var data = totalInfo.federationCardDetails;
                  $scope.parentInformation = data.federationCardInfoBean;
                  $scope.parentInformation.contactEmails =
                    $scope.parentInformation.contactEmails == null
                      ? []
                      : $scope.parentInformation.contactEmails.split(';');
                  $scope.parentApplicationPaymentStatus = $scope
                    .parentInformation.paymentStatus
                    ? 'Paid'
                    : 'Unpaid';
                  if ($scope.parentInformation.reviewZone) {
                    $scope.parentReviewZone =
                      $scope.parentInformation.reviewZone.zoneCode +
                      ', ' +
                      $scope.parentInformation.reviewZone.zoneName;
                  }
                  if (
                    $scope.parentInformation.listingZoneList &&
                    $scope.parentInformation.listingZoneList.length > 0
                  ) {
                    $scope.parentListingZone =
                      $scope.parentInformation.listingZoneList.length > 1
                        ? 'Multiple'
                        : $scope.parentInformation.listingZoneList[0].zoneCode +
                          ', ' +
                          $scope.parentInformation.listingZoneList[0].zoneName;
                  }
                });
              });
            } else {
              getGeneralInformation($scope.cfcCode)
                .then(function(response) {
                  $scope.generalInformation = response.data;
                  $scope.generalInformation.contactEmailAddress =
                    $scope.generalInformation.contactEmailAddress == null
                      ? []
                      : $scope.generalInformation.contactEmailAddress.split(
                          ';'
                        );

                  if (!$scope.applicationId) {
                    return;
                  }
                })
                .catch(function(err) {
                  $log.error(
                    err.data || 'Request failed with status ' + err.status,
                    err
                  );
                });
            }
          }

          function getGeneralInformation(cfcCode, pledgeYear) {
            if (pledgeYear) {
              // CCA-3226: disables toaster notification now, to be re-implemented in future sprint
              return CubeSvc.get(
                '/CfcCharity/api/private/charity/findCharityWithCFCAndYear',
                { params: { cfcCode: cfcCode, pledgeYear: pledgeYear } },
                false
              );
            } else {
              // CCA-3226: disables toaster notification now, to be re-implemented in future sprint
              return CubeSvc.get(
                '/CfcCharity/api/private/charity/findCharityWithCFC',
                { params: { cfcCode: cfcCode } },
                false
              );
            }
          }

          function getApplicationInformation(applicationId) {
            var promise1 = CubeSvc.get(
              '/CfcCharity/api/private/charity/cardInfo/' + applicationId,
              null,
              false
            );
            return $q.all([promise1]);
          }

          function userAuthorization() {
            var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            $scope.userAuthorized = false;
            if (
              !user.secondaryRoleList ||
              !Array.isArray(user.secondaryRoleList) ||
              user.secondaryRoleList.length === 0
            ) {
              return false;
            }

            // Charity Owner / Editor,
            // Federation Owner / Editor of the charity if it is a member federation at any time in the past of that federation,
            // Zone Manager/Reviewer of any Listing or Review Zone that the charity has been a member of in the past,
            // OPM Admin, OPM Staff, or Auditor
            // GB Finance, GB Finance Admin
            // Customer Service 1, 2 or Manager

            if (
              user.secondaryRoleList.indexOf('CHARITY_OWNER') >= 0 ||
              user.secondaryRoleList.indexOf('CHARITY_EDITOR') >= 0 ||
              user.secondaryRoleList.indexOf('FEDERATION_OWNER') >= 0 ||
              user.secondaryRoleList.indexOf('FEDERATION_EDITOR') >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.zone_manager
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.reviewer
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.opm_admin
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.opm_staff
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.opm_auditor
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.gb_finance
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
              ) >= 0
            ) {
              $scope.userAuthorized = true;
              return $scope.userAuthorized;
            }

            return false;
          }

          init();
        }
      };
    }
  ]);
})();
