'use strict';

angular
  .module('CfcCharity.admin.emailDashboard')
  .factory('CharityEmailDashboardService', CharityEmailDashboardService);

CharityEmailDashboardService.$inject = ['CubeSvc'];

function CharityEmailDashboardService(CubeSvc) {
  function getCharity(cfcCode) {
    var url = '/CfcCharity/api/private/charity/findCharityWithCFC';
    return CubeSvc.get(url, { params: { cfcCode: cfcCode } }, false);
  }
  function getCharityEmailsByType(cfcCode, charityEmailType) {
    var url =
      '/CfcCharity/api/private/charity/email/history/' +
      cfcCode +
      '/' +
      charityEmailType;
    return CubeSvc.get(url, {}, false);
  }
  function resendCharityEmail(id) {
    var url = '/CfcCharity/api/private/charity/email/resend';
    return CubeSvc.post(url, id);
  }
  return {
    getCharity: getCharity,
    getCharityEmailsByType: getCharityEmailsByType,
    resendCharityEmail: resendCharityEmail
  };
}
