// common.service.js - Common Service calls for the application

'use strict';

angular.module('CfcCharity.common').factory('CommonService', CommonService);
CommonService.$inject = ['CharityService', '$http'];

function CommonService(CharityService, $http) {
  var service = {
    getListingPeriodStatus: getListingPeriodStatus,
    getServiceStatus: getServiceStatus
  };
  return service;

  // Lookup the listing status based only on the current date and period dates
  function getListingPeriodStatus() {
    return $http.get('/CfcCharity/api/private/charity/getListingPeriodStatus');
  }

  // Return a status check if the BE is available
  function getServiceStatus() {
    return $http.get('/CfcCharity/api/public/home/servicestatus');
  }

  function calculateAfr(mgmtExpense, fundraisingExpense, revenue) {
    var url =
      '/CfcCharity/api/private/application/step9/calculateAfr?mgmtExpense=' +
      mgmtExpense +
      '&fundraisingExpense=' +
      fundraisingExpense +
      '&revenue=' +
      revenue;
    return $http.get(url);
  }
}
