angular
  .module('CfcCharity.federation_application', [
    'ui.router',
    'CfcCharity.security',
    'CfcCharity.common',
    'ngResource'
  ])
  .constant('DATES', {
    START_DATE: '01/01/',
    FISCAL_START_DATE: '06/30/',
    END_DATE: '12/31/'
  })
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'USER_ROLES',
    function myAppConfig($stateProvider, $urlRouterProvider, USER_ROLES) {
      $stateProvider
        .state('federationStep1', {
          url: '/federationStep1',
          views: {
            main: {
              template: require('../views/private/federation/step1.html'),
              controller: 'FederationApplicationStep1Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step1 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep2', {
          url: '/federationStep2',
          views: {
            main: {
              template: require('../views/private/federation/step2.html'),
              controller: 'FederationApplicationStep2Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step2 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep3', {
          url: '/federationStep3',
          views: {
            main: {
              template: require('../views/private/federation/step3.html'),
              controller: 'FederationApplicationStep3Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step3 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep4', {
          url: '/federationStep4/:fromPage',
          views: {
            main: {
              template: require('../views/private/federation/step4.html'),
              controller: 'FederationApplicationStep4Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step4 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService,
              CubeSvc
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                CubeSvc.setManualCacheRelease();
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep5', {
          url: '/federationStep5',
          views: {
            main: {
              template: require('../views/private/federation/step5.html'),
              controller: 'FederationApplicationStep5Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step5 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep6', {
          url: '/federationStep6',
          views: {
            main: {
              template: require('../views/private/federation/step6.html'),
              controller: 'FederationApplicationStep6Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step6 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep7', {
          url: '/federationStep7',
          views: {
            main: {
              template: require('../views/private/federation/step7.html'),
              controller: 'FederationApplicationStep7Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step7 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep8', {
          url: '/federationStep8',
          views: {
            main: {
              template: require('../views/private/federation/step8.html'),
              controller: 'FederationApplicationStep8Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step8 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep9', {
          url: '/federationStep9',
          views: {
            main: {
              template: require('../views/private/federation/step9.html'),
              controller: 'FederationApplicationStep9Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step9 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationStep10', {
          url: '/federationStep10',
          views: {
            main: {
              template: require('../views/private/federation/step10.html'),
              controller: 'FederationApplicationStep10Ctrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              },
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application Step10 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('fedAllSteps', {
          url: '/fedAllSteps',
          views: {
            main: {
              template: require('../views/private/federation/fedAllSteps.html'),
              controller: 'FederationApplicationAllStepsCtrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: 'Federation Application Dashboard',
                crumbRoute: 'applicationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Application All Steps Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              var appId =
                $stateParams.applicationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                BreadcrumbTokenDataService.setIsFederation(true);
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('federationReview', {
          url: '/federationReview/:applicationId/:mode',
          views: {
            main: {
              template: require('../views/private/federation/federationReview.html'),
              controller: 'FederationApplicationReviewCtrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: 'Admin Home',
                crumbRoute: 'administrationcenter',
                crumbParams: '{userId: %userId%}'
              },
              {
                crumbName: 'Zone %zoneCode%',
                crumbRoute: 'zoneCenter',
                crumbParams: '{zoneId: %zoneId%}'
              },
              {
                crumbName: '%zoneCode% Applications',
                crumbRoute: 'zoneApplications',
                crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
              },
              {
                crumbName: 'reviewLevel',
                crumbRoute: '',
                crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
              },
              {
                crumbName: 'Charity %cfcCode% Review Dashboard',
                crumbRoute: 'charityAuditDashboard',
                crumbParams: '{federationApplicationId: %applicationId%}'
              },
              { crumbName: 'Review', crumbRoute: '', crumbParams: '' }
            ],
            pageTitle: 'Charity Federation Application Review Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              if (FederationApplicationService.getApplicationId() != null) {
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  FederationApplicationService.getApplicationId()
                );
              }
            }
          }
        });
    }
  ])
  .controller('FederationApplicationStep1Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'ValidationService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    '$log',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      ValidationService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      $log,
      urlAccess
    ) {
      var step1AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      // if params.applicationId fetch application for the first time
      // App created by [Only Federation or Federation & Members] modal
      if ($state.params.applicationId) {
        FederationApplicationService.editFederationApplication(
          $state.params.applicationId
        );
      }
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      $scope.saveExit = false;
      $scope.setFederationAppStep(1);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();

      FederationApplicationService.getStates(function(data) {
        $scope.states = data;
      });

      if (!$scope.applicationStep1) {
        $scope.applicationStep1 = FederationApplicationService.getApplicationStep1();
        if (angular.equals($scope.applicationStep1, {})) {
          $scope.applicationStep1.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep1.cfcCode = FederationApplicationService.getCfcCode();
          if (
            null == $scope.applicationStep1.cfcCode &&
            !!$state.params.cfcCode
          ) {
            $scope.applicationStep1.cfcCode = $state.params.cfcCode;
          }
          $scope.applicationStep1.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep1 = res;
              } else {
                $scope.applicationStep1.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep1.cfcCode = FederationApplicationService.getCfcCode();
              }
            },
            function() {
              $log.error('STEP1 failed to load');
            }
          );
        }
      }
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step1AccessBeginDate = step1AccessBeginDate;
        FederationApplicationService.getUserSession().step1AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step1AccessBeginDate
        );
        FederationApplicationService.getUserSession().step1TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        if (!$scope.applicationStep1.lastAccessedBeginDate) {
          $scope.applicationStep1.lastAccessedBeginDate = currentTime;
        }
        if (
          !FederationApplicationService.getUserSession().lastAccessedBeginDate
        ) {
          FederationApplicationService.getUserSession().lastAccessedBeginDate = currentTime;
        }
        if (!FederationApplicationService.getUserSession().cfcCode) {
          FederationApplicationService.getUserSession().cfcCode =
            $scope.cfcCode;
        }
        FederationApplicationService.saveStep($scope.applicationStep1, function(
          response
        ) {
          FederationApplicationService.saveTable(
            FederationApplicationService.getUserSession(),
            function(response) {
              var overallStatus = 'Complete';
              FederationApplicationService.getFederationStatus(
                FederationApplicationService.getApplicationId(),
                function(data) {
                  for (var i = 0; i < data.length; i++) {
                    if (data[i].stepStatus === 'Incomplete') {
                      overallStatus = 'Incomplete';
                      break;
                    }
                  }
                  var app = FederationApplicationService.getApplication();
                  app.overallApplicationStatus = overallStatus;
                  FederationApplicationService.saveTable(
                    app,

                    function(response) {
                      if (exit) {
                        $scope.setFederationAppStep(0);
                        // Start - code added for new sprint
                        //  $state.go('federationReview');
                        var applicationId = FederationApplicationService.getApplicationId();
                        FederationApplicationService.reset();
                        $state.go('federationDashboard', {
                          federationId: applicationId
                        }); // $state.go('federationDashboard', {'federationId' : $scope.applicationStep1.applicationId});
                        // End - code added for new sprint
                      } else {
                        $('.modal-backdrop').remove();
                        $state.go('federationStep2');
                      }
                    }
                  );
                }
              );
            }
          );
        });
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.missingSecondEmail = false;
        var missingEmail = false;
        if (!$scope.applicationStep1.city) {
          $scope.fieldsMissing.push('City is not filled in');
        }
        if (!$scope.applicationStep1.contactAddress1) {
          $scope.fieldsMissing.push('Contact Address1 is not filled in');
        }
        if (!$scope.applicationStep1.contactEmailAddress) {
          missingEmail = true;
          $scope.fieldsMissing.push('Contact Email is not filled in');
        }

        if ($scope.applicationStep1.contactEmailAddress) {
          var p = $scope.applicationStep1.contactEmailAddress.split(';');
          if (p.length < 2) {
            $scope.fieldsMissing.push(
              'You have only provided one email address.  To ensure the CFC can contact you about important information related to the campaign or your application, we highly recommend you supply multiple points of contact. Your multiple points of contact can include both personal and work email addresses.'
            );
            $scope.missingSecondEmail = true;
          } else if (
            p.length === 2 &&
            (p[0] === '' ||
              p[1] === '' ||
              p[0] === null ||
              p[1] === null ||
              angular.isUndefined(p[0]) ||
              angular.isUndefined(p[1]))
          ) {
            $scope.fieldsMissing.push(
              'You have only provided one email address.  To ensure the CFC can contact you about important information related to the campaign or your application, we highly recommend you supply multiple points of contact. Your multiple points of contact can include both personal and work email addresses.'
            );
            $scope.missingSecondEmail = true;
          }
        }

        if (!$scope.applicationStep1.contactPerson) {
          $scope.fieldsMissing.push('Contact Person is not filled in');
        }
        if (!$scope.applicationStep1.contactPhoneNumber) {
          $scope.fieldsMissing.push('Contact Phone is not filled in');
        }
        if (!$scope.applicationStep1.contactTitle) {
          $scope.fieldsMissing.push('Contact Title is not filled in');
        }
        if (!$scope.applicationStep1.state) {
          $scope.fieldsMissing.push('Contact State is not filled in');
        }
        if (!$scope.applicationStep1.zip) {
          $scope.fieldsMissing.push('Contact Zip is not filled in');
        }
        var validateContactPerson = ValidationService.validateAlphaNumericPunctuation(
          'Contact Person',
          $scope.applicationStep1.contactPerson
        );
        if (validateContactPerson) {
          $scope.fieldsMissing.push(validateContactPerson);
        }
        var validateEmail = ValidationService.validateMultipleEmails(
          'Contact Email',
          $scope.applicationStep1.contactEmailAddress
        );

        $scope.contactEmailAddressesFailedValidation =
          !!validateEmail || missingEmail;

        if (validateEmail) {
          $scope.fieldsMissing.unshift(validateEmail);
        }
        if (
          ValidationService.validatePhoneNumber(
            'Contact Phone',
            $scope.applicationStep1.contactPhoneNumber
          )
        ) {
          $scope.fieldsMissing.push(
            ValidationService.validatePhoneNumber(
              'Contact Phone',
              $scope.applicationStep1.contactPhoneNumb
            )
          );
        }
        if (
          ValidationService.validateZip(
            'Contact Zip',
            $scope.applicationStep1.zip
          )
        ) {
          $scope.fieldsMissing.push(
            ValidationService.validateZip(
              'Contact Zip',
              $scope.applicationStep1.zip
            )
          );
        }
        var validateWebsite = ValidationService.validateWebsiteAddress(
          'Website Address',
          $scope.applicationStep1.websiteAddress
        );
        if (validateWebsite) {
          $scope.fieldsMissing.push(validateWebsite);
        }
        if (
          ValidationService.validatePhoneNumber(
            'Contact Fax',
            $scope.applicationStep1.contactFaxNumber
          )
        ) {
          $scope.fieldsMissing.push(
            ValidationService.validatePhoneNumber(
              'Contact Fax',
              $scope.applicationStep1.contactFaxNumber
            )
          );
        }
        if ($scope.fieldsMissing.length > 0) {
          if ($scope.fieldsMissing.length === 1 && $scope.missingSecondEmail) {
            $scope.applicationStep1.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else {
            $scope.applicationStep1.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          }
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep1.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $('#missingInfo').on('show.bs.modal', function(e) {
        $scope.goBack = false;
      });
      $('#missingInfo').on('hidden.bs.modal', function(e) {
        if ($scope.goBack) {
          return;
        }
        $scope.saveApplicationStep($scope.saveExit);
      });
      $('.modal-backdrop').remove();
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('FederationApplicationStep2Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'CHARITY_TYPE',
    'APPLICATION_TYPE',
    'APPLICATION_STEP_STATUS',
    'Step2CharitySearch',
    'FederationApplicationService',
    'BrowserEventService',
    'CharityService',
    'tokenData',
    '$log',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      CHARITY_TYPE,
      APPLICATION_TYPE,
      APPLICATION_STEP_STATUS,
      Step2CharitySearch,
      FederationApplicationService,
      BrowserEventService,
      CharityService,
      tokenData,
      $log,
      urlAccess
    ) {
      var step2AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );

      $scope.setFederationAppStep(2);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();

      if (!$state.params.fromPage) {
        FederationApplicationService.getUserSession().step2AccessBeginDate = step2AccessBeginDate;
      }

      if (!$scope.applicationStep2) {
        $scope.applicationStep2 = FederationApplicationService.getApplicationStep2();
        if (angular.equals($scope.applicationStep2, {})) {
          $scope.applicationStep2.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep2.cfcCode = FederationApplicationService.getCfcCode();

          if (
            null == $scope.applicationStep2.cfcCode &&
            !!FederationApplicationService.getUserSession().cfcCode
          ) {
            $scope.applicationStep2.cfcCode = FederationApplicationService.getUserSession().cfcCode;
          }
          $scope.applicationStep2.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep2 = res;
                if (
                  $scope.applicationStep2.noOfMembers &&
                  $scope.applicationStep2.charityType
                ) {
                  if (
                    $scope.applicationStep2.charityType === CHARITY_TYPE.local
                  ) {
                    $scope.noOfMembersL = $scope.applicationStep2.noOfMembers;
                  } else if (
                    $scope.applicationStep2.charityType ===
                    CHARITY_TYPE.national
                  ) {
                    $scope.noOfMembersN = $scope.applicationStep2.noOfMembers;
                  } else if (
                    $scope.applicationStep2.charityType ===
                    CHARITY_TYPE.international
                  ) {
                    $scope.noOfMembersI = $scope.applicationStep2.noOfMembers;
                  }
                }
              } else {
                $scope.applicationStep2.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep2.cfcCode = FederationApplicationService.getCfcCode();
              }
            },
            function() {
              $log.error('STEP2 failed to load');
            }
          );
        }
      }
      $scope.clearNoOfMembers = function(obj) {
        if (obj.target.value === CHARITY_TYPE.local) {
          $scope.noOfMembersN = null;
          $scope.noOfMembersI = null;
        } else if (obj.target.value === CHARITY_TYPE.national) {
          $scope.noOfMembersL = null;
          $scope.noOfMembersI = null;
        } else if (obj.target.value === CHARITY_TYPE.international) {
          $scope.noOfMembersN = null;
          $scope.noOfMembersL = null;
        }
      };
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step2AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step2AccessBeginDate
        );
        FederationApplicationService.getUserSession().step2TimeSpentInSeconds = dateDifferenceInSeconds;
        $scope.applicationStep2.applicationType = APPLICATION_TYPE.federation;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        FederationApplicationService.saveStep($scope.applicationStep2, function(
          response
        ) {
          FederationApplicationService.saveTable(
            FederationApplicationService.getUserSession(),
            function(response) {
              var overallStatus = 'Complete';
              FederationApplicationService.getFederationStatus(
                FederationApplicationService.getApplicationId(),
                function(data) {
                  for (var i = 0; i < data.length; i++) {
                    if (data[i].stepStatus === 'Incomplete') {
                      overallStatus = 'Incomplete';
                      break;
                    }
                  }
                  var app = FederationApplicationService.getApplication();
                  app.overallApplicationStatus = overallStatus;
                  FederationApplicationService.saveTable(app, function(
                    response
                  ) {
                    if (exit) {
                      $scope.setFederationAppStep(0);
                      // Start - code added for new sprint
                      //  $state.go('federationReview');
                      var applicationId = FederationApplicationService.getApplicationId();
                      FederationApplicationService.reset();
                      $state.go('federationDashboard', {
                        federationId: applicationId
                      }); // End - code added for new sprint
                    } else {
                      $('.modal-backdrop').remove();
                      $state.go('federationStep3');
                    }
                  });
                }
              );
            }
          );
        });
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        if (!$scope.applicationStep2.charityType) {
          $scope.fieldsMissing.push('Choose federation type.');
        } else {
          if ($scope.applicationStep2.charityType === CHARITY_TYPE.local) {
            $scope.applicationStep2.noOfMembers = $scope.noOfMembersL;
          } else if (
            $scope.applicationStep2.charityType === CHARITY_TYPE.national
          ) {
            $scope.applicationStep2.noOfMembers = $scope.noOfMembersN;
          } else if (
            $scope.applicationStep2.charityType === CHARITY_TYPE.international
          ) {
            $scope.applicationStep2.noOfMembers = $scope.noOfMembersI;
          }
          if (
            !$scope.applicationStep2.noOfMembers ||
            $scope.applicationStep2.noOfMembers < 15
          ) {
            $scope.fieldsMissing.push(
              'Your organization does not have at least 15 member organizations that individually meet CFC eligibility criteria. We believe you should submit an application as an Independent or Member organization.'
            );
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep2.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep2.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('FederationApplicationStep3Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'FederationDashboardService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'DownloadService',
    'tokenData',
    '$log',
    'urlAccess',
    'PLEDGE_YEARS',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      FederationDashboardService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      DownloadService,
      tokenData,
      $log,
      urlAccess,
      PLEDGE_YEARS
    ) {
      var step3AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      $scope.setFederationAppStep(3);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();
      $scope.displayFor2023AndBeyond = false;
      if (
        $scope.pledgeYear &&
        $scope.pledgeYear >= PLEDGE_YEARS.PLEDGE_YEAR_2023
      ) {
        $scope.displayFor2023AndBeyond = true;
      }

      if (!$scope.applicationStep3) {
        $scope.applicationStep3 = FederationApplicationService.getApplicationStep3();
        if (angular.equals($scope.applicationStep3, {})) {
          $scope.applicationStep3.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep3.cfcCode = FederationApplicationService.getCfcCode();
          $scope.applicationStep3.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep3 = res;
              } else {
                $scope.applicationStep3.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep3.cfcCode = FederationApplicationService.getCfcCode();
              }
            },
            function() {
              $log.error('STEP3 failed to load');
            }
          );
        }
      }
      if (
        null == $scope.applicationStep3.cfcCode &&
        !!FederationApplicationService.getUserSession().cfcCode
      ) {
        $scope.applicationStep3.cfcCode = FederationApplicationService.getUserSession().cfcCode;
      }
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step3AccessBeginDate = step3AccessBeginDate;
        FederationApplicationService.getUserSession().step3AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step3AccessBeginDate
        );
        FederationApplicationService.getUserSession().step3TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        FederationApplicationService.saveFileStep3(
          $scope.applicationStep3,
          function(response) {
            if (
              response.message &&
              response.message.type === 'ERROR' &&
              !$scope.continue
            ) {
              $('#modalUpload').modal();
              return;
            }

            // this handles exception with a response
            if ((!response || response.errorCode) && !$scope.continue) {
              $('#modalUpload').modal();
              return;
            }
            FederationApplicationService.saveTable(
              FederationApplicationService.getUserSession(),
              function(response) {
                FederationApplicationService.saveTable(
                  FederationApplicationService.getApplication(),
                  function(response) {
                    if (exit) {
                      $scope.setFederationAppStep(0);
                      // Start - code added for new sprint
                      //  $state.go('federationReview');
                      var applicationId = FederationApplicationService.getApplicationId();
                      FederationApplicationService.reset();
                      $state.go('federationDashboard', {
                        federationId: applicationId
                      }); // End - code added for new sprint
                    } else {
                      $('.modal-backdrop').remove();
                      $state.go('federationStep4');
                    }
                  }
                );
              }
            );
          }
        );
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.applicationStep3.stepStatus = APPLICATION_STEP_STATUS.complete;
        if (exit) {
          $scope.saveApplicationStep(exit);
        } else {
          $scope.saveApplicationStep();
        }
      };
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('FederationApplicationStep4Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'CHARITY_TYPE',
    'APPLICATION_STEP_STATUS',
    '$stateParams',
    'FederationApplicationService',
    'FederationDashboardService',
    'BrowserEventService',
    'CharityDashboardService',
    'ListingOverrideAfterCloseService',
    'DownloadService',
    'blockUI',
    '$http',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    '$log',
    'urlAccess',
    '$rootScope',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      CHARITY_TYPE,
      APPLICATION_STEP_STATUS,
      $stateParams,
      FederationApplicationService,
      FederationDashboardService,
      BrowserEventService,
      CharityDashboardService,
      ListingOverrideAfterCloseService,
      DownloadService,
      blockUI,
      $http,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      $log,
      urlAccess,
      $rootScope,
      CubeSvc
    ) {
      var step4AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );

      $scope.setFederationAppStep(4);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      var user = $scope.getCurrentUser();
      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;

      $scope.selectedPledgeYearObject = null;
      $scope.allowEditListingInfo = true;
      $scope.secondaryRoleList = secondaryRoleList;
      $scope.charityInformation = null;
      $scope.charityDbaAuthLetters = null;
      $scope.charityListingPageInfo = null;
      $scope.charityListingObject = null;
      $scope.charityListingViewObject = null;
      $scope.dbaletter = {};
      $scope.showListingPeriodErrorMessage = false;
      $scope.campaignId = null;
      $scope.listingPeriodEndedMessage = '';
      $scope.charityListingSuccessMsg = '';
      $scope.canEditDbaInfo = false;
      $scope.isApplicationPeriodOpen = false;
      $scope.showIrsCharityStatus = false;

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();
      $scope.charity_type = FederationApplicationService.getApplicationStep2().charityType;

      if (!$stateParams.fromPage) {
        FederationApplicationService.getUserSession().step4AccessBeginDate = step4AccessBeginDate;
      }
      CubeSvc.performManualCacheRelease();

      CharityService.getPreApplicationDetails(
        $scope.cfcCode,
        $scope.pledgeYear
      ).then(function(res) {
        var data = res.data.value;
        $scope.stepApplicable = !(data.charityEin && data.charityEin !== '0');
        FederationApplicationService.setPreApplicationDetails(data);
        $scope.applicationStep4 = FederationApplicationService.getApplicationStep4();
        if (angular.equals($scope.applicationStep4, {})) {
          $scope.applicationStep4.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep4.cfcCode = FederationApplicationService.getCfcCode();
          $scope.applicationStep4.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep4 = res;
              } else {
                $scope.applicationStep4.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep4.cfcCode = FederationApplicationService.getCfcCode();
              }
              $scope.federation_irs_determination_s3_link =
                $scope.applicationStep4.s3Link;
            },
            function() {
              $log.error('STEP4 failed to load');
            }
          );
        }
      });

      if (
        user != null &&
        user.secondaryRoleList != null &&
        user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 &&
        !$scope.isListingEditable
      ) {
        $scope.allowEditListingInfo = false;
      }

      var loadCharityListingPageInfo = function() {
        if ($scope.pledgeYear && $scope.cfcCode) {
          if (
            user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
            user.secondaryRoleList.indexOf('REVIEWER') < 0
          ) {
            CharityDashboardService.getCharityListingPageInfo(
              $scope.pledgeYear,
              $scope.cfcCode
            ).then(function(res) {
              var result = res.data.value;
              if (result !== null && result !== '') {
                $scope.charityListingPageInfo = result;

                $scope.charityListingViewPageInfo = angular.copy(
                  $scope.charityListingPageInfo
                );
                if (
                  $scope.charityListingPageInfo &&
                  $scope.charityListingPageInfo.dashboardListingList
                ) {
                  CharityDashboardService.getCharityDbaAuthLetters(
                    $scope.pledgeYear,
                    $scope.cfcCode,
                    $scope.charityListingPageInfo.id
                  ).then(function(resp) {
                    if (resp.data.value != null) {
                      $scope.charityDbaAuthLetters = resp.data.value;
                      if (resp.data.value[0] !== undefined) {
                        $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                      }
                    }
                  });
                  angular.forEach(
                    $scope.charityListingPageInfo.dashboardListingList,
                    function(obj) {
                      if ($scope.pledgeYear === obj.pledgeYear) {
                        $scope.selectedPledgeYearObject = obj;
                        $scope.pledgeYearId = obj.pledgeYearId;
                      }
                    }
                  );
                }
              }
            });
          }
        }

        /* Get IRS Charity Status */
        CharityService.findCharityWithCFCLimited($scope.cfcCode).then(function(
          resp
        ) {
          var result = resp.data;
          if (result !== null && result !== '') {
            $scope.charityStatus = result.charityStatus;
          }
        });
      };

      function checkIfEnrollmentIsOpen() {
        CharityService.findEnrollmentStatus().then(function(res) {
          $scope.isApplicationPeriodOpen = res.data;
        });
      }

      /* populate listing information */
      CharityDashboardService.findCharityWithCFC($scope.cfcCode).then(function(
        res
      ) {
        var result = res.data;
        if (result !== null && result !== '') {
          $scope.charityInformation = result;
        }
      });

      loadCharityListingPageInfo();
      checkIfEnrollmentIsOpen();

      if ($scope.pledgeYear && $scope.cfcCode) {
        if (
          user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
          user.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          CharityDashboardService.getCharityListingPageInfo(
            $scope.pledgeYear,
            $scope.cfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingPageInfo = result;

              if (
                $scope.charityListingPageInfo &&
                $scope.charityListingPageInfo.dashboardListingList
              ) {
                CharityDashboardService.getCharityDbaAuthLetters(
                  $scope.pledgeYear,
                  $scope.cfcCode,
                  $scope.charityListingPageInfo.id
                ).then(function(resp) {
                  if (resp.data.value != null) {
                    $scope.charityDbaAuthLetters = resp.data.value;
                    if (resp.data.value[0] !== undefined) {
                      $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                    }
                  }
                });
                angular.forEach(
                  $scope.charityListingPageInfo.dashboardListingList,
                  function(obj) {
                    if ($scope.pledgeYear === obj.pledgeYear) {
                      $scope.selectedPledgeYearObject = obj;
                      $scope.pledgeYearId = obj.pledgeYearId;
                    }
                  }
                );
              }
            }
          });
        }
      }

      if ($scope.pledgeYear && $scope.cfcCode) {
        // Show IRS Charity Status for all roles except Charity Owners/Editors
        if (user.secondaryRoleList != null && user.secondaryRoleList != '') {
          $scope.showIrsCharityStatus = true;
        }
        if (
          user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
          user.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          CharityDashboardService.getCharityListingObject(
            $scope.pledgeYear,
            $scope.cfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;

              $scope.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
              $scope.campaignId = result.campaignId;

              CharityService.getCharityUserRole($scope.cfcCode).then(function(
                res
              ) {
                var result = res.data;
                if (result !== null && result !== '') {
                  if (
                    result.userRole === 'CHARITY_OWNER' ||
                    result.userRole === 'CHARITY_EDITOR' ||
                    result.userRole === 'FEDERATION_OWNER' ||
                    result.userRole === 'FEDERATION_EDITOR'
                  ) {
                    $scope.canEditDbaInfo = true;
                  }
                }
              });

              loadListingEditableInfo();
            }
          });
        }
      }

      var loadListingEditableInfo = function() {
        ListingOverrideAfterCloseService.isListingAvailableWithOverride(
          $scope.campaignId,
          $scope.cfcCode
        ).then(function(res) {
          var response = res.data;
          $scope.isListingEditable = response;
        });
      };

      $scope.loadEditObject = function() {
        $scope.charityListingObject = angular.copy(
          $scope.charityListingViewObject
        );
      };

      /**
       * Reusable method to Show success message on the screen.
       */
      $scope.setSuccessMessage = function(message) {
        $scope.listingPeriodEndedMessage = '';
        $scope.charityListingSuccessMsg = message;
      };
      /**
       * Reusable method to Show error message on the screen
       */
      $scope.setErrorMessage = function(message) {
        $scope.listingPeriodEndedMessage = message;
        $scope.charityListingSuccessMsg = '';
      };

      var updateListingObject = function() {
        if (
          user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
          user.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          CharityDashboardService.getCharityListingObject(
            $scope.pledgeYear,
            $scope.cfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;

              $scope.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
            }
          });
        }
      };

      $scope.updateDBAName = function(charityListing) {
        if (charityListing) {
          CharityDashboardService.updateDBAName(
            charityListing.cfcCode,
            charityListing.campaignId,
            charityListing.charityDBAName,
            $scope.charityInformation.charityIrsName
          ).then(function(res) {
            var result = res.data.value;
            $('#editDBANameCancelId').click();
            updateListingObject();
            if (
              user.secondaryRoleList &&
              Array.isArray(user.secondaryRoleList)
            ) {
              if (
                user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
                user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
                user.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
              ) {
                $scope.$broadcast('reloadCfcAdminReview');
              }
            }
            checkIfEnrollmentIsOpen();
            $rootScope.$broadcast('editDbaName', res.data);
          });
        }
      };

      // Handle upload of authorized DBA file.
      $scope.uploadAuthorizedDBA = function() {
        blockUI.start();
        $scope.errorMsg = '';
        $scope.fileUploadStatusMessage = '';
        if (event.target.files.length > 0) {
          if (
            typeof event.target.files[0].type !== 'undefined' &&
            event.target.files[0].type !== null &&
            event.target.files[0].type !== 'application/pdf'
          ) {
            $scope.errorMsg = 'File upload must be PDF format.';
            blockUI.stop();
          } else {
            var fd = new FormData();
            for (i = 0; i < event.target.files.length; i++) {
              fd.append('supportingFile', event.target.files[i]);
            }
            var uploadUrl =
              '/CfcCharity/api/private/charitylisting/' +
              $scope.cfcCode +
              '/' +
              $scope.campaignId +
              '/uploadDBA';
            CubeSvc.post(
              uploadUrl,
              fd,
              {
                transformRequest: angular.identity,
                headers: {
                  'Content-Type': undefined,
                  'X-AUTH-TOKEN': $scope.xAuthToken
                }
              },
              false
            )
              .then(function(res) {
                var data = res.data.value;
                if (
                  data &&
                  data.statusCode !== undefined &&
                  data.statusCode === 'ERROR'
                ) {
                  $scope.fileUploadStatusMessage = data.message;
                  $('#fileUploadStatusMessage').modal();
                  $('#DBAauthorization').val('');
                } else if (data !== null && data !== '') {
                  $scope.fileUploadStatusMessage =
                    'File uploaded successfully.';
                  $('#fileUploadStatusMessage').modal();
                  $scope.charityListingPageInfo.charityDBAAuthUploaded = true;
                  $scope.listingPeriodEndedMessage = '';
                  $scope.charityListingObject = data;
                  if (
                    $scope.charityListingPageInfo.paymentStatus === 'Unpaid' &&
                    $scope.charityListingPageInfo.applicationType ===
                      'Independent' &&
                    $scope.charityListingPageInfo.applicationStatus ===
                      'APPROVED'
                  ) {
                    $scope.charityListingPageInfo.showPayListingFee = true;
                  }
                  $scope.charityListingViewObject = angular.copy(
                    $scope.charityListingObject
                  );
                  CharityDashboardService.getCharityDbaAuthLetters(
                    $scope.pledgeYear,
                    $scope.cfcCode,
                    $scope.charityListingPageInfo.id
                  ).then(function(resp) {
                    if (resp.data.value != null) {
                      $scope.charityDbaAuthLetters = resp.data.value;
                      if (resp.data.value[0] !== undefined) {
                        $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                      }
                    }
                  });
                }
              })
              .catch(function(err) {
                if (!$scope.continue) {
                  $('#DBAauthorization').val('');
                  $('#modalUpload').modal();
                  return;
                }
              });
            blockUI.stop();
          }
        }
        blockUI.stop();
      };

      // Handle deletion of authorized DBA file.
      $scope.deleteDbaAuthLetter = function(dbaAuthLetterId) {
        var url =
          '/CfcCharity/api/public/delete/charity/dbaAuth/' +
          $scope.cfcCode +
          '/' +
          $scope.charityListingPageInfo.campaignId +
          '/' +
          dbaAuthLetterId;
        $http({
          method: 'POST',
          url: url,
          data: {
            cfcCode: $scope.cfcCode,
            campaignId: $scope.charityListingPageInfo.campaignId,
            dbaAuthLetterId: dbaAuthLetterId
          }
        })
          .then(function(res) {
            var result = res.data;
            if (result) {
              CharityDashboardService.getCharityDbaAuthLetters(
                $scope.pledgeYear,
                $scope.cfcCode,
                $scope.charityListingPageInfo.id
              ).then(function(resp) {
                if (resp.data.value != null) {
                  $scope.charityDbaAuthLetters = resp.data.value;
                  if (resp.data.value[0] !== undefined) {
                    $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                  }
                }
              });
            }
          })
          .catch(function(err) {});
      };
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step4AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step4AccessBeginDate
        );
        FederationApplicationService.getUserSession().step4TimeSpentInSeconds = dateDifferenceInSeconds;

        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        if (!$scope.applicationStep4.certifyIrsDetermination) {
          $scope.applicationStep4.certifyIrsDetermination = false;
        }
        FederationApplicationService.saveFileStep4(
          $scope.applicationStep4,
          function(response) {
            if (
              response.message &&
              response.message.type === 'ERROR' &&
              !$scope.continue
            ) {
              $('input[type="file"]').val(null);
              $('#missingInfo').modal('hide');
              $scope.applicationStep4.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }
            if (
              response.message &&
              response.message.type === 'ERROR' &&
              !$scope.continue
            ) {
              $('input[type="file"]').val(null);
              $scope.applicationStep4.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }

            if ((!response || response.errorCode) && !$scope.continue) {
              $scope.applicationStep4.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }
            $scope.applicationStep4.fileName = response.fileName;
            FederationApplicationService.saveTable(
              FederationApplicationService.getUserSession(),
              function(response) {
                var overallStatus = 'Complete';
                FederationApplicationService.getFederationStatus(
                  FederationApplicationService.getApplicationId(),
                  function(data) {
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].stepStatus === 'Incomplete') {
                        overallStatus = 'Incomplete';
                        break;
                      }
                    }
                    var app = FederationApplicationService.getApplication();
                    app.overallApplicationStatus = overallStatus;
                    FederationApplicationService.saveTable(app, function(
                      response
                    ) {
                      if (exit) {
                        $scope.setFederationAppStep(0);
                        // Start - code added for new sprint
                        //  $state.go('federationReview');
                        var applicationId = FederationApplicationService.getApplicationId();
                        FederationApplicationService.reset();
                        $state.go('federationDashboard', {
                          federationId: applicationId
                        }); // End - code added for new sprint
                      } else {
                        $('.modal-backdrop').remove();
                        $state.go('federationStep5');
                      }
                    });
                  }
                );
              }
            );
          }
        );
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if ($scope.stepApplicable) {
          if (
            !$scope.applicationStep4.supportingFile &&
            !$scope.applicationStep4.fileName
          ) {
            $scope.fieldsMissing.push('IRS determination letter');
          } else {
            if (
              $scope.applicationStep4.supportingFile &&
              $scope.applicationStep4.supportingFile.type !== 'application/pdf'
            ) {
              $scope.fieldsMissing.push(
                'IRS determination letter: Only PDF file is allowed.'
              );
              $scope.fileError = true;
            } else {
              $scope.fileError = false;
            }
          }
          if (!$scope.applicationStep4.certifyIrsDetermination) {
            $scope.fieldsMissing.push('Please check the YES! checkbox');
          }
          if (
            $scope.charityListingViewObject.dbanameChangeStatus === 'Pending' &&
            ($scope.charityDbaAuthLetters.length === 0 ||
              $scope.charityDbaAuthLetters[0].markedToDelete === true)
          ) {
            $scope.fieldsMissing.push(
              'You have not uploaded the State Letter Authorizing DBA'
            );
          }
        }
        if (exit) {
          if ($scope.fieldsMissing.length > 0) {
            $scope.applicationStep4.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
            if (!$scope.fileError) {
              $scope.saveApplicationStep(exit);
            } else {
              $('#missingInfo').modal();
            }
          } else {
            $scope.applicationStep4.stepStatus =
              APPLICATION_STEP_STATUS.complete;
            $scope.saveApplicationStep(exit);
          }
        } else {
          if ($scope.fieldsMissing.length > 0) {
            $scope.applicationStep4.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          } else {
            $scope.applicationStep4.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          }
          $('#missingInfo').modal();
        }
      };

      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('FederationApplicationStep5Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'EXEMPTION_STATUS',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    '$log',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      EXEMPTION_STATUS,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      $log,
      urlAccess
    ) {
      var step5AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      $scope.setFederationAppStep(5);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();

      if (!$scope.applicationStep5) {
        $scope.applicationStep5 = FederationApplicationService.getApplicationStep5();
        if (angular.equals($scope.applicationStep5, {})) {
          $scope.applicationStep5.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep5.cfcCode = FederationApplicationService.getCfcCode();

          $scope.applicationStep5.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep5 = res;
              } else {
                $scope.applicationStep5.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep5.cfcCode = FederationApplicationService.getCfcCode();
              }
            },
            function() {
              $log.error('STEP5 failed to load');
            }
          );
        }
      }
      if (
        null == $scope.applicationStep5.cfcCode &&
        !!FederationApplicationService.getUserSession().cfcCode
      ) {
        $scope.applicationStep5.cfcCode = FederationApplicationService.getUserSession().cfcCode;
      }
      $scope.groupExemption = false;
      $scope.affliate = false;
      if (
        FederationApplicationService.getPreApplicationDetails() != null &&
        FederationApplicationService.getPreApplicationDetails()
          .groupExemption &&
        FederationApplicationService.getPreApplicationDetails().foundation
      ) {
        if (
          FederationApplicationService.getPreApplicationDetails()
            .groupExemption !== '0' &&
          FederationApplicationService.getPreApplicationDetails().foundation !==
            '10'
        ) {
          $scope.groupExemption = true;
        }
      }
      if (
        FederationApplicationService.getPreApplicationDetails() != null &&
        FederationApplicationService.getPreApplicationDetails().parentName
      ) {
        $scope.affliate = true;
        $scope.parentName = FederationApplicationService.getPreApplicationDetails().parentName;
        $scope.parentEin = FederationApplicationService.getPreApplicationDetails().parentEin;
        $scope.parentCfcCode = FederationApplicationService.getPreApplicationDetails().parentCfcCode;
      }
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step5AccessBeginDate = step5AccessBeginDate;
        FederationApplicationService.getUserSession().step5AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step5AccessBeginDate
        );
        FederationApplicationService.getUserSession().step5TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        FederationApplicationService.saveStep($scope.applicationStep5, function(
          response
        ) {
          FederationApplicationService.saveTable(
            FederationApplicationService.getUserSession(),
            function(response) {
              var overallStatus = 'Complete';
              FederationApplicationService.getFederationStatus(
                FederationApplicationService.getApplicationId(),
                function(data) {
                  for (var i = 0; i < data.length; i++) {
                    if (data[i].stepStatus === 'Incomplete') {
                      overallStatus = 'Incomplete';
                      break;
                    }
                  }
                  var app = FederationApplicationService.getApplication();
                  app.overallApplicationStatus = overallStatus;
                  FederationApplicationService.saveTable(app, function(
                    response
                  ) {
                    if (exit) {
                      $scope.setFederationAppStep(0);
                      // Start - code added for new sprint
                      //  $state.go('federationReview');
                      var applicationId = FederationApplicationService.getApplicationId();
                      FederationApplicationService.reset();
                      $state.go('federationDashboard', {
                        federationId: applicationId
                      }); // End - code added for new sprint
                    } else {
                      $('.modal-backdrop').remove();
                      $state.go('federationStep6');
                    }
                  });
                }
              );
            }
          );
        });
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        if (!$scope.applicationStep5.certifyWelfare) {
          $scope.fieldsMissing.push(
            'Certify human health and welfare services.'
          );
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep5.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep5.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };

      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('FederationApplicationStep6Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'GAAP_OPTION',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'FederationDashboardService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'CharityDashboardService',
    'FederationApplicationReviewService',
    'DownloadService',
    'tokenData',
    '$log',
    'urlAccess',
    '$q',
    '$filter',
    'PLEDGE_YEARS',
    'ValidationUtil',
    'DATES',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      GAAP_OPTION,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      FederationDashboardService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      CharityDashboardService,
      FederationApplicationReviewService,
      DownloadService,
      tokenData,
      $log,
      urlAccess,
      $q,
      $filter,
      PLEDGE_YEARS,
      ValidationUtil,
      DATES
    ) {
      var step6AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      $scope.setFederationAppStep(6);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.displayForPriorYears = false;
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );

      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();

      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }

      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.isOpmAdmin =
        userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
      $scope.isZoneManager =
        userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 || false;
      $scope.isOpmStaff =
        userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 || false;
      $scope.isZoneReviewer =
        userOb.secondaryRoleList.indexOf('ZONE_REVIEWER') >= 0 || false;
      $scope.isOpmAuditor =
        userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 || false;

      if (!$scope.applicationStep6) {
        $scope.applicationStep6 = FederationApplicationService.getApplicationStep6();
        // if (angular.equals($scope.applicationStep6, {})) {
        $scope.applicationStep6.applicationId = FederationApplicationService.getApplicationId();
        $scope.applicationStep6.cfcCode = FederationApplicationService.getCfcCode();
        $scope.applicationStep6.pledgeYear = FederationApplicationService.getPledgeYear();

        if (
          FederationApplicationService.getPreApplicationDetails() != null &&
          FederationApplicationService.getPreApplicationDetails().charityEin
        ) {
          $scope.applicationStep6.stepApplicable = false;
        } else {
          $scope.applicationStep6.stepApplicable = true;
        }

        $q(function(resolve, reject) {
          $scope.applicationStep6.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep6 = res;
              } else {
                $scope.applicationStep6.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep6.cfcCode = FederationApplicationService.getCfcCode();
                $scope.applicationStep6.pledgeYear = FederationApplicationService.getPledgeYear();
              }
              resolve($scope.applicationStep6);
              $scope.federation_audited_financial_s3_link =
                $scope.applicationStep6.s3Link;
              if ($scope.applicationStep6.documentEndDate) {
                var localDate = new Date(
                  $scope.applicationStep6.documentEndDate
                );
                var localTime = localDate.getTime();
                var localOffset = localDate.getTimezoneOffset() * 60000;
                $scope.mydate = new Date(localTime + localOffset);
                $scope.applicationStep6.documentEndDate = $filter('date')(
                  new Date($scope.mydate),
                  'MM/dd/yyyy'
                );
              }
            },
            function(err) {
              $log.error('STEP6 failed to load');
              reject(err);
            }
          );
        }).then(function() {
          if (
            null == $scope.applicationStep6.cfcCode &&
            !!FederationApplicationService.getUserSession().cfcCode
          ) {
            $scope.applicationStep6.cfcCode = FederationApplicationService.getUserSession().cfcCode;
          }

          CharityDashboardService.getCharityDashboardData(
            $scope.applicationStep6.cfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.applicationStep6.charityEin = result.einNumber;
            }
          });
          /* populate listing information */
          CharityDashboardService.findCharityWithCFC($scope.cfcCode).then(
            function(res) {
              var result = res.data;
              if (result !== null && result !== '') {
                $scope.charityInformation = result;
              }
            }
          );

          if (
            $scope.applicationStep6.pledgeYear &&
            $scope.applicationStep6.cfcCode
          ) {
            if (
              userOb.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
              userOb.secondaryRoleList.indexOf('REVIEWER') < 0
            ) {
              CharityDashboardService.getCharityListingObject(
                $scope.applicationStep6.pledgeYear,
                $scope.applicationStep6.cfcCode
              ).then(function(res) {
                var result = res.data.value;
                if (result !== null && result !== '') {
                  $scope.charityListingObject = result;

                  $scope.applicationStep6.charityListingViewObject = angular.copy(
                    $scope.charityListingObject
                  );
                }
              });
            }
          }
        });
        // }
      }

      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        if ($scope.applicationStep6.gaapOption === 'OPTION_2') {
          $scope.applicationStep6.supportingFile = null;
          $scope.applicationStep6.documentStartDate = null;
          $scope.applicationStep6.documentEndDate = null;
        } else {
          $scope.applicationStep6.documentStartDate = $scope.applicationStep6
            .documentStartDate
            ? new Date($scope.applicationStep6.documentStartDate)
            : null;
          $scope.applicationStep6.documentEndDate = $scope.applicationStep6
            .documentEndDate
            ? new Date($scope.applicationStep6.documentEndDate)
            : null;
        }
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step6AccessBeginDate = step6AccessBeginDate;
        FederationApplicationService.getUserSession().step6AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step6AccessBeginDate
        );
        FederationApplicationService.getUserSession().step6TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        FederationApplicationService.saveFileStep6(
          $scope.applicationStep6,
          function(response) {
            if (
              response.message &&
              response.message.type === 'ERROR' &&
              !$scope.continue
            ) {
              $('input[type="file"]').val(null);
              $scope.applicationStep6.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }

            // this handles exception with a response
            if ((!response || response.errorCode) && !$scope.continue) {
              $scope.applicationStep6.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }
            $scope.applicationStep6.fileName = response.fileName;
            FederationApplicationService.saveTable(
              FederationApplicationService.getUserSession(),
              function(response) {
                var overallStatus = 'Complete';
                FederationApplicationService.getFederationStatus(
                  FederationApplicationService.getApplicationId(),
                  function(data) {
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].stepStatus === 'Incomplete') {
                        overallStatus = 'Incomplete';
                        break;
                      }
                    }
                    var app = FederationApplicationService.getApplication();
                    app.overallApplicationStatus = overallStatus;
                    FederationApplicationService.saveTable(app, function(
                      response
                    ) {
                      if (exit) {
                        $scope.setFederationAppStep(0);
                        // Start - code added for new sprint
                        //  $state.go('federationReview');
                        var applicationId = FederationApplicationService.getApplicationId();
                        FederationApplicationService.reset();
                        $state.go('federationDashboard', {
                          federationId: applicationId
                        }); // End - code added for new sprint
                      } else {
                        $('.modal-backdrop').remove();
                        $state.go('federationStep7');
                      }
                    });
                  }
                );
              }
            );
          }
        );
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if (
          FederationApplicationService.getPreApplicationDetails() != null &&
          FederationApplicationService.getPreApplicationDetails().charityEin
        ) {
          $scope.applicationStep6.stepApplicable = false;
        } else {
          $scope.applicationStep6.stepApplicable = true;
        }
        if ($scope.applicationStep6.stepApplicable) {
          if (!$scope.applicationStep6.gaapOption) {
            $scope.fieldsMissing.push('GAAP Option');
          }
          if ($scope.applicationStep6.gaapOption === GAAP_OPTION.option1) {
            if (
              !$scope.applicationStep6.supportingFile &&
              !$scope.applicationStep6.fileName
            ) {
              $scope.fieldsMissing.push('Attachment B is not uploaded.');
            } else {
              if (
                $scope.applicationStep6.supportingFile &&
                $scope.applicationStep6.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'Attachment B: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }
            if (!$scope.displayForPriorYears) {
              if (!$scope.applicationStep6.documentEndDate) {
                $scope.fieldsMissing.push(
                  'Please enter Fiscal Period end date'
                );
              }
              if ($scope.applicationStep6.documentEndDate) {
                var valid = ValidationUtil.validateDate(
                  $scope.applicationStep6.documentEndDate
                );
                if (!valid) {
                  $scope.fileError = true;
                  $scope.fieldsMissing.push('Date is not valid.');
                }
                var documentValidStartDate =
                  DATES.FISCAL_START_DATE + ($scope.pledgeYear - 2);
                var documentValidEndDate =
                  DATES.END_DATE + ($scope.pledgeYear - 1);
                if (
                  !ValidationUtil.validateDateInRespectedPeriod(
                    null,
                    $scope.applicationStep6.documentEndDate,
                    documentValidStartDate,
                    documentValidEndDate
                  )
                ) {
                  $scope.fieldsMissing.push(
                    'The date for the financial statements is for an unacceptable period. For the ' +
                      $scope.pledgeYear +
                      ' campaign, financial statements must be for a fiscal period that ended on or after June 30, ' +
                      ($scope.pledgeYear - 2)
                  );
                }
              }
            }
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep6.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && !$scope.fileError) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep6.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      $scope.optionChanged = function() {
        $scope.applicationStep6.supportingFile = null;
        $('input[type="file"]').val(null);
      };

      $scope.resetData = function() {
        $scope.applicationStep6.documentStartDate = null;
        $scope.applicationStep6.documentEndDate = null;
      };

      init();
    }
  ])
  .controller('FederationApplicationStep7Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'REVENUE_OPTION',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'FederationDashboardService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'CharityDashboardService',
    'FederationApplicationReviewService',
    'DownloadService',
    'tokenData',
    '$log',
    'urlAccess',
    '$q',
    'PLEDGE_YEARS',
    'ValidationUtil',
    '$filter',
    'CubeSvc',
    'DATES',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      REVENUE_OPTION,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      FederationDashboardService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      CharityDashboardService,
      FederationApplicationReviewService,
      DownloadService,
      tokenData,
      $log,
      urlAccess,
      $q,
      PLEDGE_YEARS,
      ValidationUtil,
      $filter,
      CubeSvc,
      DATES
    ) {
      var step7AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      $scope.setFederationAppStep(7);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      $scope.displayForPriorYears = false;
      $scope.pledgeYear = tokenData.pledgeYear;
      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.cfcCode = FederationApplicationService.getCfcCode();
      $scope.currentFileVersion = 1;

      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.isOpmAdmin =
        userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
      $scope.isZoneManager =
        userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 || false;
      $scope.isOpmStaff =
        userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 || false;
      $scope.isZoneReviewer =
        userOb.secondaryRoleList.indexOf('ZONE_REVIEWER') >= 0 || false;
      $scope.isOpmAuditor =
        userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 || false;

      if (!$scope.applicationStep7) {
        $scope.applicationStep7 = FederationApplicationService.getApplicationStep7();
        // if (angular.equals($scope.applicationStep7, {})) {
        $scope.applicationStep7.applicationId = FederationApplicationService.getApplicationId();
        $scope.applicationStep7.cfcCode = FederationApplicationService.getCfcCode();
        $scope.applicationStep7.pledgeYear = FederationApplicationService.getPledgeYear();

        $q(function(resolve, reject) {
          $scope.applicationStep7.$get(
            function(step7) {
              if (!angular.equals(step7, {})) {
                $scope.applicationStep7 = step7;
                if (!step7.changedFileName) {
                  return;
                }
                if (
                  step7.changedFileName != null ||
                  step7.changedFileName != undefined
                ) {
                  var us = step7.changedFileName.lastIndexOf('_');
                  $scope.currentFileVersion = parseInt(
                    step7.changedFileName
                      .substring(us, step7.changedFileName.length)
                      .replace('_ver', '')
                      .replace(/\.[^\.]+$/, '')
                  );
                }
              } else {
                $scope.applicationStep7.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep7.cfcCode = FederationApplicationService.getCfcCode();
                $scope.applicationStep7.pledgeYear = FederationApplicationService.getPledgeYear();
              }
              if ($scope.applicationStep7.signed === true) {
                $scope.applicationStep7.signed = 'Yes';
              } else if ($scope.applicationStep7.signed === false) {
                $scope.applicationStep7.signed = 'No';
              } else {
                $scope.applicationStep7.signed = null;
              }
              if ($scope.applicationStep7.startDate != null) {
                var localDate = new Date($scope.applicationStep7.startDate);
                var localTime = localDate.getTime();
                var localOffset = localDate.getTimezoneOffset() * 60000;
                $scope.mydate = new Date(localTime + localOffset);
                $scope.applicationStep7.startDate = $filter('date')(
                  new Date($scope.mydate),
                  'MM/dd/yyyy'
                );
              }
              if ($scope.applicationStep7.endDate != null) {
                var localDate1 = new Date($scope.applicationStep7.endDate);
                var localTime1 = localDate1.getTime();
                var localOffset1 = localDate1.getTimezoneOffset() * 60000;
                $scope.mydate1 = new Date(localTime1 + localOffset1);
                $scope.applicationStep7.endDate = $filter('date')(
                  new Date($scope.mydate1),
                  'MM/dd/yyyy'
                );
              }
              if ($scope.applicationStep7.accountingMethod === null) {
                $scope.applicationStep7.accountingMethod = '--';
              }
              resolve($scope.applicationStep7);
              $scope.federation_form_990_s3_link =
                $scope.applicationStep7.s3Link;
            },
            function(err) {
              $log.error('STEP7 failed to load');
              reject(err);
            }
          );
        }).then(function() {
          CharityDashboardService.getCharityDashboardData(
            $scope.applicationStep7.cfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.applicationStep7.charityEin = result.einNumber;
            }
          });

          /* populate listing information */
          CharityDashboardService.findCharityWithCFC($scope.cfcCode).then(
            function(res) {
              var result = res.data;
              if (result !== null && result !== '') {
                $scope.charityInformation = result;
              }
            }
          );

          if (
            $scope.applicationStep7.pledgeYear &&
            $scope.applicationStep7.cfcCode
          ) {
            if (
              userOb.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
              userOb.secondaryRoleList.indexOf('REVIEWER') < 0
            ) {
              CharityDashboardService.getCharityListingObject(
                $scope.applicationStep7.pledgeYear,
                $scope.applicationStep7.cfcCode
              ).then(function(res) {
                var result = res.data.value;
                if (result !== null && result !== '') {
                  $scope.charityListingObject = result;

                  $scope.applicationStep7.charityListingViewObject = angular.copy(
                    $scope.charityListingObject
                  );
                }
              });
            }
          }
        });
        // }
      }

      if (
        null == $scope.applicationStep7.cfcCode &&
        !!FederationApplicationService.getUserSession().cfcCode
      ) {
        $scope.applicationStep7.cfcCode = FederationApplicationService.getUserSession().cfcCode;
      }
      $scope.getFileList = function() {
        return new Array($scope.currentFileVersion);
      };

      $scope.showModal = function() {
        if ($scope.applicationStep7.signed === 'No') {
          $('#signatureRequired').modal('show');
        }
      };

      $scope.openFilesModal = function() {
        if (!$scope.displayForPriorYears) {
          var url =
            '/CfcCharity/api/private/federation_application/step7/uploadedfiles/' +
            $scope.applicationStep7.applicationId;
          CubeSvc.get(url, null, false).then(function(res) {
            $scope.uploadedFileData = res.data.value;
            $scope.irsHistory = $scope.uploadedFileData.irsHistory;
            $scope.eSignHistory = $scope.uploadedFileData.eSignHistory;
          });
        }
        $('#allFiles').modal();
      };
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step7AccessBeginDate = step7AccessBeginDate;
        FederationApplicationService.getUserSession().step7AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step7AccessBeginDate
        );
        FederationApplicationService.getUserSession().step7TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        if ($scope.applicationStep7.accountingMethod === '--') {
          $scope.applicationStep7.accountingMethod = null;
          $scope.applicationStep7.accountingNotes = null;
        }
        if ($scope.applicationStep7.signed === 'Yes') {
          $scope.applicationStep7.signed = true;
        } else if ($scope.applicationStep7.signed === 'No') {
          $scope.applicationStep7.signed = false;
        }
        if ($scope.applicationStep7.startDate === '') {
          $scope.applicationStep7.startDate = null;
        }
        if ($scope.applicationStep7.endDate === '') {
          $scope.applicationStep7.endDate = null;
        }
        FederationApplicationService.saveFileStep7(
          $scope.applicationStep7,
          function(response) {
            if (
              response.message &&
              response.message.type === 'ERROR' &&
              !$scope.continue
            ) {
              $('input[type="file"]').val(null);
              $scope.applicationStep7.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }
            // this handles exception with a response
            if ((!response || response.errorCode) && !$scope.continue) {
              $scope.applicationStep7.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }
            $scope.applicationStep7.fileName = response.fileName;
            FederationApplicationService.saveTable(
              FederationApplicationService.getUserSession(),
              function(response) {
                var overallStatus = 'Complete';
                FederationApplicationService.getFederationStatus(
                  FederationApplicationService.getApplicationId(),
                  function(data) {
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].stepStatus === 'Incomplete') {
                        overallStatus = 'Incomplete';
                        break;
                      }
                    }
                    var app = FederationApplicationService.getApplication();
                    app.overallApplicationStatus = overallStatus;
                    FederationApplicationService.saveTable(app, function(
                      response
                    ) {
                      if (exit) {
                        $scope.setFederationAppStep(0);
                        // Start - code added for new sprint
                        //  $state.go('federationReview');
                        var applicationId = FederationApplicationService.getApplicationId();
                        FederationApplicationService.reset();
                        $state.go('federationDashboard', {
                          federationId: applicationId
                        }); // End - code added for new sprint
                      } else {
                        $('.modal-backdrop').remove();
                        $state.go('federationStep8');
                      }
                    });
                  }
                );
              }
            );
          }
        );
      };

      $scope.resetData = function() {
        $scope.applicationStep7.startDate = null;
        $scope.applicationStep7.endDate = null;
        $scope.applicationStep7.accountingMethod = '--';
        $scope.applicationStep7.accountingNotes = null;
        $scope.applicationStep7.signed = null;
        $scope.applicationStep7.fullName = null;
        $scope.applicationStep7.initials = null;
        $scope.applicationStep7.title = null;
        $scope.applicationStep7.certify = null;
      };

      $scope.accountingMethodChange = function() {
        $scope.applicationStep7.accountingNotes = null;
      };

      $scope.saveSignature = function() {
        if (!$scope.applicationStep7.certify) {
          $scope.signError = '*You are missing the cetify checkbox.';
        } else {
          $('.modal-backdrop').hide();
          $('#signatureRequired').hide();
        }
      };

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if (!$scope.applicationStep7.irsForm990Option) {
          $scope.fieldsMissing.push('IRS Form 990 or Pro Forma Form 990');
        }
        if ($scope.applicationStep7.irsForm990Option) {
          if (
            $scope.applicationStep7.irsForm990Option === REVENUE_OPTION.option1
          ) {
            if (
              !$scope.applicationStep7.supportingFile &&
              !$scope.applicationStep7.fileName
            ) {
              $scope.fieldsMissing.push('Attachment C is not uploaded.');
            } else {
              if (
                $scope.applicationStep7.supportingFile &&
                $scope.applicationStep7.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'IRS Form 990 Attachment C: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }
          } else if (
            $scope.applicationStep7.irsForm990Option === REVENUE_OPTION.option2
          ) {
            if (
              !$scope.applicationStep7.supportingFile &&
              !$scope.applicationStep7.fileName
            ) {
              $scope.fieldsMissing.push('Pro Forma Form 990 Attachment C');
            } else {
              if (
                $scope.applicationStep7.supportingFile &&
                $scope.applicationStep7.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'Pro Forma Form 990 Attachment C: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }
          }
        }
        if (!$scope.displayForPriorYears) {
          if (!$scope.applicationStep7.startDate) {
            $scope.fieldsMissing.push('Document start date is missing.');
          }
          if (!$scope.applicationStep7.endDate) {
            $scope.fieldsMissing.push('Document end date is missing.');
          }
          var dateValidationErrors = ValidationUtil.validateStartEndDates(
            $scope.applicationStep7.startDate,
            $scope.applicationStep7.endDate
          );
          if (dateValidationErrors.length > 0) {
            $scope.fileError = true;
            $scope.fieldsMissing.push(...dateValidationErrors);
          }
          if (
            $scope.applicationStep7.startDate &&
            $scope.applicationStep7.endDate
          ) {
            var documentValidStartDate =
              DATES.FISCAL_START_DATE + ($scope.pledgeYear - 2);
            var documentValidEndDate = DATES.END_DATE + ($scope.pledgeYear - 1);
            if (
              !ValidationUtil.validateDateInRespectedPeriod(
                null,
                $scope.applicationStep7.endDate,
                documentValidStartDate,
                documentValidEndDate
              )
            ) {
              $scope.fieldsMissing.push(
                'The date for the IRS Form 990, or pro forma IRS Form 990, is for an unacceptable period. For the ' +
                  $scope.pledgeYear +
                  ' campaign, tax returns must be for a fiscal period that ended on or after June 30, ' +
                  ($scope.pledgeYear - 2) +
                  '. The fiscal period must be for a 12-month duration.'
              );
            }
          }
          if (
            !$scope.applicationStep7.accountingMethod ||
            $scope.applicationStep7.accountingMethod === '--'
          ) {
            $scope.fieldsMissing.push('Accounting Method not added.');
          }
          if (
            $scope.applicationStep7.accountingMethod === 'Other' &&
            !$scope.applicationStep7.accountingNotes
          ) {
            $scope.fieldsMissing.push('Other Type is not mentioned.');
          }
          if (!$scope.applicationStep7.signed) {
            $scope.fieldsMissing.push('Signature selection is not made.');
          }
          if (
            $scope.applicationStep7.signed === 'No' &&
            !$scope.applicationStep7.certify
          ) {
            $scope.fieldsMissing.push(
              'Signature of Officer is marked as "No" and has not been added electronically'
            );
          }
          if (
            $scope.totalRevenue != undefined &&
            $scope.totalRevenue != null &&
            ValidationUtil.isNumeric($scope.totalRevenue) &&
            $scope.totalRevenue >= 100000 &&
            $scope.applicationStep7.accountingMethod != 'Accrual'
          ) {
            $scope.fieldsMissing.push(
              'An organization with a total revenue above $100,000 must use the “Accrual” accounting method (Part XII, Line 1) to prepare its IRS Form 990, or pro forma IRS Form 990.'
            );
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep7.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && !$scope.fileError) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep7.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        }
      };
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
        var url =
          '/CfcCharity/api/private/federation_application/step8/' +
          tokenData.applicationId;
        CubeSvc.get(url, null, false).then(function(res) {
          $scope.step8 = res.data.value;
          if ($scope.step8) {
            $scope.totalRevenue = $scope.step8.totalRevenue;
          }
        });
      }
      $scope.optionChanged = function() {
        $scope.applicationStep7.supportingFile = null;
        $('input[type="file"]').val(null);
      };
      init();
    }
  ])
  .controller('FederationApplicationStep8Ctrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'IRS_FORM990_OPTION',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'BrowserEventService',
    'CommonService',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    'urlAccess',
    '$q',
    'CubeSvc',
    function(
      $log,
      $state,
      $scope,
      $location,
      MODULE_NAME,
      IRS_FORM990_OPTION,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      BrowserEventService,
      CommonService,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      urlAccess,
      $q,
      CubeSvc
    ) {
      var step8AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      $scope.setFederationAppStep(8);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      $scope.pledgeYear = tokenData.pledgeYear;
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();

      if (null == $scope.cfcCode) {
        FederationApplicationService.setCfcCode(
          FederationApplicationService.getUserSession().cfcCode
        );
      }

      if (!$scope.applicationStep8) {
        $scope.applicationStep8 = FederationApplicationService.getApplicationStep8();
        if (angular.equals($scope.applicationStep8, {})) {
          $scope.applicationStep8.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep8.cfcCode = FederationApplicationService.getCfcCode();
          $scope.applicationStep8.$get(
            function(step8) {
              if (!angular.equals(step8, {})) {
                $scope.applicationStep8 = step8;
                if (!step8.changedFileName) {
                  return;
                }

                var us = $scope.applicationStep8.changedFileName.lastIndexOf(
                  '_'
                );
                $scope.currentFileVersion = parseInt(
                  $scope.applicationStep8.changedFileName
                    .substring(
                      us,
                      $scope.applicationStep8.changedFileName.length
                    )
                    .replace('_ver', '')
                    .replace(/\.[^\.]+$/, '')
                );
              } else {
                $scope.applicationStep8.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep8.cfcCode = FederationApplicationService.getCfcCode();
              }
            },
            function() {
              $log.error('STEP8 failed to load');
            }
          );
        }
      }

      $scope.onChangeStep8NumericValues = function() {
        if ($scope.mode !== 'EDIT') {
          return;
        }
        var mgmtExpense = $scope.applicationStep8.managementExpenses;
        var fundraisingExpense = $scope.applicationStep8.fundraisingExpense;
        var revenue = $scope.applicationStep8.totalRevenue;

        if (
          !isNumeric(mgmtExpense) ||
          !isNumeric(fundraisingExpense) ||
          !isNumeric(revenue)
        ) {
          $scope.applicationStep8.fundraisingRate = null;
          return;
        }

        FederationApplicationService.calculateAfr(
          mgmtExpense,
          fundraisingExpense,
          revenue
        ).then(function(res) {
          $scope.applicationStep8.fundraisingRate = res.data.value;
        });

        $scope.applicationStep8.applicationId = FederationApplicationService.getApplicationId();
        $scope.applicationStep8.cfcCode = FederationApplicationService.getCfcCode();
      };

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.step7Mismatch = false;
        $scope.fullYearOver100kPopup = false;

        if (
          !$scope.applicationStep8.managementExpenses &&
          !isNumeric($scope.applicationStep8.managementExpenses)
        ) {
          $scope.fieldsMissing.push('Management & General Expenses');
        }
        if (!isNumeric($scope.applicationStep8.fundraisingExpense)) {
          $scope.fieldsMissing.push('Fundraising Expenses');
        }
        if (!isNumeric($scope.applicationStep8.fundraisingRate)) {
          $scope.fieldsMissing.push('Fundraising Rate');
        }
        if (!isNumeric($scope.applicationStep8.totalRevenue)) {
          $scope.fieldsMissing.push('Total Revenue');
        }
        if (!$scope.applicationStep8.certifyFundraisingRate) {
          $scope.fieldsMissing.push(
            'You have not confirmed your fundraising rate.'
          );
        }
        if (
          isNumeric($scope.applicationStep8.totalRevenue) &&
          $scope.applicationStep8.totalRevenue >= 100000
        ) {
          if ($scope.applicationStep8.totalRevenue > 100000) {
            $scope.fullYearOver100kPopup = true;
          }
          if (
            $scope.applicationStep8.totalRevenue >= 100000 &&
            $scope.accountingMethod != 'Accrual'
          ) {
            $scope.step7Mismatch = true;
            $scope.fieldsMissing.push(
              'An organization with a total revenue above $100,000 must use the “Accrual” accounting method (Part XII, Line 1) to prepare its IRS Form 990, or pro forma IRS Form 990.'
            );
          }
        }
        if ($scope.fieldsMissing.length > 0 || $scope.fullYearOver100kPopup) {
          if (
            $scope.fieldsMissing.length === 0 &&
            $scope.fullYearOver100kPopup
          ) {
            $scope.applicationStep8.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else if (
            $scope.fieldsMissing.length === 1 &&
            $scope.step7Mismatch
          ) {
            $scope.applicationStep8.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else {
            $scope.applicationStep8.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          }
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep8.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };

      $scope.$watch(
        'applicationStep8.managementExpenses',
        function(newValue, oldValue) {
          if (newValue === undefined || newValue == null) return;
          $scope.applicationStep8.managementExpenses = customNumberFormatter(
            newValue
          );
        },
        true
      );

      $scope.$watch(
        'applicationStep8.fundraisingExpense',
        function(newValue, oldValue) {
          if (newValue === undefined || newValue == null) return;
          $scope.applicationStep8.fundraisingExpense = customNumberFormatter(
            newValue
          );
        },
        true
      );

      $scope.$watch(
        'applicationStep8.totalRevenue',
        function(newValue, oldValue) {
          if (newValue === undefined || newValue == null) return;
          $scope.applicationStep8.totalRevenue = customNumberFormatter(
            newValue
          );
        },
        true
      );

      function customNumberFormatter(n) {
        // allow only digits and minus sign
        var n = n.toString().replace(/[^\d|\-+]/g, '');
        // only allow minus sign at index 0
        if (n.length === 1 && n.substr(0) === '-') {
          return '-';
        }
        // don't allow multiple minus signs
        if (n.length > 1 && n.substr(n.length - 1) === '-') {
          n = n.slice(0, -1);
        }
        if (isNumeric(n)) return parseInt(n, 10);
      }

      function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }

      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step8AccessBeginDate = step8AccessBeginDate;
        FederationApplicationService.getUserSession().step8AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step8AccessBeginDate
        );
        FederationApplicationService.getUserSession().step8TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        FederationApplicationService.saveStep($scope.applicationStep8, function(
          response
        ) {
          FederationApplicationService.saveTable(
            FederationApplicationService.getUserSession(),
            function(response) {
              var overallStatus = 'Complete';
              FederationApplicationService.getFederationStatus(
                FederationApplicationService.getApplicationId(),
                function(data) {
                  for (var i = 0; i < data.length; i++) {
                    if (data[i].stepStatus === 'Incomplete') {
                      overallStatus = 'Incomplete';
                      break;
                    }
                  }
                  var app = FederationApplicationService.getApplication();
                  app.overallApplicationStatus = overallStatus;
                  FederationApplicationService.saveTable(app, function(
                    response
                  ) {
                    if (exit) {
                      $scope.setFederationAppStep(0);
                      // Start - code added for new sprint
                      //  $state.go('federationReview');
                      var applicationId = FederationApplicationService.getApplicationId();
                      FederationApplicationService.reset();
                      $state.go('federationDashboard', {
                        federationId: applicationId
                      }); // End - code added for new sprint
                    } else {
                      $('.modal-backdrop').remove();
                      $state.go('federationStep9');
                    }
                  });
                }
              );
            }
          );
        });
      };
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();

        var url =
          '/CfcCharity/api/private/federation_application/step7/' +
          tokenData.applicationId;
        CubeSvc.get(url, null, false).then(function(res) {
          $scope.step7 = res.data.value;
          $scope.accountingMethod = $scope.step7.accountingMethod;
        });
      }
      init();
    }
  ])
  .controller('FederationApplicationStep9Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'FederationDashboardService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'DownloadService',
    'tokenData',
    '$log',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      FederationDashboardService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      DownloadService,
      tokenData,
      $log,
      urlAccess
    ) {
      var step9AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      $scope.setFederationAppStep(9);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);

      if (!$scope.applicationStep9) {
        $scope.applicationStep9 = FederationApplicationService.getApplicationStep9();
        if (angular.equals($scope.applicationStep9, {})) {
          $scope.applicationStep9.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep9.cfcCode = FederationApplicationService.getCfcCode();
          $scope.applicationStep9.fundraisingRate = 0;
          $scope.applicationStep9.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep9 = res;
              } else {
                $scope.applicationStep9.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep9.cfcCode = FederationApplicationService.getCfcCode();
                $scope.applicationStep9.fundraisingRate = 0;
              }
            },
            function() {
              $log.error('STEP9 failed to load');
            }
          );
        }
      }

      if (
        null == $scope.applicationStep9.cfcCode &&
        !!FederationApplicationService.getUserSession().cfcCode
      ) {
        $scope.applicationStep9.cfcCode = FederationApplicationService.getUserSession().cfcCode;
      }
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step9AccessBeginDate = step9AccessBeginDate;
        FederationApplicationService.getUserSession().step9AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step9AccessBeginDate
        );
        FederationApplicationService.getUserSession().step9TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
          FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
            currentTime,
            FederationApplicationService.getUserSession().lastAccessedBeginDate
          );
        }
        if (!$scope.applicationStep9.certifyGoverningBody) {
          $scope.applicationStep9.certifyGoverningBody = false;
        }
        FederationApplicationService.saveFileStep9(
          $scope.applicationStep9,
          function(response) {
            if (
              response.message &&
              response.message.type === 'ERROR' &&
              !$scope.continue
            ) {
              $('input[type="file"]').val(null);
              $scope.applicationStep9.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }
            // this handles exception with a response
            if ((!response || response.errorCode) && !$scope.continue) {
              $scope.applicationStep9.supportingFile = null;
              $('#modalUpload').modal();
              return;
            }
            $scope.applicationStep9.fileName = response.fileName;
            FederationApplicationService.saveTable(
              FederationApplicationService.getUserSession(),
              function(response) {
                var overallStatus = 'Complete';
                FederationApplicationService.getFederationStatus(
                  FederationApplicationService.getApplicationId(),
                  function(data) {
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].stepStatus === 'Incomplete') {
                        overallStatus = 'Incomplete';
                        break;
                      }
                    }
                    var app = FederationApplicationService.getApplication();
                    app.overallApplicationStatus = overallStatus;
                    FederationApplicationService.saveTable(app, function(
                      response
                    ) {
                      if (exit) {
                        $scope.setFederationAppStep(0);
                        // Start - code added for new sprint
                        //  $state.go('federationReview');
                        var applicationId = FederationApplicationService.getApplicationId();
                        FederationApplicationService.reset();
                        $state.go('federationDashboard', {
                          federationId: applicationId
                        }); // End - code added for new sprint
                      } else {
                        $('.modal-backdrop').remove();
                        $state.go('federationStep10');
                      }
                    });
                  }
                );
              }
            );
          }
        );
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if (!$scope.applicationStep9.certifyGoverningBody) {
          $scope.fieldsMissing.push('Certify Governing Body');
        }
        if (
          !$scope.applicationStep9.supportingFile &&
          !$scope.applicationStep9.fileName
        ) {
          $scope.fieldsMissing.push('Attachment D is not uploaded.');
        } else {
          if (
            $scope.applicationStep9.supportingFile &&
            $scope.applicationStep9.supportingFile.type !== 'application/pdf'
          ) {
            $scope.fieldsMissing.push(
              'Attachment D: Only PDF file is allowed.'
            );
            $scope.fileError = true;
          } else {
            $scope.fileError = false;
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep9.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && !$scope.fileError) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep9.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('FederationApplicationStep10Ctrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'FederationApplicationService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    '$log',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      FederationApplicationService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      $log,
      urlAccess
    ) {
      var step10AccessBeginDate = FederationApplicationService.toUTCDate(
        new Date()
      );
      $scope.setFederationAppStep(10);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = FederationApplicationService.getPledgeYear();
      $scope.cfcCode = FederationApplicationService.getCfcCode();

      if (null == $scope.cfcCode) {
        FederationApplicationService.setCfcCode(
          FederationApplicationService.getUserSession().cfcCode
        );
      }

      if (!$scope.applicationStep10) {
        $scope.applicationStep10 = FederationApplicationService.getApplicationStep10();
        if (angular.equals($scope.applicationStep10, {})) {
          $scope.applicationStep10.applicationId = FederationApplicationService.getApplicationId();
          $scope.applicationStep10.cfcCode = FederationApplicationService.getCfcCode();
          $scope.applicationStep10.$get(
            function(res) {
              if (!angular.equals(res, {})) {
                $scope.applicationStep10 = res;
              } else {
                $scope.applicationStep10.applicationId = FederationApplicationService.getApplicationId();
                $scope.applicationStep10.cfcCode = FederationApplicationService.getCfcCode();
              }
            },
            function() {
              $log.error('STEP10 failed to load');
            }
          );
        }
      }
      $scope.saveApplicationStep = function(exit) {
        var currentTime = FederationApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        FederationApplicationService.getUserSession().step10AccessBeginDate = step10AccessBeginDate;
        FederationApplicationService.getUserSession().step10AccessEndDate = currentTime;
        dateDifferenceInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          step10AccessBeginDate
        );
        FederationApplicationService.getUserSession().step10TimeSpentInSeconds = dateDifferenceInSeconds;
        FederationApplicationService.getUserSession().lastAccessedEndDate = currentTime;
        FederationApplicationService.getUserSession().totalTimeSpentInSeconds = FederationApplicationService.getDateDifference(
          currentTime,
          FederationApplicationService.getUserSession().lastAccessedBeginDate
        );
        FederationApplicationService.saveStep(
          $scope.applicationStep10,
          function(response) {
            FederationApplicationService.saveTable(
              FederationApplicationService.getUserSession(),
              function(response) {
                var overallStatus = 'Complete';
                FederationApplicationService.getFederationStatus(
                  FederationApplicationService.getApplicationId(),
                  function(data) {
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].stepStatus === 'Incomplete') {
                        overallStatus = 'Incomplete';
                        break;
                      }
                    }
                    var app = FederationApplicationService.getApplication();
                    app.overallApplicationStatus = overallStatus;
                    FederationApplicationService.saveTable(app, function(
                      response
                    ) {
                      var applicationId = null;
                      if (exit) {
                        $scope.setFederationAppStep(0);
                        // Start - code added for new sprint
                        //  $state.go('federationReview');
                        applicationId = FederationApplicationService.getApplicationId();
                        FederationApplicationService.reset();
                        $state.go('federationDashboard', {
                          federationId: applicationId
                        }); // End - code added for new sprint
                      } else {
                        $('.modal-backdrop').remove();
                        // Start - code added for new sprint
                        // $state.go('federationReview');
                        applicationId = FederationApplicationService.getApplicationId();
                        FederationApplicationService.reset();
                        $state.go('federationDashboard', {
                          federationId: applicationId
                        }); // End - code added for new sprint
                      }
                    });
                  }
                );
              }
            );
          }
        );
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        if (!$scope.applicationStep10.certifyStatement1) {
          $scope.fieldsMissing.push('Certify Statement 1');
        }
        if (!$scope.applicationStep10.certifyStatement2) {
          $scope.fieldsMissing.push('Certify Statement 2');
        }
        if (!$scope.applicationStep10.certifyStatement3) {
          $scope.fieldsMissing.push('Certify Statement 3');
        }
        if (!$scope.applicationStep10.certifyStatement4) {
          $scope.fieldsMissing.push('Certify Statement 4');
        }
        if (!$scope.applicationStep10.certifyStatement5) {
          $scope.fieldsMissing.push('Certify Statement 5');
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep10.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep10.stepStatus =
            APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      function init() {
        $scope.appLabel = FederationApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('FederationApplicationAllStepsCtrl', [
    '$state',
    '$scope',
    '$location',
    'FederationApplicationService',
    'BrowserEventService',
    'CharityService',
    'tokenData',
    '$log',
    'urlAccess',
    'PLEDGE_YEARS',
    'CHARITY_TYPE',
    'CharityDashboardService',
    '$q',
    '$filter',
    'DownloadService',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      FederationApplicationService,
      BrowserEventService,
      CharityService,
      tokenData,
      $log,
      urlAccess,
      PLEDGE_YEARS,
      CHARITY_TYPE,
      CharityDashboardService,
      $q,
      $filter,
      DownloadService,
      CubeSvc
    ) {
      $scope.mode = 'VIEW';
      BrowserEventService.setMode($scope.mode);
      CubeSvc.performManualCacheRelease();
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.pledgeYear = tokenData.pledgeYear;
      $scope.cfcCode = tokenData.federationCfc;
      $scope.applicationId = tokenData.applicationId;

      if (null == $scope.cfcCode) {
        FederationApplicationService.setCfcCode(
          FederationApplicationService.getUserSession().cfcCode
        );
      }

      $scope.displayForPriorYears = false;

      $scope.applicationStep1 = FederationApplicationService.getApplicationStep1();
      $scope.applicationStep2 = FederationApplicationService.getApplicationStep2();
      $scope.applicationStep3 = FederationApplicationService.getApplicationStep3();
      $scope.applicationStep4 = FederationApplicationService.getApplicationStep4();
      $scope.applicationStep5 = FederationApplicationService.getApplicationStep5();
      $scope.applicationStep6 = FederationApplicationService.getApplicationStep6();
      $scope.applicationStep7 = FederationApplicationService.getApplicationStep7();
      $scope.applicationStep8 = FederationApplicationService.getApplicationStep8();
      $scope.applicationStep9 = FederationApplicationService.getApplicationStep9();
      $scope.applicationStep10 = FederationApplicationService.getApplicationStep10();

      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }

      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.isOpmAdmin =
        userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
      $scope.isZoneManager =
        userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 || false;
      $scope.isOpmStaff =
        userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 || false;
      $scope.isZoneReviewer =
        userOb.secondaryRoleList.indexOf('ZONE_REVIEWER') >= 0 || false;
      $scope.isOpmAuditor =
        userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 || false;

      //step1 data
      $scope.applicationStep1.applicationId = $scope.applicationId;
      $scope.applicationStep1.cfcCode = $scope.cfcCode;
      $scope.applicationStep1.$get(
        function(res) {
          if (!angular.equals(res, {})) {
            $scope.applicationStep1 = res;
          } else {
            $scope.applicationStep1.applicationId = $scope.applicationId;
            $scope.applicationStep1.cfcCode = $scope.cfcCode;
          }
        },
        function() {
          $log.error('STEP1 failed to load');
        }
      );

      //step2 data
      $scope.applicationStep2.applicationId = $scope.applicationId;
      $scope.applicationStep2.cfcCode = $scope.cfcCode;
      $scope.applicationStep2.$get(
        function(res) {
          if (!angular.equals(res, {})) {
            $scope.applicationStep2 = res;
            if (
              $scope.applicationStep2.noOfMembers &&
              $scope.applicationStep2.charityType
            ) {
              if ($scope.applicationStep2.charityType === CHARITY_TYPE.local) {
                $scope.noOfMembersL = $scope.applicationStep2.noOfMembers;
              } else if (
                $scope.applicationStep2.charityType === CHARITY_TYPE.national
              ) {
                $scope.noOfMembersN = $scope.applicationStep2.noOfMembers;
              } else if (
                $scope.applicationStep2.charityType ===
                CHARITY_TYPE.international
              ) {
                $scope.noOfMembersI = $scope.applicationStep2.noOfMembers;
              }
            }
          } else {
            $scope.applicationStep2.applicationId = $scope.applicationId;
            $scope.applicationStep2.cfcCode = $scope.cfcCode;
          }
        },
        function() {
          $log.error('STEP2 failed to load');
        }
      );

      //step 3
      $scope.applicationStep3.applicationId = $scope.applicationId;
      $scope.applicationStep3.cfcCode = $scope.cfcCode;
      $scope.applicationStep3.$get(
        function(res) {
          if (!angular.equals(res, {})) {
            $scope.applicationStep3 = res;
          } else {
            $scope.applicationStep3.applicationId = $scope.applicationId;
            $scope.applicationStep3.cfcCode = $scope.cfcCode;
          }
        },
        function() {
          $log.error('STEP3 failed to load');
        }
      );

      //step 4
      CharityService.getPreApplicationDetails(
        $scope.cfcCode,
        $scope.pledgeYear
      ).then(function(res) {
        var data = res.data.value;
        $scope.stepApplicable = !(data.charityEin && data.charityEin !== '0');
        FederationApplicationService.setPreApplicationDetails(data);
        $scope.applicationStep4.applicationId = $scope.applicationId;
        $scope.applicationStep4.cfcCode = $scope.cfcCode;
        $scope.applicationStep4.$get(
          function(res) {
            if (!angular.equals(res, {})) {
              $scope.applicationStep4 = res;
            } else {
              $scope.applicationStep4.applicationId = FederationApplicationService.getApplicationId();
              $scope.applicationStep4.cfcCode = FederationApplicationService.getCfcCode();
            }
          },
          function() {
            $log.error('STEP4 failed to load');
          }
        );
      });

      var loadCharityListingPageInfo = function() {
        if ($scope.pledgeYear && $scope.cfcCode) {
          if (
            !$scope.isZoneManager &&
            userOb.secondaryRoleList.indexOf('REVIEWER') < 0
          ) {
            CharityDashboardService.getCharityListingPageInfo(
              $scope.pledgeYear,
              $scope.cfcCode
            ).then(function(res) {
              var result = res.data.value;
              if (result !== null && result !== '') {
                $scope.charityListingPageInfo = result;

                $scope.charityListingViewPageInfo = angular.copy(
                  $scope.charityListingPageInfo
                );
                if (
                  $scope.charityListingPageInfo &&
                  $scope.charityListingPageInfo.dashboardListingList
                ) {
                  CharityDashboardService.getCharityDbaAuthLetters(
                    $scope.pledgeYear,
                    $scope.cfcCode,
                    $scope.charityListingPageInfo.id
                  ).then(function(resp) {
                    if (resp.data.value != null) {
                      $scope.charityDbaAuthLetters = resp.data.value;
                      if (resp.data.value[0] !== undefined) {
                        $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                      }
                    }
                  });
                  angular.forEach(
                    $scope.charityListingPageInfo.dashboardListingList,
                    function(obj) {
                      if ($scope.pledgeYear === obj.pledgeYear) {
                        $scope.selectedPledgeYearObject = obj;
                        $scope.pledgeYearId = obj.pledgeYearId;
                      }
                    }
                  );
                }
              }
            });
          }
        }
      };

      loadCharityListingPageInfo();

      if ($scope.pledgeYear && $scope.cfcCode) {
        if (
          !$scope.isZoneManager &&
          userOb.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          CharityDashboardService.getCharityListingPageInfo(
            $scope.pledgeYear,
            $scope.cfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingPageInfo = result;

              if (
                $scope.charityListingPageInfo &&
                $scope.charityListingPageInfo.dashboardListingList
              ) {
                CharityDashboardService.getCharityDbaAuthLetters(
                  $scope.pledgeYear,
                  $scope.cfcCode,
                  $scope.charityListingPageInfo.id
                ).then(function(resp) {
                  if (resp.data.value != null) {
                    $scope.charityDbaAuthLetters = resp.data.value;
                    if (resp.data.value[0] !== undefined) {
                      $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                    }
                  }
                });
                angular.forEach(
                  $scope.charityListingPageInfo.dashboardListingList,
                  function(obj) {
                    if ($scope.pledgeYear === obj.pledgeYear) {
                      $scope.selectedPledgeYearObject = obj;
                      $scope.pledgeYearId = obj.pledgeYearId;
                    }
                  }
                );
              }
            }
          });
        }
      }

      if ($scope.pledgeYear && $scope.cfcCode) {
        if (
          !$scope.isZoneManager &&
          userOb.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          $scope.charityListingViewObject = angular.copy(
            $scope.charityListingObject
          );
          CharityService.getCharityUserRole($scope.cfcCode).then(function(res) {
            var result = res.data;
            if (result !== null && result !== '') {
              if (
                result.userRole === 'CHARITY_OWNER' ||
                result.userRole === 'CHARITY_EDITOR' ||
                result.userRole === 'FEDERATION_OWNER' ||
                result.userRole === 'FEDERATION_EDITOR'
              ) {
                $scope.canEditDbaInfo = true;
              }
            }
          });
        }
      }

      //step 5
      $scope.applicationStep5.applicationId = $scope.applicationId;
      $scope.applicationStep5.cfcCode = $scope.cfcCode;
      $scope.applicationStep5.$get(
        function(res) {
          if (!angular.equals(res, {})) {
            $scope.applicationStep5 = res;
          } else {
            $scope.applicationStep5.applicationId = $scope.applicationId;
            $scope.applicationStep5.cfcCode = $scope.cfcCode;
          }
        },
        function() {
          $log.error('STEP5 failed to load');
        }
      );

      $scope.groupExemption = false;
      $scope.affliate = false;
      if (
        FederationApplicationService.getPreApplicationDetails() != null &&
        FederationApplicationService.getPreApplicationDetails()
          .groupExemption &&
        FederationApplicationService.getPreApplicationDetails().foundation
      ) {
        if (
          FederationApplicationService.getPreApplicationDetails()
            .groupExemption !== '0' &&
          FederationApplicationService.getPreApplicationDetails().foundation !==
            '10'
        ) {
          $scope.groupExemption = true;
        }
      }
      if (
        FederationApplicationService.getPreApplicationDetails() != null &&
        FederationApplicationService.getPreApplicationDetails().parentName
      ) {
        $scope.affliate = true;
        $scope.parentName = FederationApplicationService.getPreApplicationDetails().parentName;
        $scope.parentEin = FederationApplicationService.getPreApplicationDetails().parentEin;
        $scope.parentCfcCode = FederationApplicationService.getPreApplicationDetails().parentCfcCode;
      }

      //step 6
      $scope.applicationStep6.applicationId = $scope.applicationId;
      $scope.applicationStep6.cfcCode = $scope.cfcCode;
      $scope.applicationStep6.pledgeYear = tokenData.pledgeYear;

      if (
        FederationApplicationService.getPreApplicationDetails() != null &&
        FederationApplicationService.getPreApplicationDetails().charityEin
      ) {
        $scope.applicationStep6.stepApplicable = false;
      } else {
        $scope.applicationStep6.stepApplicable = true;
      }

      $q(function(resolve, reject) {
        $scope.applicationStep6.$get(
          function(res) {
            if (!angular.equals(res, {})) {
              $scope.applicationStep6 = res;
            } else {
              $scope.applicationStep6.applicationId = FederationApplicationService.getApplicationId();
              $scope.applicationStep6.cfcCode = FederationApplicationService.getCfcCode();
              $scope.applicationStep6.pledgeYear = FederationApplicationService.getPledgeYear();
            }
            resolve($scope.applicationStep6);
            if ($scope.applicationStep6.documentEndDate) {
              var localDate = new Date($scope.applicationStep6.documentEndDate);
              var localTime = localDate.getTime();
              var localOffset = localDate.getTimezoneOffset() * 60000;
              $scope.mydate = new Date(localTime + localOffset);
              $scope.applicationStep6.documentEndDate = $filter('date')(
                new Date($scope.mydate),
                'MM/dd/yyyy'
              );
            }
          },
          function(err) {
            $log.error('STEP6 failed to load');
            reject(err);
          }
        );
      }).then(function() {
        $scope.applicationStep6.charityEin =
          $scope.charityDashboardData.einNumber;
      });

      if ($scope.pledgeYear && $scope.cfcCode) {
        if (
          !$scope.isZoneManager &&
          userOb.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          $scope.applicationStep6.charityListingViewObject = angular.copy(
            $scope.charityListingObject
          );
        }
      }

      //step 7
      $scope.applicationStep7.applicationId = $scope.applicationId;
      $scope.applicationStep7.cfcCode = $scope.cfcCode;
      $scope.applicationStep7.pledgeYear = tokenData.pledgeYear;
      $q(function(resolve, reject) {
        $scope.applicationStep7.$get(
          function(step7) {
            if (!angular.equals(step7, {})) {
              $scope.applicationStep7 = step7;
              if (!step7.changedFileName) {
                return;
              }
              if (
                step7.changedFileName != null ||
                step7.changedFileName != undefined
              ) {
                var us = step7.changedFileName.lastIndexOf('_');
                $scope.currentFileVersion = parseInt(
                  step7.changedFileName
                    .substring(us, step7.changedFileName.length)
                    .replace('_ver', '')
                    .replace(/\.[^\.]+$/, '')
                );
              }
            } else {
              $scope.applicationStep7.applicationId = $scope.applicationId;
              $scope.applicationStep7.cfcCode = $scope.cfcCode;
              $scope.applicationStep7.pledgeYear = $scope.pledgeYear;
            }
            if ($scope.applicationStep7.signed === true) {
              $scope.applicationStep7.signed = 'Yes';
            } else if ($scope.applicationStep7.signed === false) {
              $scope.applicationStep7.signed = 'No';
            } else {
              $scope.applicationStep7.signed = null;
            }
            if ($scope.applicationStep7.startDate != null) {
              var localDate = new Date($scope.applicationStep7.startDate);
              var localTime = localDate.getTime();
              var localOffset = localDate.getTimezoneOffset() * 60000;
              $scope.mydate = new Date(localTime + localOffset);
              $scope.applicationStep7.startDate = $filter('date')(
                new Date($scope.mydate),
                'MM/dd/yyyy'
              );
            }
            if ($scope.applicationStep7.endDate != null) {
              var localDate1 = new Date($scope.applicationStep7.endDate);
              var localTime1 = localDate1.getTime();
              var localOffset1 = localDate1.getTimezoneOffset() * 60000;
              $scope.mydate1 = new Date(localTime1 + localOffset1);
              $scope.applicationStep7.endDate = $filter('date')(
                new Date($scope.mydate1),
                'MM/dd/yyyy'
              );
            }
            if ($scope.applicationStep7.accountingMethod === null) {
              $scope.applicationStep7.accountingMethod = '--';
            }
            resolve($scope.applicationStep7);
          },
          function(err) {
            $log.error('STEP7 failed to load');
            reject(err);
          }
        );
      }).then(function() {
        $scope.applicationStep7.charityEin =
          $scope.charityDashboardData.einNumber;
      });
      if ($scope.pledgeYear && $scope.cfcCode) {
        if (
          !$scope.isZoneManager &&
          userOb.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          $scope.applicationStep7.charityListingViewObject = angular.copy(
            $scope.charityListingObject
          );
        }
      }

      //step 8
      $scope.applicationStep8.applicationId = $scope.applicationId;
      $scope.applicationStep8.cfcCode = $scope.cfcCode;
      $scope.applicationStep8.$get(
        function(step8) {
          if (!angular.equals(step8, {})) {
            $scope.applicationStep8 = step8;
            if (!step8.changedFileName) {
              return;
            }
            var us = $scope.applicationStep8.changedFileName.lastIndexOf('_');
            $scope.currentFileVersion = parseInt(
              $scope.applicationStep8.changedFileName
                .substring(us, $scope.applicationStep8.changedFileName.length)
                .replace('_ver', '')
                .replace(/\.[^\.]+$/, '')
            );
          } else {
            $scope.applicationStep8.applicationId = FederationApplicationService.getApplicationId();
            $scope.applicationStep8.cfcCode = FederationApplicationService.getCfcCode();
          }
        },
        function() {
          $log.error('STEP8 failed to load');
        }
      );

      //step 9
      $scope.applicationStep9.applicationId = $scope.applicationId;
      $scope.applicationStep9.cfcCode = $scope.cfcCode;
      $scope.applicationStep9.fundraisingRate = 0;
      $scope.applicationStep9.$get(
        function(res) {
          if (!angular.equals(res, {})) {
            $scope.applicationStep9 = res;
          } else {
            $scope.applicationStep9.applicationId = FederationApplicationService.getApplicationId();
            $scope.applicationStep9.cfcCode = FederationApplicationService.getCfcCode();
            $scope.applicationStep9.fundraisingRate = 0;
          }
        },
        function() {
          $log.error('STEP9 failed to load');
        }
      );

      //step 10
      $scope.applicationStep10.applicationId = $scope.applicationId;
      $scope.applicationStep10.cfcCode = $scope.cfcCode;
      $scope.applicationStep10.$get(
        function(res) {
          if (!angular.equals(res, {})) {
            $scope.applicationStep10 = res;
          } else {
            $scope.applicationStep10.applicationId = FederationApplicationService.getApplicationId();
            $scope.applicationStep10.cfcCode = FederationApplicationService.getCfcCode();
          }
        },
        function() {
          $log.error('STEP10 failed to load');
        }
      );

      $scope.downloadPDFAndDocuments = function(isFed) {
        window.print();
        var downloadURL =
          '/CfcCharity/api/public/downloadappanddocuments/' +
          tokenData.applicationId +
          '/' +
          isFed +
          '/' +
          $scope.campaignId +
          '/' +
          tokenData.cfcCode;
        DownloadService.downloadFile(downloadURL);
      };

      function init() {
        $scope.appLabel = {};
        CharityService.findCharityWithCFC(tokenData.cfcCode).then(function(
          resp
        ) {
          var charity = resp.data;
          $scope.charityInformation = charity; //Information
          $scope.appLabel.charityName = charity.charityName;
          $scope.appLabel.appYear = tokenData.pledgeYear;
          $scope.appLabel.appType = tokenData.appType;
          $scope.appLabel.cfcCode = tokenData.cfcCode;
        });
        CharityDashboardService.getCharityDashboardData($scope.cfcCode).then(
          function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.charityDashboardData = result;
            }
          }
        );
        CharityDashboardService.getCharityListingObject(
          $scope.pledgeYear,
          $scope.cfcCode
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            $scope.charityListingObject = result;
            $scope.campaignId = result.campaignId;

            $scope.charityListingViewObject = angular.copy(
              $scope.charityListingObject
            );
          }
        });
      }
      init();
    }
  ])
  .controller('FederationApplicationReviewCtrl', [
    '$state',
    '$scope',
    'MODULE_NAME',
    'FederationApplicationService',
    'CharityService',
    function(
      $state,
      $scope,
      MODULE_NAME,
      FederationApplicationService,
      CharityService
    ) {
      $scope.setModuleName('CfcCharity.federation_application');
      $scope.federationNavbar.pledgeYear = FederationApplicationService.getPledgeYear();

      if (null == FederationApplicationService.getCfcCode()) {
        FederationApplicationService.setCfcCode(
          FederationApplicationService.getUserSession().cfcCode
        );
      }
      $scope.federationNavbar.cfcCode = FederationApplicationService.getCfcCode();
      $scope.setFederationAppStep(0);
      var applicationId = $state.params.applicationId;
      var mode = $state.params.mode;
      $scope.mode = mode;
      applicationId =
        applicationId === ''
          ? FederationApplicationService.getApplicationId()
          : applicationId;
      CharityService.findCharityWithCFC(
        FederationApplicationService.getCfcCode()
      ).then(function(res) {
        var charity = res.data;
        $scope.federationNavbar.charityName = charity.charityName;
      });
      FederationApplicationService.getFederationStatus(applicationId, function(
        data
      ) {
        $scope.applicationStatus = data;
      });
      $scope.showFederationStepPage = function(stepNo, applicationId, mode) {
        $state.go('federationStep' + stepNo, {
          mode: mode
        });
      };
      $scope.gotoFederationDashboard = function() {
        var applicationId = FederationApplicationService.getApplicationId();
        FederationApplicationService.reset();
        $state.go('federationDashboard', {
          federationId: applicationId
        });
      };
      $scope.gotoFederationPackage = function() {
        var pledgeYear = FederationApplicationService.getPledgeYear();
        var cfcCode = FederationApplicationService.getCfcCode();
        var fedAppId = FederationApplicationService.getApplicationId();
        FederationApplicationService.reset();
        $state.go('federationapplication', {
          year: pledgeYear,
          cfcCode: cfcCode,
          fedAppId: fedAppId
        });
      };
    }
  ])
  .directive('fileModel', [
    '$parse',
    function($parse) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          var model = $parse(attrs.fileModel);
          var modelSetter = model.assign;
          element.bind('change', function() {
            scope.$apply(function() {
              modelSetter(scope, element[0].files[0]);
            });
          });
        }
      };
    }
  ]);
