// appeal.controller.js - Controller for the Admin Appeal Dashboard page

'use strict';

(function() {
  angular
    .module('CfcCharity.admin.review.appeal')
    .controller('AdminAppealCtl', AdminAppealCtl);

  AdminAppealCtl.$inject = [
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$window',
    'parameterData',
    'AdminAppealService',
    'DeterminationLevels',
    'ApplicationWrapperService',
    'AppealService',
    'BrowserEventService',
    'blockUIConfig',
    'blockUI',
    'ZoneCenterService',
    '$http',
    'FederationApplicationReviewService',
    'ApplicationDashboardService',
    'ApplicationService',
    'CharityService',
    'FederationDashboardService',
    'FederationApplicationService',
    '$scope',
    'ValidationUtil',
    'CubeSvc'
  ];

  function AdminAppealCtl(
    $state,
    $stateParams,
    $log,
    $q,
    $window,
    parameterData,
    AdminAppealService,
    DeterminationLevels,
    ApplicationWrapperService,
    AppealService,
    BrowserEventService,
    blockUIConfig,
    blockUI,
    ZoneCenterService,
    $http,
    FederationApplicationReviewService,
    ApplicationDashboardService,
    ApplicationService,
    CharityService,
    FederationDashboardService,
    FederationApplicationService,
    $scope,
    ValidationUtil,
    CubeSvc
  ) {
    var vm = this;
    // determination levels may come back as little as an empty array, reconcile with expected results
    function resolveDeterminationLevels(raw) {
      var rawAr = raw || [];
      var determinationLevelAr = [];
      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));

      var foundCfcSpecialist = rawAr.find(function(el) {
        return DeterminationLevels.CFCSpecialist === el.level;
      });
      if (undefined === foundCfcSpecialist) {
        determinationLevelAr.push({
          level: DeterminationLevels.CFCSpecialist,
          determination: 'NOT_SET',
          determinationSetBy: null,
          determinationSetDate: null,
          displayOrder: 1
        });
      } else {
        var ob = {};
        angular.copy(foundCfcSpecialist, ob);
        ob.displayOrder = 1;
        determinationLevelAr.push(ob);
      }

      var foundCfcDirector = rawAr.find(function(el) {
        return DeterminationLevels.CFCDirector === el.level;
      });
      if (undefined === foundCfcDirector) {
        determinationLevelAr.push({
          level: DeterminationLevels.CFCDirector,
          determination: 'NOT_SET',
          determinationSetBy: null,
          determinationSetDate: null
        });
      } else {
        var ob = {};
        angular.copy(foundCfcDirector, ob);
        ob.displayOrder = 2;
        determinationLevelAr.push(ob);
      }

      var foundOpmDirector = rawAr.find(function(el) {
        return DeterminationLevels.OPMDirector === el.level;
      });
      if (undefined === foundOpmDirector) {
        determinationLevelAr.push({
          level: DeterminationLevels.OPMDirector,
          determination: 'NOT_SET',
          determinationSetBy: null,
          determinationSetDate: null
        });
      } else {
        var ob = {};
        angular.copy(foundOpmDirector, ob);
        ob.displayOrder = 3;
        determinationLevelAr.push(ob);
      }

      return determinationLevelAr;
    }

    $scope.hasWarningNoteAccess = false;
    var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
    if (
      userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
      userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
      userOb.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
      userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
      userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0
    ) {
      $scope.hasWarningNoteAccess = true;
    }

    function transformApplicationStatusInfo(raw) {
      var ob = {};
      if (raw.status != null) {
        var status = raw.status.toUpperCase();
        switch (status) {
          case 'PENDING':
            status = 'DENIED';
            break;
          case 'IN_REVIEW':
            status = 'IN_APPEAL';
            break;
        }
      }
      ob.status = status;
      ob.lastUpdatedBy = raw.lastUpdatedBy;
      ob.lastUpdatedDate = raw.lastUpdatedDate;
      vm.cfcCode = raw.cfcCode;
      return ob;
    }

    function getApplicationData() {
      if (!vm.appealId) {
        return;
      }
      return AdminAppealService.getApplicationStatus(vm.appealId)
        .then(function(res) {
          var data = res.data;
          vm.applicationInfo = transformApplicationStatusInfo(data);
        })
        .catch(function(er) {
          $log.error(er);
        });
    }

    // breaks up the init calls, phase 1
    function initPhase1() {
      // appeal status
      return AdminAppealService.getAppealStatus(
        vm.applicationId,
        vm.applicationType
      )
        .then(function(res) {
          var data = res.data;
          vm.appealId = data.appealId;
          vm.appealStatus = data;
        })
        .catch(function(er) {
          throw new Error('Error getting Appeal: ' + er.statusText);
        });
    }

    // init phase 2 relies on some info from phase 1
    function initPhase2() {
      var pDetermination = null;
      if (vm.applicationId && vm.applicationType) {
        // determinations by level
        pDetermination = AdminAppealService.getAppealDeterminationsByLevel(
          vm.applicationId,
          vm.applicationType
        )
          .then(function(res) {
            var data = res.data;
            // build out determination levels
            vm.determinationLevels = resolveDeterminationLevels(data);
          })
          .catch(function(er) {
            $log.error(er);
          });
      }

      // appeal determination history
      var pAppealDeterminationHistory = null;
      if (vm.appealId) {
        pAppealDeterminationHistory = AdminAppealService.getAppealDeterminationHistory(
          vm.appealId
        )
          .then(function(res) {
            var data = res.data;
            vm.appealDeterminationHistory = data;
          })
          .catch(function(er) {
            $log.error(er);
          });
      }

      // reviewer comments
      var pReviewerComments = null;
      if (vm.applicationId && vm.applicationType) {
        pReviewerComments = AdminAppealService.getReviewerComments(
          vm.applicationId,
          vm.applicationType
        )
          .then(function(data) {
            // already transformed by this service method as a wrapper
            vm.reviewerComments = data;
          })
          .catch(function(er) {
            $log.error(er);
          });
      }

      // appeal signature history
      var pSignHistory = null;
      if (vm.appealId && vm.cfcCode) {
        pSignHistory = AppealService.getSignAppealHistory(
          vm.appealId,
          vm.cfcCode
        )
          .then(function(res) {
            vm.signatureHistory = res.data;
          })
          .catch(function(er) {
            $log.error(er);
          });
      }

      // due date change history
      var pDueDateHistory = null;
      if (vm.appealId && vm.cfcCode) {
        pDueDateHistory = AdminAppealService.getDueDateHistory(
          vm.appealId,
          vm.cfcCode
        )
          .then(function(res) {
            vm.dueDateHistory = res.data.value;
          })
          .catch(function(er) {
            $log.error(er);
          });
      }

      // appeal status change history
      var pAppealStatusChangeHistory = null;
      if (vm.appealId) {
        pAppealStatusChangeHistory = AdminAppealService.getAppealStatusChangeHistory(
          vm.appealId
        )
          .then(function(res) {
            vm.appealStatusChangeHistory = res.data;
          })
          .catch(function(er) {});
      }
      return $q.all([
        pDetermination,
        pAppealDeterminationHistory,
        pReviewerComments,
        pSignHistory,
        pDueDateHistory,
        pAppealStatusChangeHistory
      ]);
    }

    function computeAppealAdminDashboardEditRights() {
      var yn = false;
      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      yn =
        userOb.secondaryRoleList.includes('OPM_ADMIN') ||
        userOb.secondaryRoleList.includes('OPM_STAFF');
      return yn;
    }

    function forceClearBackdrop() {
      blockUI.stop();
      $('.modal-backdrop').hide();
    }

    $scope.viewPopup = function(popupId) {
      $('#' + popupId).modal();
    };

    $scope.onChangedenialOverturnedWithWarning = function() {
      if (!vm.application) {
        return;
      }
      if (vm.application.denialOverturnedWithWarning) {
        angular.element('#addDenialExceptions').modal('show');
      } else {
        angular
          .element('#confirmDeleteDenialOverturnedWithWarningsException')
          .modal('show');
      }
    };

    $scope.denialOverturnedWithWarningModalCancel = function() {
      CubeSvc.performManualCacheRelease();
      if (!vm.isFederation) {
        ApplicationDashboardService.applicationDetailsById(vm.applicationId)
          .then(function(res) {
            vm.application = res.data.value;
          })
          .catch(function(err) {
            $log.error(err);
          });
      } else {
        FederationApplicationReviewService.getFederationDashboardData(
          vm.applicationId
        ).then(function(resp) {
          vm.application = resp.data.value;
        });
      }
      angular.element('#addDenialExceptions').modal('hide');
    };

    $scope.denialOverturnedWithWarningModalSave = function() {
      vm.application.denialOverturnedWithWarning = vm.application
        .denialOverturnedWarningNote
        ? true
        : false;
      var comment = vm.application.denialOverturnedWithWarning
        ? 'Denial overturned with warning: ' +
          vm.application.denialOverturnedWarningNote
        : 'Denial overturned with warning: flag removed';
      if (vm.isFederation) {
        FederationApplicationReviewService.addDenialOverturnedWarningNote(
          vm.applicationId,
          comment,
          vm.application
        )
          .then(function(res) {
            //success
            vm.reviewLogs =
              res.data.value.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
          })
          .catch(function(er) {
            $log.error(er);
          });
      } else {
        ZoneCenterService.addDenialOverturnedWarningNote(
          vm.applicationId,
          comment,
          vm.application
        )
          .then(function(res) {
            //success
            vm.reviewLogs = res.data.value;
          })
          .catch(function(er) {
            $log.error(er);
          });
      }
      angular.element('#addDenialExceptions').modal('hide');
    };

    $scope.cancelDeleteDenialOverturnedWithWarningsException = function() {
      if (!vm.application) {
        return;
      }
      if (vm.isFederation) {
        FederationApplicationReviewService.getFederationDashboardData(
          vm.applicationId
        ).then(function(resp) {
          vm.application = resp.data.value;
        });
      } else {
        ApplicationDashboardService.applicationDetailsById(
          vm.applicationId
        ).then(function(res) {
          vm.application = res.data.value;
        });
      }
      angular
        .element('#confirmDeleteDenialOverturnedWithWarningsException')
        .modal('hide');
    };

    $scope.deleteDenialOverturnedWithWarningsException = function() {
      vm.application.denialOverturnedWarningNote = null;
      vm.application.denialOverturnedWithWarning = false;
      $scope.denialOverturnedWithWarningModalSave();
      angular
        .element('#confirmDeleteDenialOverturnedWithWarningsException')
        .modal('hide');
    };

    vm.changeTotalRevenue = function() {
      if (vm.applicationType !== 'FEDERATION') {
        vm.totRevenueErrors = [];
        if (!vm.tempReviewerTotalRevenue) {
          vm.review.reviewerTotalRevenue = null;
        } else if (!ValidationUtil.isNumeric(vm.tempReviewerTotalRevenue)) {
          vm.totRevenueErrors.push('Please enter a valid total revenue.');
          return;
        }

        vm.tempReviewerTotalRevenue = vm.tempReviewerTotalRevenue;
        vm.review.reviewerTotalRevenue = vm.tempReviewerTotalRevenue;
        ZoneCenterService.updateApplicationReview(
          vm.applicationId,
          'REVIEWER_TOTAL_REVENUE',
          vm.review
        ).then(
          function(res) {
            vm.review = res.data.value;
            ZoneCenterService.getApplicationReviewLogs(vm.applicationId).then(
              function(res) {
                var reviewLogs = res.data.value;
                vm.reviewLogs = reviewLogs;
              },
              function(err) {
                $log.error(err);
              }
            );
            $('#appealChangeTotalRevenue').modal('toggle');
          },
          function(err) {
            vm.totRevenueErrors.push('Error updating the Total Revenue.');
          }
        );
      } else {
        vm.overrideTotalRevenueErrors = [];
        if (!ValidationUtil.isNumeric(vm.tempReviewerTotalRevenue)) {
          vm.overrideTotalRevenueErrors.push(
            'Please enter a valid total revenue.'
          );
          return;
        }

        vm.review.reviewerTotalRevenue = vm.tempReviewerTotalRevenue;

        FederationApplicationReviewService.updateFederationTR(
          vm.applicationId,
          vm.tempReviewerTotalRevenue
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              vm.showFederationApplicationReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
              vm.review &&
              federationApplicationReviewResponse.federationApplicationReviewBean
            ) {
              vm.updateTotalRevenueErrors = [];
              vm.review.totalRevenue =
                federationApplicationReviewResponse.federationApplicationReviewBean.totalRevenue;
              vm.review.federationApplicationReviewCommentBeanList =
                federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
              $('#appealChangeTotalRevenue').modal('toggle');
            }
          })
          .catch(function(err) {
            var error =
              "Error updating the Federation Application 'Total Revenue' for the federation application id : " +
              federationApplicationId;
            vm.showFederationApplicationReviewErrorMessage(error);
          });
      }
    }; // end updateTotalRevenue

    vm.changeFundRaising = function() {
      if (vm.applicationType !== 'FEDERATION') {
        vm.overrideAFRErrors = [];
        if (!ValidationUtil.isNumeric(vm.tempReviewerFundRate)) {
          vm.overrideAFRErrors.push('Please enter a valid fundraising rate.');
          return;
        }
        vm.review.reviewerFundRate = parseFloat(
          vm.tempReviewerFundRate
        ).toFixed(1);
        ZoneCenterService.updateApplicationReview(
          vm.applicationId,
          'REVIEWER_FUND_RATE',
          vm.review
        ).then(
          function(res) {
            vm.review = res.data.value;
            ZoneCenterService.getApplicationReviewLogs(vm.applicationId).then(
              function(res) {
                vm.reviewLogs = res.data.value;
                $('#appealChangeFundRaising').modal('toggle');
              },
              function(err) {
                $log.error(err);
              }
            );
          },
          function(err) {
            $log.error(err);
            vm.fundErrors.push('Error updating the Fundraising Rate.');
          }
        );
      } else {
        vm.overrideAFRErrors = [];
        if (!$.isNumeric(vm.tempReviewerFundRate)) {
          vm.overrideAFRErrors.push('Please enter a valid fundraising rate.');
          return;
        }

        vm.review.reviewerFundRate = parseFloat(
          vm.tempReviewerFundRate
        ).toFixed(1);
        FederationApplicationReviewService.updateFederationAFR(
          vm.applicationId,
          vm.tempReviewerFundRate
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              vm.showFederationApplicationReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
              vm.review &&
              federationApplicationReviewResponse.federationApplicationReviewBean
            ) {
              vm.overrideAFRErrors = [];
              vm.reviewerFundRate =
                federationApplicationReviewResponse.federationApplicationReviewBean.fundRaisingRate;
              vm.tempReviewerFundRate = vm.reviewerFundRate;
              vm.review.federationApplicationReviewCommentBeanList =
                federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
              $('#appealChangeFundRaising').modal('toggle');
            }
          })
          .catch(function(err) {
            var error =
              "Error updating the Federation Application 'Fundraising Rate' for the federation application id : " +
              federationApplicationId;
            vm.showFederationApplicationReviewErrorMessage(error);
          });
      }
    };

    vm.deleteAFROverride = function() {
      if (vm.applicationType !== 'FEDERATION') {
        vm.fundErrors = [];
        vm.review.reviewerFundRate = null;
        vm.reviewerFundRate = null;
        vm.tempReviewerFundRate = null;
        ZoneCenterService.updateApplicationReview(
          vm.applicationId,
          'REVIEWER_FUND_RATE',
          vm.review
        ).then(
          function() {
            ZoneCenterService.getApplicationReviewLogs(vm.applicationId).then(
              function(res) {
                vm.reviewLogs = res.data.value;
              },
              function(err) {
                $log.error(err);
              }
            );
          },
          function(err) {
            $log.error(err);
          }
        );
      } else {
        FederationApplicationReviewService.updateFederationAFR(
          vm.applicationId,
          null
        ).then(
          function(res) {
            vm.overrideAFRErrors = [];
            vm.review.reviewerFundRate =
              res.data.federationApplicationReviewBean.fundRaisingRate;
            vm.review.tempReviewerFundRate = null;
            vm.review.federationApplicationReviewCommentBeanList =
              res.data.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
          },
          function(err) {
            $log.error(err);
          }
        );
      }
    };

    vm.deleteTotalRevenue = function() {
      if (vm.applicationType !== 'FEDERATION') {
        vm.totRevenueErrors = [];
        vm.review.reviewerTotalRevenue = null;
        vm.tempReviewerTotalRevenue = null;
        ZoneCenterService.updateApplicationReview(
          vm.applicationId,
          'REVIEWER_TOTAL_REVENUE',
          vm.review
        ).then(
          function(res) {
            var ureview = res.data.value;
            vm.review = ureview;
            ZoneCenterService.getApplicationReviewLogs(vm.applicationId).then(
              function(res) {
                var reviewLogs = res.data.value;
                vm.reviewLogs = reviewLogs;
              },
              function(err) {
                $log.error(err);
              }
            );
          },
          function(err) {
            vm.totRevenueErrors.push('Error updating the Total Revenue.');
          }
        );
      } else {
        vm.updateTotalRevenueErrors = [];
        vm.tempReviewerTotalRevenue = null;
        vm.review.totalRevenue = null;

        FederationApplicationReviewService.updateFederationTR(
          vm.applicationId,
          null
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              vm.showFederationApplicationReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
              vm.review &&
              federationApplicationReviewResponse.federationApplicationReviewBean
            ) {
              vm.updateTotalRevenueErrors = [];
              vm.review.reviewerTotalRevenue =
                federationApplicationReviewResponse.federationApplicationReviewBean.totalRevenue;
              vm.review.tempReviewerTotalRevenue = null;
              vm.review.federationApplicationReviewCommentBeanList =
                federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
            }
          })
          .catch(function(err) {
            var error =
              "Error updating the Federation Application 'Total Revenue' for the federation application id : " +
              federationApplicationId;
            vm.showFederationApplicationReviewErrorMessage(error);
          });
      }
    };

    vm.gotoStepPage = function() {
      var stepPage = '';
      var isFed = false;
      if (vm.applicationType !== 'FEDERATION') {
        stepPage = 'step9';
      } else {
        stepPage = 'Step8';
        isFed = true;
      }
      vm.charityAuthority = false;
      CharityService.getCharityUserRole(vm.application.cfcCode).then(function(
        res
      ) {
        var userRole = res.data;
        if (userRole) {
          vm.charityAuthority = true;
        }
        if (
          vm.charityAuthority &&
          (vm.application.status === 'IN_PROGRESS' ||
            vm.application.status === 'MORE_INFO_UNSIGNED' ||
            vm.application.applicationStatus === 'IN_PROGRESS' ||
            vm.application.applicationStatus === 'MORE_INFO_UNSIGNED')
        ) {
          gotoStepPage1(
            vm.application,
            vm.isEnrollmentAvailable,
            stepPage,
            isFed
          );
        } else if (
          vm.application.status !== 'WITHDRAWN' &&
          vm.application.status !== 'DISBANDED'
        ) {
          gotoStepPage2(vm.application, stepPage, isFed);
        }
      });
    };

    /* Go to step page 1 */
    function gotoStepPage1(
      application,
      isEnrollmentAvailable,
      stepPage,
      isFed
    ) {
      if (!isFed) {
        ApplicationService.reset();
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        if (!isEnrollmentAvailable && status !== 'MORE_INFO_UNSIGNED') {
          vm.errorMsg = 'The Charity Application Period is currently Closed';
        } else {
          vm.appNavbar.pledgeYear = pledgeYear;
          vm.appNavbar.cfcCode = cfcCode;
          vm.appNavbar.fysa = fysa;
          CharityService.findCharityWithCFC(application.cfcCode).then(function(
            res
          ) {
            var charity = res.data;
            vm.appNavbar.charityName = charity.charityName;
          });
          ApplicationService.setCfcCode(cfcCode);
          ApplicationService.setPledgeYear(pledgeYear);
          vm.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode).then(function(res) {
            var role = res.data;
            var userSession = ApplicationService.getUserSession();
            userSession.cfcCode = cfcCode;
            userSession.applicationId = applicationId;
            userSession.userRole = role.userRole;
            userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
              new Date()
            );
            userSession.$save(function(response) {
              ApplicationService.userSession = response;
            });
          });
          var currentPledgeYear = sessionStorage.getItem(
            'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
          );
          CharityService.getPreApplicationDetails(
            cfcCode,
            currentPledgeYear
          ).then(function(res) {
            var data = res.data.value;
            ApplicationService.setPreApplicationDetails(data);
            ApplicationService.editApplication(applicationId);
            $state.go(stepPage, {
              mode: 'EDIT'
            });
            $scope.setModuleName('CfcCharity.application');
          });
        }
      } else {
        FederationApplicationService.reset();
        var applicationId = application.federationId;
        var status = application.applicationStatus;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        if (!isEnrollmentAvailable && status !== 'MORE_INFO_UNSIGNED') {
          vm.error = 'The Charity Application Period is currently Closed';
        } else {
          vm.federationNavbar.pledgeYear = pledgeYear;
          vm.federationNavbar.cfcCode = cfcCode;
          CharityService.findCharityWithCFC(application.cfcCode).then(function(
            resp
          ) {
            vm.federationNavbar.charityName = resp.data.charityName;
          });
          FederationApplicationService.setPledgeYear(pledgeYear);
          FederationApplicationService.setCfcCode(cfcCode);
          vm.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode).then(function(roleResp) {
            var role = roleResp.data;
            var userSession = FederationApplicationService.getUserSession();
            userSession.cfcCode = cfcCode;
            userSession.applicationId = applicationId;
            userSession.userRole = role.userRole;
            userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
              new Date()
            );
            userSession.$save(function(response) {
              FederationApplicationService.setUserSession(response);
            });
          });
          FederationApplicationService.editFederationApplication(applicationId);
          $state.go('federation' + stepPage, { mode: 'EDIT' });
          $scope.setModuleName('CfcCharity.federation_application');
        }
      }
    }
    /* go to step page 2*/
    function gotoStepPage2(application, stepPage, isFed) {
      if (!isFed) {
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        vm.appNavbar.pledgeYear = pledgeYear;
        vm.appNavbar.cfcCode = cfcCode;
        vm.appNavbar.fysa = fysa;
        CharityService.findCharityWithCFC(application.cfcCode).then(function(
          res
        ) {
          var charity = res.data;
          vm.appNavbar.charityName = charity.charityName;
        });
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setPledgeYear(pledgeYear);
        vm.appVisibility.viewMode = 'VIEW';
        var currentPledgeYear = sessionStorage.getItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
        );
        CharityService.getPreApplicationDetails(
          cfcCode,
          currentPledgeYear
        ).then(function(res) {
          var data = res.data.value;
          ApplicationService.setPreApplicationDetails(data);
          ApplicationService.editApplication(applicationId);
          $state.go(stepPage, {
            mode: 'VIEW'
          });
          $scope.setModuleName('CfcCharity.application');
        });
      } else {
        var applicationId = application.federationId;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        vm.federationNavbar.pledgeYear = pledgeYear;
        vm.federationNavbar.cfcCode = cfcCode;
        CharityService.findCharityWithCFC(application.cfcCode).then(function(
          charityResp
        ) {
          vm.federationNavbar.charityName = charityResp.data.charityName;
        });
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        FederationApplicationService.editFederationApplication(applicationId);

        vm.appVisibility.viewMode = 'VIEW';
        $state.go('federation' + stepPage, { mode: 'VIEW' });
        $scope.setModuleName('CfcCharity.federation_application');
      }
    }

    function init() {
      vm.applicationType = parameterData.applicationType;
      vm.isFederation = 'FEDERATION' === parameterData.applicationType;
      vm.canEdit = computeAppealAdminDashboardEditRights();
      vm.applicationId = parameterData.applicationId;
      vm.appNavbar = {};
      vm.federationNavbar = {};
      vm.appVisibility = {};
      vm.appealId = null;
      vm.appealStatus = {};
      vm.determinationLevels = [];
      vm.appealDeterminationHistory = [];
      vm.applicationInfo = {};
      vm.cfcCode = null;
      vm.reviewerComments = [];
      vm.signatureHistory = [];
      vm.dueDateHistory = [];
      vm.selectedDeterminationLevelForUpdate = null;
      vm.selectedDeterminationLevelForUpdateOriginalDetermination = null;
      vm.determinationUpdateSelectionPart = null;
      vm.appealStatusUpdateTo = null;
      vm.appealStatusOriginalValue = null;
      vm.appealStatusUpdateReason = null;
      vm.appealStatusChangeHistory = [];
      vm.applicationStatusUpdateTo = null;
      vm.reviewerCommentToAdd = null;
      vm.appealDataLoadStatus = 'LOADING';
      vm.sourcePageParams = $stateParams;
      vm.campaignId = BrowserEventService.getCampaignId();
      // 'navigate' to nested state, which will populate the embedded version of the 'appeal-dashboard'
      $state
        .go('admin-appeal-dashboard.full', {
          isFederation: vm.isFederation.toString(),
          applicationId: vm.applicationId
        })
        .then(initPhase1) // now populate the remaining data for admin blocks; broken into two phases
        .then(getApplicationData) // application info/status
        .then(initPhase2) // remaining data load, since we have appeal info and application info
        .then(function() {
          // successfully called all load endpoints
          vm.appealDataLoadStatus = 'SUCCESS';
          forceClearBackdrop();
        })
        .catch(function(er) {
          $log.info('Halting load of appeal based data.');
          vm.appealDataLoadStatus = 'ERROR';
          forceClearBackdrop();
        });
    }
    if (parameterData.applicationType !== 'FEDERATION') {
      ZoneCenterService.findApplicationStep9(parameterData.applicationId).then(
        function(res) {
          if (res.data.value) {
            vm.fundraisingRate = res.data.value.fundraisingRate;
          }
        },
        function(err) {
          $log.error(err);
        }
      );
      ZoneCenterService.findApplicationReview(parameterData.applicationId).then(
        function(res) {
          var review = res.data.value;
          if (review !== null && review !== '') {
            vm.review = review;
            vm.reviewStatus = review.applicationReviewStatus;
            vm.reviewerFundRate = review.reviewerFundRate;
            vm.tempReviewerFundRate = review.reviewerFundRate;
            vm.reviewerTotalRevenue = review.reviewerTotalRevenue;
            vm.tempReviewerTotalRevenue = review.reviewerTotalRevenue;
          }
        }
      );
      ZoneCenterService.findCharityApplication9(
        parameterData.applicationId
      ).then(function(res1) {
        if (res1.data.value) {
          vm.totalRevenue = res1.data.value.totalRevenue;
        }
      });
      ZoneCenterService.getZoneDetails(parameterData.applicationId).then(
        function(res) {
          vm.cfcZone = res.data;
        }
      );
      ApplicationDashboardService.applicationDetailsById(
        parameterData.applicationId
      ).then(function(res) {
        vm.application = res.data.value;
      });
    } else {
      CubeSvc.get(
        '/CfcCharity/api/private/federation_application/step8/' +
          parameterData.applicationId,
        null,
        false
      ).then(function(res) {
        if (res.data.value) {
          vm.fundraisingRate = res.data.value.fundraisingRate;
        }
      });
      FederationApplicationReviewService.getFederationApplicationStep8(
        parameterData.applicationId
      ).then(function(res1) {
        if (res1.data.value) {
          vm.totalRevenue = res1.data.value.totalRevenue;
        }
      });
      FederationApplicationReviewService.getFederationReviewDetails(
        parameterData.applicationId
      ).then(function(res) {
        var federationApplicationReviewResponse = res.data;
        vm.federationApplicationReviewAFR = {};
        vm.federationApplicationReviewStatus = {};
        vm.federationApplicationReviewRequestForInformation = {};
        if (
          federationApplicationReviewResponse &&
          (federationApplicationReviewResponse.error ||
            federationApplicationReviewResponse.statusCode === 'ERROR')
        ) {
          vm.showFederationApplicationReviewErrorMessage(
            federationApplicationReviewResponse.errorResponse.errorCode +
              ' : ' +
              federationApplicationReviewResponse.errorResponse.errorMessage +
              '. Please try again later!'
          );
        } else if (
          federationApplicationReviewResponse &&
          federationApplicationReviewResponse.statusCode === 'SUCCESS'
        ) {
          vm.review =
            federationApplicationReviewResponse.federationApplicationReviewBean;
          vm.review.reviewerFundRate =
            federationApplicationReviewResponse.federationApplicationReviewBean.fundRaisingRate;
          vm.review.reviewerTotalRevenue =
            federationApplicationReviewResponse.federationApplicationReviewBean.totalRevenue;
          vm.review.tempReviewerFundRate =
            federationApplicationReviewResponse.federationApplicationReviewBean.fundRaisingRate;
          vm.review.tempReviewerTotalRevenue =
            federationApplicationReviewResponse.federationApplicationReviewBean.totalRevenue;
          vm.review.reviewStatus =
            federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatus;
        }
      });
      FederationApplicationReviewService.getZoneDetails(
        parameterData.applicationId
      ).then(function(res) {
        vm.cfcZone = res.data;
      });
      CubeSvc.get(
        '/CfcCharity/api/private/federation/' + parameterData.applicationId
      ).then(function(res) {
        vm.application = res.data.value;
      });
    }
    CharityService.findEnrollmentStatus().then(function(res) {
      vm.isEnrollmentAvailable = res.data;
    });
    // start determination helper functions
    function getInitialsFromUserName(userOb) {
      var initials = '';
      if (userOb && userOb.firstName && userOb.firstName.length >= 1) {
        initials = userOb.firstName.substring(0, 1).toUpperCase();
      }
      if (userOb && userOb.lastName && userOb.lastName.length >= 1) {
        initials += userOb.lastName.substring(0, 1).toUpperCase();
      }
      return initials;
    }

    vm.getDisplayDeterminationSetBy = function(user) {
      var fullName = '';
      if (null != user) {
        user.hasOwnProperty('firstName')
          ? (fullName += user.firstName + ' ')
          : null;
        user.hasOwnProperty('lastName') ? (fullName += user.lastName) : null;
        fullName += ' (' + getInitialsFromUserName(user) + ')';
      }
      return fullName;
    };

    vm.hideAppealDeterminationHistoryObject = function(histOb) {
      return (
        'NOT_SET' === histOb.determination &&
        (histOb.reason || '').toLowerCase().indexOf('initial insert') === 0
      );
    };

    // end determination helper functions

    // start determination level update functions
    vm.selectDeterminationLevelForUpdate = function(
      selectedDeterminationLevel
    ) {
      vm.selectedDeterminationLevelForUpdate = selectedDeterminationLevel;
      vm.selectedDeterminationLevelForUpdateOriginalDetermination =
        selectedDeterminationLevel.determination;
      vm.determinationUpdateSelectionPart = 1;
      $('#appealLevelStatus').modal('show');
    };

    function clearDeterminationValuesAndHideModal() {
      vm.selectedDeterminationLevelForUpdate = { determination: null };
      vm.determinationUpdateSelectionPart = null;
      vm.selectedDeterminationLevelForUpdateOriginalDetermination = null;
      vm.determinationUpdateSelectionReason = null;
      $('#appealLevelStatus').modal('hide');
    }

    vm.cancelDeterminationLevelUpdate = function() {
      vm.selectedDeterminationLevelForUpdate.determination =
        vm.selectedDeterminationLevelForUpdateOriginalDetermination;
      clearDeterminationValuesAndHideModal();
    };

    vm.showAppealDeterminationLevelUpdateSaveButton = function() {
      return (
        2 === vm.determinationUpdateSelectionPart &&
        '' !== (vm.determinationUpdateSelectionReason || '').trim()
      );
    };

    vm.updateDeterminationLevel = function() {
      var ob = vm.selectedDeterminationLevelForUpdate;
      ob.reason = vm.determinationUpdateSelectionReason;
      AdminAppealService.updateAppealDeterminationLevel(vm.appealId, ob)
        .then(function(res) {
          // returns the updated version of level, update vm.determinationLevels's corresponding object
          var data = res.data;
          updateAppealDeterminationLevel(data);
          initPhase2();
        })
        .catch(function(er) {
          $log.error(er);
          $window.alert(
            'Something went wrong with updating the appeal determination.'
          );
        });
    };

    function updateAppealDeterminationLevel(determination) {
      var foundMatch = vm.determinationLevels.find(function(el) {
        return el.level === determination.level;
      });
      if (undefined !== foundMatch) {
        foundMatch.determination = determination.determination;
        foundMatch.determinationSetBy = determination.determinationSetBy;
        foundMatch.determinationSetDate = determination.determinationSetDate;
      } else {
        $log.error(
          "couldn't resolve matching determination level to update, brute force reloading all"
        );
        AdminAppealService.getAppealDeterminationsByLevel(
          vm.applicationId,
          vm.applicationType
        )
          .then(function(res) {
            var data = res.data;
            // build out determination levels
            vm.determinationLevels = resolveDeterminationLevels(data);
          })
          .catch(function(er) {
            $log.error(er);
          });
      }
      clearDeterminationValuesAndHideModal();
    }
    // end determination level update functions

    // start appeal status functions

    vm.prepareToUpdateAppealStatus = function() {
      vm.appealStatusUpdateTo = vm.appealStatus.status;
      vm.appealStatusOriginalValue = vm.appealStatus.status;
      vm.appealStatusUpdateReason = null;
      $('#appealStatusUpdate').modal('show');
    };

    vm.cancelAppealStatusUpdate = function() {
      vm.appealStatusUpdateTo = null;
      vm.appealStatusOriginalValue = null;
      vm.appealStatusUpdateReason = null;
      $('#appealStatusUpdate').modal('hide');
    };

    vm.hideAppealStatusHistoryObject = function(histOb) {
      return 'Not Started' === histOb.appealStatus;
    };

    vm.appealStatusUpdateAllowed = function() {
      var yn = false;
      if (!!vm.appealStatusUpdateTo) {
        if (vm.appealStatusUpdateTo !== vm.appealStatusOriginalValue) {
          if (
            null !== vm.appealStatusUpdateReason &&
            (vm.appealStatusUpdateReason || '').trim() !== ''
          ) {
            yn = true;
          }
        }
      }
      return yn;
    };

    vm.updateAppealStatus = function() {
      AdminAppealService.updateAppealStatus(vm.appealId, {
        status: vm.appealStatusUpdateTo,
        reason: vm.appealStatusUpdateReason
      })
        .then(function(res) {
          $scope.$broadcast('appealStatusChangedEvent');
          var data = res.data;
          // success, refresh the appealStatus info
          vm.appealStatus.status = data.appealStatus;
          vm.appealStatus.lastModifiedDate = data.appealStatusSetDate;
          vm.appealStatus.lastModifiedBy =
            data.appealStatusSetBy.firstName +
            ' ' +
            data.appealStatusSetBy.lastName;
          vm.cancelAppealStatusUpdate();
          vm.updateApplicationStatus(data.id, data.appealStatus);
          AppealService.sendAppealStatusUpdateEmail(
            vm.cfcCode,
            data.campaignId,
            data.applicationId,
            data.applicationType,
            data.appealStatus,
            vm.application.denialOverturnedWithWarning
          );
        })
        .then(function() {
          // re-fetch appeal status history
          AdminAppealService.getAppealStatusChangeHistory(vm.appealId)
            .then(function(res) {
              vm.appealStatusChangeHistory = res.data;
            })
            .catch(function(er) {
              $log.error(er);
            });
        })
        .catch(function(er) {
          $log.error(er);
          $window.alert('There was an issue updating the appeal status.');
          vm.cancelAppealStatusUpdate();
        });
    };

    // end appeal status functions

    // start application status update functions

    vm.prepareToUpdateApplicationStatus = function() {
      vm.applicationStatusUpdateTo = vm.applicationInfo.status;
      $('#applicationStatus').modal('show');
    };

    vm.cancelUpdateApplicationStatus = function() {
      vm.applicationStatusUpdateTo = null;
      $('#applicationStatus').modal('hide');
    };

    vm.updateApplicationStatus = function(appealId, appealStatus) {
      var appealStatus = appealStatus.toUpperCase();
      switch (appealStatus) {
        case 'PENDING':
          appealStatus = 'DENIED';
          break;
        case 'IN_REVIEW':
          appealStatus = 'IN_APPEAL';
          break;
      }
      AdminAppealService.updateApplicationStatus(appealId, appealStatus)
        .then(function(res) {
          // transform already done
          var data = res.data;
          // update status fields
          vm.applicationInfo = transformApplicationStatusInfo(data);
        })
        .then(function() {
          // update reviewer comments
          AdminAppealService.getReviewerComments(
            vm.applicationId,
            vm.applicationType
          )
            .then(function(data) {
              // already transformed by this service method as a wrapper
              vm.reviewerComments = data;
            })
            .catch(function(er) {
              $log.error(er);
            })
            .finally(function() {
              $('#applicationStatus').modal('hide');
            });
        })
        .catch(function(er) {
          $log.error(er);
          $window.alert('There was an error updating the application status.');
          $('#applicationStatus').modal('hide');
        });
    };

    // end application status update functions

    // start add reviewer comment functions

    vm.prepareToAddComment = function() {
      $('#viewAllComments').modal('hide');
      vm.reviewerCommentToAdd = '';
      $('#addReviewerComment').modal('show');
    };

    vm.cancelAddComment = function() {
      vm.reviewerCommentToAdd = null;
      $scope.addReviewerCommentErrors = [];
      $('#addReviewerComment').modal('hide');
    };

    vm.performAddComment = function() {
      $scope.addReviewerCommentErrors = [];
      if (
        !vm.reviewerCommentToAdd ||
        ((vm.reviewerCommentToAdd &&
          vm.reviewerCommentToAdd.trim().length < 1) ||
          vm.reviewerCommentToAdd.length > 2000)
      ) {
        $scope.addReviewerCommentErrors.push(
          'Please enter a comment between 1 and 2000 characters.'
        );
      } else if (
        vm.reviewerCommentToAdd &&
        (vm.reviewerCommentToAdd.length > 0 &&
          vm.reviewerCommentToAdd.length <= 2000)
      ) {
        AdminAppealService.addReviewerComment(
          vm.applicationType,
          vm.applicationId,
          vm.reviewerCommentToAdd
        )
          .then(function(data) {
            // pre-filtered, not direct $http promise
            vm.reviewerComments = data;
            vm.reviewerCommentToAdd = null;
            $scope.addReviewerCommentErrors = [];
            $('#addReviewerComment').modal('hide');
          })
          .catch(function(er) {
            $log.error(er);
            $scope.addReviewerCommentErrors.push(error);
          });
      }
    };

    // end add reviewer comment functions

    vm.goToEmailDashboard = function() {
      $state.go('email-dashboard', {
        isFederation: vm.isFederation,
        applicationId: vm.applicationId,
        sourcePageName: 'admin-appeal-dashboard',
        sourcePageParams: vm.sourcePageParams,
        charityType: vm.applicationType
      });
    };

    init();
  }
})();
