(function() {
  var moduleName = 'CfcCharity.common.appealService';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist$F so create one
    module = angular.module(moduleName, []);
  }

  module.constant('AppealStatus', {
    NotStarted: 'Not Started',
    Pending: 'Pending',
    Closed: 'Closed',
    Denied: 'Denied',
    Approved: 'Approved'
  });

  module.service('AppealService', [
    '$http',
    '$q',
    '$log',
    'EmailService',
    'toastrService',
    'CubeSvc',
    function($http, $q, $log, EmailService, toastrService, CubeSvc) {
      var getApplicationAppeal = function(
        applicationType,
        applicationId,
        cfcCode
      ) {
        var url =
          '/CfcCharity/api/private/charity/appeal/' +
          applicationType +
          '/' +
          applicationId +
          '/' +
          cfcCode;
        return $http.get(url);
      };

      var getApplicationAppealStatus = function(
        applicationId,
        applicationType
      ) {
        var url =
          '/CfcCharity/api/private/charity/review/appeal/status/' +
          applicationId +
          '/' +
          applicationType;
        return $http.get(url);
      };

      var UpdateApplicationAppealStaus = function(appealId, status, reason) {
        var url =
          '/CfcCharity/api/private/charity/appeal/status/update/' + appealId;
        return $http.put(url, { status: status, reason: reason });
      };

      var toUTCDate = function(date) {
        return new Date(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        );
      };

      function getAppealDocuments(campaignId, cfcCode, appealId) {
        return $http.get(
          '/CfcCharity/api/private/charity/appeal/charityAppealDocuments/' +
            campaignId +
            '/' +
            cfcCode +
            '/' +
            appealId
        );
      }

      function getApplicationDocuments(campaignId, cfcCode, appId) {
        return $http.get(
          '/CfcCharity/api/private/charity/appeal/charityApplicationDocuments/' +
            campaignId +
            '/' +
            cfcCode +
            '/' +
            appId
        );
      }

      function UpdateAppeal(id, reason, cfcCode) {
        return $http.put(
          '/CfcCharity/api/private/charity/appeal/' + id + '/' + cfcCode,
          { appealReason: reason }
        );
      }

      function signAppeal(id, cfcCode, isWebservice, sinObj) {
        var url =
          '/CfcCharity/api/private/charity/appeal/sign/' +
          cfcCode +
          '/' +
          id +
          '/' +
          isWebservice;
        return $http.put(url, {
          certification: sinObj.certification,
          signature: sinObj.signature,
          initials: sinObj.initials
        });
      }

      function unsignAppeal(id, cfcCode) {
        var url =
          '/CfcCharity/api/private/charity/appeal/unsign/' + cfcCode + '/' + id;
        return $http.put(url, {});
      }

      function getSignAppealHistory(id, cfcCode) {
        var url =
          '/CfcCharity/api/private/charity/appeal/signature/history/' +
          cfcCode +
          '/' +
          id;
        if (!!id && !!cfcCode) {
          return $http.get(url);
        } else {
          return $q.reject(
            "Necessary parameters not supplied, received id: '" +
              id +
              "', cfcCode: '" +
              cfcCode +
              "'"
          );
        }
      }

      // takes 4-digit campaign year
      function getApplicationCertificationsByYear(campaignYear, cfcCode) {
        var url =
          '/CfcCharity/api/private/charity/application/certifications/' +
          campaignYear +
          '/' +
          cfcCode;
        return $http.get(url);
      }

      function getCharityAppealCertificationsByApplicationId(
        appId,
        appType,
        cfcCode
      ) {
        var url =
          '/CfcCharity/api/private/charity/appeal/charityCertifications/' +
          appId +
          '/' +
          cfcCode;
        return $http.get(url, { params: { applicationType: appType } });
      }

      function updateCharityAppealCertification(
        charityAppealCertificationOb,
        cfcCode
      ) {
        var url =
          '/CfcCharity/api/private/charity/appeal/charityCertifications/' +
          charityAppealCertificationOb.applicationId;
        return $http.put(url, charityAppealCertificationOb, {
          params: { cfcCode: cfcCode }
        });
      }

      function updateCharityToPendingAndPopulateMissingCerertifications(
        appId,
        appType,
        cfcCode
      ) {
        var url =
          '/CfcCharity/api/private/charity/appeal/updateToPending/' + cfcCode;
        return $http.post(url, {
          applicationId: appId,
          applicationType: appType
        });
      }

      function saveDocumentSignature(
        cfcCode,
        appealId,
        appealDocumentId,
        appealDocumentSignature
      ) {
        var url =
          '/CfcCharity/api/private/charity/appealDocument/sign/' +
          cfcCode +
          '/' +
          appealId +
          '/' +
          appealDocumentId;
        return CubeSvc.put(url, appealDocumentSignature);
      }

      function getAppealDocumentSignature(cfcCode, appealId, appealDocumentId) {
        var url =
          '/CfcCharity/api/private/charity/appealDocument/signatureDetails/' +
          cfcCode +
          '/' +
          appealId +
          '/' +
          appealDocumentId;
        return CubeSvc.get(url, null, false);
      }

      function sendAppealStatusUpdateEmail(
        cfcCode,
        campaignId,
        applicationId,
        applicationType,
        appealStatus,
        isDenialOverturnedWithWarning,
        toastrFlag = true
      ) {
        var displayText =
          appealStatus === 'APPROVED'
            ? 'Denial Overturned'
            : appealStatus === 'DENIED'
            ? 'Denial Sustained'
            : null;
        if (!displayText) {
          return;
        }
        if (
          displayText === 'Denial Overturned' &&
          isDenialOverturnedWithWarning
        ) {
          displayText = 'Denial Overturned With Warning';
        }
        EmailService.getEmailTemplates(campaignId)
          .then(function(resp) {
            return resp.data.filter(function(item) {
              return item.displayText === displayText;
            })[0];
          })
          .then(function(template) {
            EmailService.generateEmail(
              cfcCode,
              campaignId,
              applicationId,
              applicationType,
              template.templateTypeId
            ).then(
              function(res) {
                const emailId = res.data.id;
                EmailService.getEmail(emailId).then(function(res) {
                  EmailService.sendEmail(res.data)
                    .then(function() {
                      // notify user success
                      if (toastrFlag) {
                        toastrService.success(
                          displayText + ' email sent successfully.'
                        );
                      }
                    })
                    .catch(function() {
                      // notify user unsuccessfull
                      if (toastrFlag) {
                        toastrService.error(
                          displayText + ' email was not sent successfully.'
                        );
                      }
                    });
                });
              },
              function(error) {
                $log.error(error.data.errorMessage);
              }
            );
          });
      }

      return {
        getApplicationAppeal: getApplicationAppeal,
        toUTCDate: toUTCDate,
        getAppealDocuments: getAppealDocuments,
        getApplicationDocuments: getApplicationDocuments,
        UpdateAppeal: UpdateAppeal,
        signAppeal: signAppeal,
        unsignAppeal: unsignAppeal,
        getSignAppealHistory: getSignAppealHistory,
        getApplicationCertificationsByYear: getApplicationCertificationsByYear,
        getCharityAppealCertificationsByApplicationId: getCharityAppealCertificationsByApplicationId,
        updateCharityAppealCertification: updateCharityAppealCertification,
        updateCharityToPendingAndPopulateMissingCerertifications: updateCharityToPendingAndPopulateMissingCerertifications,
        getApplicationAppealStatus: getApplicationAppealStatus,
        UpdateApplicationAppealStaus: UpdateApplicationAppealStaus,
        sendAppealStatusUpdateEmail: sendAppealStatusUpdateEmail,
        saveDocumentSignature: saveDocumentSignature,
        getAppealDocumentSignature: getAppealDocumentSignature
      };
    }
  ]);
})();
