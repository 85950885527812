// common.module.js - Module declaration for Common functionality

'use strict';

angular
  .module('CfcCharity.common', [])
  .config(['$stateProvider', config])
  .directive('cfcNotification', function() {
    return {
      template:
        "<div id='cfcNotification' class='alert alert-{{alertData.type}} fade in' ng-show='alertData.message' role='alert' data-notification='{{alertData.status}}'> <a href='' class='close' data-ng-click='alertData.message = null' aria-label='close'>&times;</a>{{alertData.message}}</div>",
      restrict: 'E',
      scope: {
        alertData: '='
      }
    };
  })
  .directive('cfcApplicationUploadExceptionModal', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: false,
      controller: function($scope) {
        $scope.f = function() {
          $scope.continue = true;
          $scope.saveApplicationStep();
        };
      },
      template:
        '<div class="modal fade" id="modalUpload" tabindex="-1" role="dialog" aria-labelledby="modalUpload">\
      <div class="modal-dialog" role="document">\
              <div class="modal-content">\
                  <div class="modal-header">\
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>\
                      <h4 class="modal-title">YOUR FILE COULD NOT BE UPLOADED</h4>\
                  </div>\
                  <div class="modal-body">\
                      <h4>\
                      There is a problem with your file. Your file may be corrupted or be password protected. Please check and fix your file and upload it again.\
                      </h4>\
                  </div>\
                  <div class="modal-footer">\
                      <button type="button" class="btn btn-info" data-dismiss="modal">Go Back</button>\
              </div>\
          </div>\
      </div>'
    };
  })
  .directive('cfcAppLabel', function() {
    return {
      restrict: 'E',
      scope: { data: '=' },
      controller: function($scope, $state) {
        $scope.goToCharityDashboard = function(cfcCode) {
          $state.go('charitydashboard', {
            charityDashboardCfcCode: cfcCode
          });
        };
      },
      template:
        '<h2>{{data.appYear}} {{data.appType}} Application</h2><a data-ng-click="goToCharityDashboard(data.cfcCode)" href="" data-ng-show="data.charityName"><h4 class="app-label-h4 lead">{{data.charityName}}, CFC #{{data.cfcCode}}</h4></a><hr>'
    };
  })
  .directive('cfcReviewDashboardLabel', function() {
    return {
      restrict: 'E',
      scope: { data: '=' },
      controller: function($scope, $state, $http, CubeSvc) {
        $scope.labelData = {
          charityName: '',
          cfcCode: '',
          pledgeYear: '',
          applicationId: $scope.data,
          orgType: '',
          charityType: '',
          fysaFyspWfr: ''
        };
        function init() {
          CubeSvc.get(
            '/CfcCharity/api/private/application/details/' + $scope.data
          ).then(function(res) {
            var response = res.data.value;
            $scope.labelData.charityName = response.name;
            $scope.labelData.cfcCode = response.cfcCode;
            $scope.labelData.pledgeYear = response.pledgeYear;
            $scope.labelData.fysaFyspWfr = response.fysaFyspWfr;
            CubeSvc.get(
              '/CfcCharity/api/private/charity/findCurrentCharityType?cfcCode=' +
                $scope.labelData.cfcCode +
                '&pledgeYear=' +
                $scope.labelData.pledgeYear
            ).then(function(res) {
              var response = res.data.value;
              $scope.labelData.orgType = response.applicationType;
              $scope.labelData.charityType = response.charityType;
              if ($scope.labelData.fysaFyspWfr) {
                $scope.labelData.charityType += ' FSYA/FSYP/MWR';
              }
            });
          });
        }
        init();
        $scope.goToCharityDashboard = function(cfcCode) {
          $state.go('charitydashboard', {
            charityDashboardCfcCode: cfcCode
          });
        };
        $scope.goToApplication = function(applicationId) {
          $state.go('applicationDashboard', {
            applicationId: applicationId,
            fromPage: 'CHARITY_REVIEW_PAGE'
          });
        };

        $scope.showGoToApp = function() {
          return $scope.labelData.applicationId && $scope.labelData.orgType;
        };
      },
      template:
        '<p data-ng-show="labelData.orgType" class="lead" style="margin-bottom:0">\
            <a data-ng-click="goToCharityDashboard(labelData.cfcCode)" href="">{{labelData.charityName}} - CFC #{{labelData.cfcCode}}</a>\
        </p>\
        <p data-ng-show="showGoToApp()" style="margin-top:0">\
            <a data-ng-click="goToApplication(labelData.applicationId)" href="">{{labelData.pledgeYear}} {{labelData.charityType}} {{labelData.orgType}} Application</a>\
        </p>'
    };
  })
  .directive('cfcFedReviewDashboardLabel', function() {
    return {
      restrict: 'E',
      scope: { data: '=' },
      controller: function($scope, $state, $http, CubeSvc) {
        $scope.labelData = {
          charityName: '',
          cfcCode: '',
          pledgeYear: '',
          applicationId: $scope.data,
          orgType: '',
          charityType: ''
        };
        function init() {
          CubeSvc.get('/CfcCharity/api/private/federation/' + $scope.data).then(
            function(res) {
              var respValue = res.data.value;
              $scope.labelData.charityName = respValue.federationName;
              $scope.labelData.cfcCode = respValue.cfcCode;
              $scope.labelData.pledgeYear = respValue.pledgeYear;
              $scope.labelData.fysaFyspWfr = respValue.fysaFyspWfr;
              $scope.federationId = respValue.federationId;
              CubeSvc.get(
                '/CfcCharity/api/private/charity/findCurrentCharityType?cfcCode=' +
                  $scope.labelData.cfcCode +
                  '&pledgeYear=' +
                  $scope.labelData.pledgeYear
              ).then(function(res) {
                var response = res.data.value;
                $scope.labelData.charityType = response.charityType;
                $scope.labelData.orgType = response.applicationType;
              });
            }
          );
        }
        init();
        $scope.goToCharityDashboard = function(cfcCode) {
          $state.go('charitydashboard', {
            charityDashboardCfcCode: cfcCode
          });
        };
        $scope.goToApplication = function(applicationId) {
          $state.go('applicationDashboard', {
            applicationId: applicationId,
            fromPage: 'CHARITY_REVIEW_PAGE'
          });
        };
        $scope.goToFedApplication = function(year, cfcCode, applicationId, e) {
          e.preventDefault();
          $state.go('federationapplication', {
            year: year,
            cfcCode: cfcCode,
            fedAppId: applicationId
          });
        };
        $scope.showGoToApp = function() {
          return (
            $scope.labelData.pledgeYear &&
            $scope.labelData.cfcCode &&
            $scope.labelData.orgType
          );
        };
      },
      template:
        '<p data-ng-show="labelData.orgType" class="lead" style="margin-bottom:0">\
            <a data-ng-click="goToCharityDashboard(labelData.cfcCode)" href="">{{labelData.charityName}} - CFC #{{labelData.cfcCode}}</a>\
        </p>\
        <p data-ng-show="showGoToApp()" style="margin-top:0">\
            <a data-ng-click="goToFedApplication(labelData.pledgeYear, labelData.cfcCode, labelData.applicationId, $event)" href="#">{{labelData.pledgeYear}} {{labelData.charityType}} {{labelData.orgType}} Application</a>\
        </p>'
    };
  });

function config($stateProvider) {
  // Placeholder for any future common module config
}
