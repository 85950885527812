// common user service

(function() {
  angular.module('CfcCharity.common').service('UserService', UserService);

  UserService.$inject = ['$log', 'USER_ROLES', 'CharityService'];

  function UserService($log, USER_ROLES, CharityService) {
    var user = {};

    return {
      setUser: setUser,
      getUser: getUser,
      isCharityUser: isCharityUser,
      isSystemUser: isSystemUser,
      hasSystemRole: hasSystemRole,
      getCharityRole: getCharityRole
    };

    function setUser(userOb) {
      user = userOb;
    }

    function getUser() {
      if (angular.equals(user, {})) {
        user = JSON.parse(sessionStorage.getItem('USER_OBJ')) || {};
      }
      return user;
    }

    function isCharityUser() {
      var u = getUser();
      return u.roles.includes(USER_ROLES.charity_user);
    }

    function isSystemUser() {
      var u = getUser();
      return u.roles.includes(USER_ROLES.system_user);
    }

    /*
     * takes single string of role to match, or array of possible matches (returns true if one does match)
     */
    function hasSystemRole(roleAr) {
      var yn = false;
      var u = getUser();
      var rolesAr = [];
      if (!Array.isArray(roleAr)) {
        rolesAr.push(roleAr);
      } else {
        rolesAr = roleAr;
      }
      if (
        u.hasOwnProperty('secondaryRoleList') &&
        Array.isArray(u.secondaryRoleList)
      ) {
        angular.forEach(rolesAr, function(val) {
          if (u.secondaryRoleList.includes(val)) {
            yn = true;
          }
        });
      }
      return yn;
    }

    function getCharityRole(cfcCode) {
      CharityService.getCharityUserRole(cfcCode)
        .then(function(res) {
          var userRole = res.data;
          if (userRole) {
            return userRole;
          } else {
            // user has no charity role
            return null;
          }
        })
        .catch(function(err) {
          $log.error('Error getting charity user role: ', err);
        });
    }
  }
})();
