angular
  .module('CfcCharity.federationapplicationthankyou', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('federationapplicationthankyou', {
        url: '/federationapplicationthankyou',
        views: {
          main: {
            template: require('../views/private/federation/federationapplicationthankyou.html'),
            controller: 'FederationApplicationThankYouCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Application Thank You',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          cfcCode: null,
          userId: null,
          applicationId: null,
          paymentid: null,
          paymenttype: null,
          paymentfeeType: null,
          paymentMethod: null,
          paymentyear: null
        },
        resolve: {
          federationApplicationData: function($stateParams) {
            var federationAppData = null;
            if ($stateParams.applicationId != null) {
              var fedApplicationId = $stateParams.applicationId;
              fedAppData = {
                cfcCode: $stateParams.cfcCode,
                userId: $stateParams.userId,
                applicationId: fedApplicationId,
                paymentid: $stateParams.paymentid,
                paymenttype: $stateParams.paymenttype,
                paymentfeeType: $stateParams.paymentfeeType,
                paymentMethod: $stateParams.paymentMethod,
                paymentyear: $stateParams.paymentyear
              };
            }
            return fedAppData;
          }
        }
      });
    }
  ])
  .controller('FederationApplicationThankYouCtrl', [
    '$state',
    '$scope',
    '$stateParams',
    'CubeSvc',
    'federationApplicationData',
    function($state, $scope, $stateParams, CubeSvc, federationApplicationData) {
      $scope.setModuleName('CfcCharity.federationapplicationthankyou');
      $scope.federationApplicationData = federationApplicationData;
      $scope.continueToNext = function() {
        CubeSvc.get(
          '/CfcCharity/api/private/federation/' + $stateParams.applicationId,
          null,
          false
        )
          .then(function(response) {
            $state.go('federationapplication', {
              userId: $stateParams.userId,
              year: response.data.value.pledgeYear,
              cfcCode: $stateParams.cfcCode
            });
          })
          .catch(function() {
            $state.go('federationapplication');
          });
      };
    }
  ]);
