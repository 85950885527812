(function() {
  var moduleName = 'CfcCharity.directives.adminReviewStatus';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcAdminReviewStatus', [
    '$location',
    '$anchorScroll',
    'BreadcrumbTokenDataService',
    'APPLICATION_TYPE',
    function(
      $location,
      $anchorScroll,
      BreadcrumbTokenDataService,
      APPLICATION_TYPE
    ) {
      return {
        restrict: 'E',
        template: require('./adminReviewStatus.html'),
        scope: {},
        controller: function($scope, $rootScope) {
          function init() {
            $rootScope.$on('adminReviewLevelStatus', function(event, res) {
              $scope.currentReview = res;
              $scope.isFederation =
                BreadcrumbTokenDataService.appType ===
                APPLICATION_TYPE.federation;
            });
          }
          $scope.scrollTo = function(id) {
            var old = $location.hash();
            $location.hash(id);
            $anchorScroll();
            $location.hash(old);
          };
          init();
        }
      };
    }
  ]);
})();
