'use strict';
(function() {
  angular
    .module('CfcCharity.admin.zone.appeals', [])
    .config([
      '$stateProvider',
      'USER_ROLES',
      'SYSTEM_USER_SECONDARY_ROLES',
      config
    ])
    .controller('ZoneAppealsController', ZoneAppealsController);

  function config($stateProvider, USER_ROLES, SYSTEM_USER_SECONDARY_ROLES) {
    $stateProvider.state('zone-appeals', {
      url: '/zone-appeals',
      views: {
        main: {
          template: require('./zone-appeals.html'),
          controller: 'ZoneAppealsController'
        }
      },
      data: {
        pageTitle: 'Zone Appeals',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.opm_admin],
        breadcrumbs: [
          {
            crumbName: 'Zone %zoneCode%',
            crumbRoute: 'zoneCenter',
            crumbParams: '{zoneId: %zoneId%}'
          },
          {
            crumbName: 'Applications',
            crumbRoute: 'zoneApplications',
            crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
          }
        ]
      },
      params: {
        zoneId: null,
        pledgeYear: null,
        zoneName: null,
        zoneCode: null
      },
      resolve: {
        parameterData: function($stateParams) {
          return {
            zoneId: $stateParams.zoneId,
            pledgeYear: $stateParams.pledgeYear,
            zoneName: $stateParams.zoneName,
            zoneCode: $stateParams.zoneCode
          };
        }
      }
    });
  }

  ZoneAppealsController.$inject = [
    '$log',
    '$scope',
    'blockUI',
    'parameterData',
    '$state',
    'ZoneAppealsService',
    'toastrService',
    'ZoneCenterService'
  ];

  function ZoneAppealsController(
    $log,
    $scope,
    blockUI,
    parameterData,
    $state,
    ZoneAppealsService,
    toastrService,
    ZoneCenterService
  ) {
    function init() {
      $scope.appeals = [];
      $scope.zoneId = parameterData.zoneId;
      $scope.pledgeYear = parameterData.pledgeYear;
      $scope.zoneName = parameterData.zoneName;
      $scope.zoneCode = parameterData.zoneCode;
      $scope.filter = {
        applicationType: undefined,
        appealStatus: undefined
      };
      getApplicationsInAppeal($scope.zoneId, $scope.pledgeYear);
    }

    function getApplicationsInAppeal(zoneId, pledgeYear) {
      $scope.appeals = [];
      blockUI.start();
      ZoneAppealsService.getApplicationsInAppeal(zoneId, pledgeYear)
        .then(function(response) {
          $scope.appeals = response.data.value.appeals;
          $scope.OriginalAppeals = $scope.appeals;
          $scope.applicationTypes = response.data.value.applicationTypes;
          $scope.applealStatuses = response.data.value.appealStatus;
        })
        .catch(function(err) {
          $log.error(err);
        })
        .finally(function() {
          $scope.getSelectedCount();
          blockUI.stop();
        });
    }

    $scope.onFilterChange = function(typeOrStatus, isTrue, e) {
      e.preventDefault();
      $scope.clearAllSelection();
      if (isTrue) {
        $scope.filter.appealStatus = typeOrStatus;
      } else {
        $scope.filter.applicationType = typeOrStatus;
      }
    };

    $scope.onStatusChange = function(type, status) {
      $scope.clearAllSelection();
      $scope.tempAppeals = [];
      $scope.appeals = $scope.OriginalAppeals;
      if (status != undefined) {
        angular.forEach($scope.appeals, function(appeal) {
          if (type === 'CFCSpecialist') {
            if (appeal.cfcSpecialistStatus === status) {
              $scope.tempAppeals.push(appeal);
            }
          } else if (type === 'CFCDirector') {
            if (appeal.cfcDirectorStatus === status) {
              $scope.tempAppeals.push(appeal);
            }
          } else {
            if (appeal.opmDirectorStatus === status) {
              $scope.tempAppeals.push(appeal);
            }
          }
        });
        $scope.appeals = $scope.tempAppeals;
      }
    };

    $scope.goToAppealAdminPage = function(applicationId, applicationType) {
      // review-admin-appeal-dashboard
      $state.go('admin-appeal-dashboard', {
        applicationType: applicationType,
        applicationId: applicationId
      });
    };

    $scope.gotToReviewDashboard = function(applicationId, type, cfcCode) {
      var user = $scope.getCurrentUser();
      var secondaryRoleList = user.secondaryRoleList;
      if (
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
      ) {
        if (type === 'FEDERATION') {
          $state.go('federationapplicationreview', {
            federationApplicationId: applicationId
          });
        } else {
          ZoneCenterService.goToCharityAuditDashboard(applicationId, user.id);
        }
      } else if (
        secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('REVIEWER') >= 0
      ) {
        ZoneCenterService.findCharityAccessEligibility(user.id, cfcCode).then(
          function(res) {
            var isAccessEligible = res.data;
            if (isAccessEligible) {
              if (type === 'FEDERATION') {
                $state.go('federationapplicationreview', {
                  federationApplicationId: applicationId
                });
              } else {
                ZoneCenterService.goToCharityAuditDashboard(
                  applicationId,
                  user.id
                );
              }
            } else {
              $state.go('accessDenied', {
                userId: user.id
              });
            }
          }
        );
      } else {
        $state.go('accessDenied', {
          userId: user.id
        });
      }
    };

    $scope.updateAppealStatus = function(status) {
      $scope.batchStatus = status;
      $scope.batchStatusDisplayText =
        status === 'APPROVED' ? 'Denial Overturned' : 'Denial Sustained';
      angular.element('#comfirmBulkUpdate').modal('show');
    };

    $scope.batchApproved = function() {
      angular.element('#comfirmBulkUpdate').modal('hide');
      blockUI.start();
      var selectedAppeals = getSelectedAppeals();
      ZoneAppealsService.updateAppeals(selectedAppeals, $scope.batchStatus)
        .then(function() {})
        .catch(function() {
          toastrService.error(
            'There was a problem processing your updates. Please try again.'
          );
        })
        .finally(function() {
          getApplicationsInAppeal($scope.zoneId, $scope.pledgeYear);
          blockUI.stop();
        });
    };

    $scope.clearAllSelection = function() {
      angular.forEach($scope.appeals, function(appeal) {
        appeal.isSelected = false;
      });
      $scope.getSelectedCount();
    };

    $scope.getSelectedCount = function() {
      var selectedAppeals = getSelectedAppeals();
      $scope.rowCount = selectedAppeals === null ? 0 : selectedAppeals.length;
    };

    $scope.selectPage = function() {
      $scope.clearAllSelection();
      var pagedRows = $('#appealsTable')
        .children('tbody')
        .children('tr');
      var pagedRowsIds = [];
      $(pagedRows).each(function() {
        pagedRowsIds.push($(this).data('id'));
      });
      angular.forEach($scope.appeals, function(item) {
        if (pagedRowsIds.includes(item.appealId)) {
          item.isSelected = true;
        }
      });
      $scope.getSelectedCount();
    };

    $scope.selectAll = function(visibleItems) {
      $scope.clearAllSelection();
      angular.forEach(visibleItems, function(item) {
        item.isSelected = true;
      });
      $scope.getSelectedCount();
    };

    function getSelectedAppeals() {
      return $scope.appeals.filter(function(obj) {
        return obj.isSelected === true;
      });
    }

    init();
  }
})();
