angular
  .module('CfcCharity.paymentreceipt', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('paymentreceipt', {
        url: '/paymentreceipt',
        views: {
          main: {
            template: require('../views/private/finance/paymentReceipt.html'),
            controller: 'PaymentReceiptCtrl'
          }
        },
        data: {
          pageTitle: 'Payment Receipt',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          userId: null,
          id: null,
          type: null,
          feeType: null,
          paymentMethod: null,
          year: null
        },
        resolve: {
          paymentReceiptData: function(
            $http,
            $stateParams,
            FinanceTransactionsService
          ) {
            if ($stateParams.paymentMethod === 'Credit Card') {
              if (
                $stateParams.userId &&
                $stateParams.id &&
                $stateParams.type &&
                $stateParams.feeType &&
                $stateParams.year
              ) {
                var financialTransactionsBean = {
                  userId: $stateParams.userId,
                  id: $stateParams.id,
                  type: $stateParams.type,
                  feeType: $stateParams.feeType
                };

                var financialTransactionsRequest = {
                  financialTransactionsBean: financialTransactionsBean
                };

                return FinanceTransactionsService.retrieveCCPaymentDetails(
                  financialTransactionsRequest
                ).then(function(res) {
                  return res.data;
                });
              }
            } // end if
            else {
              if (
                $stateParams.userId &&
                $stateParams.id &&
                $stateParams.type &&
                $stateParams.feeType &&
                $stateParams.year
              ) {
                var financialTransactionsBean = {
                  userId: $stateParams.userId,
                  id: $stateParams.id,
                  type: $stateParams.type,
                  feeType: $stateParams.feeType,
                  paymentType: $stateParams.paymentMethod
                };

                var financialTransactionsRequest = {
                  financialTransactionsBean: financialTransactionsBean
                };

                return FinanceTransactionsService.retrieveACHPaymentDetails(
                  financialTransactionsRequest
                ).then(function(res) {
                  return res.data;
                });
              }
            }
          } // end function
        } // end resolve
      });
    }
  ])
  .service('PaymentReceiptService', [
    '$http',
    '$filter',
    function($http, $filter) {} // end PaymentHistoryService function
  ])
  .controller('PaymentReceiptCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'paymentReceiptData',
    'BrowserEventService',
    'FinanceTransactionsService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      paymentReceiptData,
      BrowserEventService,
      FinanceTransactionsService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.paymentreceipt');
      $scope.paymentReceiptData = {};
      var paymentReceiptResponse = {};
      var userId = null;
      paymentReceiptResponse.errorResponse = {};
      var error = '';

      $scope.showPaymentHistoryErrorMessage = function(error) {
        $scope.paymentReceiptError = error;
      };
      if (paymentReceiptData) {
        userId = $state.params.userId;
        paymentReceiptResponse = paymentReceiptData;
      } else {
        paymentReceiptResponse.errorResponse.errorCode = '5000 : ';
        paymentReceiptResponse.errorResponse.errorMessage =
          'Error retrieving the payment details';
      }

      if (
        paymentReceiptResponse &&
        (paymentReceiptResponse.error ||
          paymentReceiptResponse.statusCode === 'ERROR')
      ) {
        error =
          paymentReceiptResponse.errorResponse.errorCode +
          ' : ' +
          paymentReceiptResponse.errorResponse.errorMessage +
          '. Please try later';

        $scope.showPaymentReceiptErrorMessage(error);
      } else if (
        paymentReceiptResponse &&
        paymentReceiptResponse.statusCode === 'SUCCESS'
      ) {
        $scope.paymentReceiptData =
          paymentReceiptResponse.financialTransactionsBean
            .creditCardPaymentDetailsBean ||
          paymentReceiptResponse.financialTransactionsBean.achPaymentDetailBean;
        $scope.paymentReceiptData.paymentMethod =
          paymentReceiptResponse.financialTransactionsBean.paymentType;
        if ($scope.paymentReceiptData.paymentMethod === null) {
          $scope.paymentReceiptData.paymentMethod = 'Credit Card';
        }
        var paymentReceiptRequestData = {
          userId: $scope.paymentReceiptData.userId,
          id: $scope.paymentReceiptData.id,
          type: $scope.paymentReceiptData.type,
          feeType: $scope.paymentReceiptData.feeType,
          paymentMethod: $scope.paymentReceiptData.paymentMethod,
          year: $scope.paymentReceiptData.year
        };
        BrowserEventService.setPaymentHistoryRequestData(
          paymentReceiptRequestData
        );
      }

      $scope.showPaymentReceiptErrorMessage = function(error) {
        $scope.paymentReceiptError = error;
      };

      $scope.showPaymentReceiptErrorMessage = function(error) {
        $scope.paymentReceiptError = error;
      };

      $scope.gotoCharityDashboard = function(cfcCode) {
        $('.modal-backdrop').remove();
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
    } // end PaymentHistoryCtrl controller
  ]);
