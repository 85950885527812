angular
  .module('CfcCharity.grantusercharityrole', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('grantusercharityrole', {
        url: '/grantusercharityrole',
        views: {
          main: {
            template: require('../views/private/admin/grantusercharityrole.html'),
            controller: 'grantusercharityroleCtrl'
          }
        },
        data: {
          pageTitle: 'Grant User Charity Role page title',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          userId: null
        }
      }); // end state grantusercharityrole
      $stateProvider.state('grantusercharityrolesuccess', {
        url: '/grantusercharityrolesuccess',
        views: {
          main: {
            template: require('../views/private/admin/grantusercharityrolesuccess.html'),
            controller: 'grantusercharityroleSuccessCtrl'
          }
        },
        data: {
          pageTitle: 'Successful Granting of User Charity role',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
          ]
        },
        params: {
          grantusercharityroleData: null
        },
        resolve: {
          grantusercharityroleData: function($http, $stateParams) {
            if ($stateParams.grantusercharityroleData != null) {
              return $stateParams.grantusercharityroleData;
            } // end if
          } // end function
        } // end resolve
      }); // end state grantusercharityrolesuccess
    } // end function myApp
  ])
  .service('grantusercharityroleService', [
    '$http',
    function($http) {
      this.grantusercharityrole = function(grantUserData) {
        return $http({
          method: 'POST',
          url: '/CfcCharity/api/private/manageUsers/insertCharityAccess',
          params: {
            targetUserId: grantUserData.targetUserId,
            cfcCode: grantUserData.cfcCode,
            grantingUserId: grantUserData.grantingUserId,
            selectedRole: grantUserData.selectedRole
          }
        });
      };
    }
  ])
  .controller('grantusercharityroleCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$log',
    'grantusercharityroleService',
    'AdministrationCenterService',
    'CharityDashboardService',
    'BrowserEventService',
    'blockUI',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $log,
      grantusercharityroleService,
      AdministrationCenterService,
      CharityDashboardService,
      BrowserEventService,
      blockUI,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.grantusercharityrole');

      $scope.roleOptions = [
        { value: 'CHARITY_EDITOR', label: 'Editor' },
        { value: 'CHARITY_OWNER', label: 'Owner' }
      ];

      $scope.findUserByEmailAddress = function(emailAddress) {
        if (emailAddress === null || emailAddress === undefined) {
          return;
        }
        $log.info('Looking up user info for email address: ' + emailAddress);
        AdministrationCenterService.getUserByUserEmail(emailAddress).then(
          function(res) {
            var user = res.data;
            if (user !== null && user !== '') {
              $scope.grantUserData.targetUserId = user.id;
              $scope.firstName = user.firstName;
              $scope.lastName = user.lastName;
              $scope.showgrantusercharityroleErrorMessage('');
            } else {
              $log.info('No match found for email address: ' + emailAddress);
              $scope.showgrantusercharityroleErrorMessage(
                'User email address not found'
              );
            }
          }
        );
      };

      $scope.findCharityByCfcCode = function(cfcCode) {
        if (cfcCode === null || cfcCode === undefined) {
          return;
        }
        $log.info('Looking up charity by CFC code: ' + cfcCode);
        CharityDashboardService.findCharityWithCFC(cfcCode).then(function(res) {
          var result = res.data;
          if (result.charityEin !== null && result.charityName !== null) {
            $scope.charityInformation = result;
            $scope.showgrantusercharityroleErrorMessage('');
          } else {
            $log.warn('CFC code not found: ' + cfcCode);
            $scope.showgrantusercharityroleErrorMessage('CFC code not found');
          }
        });
      };

      // add a charity role for the given user
      $scope.grantusercharityrole = function() {
        if (
          $scope.grantUserData === null ||
          $scope.grantUserData === undefined
        ) {
          $scope.showgrantusercharityroleErrorMessage(
            '$scope.grantUserData is undefined'
          );
          return;
        }

        if (
          $scope.grantUserData.targetUserId === null ||
          $scope.grantUserData.targetUserId === undefined
        ) {
          $scope.showgrantusercharityroleErrorMessage('User ID is required');
          return;
        }

        if (
          $scope.grantUserData.cfcCode === null ||
          $scope.grantUserData.cfcCode === undefined
        ) {
          $scope.showgrantusercharityroleErrorMessage('CFC code is required');
          return;
        }

        if (
          $scope.grantUserData.selectedRole === null ||
          $scope.grantUserData.selectedRole === undefined ||
          $scope.grantUserData.selectedRole === 'Select a Role'
        ) {
          $scope.showgrantusercharityroleErrorMessage('Please select a role');
          return;
        }

        $scope.showgrantusercharityroleErrorMessage('');

        blockUI.start();

        // Submit the data to the server side for the Grant User Charity role
        var grantUserData = $scope.grantUserData;
        grantUserData.grantingUserId = $scope.getCurrentUser().id;

        grantusercharityroleService
          .grantusercharityrole(grantUserData)
          .then(function(res) {
            var data = res.data;
            data.grantUserData = $scope.grantUserData;
            data.firstName = $scope.firstName;
            data.lastName = $scope.lastName;
            data.charityInformation = $scope.charityInformation;
            $state.go('grantusercharityrolesuccess', {
              grantusercharityroleData: data
            });
          })
          .catch(function(err) {
            var grantusercharityroleResponse = err.data;
            if (grantusercharityroleResponse.err.status === 400) {
              $scope.showgrantusercharityroleErrorMessage(
                grantusercharityroleResponse.errorMessage
              );
              return;
            }
          });
        blockUI.stop();
      };

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      $scope.showgrantusercharityroleErrorMessage = function(error) {
        $scope.grantusercharityroleErrors = error;
      };
    } // end function
  ])
  .controller('grantusercharityroleSuccessCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'grantusercharityroleData',
    'grantusercharityroleService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      grantusercharityroleData,
      grantusercharityroleService,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.grantusercharityrolesuccess');
      if (grantusercharityroleData != null) {
        $scope.grantusercharityroleData = grantusercharityroleData;
      }
    } // end function
  ]);
