/**
 * @ngdoc Module
 * @author Bhaskar Bommedi
 * @date Mar 16, 2016
 * @description This module will take care of all actions on Invite users
 *
 */
angular
  .module('CfcCharity.invitations', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('inviteUserToCharity', {
        url: '/inviteUserToCharity',
        views: {
          main: {
            template: require('../views/private/charity/InviteUserToCharity.html'),
            controller: 'InviteUserCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Charity Invite User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          charityDashboardCfcCode: null,
          adminWithCharityEditAccess: null
        },
        resolve: {
          charityInfo: function($http, $stateParams) {
            if ($stateParams.charityDashboardCfcCode != null) {
              var cfcCode1 = $stateParams.charityDashboardCfcCode;
              var url = '/CfcCharity/api/private/charity/details/' + cfcCode1;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
    }
  ])
  .service('InvitationService', [
    '$http',
    function($http) {
      this.inviteUserToCharity = function(inviteUser) {
        return $http.post(
          '/CfcCharity/api/private/charity/inviteUser',
          inviteUser
        );
      };
      this.resendInviteUserToCharity = function(inviteUser) {
        return $http.post(
          '/CfcCharity/api/private/charity/resendInviteUser',
          inviteUser
        );
      };
    }
  ])
  .controller('InviteUserCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'InvitationService',
    'charityInfo',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      InvitationService,
      charityInfo
    ) {
      var invite = {};
      $scope.adminWithCharityEditAccess =
        $state.params.adminWithCharityEditAccess;
      $scope.charityInfo = charityInfo;
      invite.role = 'CHARITY_EDITOR';
      invite.cfcCode = charityInfo.cfcCode;
      $scope.invite = invite;
      var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.invitationSent = false;
      $scope.requestExists = false;
      $scope.sendInvitation = function(invite) {
        InvitationService.inviteUserToCharity(invite).then(function(res) {
          var data = res.data;
          if (data.statusCode === 'ERR_INVITE_001') {
            $scope.inviteUserErrors =
              'User was already assigned to your charity.';
          } else if (data.statusCode === 'ERR_INVITE_003') {
            $scope.inviteUserErrors =
              'User is already assigned to your charity but user is inactive.';
          } else if (data.statusCode === 'ERR_INVITE_002') {
            $scope.inviteUserErrors = 'Invitation already sent to this user.';
          } else if (data.statusCode === 'ERR_INVITE_ACC_DISABLED') {
            $scope.inviteUserErrors =
              'The given email ID exist in our system and account has been disabled.';
          } else if (data.statusCode === 'ERR_INVITE_ACC_SUSPENDED') {
            $scope.inviteUserErrors =
              'The given email ID exist in our system and account has been suspended.';
          } else if (data.statusCode === 'ERR_INVITE_ACC_BLOCKED') {
            $scope.inviteUserErrors =
              'The given email ID exist in our system and account has been blocked.';
          } else if (data.statusCode === 'ERR_INVITE_004') {
            $scope.inviteUserErrors = 'Request already exist.';
            $scope.invitationSent = false;
            $scope.requestExists = true;
          } else {
            $scope.invitationSent = true;
            $scope.requestExists = false;
          }
        });
      };
      $scope.charitydetail = function() {
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        $state.go('charitydashboard', {
          charityDashboardCfcCode: $scope.charityInfo.cfcCode
        });
      };
      $scope.userdetails = function() {
        $state.go('manageCharityUsers', {
          cfcCode: $scope.charityInfo.cfcCode,
          charityName: $scope.charityInfo.charityName
        });
      };
    }
  ]);
