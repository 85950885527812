angular
  .module('CfcCharity.federationapplicationreview', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('federationapplicationreview', {
        url: '/federationapplicationreview',
        views: {
          main: {
            template: require('../views/private/zoneadmin/federationapplicationreview.html'),
            controller: 'FederationAppReviewCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '%zoneCode% Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: 'reviewLevel',
              crumbRoute: 'federationCompletenessReview',
              crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
            },
            {
              crumbName: '%pledgeYear% #%cfcCode% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
            },
            {
              crumbName: 'Federation Application',
              crumbRoute: 'applicationDashboard',
              crumbParams: '{applicationId: %applicationId%}'
            }
          ],
          pageTitle: 'Federation Package Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: { federationApplicationId: null },
        resolve: {
          r: function($stateParams, BreadcrumbTokenDataService, CubeSvc) {
            CubeSvc.setManualCacheRelease();
            BreadcrumbTokenDataService.setIsFederation(true);
            BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
              $stateParams.federationApplicationId
            );
            return {};
          },
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            FederationApplicationService
          ) {
            var appId =
              $stateParams.applicationId ||
              $stateParams.federationApplicationId;
            if (appId == null) {
              appId = FederationApplicationService.getApplicationId();
            }
            if (appId != null) {
              BreadcrumbTokenDataService.setIsFederation(true);
              return BreadcrumbTokenDataService.getReviewLevel(appId, true)
                .then(function(res1) {
                  return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                    appId
                  );
                })
                .catch(function(err) {
                  //failure for settokendata
                });
            }
          }
        }
      });

      /*federation completeness review*/
      $stateProvider.state('federationCompletenessReview', {
        url: '/federationCompletenessReview',
        views: {
          main: {
            template: require('../views/private/zoneadmin/federationCompletenessReview.html'),
            controller: 'FederationAppReviewCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '%zoneCode% Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: 'Zone Completeness',
              crumbRoute: 'zonecompletenessreview',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: '%pledgeYear% #%cfcCode% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %federationId%}'
            },
            {
              crumbName: 'Federation Application',
              crumbRoute: 'federationDashboard',
              crumbParams: '{federationId: %applicationId%}'
            },
            {
              crumbName: 'Application Review',
              crumbRoute: 'federationapplicationreview',
              crumbParams: '{federationApplicationId: %applicationId%}'
            }
          ],
          pageTitle: 'Federation Package Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: {
          federationApplicationId: null,
          reviewLevel: 'COMPLETENESS'
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            if ($stateParams.federationApplicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.Completeness
              );
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                $stateParams.federationApplicationId
              );
            }
          }
        }
      });

      /*federation federationStaffEligibilityReview review*/
      $stateProvider.state('federationStaffEligibilityReview', {
        url: '/federationStaffEligibilityReview',
        views: {
          main: {
            template: require('../views/private/zoneadmin/federationStaffEligibilityReview.html'),
            controller: 'FederationAppReviewCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '%zoneCode% Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: 'Zone Staff Eligibility',
              crumbRoute: 'zonereviewlevel1',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: '%pledgeYear% #%cfcCode% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %federationId%}'
            },
            {
              crumbName: 'Federation Application',
              crumbRoute: 'federationDashboard',
              crumbParams: '{federationId: %applicationId%}'
            },
            {
              crumbName: 'Application Review',
              crumbRoute: 'federationapplicationreview',
              crumbParams: '{federationApplicationId: %applicationId%}'
            }
          ],
          pageTitle: 'Federation Package Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: {
          federationApplicationId: null,
          reviewLevel: 'REVIEW_1'
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            if ($stateParams.federationApplicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.StaffEligibility
              );
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                $stateParams.federationApplicationId
              );
            }
          }
        }
      });

      /*federation federationPreliminaryEligibilityReview review*/
      $stateProvider.state('federationPreliminaryEligibilityReview', {
        url: '/federationPreliminaryEligibilityReview',
        views: {
          main: {
            template: require('../views/private/zoneadmin/federationPreliminaryEligibilityReview.html'),
            controller: 'FederationAppReviewCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '%zoneCode% Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: 'Zone Preliminary Eligibility',
              crumbRoute: 'zonereviewlevel2',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: '%pledgeYear% #%cfcCode% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %federationId%}'
            },
            {
              crumbName: 'Federation Application',
              crumbRoute: 'federationDashboard',
              crumbParams: '{federationId: %applicationId%}'
            },
            {
              crumbName: 'Application Review',
              crumbRoute: 'federationapplicationreview',
              crumbParams: '{federationApplicationId: %applicationId%}'
            }
          ],
          pageTitle: 'Federation Package Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: {
          federationApplicationId: null,
          reviewLevel: 'REVIEW_2'
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            if ($stateParams.federationApplicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.PreliminaryEligibility
              );
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                $stateParams.federationApplicationId
              );
            }
          }
        }
      });

      /*federation finalEligibilityReview review*/
      $stateProvider.state('federationFinalEligibilityReview', {
        url: '/federationFinalEligibilityReview',
        views: {
          main: {
            template: require('../views/private/zoneadmin/federationFinalEligibilityReview.html'),
            controller: 'FederationAppReviewCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '%zoneCode% Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: 'Zone Final Eligibility',
              crumbRoute: 'zonereviewlevel3',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: '%pledgeYear% #%cfcCode% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %federationId%}'
            },
            {
              crumbName: 'Federation Application',
              crumbRoute: 'federationDashboard',
              crumbParams: '{federationId: %applicationId%}'
            },
            {
              crumbName: 'Application Review',
              crumbRoute: 'federationapplicationreview',
              crumbParams: '{federationApplicationId: %applicationId%}'
            }
          ],
          pageTitle: 'Federation Package Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: {
          federationApplicationId: null,
          reviewLevel: 'REVIEW_3'
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            if ($stateParams.federationApplicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.FinalEligibility
              );
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                $stateParams.federationApplicationId
              );
            }
          }
        }
      });
    }
  ])

  .service('FederationApplicationReviewService', [
    '$http',
    'CubeSvc',
    function($http, CubeSvc) {
      this.getFederationDashboardData = function(federationId) {
        var url = '/CfcCharity/api/private/federation/' + federationId;
        return $http.get(url);
      };

      this.getFederationApplicationStep8 = function(federationId) {
        var url =
          '/CfcCharity/api/private/federation_application/step8/' +
          federationId;
        return CubeSvc.get(url, null, false);
      };

      this.getCharityDashboardData = function(cfcCode) {
        var url = '/CfcCharity/api/private/users/charitydashboard/' + cfcCode;
        return CubeSvc.get(url);
      };

      this.getUserById = function(userId) {
        var url = '/CfcCharity/api/private/users/' + userId;
        return $http.get(url);
      };

      this.getZoneDetails = function(applicationId) {
        var url =
          '/CfcCharity/api/private/application/getZoneDetails?applicationId=' +
          applicationId;
        return $http.get(url);
      };

      this.getScanReviewDetails = function(applicationId, scanType) {
        var url =
          '/CfcCharity/api/private/federation/review/scan/' +
          applicationId +
          '/' +
          scanType;
        return $http.get(url);
      };

      /*Set application step review status*/
      this.setStepReviewStatus = function(
        applicationId,
        step,
        reviewLevel,
        status
      ) {
        var url =
          '/CfcCharity/api/private/federation/review/step/' + applicationId;
        return CubeSvc.put(url, {
          step: step,
          reviewLevel: reviewLevel,
          status: status
        });
      };

      this.getFederationReviewDetails = function(federationApplicationId) {
        var url =
          '/CfcCharity/api/private/federation/review/' +
          federationApplicationId;
        return $http.get(url);
      };

      this.retrieveReviewGroups = function() {
        var url = '/CfcCharity/api/private/admin/review/groups';
        return $http.get(url);
      };

      this.retrieveReviewers = function(
        federationApplicationId,
        zoneIdForReview
      ) {
        var retrieveReviewersURL =
          '/CfcCharity/api/private/admin/federation/review/reviewers/';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;

        return $http.post(
          retrieveReviewersURL,
          federationApplicationReviewRequest
        );
      };

      this.retrieveActiveZones = function(year) {
        var url = '/CfcCharity/api/private/admin/zones/active/' + year;
        return $http.get(url);
      };

      this.updateFederationRequestForInformation = function(
        federationApplicationId,
        federationRequestForInformationData
      ) {
        var federationApplicationReviewUpdateRequestForInformationURI =
          '/CfcCharity/api/private/federation/requestforinformation/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.requestedInformationDate =
          federationRequestForInformationData.dateOfRequest;
        federationApplicationReviewRequest.federationApplicationReviewBean.requestedInformationBy =
          federationRequestForInformationData.requestedBy;
        return $http.post(
          federationApplicationReviewUpdateRequestForInformationURI,
          federationApplicationReviewRequest
        );
      };
      this.updateFederationAFR = function(
        federationApplicationId,
        fundRaisingRate
      ) {
        var federationApplicationReviewUpdateAFRURI =
          '/CfcCharity/api/private/federation/afr/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.fundRaisingRate = fundRaisingRate;
        return $http.post(
          federationApplicationReviewUpdateAFRURI,
          federationApplicationReviewRequest
        );
      };
      this.updateFederationTR = function(
        federationApplicationId,
        totalRevenue
      ) {
        var federationApplicationReviewUpdateAFRURI =
          '/CfcCharity/api/private/federation/totalRevenue/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.totalRevenue = totalRevenue;
        return $http.post(
          federationApplicationReviewUpdateAFRURI,
          federationApplicationReviewRequest
        );
      };
      this.updateFederationApplicationReviewStatus = function(
        federationApplicationId,
        applicationReviewStatus
      ) {
        var federationApplicationReviewUpdateApplicationReviewStatusURI =
          '/CfcCharity/api/private/federation/applicationreviewstatus/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.applicationReviewStatus = applicationReviewStatus;
        return $http.post(
          federationApplicationReviewUpdateApplicationReviewStatusURI,
          federationApplicationReviewRequest
        );
      };

      this.assignReviewer = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel,
        reviewerUserId
      ) {
        var federationApplicationReviewerAssignReviewerURI =
          '/CfcCharity/api/private/federation/reviewer/assign';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewLevel = reviewLevel;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewerUserId = reviewerUserId;
        return $http.post(
          federationApplicationReviewerAssignReviewerURI,
          federationApplicationReviewRequest
        );
      };

      this.addReviewerComment = function(
        federationApplicationId,
        zoneIdForReview,
        reviewerComment
      ) {
        var federationApplicationReviewerAddReviewerCommentURI =
          '/CfcCharity/api/private/federation/review/reviewercomment/add';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewerComment = reviewerComment;
        return $http.post(
          federationApplicationReviewerAddReviewerCommentURI,
          federationApplicationReviewRequest
        );
      }; // end addReviewerComment comment

      this.updateDetermination = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel,
        determination
      ) {
        var federationApplicationReviewUpdateDeterminationURI =
          '/CfcCharity/api/private/federation/determination/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewLevel = reviewLevel;
        federationApplicationReviewRequest.federationApplicationReviewBean.determination = determination;
        return $http.post(
          federationApplicationReviewUpdateDeterminationURI,
          federationApplicationReviewRequest
        );
      };

      this.updateZoneForReview = function(
        federationApplicationId,
        zoneIdForReview
      ) {
        var federationApplicationReviewUpdateZoneForReviewURI =
          '/CfcCharity/api/private/federation/zoneforreview/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        return $http.post(
          federationApplicationReviewUpdateZoneForReviewURI,
          federationApplicationReviewRequest
        );
      };

      this.addWarningNote = function(applicationId, application) {
        var url =
          '/CfcCharity/api/private/federation/review/addWarningNote/' +
          applicationId;
        return $http.put(url, {
          warningNote: application.warningNote,
          approvedWithWarning: application.approvedWithWarning
        });
      };

      this.addDenialOverturnedWarningNote = function(
        applicationId,
        comment,
        application
      ) {
        var url =
          '/CfcCharity/api/private/federation/review/addDenialOverturnedWarningNote/' +
          applicationId;
        return $http.put(url, {
          denialWarningNote: application.denialOverturnedWarningNote,
          denialOverturnedWithWarning: application.denialOverturnedWithWarning,
          appealComment: comment
        });
      };

      this.updateCurrentReviewLevel = function(applicationId, reviewLevel) {
        var url =
          '/CfcCharity/api/private/federation/review/currentReviewLevel/' +
          applicationId +
          '/' +
          reviewLevel;
        return CubeSvc.put(url, null, false);
      };

      this.addComment = function(federationApplicationId, reviewerComment) {
        return $http.post(
          '/CfcCharity/api/private/federation/review/reviewercomment/add',
          {
            federationApplicationReviewBean: {
              federationId: federationApplicationId,
              reviewerComment: reviewerComment
            }
          }
        );
      };
    }
  ])
  .controller('FederationAppReviewCtrl', [
    '$state',
    '$stateParams',
    '$scope',
    '$location',
    '$rootScope',
    '$filter',
    '$http',
    '$log',
    'CubeSvc',
    'FederationApplicationReviewService',
    'FederationApplicationService',
    'BrowserEventService',
    'FederationApplicationStep2Service',
    'ApplicationService',
    'CharityService',
    'EmailService',
    'AppealService',
    'FederationDashboardService',
    'DenialReasonsService',
    'ZoneCenterService',
    'ValidationUtil',
    'ApplicationDashboardService',
    function(
      $state,
      $stateParams,
      $scope,
      $location,
      $rootScope,
      $filter,
      $http,
      $log,
      CubeSvc,
      FederationApplicationReviewService,
      FederationApplicationService,
      BrowserEventService,
      FederationApplicationStep2Service,
      ApplicationService,
      CharityService,
      EmailService,
      AppealService,
      FederationDashboardService,
      DenialReasonsService,
      ZoneCenterService,
      ValidationUtil,
      ApplicationDashboardService
    ) {
      $scope.setModuleName('CfcCharity.federationapplicationreview');
      $scope.pledgeYear = BrowserEventService.getPledgeYear();
      $scope.zoneId = BrowserEventService.getZoneId();
      $scope.zoneUserRole = BrowserEventService.getZoneUserRole();
      $scope.cfcCode = BrowserEventService.getCfcCode();
      $scope.determinationUpdateCounter = 0;

      if (!$scope.application) {
        $scope.application = ApplicationService.getApplication();
        if (ApplicationService.getApplicationStep2()) {
          $scope.applicationType = ApplicationService.getApplicationStep2().applicationType;
        }
      }
      var federationApplicationId = $state.params.federationApplicationId;
      var reviewLevel = $state.params.reviewLevel;

      $scope.reviewLevel = reviewLevel;

      var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.isZoneManagerOrReviewer =
        user.secondaryRoleList.includes('ZONE_MANAGER') ||
        user.secondaryRoleList.includes('REVIEWER');

      CubeSvc.performManualCacheRelease();

      /*work on federation dashboard data */
      var federationDashboardData = {};
      $scope.federationDashboardData = {};
      if (federationApplicationId) {
        $scope.campaignId = BrowserEventService.getCampaignId();

        CharityService.findEnrollmentStatus($scope.cfcCode).then(function(
          respo
        ) {
          var enrollmentStatus = respo.data;
          federationDashboardData.isEnrollmentAvailable = enrollmentStatus;
          $scope.mode = 'VIEW';
          if (
            federationDashboardData &&
            federationDashboardData.federationDetails &&
            federationDashboardData.federationDetails.applicationStatus ===
              'IN_PROGRESS' &&
            federationDashboardData.isEnrollmentAvailable
          ) {
            $scope.mode = 'EDIT';
          } // end if
          // End - code added for new sprint

          CharityService.getCharityUserRole($scope.cfcCode).then(function(
            roleResp
          ) {
            var userRole = roleResp.data;
            if (userRole) {
              if (userRole != null && userRole.userRole === 'CHARITY_OWNER') {
                federationDashboardData.isUsersButtonVisible = true;
              }
            } else {
              federationDashboardData.federationAuthority = false;
            }
          });
        });
      }

      if (!$scope.applicationStep2) {
        $scope.applicationStep2 = ApplicationService.getApplicationStep2();
        $scope.applicationStep2.applicationId = ApplicationService.getApplicationId();
        $scope.applicationStep2.cfcCode = ApplicationService.getCfcCode();
        var federationId = ApplicationService.getApplication().federationId;

        FederationApplicationStep2Service.retrieveFederationApplicationStep2DetailsByFedAndMemberId(
          federationApplicationId,
          federationApplicationId
        ).then(function(res) {
          var federationStep2 = res.data.value;
          if (federationStep2) {
            $scope.applicationStep2.applicationType = 'MEMBER';
            $scope.applicationStep2.parentCfcCode = federationStep2.cfcCode;
            $scope.applicationStep2.charityType = federationStep2.charityType;
            $scope.charityType = federationStep2.charityType;
            $scope.setFromMemberCharitiesApplication(true);
            $scope.charityFromFederation = true;
          }
          if (
            $scope.applicationStep2 &&
            $scope.applicationStep2.applicationType
          ) {
            $scope.applicationPreviousType =
              $scope.applicationStep2.applicationType;
          }
          if (
            $scope.applicationStep2 &&
            $scope.applicationStep2.parentCfcCode
          ) {
            $scope.applicationPreviousParentCfcCode =
              $scope.applicationStep2.parentCfcCode;
          }
        });
      }

      $scope.zoneCode = BrowserEventService.getZoneCode();
      var secondaryRoleList = user.secondaryRoleList;
      var canEdit = true;
      if (
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
        secondaryRoleList.indexOf('GB_ADMIN') >= 0 ||
        (secondaryRoleList.indexOf('OPM_STAFF') >= 0 &&
          !(
            $scope.zoneCode === 'INT' ||
            $scope.zoneCode === 'FED' ||
            $scope.zoneCode === 'NAT'
          ))
      ) {
        canEdit = false;
      }

      $scope.hasWarningNoteAccess = false;
      if (
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('REVIEWER') >= 0 ||
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
        secondaryRoleList.indexOf('OPM_STAFF') >= 0
      ) {
        $scope.hasWarningNoteAccess = true;
      }

      $scope.isOpmStaffOrAuditor = false;
      if (
        secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
      ) {
        $scope.isOpmStaffOrAuditor = true;
      }

      $scope.isOpmAdminOrStaff =
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('OPM_STAFF') >= 0;

      $scope.isZoneReviewerOrManager = false;
      if (
        secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('REVIEWER') >= 0
      ) {
        $scope.isZoneReviewerOrManager = true;
      }

      $scope.canEditApplicationStatus =
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0;
      $scope.canEdit = canEdit;
      if (federationApplicationId) {
        BrowserEventService.setFederationApplicationReviewFederationId(
          federationApplicationId
        );
      }

      $scope.onChangeApprovedWithWarning = function() {
        if (!$scope.federationApplication) {
          return;
        }
        if ($scope.federationApplication.approvedWithWarning) {
          angular.element('#addExceptions').modal('show');
        } else {
          angular
            .element('#confirmDeleteApprovedWithWarningsException')
            .modal('show');
        }
      };

      $scope.addViewApprovedWithWarningReasons = function() {
        if (!$scope.federationApplication) {
          return;
        }
        angular.element('#addExceptions').modal('show');
      };

      $scope.approvedWithWarningModalCancel = function() {
        FederationApplicationReviewService.getFederationDashboardData(
          $scope.federationApplication.federationId
        ).then(function(resp) {
          federationDashboardData.federationDetails = resp.data.value;
          $scope.federationApplication = resp.data.value;
        });
        angular.element('#addExceptions').modal('hide');
      };

      $scope.approvedWithWarningModalSave = function() {
        $scope.federationApplication.approvedWithWarning = $scope
          .federationApplication.warningNote
          ? true
          : false;
        var comment = $scope.federationApplication.approvedWithWarning
          ? 'Approved With Warning: ' + $scope.federationApplication.warningNote
          : 'Approved With Warning: flag removed';
        FederationApplicationReviewService.addWarningNote(
          $scope.federationApplication.federationId,
          $scope.federationApplication
        )
          .then(function() {
            //success
            FederationApplicationReviewService.addComment(
              $scope.federationApplication.federationId,
              comment
            ).then(function(res) {
              $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                res.data.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
            });
          })
          .catch(function(er) {
            $log.error(er);
          });
        angular.element('#addExceptions').modal('hide');
      };

      $scope.cancelDeleteApprovedWithWarningsException = function() {
        if (!$scope.federationApplication) {
          return;
        }
        FederationApplicationReviewService.getFederationDashboardData(
          $scope.federationApplication.federationId
        ).then(function(resp) {
          federationDashboardData.federationDetails = resp.data.value;
          $scope.federationApplication = resp.data.value;
        });
        angular
          .element('#confirmDeleteApprovedWithWarningsException')
          .modal('hide');
      };

      $scope.deleteApprovedWithWarningsException = function() {
        $scope.federationApplication.warningNote = null;
        $scope.federationApplication.approvedWithWarning = false;
        $scope.approvedWithWarningModalSave();
        angular
          .element('#confirmDeleteApprovedWithWarningsException')
          .modal('hide');
      };

      $scope.onChangeDenialOverturnedWithWarning = function() {
        if (!$scope.federationApplication) {
          return;
        }
        if ($scope.federationApplication.denialOverturnedWithWarning) {
          angular.element('#addDenialExceptionsFed').modal('show');
        } else {
          angular
            .element('#confirmDeleteDenialOverturnedWithWarningsException')
            .modal('show');
        }
      };

      $scope.denialOverturnedWithWarningModalCancel = function() {
        FederationApplicationReviewService.getFederationDashboardData(
          $scope.federationApplication.federationId
        ).then(function(resp) {
          federationDashboardData.federationDetails = resp.data.value;
          $scope.federationApplication = resp.data.value;
        });
        angular.element('#addDenialExceptionsFed').modal('hide');
      };

      $scope.denialOverturnedWithWarningModalSave = function() {
        $scope.federationApplication.denialOverturnedWithWarning = $scope
          .federationApplication.denialOverturnedWarningNote
          ? true
          : false;
        var comment = $scope.federationApplication.denialOverturnedWithWarning
          ? 'Denial Overturned With Warning: ' +
            $scope.federationApplication.denialOverturnedWarningNote
          : 'Denial Overturned With Warning: flag removed';
        FederationApplicationReviewService.addDenialOverturnedWarningNote(
          $scope.federationApplication.federationId,
          comment,
          $scope.federationApplication
        )
          .then(function(res) {
            //success
            $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
              res.data.value.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
          })
          .catch(function(er) {
            $log.error(er);
          });
        angular.element('#addDenialExceptionsFed').modal('hide');
      };

      $scope.cancelDeleteDenialOverturnedWithWarningsException = function() {
        if (!$scope.federationApplication) {
          return;
        }
        FederationApplicationReviewService.getFederationDashboardData(
          $scope.federationApplication.federationId
        ).then(function(resp) {
          federationDashboardData.federationDetails = resp.data.value;
          $scope.federationApplication = resp.data.value;
        });
        angular
          .element('#confirmDeleteDenialOverturnedWithWarningsException')
          .modal('hide');
      };

      $scope.deleteDenialOverturnedWithWarningsException = function() {
        $scope.federationApplication.denialOverturnedWarningNote = null;
        $scope.federationApplication.denialOverturnedWithWarning = false;
        $scope.denialOverturnedWithWarningModalSave();
        angular
          .element('#confirmDeleteDenialOverturnedWithWarningsException')
          .modal('hide');
      };

      $scope.federationApplicationId = federationApplicationId;
      var error = '';
      if (!federationApplicationId) {
        error =
          'Cannot retrieve the federation application review as the federation application ID is : ' +
          federationApplicationId;
        // TODO $scope.showFederationApplicationReviewErrorMessage(error);
      }

      var getFederationReviewDetails;
      (getFederationReviewDetails = function() {
        FederationApplicationReviewService.getFederationReviewDetails(
          federationApplicationId
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            ApplicationService.setCfcCode(res.cfcCode);
            $scope.federationApplicationReviewAFR = {};
            $scope.federationApplicationReviewStatus = {};
            $scope.federationApplicationReviewRequestForInformation = {};
            federationDashboardData.federationDetails = {};
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $scope.showFederationApplicationReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $scope.federationApplicationReviewData =
                federationApplicationReviewResponse.federationApplicationReviewBean;
              $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewDateOfRequest = $filter(
                'date'
              )(
                $scope.federationApplicationReviewData.requestedInformationDate,
                'MM/dd/yyyy'
              );
              $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewRequestedBy =
                $scope.federationApplicationReviewData.requestedInformationBy;
              $scope.federationApplicationReviewAFR.afr =
                $scope.federationApplicationReviewData.fundRaisingRate;
              $scope.federationApplicationReviewAFR.tr =
                $scope.federationApplicationReviewData.totalRevenue;
              $scope.tempReviewerTotalRevenue =
                $scope.federationApplicationReviewData.totalRevenue;
              $scope.federationApplicationReviewStatus =
                $scope.federationApplicationReviewData.applicationReviewStatus;

              $scope.fundraisingRateOverride =
                $scope.federationApplicationReviewData.fundRaisingRate;
              $scope.tempFundraisingRateOverride =
                $scope.fundraisingRateOverride;

              $scope.cfcZone =
                $scope.federationApplicationReviewData.cfcReviewZone;
              federationDashboardData.federationDetails.cfcCode =
                $scope.federationApplicationReviewData.cfcCode;
              federationDashboardData.federationDetails.pledgeYear =
                $scope.federationApplicationReviewData.pledgeYear;
              federationDashboardData.federationDetails.applicationStatus =
                $scope.federationApplicationReviewData.applicationStatus;
              federationDashboardData.federationDetails.approvedWithWarning =
                $scope.federationApplicationReviewData.approvedWithWarning;
              federationDashboardData.federationDetails.warningNote =
                $scope.federationApplicationReviewData.warningNote;
              federationDashboardData.federationDetails.denialOverturnedWithWarning =
                $scope.federationApplicationReviewData.denialOverturnedWithWarning;
              federationDashboardData.federationDetails.denialOverturnedWarningNote =
                $scope.federationApplicationReviewData.denialOverturnedWarningNote;
              federationDashboardData.federationDetails.federationId = federationApplicationId;
              $scope.federationApplication =
                federationDashboardData.federationDetails;

              loadDenialReasons(
                $scope.federationApplication.cfcCode,
                $scope.federationApplication.pledgeYear,
                $scope.federationApplication.applicationStatus
              );
              // get email
              var emailHistory = [];
              var keepGoing = true;
              $scope.emailHistoryResponse =
                $scope.federationApplicationReviewData.emailActivity;
              angular.forEach($scope.emailHistoryResponse, function(arrList) {
                if (keepGoing) {
                  if (arrList.status.toLowerCase() === 'sent') {
                    emailHistory.push({
                      canDelete: arrList.canDelete,
                      canEdit: arrList.canEdit,
                      emailType: arrList.emailType,
                      id: arrList.id,
                      sendDate: arrList.sendDate,
                      sentBy: arrList.sentBy,
                      status: arrList.status,
                      statusAtSend: arrList.statusAtSend
                    });
                    $scope.emailHistory = emailHistory;
                    keepGoing = false;
                  }
                }
              });
              $scope.federationDashboardData = federationDashboardData;
              getAppealStatus($scope.federationApplicationReviewData);

              if ($scope.reviewLevel != null) {
                if ($scope.reviewLevel === 'REVIEW_2') {
                  $scope.determinationStatus =
                    $scope.federationApplicationReviewData.review2Status ===
                      'Not Set' ||
                    $scope.federationApplicationReviewData.review2Status ==
                      null ||
                    $scope.federationApplicationReviewData.review2Status === ''
                      ? 'Not Set'
                      : $scope.federationApplicationReviewData.review2Status;
                } else if ($scope.reviewLevel === 'REVIEW_1') {
                  $scope.determinationStatus =
                    $scope.federationApplicationReviewData.review1Status ===
                      'Not Set' ||
                    $scope.federationApplicationReviewData.review1Status ==
                      null ||
                    $scope.federationApplicationReviewData.review1Status === ''
                      ? 'Not Set'
                      : $scope.federationApplicationReviewData.review1Status;
                } else if ($scope.reviewLevel === 'REVIEW_3') {
                  $scope.determinationStatus =
                    $scope.federationApplicationReviewData.review3Status ===
                      'Not Set' ||
                    $scope.federationApplicationReviewData.review3Status ==
                      null ||
                    $scope.federationApplicationReviewData.review3Status === ''
                      ? 'Not Set'
                      : $scope.federationApplicationReviewData.review3Status;
                } else if ($scope.reviewLevel === 'COMPLETENESS') {
                  $scope.determinationStatus =
                    $scope.federationApplicationReviewData
                      .completenessReviewStatus === 'Not Set' ||
                    $scope.federationApplicationReviewData
                      .completenessReviewStatus == null ||
                    $scope.federationApplicationReviewData
                      .completenessReviewStatus === ''
                      ? 'Not Set'
                      : $scope.federationApplicationReviewData
                          .completenessReviewStatus;
                }
              }
            }

            angular.forEach(
              $scope.federationApplicationReviewData
                .federationApplicationReviewOverviewByLevelBeanList,
              function(value, key) {
                value.currentReviewLevel = isCurrentLevel(
                  value.reviewLevel,
                  $scope.federationApplicationReviewData.currentLevel
                );
                if (value.currentReviewLevel) {
                  $scope.selectedReviewLevel = value.reviewLevel;
                }
              }
            );
          })
          .catch(function(err) {
            error =
              'Error retrieving the federation application review for the federation application id : ' +
              federationApplicationId;
            $scope.showFederationApplicationReviewErrorMessage(error);
          });
      })();

      function isCurrentLevel(reviewLevel, currentReviewLevel) {
        if (!reviewLevel || !currentReviewLevel) {
          return;
        }
        reviewLevel = reviewLevel.toLowerCase();

        switch (reviewLevel) {
          case 'staff eligibility':
            reviewLevel = 'review_1';
            break;
          case 'preliminary eligibility':
            reviewLevel = 'review_2';
            break;
          case 'final eligibility':
            // if application approved, in appeal or denied make final elilgibility the current level
            switch (currentReviewLevel.toLowerCase()) {
              case 'approved':
              case 'in_appeal':
              case 'denied':
                return true;
            }
            reviewLevel = 'review_3';
            break;
        }
        return reviewLevel === currentReviewLevel.toLowerCase();
      }

      getAfr(federationApplicationId);

      $scope.federationApplicationReviewMemberApplicationBeanListLimit = 10;
      $scope.loadMoreFederationApplicationReviewMemberApplications = function() {
        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData
            .federationApplicationReviewMemberApplicationBeanList &&
          $scope.federationApplicationReviewMemberApplicationBeanListLimit <
            $scope.federationApplicationReviewData
              .federationApplicationReviewMemberApplicationBeanList.length
        ) {
          $scope.federationApplicationReviewMemberApplicationBeanListLimit += 10;
        }
      };
      $scope.federationApplicationReviewCommentBeanListLimit = 10;
      $scope.loadMoreFederationApplicationReviewComments = function() {
        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData
            .federationApplicationReviewCommentBeanList &&
          $scope.federationApplicationReviewCommentBeanListLimit <
            $scope.federationApplicationReviewData
              .federationApplicationReviewCommentBeanList.length
        ) {
          $scope.federationApplicationReviewCommentBeanListLimit += 10;
        }
      };
      $scope.resetFederationApplicationRequestForInformation = function() {
        $scope.updateRequestForInformationErrors = [];
        $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewDateOfRequest = $filter(
          'date'
        )(
          $scope.federationApplicationReviewData.requestedInformationDate,
          'MM/dd/yyyy'
        );
        $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewRequestedBy =
          $scope.federationApplicationReviewData.requestedInformationBy;
      };
      $scope.updateFederationRequestForInformation = function() {
        $scope.updateRequestForInformationErrors = [];
        if (!$scope.federationApplicationReviewRequestForInformation) {
          $scope.updateRequestForInformationErrors.push(
            'Date of Request is required.'
          );
          $scope.updateRequestForInformationErrors.push(
            'Requested By is required.'
          );
        } else if (
          !$scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest
        ) {
          $scope.updateRequestForInformationErrors.push(
            'Date of Request is required.'
          );
        } else if (
          !$scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy
        ) {
          $scope.updateRequestForInformationErrors.push(
            'Requested By is required.'
          );
        } else if (
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest &&
          $scope.federationApplicationReviewData.requestedInformationDate &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy &&
          $scope.federationApplicationReviewData.requestedInformationBy &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest ===
            $scope.federationApplicationReviewData.requestedInformationDate &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy ===
            $scope.federationApplicationReviewData.requestedInformationBy
        ) {
          $scope.updateRequestForInformationErrors.push(
            "The entered 'Date of Request' and 'Requested By' rate is same as the existing 'Request For Information'."
          );
        } else if (
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy
        ) {
          var dateOfRequest = new Date(
            $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewDateOfRequest
          );
          var requestedBy =
            $scope.federationApplicationReviewRequestForInformation
              .federationApplicationReviewRequestedBy;
          federationRequestForInformationData = {
            dateOfRequest: FederationApplicationService.toUTCDate(
              dateOfRequest
            ),
            requestedBy: requestedBy
          };
          FederationApplicationReviewService.updateFederationRequestForInformation(
            federationApplicationId,
            federationRequestForInformationData
          )
            .then(function(res) {
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                $scope.federationApplicationReviewData &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $scope.updateRequestForInformationErrors = [];
                $scope.federationApplicationReviewData.requestedInformationDate = $filter(
                  'date'
                )(
                  federationApplicationReviewResponse
                    .federationApplicationReviewBean.requestedInformationDate,
                  'MM/dd/yyyy'
                );
                $scope.federationApplicationReviewData.requestedInformationBy =
                  federationApplicationReviewResponse.federationApplicationReviewBean.requestedInformationBy;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $('#requestForInformation').modal('toggle'); // $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                "Error updating the Federation Application 'Request for Information' for the federation application id : " +
                federationApplicationId;
              $scope.showFederationApplicationReviewErrorMessage(error);
            });
        }
      };

      $scope.updateTR = function() {
        $scope.updateTotalRevenueErrors = [];
        if (!$scope.tempReviewerTotalRevenue) {
          $scope.tempReviewerTotalRevenue = null;
        } else if (
          $scope.tempReviewerTotalRevenue &&
          !ValidationUtil.isNumeric($scope.tempReviewerTotalRevenue)
        ) {
          $scope.updateTotalRevenueErrors.push(
            'Please enter a valid total revenue.'
          );
          return;
        }
        $scope.tempReviewerTotalRevenue = $scope.tempReviewerTotalRevenue;
        $scope.federationApplicationReviewAFR.tr =
          $scope.tempReviewerTotalRevenue;

        FederationApplicationReviewService.updateFederationTR(
          federationApplicationId,
          $scope.federationApplicationReviewAFR.tr
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $scope.showFederationApplicationReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
              $scope.federationApplicationReviewData &&
              federationApplicationReviewResponse.federationApplicationReviewBean
            ) {
              $scope.updateTotalRevenueErrors = [];
              $scope.federationApplicationReviewData.totalRevenue =
                federationApplicationReviewResponse.federationApplicationReviewBean.totalRevenue;
              $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
              $('#updateTotalRevenue').modal('toggle');
            }
          })
          .catch(function(err) {
            var error =
              "Error updating the Federation Application 'Total Revenue' for the federation application id : " +
              federationApplicationId;
            $scope.showFederationApplicationReviewErrorMessage(error);
          });
      }; // end updateTotalRevenue

      $scope.deleteTR = function() {
        $scope.updateTotalRevenueErrors = [];
        $scope.tempReviewerTotalRevenue = null;
        $scope.federationApplicationReviewAFR.tr = null;

        FederationApplicationReviewService.updateFederationTR(
          federationApplicationId,
          null
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $scope.showFederationApplicationReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
              $scope.federationApplicationReviewData &&
              federationApplicationReviewResponse.federationApplicationReviewBean
            ) {
              $scope.updateTotalRevenueErrors = [];
              $scope.federationApplicationReviewData.totalRevenue =
                federationApplicationReviewResponse.federationApplicationReviewBean.totalRevenue;
              $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
            }
          })
          .catch(function(err) {
            var error =
              "Error updating the Federation Application 'Total Revenue' for the federation application id : " +
              federationApplicationId;
            $scope.showFederationApplicationReviewErrorMessage(error);
          });
      }; // end deleteTR

      /* Get federation application steps */
      FederationApplicationService.getFederationStatus(
        federationApplicationId,
        function(data) {
          angular.forEach(data, function(part, index, theArray) {
            if (part.completenessSetBy) {
              FederationApplicationReviewService.getUserById(
                part.completenessSetBy
              ).then(function(userResp) {
                var user = userResp.data;
                data[index].completenessSetByFN = user.firstName;
                data[index].completenessSetByLN = user.lastName;
              });
            }
            if (part.preliminarySetBy) {
              FederationApplicationReviewService.getUserById(
                part.preliminarySetBy
              ).then(function(userResp) {
                var user = userResp.data;
                data[index].preliminarySetByFN = user.firstName;
                data[index].preliminarySetByLN = user.lastName;
              });
            }
            if (part.staffSetBy) {
              FederationApplicationReviewService.getUserById(
                part.staffSetBy
              ).then(function(userResp) {
                var user = userResp.data;
                data[index].staffSetByFN = user.firstName;
                data[index].staffSetByLN = user.lastName;
              });
            }
            if (part.finalSetBy) {
              FederationApplicationReviewService.getUserById(
                part.finalSetBy
              ).then(function(userResp) {
                var user = userResp.data;
                data[index].finalSetByFN = user.firstName;
                data[index].finalSetByLN = user.lastName;
              });
            }
          });

          $scope.applicationStatus = data;
        }
      );

      /* set step review status */
      $scope.setStepReviewLevelStatus = function(
        applicationId,
        step,
        reviewLevel,
        status
      ) {
        FederationApplicationReviewService.setStepReviewStatus(
          applicationId,
          'Step' + step,
          reviewLevel,
          status
        ).then(function(res) {
          var data = res.data.value;
          /*update current binding*/
          angular.forEach($scope.applicationStatus, function(
            part,
            index,
            theArray
          ) {
            if (part.stepNo === step) {
              if (reviewLevel === 'completenessReviewStatus') {
                $scope.applicationStatus[index].completenessReviewStatus =
                  data.completenessReviewStatus;
                $scope.applicationStatus[index].completenessSetBy =
                  data.completenessSetBy;
                $scope.applicationStatus[index].completenessSetByDate =
                  data.completenessSetByDate;

                FederationApplicationReviewService.getUserById(
                  data.completenessSetBy
                ).then(function(userResp) {
                  var user = userResp.data;
                  $scope.applicationStatus[index].completenessSetByFN =
                    user.firstName;
                  $scope.applicationStatus[index].completenessSetByLN =
                    user.lastName;
                });
              } else if (reviewLevel === 'preliminaryReviewStatus') {
                $scope.applicationStatus[index].preliminaryReviewStatus =
                  data.preliminaryReviewStatus;
                $scope.applicationStatus[index].preliminarySetBy =
                  data.preliminarySetBy;
                $scope.applicationStatus[index].preliminarySetByDate =
                  data.preliminarySetByDate;

                FederationApplicationReviewService.getUserById(
                  data.preliminarySetBy
                ).then(function(userResp) {
                  var user = userResp.data;
                  $scope.applicationStatus[index].preliminarySetByFN =
                    user.firstName;
                  $scope.applicationStatus[index].preliminarySetByLN =
                    user.lastName;
                });
              } else if (reviewLevel === 'staffReviewStatus') {
                $scope.applicationStatus[index].staffReviewStatus =
                  data.staffReviewStatus;
                $scope.applicationStatus[index].staffSetBy = data.staffSetBy;
                $scope.applicationStatus[index].staffSetByDate =
                  data.staffSetByDate;

                FederationApplicationReviewService.getUserById(
                  data.staffSetBy
                ).then(function(userResp) {
                  var user = userResp.data;
                  $scope.applicationStatus[index].staffSetByFN = user.firstName;
                  $scope.applicationStatus[index].staffSetByLN = user.lastName;
                });
              } else if (reviewLevel === 'finalReviewStatus') {
                $scope.applicationStatus[index].finalReviewStatus =
                  data.finalReviewStatus;
                $scope.applicationStatus[index].finalSetBy = data.finalSetBy;
                $scope.applicationStatus[index].finalSetByDate =
                  data.finalSetByDate;

                FederationApplicationReviewService.getUserById(
                  data.finalSetBy
                ).then(function(userResp) {
                  var user = userResp.data;
                  $scope.applicationStatus[index].finalSetByFN = user.firstName;
                  $scope.applicationStatus[index].finalSetByLN = user.lastName;
                });
              }
            }
          });
        });
      };

      $scope.resetFederationApplicationReviewStatus = function() {
        $scope.federationApplicationReviewStatus =
          $scope.federationApplicationReviewData.applicationReviewStatus;
        $scope.updateFederationApplicationReviewStatusErrors = [];
      };
      $scope.updateFederationApplicationReviewStatus = function() {
        $scope.updateFederationApplicationReviewStatusErrors = [];
        if (!$scope.federationApplicationReviewStatus) {
          $scope.updateFederationApplicationReviewStatusErrors.push(
            'Please select the application status.'
          );
        } else if (
          $scope.federationApplicationReviewStatus &&
          $scope.federationApplicationReviewData.applicationReviewStatus &&
          $scope.federationApplicationReviewStatus ===
            $scope.federationApplicationReviewData.applicationReviewStatus
        ) {
          $scope.updateFederationApplicationReviewStatusErrors.push(
            "The selected application status is same as the existing 'Application Status'. Please select the different application status."
          );
        } else if ($scope.federationApplicationReviewStatus) {
          var newFederationApplicationReviewStatus =
            $scope.federationApplicationReviewStatus;
          FederationApplicationReviewService.updateFederationApplicationReviewStatus(
            federationApplicationId,
            newFederationApplicationReviewStatus
          )
            .then(function(res) {
              var data = res.data;
              performRoleChecks(
                $scope.federationApplication.cfcCode,
                newFederationApplicationReviewStatus
              );

              var federationApplicationReviewResponse = data;

              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                $scope.federationApplicationReviewData &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $scope.federationApplicationReviewData.applicationReviewStatus =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatus;
                $scope.federationApplicationReviewData.applicationReviewStatusBy =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatusBy;
                $scope.federationApplicationReviewData.applicationReviewStatusDate =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatusDate;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.updateFederationApplicationReviewStatusErrors = []; //  $('#applicationReviewStatus').modal().hide();
                //  $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the Federation Application Review status for the federation application ID : ' +
                federationApplicationId;
              $scope.showFederationApplicationReviewErrorMessage(error);
            })
            .finally(getFederationReviewDetails);
        }
      };

      // Start - edit and update zone for review

      $scope.retrieveActiveZonesForReview = function(year) {
        FederationApplicationReviewService.retrieveActiveZones(year)
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $('#editZoneForReview').modal('toggle');
              $scope.setEditZoneForReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $('#editZoneForReview').modal('toggle');
              $scope.federationApplicationReviewData.availableZonesForReview =
                federationApplicationReviewResponse.cfcZoneBeanList;
              $scope.federationApplicationReviewData.selectedZoneForReview = {};
              $scope.federationApplicationReviewData.selectedZoneForReview.zoneId =
                $scope.federationApplicationReviewData.zoneIdForReview;
              $scope.federationApplicationReviewData.selectedZoneForReview.zoneName =
                $scope.federationApplicationReviewData.zoneNameForReview;
              $scope.setEditZoneForReviewErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the zones. Please try later!';
            $scope.setFederationApplicationReviewErrorMessage(error);
          });
      }; // end retrieveActiveZones

      $scope.resetEditZoneForReview = function() {
        $scope.federationApplicationReviewData.selectedZoneForReview.zoneId =
          $scope.federationApplicationReviewData.zoneIdForListing;
        $scope.federationApplicationReviewData.selectedZoneForReview.zoneName =
          $scope.federationApplicationReviewData.zoneNameForListing;

        $scope.setEditZoneForReviewErrorMessage('');
      };

      $scope.updateZoneForReview = function() {
        $scope.editZoneForReviewErrors = [];

        if (!$scope.federationApplicationReviewData) {
          $scope.editZoneForReviewErrors.push('Please select the zone.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.zoneIdForReview &&
          $scope.federationApplicationReviewData.selectedZoneForReview &&
          $scope.federationApplicationReviewData.zoneIdForReview ===
            $scope.federationApplicationReviewData.selectedZoneForReview.zoneId
        ) {
          $scope.editZoneForReviewErrors.push(
            'The zone selected is same as current zone for review. Please select a different zone.'
          );
        } else if (
          $scope.federationApplicationReviewData.selectedZoneForReview &&
          $scope.federationApplicationReviewData.selectedZoneForReview.zoneId
        ) {
          var newSelectedZoneId =
            $scope.federationApplicationReviewData.selectedZoneForReview.zoneId;

          FederationApplicationReviewService.updateZoneForReview(
            federationApplicationId,
            newSelectedZoneId
          )
            .then(function(res) {
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setEditZoneForReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.zoneIdForReview =
                  $scope.federationApplicationReviewData.selectedZoneForReview.zoneId;
                $scope.federationApplicationReviewData.zoneNameForReview =
                  $scope.federationApplicationReviewData.selectedZoneForReview.zoneName;

                $('#editZoneForReview')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();

                /* $state.go('zonememberapplications', {'federationApplicationId' : $scope.federationApplicationReviewData.federationId, 'zoneId' : newSelectedZoneId});
                 */
                $state.go('zoneApplications', {
                  zoneId: newSelectedZoneId,
                  pledgeYear: $scope.federationApplicationReviewData.year,
                  zoneUserRole: null
                });
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the new zone for review for the federation application Id : ' +
                federationApplicationId;
              $scope.setEditZoneForReviewErrorMessage(error);
            });
        } // end else if
      };

      $scope.viewPopup = function(popupId) {
        $('#' + popupId).modal();
      };

      $scope.setEditZoneForReviewErrorMessage = function(error) {
        $scope.editZoneForReviewErrors = [];
        $scope.editZoneForReviewErrors = error;
      };

      // End - edit and update zone for review
      $scope.setCharityInformation = function(data) {
        $scope.charityInformation = data;
      };

      /* Show contact information modal */
      $scope.showContactInformation = function() {
        /*get charity dashboard data for contact information*/

        $scope.charityInformation = {};
        FederationApplicationReviewService.getCharityDashboardData(
          $scope.federationApplicationReviewData.cfcCode
        ).then(function(res) {
          $('#contactInfo').modal('toggle');

          $scope.charityInformation = res.data.value;
        });
      };

      var type = 'FEDERATION';
      $scope.appType = type;
      function getAppealStatus(federationApplicationReviewData) {
        $scope.federationAppealData =
          federationApplicationReviewData.charityAppeal;
        if ($scope.federationAppealData != null) {
          $scope.appealStatusOriginalValue = $scope.federationAppealData.status;
        } else {
          $scope.appealStatusOriginalValue = null;
        }
        $scope.appealStatusUpdateReason = null;
      }

      $scope.updateFederationApplicationAppealStatus = function() {
        AppealService.UpdateApplicationAppealStaus(
          $scope.federationAppealData.appealId,
          $scope.federationAppealData.status,
          $scope.appealStatusUpdateReason
        )
          .then(function(res) {
            $scope.appealStatusOriginalValue = null;
            $scope.appealStatusUpdateReason = null;
            var status = res.data.appealStatus.toUpperCase();
            switch (status) {
              case 'DENIED':
              case 'PENDING':
                status = 'Denied';
                break;
              case 'IN_REVIEW':
                status = 'In Appeal';
                break;
              case 'APPROVED':
                status = 'Approved';
                break;
            }
            $scope.federationApplicationReviewStatus = status;
            $scope.updateFederationApplicationReviewStatus();
            AppealService.sendAppealStatusUpdateEmail(
              $scope.federationApplicationReviewData.cfcCode,
              $scope.campaignId,
              $scope.federationApplicationId,
              $scope.appType,
              $scope.federationAppealData.status,
              $scope.federationApplication.denialOverturnedWithWarning
            );
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.cancelUpdateApplicationAppeal = function() {
        if ($scope.previousStatus) {
          $scope.federationAppealData.status = $scope.previousStatus;
        }
        $('#charityAppealStatus').modal('hide');
      };

      $('#charityAppealStatus').on('show.bs.modal', function(e) {
        if ($scope.federationAppealData && $scope.federationAppealData.status) {
          $scope.previousStatus = $scope.federationAppealData.status;
        }
      });

      $('#charityAppealStatus').on('hidden.bs.modal', function(e) {
        $scope.previousStatus = null;
      });

      $scope.gotoApplicationPage = function(
        application,
        isEnrollmentAvailable,
        stepPage,
        federationAuthority
      ) {
        if (
          federationAuthority &&
          (application.applicationStatus === 'IN_PROGRESS' ||
            application.applicationStatus === 'MORE_INFO_UNSIGNED')
        ) {
          gotoStepPage1(application, isEnrollmentAvailable, stepPage);
        } else if (
          application.applicationStatus !== 'WITHDRAWN' &&
          application.applicationStatus !== 'DISBANDED'
        ) {
          gotoStepPage2(application, stepPage);
        }
      };
      var gotoStepPage1 = function(
        application,
        isEnrollmentAvailable,
        stepPage
      ) {
        FederationApplicationService.reset();
        var applicationId = application.federationId;
        var status = application.applicationStatus;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        if (!isEnrollmentAvailable && status !== 'MORE_INFO_UNSIGNED') {
          $scope.error = 'The Charity Application Period is currently Closed';
        } else {
          $scope.federationNavbar.pledgeYear = pledgeYear;
          $scope.federationNavbar.cfcCode = cfcCode;
          CharityService.findCharityWithCFC(application.cfcCode).then(function(
            resp
          ) {
            $scope.federationNavbar.charityName = resp.data.charityName;
          });
          FederationApplicationService.setPledgeYear(pledgeYear);
          FederationApplicationService.setCfcCode(cfcCode);
          $scope.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode).then(function(roleResp) {
            var role = roleResp.data;
            var userSession = FederationApplicationService.getUserSession();
            userSession.cfcCode = cfcCode;
            userSession.applicationId = applicationId;
            userSession.userRole = role.userRole;
            userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
              new Date()
            );
            userSession.$save(function(response) {
              FederationApplicationService.setUserSession(response);
            });
          });
          FederationApplicationService.editFederationApplication(applicationId);
          $state.go('federationStep' + stepPage, { mode: 'EDIT' });
          $scope.setModuleName('CfcCharity.federation_application');
        }
      };
      var gotoStepPage2 = function(application, stepPage) {
        var applicationId = application.federationId;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        $scope.federationNavbar.pledgeYear = pledgeYear;
        $scope.federationNavbar.cfcCode = cfcCode;
        CharityService.findCharityWithCFC(application.cfcCode).then(function(
          charityResp
        ) {
          $scope.federationNavbar.charityName = charityResp.data.charityName;
        });
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        $scope.appVisibility.viewMode = 'VIEW';
        FederationApplicationService.editFederationApplication(applicationId);
        $state.go('federationStep' + stepPage, { mode: 'VIEW' });
        $scope.setModuleName('CfcCharity.federation_application');
      };

      // Start - edit and update zone for listing

      $scope.retrieveActiveZonesForListing = function() {
        FederationApplicationReviewService.retrieveActiveZones()
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $('#editZoneForListing').modal('toggle');
              $scope.setEditZoneForReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $('#editZoneForListing').modal('toggle');
              $scope.federationApplicationReviewData.availableZonesForListing =
                federationApplicationReviewResponse.cfcZoneBeanList;
              $scope.federationApplicationReviewData.selectedZoneForListing = {};
              $scope.federationApplicationReviewData.selectedZoneForListing.zoneId =
                $scope.federationApplicationReviewData.zoneIdForListing;
              $scope.federationApplicationReviewData.selectedZoneForListing.zoneName =
                $scope.federationApplicationReviewData.zoneNameForListing;
              $scope.setEditZoneForReviewErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the zones. Please try later!';
            $scope.setFederationApplicationReviewErrorMessage(error);
          });
      }; // end retrieveActiveZones

      $scope.resetEditZoneForListing = function() {
        $scope.federationApplicationReviewData.selectedZoneForListing.zoneId =
          $scope.federationApplicationReviewData.zoneIdForListing;
        $scope.federationApplicationReviewData.selectedZoneForListing.zoneName =
          $scope.federationApplicationReviewData.zoneNameForListing;
        $scope.setEditZoneForListingErrorMessage('');
      };

      $scope.updateZoneForListing = function() {
        $scope.editZoneForListingErrors = [];

        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.selectedZoneForListing &&
          ($scope.federationApplicationReviewData.selectedZoneForListing
            .zoneId === 0 ||
            !$scope.federationApplicationReviewData.selectedZoneForListing
              .zoneName)
        ) {
          $scope.editZoneForListingErrors.push('Please select the zone.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.zoneIdForListing &&
          $scope.federationApplicationReviewData.selectedZoneForListing &&
          $scope.federationApplicationReviewData.zoneIdForListing ===
            $scope.federationApplicationReviewData.selectedZoneForListing.zoneId
        ) {
          $scope.editZoneForListingErrors.push(
            'The zone selected is same as current zone for review. Please select a different zone.'
          );
        } else if (
          $scope.federationApplicationReviewData.selectedZoneForListing &&
          $scope.federationApplicationReviewData.selectedZoneForListing.zoneId
        ) {
          var newSelectedZoneId =
            $scope.federationApplicationReviewData.selectedZoneForListing
              .zoneId;

          FederationApplicationReviewService.updateZoneForListing(
            federationApplicationId,
            newSelectedZoneId
          )
            .then(function(res) {
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setEditZoneForListingErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.zoneIdForListing =
                  $scope.federationApplicationReviewData.selectedZoneForListing.zoneId;
                $scope.federationApplicationReviewData.zoneNameForListing =
                  $scope.federationApplicationReviewData.selectedZoneForListing.zoneName;

                $('#editZoneForListing')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();

                /*   $state.go('zonememberapplications', {'federationApplicationId' : $scope.federationApplicationReviewData.federationId, 'zoneId' : newSelectedZoneId});
                 */
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the new zone for listing for the federation application Id : ' +
                federationApplicationId;
              $scope.setEditZoneForReviewErrorMessage(error);
            });
        } // end else if
      };

      $scope.setEditZoneForListingErrorMessage = function(error) {
        $scope.editZoneForListingErrors = [];
        $scope.editZoneForListingErrors = error;
      };

      // End - edit and update zone for listing

      // Start - edit and update review group

      $scope.retrieveReviewGroups = function() {
        FederationApplicationReviewService.retrieveReviewGroups()
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $('#editReviewGroup').modal('toggle');
              $scope.setEditReviewGroupErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $('#editReviewGroup').modal('toggle');
              $scope.federationApplicationReviewData.availableReviewGroups =
                federationApplicationReviewResponse.reviewGroupBeanList;
              $scope.federationApplicationReviewData.selectedReviewGroup = {};
              $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupId =
                $scope.federationApplicationReviewData.reviewGroupId;
              $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupName =
                $scope.federationApplicationReviewData.reviewGroupName;
              $scope.setEditReviewGroupErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the review groups. Please try later!';
            $scope.setEditReviewGroupErrorMessage(error);
          });
      }; // end retrieveActiveZonesr

      // start - edit review group
      $scope.resetEditReviewGroup = function() {
        $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupId =
          $scope.federationApplicationReviewData.reviewGroupId;
        $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupName =
          $scope.federationApplicationReviewData.reviewGroupName;
        $scope.setEditReviewGroupErrorMessage('');
      };

      $scope.updateReviewGroup = function() {
        $scope.editReviewGroupErrors = [];

        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.selectedReviewGroup &&
          ($scope.federationApplicationReviewData.selectedReviewGroup
            .reviewGroupId === 0 ||
            !$scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupName)
        ) {
          $scope.editReviewGroupErrors.push('Please select the review group.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.reviewGroupId &&
          $scope.federationApplicationReviewData.selectedReviewGroup &&
          $scope.federationApplicationReviewData.reviewGroupId ===
            $scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupId
        ) {
          $scope.editReviewGroupErrors.push(
            'The review group selected is same as current review group for review. Please select a review group.'
          );
        } else if (
          $scope.federationApplicationReviewData.selectedReviewGroup &&
          $scope.federationApplicationReviewData.selectedReviewGroup
            .reviewGroupId
        ) {
          ApplicationDashboardService.updateGroupNumber(
            federationApplicationId,
            $scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupName,
            true
          )
            .then(function(res) {
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.message.type === 'ERROR')
              ) {
                $scope.setEditReviewGroupErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.message.type === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.reviewGroupId =
                  $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupId;
                $scope.federationApplicationReviewData.reviewGroupName =
                  $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupName;

                $('#editReviewGroup')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the new review group for the federation application Id : ' +
                federationApplicationId;
              $scope.setEditReviewGroupErrorMessage(error);
            });
        } // end else if
      };

      $scope.setEditReviewGroupErrorMessage = function(error) {
        $scope.editReviewGroupErrors = [];
        $scope.editReviewGroupErrors = error;
      };

      // end - edit and update review group

      // start - retrieve reviewers list

      $scope.retrieveReviewers = function(
        federationApplicationId,
        zoneIdForReview,
        federationApplicationReviewOverviewByLevelBean
      ) {
        $scope.assignReviewerErrors = [];
        FederationApplicationReviewService.retrieveReviewers(
          federationApplicationId,
          zoneIdForReview
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $scope.assignReviewerErrors.push(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $scope.federationApplicationReviewData.availableReviewers =
                federationApplicationReviewResponse.reviewerUserBeanList;

              var reviewLevel =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewLevel
                  : null;

              $scope.federationApplicationReviewData.reviewLevel = reviewLevel;

              $scope.federationApplicationReviewData.selectedReviewer = {};
              var reviewerUserId =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerUserId
                  : null;

              var reviewerFirstName =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerFirstName
                  : null;
              var reviewerLastName =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerLastName
                  : null;

              var reviewerAssignedDate =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerAssignedDate
                  : null;

              $scope.federationApplicationReviewData.selectedReviewer.reviewerUserId = reviewerUserId;
              $scope.federationApplicationReviewData.reviewerUserId = reviewerUserId;

              var reviewerUserName =
                reviewerFirstName != null ? reviewerFirstName : '';
              reviewerUserName +=
                reviewerLastName != null ? ' ' + reviewerLastName : '';

              $scope.federationApplicationReviewData.selectedReviewer.reviewerUserName = reviewerUserName;
              $scope.federationApplicationReviewData.reviewerUserName = reviewerUserName;

              $scope.federationApplicationReviewData.reviewerAssignedDate = reviewerAssignedDate;

              $scope.setAssignReviewerErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the reviewers. Please try later!';
            $scope.setAssignReviewerErrorMessage(error);
          });
      }; // end retrieveReviewers

      // end - retrieve reviewers list

      // start - reset eviewer
      $scope.resetReviewer = function() {
        $scope.federationApplicationReviewData.selectedReviewer.reviewerUserId =
          '';
        $scope.federationApplicationReviewData.selectedReviewer.reviewerUserName =
          '';
        $scope.setAssignReviewerErrorMessage('');
      };
      // end - reset reviewer

      // start - assign reviewer

      $scope.assignReviewer = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel
      ) {
        $scope.assignReviewerErrors = [];

        if (
          $scope.federationApplicationReviewData.selectedReviewer &&
          (!$scope.federationApplicationReviewData.selectedReviewer
            .reviewerUserId ||
            !$scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserName)
        ) {
          $scope.assignReviewerErrors.push('Please select the reviewer.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.selectedReviewer &&
          $scope.federationApplicationReviewData.reviewerUserId &&
          $scope.federationApplicationReviewData.selectedReviewer
            .reviewerUserId &&
          $scope.federationApplicationReviewData.reviewerUserId ===
            $scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserId
        ) {
          $scope.assignReviewerErrors.push(
            'The reviewer selected is same as current reviewer for federation application review. Please select a different reviewer.'
          );
        } else if (
          federationApplicationId &&
          zoneIdForReview &&
          reviewLevel &&
          $scope.federationApplicationReviewData.selectedReviewer
        ) {
          FederationApplicationReviewService.assignReviewer(
            federationApplicationId,
            zoneIdForReview,
            reviewLevel,
            $scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserId
          )
            .then(function(res) {
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.assignReviewerErrors.push(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.federationApplicationReviewOverviewByLevelBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewOverviewByLevelBeanList;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;

                /*get federation review levels*/
                FederationApplicationReviewService.getFederationReviewDetails(
                  federationApplicationId
                )
                  .then(function(resp) {
                    var levelDetails = resp.data;
                    var reviewLevels =
                      levelDetails.federationApplicationReviewBean
                        .federationApplicationReviewOverviewByLevelBeanList;
                    var currentLevel = reviewLevels.filter(function(level) {
                      if (level.reviewLevel === reviewLevel) {
                        $scope.setReviewLevelBean(level);
                        return level;
                      }
                    });
                  })
                  .catch(function(err) {
                    var error =
                      'Error assigning the reviewer for the ' +
                      reviewLevel +
                      ' for the federation application Id : ' +
                      federationApplicationId +
                      ' and the zone id ' +
                      zoneIdForReview;
                    $scope.assignReviewerErrors.push(error);
                  });

                $('#assignReviewer').modal('toggle');

                //$scope.resetReviewer();
              }
            })
            .catch(function(er) {
              var error =
                'Error assigning the reviewer for the ' +
                reviewLevel +
                ' for the federation application Id : ' +
                federationApplicationId +
                ' and the zone id ' +
                zoneIdForReview;
              $scope.assignReviewerErrors.push(error);
            });
        } // end else if
      };

      $scope.setAssignReviewerErrorMessage = function(error) {
        //  $scope.assignReviewerErrors = [];
        $scope.assignReviewerErrors = error;
      };

      // end - add reviewer comment

      // start - reset reviewer comment
      $scope.resetReviewerComment = function() {
        $scope.federationApplicationReviewData.reviewerComment = '';
        $scope.addReviewerCommentErrors = [];
      };

      // end - reset reviewer

      // start - add reviewer comment

      $scope.addReviewerComment = function(
        federationApplicationId,
        zoneIdForReview
      ) {
        $scope.addReviewerCommentErrors = [];
        if (
          !$scope.federationApplicationReviewData.reviewerComment ||
          (($scope.federationApplicationReviewData.reviewerComment &&
            $scope.federationApplicationReviewData.reviewerComment.trim()
              .length < 1) ||
            $scope.federationApplicationReviewData.reviewerComment.length >
              2000)
        ) {
          $scope.addReviewerCommentErrors.push(
            'Please enter a comment between 1 and 2000 characters.'
          );
        } else if (
          $scope.federationApplicationReviewData.reviewerComment &&
          ($scope.federationApplicationReviewData.reviewerComment.length > 0 &&
            $scope.federationApplicationReviewData.reviewerComment.length <=
              2000)
        ) {
          FederationApplicationReviewService.addReviewerComment(
            federationApplicationId,
            zoneIdForReview,
            $scope.federationApplicationReviewData.reviewerComment
          )
            .then(function(res) {
              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setAddReviewerCommentErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.resetReviewerComment();
                $('#comment').modal('toggle');
              }
            })
            .catch(function(err) {
              var error =
                'Error adding the reviewer comment for the federation application Id : ' +
                federationApplicationId +
                ' and the zone id ' +
                zoneIdForReview;
              $scope.setAddReviewerCommentErrorMessage(error);
            });
        } // end else if
      };

      $scope.setAddReviewerCommentErrorMessage = function(error) {
        $scope.addReviewerCommentErrors = [];
        $scope.addReviewerCommentErrors.push(error);
      };

      // end - add reviewer comment

      // Start - updateDetermination
      $scope.updateDetermination = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel,
        determination
      ) {
        $scope.updateDeterminationsErrors = [];
        if (!determination) {
          $scope.updateDeterminationsErrors.push(
            'Please select the determination for the ' + reviewLevel
          );
        } else if (determination) {
          $scope.determinationStatus = determination;
          FederationApplicationReviewService.updateDetermination(
            federationApplicationId,
            zoneIdForReview,
            reviewLevel,
            determination
          )
            .then(function(res) {
              $scope.determinationUpdateCounter += 1;

              var federationApplicationReviewResponse = res.data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                $scope.federationApplicationReviewData &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $scope.federationApplicationReviewData.federationApplicationReviewOverviewByLevelBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewOverviewByLevelBeanList;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.federationApplicationReviewError = '';
              }
            })
            .finally(getFederationReviewDetails)
            .catch(function(err) {
              var error =
                'Error updating the determination for the ' +
                reviewLevel +
                ' for the federation application id ' +
                federationApplicationId;
              $scope.showFederationApplicationReviewErrorMessage(error);
            });
        }
        if ($scope.updateDeterminationsErrors.length > 0) {
          $scope.showFederationApplicationReviewErrorMessage(
            $scope.updateDeterminationsErrors.join()
          );
        } else {
        }
      };

      $scope.showFederationApplicationReviewErrorMessage = function(error) {
        $scope.federationApplicationReviewError = error;
      };

      // End - updateDetermination

      $scope.showFederationApplicationReviewErrorMessage = function(error) {
        $scope.federationApplicationReviewError = error;
      };

      $scope.goToDenialReasonsPage = function(isFed) {
        // review-denial-reasons
        $state.go('review-denial-reasons', {
          isFederation: isFed,
          applicationId: $scope.federationApplicationId,
          sourcePageName: $state.current.name,
          sourcePageParams: $stateParams,
          charityType: $scope.charityType
        });
      };
      $scope.goToEmailPage = function(isFed) {
        // review-email-dashboard
        $state.go('email-dashboard', {
          isFederation: isFed,
          applicationId: $scope.federationApplicationId,
          sourcePageName: $state.current.name,
          sourcePageParams: $stateParams,
          charityType: $scope.charityType
        });
      };

      $scope.goToAppealAdminPage = function(type) {
        // review-admin-appeal-dashboard
        $state.go('admin-appeal-dashboard', {
          applicationType: type,
          applicationId: $scope.federationApplicationId
        });
      };

      $('#requestForInformation').on('show.bs.modal', function(e) {
        var currentUser = BrowserEventService.getCurrentUser();
        $('#federationApplicationReviewRequestedBy').val(
          currentUser.firstName + ' ' + currentUser.lastName
        );
        $('#federationApplicationReviewRequestedBy').trigger('change');
      });

      function loadDenialReasons(cfcCode, pledgeYear, applicationStatus) {
        performRoleChecks(cfcCode, applicationStatus);
        DenialReasonsService.getDenialReasonsByYear(pledgeYear).then(
          function(resp) {
            // success
            var cannedDenialReasons = resp.data;

            DenialReasonsService.getSelectDenialReasonsByYearAndCFC(
              pledgeYear,
              cfcCode
            ).then(
              function(resp) {
                // success
                $scope.selectedDenialReasons = resp.data;
                var filteredDR = [];
                angular.forEach($scope.selectedDenialReasons, function(
                  selectedDenialReason,
                  key
                ) {
                  var cannedDenialReason = cannedDenialReasons.filter(function(
                    item
                  ) {
                    return item.id === selectedDenialReason.denialReasonId;
                  })[0];
                  if (
                    cannedDenialReason != null ||
                    cannedDenialReason != undefined
                  ) {
                    selectedDenialReason.reason = cannedDenialReason.reason;
                    selectedDenialReason.applicationStep =
                      cannedDenialReason.applicationStep;
                    selectedDenialReason.issueName =
                      cannedDenialReason.issueName;
                    selectedDenialReason.applicationType =
                      cannedDenialReason.applicationType;

                    if (selectedDenialReason.issueName === 'CUSTOM') {
                      selectedDenialReason.reason =
                        selectedDenialReason.denialReasonText;
                    }
                    filteredDR.push(selectedDenialReason);
                  }
                });
                $scope.selectedDenialReasons = filteredDR;
              },
              function(err) {
                // error
                $log.error(err);
              }
            );
          },
          function(err) {
            // error
            reject(err);
          }
        );
      }

      function performRoleChecks(cfcCode, applicationStatus) {
        $scope.canEditReview = false;
        $scope.canEditAppeal = false;
        var user = BrowserEventService.getCurrentUser();
        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        if (
          secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('OPM_STAFF') >= 0
        ) {
          $scope.canEditReview = isInReviewStatus(applicationStatus);
          $scope.canEditAppeal =
            applicationStatus === 'IN_APPEAL' ? true : false;
        } else if (
          secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0
        ) {
          ZoneCenterService.findCharityAccessEligibility(user.id, cfcCode).then(
            function(res) {
              var isAccessEligible = res.data;
              if (isAccessEligible) {
                $scope.canEditReview = isInReviewStatus(applicationStatus);
              }
            }
          );
        }
      }

      $scope.onCheckChanged = function(reason) {
        DenialReasonsService.updateSelectDenialReasons(reason);
      };

      function isInReviewStatus(appStatus) {
        var status = [
          'IN_REVIEW',
          'IN REVIEW',
          'SUBMITTED',
          'MORE_INFO',
          'MORE INFO'
        ];
        if (status.indexOf(appStatus.toUpperCase()) > -1) {
          return true;
        }
        return false;
      }

      $scope.gotoStep8 = function() {
        $scope.gotoApplicationPage(
          federationDashboardData.federationDetails,
          federationDashboardData.isEnrollmentAvailable,
          '8',
          federationDashboardData.federationAuthority &&
            !$scope.isApplicationChangeDateEnded
        );
      };

      $scope.overrideAFR = function() {
        $scope.overrideAFRErrors = [];
        if (
          $scope.tempFundraisingRateOverride &&
          !$.isNumeric($scope.tempFundraisingRateOverride)
        ) {
          $scope.overrideAFRErrors.push('Please enter a valid rate.');
          return;
        }
        FederationApplicationReviewService.updateFederationAFR(
          federationApplicationId,
          $scope.tempFundraisingRateOverride
        )
          .then(function(res) {
            var federationApplicationReviewResponse = res.data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $scope.showFederationApplicationReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
              $scope.federationApplicationReviewData &&
              federationApplicationReviewResponse.federationApplicationReviewBean
            ) {
              $scope.overrideAFRErrors = [];
              $scope.fundraisingRateOverride =
                federationApplicationReviewResponse.federationApplicationReviewBean.fundRaisingRate;
              $scope.tempFundraisingRateOverride =
                $scope.fundraisingRateOverride;
              $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
              $('#overrideAFR').modal('toggle');
            }
          })
          .catch(function(err) {
            var error =
              "Error updating the Federation Application 'Fundraising Rate' for the federation application id : " +
              federationApplicationId;
            $scope.showFederationApplicationReviewErrorMessage(error);
          });
      };

      $scope.cancelOverrideAFR = function() {
        $scope.overrideAFRErrors = [];
        $scope.tempFundraisingRateOverride = $scope.fundraisingRateOverride;
      };

      $scope.deleteAFROverride = function() {
        FederationApplicationReviewService.updateFederationAFR(
          federationApplicationId,
          null
        ).then(
          function(res) {
            $scope.overrideAFRErrors = [];
            $scope.fundraisingRateOverride =
              res.data.federationApplicationReviewBean.fundRaisingRate;
            $scope.tempFundraisingRateOverride = $scope.fundraisingRateOverride;
            $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
              res.data.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
          },
          function(err) {
            $log.error(err);
          }
        );
      };

      function getAfr(applicationId) {
        CubeSvc.get(
          '/CfcCharity/api/private/federation_application/step8/' +
            applicationId,
          null,
          false
        ).then(function(res) {
          if (res.data.value) {
            $scope.fundraisingRate = res.data.value.fundraisingRate;
          }
        });
      }
    }
  ]);
