// listing.service.js - Service for the Admin Zone Listing module

'use strict';

angular
  .module('CfcCharity.admin.zone.listing')
  .factory('AdminZoneListingService', AdminZoneListingService);

AdminZoneListingService.$inject = ['$http', 'CubeSvc'];

function AdminZoneListingService($http, CubeSvc) {
  var service = {
    getZoneCharityListingInfo: getZoneCharityListingInfo,
    getZoneCharityListingDetails: getZoneCharityListingDetails,
    sortRandomizedZoneFederations: sortRandomizedZoneFederations,
    randomizeZoneFederations: randomizeZoneFederations,
    getRandomizedZoneFederations: getRandomizedZoneFederations,
    getRandomizationHistory: getRandomizationHistory,
    getRandomizationOrder: getRandomizationOrder,
    getRandomizationAccess: getRandomizationAccess
  };
  return service;

  function getZoneCharityListingInfo(zoneId, pledgeYear) {
    var url =
      '/CfcCharity/api/private/admin/zone/getZoneCharityListingInfo/' +
      zoneId +
      '/' +
      pledgeYear;
    return $http.post(url);
  }

  function getZoneCharityListingDetails(campaignId, zoneId) {
    var url =
      '/CfcCharity/api/private/admin/zone/getZoneCharityListingDetails/' +
      campaignId +
      '/' +
      zoneId;
    return $http.post(url);
  }

  function sortRandomizedZoneFederations(campaignId, zoneId) {
    var url =
      '/CfcCharity/api/public/admin/zone/randomizationsort/' +
      campaignId +
      '/' +
      zoneId;
    return CubeSvc.post(url);
  }

  function randomizeZoneFederations(
    campaignId,
    zoneId,
    randomzationResultLength,
    isRefresh
  ) {
    var url =
      '/CfcCharity/api/public/admin/zone/randomization/' +
      campaignId +
      '/' +
      zoneId +
      '/' +
      randomzationResultLength +
      '/' +
      isRefresh;
    return CubeSvc.post(url);
  }

  function getRandomizedZoneFederations(campaignId, zoneId) {
    var url =
      '/CfcCharity/api/public/admin/zone/randomization/' +
      campaignId +
      '/' +
      zoneId;
    return $http.get(url);
  }

  function getRandomizationHistory(campaignId, zoneId) {
    var url =
      '/CfcCharity/api/private/admin/zone/getRandomizationHistory/' +
      campaignId +
      '/' +
      zoneId;
    return $http.post(url);
  }

  function getRandomizationOrder(zoneId, zoneRandomizationId) {
    var url =
      '/CfcCharity/api/private/admin/zone/getRandomizationOrder/' +
      zoneId +
      '/' +
      zoneRandomizationId;
    return $http.post(url);
  }

  function getRandomizationAccess(
    campaignId,
    zoneId,
    randomzationResultLength
  ) {
    var url =
      '/CfcCharity/api/public/admin/zone/randomizationaccess/' +
      campaignId +
      '/' +
      zoneId +
      '/' +
      randomzationResultLength;
    return $http.get(url);
  }
}
