'use strict';

(function() {
  angular
    .module('CfcCharity.review.appeal', [
      'ui.router',
      'CfcCharity.security',
      'hateoas',
      'CfcCharity.common'
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      'HateoasInterceptorProvider',
      'USER_ROLES',
      'SYSTEM_USER_SECONDARY_ROLES',
      config
    ])
    .controller('AppealCtrl', AppealCtrl);

  function config(
    $stateProvider,
    $urlRouterProvider,
    HateoasInterceptorProvider,
    USER_ROLES,
    SYSTEM_USER_SECONDARY_ROLES
  ) {
    $stateProvider.state('appeal-dashboard', {
      url: '/appeal-dashboard',
      views: {
        main: {
          template: require('./appeal.html'),
          controller: 'AppealCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        breadcrumbs: function(ApplicationService, isFed) {
          if (isFed === 'false') {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          } else {
            return [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
              },
              {
                crumbName: 'Federation Application',
                crumbRoute: 'federationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              }
            ];
          }
        },
        pageTitle: 'Review Appeal Dashboard',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.charity_user]
      },
      params: {
        isFederation: null,
        applicationId: null
      },
      resolve: {
        parameterData: function($stateParams) {
          return {
            showAdminAppealControls: false,
            isFederation: $stateParams.isFederation,
            applicationId: $stateParams.applicationId || null
          };
        },
        tokenData: function($stateParams, BreadcrumbTokenDataService) {
          var isFed = $stateParams.isFederation === 'true';
          BreadcrumbTokenDataService.setIsFederation(isFed);
          if (isFed) {
            return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
              $stateParams.applicationId
            );
          } else {
            return BreadcrumbTokenDataService.setApplicationReviewTokenData(
              $stateParams.applicationId
            );
          }
        }
      }
    });
  }

  AppealCtrl.$inject = [
    '$state',
    '$log',
    '$q',
    '$scope',
    '$http',
    '$filter',
    '$window',
    'blockUI',
    'parameterData',
    'BrowserEventService',
    'ZoneCenterService',
    'AppealService',
    'ApplicationWrapperService',
    'FederationApplicationReviewService',
    'DownloadService',
    'CharityService',
    'CharityDashboardService',
    'DenialReasonsService',
    'AdminAppealService',
    'urlAccess',
    'tokenData',
    'ApplicationDashboardService',
    'ApplicationService',
    'FederationDashboardService',
    'FederationApplicationService',
    'CubeSvc',
    '$anchorScroll',
    '$location',
    '$timeout'
  ];

  function AppealCtrl(
    $state,
    $log,
    $q,
    $scope,
    $http,
    $filter,
    $window,
    blockUI,
    parameterData,
    BrowserEventService,
    ZoneCenterService,
    AppealService,
    ApplicationWrapperService,
    FederationApplicationReviewService,
    DownloadService,
    CharityService,
    CharityDashboardService,
    DenialReasonsService,
    AdminAppealService,
    urlAccess,
    tokenData,
    ApplicationDashboardService,
    ApplicationService,
    FederationDashboardService,
    FederationApplicationService,
    CubeSvc,
    $anchorScroll,
    $location,
    $timeout
  ) {
    // using controllerAs syntax, both here and in html, `vm` = this context
    var vm = this;
    var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
    $scope.fileType = {};
    $scope.appealId = {};
    $scope.isAppealDueDateReached = false;
    $scope.isAppealSigned = false;
    var scrollToAOS = false;
    $scope.appealDocumentSignature = {};

    //check the access to show the breadcrumb links
    if (tokenData.federationId != null) {
      if ($scope.$parent.$$childHead.breadcrumbs !== undefined) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              if ($scope.$parent) {
                $scope.$parent.$$childHead.breadcrumbs[0] = res;
              }
            });
        }
      } else {
        if ($scope.$parent.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              if ($scope.$parent) {
                $scope.$parent.$parent.$$childHead.breadcrumbs[0] = res;
              }
            });
        }
      }
    }

    function loadInitialStateValues() {
      vm.canEdit = false;
      vm.webService = 'No';
      vm.adminUsers = false;
      vm.auditor = false;
      vm.hasUploadDeleteAccess = false;
      vm.hasDownloadAccess = false;
      vm.appType = null;
      vm.applicationCertifications = [];
      vm.missingCertifications = [];
      vm.changedAppealDueDate = null;
      vm.canUpdateAppealDueDate = false;
      vm.appNavbar = {};
      vm.federationNavbar = {};
      vm.appVisibility = {};
      vm.hasAOSfile = false;
      vm.isOpmAdmin = false;
    }

    function isOffYear() {
      $scope.isOffYear = {};
      CharityService.getPrevYearApplicationStatus(
        vm.appDash.cfcCode,
        vm.appDash.pledgeYear
      ).then(
        function(res) {
          if (res.data.value) {
            $scope.isOffYear = res.data.value.isOffYear;
          }
        },
        function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        }
      );
      return $scope.isOffYear;
    }

    $scope.$on('$locationChangeSuccess', function(evt, newUrl, oldUrl) {
      if (
        oldUrl.indexOf('aosLocalAttachment') > -1 ||
        oldUrl.indexOf('aosNationalAttachment') > -1
      ) {
        scrollToAOS = true;
      }
    });

    function getAreaOfServices() {
      var url =
        '/CfcCharity/api/private/charity/appeal/aos/' +
        parameterData.applicationId +
        '/' +
        vm.appDash.cfcCode;
      CubeSvc.get(url)
        .then(function(res) {
          var result = res.data.value;
          vm.services = result.aosList;
          vm.charity = result.masterCharityData;
          vm.aosHisList = result.aosHisList;
          vm.charityType = result.charityType;
          vm.fedId = result.federationId;
          vm.showAOS = result.showAOS;
        })
        .catch(function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        });
    }

    $scope.getHistory = function(id) {
      vm.history = [];
      angular.forEach(vm.aosHisList, function(hist) {
        if (hist.id === id) {
          vm.history.push(hist);
        }
      });
      $('#AOShistory').modal();
    };

    $scope.setDeterminationStatus = function(id, isAppealAOS, level, status) {
      var url =
        '/CfcCharity/api/private/charity/aos/determination/' +
        id +
        '/' +
        isAppealAOS +
        '/' +
        level +
        '/' +
        status +
        '/' +
        parameterData.applicationId;
      CubeSvc.post(url)
        .then(function(res) {
          var result = res.data.value;
          vm.services = result.aosList;
          vm.aosHisList = result.aosHisList;
          vm.charityType = result.charityType;
          vm.fedId = result.federationId;
          vm.showAOS = result.showAOS;
        })
        .catch(function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        });
    };

    $scope.editAttachment = function(
      id,
      fedId,
      isAppealAOS,
      mode,
      reviewStatus
    ) {
      if (vm.charityType === 'LOCAL') {
        $state.go('aosLocalAttachment', {
          mode: mode,
          applicationId: parameterData.applicationId,
          id: id,
          parentFedAppId: fedId,
          isAppealAOS: isAppealAOS,
          charityType: vm.charityType,
          cfcCode: vm.appDash.cfcCode,
          pledgeYear: vm.appDash.pledgeYear,
          reviewStatus: reviewStatus
        });
      } else {
        $state.go('aosNationalAttachment', {
          mode: mode,
          applicationId: parameterData.applicationId,
          id: id,
          parentFedAppId: fedId,
          isAppealAOS: isAppealAOS,
          charityType: vm.charityType,
          cfcCode: vm.appDash.cfcCode,
          pledgeYear: vm.appDash.pledgeYear,
          reviewStatus: reviewStatus
        });
      }
    };

    $scope.addAttachment = function() {
      if (vm.charityType === 'LOCAL') {
        $state.go('aosLocalAttachment', {
          mode: 'EDIT',
          applicationId: parameterData.applicationId,
          parentFedAppId: vm.fedId,
          isAppealAOS: true,
          charityType: vm.charityType,
          cfcCode: vm.appDash.cfcCode,
          pledgeYear: vm.appDash.pledgeYear
        });
      } else {
        $state.go('aosNationalAttachment', {
          mode: 'EDIT',
          applicationId: parameterData.applicationId,
          parentFedAppId: vm.fedId,
          isAppealAOS: true,
          charityType: vm.charityType,
          cfcCode: vm.appDash.cfcCode,
          pledgeYear: vm.appDash.pledgeYear
        });
      }
    };

    function getFileTypes() {
      $scope.setFileTypes = [];
      if (vm.appType != null) {
        $scope.setFileTypes = [
          { value: 'File Type:', label: 'File Type:' },
          { value: 'Form990', label: 'IRS Form 990' },
          {
            value: 'EligibleMemberOrganization',
            label: 'Eligible Member Organizations'
          },
          {
            value: 'IrsDetermination',
            label: 'IRS Determination Letter'
          },
          { value: 'dba', label: 'DBA Name Request' },
          {
            value: 'Other',
            label: 'Other'
          }
        ];
        if (
          vm.appDash.fysaFyspWfr &&
          vm.appDash.fysaFyspWfr === true &&
          (vm.appType === 'MEMBER' || vm.appType === 'INDEPENDENT')
        ) {
          $scope.setFysaFileTypes = [
            { value: 'File Type:', label: 'File Type:' },
            {
              value: 'BaseCommander',
              label: 'Base Commander Letter (FSYA/FSYP/WFR only)'
            },
            {
              value: 'Other',
              label: 'Other'
            }
          ];
          $scope.setFileTypes = $scope.setFysaFileTypes;
        } else if (vm.appType === 'FEDERATION') {
          $scope.setFileTypes.splice(5, 0, {
            value: 'AuditedFinancial',
            label: 'Audited Financial Statement'
          });
          $scope.setFileTypes.splice(6, 0, {
            value: 'GoverningBody',
            label: 'Governing Body'
          });
          // File Types for full application
        } else if (
          $scope.isOffYear === false &&
          (vm.appType === 'MEMBER' || vm.appType === 'INDEPENDENT')
        ) {
          $scope.setFileTypes.splice(5, 0, {
            value: 'AuditedFinancial',
            label: 'Audited Financial Statement'
          });
          $scope.setFileTypes.splice(6, 0, {
            value: 'GroupExemption',
            label: 'Group Exemption Letter'
          });
          $scope.setFileTypes.splice(7, 0, {
            value: 'aos',
            label: 'AOS Web-based Services'
          });
          // File types for verification application
        } else if (
          $scope.isOffYear === true &&
          (vm.appType === 'MEMBER' || vm.appType === 'INDEPENDENT')
        ) {
          $scope.setFileTypes = $scope.setFileTypes;
        }
      }
      return $scope.setFileTypes;
    }

    $scope.viewPopup = function(popupId) {
      $scope.fileTypes = getFileTypes();
      $scope.fileType = $scope.fileTypes[0].value;
      if (
        popupId === 'signAppeal' &&
        vm.webService === 'Yes' &&
        !$scope.supportingFile &&
        !vm.hasAOSfile
      ) {
        $('#webMissingInfo').modal();
      } else {
        $('#' + popupId).modal();
      }
    };

    $scope.deleteAOS = function(aosId) {
      $scope.attachmentId = aosId;
      $('#deleteAOS').modal('show');
    };

    $scope.deleteAOSById = function(attachmentId) {
      var url =
        '/CfcCharity/api/private/charity/delete/aos/detail/' +
        attachmentId +
        '/' +
        parameterData.applicationId +
        '/' +
        vm.appDash.cfcCode;
      CubeSvc.get(url)
        .then(function(res) {
          var result = res.data.value;
          vm.services = result.aosList;
          vm.aosHisList = result.aosHisList;
          vm.charityType = result.charityType;
          vm.fedId = result.federationId;
          vm.showAOS = result.showAOS;
        })
        .catch(function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        });
    };

    $scope.loadAppealDocumentSignatureModal = function(
      loadModal,
      appealId,
      appealDocumentId
    ) {
      CubeSvc.performManualCacheRelease();
      AppealService.getAppealDocumentSignature(
        vm.appDash.cfcCode,
        appealId,
        appealDocumentId
      )
        .then(function(res) {
          if (res && res.data && res.data.value) {
            $scope.appealDocumentSignature = res.data.value;
            $scope.appealId = appealId;
            $scope.appealDocumentId = appealDocumentId;
            $('#' + loadModal).modal();
          }
        })
        .catch(function(error) {
          if (error.data !== undefined) {
            $log.error(error.data);
          }
        });
    };

    // async prepares parameter data for use
    function loadStateParams() {
      return $q(function(resolve, reject) {
        if (parameterData) {
          // load from resolved parameterData
          vm.isFederation = parameterData.isFederation;
          vm.applicationId = parameterData.applicationId;
          $scope.applicationId = parameterData.applicationId;
          vm.charityType = parameterData.charityType;

          ApplicationWrapperService.getAppData(
            vm.applicationId,
            'true' === vm.isFederation
          )
            .then(
              function(res) {
                // set to vm
                vm.appDash = res.data.value;
                $scope.campaignId = vm.appDash.campaignId;
                $q(function(resolv, reje) {
                  if (vm.isFederation === 'true') {
                    vm.appType = 'FEDERATION';
                    var federationDashboardData = {};
                    // since pledgeYear and charity name don't come in the federation dashboard response, grab it
                    FederationApplicationReviewService.getFederationDashboardData(
                      vm.applicationId
                    )
                      .then(function(resp) {
                        var data = resp.data.value;
                        federationDashboardData.federationDetails = data;
                        vm.appDash.applicationId = vm.appDash.id;
                        vm.appDash.pledgeYear =
                          federationDashboardData.federationDetails.pledgeYear;
                        vm.appDash.name =
                          federationDashboardData.federationDetails.federationName;
                        vm.appDash.cfcCode =
                          federationDashboardData.federationDetails.cfcCode;
                        CubeSvc.get(
                          '/CfcCharity/api/private/federation/' +
                            parameterData.applicationId
                        ).then(function(res) {
                          vm.application = res.data.value;
                        });
                        resolv();
                        $scope.isFederation = true;
                      })
                      .catch(function(er) {
                        $log.error(er);
                        reje(er);
                      });
                  } else {
                    if (
                      vm.appDash.federationNumber !== null &&
                      vm.appDash.federationNumber !== ''
                    ) {
                      vm.appType = 'MEMBER';
                    } else {
                      vm.appType = 'INDEPENDENT';
                    }
                    $scope.isFederation = false;
                    ApplicationDashboardService.applicationDetailsById(
                      parameterData.applicationId
                    ).then(function(res) {
                      vm.application = res.data.value;
                    });
                    resolv();
                  }
                }).then(function() {
                  initAppealModal();
                  resolve(
                    'successfully loaded state params and dependent app review'
                  );
                  isOffYear();
                  resolve(
                    'determine if application is full or verification application'
                  );
                  getDenialReasons();
                });
              },
              function(err) {
                reject(err);
              }
            )
            .catch(function(err) {
              reject(err);
            });
        } else {
          reject('failed loading state parameter data');
        }
      });
    }

    $scope.$on('appealStatusChangedEvent', function() {
      initAppealModal();
    });

    function initAppealModal() {
      AppealService.getApplicationAppeal(
        vm.appType,
        vm.applicationId,
        vm.appDash.cfcCode
      )
        .then(function(res) {
          $scope.applicationId = vm.applicationId;
          $scope.cfcCode = vm.appDash.cfcCode;

          $scope.appealData = res.data;
          if (
            $scope.appealData == null ||
            $scope.appealData === '' ||
            $scope.appealData.appealStatus === 'Not Started' ||
            $scope.appealData.appealStatus === 'Pending'
          ) {
            $scope.showStepsDiv = true;
          } else {
            $scope.showStepsDiv = false;
          }
          if ($scope.appealData != null) {
            $scope.txtReason = res.data.appealReason;

            // for remaining characters in maxlength for appeal reason
            var maxLength = 2000;
            $('textarea').keyup(function() {
              var length = $(this).val().length;
              length = maxLength - length;
              $('#chars').text(length);
            });

            if ($scope.appealData.isAOSFile) {
              vm.webService = 'Yes';
            } else {
              vm.webService = 'No';
            }
            getApplicationDocuments(
              vm.appDash.campaignId,
              vm.appDash.cfcCode,
              $scope.applicationId
            );

            //check if appeal exists
            $scope.appealId = $scope.appealData.id;

            if ($scope.appealData.isAppealEndDateReached) {
              $scope.isAppealDueDateReached = false;
            } else {
              $scope.isAppealDueDateReached = true;
            }
            if ($scope.appealId != null) {
              getAppealDocuments(
                vm.appDash.campaignId,
                vm.appDash.cfcCode,
                $scope.appealId
              );
            }

            AppealService.getSignAppealHistory(
              res.data.id,
              vm.appDash.cfcCode
            ).then(
              function(res1) {
                $scope.appealHistory = res1.data;
                if (
                  $scope.appealHistory.length > 0 &&
                  $scope.appealHistory[0].isSigned === true
                ) {
                  $scope.isAppealSigned = true;
                } else {
                  $scope.isAppealSigned = false;
                }
              },
              function(error) {
                if (error.data !== undefined) {
                  $log.error(error.data.errorMessage);
                }
              }
            );
          }
        })
        .finally(function() {
          if (scrollToAOS) {
            $location.hash('AOS');
            $timeout(function() {
              $anchorScroll();
            }, 500);
          }
        })
        .catch(function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        });
    }

    function getDenialReasons() {
      DenialReasonsService.getDenialReasonsByYear(
        vm.appDash.pledgeYear,
        vm.appDash.cfcCode
      ).then(
        function(resp) {
          // success
          var cannedDenialReasons = resp.data;

          DenialReasonsService.getSelectDenialReasonsByYearAndCFC(
            vm.appDash.pledgeYear,
            vm.appDash.cfcCode
          ).then(
            function(resp) {
              vm.selectedDenialReasons = resp.data;
              var deletedId = [];
              var temp = [];

              angular.forEach(vm.selectedDenialReasons, function(
                selectedDenialReason
              ) {
                var cannedDenialReason = cannedDenialReasons.filter(function(
                  item
                ) {
                  return item.id === selectedDenialReason.denialReasonId;
                })[0];
                if (
                  cannedDenialReason != undefined ||
                  cannedDenialReason != null
                ) {
                  selectedDenialReason.reason = cannedDenialReason.reason;
                  selectedDenialReason.applicationStep =
                    cannedDenialReason.applicationStep;
                  selectedDenialReason.issueName = cannedDenialReason.issueName;
                  if (selectedDenialReason.issueName === 'CUSTOM') {
                    selectedDenialReason.reason =
                      selectedDenialReason.denialReasonText;
                  }
                  selectedDenialReason.attachments =
                    selectedDenialReason.regionDisplay;
                } else {
                  deletedId.push(selectedDenialReason.denialReasonId);
                }
              });
              if (deletedId.length > 0) {
                angular.forEach(deletedId, function(id) {
                  angular.forEach(vm.selectedDenialReasons, function(rs) {
                    if (id.toString() != rs.denialReasonId.toString()) {
                      temp.push(rs);
                    }
                  });
                });
                vm.selectedDenialReasons = temp;
              }
            },
            function(err) {
              $log.error(err);
            }
          );
        },
        function(err) {
          $log.error(err);
        }
      );
    }

    $scope.gotoAppStep = function(stepNo) {
      if (stepNo.toString().length < 3) {
        stepNo = 'Step' + stepNo;
      }
      stepNo = stepNo.replace(/[\s]/g, '');
      vm.charityAuthority = false;
      CharityService.getCharityUserRole($scope.cfcCode).then(function(res) {
        var userRole = res.data;
        if (userRole) {
          vm.charityAuthority = true;
        }
        if (stepNo === 'Any') {
          vm.goToApplicationDashboard();
        } else {
          if (
            vm.charityAuthority &&
            (vm.application.status === 'IN_PROGRESS' ||
              vm.application.status === 'MORE_INFO_UNSIGNED' ||
              vm.application.applicationStatus === 'IN_PROGRESS' ||
              vm.application.applicationStatus === 'MORE_INFO_UNSIGNED')
          ) {
            gotoStepPage1(
              vm.application,
              vm.isEnrollmentAvailable,
              stepNo,
              $scope.isFederation
            );
          } else if (
            vm.application.status !== 'WITHDRAWN' &&
            vm.application.status !== 'DISBANDED'
          ) {
            gotoStepPage2(vm.application, stepNo, $scope.isFederation);
          }
        }
      });
    };

    /* Go to step page in edit mode */
    function gotoStepPage1(
      application,
      isEnrollmentAvailable,
      stepPage,
      isFed
    ) {
      if (!isFed) {
        ApplicationService.reset();
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        if (!isEnrollmentAvailable && status !== 'MORE_INFO_UNSIGNED') {
          vm.errorMsg = 'The Charity Application Period is currently Closed';
        } else {
          vm.appNavbar.pledgeYear = pledgeYear;
          vm.appNavbar.cfcCode = cfcCode;
          vm.appNavbar.fysa = fysa;
          CharityService.findCharityWithCFC(application.cfcCode).then(function(
            res
          ) {
            var charity = res.data;
            vm.appNavbar.charityName = charity.charityName;
          });
          ApplicationService.setCfcCode(cfcCode);
          ApplicationService.setPledgeYear(pledgeYear);
          vm.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode).then(function(res) {
            var role = res.data;
            var userSession = ApplicationService.getUserSession();
            userSession.cfcCode = cfcCode;
            userSession.applicationId = applicationId;
            userSession.userRole = role.userRole;
            userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
              new Date()
            );
            userSession.$save(function(response) {
              ApplicationService.userSession = response;
            });
          });
          var currentPledgeYear = sessionStorage.getItem(
            'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
          );
          CharityService.getPreApplicationDetails(
            cfcCode,
            currentPledgeYear
          ).then(function(res) {
            var data = res.data.value;
            ApplicationService.setPreApplicationDetails(data);
            ApplicationService.editApplication(applicationId);
            $state.go(stepPage, {
              mode: 'EDIT'
            });
            $scope.setModuleName('CfcCharity.application');
          });
        }
      } else {
        FederationApplicationService.reset();
        var applicationId = application.federationId;
        var status = application.applicationStatus;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        if (!isEnrollmentAvailable && status !== 'MORE_INFO_UNSIGNED') {
          vm.error = 'The Charity Application Period is currently Closed';
        } else {
          vm.federationNavbar.pledgeYear = pledgeYear;
          vm.federationNavbar.cfcCode = cfcCode;
          CharityService.findCharityWithCFC(application.cfcCode).then(function(
            resp
          ) {
            vm.federationNavbar.charityName = resp.data.charityName;
          });
          FederationApplicationService.setPledgeYear(pledgeYear);
          FederationApplicationService.setCfcCode(cfcCode);
          vm.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode).then(function(roleResp) {
            var role = roleResp.data;
            var userSession = FederationApplicationService.getUserSession();
            userSession.cfcCode = cfcCode;
            userSession.applicationId = applicationId;
            userSession.userRole = role.userRole;
            userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
              new Date()
            );
            userSession.$save(function(response) {
              FederationApplicationService.setUserSession(response);
            });
          });
          FederationApplicationService.editFederationApplication(applicationId);
          $state.go('federation' + stepPage, { mode: 'EDIT' });
          $scope.setModuleName('CfcCharity.federation_application');
        }
      }
    }

    /* go to step page in view mode*/
    function gotoStepPage2(application, stepPage, isFed) {
      if (!isFed) {
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        var fedId = application.federationNumber;
        vm.appNavbar.pledgeYear = pledgeYear;
        vm.appNavbar.cfcCode = cfcCode;
        vm.appNavbar.fysa = fysa;
        $scope.appNavbar.fysa = fysa;
        CharityService.findCharityWithCFC(application.cfcCode).then(function(
          res
        ) {
          var charity = res.data;
          vm.appNavbar.charityName = charity.charityName;
        });
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setPledgeYear(pledgeYear);
        ApplicationService.setParentFederationApplicationId(fedId);
        vm.fedId = fedId;
        vm.appVisibility.viewMode = 'VIEW';
        var currentPledgeYear = sessionStorage.getItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
        );
        CharityService.getPreApplicationDetails(
          cfcCode,
          currentPledgeYear
        ).then(function(res) {
          var data = res.data.value;
          ApplicationService.setPreApplicationDetails(data);
          ApplicationService.editApplication(applicationId);
          $state.go(stepPage.toLowerCase(), {
            mode: 'VIEW',
            applicationId: applicationId,
            parentFedAppId: fedId
          });
          $scope.setModuleName('CfcCharity.application');
        });
      } else {
        var applicationId = application.federationId;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        vm.federationNavbar.pledgeYear = pledgeYear;
        vm.federationNavbar.cfcCode = cfcCode;
        CharityService.findCharityWithCFC(application.cfcCode).then(function(
          charityResp
        ) {
          vm.federationNavbar.charityName = charityResp.data.charityName;
        });
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        FederationApplicationService.editFederationApplication(applicationId);

        vm.appVisibility.viewMode = 'VIEW';
        $state.go('federation' + stepPage, { mode: 'VIEW' });
        $scope.setModuleName('CfcCharity.federation_application');
      }
    }

    // only if embedded in admin appeal dashboard, re-load the parent page after starting appeal
    vm.checkForAdminAppealDashboardReload = function() {
      blockUI.start();
      if ('admin-appeal-dashboard.full' === $state.current.name) {
        $state.go(
          'admin-appeal-dashboard',
          {
            applicationType: vm.appType,
            applicationId: vm.appDash.applicationId
          },
          { reload: true }
        );
      } else {
        forceClearBackdrop();
      }
    };

    $scope.resetApplicationAppeal = function(id) {
      initAppealModal();
      angular.element(id).modal('toggle');
      vm.checkForAdminAppealDashboardReload();
    };

    $scope.updateApplicationAppeal = function(id, reason) {
      AppealService.UpdateAppeal(id, reason, vm.appDash.cfcCode)
        .then(function(res) {
          // success, nothing further needed
          vm.checkForAdminAppealDashboardReload();
        })
        .catch(function(err) {
          $log.error(err);
        })
        .finally(function() {
          initAppealModal();
        });
    };

    $scope.signAppeal = function(
      id,
      certification,
      fullname,
      initials,
      webservice
    ) {
      var signObj = [];

      signObj.certification = certification;
      signObj.signature = fullname;
      signObj.initials = initials;
      $scope.fieldsMissing = [];
      if (!signObj.certification) {
        $scope.fieldsMissing.push('Certify Application is Required');
      }
      if (!signObj.signature) {
        $scope.fieldsMissing.push('Full name is Required');
      }
      if (!signObj.initials) {
        $scope.fieldsMissing.push('Initials Required');
      }
      if ($scope.fieldsMissing.length > 0) {
        $('#missingInfo').modal();
      } else {
        AppealService.signAppeal(id, vm.appDash.cfcCode, webservice, signObj)
          .then(function(res) {
            // success, nothing further needed
            vm.checkForAdminAppealDashboardReload();
          })
          .catch(function(err) {
            $log.error(err);
          })
          .finally(function() {
            initAppealModal();
          });
      }
    };

    $scope.unsignAppeal = function(id) {
      AppealService.unsignAppeal(id, vm.appDash.cfcCode)
        .then(function(res) {
          // success, nothing further needed
        })
        .catch(function(err) {
          $log.error(err);
        })
        .finally(function() {
          initAppealModal();
        });
    };

    function performRoleChecks() {
      return $q(function(resolve, reject) {
        vm.user = BrowserEventService.getCurrentUser();
        if (
          vm.user.secondaryRoleList &&
          Array.isArray(vm.user.secondaryRoleList)
        ) {
          if (
            vm.user.secondaryRoleList.includes('OPM_ADMIN') ||
            vm.user.secondaryRoleList.includes('OPM_STAFF') ||
            vm.user.secondaryRoleList.includes('CHARITY_OWNER') ||
            vm.user.secondaryRoleList.includes('CHARITY_EDITOR') ||
            vm.user.secondaryRoleList.includes('FEDERATION_OWNER') ||
            vm.user.secondaryRoleList.includes('FEDERATION_EDITOR') ||
            vm.user.secondaryRoleList.includes('OPM_AUDITOR')
          ) {
            vm.hasDownloadAccess = true;
            vm.hasUploadDeleteAccess = true;
          } else if (
            vm.user.secondaryRoleList.includes('ZONE_MANAGER') ||
            vm.user.secondaryRoleList.includes('REVIEWER') ||
            vm.user.secondaryRoleList.includes('CUSTOMER_SERVICE_MANAGER') ||
            vm.user.secondaryRoleList.includes('CUSTOMER_SERVICE_TIER_1') ||
            vm.user.secondaryRoleList.includes(
              'CUSTOMER_SERVICE_TIER_2_FINANCE'
            )
          ) {
            vm.hasDownloadAccess = true;
          }
        }
        if (
          vm.user.secondaryRoleList.includes('OPM_ADMIN') ||
          vm.user.secondaryRoleList.includes('OPM_STAFF') ||
          vm.user.secondaryRoleList.includes('OPM_AUDITOR')
        ) {
          vm.adminUsers = true;
        }
        if (
          vm.user.secondaryRoleList &&
          Array.isArray(vm.user.secondaryRoleList)
        ) {
          if (vm.user.secondaryRoleList.includes('OPM_ADMIN')) {
            vm.isOpmAdmin = true;
          }
          if (
            vm.user.secondaryRoleList.includes('OPM_ADMIN') ||
            vm.user.secondaryRoleList.includes('OPM_STAFF')
          ) {
            vm.canEdit = true;
            vm.canUpdateAppealDueDate = true;
            resolve('has access');
          } else if (vm.user.secondaryRoleList.includes('OPM_AUDITOR')) {
            vm.canEdit = false;
            vm.auditor = true;
            resolve('has access');
          } else if (
            vm.user.secondaryRoleList.includes('ZONE_MANAGER') ||
            vm.user.secondaryRoleList.includes('REVIEWER') ||
            vm.user.secondaryRoleList.includes('CUSTOMER_SERVICE_TIER_1')
          ) {
            // check for respective zone!
            ZoneCenterService.findCharityAccessEligibility(
              vm.user.id,
              vm.appDash.cfcCode
            ).then(
              function(res) {
                if (res.data === true) {
                  vm.canEdit = true;
                  resolve('has access');
                } else {
                  vm.canEdit = false;
                  reject('no access');
                }
              },
              function(err) {
                $log.error('error establishing user access for this zone', err);
                vm.hasAccess = false;
                vm.canEdit = false;
                reject('no access');
              }
            );
          } else if (
            vm.user.secondaryRoleList.includes('CUSTOMER_SERVICE_MANAGER') ||
            vm.user.secondaryRoleList.includes(
              'CUSTOMER_SERVICE_TIER_2_FINANCE'
            )
          ) {
            vm.readOnly = true;
            resolve('has access');
          } else {
            CharityDashboardService.getCharityFedMemberAccessForYear(
              vm.appDash.pledgeYear,
              vm.appDash.cfcCode
            )
              .then(function(res) {
                var yn = 'true' === res.data;
                if (yn) {
                  vm.canEdit = true;
                } else {
                  CharityService.getCharityUserRole(vm.appDash.cfcCode)
                    .then(function(res) {
                      var role = res.data.userRole;
                      if (
                        'CHARITY_OWNER' === role ||
                        'CHARITY_EDITOR' === role
                      ) {
                        vm.canEdit = true;
                        resolve('access is good');
                      } else {
                        vm.canEdit = false;
                        resolve('no edit access');
                      }
                    })
                    .catch(function(er) {
                      vm.canEdit = false;
                      reject('charity access failed');
                    });
                }
                resolve('fed rules over this member');
              })
              .catch(function(er) {
                vm.canEdit = false;
                reject('charity access failed');
              });
          }
        } else {
          reject('no access');
        }
      });
    }

    vm.goToCharityDashboard = function() {
      var dest = 'charitydashboard';
      var opts = {};

      opts.charityDashboardCfcCode = vm.appDash.cfcCode;
      opts.pledgeYear = vm.appDash.pledgeYear;

      $state.go(dest, opts);
    };

    vm.downloadPDFAndDocuments = function() {
      window.print();
      var downloadURL =
        '/CfcCharity/api/public/downloadappealdocuments/' +
        $scope.appealId +
        '/' +
        vm.appDash.campaignId +
        '/' +
        tokenData.cfcCode;
      DownloadService.downloadFile(downloadURL);
    };

    vm.goToApplicationDashboard = function() {
      var dest = null;
      var opts = {};
      if (vm.isFederation === 'true') {
        dest = 'federationDashboard';
        opts.federationId = vm.applicationId;
      } else {
        dest = 'applicationDashboard';
        opts.applicationId = vm.applicationId;
      }
      $state.go(dest, opts);
    };

    function getInitials() {
      var initials = '';
      if (userOb && userOb.firstName && userOb.firstName.length >= 1) {
        initials = userOb.firstName.substring(0, 1).toUpperCase();
      }
      if (userOb && userOb.lastName && userOb.lastName.length >= 1) {
        initials += userOb.lastName.substring(0, 1).toUpperCase();
      }
      return initials;
    }

    // CCA-1856
    function loadCertificationsData() {
      // load application certs
      AppealService.getApplicationCertificationsByYear(
        vm.appDash.pledgeYear,
        vm.appDash.cfcCode
      )
        .then(function(res) {
          vm.applicationCertifications = res.data;

          // load missing certifications
          AppealService.getCharityAppealCertificationsByApplicationId(
            vm.appDash.applicationId,
            vm.appType,
            vm.appDash.cfcCode
          )
            .then(function(resp) {
              vm.missingCertifications = resp.data;
            })
            .catch(function(er) {
              $log.error(er);
            });
        })
        .catch(function(err) {
          $log.error(err);
        });
    }

    vm.performMissingCertificationCheckUnCheck = function(missingCertOb) {
      // call update with appropriately set checked boolean; checked should already be set by UI binding to ng-model
      AppealService.updateCharityAppealCertification(
        missingCertOb,
        vm.appDash.cfcCode
      )
        .then(function(res) {
          // nothing, it saved correctly to the server
        })
        .catch(function(err) {
          $log.error(err);
        });
    };

    // returns matching ApplicationCertification by given CharityAppealCertification
    vm.getApplicationCertificationFromCharityAppealCertification = function(
      charAppCert
    ) {
      var found = vm.applicationCertifications.find(function(el) {
        return el.id === charAppCert.applicationCertificationId;
      });
      return found;
    };

    // performs update to PENDING and populates missing certifications
    vm.updateAppealToPending = function() {
      blockUI.start();
      AppealService.updateCharityToPendingAndPopulateMissingCerertifications(
        vm.appDash.applicationId,
        vm.appType,
        vm.appDash.cfcCode
      )
        .then(function(res) {
          // now that they're created, set them
          vm.missingCertifications = res.data;
          // call next modal
          forceClearBackdrop();
          $('#startAppeal').modal('show');
        })
        .catch(function(err) {
          $log.error(err);
          // extremely unlikely this will be reached, endpoint auth is consistent, button protected by canEdit
          forceClearBackdrop();
          $window.alert('There was an error setting your appeal to pending.');
        });
    };

    function forceClearBackdrop() {
      blockUI.stop();
      $('.modal-backdrop').hide();
    }

    // initialization sequence
    function init() {
      loadInitialStateValues();
      loadStateParams()
        .then(function(success) {
          getAreaOfServices();
          // role check
          performRoleChecks()
            .then(function(success) {
              loadCertificationsData();
              forceClearBackdrop();
            })
            .catch(function(er) {
              $log.error(er);
              forceClearBackdrop();
            });
          getApplicationStep4();
        })
        .catch(function(fail) {
          $log.error(fail);
          forceClearBackdrop();
        });
    }
    // let's begin
    init();

    $scope.submitAppealFile = function() {
      blockUI.start();
      $scope.fileUploadStatusMessage = '';
      if (!$scope.supportingFile) {
        $scope.fileUploadStatusMessage =
          'A valid file must be uploaded in PDF format.';
        $('#fileUploadStatusMessage').modal();
        forceClearBackdrop();
        $('#addFile').modal();
      } else if ($scope.supportingFile) {
        if ($scope.supportingFile.type !== 'application/pdf') {
          $scope.fileUploadStatusMessage =
            'A valid file must be uploaded in PDF format.';
          $('#fileUploadStatusMessage').modal();
          forceClearBackdrop();
        } else if ($scope.fileType === 'File Type:') {
          $scope.fileUploadStatusMessage =
            'A valid file type must be selected.';
          $('#fileUploadStatusMessage').modal();
          forceClearBackdrop();
        } else {
          var fd = new FormData();
          fd.append('supportingFile', $scope.supportingFile);
          var uploadUrl =
            '/CfcCharity/api/private/charity/appeal/uploadAppealDocument/' +
            $scope.appealId +
            '/' +
            vm.appDash.cfcCode +
            '/' +
            vm.appDash.campaignId +
            '/' +
            $scope.fileType;
          $http
            .post(uploadUrl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined,
                'X-AUTH-TOKEN': $scope.xAuthToken
              }
            })
            .then(function(res) {
              var data = res.data;
              if (
                data &&
                data.statusCode !== undefined &&
                data.statusCode === 'ERROR'
              ) {
                $scope.fileUploadStatusMessage = data.message;
                $('#fileUploadStatusMessage').modal();
                document.getElementById('appealFileUpload').value = '';
              } else if (data !== null && data !== '') {
                $scope.fileUploadStatusMessage = 'File uploaded successfully.';
                $('#fileUploadStatusMessage').modal();
                document.getElementById('appealFileUpload').value = '';
                getAppealDocuments(
                  vm.appDash.campaignId,
                  vm.appDash.cfcCode,
                  $scope.appealId
                );
                getApplicationDocuments(
                  vm.appDash.campaignId,
                  vm.appDash.cfcCode,
                  $scope.applicationId
                );
              }
            })
            .catch(function(err) {
              $scope.fileUploadStatusMessage =
                'There is a problem with your file. Your file may be corrupted or be password protected. Please check and fix your file and upload it again.';
              $('#fileUploadStatusMessage').modal();
              document.getElementById('appealFileUpload').value = '';
            });
          forceClearBackdrop();
        }
      }
      forceClearBackdrop();
    };

    $scope.submitWebAOSFile = function() {
      blockUI.start();
      $scope.fileUploadStatusMessage = '';
      if (!$scope.supportingFile) {
        $scope.fileUploadStatusMessage =
          'A valid file must be uploaded in PDF format.';
        $('#fileUploadStatusMessage').modal();
        forceClearBackdrop();
      } else if ($scope.supportingFile) {
        if ($scope.supportingFile.type !== 'application/pdf') {
          $scope.fileUploadStatusMessage =
            'A valid file must be uploaded in PDF format.';
          $('#fileUploadStatusMessage').modal();
          forceClearBackdrop();
        } else {
          var fd = new FormData();
          fd.append('supportingFile', $scope.supportingFile);
          var uploadUrl =
            '/CfcCharity/api/private/charity/appeal/uploadAppealDocument/' +
            $scope.appealId +
            '/' +
            vm.appDash.cfcCode +
            '/' +
            vm.appDash.campaignId +
            '/aos';
          $http
            .post(uploadUrl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined,
                'X-AUTH-TOKEN': $scope.xAuthToken
              }
            })
            .then(function(res) {
              var data = res.data;
              if (
                data &&
                data.statusCode !== undefined &&
                data.statusCode === 'ERROR'
              ) {
                $scope.fileUploadStatusMessage = data.message;
                $('#fileUploadStatusMessage').modal();
                document.getElementById('appealFileUpload').value = '';
              } else if (data !== null && data !== '') {
                $scope.fileUploadStatusMessage = 'File uploaded successfully.';
                $('#fileUploadStatusMessage').modal();
                document.getElementById('appealFileUpload').value = '';
                getAppealDocuments(
                  vm.appDash.campaignId,
                  vm.appDash.cfcCode,
                  $scope.appealId
                );
              }
            })
            .catch(function(err) {
              $scope.fileUploadStatusMessage =
                'There is a problem with your file. Your file may be corrupted or be password protected. Please check and fix your file and upload it again.';
              $('#fileUploadStatusMessage').modal();
              document.getElementById('appealFileUpload').value = '';
            });
          forceClearBackdrop();
        }
      }
      forceClearBackdrop();
    };

    function confirmDeleting() {
      return $window.confirm('Are you sure you want to Delete this file?');
    }

    $scope.deleteAppealDocument = function(documentId) {
      if (confirmDeleting()) {
        var url =
          '/CfcCharity/api/private/charity/appeal/deleteCharityAppealDocument/' +
          documentId +
          '/' +
          vm.appDash.cfcCode;
        $http({
          method: 'POST',
          url: url,
          data: {
            documentId: documentId
          }
        })
          .then(function(res) {
            var data = res.data;
            document.getElementById('webServicesDocumentation').value = '';
            $scope.supportingFile = null;
            if (data) {
              vm.hasAOSfile = false;
              getAppealDocuments(
                vm.appDash.campaignId,
                vm.appDash.cfcCode,
                $scope.appealId
              );
              getApplicationDocuments(
                vm.appDash.campaignId,
                vm.appDash.cfcCode,
                $scope.applicationId
              );
            }
          })
          .catch(function(err) {});
      }
    };

    function getAppealDocuments(campaignId, cfcCode, appealId) {
      AppealService.getAppealDocuments(campaignId, cfcCode, appealId)
        .then(function(res) {
          if (res.data != null) {
            $scope.charityAppealDocumentData = res.data;
            angular.forEach($scope.charityAppealDocumentData, function(file) {
              if (file.fileType === 'AOS Web-Based Services') {
                vm.hasAOSfile = true;
              }
            });
          }
        })
        .catch(function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        });
    }

    function getApplicationDocuments(campaignId, cfcCode, appId) {
      AppealService.getApplicationDocuments(campaignId, cfcCode, appId)
        .then(function(res) {
          if (res.data != null) {
            $scope.charityApplicationDocumentData = res.data.value;
          }
        })
        .catch(function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        });
    }

    vm.saveAppealDueDate = function() {
      var dueDate = new Date(vm.changedAppealDueDate);
      var appealId = $scope.appealId;

      AdminAppealService.updateAppealDueDate(appealId, dueDate)
        .then(function(res) {
          $scope.appealData.appealDueDate = vm.changedAppealDueDate;
          $scope.changeAppealDueDateModal.appealDueDate.$untouched = true;
          $scope.changeAppealDueDateModal.appealDueDate.$pristine = true;
          vm.checkForAdminAppealDashboardReload();
        })
        .catch(function(error) {
          //CubeSvc handles any errors
        })
        .finally(function() {
          initAppealModal();
        });
    };

    vm.saveAppealDocumentSignature = function(appealId, appealDocumentId) {
      $scope.fieldsMissing = [];
      if (!$scope.appealDocumentSignature.certificationChecked) {
        $scope.fieldsMissing.push('Certify Application');
      }
      if (!$scope.appealDocumentSignature.fullName) {
        $scope.fieldsMissing.push('Full name');
      }
      if (!$scope.appealDocumentSignature.initials) {
        $scope.fieldsMissing.push('Initials');
      }
      if (!$scope.appealDocumentSignature.title) {
        $scope.fieldsMissing.push('Title');
      }
      if ($scope.fieldsMissing.length > 0) {
        $('#missingDocumentSignatureInfo').modal();
      } else {
        AppealService.saveDocumentSignature(
          vm.appDash.cfcCode,
          appealId,
          appealDocumentId,
          $scope.appealDocumentSignature
        )
          .then(function() {
            vm.checkForAdminAppealDashboardReload();
          })
          .catch(function(error) {})
          .finally(function() {
            initAppealModal();
          });
      }
    };

    function getApplicationStep4() {
      var applicationStep4 = ApplicationService.getApplicationStep4();
      applicationStep4.applicationId = parameterData.applicationId;
      applicationStep4.$get(
        function(res) {
          if (res.value) {
            var webBasedService = res.value.webBasedService;
            if (
              webBasedService === null ||
              webBasedService === undefined ||
              webBasedService === false
            ) {
              vm.webService = 'No';
            } else {
              vm.webService = 'Yes';
            }
          }
        },
        function(error) {
          if (error.data !== undefined) {
            $log.error(error.data.errorMessage);
          }
        }
      );
    }
  }
})();
