(function() {
  var moduleName = 'CfcCharity.directives.areaOfServiceReviewStatus';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcAreaOfServiceReviewStatus', [
    '$location',
    '$anchorScroll',
    function($location, $anchorScroll) {
      return {
        restrict: 'E',
        template: require('./areaOfServiceReviewStatus.html'),
        scope: {},
        controller: function($scope, $rootScope) {
          function init() {
            $rootScope.$on('areaOfServiceReviewStatusUpdate', function(
              event,
              res
            ) {
              $scope.currentReview = res;
            });
          }

          $scope.scrollTo = function(id) {
            var old = $location.hash();
            $location.hash(id);
            $anchorScroll();
            $location.hash(old);
          };

          init();
        }
      };
    }
  ]);
})();
