(function() {
  var moduleName = 'CfcCharity.directives.cms';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    module = angular.module(moduleName, []);
  }

  module.directive('cfcCms', [
    'cmsService',
    '$log',
    function(cmsService, $log) {
      return {
        restrict: 'E',
        template: require('./cms.html'),
        scope: { contentKey: '@', noBorder: '@' },
        controller: function($scope) {
          function init() {
            $scope.border = angular.isDefined($scope.noBorder) ? false : true;
            cmsService
              .getContent($scope.contentKey)
              .then(function(resp) {
                $scope.content = resp.data.content;
                if ($scope.content != null && $scope.content !== '') {
                  $scope.content = $scope.content.replace(
                    'Step Instructions',
                    '<b>STEP INSTRUCTIONS</b>'
                  );
                }
              })
              .catch(function(err) {
                $log.error(
                  err.data || 'Request failed with status ' + err.status,
                  err
                );
              })
              .finally(function() {});
          }

          init();
        }
      };
    }
  ]);
})();
