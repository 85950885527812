angular
  .module('CfcCharity.zonememberapplications', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('zonememberapplications', {
        url: '/zonememberapplications',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zonememberapplications.html'),
            controller: 'ZoneMemberApplicationsCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Zone %zoneCode%',
              crumbRoute: 'zoneCenter',
              crumbParams: '{zoneId: %zoneId%}'
            },
            {
              crumbName: '%zoneCode% Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            },
            {
              crumbName: '%pledgeYear% #%federationCfc% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
            },
            {
              crumbName: 'Federation Review',
              crumbRoute: 'federationapplicationreview',
              crumbParams: '{federationApplicationId: %applicationId%}'
            }
          ],
          pageTitle: 'Zone Member Applications',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: {
          federationApplicationId: null,
          zoneId: null
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            if ($stateParams.federationApplicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.Completeness
              );
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                $stateParams.federationApplicationId
              );
            }
          }
        }
      });
    }
  ])
  .filter('groupFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, groupIdToFilter) {
        if (!dataArray) {
          return;
        } else if (!groupIdToFilter || groupIdToFilter === 'All') {
          return dataArray;
        } else {
          return dataArray.filter(function(item) {
            var groupFound =
              item &&
              item.reviewGroupBean &&
              item.reviewGroupBean.reviewGroupId === groupIdToFilter;
            return groupFound;
          });
        } // end else
      };
    }
  ])
  .filter('charityApplicationStatusFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, charityApplicationStatusToFilter) {
        // If no array is given, exit.
        if (!dataArray) {
          return;
        }
        // If no search term exists, return the array unfiltered.
        else if (
          !charityApplicationStatusToFilter ||
          charityApplicationStatusToFilter === 'All'
        ) {
          return dataArray;
        }
        // Otherwise, continue.
        else {
          // Return the array and filter it by looking for any occurrences of the search term in each items id or name.
          return dataArray.filter(function(item) {
            var charityApplicationStatusFound = false;

            for (var i = 0; i < charityApplicationStatusToFilter.length; i++) {
              if (
                item &&
                item.charityApplicationStatus &&
                item.charityApplicationStatus ===
                  charityApplicationStatusToFilter[i]
              ) {
                charityApplicationStatusFound = true;
                break;
              } // end if
            } // end for loop

            return charityApplicationStatusFound;
          });
        }
      };
    }
  ])
  .filter('cfcCodeOrEinFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, cfcCodeOrEinToFilter) {
        if (!dataArray) {
          return;
        } else if (!cfcCodeOrEinToFilter) {
          return dataArray;
        } else {
          return dataArray.filter(function(item) {
            var charityCfcCode =
              item &&
              item.cfcCode &&
              item.cfcCode.indexOf(cfcCodeOrEinToFilter) > -1;
            var charityEin =
              item && item.ein && item.ein.indexOf(cfcCodeOrEinToFilter) > -1;
            return charityCfcCode || charityEin;
          });
        }
      };
    }
  ])
  .service('ZoneMemberApplicationsService', [
    '$http',
    function($http) {
      this.getZoneMemberApplicationsDetails = function(
        federationApplicationId,
        zoneId
      ) {
        var url =
          '/CfcCharity/api/private/federation/zone/memberapplications/' +
          federationApplicationId +
          '/' +
          zoneId;
        return $http.get(url);
      }; // end getZoneMemberApplicationsDetails function

      this.deleteMemberApplication = function(
        federationApplicationId,
        zoneId,
        memberApplicationId
      ) {
        var url =
          '/CfcCharity/api/private/federation/zone/memberapplication/' +
          federationApplicationId +
          '/' +
          zoneId +
          '/' +
          memberApplicationId;
        return $http.get(url);
      };
    } // end ZoneMemberApplicationsService function
  ])
  .controller('ZoneMemberApplicationsCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'ZoneMemberApplicationsService',
    'BrowserEventService',
    function(
      $log,
      $state,
      $scope,
      $location,
      $rootScope,
      ZoneMemberApplicationsService,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.zonememberapplications');
      $log.info(
        'Federation applicationId : ' + $state.params.federationApplicationId
      );
      $log.info('ZoneId : ' + $state.params.zoneId);

      var federationApplicationId = $state.params.federationApplicationId;
      var zoneId = $state.params.zoneId;
      $scope.deleteMemberApplicationErrors = '';

      var user = $scope.getCurrentUser();

      if (federationApplicationId && zoneId) {
        BrowserEventService.setFederationApplicationReviewFederationId(
          federationApplicationId
        );
        BrowserEventService.setZoneMemberApplicationsZoneId(zoneId);
      } // end if

      var error = '';

      if (!federationApplicationId) {
        error =
          'Can not retrieve the zone member applications as the federationApplicationId is : ' +
          federationApplicationId;
        $scope.showZoneMemberApplicationsErrorMessage(error);
      }

      if (!zoneId) {
        error =
          'Can not retrieve the zone member applications as the zoneId is : ' +
          zoneId;
        $scope.showZoneMemberApplicationsErrorMessage(error);
      }

      ZoneMemberApplicationsService.getZoneMemberApplicationsDetails(
        federationApplicationId,
        zoneId
      )
        .then(function(res) {
          var data = res.data;
          var zoneMemberApplicationsDetailsResponse = data;
          $scope.zoneMemberApplicationsDetailsData = {};

          if (
            zoneMemberApplicationsDetailsResponse &&
            (zoneMemberApplicationsDetailsResponse.error ||
              zoneMemberApplicationsDetailsResponse.statusCode === 'ERROR')
          ) {
            $scope.showZoneMemberApplicationsErrorMessage(
              zoneMemberApplicationsDetailsResponse.errorResponse.errorCode +
                ' : ' +
                zoneMemberApplicationsDetailsResponse.errorResponse
                  .errorMessage +
                '. Please try again later!'
            );
          } else if (
            zoneMemberApplicationsDetailsResponse &&
            zoneMemberApplicationsDetailsResponse.statusCode === 'SUCCESS'
          ) {
            $scope.zoneMemberApplicationsDetailsData =
              zoneMemberApplicationsDetailsResponse.zoneMemberApplicationsBean;
          }
        })
        .catch(function(err) {
          error =
            'Error retrieving the zone member applications for the federation application id : ' +
            federationApplicationId;
          $scope.showZoneMemberApplicationsErrorMessage(error);

          $log.info(error + ' ' + JSON.stringify(err.data));
        }); // end error

      // end getZoneMemberApplicationsDetails function

      $scope.memberApplicationBeanListLimit = 10;
      $scope.loadMoreMemberApplications = function() {
        if (
          $scope.zoneMemberApplicationsDetailsData &&
          $scope.zoneMemberApplicationsDetailsData.memberApplicationBeanList &&
          $scope.memberApplicationBeanListLimit <
            $scope.zoneMemberApplicationsDetailsData.memberApplicationBeanList
              .length
        ) {
          $scope.memberApplicationBeanListLimit += 10;
          $log.info(
            'Increase memberApplicationBeanListLimit Limit',
            $scope.memberApplicationBeanListLimit
          );
        } // end if
      }; // end loadMoreMemberApplications function

      // start - reset delete member application

      $scope.resetDeleteMemberApplication = function() {
        $scope.deleteFederationApplicationId = '';
        $scope.deleteMemberApplicationId = '';
        $scope.deleteZoneId = '';
        $scope.deleteMemberApplicationErrors = '';
      }; // end resetDeleteMemberApplication function

      // end - reset delete member applicatio

      // start - confirm delete member application

      $scope.confirmDeleteMemberApplication = function(
        federationApplicationId,
        deleteZoneId,
        memberApplicationId
      ) {
        $scope.deleteMemberApplicationErrors = [];
        if (!federationApplicationId || !zoneId || !memberApplicationId) {
          $scope.deleteMemberApplicationErrors.push(
            'federationApplicationId and memberApplicationId is required to delete the member application'
          );
        } else if (federationApplicationId && zoneId && memberApplicationId) {
          $scope.deleteFederationApplicationId = federationApplicationId;
          $scope.deleteMemberApplicationId = memberApplicationId;
          $scope.deleteZoneId = deleteZoneId;
          $('#deleteMemberApplication').modal('toggle');
        } // end else if
      }; // end confirmDeleteMemberApplication function

      // end - confirm delete member application

      // start - delete member application

      $scope.deleteMemberApplication = function(
        federationApplicationId,
        zoneId,
        memberApplicationId
      ) {
        $scope.deleteMemberApplicationErrors = [];
        if (!federationApplicationId || !zoneId || !memberApplicationId) {
          $scope.deleteMemberApplicationErrors.push(
            'federationApplicationId and memberApplicationId is required to delete the member application'
          );
        } else if (federationApplicationId && zoneId && memberApplicationId) {
          ZoneMemberApplicationsService.deleteMemberApplication(
            federationApplicationId,
            zoneId,
            memberApplicationId
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation zone delete member application success ' +
                  JSON.stringify(data)
              );
              var zoneMemberApplicationsDetailsResponse = data;
              $scope.zoneMemberApplicationsDetailsData = {};

              if (
                zoneMemberApplicationsDetailsResponse &&
                (zoneMemberApplicationsDetailsResponse.error ||
                  zoneMemberApplicationsDetailsResponse.statusCode === 'ERROR')
              ) {
                $scope.deleteMemberApplicationErrors.push(
                  zoneMemberApplicationsDetailsResponse.errorResponse
                    .errorCode +
                    ' : ' +
                    zoneMemberApplicationsDetailsResponse.errorResponse
                      .errorMessage +
                    '. Please try later!'
                );
              } else if (
                zoneMemberApplicationsDetailsResponse &&
                zoneMemberApplicationsDetailsResponse.statusCode ===
                  'SUCCESS' &&
                zoneMemberApplicationsDetailsResponse.deletedMemberApplication ===
                  true
              ) {
                $scope.zoneMemberApplicationsDetailsData =
                  zoneMemberApplicationsDetailsResponse.zoneMemberApplicationsBean;
                $scope.deleteMemberApplicationErrors = '';
                $('#deleteMemberApplication').modal('toggle');
              }
            })
            .catch(function(err) {
              error =
                'Error deleting the  member application for the federation application id : ' +
                federationApplicationId;
              $scope.deleteMemberApplicationErrors.push(error);

              $log.error(error + ' ' + JSON.stringify(err.data));
            }); // end error
        } // end else if
      }; // end deleteMemberApplication function

      // end - delete member application

      $scope.setSelectedGroup = function(selectedGroupId) {
        $scope.selectedGroupId = selectedGroupId;
      };

      $scope.matchReviewGroup = function(selectedGroupId) {
        return function(zoneMemberApplicationsDetailsData) {
          // var zoneMemberAppDetailsData = zoneMemberApplicationsDetailsData;

          if (!selectedGroupId || selectedGroupId === 'All') {
            $scope.setSelectedGroup(undefined);
            return zoneMemberApplicationsDetailsData;
          } else if (
            zoneMemberApplicationsDetailsData &&
            zoneMemberApplicationsDetailsData.reviewGroupBean &&
            zoneMemberApplicationsDetailsData.reviewGroupBean.reviewGroupId &&
            zoneMemberApplicationsDetailsData.reviewGroupBean.reviewGroupId ===
              selectedGroupId
          ) {
            $scope.setSelectedGroup(undefined);
            return zoneMemberApplicationsDetailsData;
          }

          //return zoneMemberAppDetailsData;
        };
      };

      $scope.setCharityApplicationStatusCode = function(
        charityApplicationStatusCodes
      ) {
        $scope.selectedCharityApplicationStatusCodes = charityApplicationStatusCodes;
      };

      $scope.matchCharityApplicationStatus = function(
        charityApplicationStatusCodes
      ) {
        return function(zoneMemberApplicationsDetailsData) {
          if (!charityApplicationStatusCodes) {
            $scope.setCharityApplicationStatusCode(undefined);
            return zoneMemberApplicationsDetailsData;
          } else if (
            zoneMemberApplicationsDetailsData &&
            zoneMemberApplicationsDetailsData.memberApplicationBean &&
            zoneMemberApplicationsDetailsData.memberApplicationBean
              .charityApplicationStatus
          ) {
            for (var i = 0; i < charityApplicationStatusCodes.length; i++) {
              var charityApplicationStatusCode =
                charityApplicationStatusCodes[i];
              if (
                zoneMemberApplicationsDetailsData.memberApplicationBean
                  .charityApplicationStatus === charityApplicationStatusCode
              ) {
                $scope.setCharityApplicationStatusCode(undefined);
                return zoneMemberApplicationsDetailsData;
              } // end if
            } // end for
          } // end if
        };
      };

      /* $scope.matchCfcCodeOrEin = function(searchInput) {
                var memberAppBean = null;
                 for (var i=0; searchInput && $scope.zoneMemberApplicationsDetailsData && $scope.zoneMemberApplicationsDetailsData.memberApplicationBeanList && i < $scope.zoneMemberApplicationsDetailsData.memberApplicationBeanList.length; i++) {
                     var memberApplicationBean = $scope.zoneMemberApplicationsDetailsData.memberApplicationBeanList[i];
                     if (memberApplicationBean && ( (memberApplicationBean.cfcCode && memberApplicationBean.cfcCode.includes(searchInput)) || (memberApplicationBean.ein && memberApplicationBean.ein.includes(searchInput)) ) ) {
                         memberAppBean = memberApplicationBean;
                         } // end if
                 } // end for
               /*
                 if (!memberAppBeanList || (memberAppBeanList && memberAppBeanList.length === 0)) {
                     memberAppBeanList = $scope.zoneMemberApplicationsDetailsData.memberApplicationBeanList;
                 }*/
      // return memberAppBean;
      //  }; // matchCfcCodeOrEIN method*/

      $scope.showZoneMemberApplicationsErrorMessage = function(error) {
        $scope.zoneMemberApplicationsError = error;
      };
    } // end ZoneMemberApplicationsCtrl controller
  ]);
