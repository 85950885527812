require('@babel/polyfill');

// vendor and app styles
require('./assets/vendor.js');

// app source files
require('./js/pageSizeOptions.js');
require('./js/PageSizeUserDashBoardOptions.js');
require('./js/app/common/analytics.service.js');
require('./js/userPageActivity.js');
require('./js/login.js');
require('./js/loginRedirect.js');
require('./js/home.js');
require('./js/userdashboard.js');
require('./js/accessrequestdenied.js');
require('./js/charitydashboard.js');
require('./js/charityMemberListings.js');
require('./js/updateuserinfo.js');
require('./js/optOut.js');
require('./js/federationapplicationfee.js');
require('./js/federationapplicationthankyou.js');
require('./js/federationDashboard.js');
require('./js/federationapplicationreview.js');
require('./js/zonereviewlevel.js');
require('./js/zonememberapplications.js');
require('./js/financeadministration.js');
require('./js/financetransactions.js');
require('./js/creditcardtransactions.js');
require('./js/paymenthistory.js');
require('./js/paymentreceipt.js');
require('./js/security.js');
require('./js/charity.js');
require('./js/charitylistingpayment.js');
require('./js/invitations.js');
require('./js/manageCharityUser.js');
require('./js/gbadmin.js');
require('./js/opmadmin.js');
require('./js/application.js');
require('./js/app/application/application-services.js');
require('./js/federation_application.js');
require('./js/app/application/federation-application-service.js');
require('./js/applicationDashboard.js');
require('./js/angular_card_input.js');
require('./js/systemmanagement.js');
require('./js/systemusers.js');
require('./js/addsystemuser.js');
require('./js/editsystemuser.js');
require('./js/administrationcenter.js');
require('./js/zoneadmin.js');
require('./js/zonecenter.js');
require('./js/zoneusers.js');
require('./js/addzoneuser.js');
require('./js/editzoneuser.js');
require('./js/federationlistingfee.js');
require('./js/federationlistingthankyou.js');
require('./js/recordachpayment.js');
require('./js/applicationValidations.js');
require('./js/grantusercharityrole.js');
require('./js/zonecompletenessreview.js');
require('./js/app/common/environmentInfo.service.js');
require('./js/app/common/common.module.js');
require('./js/app/common/common.service.js');
require('./js/app/common/app.service.js');
require('./js/app/common/charityReview.service.js');
require('./js/app/common/user.service.js');
require('./js/app/common/cube.service.js');
require('./js/app/common/cake.service.js');
require('./js/app/common/urlAccess.service.js');
require('./js/app/common/reviewLevel.service.js');
require('./js/app/common/download.service.js');
require('./js/app/common/redirect.service.js');
require('./js/app/common/validationUtil.js');
require('./js/app/common/textAngular-dropdownToggle.js');
require('./js/app/directives/breadcrumbs.js');
require('./js/app/directives/adminReviewSection.js');
require('./js/app/directives/adminReviewSectionStepDetermination.js');
require('./js/app/directives/adminReviewStatus.js');
require('./js/app/directives/adminReviewDenialReasons.js');
require('./js/app/common/appeal.service.js');
require('./js/app/directives/applicationAppealAlert/applicationAppealAlert.js');
require('./js/app/directives/applicationStatus/applicationStatus.js');
require('./js/app/directives/applicationAppealButton/applicationAppealButton.js');
require('./js/app/directives/approvalWithWarningMessage/approvalWithWarningMessage.js');
require('./js/app/directives/datepicker.js');
require('./js/app/directives/cms/cms.js');
require('./js/app/directives/charityCard/charityCard.js');
require('./js/app/directives/charityCard/federationCard.js');
require('./js/app/directives/currencyInput.js');
require('./js/app/admin/listing/listingOverrideAfterClose.service.js');
require('./js/app/admin/system/system.module.js');
require('./js/app/admin/system/system.service.js');
require('./js/app/admin/zone/listing/listing.module.js');
require('./js/app/admin/zone/listing/listing.service.js');
require('./js/app/admin/zone/listing/overview/overview.controller.js');
require('./js/app/admin/zone/listing/printorder/printorder.controller.js');
require('./js/app/admin/zone/listing/printorderhistory/printorderhistory.controller.js');
require('./js/app/admin/zone/pledges/pledges.module.js');
require('./js/app/admin/zone/pledges/pledges.service.js');
require('./js/app/admin/zone/pledges/overview/overview.controller.js');
require('./js/app/admin/system/jobs/jobs.controller.js');
require('./js/app/admin/system/jobs/jobs.service.js');
require('./js/app/admin/charity/pledge/pledge.service.js');
require('./js/app/admin/charity/pledge/pledge.controller.js');
require('./js/app/application/steps/checklist/checklist-controller.js');
require('./js/app/application/steps/app-steps-service.js');
require('./js/app/review/review.module.js');
require('./js/app/review/denial/denial.module.js');
require('./js/app/review/Email.module.js');
require('./js/app/review/appeal.module.js');
require('./js/app/admin/review/appeal/appeal.module.js');
require('./js/app/admin/review/appeal/appeal.controller.js');
require('./js/app/admin/review/appeal/appeal.service.js');
require('./js/app/donations/donations.module.js');
require('./js/app/donations/donations.service.js');
require('./js/app/donations/donations.controller.js');
require('./js/app/donations/member/memberdonation.controller.js');
require('./js/app/donations/disbursement/disbursement.controller.js');
require('./js/app/admin/system/email-templates/email-template.controller.js');
require('./js/app/admin/system/email-templates/email-templates.controller.js');
require('./js/app/admin/system/email-templates/email-templates.service.js');
require('./js/app/admin/system/banner/banner.module.js');
require('./js/app/admin/system/motd/motd.controller.js');
require('./js/app/admin/system/motd/motd.service.js');
require('./js/app/admin/system/cms/cms-list.controller.js');
require('./js/app/admin/system/cms/cms-detail.controller.js');
require('./js/app/admin/system/cms/cms.service.js');
require('./js/app/common/notification.service.js');
require('./js/app/common/toastr.service.js');
require('./js/app/directives/charitySupportingDocumentation/charitySupportingDocumentation.js');
require('./js/app/directives/areaOfServiceReview/areaOfServiceReview.js');
require('./js/app/directives/areaOfServiceReview/areaOfServiceReview.service.js');
require('./js/app/directives/adminReviewComments/adminReviewComments.js');
require('./js/app/directives/areaOfServiceReview/areaOfServiceReviewStatus.js');
require('./js/app/common/zone.service.js');
require('./js/app/common/authorizationHelper.js');
require('./js/app/admin/charity/emailDashboard/emailDashboard.controller.js');
require('./js/app/admin/charity/emailDashboard/emailDashboard.service.js');
require('./js/app/admin/system/jobs/jobs-history.controller.js');
require('./js/app/admin/system/upload/upload-region-files.controller.js');
require('./js/app/admin/system/upload/upload-region-files.service.js');
require('./js/app/admin/system/irs-master-file/irs-master-file-search.service.js');
require('./js/app/admin/system/irs-master-file/irs-master-file-search.controller.js');
require('./js/app/incompleteMemberListings/incompleteMemberListings.controller.js');
require('./js/app/admin/zone-appeals/zone-appeals.controller.js');
require('./js/app/admin/zone-appeals/zone-appeals.service.js');
require('./js/app/directives/videoLink/videoLink.js');
require('./js/app/directives/appeals/aosAppeal.js');
require('./js/app/directives/charityIrsStatus/charityIrsStatus.js');
require('./js/confirmUpdateUsername.js');
// main app
require('./js/app.js');
