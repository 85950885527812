// appeal.service.js - Services for the Admin Appeal Dashboard

'use strict';

(function() {
  angular
    .module('CfcCharity.admin.review.appeal')
    .factory('AdminAppealService', AdminAppealService);

  AdminAppealService.$inject = [
    '$http',
    '$q',
    'ZoneCenterService',
    'FederationApplicationReviewService',
    'CubeSvc'
  ];

  function AdminAppealService(
    $http,
    $q,
    ZoneCenterService,
    FederationApplicationReviewService,
    CubeSvc
  ) {
    function getAppealStatus(appId, appType) {
      return $http.get(
        '/CfcCharity/api/private/charity/review/appeal/status/' +
          appId +
          '/' +
          appType
      );
    }

    function getAppealDeterminationsByLevel(appId, appType) {
      return $http.get(
        '/CfcCharity/api/private/charity/determination/appeal/status/' +
          appId +
          '/' +
          appType
      );
    }

    function getAppealDeterminationHistory(appealId) {
      var url =
        '/CfcCharity/api/private/charity/determination/appeal/all/' + appealId;
      return $http.get(url);
    }

    function updateAppealDeterminationLevel(appealId, ob) {
      var url =
        '/CfcCharity/api/private/charity/determination/appeal/current/' +
        appealId;
      ob.charityAppealId = appealId;
      return $http.put(url, ob);
    }

    function getAppealStatusChangeHistory(appealId) {
      var url =
        '/CfcCharity/api/private/charity/appeal/status/history/' + appealId;
      return $http.get(url);
    }

    function updateAppealStatus(appealId, statusOb) {
      var url =
        '/CfcCharity/api/private/charity/appeal/status/update/' + appealId;
      return $http.put(url, statusOb);
    }

    // handles getting reviewer comments for fed or non-fed
    function getReviewerComments(appId, appType) {
      var isFed = 'FEDERATION' === appType;
      return $q(function(resolve, reject) {
        if (isFed) {
          FederationApplicationReviewService.getFederationReviewDetails(appId)
            .then(function(res) {
              resolve(
                res.data.federationApplicationReviewBean
                  .federationApplicationReviewCommentBeanList
              );
            })
            .catch(function(er) {
              reject(er);
            });
        } else {
          ZoneCenterService.getApplicationReviewLogs(appId)
            .then(function(res) {
              resolve(res.data.value);
            })
            .catch(function(er) {
              reject(er);
            });
        }
      });
    }

    function getApplicationStatus(appealId) {
      var url =
        '/CfcCharity/api/private/charity/admin/appeal/applicationstatus/' +
        appealId;
      return $http.get(url);
    }

    function updateApplicationStatus(appealId, status) {
      var url =
        '/CfcCharity/api/private/charity/admin/appeal/applicationstatus/' +
        appealId;
      return $http.put(url, { status: status });
    }

    function addReviewerComment(type, applicationId, comment) {
      var isFed = 'FEDERATION' === type;
      return $q(function(resolve, reject) {
        if (isFed) {
          var zoneId = null; // not actually needed
          FederationApplicationReviewService.addReviewerComment(
            applicationId,
            zoneId,
            comment
          )
            .then(function(res) {
              resolve(
                res.data.federationApplicationReviewBean
                  .federationApplicationReviewCommentBeanList
              );
            })
            .catch(function(er) {
              reject(er);
            });
        } else {
          ZoneCenterService.addReviewComment(applicationId, comment)
            .then(function(res) {
              // non-fed comes back with no response body, get and resolve
              ZoneCenterService.getApplicationReviewLogs(applicationId)
                .then(function(resp) {
                  resolve(resp.data.value);
                })
                .catch(function(err) {
                  reject(err);
                });
            })
            .catch(function(er) {
              reject(er);
            });
        }
      });
    }

    function getDueDateHistory(appealId, cfcCode) {
      var url = '/CfcCharity/api/private/charity/appeal/dueDate/history';
      return CubeSvc.get(
        url,
        { params: { appealId: appealId, cfcCode: cfcCode } },
        false
      );
    }

    function updateAppealDueDate(appealId, dueDate) {
      var url = '/CfcCharity/api/private/charity/admin/appeal/updateDueDate';
      var charityAppealUpdateDueDateBean = {
        appealId: appealId,
        dueDate: dueDate
      };
      return CubeSvc.put(url, charityAppealUpdateDueDateBean);
    }

    return {
      getAppealStatus: getAppealStatus,
      getAppealDeterminationsByLevel: getAppealDeterminationsByLevel,
      updateAppealDeterminationLevel: updateAppealDeterminationLevel,
      getAppealDeterminationHistory: getAppealDeterminationHistory,
      getReviewerComments: getReviewerComments,
      getDueDateHistory: getDueDateHistory,
      updateAppealStatus: updateAppealStatus,
      getAppealStatusChangeHistory: getAppealStatusChangeHistory,
      getApplicationStatus: getApplicationStatus,
      updateApplicationStatus: updateApplicationStatus,
      addReviewerComment: addReviewerComment,
      updateAppealDueDate: updateAppealDueDate
    };
  }
})();
