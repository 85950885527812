/*
 * This is the 'source' that the vendor JS lib will build from. No more manual generation.
 *
 * To build:
 * - `cd ui`
 * - `npm install`
 * - `npm run build:vendor` (runs both `npm run build:vendor-css` and `npm run build:vendor-js`).
 */

// jquery, bootstrap, etc. libs
const jquery = require('jquery');
Object.defineProperty(window, 'jQuery', { value: jquery });
Object.defineProperty(window, '$', { value: jquery });
require('jquery-ui/ui/widgets/datepicker');
require('../../node_modules/bootstrap/dist/js/bootstrap');
require('spectrum-colorpicker')(jquery);
require('moment');

// other jquery stuff, moved here
jQuery.browser = {};
jQuery.browser.msie = false;
jQuery.browser.version = 0;
if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
  jQuery.browser.msie = true;
  jQuery.browser.version = RegExp.$1;
}

// angular and direct deps
const angular = require('angular');
Object.defineProperty(window, 'angular', { value: angular });
require('angular-messages');
require('angular-hateoas');
require('angular-resource');
require('angular-aria');
require('@uirouter/angularjs');
require('angular-ui-bootstrap');
require('angular-utils-pagination');
require('../../node_modules/textangular/dist/textAngular-rangy.min'); // rangy from textAngular
require('../../node_modules/textangular/dist/textAngular-sanitize.min'); // sanitize from textAngular
require('../../node_modules/textangular/dist/textAngular.min'); // main textAngular
require('angular-ui-mask');
require('angular-spectrum-colorpicker');
require('angular-block-ui');
require('angular-uuid');
require('angular-moment');
require('ng-csv');

require('ng-idle');
require('../../node_modules/ng-infinite-scroll/build/ng-infinite-scroll');

// toastr
const toastr = require('toastr');
window.toastr = toastr;

require('../js/low-level-polyfills');
