(function() {
  var moduleName = 'CfcCharity.directives.applicationAppealButton';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcApplicationAppealButton', [
    '$state',
    'AppealService',
    '$log',
    'AppealStatus',
    'BrowserEventService',
    function($state, AppealService, $log, AppealStatus, BrowserEventService) {
      return {
        restrict: 'E',
        template: require('./applicationAppealButton.html'),
        scope: {
          applicationId: '@',
          applicationStatus: '@',
          applicationType: '@',
          cfcCode: '@'
        },
        controller: function($scope, $state, BreadcrumbTokenDataService) {
          $scope.showAppealButton = false;
          $scope.isFed = 'FEDERATION' === $scope.applicationType;
          var secondaryRoleList = BrowserEventService.getCurrentUser()
            .secondaryRoleList;

          function init() {
            if (
              $scope.applicationStatus !== 'DENIED' &&
              $scope.applicationStatus !== 'IN_APPEAL'
            ) {
              return;
            }

            AppealService.getApplicationAppeal(
              $scope.applicationType,
              $scope.applicationId,
              $scope.cfcCode
            )
              .then(function(res) {
                var appeal = res.data;
                if (!appeal) {
                  return;
                }
                if ($scope.isFed === true) {
                  BreadcrumbTokenDataService.setIsFederation(true);
                  BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                    $scope.applicationId
                  );
                } else {
                  BreadcrumbTokenDataService.setApplicationReviewTokenData(
                    $scope.applicationId
                  );
                }
                $scope.appealDueDate = AppealService.toUTCDate(
                  new Date(appeal.appealDueDate)
                );
                $scope.showAppealButton =
                  appeal.appealStatus === AppealStatus.NotStarted ||
                  appeal.appealStatus === AppealStatus.Pending;
                $scope.actionText =
                  $scope.showAppealButton &&
                  appeal.appealStatus !== AppealStatus.NotStarted
                    ? 'View Appeal'
                    : 'Appeal Now';
              })
              .catch(function(err) {});
          }

          $scope.goToAppealPage = function() {
            if (
              secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
              secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
              secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
            ) {
              $state.go('admin-appeal-dashboard', {
                applicationType: $scope.applicationType,
                applicationId: $scope.applicationId
              });
            } else {
              $state.go('appeal-dashboard', {
                isFederation: $scope.isFed.toString(),
                applicationId: $scope.applicationId
              });
            }
          };

          init();
        }
      };
    }
  ]);
})();
