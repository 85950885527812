// review.module.js - Module declaration for common Review functionality across multiple pages

'use strict';

angular
  .module('CfcCharity.review', [])
  .service('ApplicationReviewService', [
    '$log',
    '$http',
    '$q',
    'CubeSvc',
    function($log, $http, $q, CubeSvc) {
      function nullSafeNames(first, last) {
        var full = [];
        if (null != first) {
          full.push(first);
        }
        if (null != last) {
          full.push(last);
        }
        if (full.length == 0) {
          return null;
        } else {
          return full.join(' ');
        }
      }

      return {
        getData: function(appId, isFed) {
          if (null != appId && undefined !== appId && '' !== appId) {
            var appReview = {};
            var url =
              '/CfcCharity/api/private/application/review/header/' + appId;
            if (isFed) {
              url = '/CfcCharity/api/private/federation/review/' + appId;
            }
            return CubeSvc.get(url).then(
              function(response) {
                // success
                if (isFed) {
                  // transform to same format as others
                  var src = response.data.federationApplicationReviewBean;
                  var ar =
                    src.federationApplicationReviewOverviewByLevelBeanList;
                  var data = {
                    applicationId: appId,
                    currentLevel: src.currentLevel,
                    applicationReviewStatus: src.applicationReviewStatus.toUpperCase(),
                    applicationReviewStatusUpdatedDate:
                      src.applicationReviewStatusDate,
                    applicationReviewStatusUpdatedByName:
                      src.applicationReviewStatusBy
                  };
                  angular.forEach(ar, function(ob, key) {
                    switch (ob.reviewLevel) {
                      case 'Completeness':
                        data.completenessReviewStatus = ob.currentDetermination
                          .toUpperCase()
                          .replace(/\ /i, '_');
                        data.completenessStatusUpdatedDate =
                          ob.determinationDate;
                        data.completenessStatusUpdatedByName = nullSafeNames(
                          ob.determinationSetByFirstName,
                          ob.determinationSetByLastName
                        );
                        break;
                      case 'Staff Eligibility':
                        data.review1Status = ob.currentDetermination
                          .toUpperCase()
                          .replace(/\ /i, '_');
                        data.review1StatusUpdatedDate = ob.determinationDate;
                        data.review1StatusUpdatedByName = nullSafeNames(
                          ob.determinationSetByFirstName,
                          ob.determinationSetByLastName
                        );
                        break;
                      case 'Preliminary Eligibility':
                        data.review2Status = ob.currentDetermination
                          .toUpperCase()
                          .replace(/\ /i, '_');
                        data.review2StatusUpdatedDate = ob.determinationDate;
                        data.review2StatusUpdatedByName = nullSafeNames(
                          ob.determinationSetByFirstName,
                          ob.determinationSetByLastName
                        );
                        break;
                      case 'Final Eligibility':
                        data.review3Status = ob.currentDetermination
                          .toUpperCase()
                          .replace(/\ /i, '_');
                        data.review3StatusUpdatedDate = ob.determinationDate;
                        data.review3StatusUpdatedByName = nullSafeNames(
                          ob.determinationSetByFirstName,
                          ob.determinationSetByLastName
                        );
                        break;
                      default:
                        $log.error('unknown review level');
                        break;
                    }
                  });
                  return data;
                } else {
                  return response.data;
                }
              },
              function(err) {
                // error
                return $q.reject('Error with required parameters...');
              }
            );
          } else {
            return $q.reject('Error with required parameters...');
          }
        }
      };
    }
  ])
  .directive('cfcApplicationReviewReference', [
    '$http',
    '$log',
    '$state',
    '$stateParams',
    'ApplicationReviewService',
    function($http, $log, $state, $stateParams, ApplicationReviewService) {
      return {
        restrict: 'E',
        scope: {
          applicationId: '@',
          isFederation: '@',
          cfcZone: '@',
          activeLevel: '@',
          charityType: '@',
          determinationUpdateCounter: '='
        },
        link: function(scope, elm, attrs, ctrl) {
          scope.isFed = scope.isFederation.toLowerCase() === 'true';
          attrs.$observe('applicationId', function(val) {
            if ('undefined' == typeof val) {
              return;
            }
            scope.render(val);
          });
        },
        controller: [
          '$scope',
          '$element',
          '$attrs',
          '$state',
          '$stateParams',
          function($scope, $element, $attrs, $state, stateParams) {
            $scope.isOpen = false;
            $scope.render = function(appId) {
              if (typeof appId !== 'undefined') {
                ApplicationReviewService.getData(appId, $scope.isFed)
                  .then(
                    function(res) {
                      // set to $scoped var
                      $scope.appReview = res;
                    },
                    function(err) {
                      // ignoring, since the life cycle digests properties as empty first time around
                    }
                  )
                  .catch(function(err) {
                    $log.error(err);
                  });
                $scope.goToCompletenessReview = function() {
                  if ($scope.isFed) {
                    $state.go('federationCompletenessReview', {
                      federationApplicationId: $scope.applicationId
                    });
                  } else {
                    $state.go('reviewCompleteness', {
                      applicationId: $scope.applicationId
                    });
                  }
                };
                $scope.goToStaffReview = function() {
                  if ($scope.isFed) {
                    $state.go('federationStaffEligibilityReview', {
                      federationApplicationId: $scope.applicationId
                    });
                  } else {
                    $state.go('staffEligibility', {
                      applicationId: $scope.applicationId
                    });
                  }
                };
                $scope.goToPreliminaryReview = function() {
                  if ($scope.isFed) {
                    $state.go('federationPreliminaryEligibilityReview', {
                      federationApplicationId: $scope.applicationId
                    });
                  } else {
                    $state.go('preliminaryEligibility', {
                      applicationId: $scope.applicationId
                    });
                  }
                };
                $scope.goToFinalReview = function() {
                  if ($scope.isFed) {
                    $state.go('federationFinalEligibilityReview', {
                      federationApplicationId: $scope.applicationId
                    });
                  } else {
                    $state.go('finalEligibility', {
                      applicationId: $scope.applicationId
                    });
                  }
                };
              }
            };
            $scope.$watch('determinationUpdateCounter', function(
              newValue,
              oldValue
            ) {
              if (
                'undefined' == typeof $scope.applicationId ||
                !newValue ||
                angular.equals(newValue, oldValue)
              ) {
                return;
              }
              $scope.render($scope.applicationId);
            });
            $scope.goToEmailPage = function(isFed) {
              // review-email-dashboard
              $state.go('email-dashboard', {
                isFederation: isFed,
                applicationId: $scope.applicationId,
                sourcePageName: $state.current.name,
                sourcePageParams: $stateParams,
                charityType: $scope.charityType
              });
            };

            $scope.goToDenialReasonsPage = function(isFed) {
              // review-denial-reasons
              $state.go('review-denial-reasons', {
                isFederation: isFed,
                applicationId: $scope.applicationId,
                sourcePageName: $state.current.name,
                sourcePageParams: $stateParams,
                charityType: $scope.charityType
              });
            };
          }
        ],
        template: require('./levelReference.html')
      };
    }
  ])
  .filter('reviewStatusDisplay', function() {
    return function(input) {
      if (!!input) {
        return input.replace(/_/g, ' ');
      }
      return input;
    };
  });
