angular
  .module('CfcCharity.zoneusers', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('activezoneusers', {
        url: '/activezoneusers',
        views: {
          main: {
            template: require('../views/private/admin/zoneusers.html'),
            controller: 'ActiveZoneUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Active Zone Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          zoneId: null,
          zoneName: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            if ($stateParams.zoneId != null) {
              var zoneId = $stateParams.zoneId;
              var url =
                '/CfcCharity/api/private/admin/zone/users/active/' + zoneId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('invitedzoneusers', {
        url: '/invitedzoneusers',
        views: {
          main: {
            template: require('../views/private/admin/zoneusers.html'),
            controller: 'InvitedZoneUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Invited Zone Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          zoneId: null,
          zoneName: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            if ($stateParams.zoneId != null) {
              var zoneId = $stateParams.zoneId;
              var url =
                '/CfcCharity/api/private/admin/zone/users/invited/' + zoneId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('deletezoneuserroleconfirmation', {
        url: '/deletezoneuserroleconfirmation',
        views: {
          main: {
            template: require('../views/private/admin/deletezoneuserroleconfirmation.html'),
            controller: 'DeleteZoneUserRoleConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Delete Zone User Role Confirmation',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          userId: null,
          userSystemRoleId: null,
          userFirstName: null,
          userLastName: null,
          role: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          deleteZoneUserRoleConfirmationData: function($http, $stateParams) {
            var deleteZoneUserRoleConfirmationData = null;
            if (
              $stateParams.userId != null &&
              $stateParams.userSystemRoleId != null
            ) {
              deleteZoneUserRoleConfirmationData = {
                userId: $stateParams.userId,
                userSystemRoleId: $stateParams.userSystemRoleId,
                userFirstName: $stateParams.userFirstName,
                userLastName: $stateParams.userLastName,
                role: $stateParams.role,
                systemUserSecondLevelRoleList:
                  $stateParams.systemUserSecondLevelRoleList,
                availableSystemUserSecondLevelRoleList:
                  $stateParams.availableSystemUserSecondLevelRoleList
              };
            }
            return deleteZoneUserRoleConfirmationData;
          }
        }
      });
      $stateProvider.state('deletezoneuser', {
        url: '/deletezoneuser',
        views: {
          main: {
            template: require('../views/private/admin/zoneusers.html'),
            controller: 'ActiveZoneUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Active Zone Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          zoneId: null,
          zoneName: null,
          systemUserAdminData: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            var systemUserAdminData = null;
            if ($stateParams.systemUserAdminData != null) {
              systemUserAdminData = $stateParams.systemUserAdminData;
            }
            return systemUserAdminData;
          }
        }
      });
      $stateProvider.state('deletezoneuserinvitationconfirmation', {
        url: '/deletezoneuserinvitationconfirmation',
        views: {
          main: {
            template: require('../views/private/admin/deletezoneuserinvitationconfirmation.html'),
            controller: 'DeleteZoneUserInvitationConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Delete Zone User Invitation',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          userId: null,
          systemUserInvitationId: null,
          invitedUserId: null,
          invitedUserName: null,
          role: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          deleteZoneUserInvitationConfirmationData: function(
            $http,
            $stateParams
          ) {
            var deleteZoneUserRoleConfirmationData = null;
            if (
              $stateParams.systemUserInvitationId != null &&
              $stateParams.invitedUserName != null
            ) {
              deleteSystemUserInvitationConfirmationData = {
                userId: $stateParams.userId,
                systemUserInvitationId: $stateParams.systemUserInvitationId,
                invitedUserId: $stateParams.invitedUserId,
                invitedUserName: $stateParams.invitedUserName,
                role: $stateParams.role,
                systemUserSecondLevelRoleList:
                  $stateParams.systemUserSecondLevelRoleList,
                availableSystemUserSecondLevelRoleList:
                  $stateParams.availableSystemUserSecondLevelRoleList
              };
            }
            return deleteSystemUserInvitationConfirmationData;
          }
        }
      });
      $stateProvider.state('deletezoneuserinvitation', {
        url: '/deletezoneuserinvitation',
        views: {
          main: {
            template: require('../views/private/admin/zoneusers.html'),
            controller: 'InvitedZoneUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Invited Zone Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          systemUserAdminData: null,
          zoneId: null,
          zoneName: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            var systemUserAdminData = null;
            if ($stateParams.systemUserAdminData != null) {
              systemUserAdminData = $stateParams.systemUserAdminData;
            }
            return systemUserAdminData;
          }
        }
      });
      $stateProvider.state('resendzoneinvitation', {
        url: '/resendzoneuserinvitation',
        views: {
          main: {
            template: require('../views/private/admin/resendzoneinvitationsuccess.html'),
            controller: 'ResendZoneUserInvitationCtrl'
          }
        },
        data: {
          pageTitle: 'Invited Zone Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          userId: null,
          systemUserInvitationId: null,
          zoneId: null,
          invitedUserName: null,
          role: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          systemUserInvitationData: function($http, $stateParams) {
            var systemUserInvitationData = null;
            if ($stateParams.systemUserInvitationId != null) {
              systemUserInvitationData = {
                userId: $stateParams.userId,
                systemUserInvitationId: $stateParams.systemUserInvitationId,
                zoneId: $stateParams.zoneId,
                invitedUserName: $stateParams.invitedUserName,
                role: $stateParams.role
              };
            }
            return systemUserInvitationData;
          }
        }
      });
      $stateProvider.state('resendzoneinvitationsuccess', {
        url: '/resendzoneinvitationsuccess',
        views: {
          main: {
            template: require('../views/private/admin/zoneusers.html'),
            controller: 'InvitedZoneUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Invited Zone Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
          ]
        },
        params: {
          systemUserAdminData: null,
          zoneId: null,
          zoneName: null,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            var systemUserAdminData = null;
            if ($stateParams.systemUserAdminData != null) {
              systemUserAdminData = $stateParams.systemUserAdminData;
            }
            return systemUserAdminData;
          }
        }
      });
    }
  ])
  .service('ZoneUsersService', [
    '$http',
    function($http) {
      this.getActiveZoneUsers = function(zoneId) {
        return $http.get(
          '/CfcCharity/api/private/admin/zone/users/active/' + zoneId
        );
      };
      this.getInvitedZoneUsers = function(zoneId) {
        return $http.get(
          '/CfcCharity/api/private/admin/zone/users/invited/' + zoneId
        );
      };
      this.addZoneUser = function(zoneUser) {
        var addZoneUserURI = '/CfcCharity/api/private/admin/system/user/add';
        return $http.post(addSystemUserURI, zoneUser);
      };

      this.deleteZoneUser = function(deleteZoneUserData) {
        var addSystemUserURI = '/CfcCharity/api/private/admin/zone/user/delete';
        return $http.post(addSystemUserURI, deleteZoneUserData);
      };
    }
  ])
  .controller('ActiveZoneUsersCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'systemUserAdminData',
    'ZoneUsersService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      systemUserAdminData,
      ZoneUsersService,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.zoneusers');
      $scope.zoneId = $state.params.zoneId;
      $scope.zoneName = $state.params.zoneName;
      if ($scope.zoneId !== null && $scope.zoneName !== null) {
        BrowserEventService.setZoneId($scope.zoneId);
        BrowserEventService.setZoneName($scope.zoneName);
      }
      $scope.activeZoneUsersSelected = true;
      $scope.invitedZoneUsersSelected = false;
      if (systemUserAdminData) {
        $scope.systemUserAdminData = systemUserAdminData;
      }
      $scope.retrieveActiveZoneUsers = function() {
        $scope.activeZoneUsersSelected = true;
        $scope.invitedZoneUsersSelected = false;
        ZoneUsersService.getActiveZoneUsers($scope.zoneId)
          .then(function(res) {
            $scope.systemUserAdminData = res.data;
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.retrieveInvitedZoneUsers = function() {
        $scope.activeZoneUsersSelected = false;
        $scope.invitedZoneUsersSelected = true;
        ZoneUsersService.getInvitedZoneUsers($scope.zoneId)
          .then(function(res) {
            $scope.systemUserAdminData = res.data;
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.addZoneUser = function() {
        $state.go('addZoneuser', {
          userId: $scope.getCurrentUser().id,
          zoneId: $scope.zoneId,
          zoneName: $scope.zoneName,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        });
      };
      $scope.editZoneUser = function(invitedUserId) {
        BrowserEventService.setInvitedUserId(invitedUserId);
        $state.go('editzoneuser', {
          userId: $scope.getCurrentUser().id,
          invitedUserId: invitedUserId,
          zoneId: $scope.zoneId
        });
      };
    }
  ])
  .controller('InvitedZoneUsersCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'systemUserAdminData',
    'SystemUsersService',
    'BrowserEventService',
    'ZoneUsersService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      systemUserAdminData,
      SystemUsersService,
      BrowserEventService,
      ZoneUsersService
    ) {
      $scope.setModuleName('CfcCharity.zoneusers');
      $scope.zoneId = $state.params.zoneId;
      $scope.zoneName = $state.params.zoneName;
      BrowserEventService.setZoneId($scope.zoneId);
      BrowserEventService.setZoneName($scope.zoneName);
      $scope.activeZoneUsersSelected = false;
      $scope.invitedZoneUsersSelected = true;
      if (systemUserAdminData) {
        $scope.systemUserAdminData = systemUserAdminData;
      }
      $scope.retrieveActiveZoneUsers = function() {
        $scope.activeZoneUsersSelected = true;
        $scope.invitedZoneUsersSelected = false;
        ZoneUsersService.getActiveZoneUsers($scope.zoneId)
          .then(function(res) {
            $scope.systemUserAdminData = res.data;
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.retrieveInvitedZoneUsers = function() {
        $scope.activeZoneUsersSelected = false;
        $scope.invitedZoneUsersSelected = true;
        ZoneUsersService.getInvitedZoneUsers($scope.zoneId)
          .then(function(res) {
            $scope.systemUserAdminData = res.data;
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
      $scope.addZoneUser = function() {
        $state.go('addZoneuser', {
          userId: $scope.getCurrentUser().id,
          zoneId: $scope.zoneId,
          zoneName: $scope.zoneName,
          systemUserSecondLevelRoleList: null,
          availableSystemUserSecondLevelRoleList: null
        });
      };
    }
  ])
  .controller('DeleteZoneUserRoleConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'deleteZoneUserRoleConfirmationData',
    'ZoneUsersService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      deleteZoneUserRoleConfirmationData,
      ZoneUsersService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.zoneusers');
      $scope.deleteZoneUserRoleConfirmationData = deleteZoneUserRoleConfirmationData;
      $scope.zoneId = BrowserEventService.getZoneId();
      $scope.zoneName = BrowserEventService.getZoneName();
      $scope.deleteZoneUser = function() {
        var deleteZoneUserData = {
          userId: $scope.deleteZoneUserRoleConfirmationData.userId,
          userSystemRoleId:
            $scope.deleteZoneUserRoleConfirmationData.userSystemRoleId,
          zoneId: $scope.zoneId,
          role: $scope.deleteZoneUserRoleConfirmationData.role,
          zoneUser: true
        };
        ZoneUsersService.deleteZoneUser(deleteZoneUserData)
          .then(function(res) {
            var deleteZoneUserResponse = res.data;
            if (
              deleteZoneUserResponse.error === true ||
              deleteZoneUserResponse.statusCode === 'ERROR'
            ) {
              $scope.showDeleteZoneUserErrorMessage(
                deleteZoneUserResponse.errorResponse.errorCode +
                  ' : ' +
                  deleteZoneUserResponse.errorResponse.errorMessage +
                  '. Please try again later!'
              );
              return;
            }
            var systemUserAdminData =
              deleteZoneUserResponse.systemUserAdminBean;
            $state.go('deletezoneuser', {
              zoneId: BrowserEventService.getZoneId(),
              zoneName: BrowserEventService.getZoneName(),
              systemUserAdminData: systemUserAdminData,
              systemUserSecondLevelRoleList:
                $scope.systemUserSecondLevelRoleList,
              availableSystemUserSecondLevelRoleList:
                $scope.availableSystemUserSecondLevelRoleList
            });
          })
          .catch(function(err) {
            $scope.showDeleteZoneUserErrorMessage(
              'Error while deleting a zone user. Please try again later!'
            );
          });
      };
      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };
      $scope.showDeleteZoneUserErrorMessage = function(error) {
        $scope.deleteZoneUserErrors = error;
      };
    }
  ])
  .controller('DeleteZoneUserInvitationConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'deleteZoneUserInvitationConfirmationData',
    'SystemUsersService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      deleteZoneUserInvitationConfirmationData,
      SystemUsersService,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.zoneusers');
      $scope.deleteZoneUserInvitationConfirmationData = deleteZoneUserInvitationConfirmationData;
      $scope.zoneId = BrowserEventService.getZoneId();
      $scope.zoneName = BrowserEventService.getZoneName();
      $scope.deleteZoneUserInvitation = function() {
        var deleteZoneUserInvitationData = {
          userId: $scope.deleteZoneUserInvitationConfirmationData.userId,
          systemUserInvitationId:
            $scope.deleteZoneUserInvitationConfirmationData
              .systemUserInvitationId,
          zoneUser: true,
          zoneId: $scope.zoneId
        };
        SystemUsersService.deleteSystemUserInvitation(
          deleteZoneUserInvitationData
        )
          .then(function(res) {
            var deleteZoneUserInvitationResponse = res.data;
            if (
              deleteZoneUserInvitationResponse.error === true ||
              deleteZoneUserInvitationResponse.statusCode === 'ERROR'
            ) {
              $scope.showDeleteZoneUserInvitationErrorMessage(
                deleteZoneUserInvitationResponse.errorResponse.errorCode +
                  ' : ' +
                  deleteZoneUserInvitationResponse.errorResponse.errorMessage +
                  '. Please try again later!'
              );
              return;
            }
            var systemUserAdminData =
              deleteZoneUserInvitationResponse.systemUserAdminBean;
            $state.go('deletezoneuserinvitation', {
              systemUserAdminData: systemUserAdminData,
              zoneId: $scope.zoneId,
              zoneName: $scope.zoneName,
              systemUserSecondLevelRoleList:
                $scope.systemUserSecondLevelRoleList,
              availableSystemUserSecondLevelRoleList:
                $scope.availableSystemUserSecondLevelRoleList
            });
          })
          .catch(function(err) {
            $scope.showDeleteZoneUserErrorMessage(
              'Error while deleting a zone user invitation. Please try again later!'
            );
          });
      };
      $scope.showErrorMessage = function(error) {
        /*Todo: Log error*/
      };
      $scope.showDeleteZoneUserInvitationErrorMessage = function(error) {
        $scope.deleteZoneUserInvitationErrors = error;
      };
    }
  ])
  .controller('ResendZoneUserInvitationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'systemUserInvitationData',
    'SystemUsersService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      systemUserInvitationData,
      SystemUsersService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.zoneusers');
      $scope.systemUserInvitationData = systemUserInvitationData;
      $scope.zoneId = BrowserEventService.getZoneId();
      $scope.zoneName = BrowserEventService.getZoneName();
      if (
        $scope.systemUserInvitationData !== null &&
        $scope.systemUserInvitationData !== undefined &&
        $scope.systemUserInvitationData.systemUserInvitationId !== null &&
        $scope.systemUserInvitationData.systemUserInvitationId !== undefined
      ) {
        $scope.systemUserInvitationData.zoneUser = true;
        $scope.systemUserInvitationData.zoneId = $scope.zoneId;
        SystemUsersService.resendSystemUserInvitation(
          $scope.systemUserInvitationData
        )
          .then(function(res) {
            var resendSystemUserInvitationResponse = res.data;
            if (
              resendSystemUserInvitationResponse.error === true ||
              resendSystemUserInvitationResponse.statusCode === 'ERROR'
            ) {
              $scope.showResendSystemUserInvitationErrorMessage(
                resendSystemUserInvitationResponse.errorResponse.errorCode +
                  ' : ' +
                  resendSystemUserInvitationResponse.errorResponse
                    .errorMessage +
                  '. Please try again later!'
              );
              return;
            }
            $scope.systemUserAdminData =
              resendSystemUserInvitationResponse.systemUserAdminBean;
          })
          .catch(function(err) {
            $scope.showResendSystemUserInvitationErrorMessage(
              'Error while resending a system user invitation. Please try again later!'
            );
          });
      }
      $scope.displayInvitedZoneUsers = function() {
        $state.go('resendzoneinvitationsuccess', {
          systemUserAdminData: $scope.systemUserAdminData,
          zoneId: $scope.zoneId,
          zoneName: $scope.zoneName,
          systemUserSecondLevelRoleList: $scope.systemUserSecondLevelRoleList,
          availableSystemUserSecondLevelRoleList:
            $scope.availableSystemUserSecondLevelRoleList
        });
      };
      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };
      $scope.showResendSystemUserInvitationErrorMessage = function(error) {
        $scope.resendSystemUserInvitationErrors = error;
      };
    }
  ]);
