(function() {
  var moduleName = 'CfcCharity.directives.adminReviewDenialReasons';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }
  module.service('AdminReviewDenialReasonsService', [
    '$http',
    function($http) {
      var getDenialReasons = function(pledgeYear, step, appType) {
        return getDenialReasonsByPledgeYear(pledgeYear, step, appType);
      };
      // standard available denial reasons
      function getDenialReasonsByPledgeYear(pledgeYear, step, appType) {
        var url =
          '/CfcCharity/api/private/charity/application/denialreason/lookup/' +
          pledgeYear +
          '/' +
          step +
          '/' +
          appType;
        return $http.get(url);
      }

      var saveDenialReasons = function(appealReason) {
        return saveDenialReasonStep(appealReason);
      };
      // standard available denial reasons
      function saveDenialReasonStep(appealReason) {
        var url =
          '/CfcCharity/api/private/charity/application/denialreason/stored/update';
        return $http.post(url, appealReason);
      }

      var saveDenialReasonsAos = function(appealReason, aosAttachmentId) {
        return saveDenialReasonsAosStep(appealReason, aosAttachmentId);
      };
      function saveDenialReasonsAosStep(appealReason, aosAttachmentId) {
        var url =
          '/CfcCharity/api/private/charity/application/denialreason/stored/update/aos/' +
          aosAttachmentId;
        return $http.post(url, appealReason);
      }

      return {
        getDenialReasons: getDenialReasons,
        saveDenialReasons: saveDenialReasons,
        saveDenialReasonsAos
      };
    }
  ]);
  module.directive('cfcAdminReviewDenialReasons', [
    '$log',
    '$state',
    '$rootScope',
    '$filter',
    'FederationApplicationService',
    'ApplicationService',
    'AdminReviewDenialReasonsService',
    'ZoneCenterService',
    'FederationApplicationReviewService',
    'AdminReviewSectionStepDeterminationService',
    'AdminReviewSectionStepDeterminationSessionService',
    'DenialReasonsService',
    '$q',
    'BrowserEventService',
    'ApplicationWrapperService',
    '$window',
    '$sce',
    function(
      $log,
      $state,
      $rootScope,
      $filter,
      FederationApplicationService,
      ApplicationService,
      AdminReviewDenialReasonsService,
      ZoneCenterService,
      FederationApplicationReviewService,
      AdminReviewSectionStepDeterminationService,
      AdminReviewSectionStepDeterminationSessionService,
      DenialReasonsService,
      $q,
      BrowserEventService,
      ApplicationWrapperService,
      $window,
      $sce
    ) {
      return {
        restrict: 'E',
        template: require('./adminReviewDenialReasons.html'),
        scope: true,
        link: function($scope, $element, $attrs) {},
        controller: function($scope, $element, $attrs) {
          var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
          $scope.show = false;
          $scope.isOpen = false;
          $scope.isReadOnly = false;
          $scope.canEdit = false;
          $scope.canEditAppeal = false;
          $scope.canEditReview = false;
          $scope.isZoneManagerOrReviewer =
            userOb.secondaryRoleList.includes('ZONE_MANAGER') ||
            userOb.secondaryRoleList.includes('REVIEWER');
          var applicationId = null;
          var isFedApp =
            $scope.federationNavbar && $scope.federationNavbar.step != null;
          var currentStep =
            $scope.appNavbar.step || $scope.federationNavbar.step;
          $scope.currentStep = currentStep;
          $scope.step = 'Step ' + currentStep;
          $scope.pledgeYear = determinePledgeYear();
          $scope.currentReviewStatus = {};
          $scope.reviewStatuses = [];
          $scope.appType = $scope.appType || 'All';
          function init() {
            var user = $scope.getCurrentUser();
            if (
              user.secondaryRoleList &&
              Array.isArray(user.secondaryRoleList)
            ) {
              $scope.show = AdminReviewSectionStepDeterminationService.isAuthorized();
              $scope.isReadOnly =
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0;
            }
            applicationId = isFedApp
              ? FederationApplicationService.getApplicationId()
              : ApplicationService.getApplicationId();
            if (!isFedApp) {
              if ($scope.show) {
                performRoleChecks();
              }
            }
          }

          function performRoleChecks() {
            return $q(function(resolve, reject) {
              $scope.user = BrowserEventService.getCurrentUser();
              if (
                $scope.user.secondaryRoleList &&
                Array.isArray($scope.user.secondaryRoleList)
              ) {
                ApplicationWrapperService.getAppData(
                  applicationId,
                  isFedApp
                ).then(function(res) {
                  $scope.appDash = res.data.value;
                  setDenialReasons();
                  if (
                    $scope.user.secondaryRoleList.includes('OPM_ADMIN') ||
                    $scope.user.secondaryRoleList.includes('OPM_STAFF')
                  ) {
                    $scope.hasAccess = true;
                    $scope.canEdit = true;
                    $scope.canEditAppeal =
                      $scope.appDash.status === 'IN_APPEAL' ||
                      $scope.appDash.applicationStatus === 'IN_APPEAL'
                        ? true
                        : false;
                    $scope.canEditReview = isInReviewStatus($scope.appDash);
                    resolve('has access');
                  } else if (
                    $scope.user.secondaryRoleList.includes('OPM_AUDITOR')
                  ) {
                    $scope.hasAccess = true;
                    $scope.canEdit = false;
                    $scope.canEditAppeal = false;
                    $scope.canEditReview = false;
                    resolve('has access');
                  } else if (
                    $scope.user.secondaryRoleList.includes('ZONE_MANAGER') ||
                    $scope.user.secondaryRoleList.includes('REVIEWER')
                  ) {
                    // check for respective zone!
                    ZoneCenterService.findCharityAccessEligibility(
                      $scope.user.id,
                      $scope.appDash.cfcCode
                    ).then(
                      function(res) {
                        if (res.data === true) {
                          $scope.hasAccess = true;
                          $scope.canEdit = true;
                          $scope.canEditReview = isInReviewStatus(
                            $scope.appDash
                          );
                          resolve('has access');
                        } else {
                          $scope.hasAccess = false;
                          $scope.canEdit = false;
                          $scope.canEditAppeal = false;
                          $scope.canEditReview = false;
                          reject('no access');
                        }
                      },
                      function(err) {
                        $log.error(
                          'error establishing user access for this zone',
                          err
                        );
                        $scope.hasAccess = false;
                        $scope.canEdit = false;
                        reject('no access');
                      }
                    );
                  } else {
                    reject('no access');
                  }
                });
              } else {
                reject('no access');
              }
            });
          }

          function determinePledgeYear() {
            var pledgeYear = null;
            if (isFedApp) {
              pledgeYear = FederationApplicationService.getPledgeYear();
            } else {
              pledgeYear = ApplicationService.getPledgeYear();
            }
            if (pledgeYear == null) {
              pledgeYear =
                $scope.$parent.$parent.pledgeYear ||
                $scope.$parent.$parent.appNavbar.pledgeYear;
            }
            $log.debug('returning PledgeYear: ' + pledgeYear);
            return pledgeYear;
          }

          function isInReviewStatus(app) {
            var status = ['IN_REVIEW', 'SUBMITTED', 'MORE_INFO'];
            if (
              status.indexOf(app.status) > -1 ||
              status.indexOf(app.applicationStatus) > -1
            ) {
              return true;
            }
            return false;
          }

          function getInitials() {
            var initials = '';
            if (userOb && userOb.firstName && userOb.firstName.length >= 1) {
              initials = userOb.firstName.substring(0, 1).toUpperCase();
            }
            if (userOb && userOb.lastName && userOb.lastName.length >= 1) {
              initials += userOb.lastName.substring(0, 1).toUpperCase();
            }
            return initials;
          }

          // async setter for canned and selected/custom reasons, returns promise handle
          function setDenialReasons() {
            return $q(function(resolve, reject) {
              // get standard/canned denial reasons
              AdminReviewDenialReasonsService.getDenialReasons(
                $scope.pledgeYear,
                $scope.step,
                $scope.appType
              ).then(
                function(resp1) {
                  var cannedDenialReasons =
                    resp1.data.applicationDenialReasonLookupList;
                  $scope.cannedDenialReasons = [];
                  $scope.isOffYear = 'all';
                  //filter all the data if there is apptype filter again
                  var aType = null,
                    bType = null,
                    cType = null;

                  //get the application type from step 2
                  var preAppDetails = ApplicationService.getPreApplicationDetails();
                  if (preAppDetails != null) {
                    var isOffYear = preAppDetails.isOffYear;
                    if (isOffYear === false) {
                      $scope.isOffYear = 'Full Application';
                      cType = 'full application';
                    }
                  }
                  var app = ApplicationService.getApplicationStep2();
                  if (app != null) {
                    switch (app.charityType) {
                      case 'LOCAL':
                        if (app.fysaFyspWfr) {
                          aType = 'fsya, fsyp, mwr';
                          bType = 'local';
                        } else {
                          bType = 'local';
                        }
                        break;
                      case 'INTERNATIONAL':
                        if (app.fysaFyspWfr) {
                          aType = 'fsya, fsyp, mwr';
                          bType = 'international';
                        } else {
                          bType = 'international';
                        }
                        break;
                      case 'NATIONAL/INTERNATIONAL':
                      case 'NATIONAL':
                        if (app.fysaFyspWfr) {
                          aType = 'fsya, fsyp, mwr';
                          bType = 'national/international';
                        } else {
                          bType = 'national/international';
                        }
                        break;
                      default:
                        bType = 'all';
                    }

                    //filter the array again based on the desired filter
                    if (bType === 'all') {
                      $scope.cannedDenialReasons = cannedDenialReasons;
                    } else {
                      const applicationTypes = [aType, bType, cType];
                      $scope.cannedDenialReasons = cannedDenialReasons.filter(
                        v =>
                          !v.applicationType ||
                          applicationTypes.includes(
                            v.applicationType.toLowerCase()
                          )
                      );
                    }
                  }

                  if (
                    $scope.currentStep === '4 AOS' &&
                    $scope.aosAttachmentId
                  ) {
                    // get selected/custom denial reasons
                    DenialReasonsService.getSelectDenialReasonsByAttachmentId(
                      $scope.aosAttachmentId
                    ).then(
                      function(resp2) {
                        $scope.selectedReasons =
                          resp2.data.charityApplicationDenialReasons;
                        mergeSelectedWithCanned();
                        resolve('success');
                      },
                      function(err) {
                        reject(err);
                      }
                    );
                  } else {
                    // get selected/custom denial reasons
                    DenialReasonsService.getSelectDenialReasonsByYearAndCFC(
                      $scope.pledgeYear,
                      $scope.appDash.cfcCode
                    ).then(
                      function(resp2) {
                        $scope.selectedReasons = resp2.data;
                        mergeSelectedWithCanned();
                        resolve('success');
                      },
                      function(err) {
                        reject(err);
                      }
                    );
                  }
                },
                function(err) {
                  // error
                  reject(err);
                }
              );
            });
          }

          function mergeSelectedWithCanned() {
            var ar = [];
            angular.forEach($scope.cannedDenialReasons, function(
              cannedDenialReason,
              key
            ) {
              var selectedReason = $scope.selectedReasons.filter(function(
                item
              ) {
                return (
                  item.denialReasonId === cannedDenialReason.id &&
                  (item.checked || item.appealChecked)
                );
              })[0];
              // the following allows html in the reason value to be rendered using ng-bind-html in the template
              cannedDenialReason.reason = $sce.trustAsHtml(
                cannedDenialReason.reason
              );
              if (selectedReason) {
                if (selectedReason.checked) {
                  cannedDenialReason.checked = true;
                  cannedDenialReason.denialReasonText =
                    selectedReason.denialReasonText;
                  cannedDenialReason.updateDate = selectedReason.updateDate;
                  cannedDenialReason.updateByUserInitials =
                    selectedReason.updateByUserInitials;
                }

                if (selectedReason.appealChecked) {
                  cannedDenialReason.appealChecked = true;
                  cannedDenialReason.denialReasonText =
                    selectedReason.denialReasonText;
                  cannedDenialReason.appealUpdateByUserId =
                    selectedReason.appealUpdateByUserId;
                  cannedDenialReason.appealUpdateByUserInitials =
                    selectedReason.appealUpdateByUserInitials;
                  cannedDenialReason.appealUpdateDate =
                    selectedReason.appealUpdateDate;
                }

                ar.push(cannedDenialReason);
              } else {
                ar.push(cannedDenialReason);
              }
            });

            $scope.customReasons = ar;
          }

          function confirmDeletingCustomDenialEntry() {
            return $window.confirm(
              'You have elected to remove your custom denial reason. This will delete the current text. Do you wish to continue?'
            );
          }

          $scope.onCheckChanged = function(reason) {
            if (
              reason.denialReasonText != null &&
              reason.denialReasonText.length > 0 &&
              (!reason.appealChecked && !reason.checked)
            ) {
              if (!confirmDeletingCustomDenialEntry()) {
                if (reason.appealChecked !== undefined) {
                  reason.appealChecked = !reason.appealChecked;
                }
                if (reason.checked !== undefined) {
                  reason.checked = !reason.checked;
                }
                return;
              }
              reason.denialReasonText = null;
            }

            reason.appealUpdateDate = reason.appealChecked ? new Date() : null;
            reason.appealUpdateByUserInitials = reason.appealChecked
              ? getInitials()
              : null;
            reason.updateDate = reason.checked ? new Date() : null;
            reason.updateByUserInitials = reason.checked ? getInitials() : null;

            var appealReason = {
              cfcCode: $scope.appDash.cfcCode,
              campaignId: $scope.appDash.campaignId,
              applicationType: reason.applicationType || null,
              applicationId: $scope.appDash.applicationId,
              denialReasonId: reason.id,
              appealChecked: reason.appealChecked,
              appealUpdateByUserId: userOb.id,
              appealUpdateByUserInitials: reason.appealUpdateByUserInitials,
              appealUpdateDate: reason.appealUpdateDate,
              checked: reason.checked,
              updateByUserId: reason.checked ? userOb.id : null,
              updateByUserInitials: reason.updateByUserInitials,
              updateDate: reason.updateDate
            };
            if ($scope.currentStep === '4 AOS') {
              AdminReviewDenialReasonsService.saveDenialReasonsAos(
                appealReason,
                $scope.aosAttachmentId
              );
            } else {
              AdminReviewDenialReasonsService.saveDenialReasons(appealReason);
            }
          };

          $scope.onCustomDenialReasonSave = function(reason) {
            var appealReason = {
              cfcCode: $scope.appDash.cfcCode,
              campaignId: $scope.appDash.campaignId,
              applicationType: reason.applicationType || null,
              applicationId: $scope.appDash.applicationId,
              denialReasonId: reason.id,
              checked: reason.checked,
              updateByUserId: reason.updateByUserId,
              updateByUserInitials: reason.updateByUserInitials,
              updateDate: reason.updateDate,
              appealChecked: reason.appealChecked,
              appealUpdateByUserId: reason.appealUpdateByUserId,
              appealUpdateByUserInitials: reason.appealUpdateByUserInitials,
              appealUpdateDate: reason.appealUpdateDate,
              denialReasonText: reason.denialReasonText
            };
            AdminReviewDenialReasonsService.saveDenialReasons(appealReason);
          };

          $scope.$on('aosSaveReview', function(evt, data) {
            if (data.lookupCd === 'PASS') {
              $scope.cannedDenialReasons.forEach(reason => {
                reason.checked = false;
              });
            }
          });

          init();
        }
      };
    }
  ]);
})();
