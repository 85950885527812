(function() {
  angular
    .module('CfcCharity.federation_application')
    .factory('FederationApplication', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/:id',
          {
            id: '@id'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep1', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step1/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep2', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step2/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep3', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step3/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep4', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step4/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep5', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step5/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep6', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step6/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep7', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step7/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep8', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step8/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep9', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step9/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationStep10', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/step10/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationApplicationSession', [
      '$resource',
      'CubeSvc',
      'CakeSvc',
      function($resource, CubeSvc, CakeSvc) {
        return $resource(
          '/CfcCharity/api/private/charity/federation_application/session/:id',
          {
            id: '@id'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  var nwInst = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                  return nwInst;
                }
              }
            }
          }
        );
      }
    ])
    .factory('FederationStatus', [
      '$resource',
      'CakeSvc',
      function($resource, CakeSvc) {
        return $resource(
          '/CfcCharity/api/private/federation_application/status/:id',
          {
            id: '@id'
          },
          {
            query: {
              method: 'GET', // CCA-3815: no `isArray: true`, since it needs to be loose for the interceptor transform
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  return instance.value;
                }
              }
            }
          }
        );
      }
    ])
    .service('FederationApplicationService', [
      '$http',
      '$log',
      'CakeSvc',
      'CharityService',
      'FederationApplicationSession',
      'FederationApplication',
      'FederationApplicationStep1',
      'FederationApplicationStep2',
      'FederationApplicationStep3',
      'FederationApplicationStep4',
      'FederationApplicationStep5',
      'FederationApplicationStep6',
      'FederationApplicationStep7',
      'FederationApplicationStep8',
      'FederationApplicationStep9',
      'FederationApplicationStep10',
      'fileUpload',
      'LookupCode',
      'FederationStatus',
      'LOOKUP_CODE',
      'APPLICATION_TYPE',
      'CubeSvc',
      function(
        $http,
        $log,
        CakeSvc,
        CharityService,
        FederationApplicationSession,
        FederationApplication,
        FederationApplicationStep1,
        FederationApplicationStep2,
        FederationApplicationStep3,
        FederationApplicationStep4,
        FederationApplicationStep5,
        FederationApplicationStep6,
        FederationApplicationStep7,
        FederationApplicationStep8,
        FederationApplicationStep9,
        FederationApplicationStep10,
        fileUpload,
        LookupCode,
        FederationStatus,
        LOOKUP_CODE,
        APPLICATION_TYPE,
        CubeSvc
      ) {
        var pledgeYear = null;
        var cfcCode = null;
        var campaignId = null;
        var applicationId = null;
        var preApplicationDetails = null;
        var userSession = null;
        var application = null;
        var applicationStep1 = null;
        var applicationStep2 = null;
        var applicationStep3 = null;
        var applicationStep4 = null;
        var applicationStep5 = null;
        var applicationStep6 = null;
        var applicationStep7 = null;
        var applicationStep8 = null;
        var applicationStep9 = null;
        var applicationStep10 = null;
        var applicationStep = null;
        this.reset = function() {
          pledgeYear = null;
          cfcCode = null;
          campaignId = null;
          applicationId = null;
          preApplicationDetails = null;
          userSession = null;
          application = null;
          applicationStep1 = null;
          applicationStep2 = null;
          applicationStep3 = null;
          applicationStep4 = null;
          applicationStep5 = null;
          applicationStep6 = null;
          applicationStep7 = null;
          applicationStep8 = null;
          applicationStep9 = null;
          applicationStep10 = null;
          applicationStep = null;
        };
        this.toUTCDate = function(date) {
          var _utc = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
          );
          return _utc;
        };
        this.getUserSession = function() {
          if (userSession == null) {
            userSession = new FederationApplicationSession();
          }
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
            userSession
          );
          return (userSession = tmp);
        };
        this.setUserSession = function(usrSession) {
          userSession = usrSession;
        };
        this.getApplication = function() {
          if (application == null) {
            application = new FederationApplication();
            if (this.getApplicationId()) {
              FederationApplication.get({
                id: this.getApplicationId()
              }).$promise.then(function(raw) {
                application = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  raw
                );
              });
            }
          } else {
            if (application.constructor === Object) {
              if (application.hasOwnProperty('id')) {
                delete application.id;
              }
              application = new FederationApplication(application);
            }
          }
          return application;
        };
        this.setApplication = function(data) {
          application = data;
        };
        this.getApplicationStep1 = function() {
          if (applicationStep1 == null) {
            applicationStep1 = new FederationApplicationStep1();
          }
          return applicationStep1;
        };
        this.getApplicationStep2 = function() {
          if (applicationStep2 == null) {
            applicationStep2 = new FederationApplicationStep2();
          }
          return applicationStep2;
        };
        this.getApplicationStep3 = function() {
          if (applicationStep3 == null) {
            applicationStep3 = new FederationApplicationStep3();
          }
          return applicationStep3;
        };
        this.getApplicationStep4 = function() {
          if (applicationStep4 == null) {
            applicationStep4 = new FederationApplicationStep4();
          }
          return applicationStep4;
        };
        this.getApplicationStep5 = function() {
          if (applicationStep5 == null) {
            applicationStep5 = new FederationApplicationStep5();
          }
          return applicationStep5;
        };
        this.getApplicationStep6 = function() {
          if (applicationStep6 == null) {
            applicationStep6 = new FederationApplicationStep6();
          }
          return applicationStep6;
        };
        this.getApplicationStep7 = function() {
          if (applicationStep7 == null) {
            applicationStep7 = new FederationApplicationStep7();
          }
          return applicationStep7;
        };
        this.getApplicationStep8 = function() {
          if (applicationStep8 == null) {
            applicationStep8 = new FederationApplicationStep8();
          }
          return applicationStep8;
        };
        this.getApplicationStep9 = function() {
          if (applicationStep9 == null) {
            applicationStep9 = new FederationApplicationStep9();
          }
          return applicationStep9;
        };
        this.getApplicationStep10 = function() {
          if (applicationStep10 == null) {
            applicationStep10 = new FederationApplicationStep10();
          }
          return applicationStep10;
        };
        this.editFederationApplication = function(appId) {
          this.applicationId = appId;
          FederationApplication.get({
            id: appId
          }).$promise.then(function(app) {
            application = CakeSvc.transformGenericResponseToFlatResourceResponse(
              app
            );
          });
          applicationStep1 = FederationApplicationStep1.get({
            applicationId: appId
          });
          applicationStep2 = FederationApplicationStep2.get({
            applicationId: appId
          });
          applicationStep3 = FederationApplicationStep3.get({
            applicationId: appId
          });
          applicationStep4 = FederationApplicationStep4.get({
            applicationId: appId
          });
          applicationStep5 = FederationApplicationStep5.get({
            applicationId: appId
          });
          applicationStep6 = FederationApplicationStep6.get({
            applicationId: appId
          });
          applicationStep7 = FederationApplicationStep7.get({
            applicationId: appId
          });
          applicationStep8 = FederationApplicationStep8.get({
            applicationId: appId
          });
          applicationStep9 = FederationApplicationStep9.get({
            applicationId: appId
          });
          applicationStep10 = FederationApplicationStep10.get({
            applicationId: appId
          });
        };
        this.saveFileStep3 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          fileUpload
            .updateFileData(
              '/CfcCharity/api/private/federation_application/step3/',
              table
            )
            .then(function(res) {
              if (res.data.value) {
                var response = res.data.value;
                callback(response);
              }
            })
            .catch(function(failedResponse) {
              callback(failedResponse.data);
            });
        };
        this.saveFileStep4 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          fileUpload
            .updateFileData(
              '/CfcCharity/api/private/federation_application/step4/',
              table
            )
            .then(function(res) {
              if (res.data.value) {
                var response = res.data.value;
                callback(response);
              }
            })
            .catch(function(failedResponse) {
              callback(failedResponse.data);
            });
        };
        this.saveFileStep6 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          fileUpload
            .updateFileData(
              '/CfcCharity/api/private/federation_application/step6/',
              table
            )
            .then(function(res) {
              if (res.data.value) {
                var response = res.data.value;
                callback(response);
              }
            })
            .catch(function(failedResponse) {
              callback(failedResponse.data);
            });
        };
        this.saveFileStep7 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          fileUpload
            .updateFileData(
              '/CfcCharity/api/private/federation_application/step7/',
              table
            )
            .then(function(res) {
              if (res.data.value) {
                var response = res.data.value;
                callback(response);
              }
            })
            .catch(function(failedResponse) {
              callback(failedResponse.data);
            });
        };
        this.saveFileStep9 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          fileUpload
            .updateFileData(
              '/CfcCharity/api/private/federation_application/step9/',
              table
            )
            .then(function(res) {
              if (res.data.value) {
                var response = res.data.value;
                callback(response);
              }
            })
            .catch(function(failedResponse) {
              callback(failedResponse.data);
            });
        };
        this.saveTable = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            id: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          table
            .$update()
            .then(function(response) {
              callback(response);
            })
            .catch(function(failedResponse) {
              var failResp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                failedResponse
              );
              callback(failResp);
            });
        };
        this.updateTable = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          table
            .$update()
            .then(function(response) {
              callback(response);
            })
            .catch(function(failedResponse) {
              var failResp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                failedResponse
              );
              callback(failResp);
            });
        };
        this.saveStep = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          table
            .$update()
            .then(function(response) {
              callback(response);
            })
            .catch(function(failedResponse) {
              var failResp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                failedResponse
              );
              callback(failResp);
            });
        };
        this.getStates = function(callback) {
          LookupCode.query(
            {
              lookupType: LOOKUP_CODE.states
            },
            function(data) {
              if (typeof callback === 'function') {
                callback(data);
              }
            }
          );
        };
        this.getFederationStatus = function(applicationId, callback) {
          FederationStatus.query({
            id: applicationId
          })
            .$promise.then(function(data) {
              if (typeof callback === 'function') {
                callback(data);
              }
            })
            .catch(function(err) {
              // error encountered while querying fed status
              $log.error(err.data.message || err);
            });
        };
        this.getCampaignId = function() {
          return campaignId;
        };
        this.setCampaignId = function(pCampaignId) {
          campaignId = pCampaignId;
        };
        this.getApplicationId = function() {
          return this.applicationId;
        };
        this.setApplicationId = function(applicationId) {
          this.applicationId = applicationId;
        };
        this.getPreApplicationDetails = function() {
          return preApplicationDetails;
        };
        this.setPreApplicationDetails = function(pPreApplicationDetails) {
          preApplicationDetails = pPreApplicationDetails;
        };
        this.getPledgeYear = function() {
          return pledgeYear;
        };
        this.setPledgeYear = function(pYear) {
          pledgeYear = pYear;
        };
        this.getCfcCode = function() {
          return cfcCode;
        };
        this.setCfcCode = function(cCode) {
          cfcCode = cCode;
        };
        this.getApplicationStep = function() {
          return applicationStep;
        };
        this.setApplicationStep = function(step) {
          applicationStep = step;
        };
        this.getDateDifference = function(dt2, dt1) {
          var dif =
            (dt2 instanceof Date ? dt2.getTime() : dt2) -
            (dt1 instanceof Date ? dt1.getTime() : dt1);
          var Seconds_from_DT2_to_DT1 = dif / 1000;
          var Seconds_Between_Dates = Math.abs(Seconds_from_DT2_to_DT1);
          return Seconds_Between_Dates;
        };
        this.calculateAfr = function(mgmtExpense, fundraisingExpense, revenue) {
          var url =
            '/CfcCharity/api/private/application/step9/calculateAfr?mgmtExpense=' +
            mgmtExpense +
            '&fundraisingExpense=' +
            fundraisingExpense +
            '&revenue=' +
            revenue;
          return $http.get(url);
        };

        this.getAppLabel = function() {
          var appLabel = {
            charityName: '',
            cfcCode: this.getCfcCode(),
            appYear: this.getPledgeYear(),
            appType: APPLICATION_TYPE.federation
          };
          appLabel.appType =
            appLabel.appType
              .toLowerCase()
              .charAt(0)
              .toUpperCase() + appLabel.appType.toLowerCase().slice(1);
          CharityService.findCharityWithCFC(appLabel.cfcCode).then(function(
            res
          ) {
            var charity = res.data;
            appLabel.charityName = charity.charityName;
          });
          return appLabel;
        };
      }
    ])
    .service('FederationApplicationStep2Service', [
      'CubeSvc',
      function(CubeSvc) {
        this.federationApplicationStep2Details = function(applicationId) {
          var url =
            '/CfcCharity/api/private/federation_application/step2/' +
            applicationId;
          return CubeSvc.get(url, null, false);
        };
        this.retrieveFederationApplicationStep2DetailsByFedAndMemberId = function(
          federationApplicationId,
          memberApplicationId
        ) {
          var retrieveFederationApplicationStep2DetailsByFedAndMemberIdURL =
            '/CfcCharity/api/private/federation_application/step2/' +
            federationApplicationId +
            '/' +
            memberApplicationId;
          return CubeSvc.get(
            retrieveFederationApplicationStep2DetailsByFedAndMemberIdURL,
            null,
            false
          );
        };
      }
    ]);
})();
