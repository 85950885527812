(function() {
  'use strict';

  angular
    .module('CfcCharity.admin.system')
    .controller('CmsDetailCtrl', CmsDetailCtrl);

  CmsDetailCtrl.$inject = [
    '$state',
    '$stateParams',
    '$log',
    'toastrService',
    'cmsService',
    'blockUI',
    'textAngularManager'
  ];

  function CmsDetailCtrl(
    $state,
    $stateParams,
    $log,
    toastrService,
    cmsService,
    blockUI,
    textAngularManager
  ) {
    var vm = this;
    vm.contentId = $stateParams.contentId || -1;

    function init() {
      vm.ready = vm.contentId > 0 ? false : true;

      blockUI.start();
      cmsService
        .getDetail(vm.contentId)
        .then(function(resp) {
          // success
          vm.content = resp.data;
          vm.pageTitle = vm.content.description;
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Request failed.');
        })
        .finally(function() {
          // finally
          vm.ready = true;
          blockUI.stop();
        });
    }

    vm.onCancel = function() {
      $state.go('cms-list', {
        contentId: vm.contentId
      });
    };

    vm.onSave = function() {
      blockUI.start();
      cmsService
        .saveContent(vm.content)
        .then(function(resp) {
          // success
          vm.content = resp.data;
          vm.pageTitle = vm.content.description;
          toastrService.success('Saved successfully');
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Save failed');
        })
        .finally(function() {
          // finally
          blockUI.stop();
        });
    };

    init();
  }
})();
