(function() {
  'use strict';

  angular.module('CfcCharity.admin.system').controller('motdCtrl', motdCtrl);

  motdCtrl.$inject = [
    '$state',
    '$stateParams',
    '$log',
    'toastrService',
    'motdService',
    'blockUI',
    'BrowserEventService'
  ];

  function motdCtrl(
    $state,
    $stateParams,
    $log,
    toastrService,
    motdService,
    blockUI,
    BrowserEventService
  ) {
    var vm = this;

    function init() {
      vm.lastSavedMessage;
      vm.messageChanged = false;
      vm.ready = false;

      motdService
        .getMOTD()
        .then(function(response) {
          // success
          blockUI.start();
          vm.lastSavedMessage = response.data.messageText;
          vm.motd = response.data.messageText;
          vm.ready = true;
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Request failed.');
        })
        .finally(function() {
          // finally
          vm.messageChanged = false;
          blockUI.stop();
        });
    }

    vm.onCancel = function() {
      vm.motd = vm.lastSavedMessage;
      vm.messageChanged = false;
      $state.go('systemmanagement', {
        userId: BrowserEventService.getCurrentUser().id
      });
    };

    vm.onSave = function() {
      motdService
        .saveMOTD(vm.motd)
        .then(function() {
          // success
          blockUI.start();
          vm.messageChanged = false;
          vm.lastSavedMessage = vm.motd;
          toastrService.success('Saved successfully');
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Save failed');
        })
        .finally(function() {
          // finally
          blockUI.stop();
          $state.go('systemmanagement', {
            userId: BrowserEventService.getCurrentUser().id
          });
        });
    };

    vm.onDelete = function() {
      motdService
        .deleteMOTD(vm.motd)
        .then(function() {
          // success
          blockUI.start();
          vm.motd = null;
          vm.messageChanged = false;
          vm.lastSavedMessage = null;
          toastrService.success('Message of the Day deleted successfully');
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Save failed');
        })
        .finally(function() {
          // finally
          blockUI.stop();
        });
    };

    vm.onPreview = function() {
      toastrService.info(vm.motd, 'Message of the Day', {
        timeOut: 0,
        closeButton: true,
        positionClass: 'toast-top-full-width'
      });
    };

    init();
  }
})();
