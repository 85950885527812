// listing.module.js - Module declaration for Admin Zone Listing area

'use strict';

angular
  .module('CfcCharity.admin.zone.listing', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])

  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    config
  ]);

function config(
  $stateProvider,
  $urlRouterProvider,
  HateoasInterceptorProvider,
  USER_ROLES,
  SYSTEM_USER_SECONDARY_ROLES
) {
  $stateProvider
    .state('admin-zone-listing-overview', {
      url: '/admin-zone-listing-overview',
      views: {
        main: {
          template: require('./overview/overview.html'),
          controller: 'AdminZoneListingOverviewCtl'
        }
      },
      data: {
        pageTitle: 'Admin Zone Listing Overview',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
      },
      params: {
        zoneId: null,
        pledgeYear: null,
        zoneUserRole: null
      },
      resolve: {
        parameterData: function($http, $stateParams) {
          // Handle the route from /administrationcenter
          if ($stateParams.zoneId && $stateParams.pledgeYear) {
            var data = {};
            data.zoneId = $stateParams.zoneId;
            data.pledgeYear = $stateParams.pledgeYear;
            data.zoneUserRole = $stateParams.zoneUserRole;
            return data;
          }

          // Handle the route from /admin-zone-listing-printorder
          if ($stateParams.zone && $stateParams.pledgeYear) {
            var data = {};
            data.zoneId = $stateParams.zone.id;
            data.pledgeYear = $stateParams.pledgeYear;
            data.zoneUserRole = $stateParams.zoneUserRole;
            return data;
          }
        }
      }
    })
    .state('admin-zone-listing-printorder', {
      url: '/admin-zone-listing-printorder',
      views: {
        main: {
          template: require('./printorder/printorder.html'),
          controller: 'AdminZoneListingPrintorderCtl'
        }
      },
      data: {
        breadcrumbs: [
          {
            crumbName: '%zoneCode% Listings',
            crumbRoute: 'admin-zone-listing-overview',
            crumbParams:
              '{pledgeYear: %pledgeYear%, zoneId: %zoneId%, zoneUserRole: %zoneUserRole%}'
          }
        ],
        pageTitle: 'Admin Zone Listing Print Order',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
      },
      params: {
        zone: null,
        campaignId: null,
        pledgeYear: null,
        zoneUserRole: null
      },
      resolve: {
        parameterData: function($http, $stateParams) {
          if (
            $stateParams.zone &&
            $stateParams.pledgeYear &&
            $stateParams.campaignId
          ) {
            var data = {};
            data.zone = $stateParams.zone;
            data.pledgeYear = $stateParams.pledgeYear;
            data.campaignId = $stateParams.campaignId;
            return data;
          }
        },
        tokenData: function($stateParams, BreadcrumbTokenDataService) {
          var zonedata = {};
          zonedata.zoneId = $stateParams.zone.id;
          zonedata.zoneCode = $stateParams.zone.code;
          zonedata.pledgeYear = $stateParams.pledgeYear;
          zonedata.zoneUserRole = $stateParams.zoneUserRole;
          return BreadcrumbTokenDataService.setTokenData(zonedata);
        }
      }
    })
    .state('admin-zone-listing-printorder-history', {
      url: '/admin-zone-listing-printorder-history',
      views: {
        main: {
          template: require('./printorderhistory/printorderhistory.html'),
          controller: 'AdminZoneListingPrintorderHistoryCtl'
        }
      },
      data: {
        pageTitle: 'Admin Zone Listing Print Order History',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
      },
      params: {
        zone: null,
        campaignId: null,
        pledgeYear: null,
        zoneUserRole: null
      },
      resolve: {
        parameterData: function($http, $stateParams) {
          if (
            $stateParams.zone &&
            $stateParams.pledgeYear &&
            $stateParams.campaignId
          ) {
            var data = {};
            data.zone = $stateParams.zone;
            data.pledgeYear = $stateParams.pledgeYear;
            data.campaignId = $stateParams.campaignId;
            data.zoneUserRole = $stateParams.zoneUserRole;
            return data;
          }
        }
      }
    });
}
