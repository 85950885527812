(function() {
  'use strict';

  angular
    .module('CfcCharity.admin.system')
    .controller('EmailTemplateCtrl', EmailTemplateCtrl);

  EmailTemplateCtrl.$inject = [
    '$state',
    '$stateParams',
    '$log',
    'toastrService',
    'emailTemplatesService',
    'textAngularManager',
    'blockUI'
  ];

  function EmailTemplateCtrl(
    $state,
    $stateParams,
    $log,
    toastrService,
    emailTemplatesService,
    textAngularManager,
    blockUI
  ) {
    var vm = this;

    function init() {
      vm.ready = false;
      vm.emailTemplateId = $stateParams.emailTemplateId || -1;

      emailTemplatesService
        .getEmailTemplate(vm.emailTemplateId)
        .then(function(resp) {
          // success
          blockUI.start();
          vm.template = resp.data.value;
          vm.ready = true;
          if (vm.template.id === null) {
            vm.template.active = true;
          }
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Request failed.');
        })
        .finally(function() {
          // finally
          blockUI.stop();
        });
    }

    vm.onCancel = function() {
      $state.go('emailTemplates', {
        emailTemplateId: vm.template.id
      });
    };

    vm.onSave = function() {
      if (vm.template.id === null) {
        vm.template.id = 0;
        vm.template.templateName = vm.template.newTemplateName;
      }
      emailTemplatesService
        .saveEmailTemplate(vm.template)
        .then(function(resp) {
          $state.go('emailTemplates');
        })
        .catch(function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
          toastrService.error('Save failed');
        })
        .finally(function() {
          // finally
          blockUI.stop();
        });
    };

    init();
  }
})();
