'use strict';

angular
  .module('CfcCharity.donations')
  .controller('DisbursementCtl', DisbursementCtl);

DisbursementCtl.$inject = [
  '$scope',
  'DonationsService',
  'DownloadService',
  'DonationsReportsDefn',
  '$state',
  '$log',
  'CharityService',
  'CubeSvc'
];

function DisbursementCtl(
  $scope,
  DonationsService,
  DownloadService,
  DonationsReportsDefn,
  $state,
  $log,
  CharityService,
  CubeSvc
) {
  $scope.moduleName = 'CfcCharity.donations';
  $scope.cfcCode = $state.params.cfcCode;
  $scope.campaignId = $state.params.campaignId;
  $scope.year = $state.params.year;
  $scope.month = $state.params.month;
  $scope.date = $state.params.month + '/' + $state.params.year;
  $scope.disbursementAmount = $state.params.disbursementAmount;
  $scope.isFederation = $state.params.isFederation;
  $scope.charityName = $state.params.charityName;
  $scope.donationReportData = {};

  function init() {
    $scope.disbursementDataLoadStatus = 'LOADING';

    var url =
      '/CfcCharity/api/private/charity/application/donation/member/disbursement/' +
      $scope.year +
      '/' +
      $scope.cfcCode +
      '/' +
      $scope.month +
      '/' +
      $scope.campaignId;

    if ($scope.isFederation) {
      CubeSvc.get(url)
        .then(function(res) {
          $scope.disbursementData = res.data.value;
          $scope.disbursementDataLoadStatus = 'SUCCESS';
        })
        .catch(function(err) {
          $log.error(err);
          $scope.disbursementDataLoadStatus = 'ERROR';
        });
    }
  }

  /* download Disbursement Data Report */
  $scope.downloadDisbursementData = function() {
    var url =
      '/CfcCharity/api/private/charity/application/donation/download/reports/' +
      DonationsReportsDefn.FedMembersDisbursementsByMonthYear +
      '/' +
      $scope.cfcCode +
      '/' +
      $scope.year +
      '/' +
      $scope.campaignId +
      '/' +
      '?month=' +
      $scope.month;
    DownloadService.getFileDownload(url);
  };

  $scope.gotoCharityDashboard = function() {
    $state.go('charitydashboard', {
      charityDashboardCfcCode: $scope.cfcCode
    });
  };

  init();
}
