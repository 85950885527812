angular
  .module('CfcCharity.applicationValidations', [])
  .service('ValidationService', ValidationService);
function ValidationService() {
  this.maxLength = function(field, fieldSize) {
    var fielMaxdSize = 50;
    if (fieldSize > fielMaxdSize) {
      return field + ' length should not be greater than ' + fielMaxdSize;
    }
    return undefined;
  };

  this.validateMultipleEmails = function(field, email) {
    var invalid = false;
    var emails;
    if (email) {
      if (email.indexOf(';') > 1) {
        emails = email.split(';');
      } else {
        var emailArray = [];
        emailArray.push(email);
        emails = emailArray;
      }
    } else {
      return invalid;
    }
    for (var index = 0; index < emails.length; ++index) {
      invalid = this.validateEmail(field, emails[index]);
      if (invalid) {
        break;
      }
    }
    return invalid;
  };

  this.validateEmail = function(field, email) {
    email = email.trim();
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && !re.test(email)) {
      return field + ' invalid email address: ' + email;
    }
    return undefined;
  };

  this.sendValidateEmails = function(field, email) {
    var invalid = false;
    var emails;
    var emailList = [];
    if (email) {
      if (email.indexOf(';') > 1) {
        emails = email.split(';');
      } else {
        var emailArray = [];
        emailArray.push(email);
        emails = emailArray;
      }
    } else {
      return invalid;
    }
    for (var index = 0; index < emails.length; ++index) {
      invalid = this.validateEmail(field, emails[index].trim());
      if (!invalid) {
        emailList.push(emails[index].trim());
      }
    }
    emails = null;
    for (var index = 0; index < emailList.length; ++index) {
      if (index === 0) {
        emails = emailList[index];
      } else {
        emails = emails + ';' + emailList[index];
      }
    }
    return emails;
  };

  this.validateZip = function(field, sZip) {
    var isValid = /^\d{5}(\d{4})?$/.test(sZip);
    if (sZip && !isValid) {
      return field + ' invalid zip code ';
    }
    return undefined;
  };

  this.validateUIZip = function(sZip) {
    if (sZip) {
      var isZip5Pass = /^\d{5}$/.test(sZip);
      var isZip9Pass = /^\+?([0-9]{5})\)?[-. ]?([0-9]{4})$/.test(sZip);
      if (isZip5Pass || isZip9Pass) {
        return true;
      } else {
        return false;
      }
    }
  };

  this.validatePhoneNumber = function(field, phoneNumber) {
    var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var isValid = phoneno.test(phoneNumber);
    if (phoneNumber && !isValid) {
      return field + ' invalid phone number ';
    }
    return undefined;
  };

  this.validateStartingZeroFormat = function(field, number) {
    var cfc = /^[0-9]+$/i;
    var isValid = cfc.test(number);
    if (number && !isValid) {
      return field + ' invalid characters ';
    }
    return undefined;
  };

  this.validateWebsiteAddress = function(field, websiteAddress) {
    var web = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    var isValid = web.test(websiteAddress);
    if (websiteAddress && !isValid) {
      return field + ' invalid format ';
    }
    return undefined;
  };

  this.validateAlphaNumeric = function(field, value) {
    var regExp = /^[A-Za-z0-9\s]+$/;
    var isValid = regExp.test(value);
    if (value && !isValid) {
      return field + ' invalid characters ';
    }
    return undefined;
  };

  this.validateAlphaNumericPunctuation = function(field, value) {
    var regExp = /[A-Za-z0-9 _.,!"'/$]*/;
    var isValid = regExp.test(value);
    if (value && !isValid) {
      return field + ' invalid characters ';
    }
    return undefined;
  };
}
