(function() {
  angular.module('CfcCharity.common').factory('urlAccess', UrlAccessFactory);

  UrlAccessFactory.$inject = ['$q', '$http', 'CubeSvc'];

  function UrlAccessFactory($q, $http, CubeSvc) {
    return {
      getFederationApplicationAccess: getFederationApplicationAccess,
      getMemberApplicationAccess: getMemberApplicationAccess,
      getFederationListingAccess: getFederationListingAccess,
      getMemberListingAccess: getMemberListingAccess,
      getDonorDataDownloadAccess: getDonorDataDownloadAccess
    };

    function getFederationApplicationAccess(year, cfcCode, breadcrumbs) {
      var url =
        '/CfcCharity/api/private/users/federation/application/' +
        year +
        '/' +
        cfcCode;
      return CubeSvc.get(url)
        .then(function(res) {
          var access = res.data.value;
          if (access.hasAccessToFedApp === false) {
            breadcrumbs.crumbParams = '';
            breadcrumbs.crumbRoute = '';
          }
          return breadcrumbs;
        })
        .catch(function(err) {});
    }

    function getMemberApplicationAccess(year, cfcCode, breadcrumbs) {
      var url =
        '/CfcCharity/api/private/users/federation/memberapplications/' +
        year +
        '/' +
        cfcCode;
      return CubeSvc.get(url)
        .then(function(res) {
          var access = res.data.value;
          if (access.hasAccessToMemberApp === false) {
            breadcrumbs.crumbParams = '';
            breadcrumbs.crumbRoute = '';
          }
          return breadcrumbs;
        })
        .catch(function(err) {});
    }

    function getFederationListingAccess(year, cfcCode, breadcrumbs) {
      var url =
        '/CfcCharity/api/private/charity/federationListingPage/' +
        year +
        '/' +
        cfcCode;
      return $http
        .get(url)
        .then(function(res) {
          var access = res.data;
          if (access.hasAccessToFedListing === false) {
            breadcrumbs.crumbParams = '';
            breadcrumbs.crumbRoute = '';
          }
          return breadcrumbs;
        })
        .catch(function(err) {});
    }

    function getMemberListingAccess(year, cfcCode, breadcrumbs) {
      var url =
        '/CfcCharity/api/private/charity/memberCharityListingPage/' +
        year +
        '/' +
        cfcCode;
      return CubeSvc.get(url)
        .then(function(res) {
          var access = res.data.value;
          if (access.hasAccessToMemListing === false) {
            breadcrumbs.crumbParams = '';
            breadcrumbs.crumbRoute = '';
          }
          return breadcrumbs;
        })
        .catch(function(err) {});
    }

    function getDonorDataDownloadAccess(campaignYear, cfcCode, includeMembers) {
      var url =
        '/CfcCharity/api/private/admin/reports/pledge/charityDonors/' +
        campaignYear +
        '/' +
        cfcCode;
      return $http
        .get(url)
        .then(function(res) {
          var hasAccessToDownloadDonorData = res.data;
          return hasAccessToDownloadDonorData;
        })
        .catch(function(err) {});
    }
  }
})();
