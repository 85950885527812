(function() {
  angular
    .module('CfcCharity.application')
    .factory('UserCharityRole', [
      '$resource',
      function($resource) {
        return $resource(
          '/CfcCharity/api/private/charity/role/user/cfcCode/:cfcCode'
        );
      }
    ])
    .factory('CharityApplication', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/:id',
          {
            id: '@id'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  return CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  return CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep1', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step1/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep2', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step2/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep2A', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step2a/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            create: {
              method: 'POST',
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep3', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step3/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep4', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step4/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep5', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step5/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep6', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step6/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep7', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step7/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep8', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step8/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep9', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step9/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep10', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step10/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationStep11', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/step11/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationFee', [
      '$resource',
      'CakeSvc',
      'CubeSvc',
      function($resource, CakeSvc, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/fee/:applicationId',
          {
            applicationId: '@applicationId'
          },
          {
            get: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  return CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                }
              }
            },
            save: {
              method: 'POST',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  return CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                }
              }
            },
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  return CakeSvc.transformGenericResponseToFlatResourceResponse(
                    instance
                  );
                }
              }
            }
          }
        );
      }
    ])
    .factory('CharityApplicationSession', [
      '$resource',
      'CubeSvc',
      function($resource, CubeSvc) {
        return $resource(
          '/CfcCharity/api/private/charity/application/session/:id',
          {
            id: '@id'
          },
          {
            update: {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            },
            save: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              interceptor: {
                response: function(response) {
                  // Get the instance from the response object
                  var instance = response.resource;

                  // Return the instance, accounting for the .value namespace, etc.
                  CubeSvc.displayMessages(instance);
                  return instance;
                }
              }
            }
          }
        );
      }
    ])
    .factory('LookupCode', [
      '$resource',
      function($resource) {
        return $resource('/CfcCharity/api/private/lookup/:lookupType', {
          lookupType: '@lookupType'
        });
      }
    ])
    .factory('ApplicationStatus', [
      '$resource',
      'CubeSvc',
      'CakeSvc',
      function($resource, CubeSvc, CakeSvc) {
        return $resource(
          '/CfcCharity/api/private/application/status/:id',
          {
            id: '@id'
          },
          {
            query: {
              method: 'GET',
              interceptor: {
                response: function(response) {
                  var instance = response.resource;
                  CubeSvc.displayMessages(instance);
                  return instance.value;
                }
              }
            }
          }
        );
      }
    ])
    .service('fileUpload', [
      '$http',
      function($http) {
        this.createFileData = function(uploadUrl, table) {
          var fd = new FormData();
          for (var key in table) {
            if (key.indexOf('$') < 0) {
              if (null != table[key]) {
                fd.append(key, table[key]);
              }
            }
          }
          return $http.post(uploadUrl, fd, {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined
            }
          });
        };
        this.updateFileData = function(uploadUrl, table) {
          var fd = new FormData();
          for (var key in table) {
            if (key.indexOf('$') < 0) {
              if (null != table[key]) {
                fd.append(key, table[key]);
              }
            }
          }
          return $http.put(uploadUrl, fd, {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined
            }
          });
        };
      }
    ])
    .service('ApplicationService', [
      '$http',
      '$q',
      '$log',
      'CubeSvc',
      'CakeSvc',
      'CharityApplicationSession',
      'CharityApplication',
      'CharityApplicationStep1',
      'CharityApplicationStep2',
      'CharityApplicationStep2A',
      'CharityApplicationStep3',
      'CharityApplicationStep4',
      'CharityApplicationStep5',
      'CharityApplicationStep6',
      'CharityApplicationStep7',
      'CharityApplicationStep8',
      'CharityApplicationStep9',
      'CharityApplicationStep10',
      'CharityApplicationStep11',
      'CharityApplicationFee',
      'fileUpload',
      'LookupCode',
      'ApplicationStatus',
      'LOOKUP_CODE',
      'CharityService',
      'APPLICATION_TYPE',
      function(
        $http,
        $q,
        $log,
        CubeSvc,
        CakeSvc,
        CharityApplicationSession,
        CharityApplication,
        CharityApplicationStep1,
        CharityApplicationStep2,
        CharityApplicationStep2A,
        CharityApplicationStep3,
        CharityApplicationStep4,
        CharityApplicationStep5,
        CharityApplicationStep6,
        CharityApplicationStep7,
        CharityApplicationStep8,
        CharityApplicationStep9,
        CharityApplicationStep10,
        CharityApplicationStep11,
        CharityApplicationFee,
        fileUpload,
        LookupCode,
        ApplicationStatus,
        LOOKUP_CODE,
        CharityService,
        APPLICATION_TYPE
      ) {
        var pledgeYear = null;
        var cfcCode = null;
        var campaignId = null;
        var applicationId = null;
        var parentFederationApplicationId = null;
        var preApplicationDetails = null;
        var userSession = null;
        var application = null;
        var applicationStep1 = null;
        var applicationStepTwo = null;
        var applicationStep2A = null;
        var applicationStep3 = null;
        var applicationStep4 = null;
        var applicationStep5 = null;
        var applicationStep6 = null;
        var applicationStep7 = null;
        var applicationStep8 = null;
        var applicationStep9 = null;
        var applicationStep10 = null;
        var applicationStep11 = null;
        var applicationFee = null;
        var applicationStep = null;
        this.reset = function() {
          pledgeYear = null;
          cfcCode = null;
          campaignId = null;
          applicationId = null;
          parentFederationApplicationId = null;
          preApplicationDetails = null;
          userSession = null;
          application = null;
          applicationStep1 = null;
          applicationStepTwo = null;
          applicationStep2A = null;
          applicationStep3 = null;
          applicationStep4 = null;
          applicationStep5 = null;
          applicationStep6 = null;
          applicationStep7 = null;
          applicationStep8 = null;
          applicationStep9 = null;
          applicationStep10 = null;
          applicationStep11 = null;
          applicationStep = null;
          this.setApplicationId(null);
        };
        this.toUTCDate = function(date) {
          var _utc = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
          );
          return _utc;
        };
        this.getUserSession = function() {
          if (userSession == null) {
            userSession = new CharityApplicationSession();
          }
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
            userSession
          );
          return (userSession = tmp);
        };
        this.saveUserSession = function() {
          var userSession = this.getUserSession();
          userSession.applicationId = this.getApplicationId();
          userSession.cfcCode = this.getCfcCode();
          if (!userSession.lastAccessedBeginDate) {
            userSession.lastAccessedBeginDate = this.toUTCDate(new Date());
          }
          if (userSession.hasOwnProperty('id')) {
            delete userSession.id;
          }
          return $q(function(resolve, reject) {
            userSession.$save(
              function(res) {
                CubeSvc.displayMessages(res);
                resolve(res);
              },
              function(err) {
                CubeSvc.displayMessages(err);
                reject(err);
              }
            );
          });
        };
        this.getParentFederationApplicationId = function() {
          return parentFederationApplicationId;
        };
        this.setParentFederationApplicationId = function(parentFedAppId) {
          parentFederationApplicationId = parentFedAppId;
        };
        this.getApplication = function() {
          if (application == null) {
            application = new CharityApplication();
            if (this.getApplicationId()) {
              CharityApplication.get({
                id: this.getApplicationId()
              }).$promise.then(function(raw) {
                application = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  raw
                );
              });
            }
          } else {
            if (application.constructor === Object) {
              if (application.hasOwnProperty('id')) {
                delete application.id;
              }
              application = new CharityApplication(application);
            }
          }
          return application;
        };
        this.setApplication = function(data) {
          application = data;
        };
        this.getApplicationStep1 = function() {
          if (applicationStep1 == null) {
            applicationStep1 = new CharityApplicationStep1();
            return applicationStep1;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep1
          );
        };
        this.getApplicationStep2 = function() {
          if (applicationStepTwo == null) {
            applicationStepTwo = new CharityApplicationStep2();
            return applicationStepTwo;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStepTwo
          );
        };
        this.getApplicationStep2A = function() {
          if (applicationStep2A == null) {
            applicationStep2A = new CharityApplicationStep2A();
            return applicationStep2A;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep2A
          );
        };
        this.getApplicationStep3 = function() {
          if (applicationStep3 == null) {
            applicationStep3 = new CharityApplicationStep3();
            return applicationStep3;
          } else {
            return CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep3
            );
          }
        };
        this.getApplicationStep4 = function(applicationId, id) {
          if (applicationStep4 == null) {
            applicationStep4 = new CharityApplicationStep4();
            return applicationStep4;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep4
          );
        };
        this.getApplicationStep5 = function() {
          if (applicationStep5 == null) {
            applicationStep5 = new CharityApplicationStep5();
            return applicationStep5;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep5
          );
        };
        this.getApplicationStep6 = function() {
          if (applicationStep6 == null) {
            applicationStep6 = new CharityApplicationStep6();
            return applicationStep6;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep6
          );
        };
        this.getApplicationStep7 = function() {
          if (applicationStep7 == null) {
            applicationStep7 = new CharityApplicationStep7();
            return applicationStep7;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep7
          );
        };
        this.getApplicationStep8 = function() {
          if (applicationStep8 == null) {
            applicationStep8 = new CharityApplicationStep8();
            return applicationStep8;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep8
          );
        };
        this.getApplicationStep9 = function() {
          if (applicationStep9 == null) {
            applicationStep9 = new CharityApplicationStep9();
            return applicationStep9;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep9
          );
        };
        this.getApplicationStep10 = function() {
          if (applicationStep10 == null) {
            applicationStep10 = new CharityApplicationStep10();
            return applicationStep10;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep10
          );
        };
        this.getApplicationStep11 = function() {
          if (applicationStep11 == null) {
            applicationStep11 = new CharityApplicationStep11();
            return applicationStep11;
          }
          return CakeSvc.transformGenericResponseToFlatResourceResponse(
            applicationStep11
          );
        };
        this.getApplicationFee = function() {
          if (applicationFee == null) {
            applicationFee = new CharityApplicationFee();
          }
          return applicationFee;
        };
        this.editApplication = function(appId) {
          this.applicationId = appId;
          CharityApplication.get({
            id: appId
          }).$promise.then(function(application) {
            application = CakeSvc.transformGenericResponseToFlatResourceResponse(
              application
            );
          });
          CharityApplicationStep1.get({
            applicationId: appId
          }).$promise.then(function(applicationStep1) {
            applicationStep1 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep1
            );
          });
          CharityApplicationStep2.get({
            applicationId: appId
          }).$promise.then(function(applicationStep2) {
            applicationStepTwo = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep2
            );
          });
          CharityApplicationStep2A.get({
            applicationId: appId
          }).$promise.then(function(applicationStep2A) {
            applicationStep2A = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep2A
            );
          });
          CharityApplicationStep3.get({
            applicationId: appId
          }).$promise.then(function(appStep3) {
            applicationStep3 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              appStep3
            );
          });
          CharityApplicationStep4.get({
            applicationId: appId
          }).$promise.then(function(applicationStep4) {
            applicationStep4 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep4
            );
          });
          CharityApplicationStep5.get({
            applicationId: appId
          }).$promise.then(function(applicationStep5) {
            applicationStep5 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep5
            );
          });
          CharityApplicationStep6.get({
            applicationId: appId
          }).$promise.then(function(applicationStep6) {
            applicationStep6 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep6
            );
          });
          CharityApplicationStep7.get({
            applicationId: appId
          }).$promise.then(function(applicationStep7) {
            applicationStep7 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep7
            );
          });
          CharityApplicationStep8.get({
            applicationId: appId
          }).$promise.then(function(applicationStep8) {
            applicationStep8 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep8
            );
          });
          CharityApplicationStep9.get({
            applicationId: appId
          }).$promise.then(function(applicationStep9) {
            applicationStep9 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep9
            );
          });
          CharityApplicationStep10.get({
            applicationId: appId
          }).$promise.then(function(applicationStep10) {
            applicationStep10 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep10
            );
          });
          CharityApplicationStep11.get({
            applicationId: appId
          }).$promise.then(function(applicationStep11) {
            applicationStep11 = CakeSvc.transformGenericResponseToFlatResourceResponse(
              applicationStep11
            );
          });
        };
        this.saveFileStep2A = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          if (!table.$resolved) {
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/application/step2a/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          } else {
            fileUpload
              .updateFileData(
                '/CfcCharity/api/private/application/step2a/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          }
        };
        this.saveFileStep4 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          if (!table.$resolved) {
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/application/step4/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          } else {
            fileUpload
              .updateFileData(
                '/CfcCharity/api/private/application/step4/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          }
        };
        this.saveFileStep5 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          if (!table.$resolved) {
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/application/step5/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          } else {
            fileUpload
              .updateFileData(
                '/CfcCharity/api/private/application/step5/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          }
        };
        this.saveFileStep6 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          if (!table.$resolved) {
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/application/step6/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          } else {
            fileUpload
              .updateFileData(
                '/CfcCharity/api/private/application/step6/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          }
        };
        this.saveFileStep7 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          if (!table.$resolved) {
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/application/step7/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          } else {
            fileUpload
              .updateFileData(
                '/CfcCharity/api/private/application/step7/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          }
        };
        this.saveFileStep8 = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          if (!table.$resolved) {
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/application/step8/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          } else {
            fileUpload
              .updateFileData(
                '/CfcCharity/api/private/application/step8/',
                table
              )
              .then(function(res) {
                if (res.data.value) {
                  var response = res.data.value;
                  callback(response);
                }
              })
              .catch(function(failedResponse) {
                callback(failedResponse.data);
              });
          }
        };
        this.saveTable = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            id: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          table
            .$update()
            .then(function(resp) {
              var response = CakeSvc.transformGenericResponseToFlatResourceResponse(
                resp
              );
              callback(response);
            })
            .catch(function(failedResponse) {
              var failResp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                failedResponse
              );
              callback(failResp);
            });
        };
        this.updateTable = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          table
            .$update()
            .then(function(resp) {
              var response = CakeSvc.transformGenericResponseToFlatResourceResponse(
                resp
              );
              callback(response);
            })
            .catch(function(failedResponse) {
              var failResp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                failedResponse
              );
              callback(failResp);
            });
        };
        this.saveStep = function(table, callback) {
          table = CakeSvc.addPropsTo(table, {
            applicationId: this.getApplicationId(),
            cfcCode: this.getCfcCode
          });
          if (table.$resolved) {
            table
              .$update()
              .then(function(resp) {
                var response = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  resp
                );
                callback(response);
              })
              .catch(function(failedResponse) {
                var failResp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  failedResponse
                );
                callback(failResp);
              });
          } else {
            table
              .$save({ applicationId: null })
              .then(function(resp) {
                var response = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  resp
                );
                callback(response);
              })
              .catch(function(failedResponse) {
                var failResp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  failedResponse
                );
                callback(failResp);
              });
          }
        };
        this.getStates = function(callback) {
          LookupCode.query(
            {
              lookupType: LOOKUP_CODE.states
            },
            function(data) {
              if (typeof callback === 'function') {
                callback(data);
              }
            }
          );
        };
        this.getApplicationStatus = function(applicationId, callback) {
          ApplicationStatus.query(
            {
              id: applicationId
            },
            function(data) {
              if (typeof callback === 'function') {
                callback(data);
              }
            },
            function(err) {
              $log.error(err);
            }
          );
        };
        this.getCampaignId = function() {
          return campaignId;
        };
        this.setCampaignId = function(pCampaignId) {
          campaignId = pCampaignId;
        };
        this.getApplicationId = function() {
          return this.applicationId;
        };
        this.setApplicationId = function(applicationId) {
          this.applicationId = applicationId;
        };
        this.getPreApplicationDetails = function() {
          return preApplicationDetails;
        };
        this.setPreApplicationDetails = function(pPreApplicationDetails) {
          preApplicationDetails = pPreApplicationDetails;
        };
        this.getPledgeYear = function() {
          return pledgeYear;
        };
        this.setPledgeYear = function(pYear) {
          pledgeYear = pYear;
        };
        this.getCfcCode = function() {
          return cfcCode;
        };
        this.setCfcCode = function(cCode) {
          cfcCode = cCode;
        };
        this.getApplicationType = function() {
          if (this.getParentFederationApplicationId() === null) {
            var app = this.getApplication();
            if (app.federationId != null) {
              return APPLICATION_TYPE.member_of_federation;
            } else {
              return APPLICATION_TYPE.independent;
            }
          } else {
            return APPLICATION_TYPE.member_of_federation;
          }
        };
        this.getApplicationStep = function() {
          return applicationStep;
        };
        this.setApplicationStep = function(step) {
          applicationStep = step;
        };
        this.getDateDifference = function(dt2, dt1) {
          var dif =
            (dt2 instanceof Date ? dt2.getTime() : dt2) -
            (dt1 instanceof Date ? dt1.getTime() : dt1);
          var Seconds_from_DT2_to_DT1 = dif / 1000;
          var Seconds_Between_Dates = Math.abs(Seconds_from_DT2_to_DT1);
          return Seconds_Between_Dates;
        };
        this.calculateAfr = function(mgmtExpense, fundraisingExpense, revenue) {
          var url =
            '/CfcCharity/api/private/application/step9/calculateAfr?mgmtExpense=' +
            mgmtExpense +
            '&fundraisingExpense=' +
            fundraisingExpense +
            '&revenue=' +
            revenue;
          return CubeSvc.get(url);
        };
        this.getAllApplicationRFIs = function(applicationId) {
          var url =
            '/CfcCharity/api/private/application/review/' +
            applicationId +
            '/all';
          return CubeSvc.get(url, null, false);
        };
        this.getApplicationSignatureHistory = function(applicationId) {
          var url =
            '/CfcCharity/api/private/application/signatureHistory/' +
            applicationId;
          return CubeSvc.get(url, { params: { isFederation: false } }, false);
        };
        this.getAppLabel = function() {
          var appLabel = {
            charityName: '',
            cfcCode: this.getCfcCode(),
            appYear: this.getPledgeYear(),
            appType: this.getApplicationType()
          };
          appLabel.appType =
            appLabel.appType
              .toLowerCase()
              .charAt(0)
              .toUpperCase() + appLabel.appType.toLowerCase().slice(1);

          CharityService.findCharityWithCFC(appLabel.cfcCode).then(function(
            resp
          ) {
            var charity = resp.data;
            appLabel.charityName = charity.charityName;
          });
          return appLabel;
        };
        this.findCurrentCharityInfo = function(cfcCode, pledgeYear) {
          var defer = $q.defer();
          CubeSvc.get(
            '/CfcCharity/api/private/getParentInformation/' +
              cfcCode +
              '/' +
              pledgeYear
          ).then(function(response) {
            if (response && response.data && response.data.value) {
              defer.resolve({
                applicationStatus: response.data.value.applicationStatus,
                applicationType: response.data.value.applicationType,
                applicationId: response.data.value.applicationId,
                charityType: response.data.value.charityType
              });
            } else {
              defer.resolve(null);
            }
          });
          return defer.promise;
        };
      }
    ])
    .service('UtilityService', [
      '$http',
      function($http) {
        return {
          findIndependentAppFees: function(campaignId, applicationId) {
            return $http.get(
              '/CfcCharity/api/private/application/fees?campaignId=' +
                campaignId +
                '&applicationType=INDEPENDENT' +
                '&applicationId=' +
                applicationId
            );
          }
        };
      }
    ])
    .service('Step2CharitySearch', [
      '$http',
      'ApplicationService',
      'CubeSvc',
      function($http, ApplicationService, CubeSvc) {
        return {
          findCharityWithEIN: function(ein) {
            return $http.get(
              '/CfcCharity/api/private/charity/findCharityWithEIN/' + ein
            );
          },
          findCharityWithName: function(charityName) {
            return $http.get(
              '/CfcCharity/api/private/charity/findCharityWithName/' +
                charityName
            );
          },
          findCurrentCharityType: function(cfcCode) {
            var pledgeYear =
              ApplicationService.getApplication().pledgeYear ||
              ApplicationService.getPledgeYear();
            return CubeSvc.get(
              '/CfcCharity/api/private/charity/findCurrentCharityType?cfcCode=' +
                cfcCode +
                '&pledgeYear=' +
                pledgeYear
            );
          }
        };
      }
    ])
    .service('Step4', [
      '$http',
      function($http) {
        return {
          updateApplicationStep4: function(applicationStep4) {
            return fileUpload.updateFileData(
              '/CfcCharity/api/private/application/step4/',
              applicationStep4
            );
          }
        };
      }
    ])
    .service('Step4Details', [
      'CubeSvc',
      function(CubeSvc) {
        return {
          createStep4Detail: function(detail) {
            return CubeSvc.post(
              '/CfcCharity/api/private/application/step4/details',
              detail
            );
          },
          updateStep4Detail: function(detail) {
            return CubeSvc.put(
              '/CfcCharity/api/private/application/step4/details/' +
                detail.applicationId +
                '/' +
                detail.id,
              detail
            );
          },
          deleteStep4Detail: function(applicationId, detailId) {
            return CubeSvc['delete'](
              '/CfcCharity/api/private/application/step4/details/' +
                applicationId +
                '/' +
                detailId
            );
          },
          getStep4Detail: function(applicationId, id) {
            return CubeSvc.get(
              '/CfcCharity/api/private/application/step4/details/get/' +
                applicationId +
                '/' +
                id
            );
          },
          queryStep4Details: function(applicationId) {
            return CubeSvc.get(
              '/CfcCharity/api/private/application/step4/details/' +
                applicationId
            );
          }
        };
      }
    ])
    .service('AOSDetails', [
      'CubeSvc',
      function(CubeSvc) {
        return {
          createOrUpdateAOSDetail: function(detail) {
            return CubeSvc.post(
              '/CfcCharity/api/private/charity/appeal/aos/detail',
              detail
            );
          },
          updateStep4Detail: function(detail) {
            return CubeSvc.put(
              '/CfcCharity/api/private/application/step4/details/' +
                detail.applicationId +
                '/' +
                detail.id,
              detail
            );
          },
          queryStep4Details: function(applicationId) {
            return CubeSvc.get(
              '/CfcCharity/api/private/application/step4/details/' +
                applicationId
            );
          }
        };
      }
    ]);
})();
