(function() {
  angular
    .module('CfcCharity.common')
    .factory('reviewLevelService', ReviewLevelFactory);

  ReviewLevelFactory.$inject = [
    '$q',
    '$http',
    'AdminReviewSectionStepDeterminationSessionService',
    'ApplicationDashboardService',
    'reviewLevelConstants',
    '$rootScope'
  ];

  function ReviewLevelFactory(
    $q,
    $http,
    AdminReviewSectionStepDeterminationSessionService,
    ApplicationDashboardService,
    reviewLevelConstants,
    $rootScope
  ) {
    return {
      getCurrentReviewLevel: getCurrentReviewLevel,
      currentReviewLevelOverrideMap: currentReviewLevelOverrideMap,
      displayStatus: displayStatus
    };

    function getCurrentReviewLevel(reviewLevel) {
      var currentReviewLevel = {
        applicationId: reviewLevel.reviewLevels.applicationId,
        step: 'Step' + reviewLevel.reviewLevels.stepNo,
        stepNo: '',
        reviewLevel: reviewLevel.currentLevel,
        displayText: '',
        status: '',
        setById: '',
        setByInitial: ''
      };

      currentReviewLevel = currentReviewLevelOverrideMap(currentReviewLevel);

      switch (currentReviewLevel.reviewLevel.toLowerCase()) {
        case 'Completeness'.toLowerCase():
          currentReviewLevel.displayText = 'Completeness';
          currentReviewLevel.status =
            reviewLevel.reviewLevels.completenessReviewStatus == null
              ? 'NotSet'
              : reviewLevel.reviewLevels.completenessReviewStatus;
          currentReviewLevel.reviewLevel = 'completenessReviewStatus';
          currentReviewLevel.setById =
            reviewLevel.reviewLevels.completenessSetBy;
          break;
        case 'REVIEW_1'.toLowerCase():
          if (
            reviewLevel.zoneCode !== 'DOD' &&
            (reviewLevel.charityType === 'NATIONAL' ||
              reviewLevel.charityType === 'INTERNATIONAL')
          ) {
            currentReviewLevel.displayText = 'Staff Eligibility';
            currentReviewLevel.status =
              reviewLevel.reviewLevels.staffReviewStatus == null
                ? 'NotSet'
                : reviewLevel.reviewLevels.staffReviewStatus;
            currentReviewLevel.reviewLevel = 'staffReviewStatus';
            currentReviewLevel.setById = reviewLevel.reviewLevels.staffSetBy;
          }
          break;
        case 'REVIEW_2'.toLowerCase():
          if (reviewLevel.zoneCode !== 'DOD') {
            currentReviewLevel.displayText = 'Preliminary Eligibility';
            currentReviewLevel.status =
              reviewLevel.reviewLevels.preliminaryReviewStatus == null
                ? 'NotSet'
                : reviewLevel.reviewLevels.preliminaryReviewStatus;
            (currentReviewLevel.reviewLevel = 'preliminaryReviewStatus'),
              (currentReviewLevel.setById =
                reviewLevel.reviewLevels.preliminarySetBy);
          }
          break;
        case 'REVIEW_3'.toLowerCase():
          currentReviewLevel.displayText = 'Final Eligibility';
          currentReviewLevel.status =
            reviewLevel.reviewLevels.finalReviewStatus == null
              ? 'NotSet'
              : reviewLevel.reviewLevels.finalReviewStatus;
          (currentReviewLevel.reviewLevel = 'finalReviewStatus'),
            (currentReviewLevel.setById = reviewLevel.reviewLevels.finalSetBy);
          break;
        case 'approved':
        case 'in_appeal':
        case 'denied':
          currentReviewLevel.displayText = 'Final Eligibility';
          currentReviewLevel.status =
            reviewLevel.reviewLevels.finalReviewStatus == null
              ? 'NotSet'
              : reviewLevel.reviewLevels.finalReviewStatus;
          break;
      }
      displayStatus(currentReviewLevel);
      return currentReviewLevel;
    }

    function currentReviewLevelOverrideMap(currentReviewLevel) {
      // Override the application's current review level with reviewer's manually selection.
      var sessionReviewLevel = AdminReviewSectionStepDeterminationSessionService.getCurrentReviewLevel();
      switch (sessionReviewLevel) {
        case reviewLevelConstants.Completeness:
          currentReviewLevel.reviewLevel = 'COMPLETENESS';
          break;
        case reviewLevelConstants.StaffEligibility:
          currentReviewLevel.reviewLevel = 'REVIEW_1';
          break;
        case reviewLevelConstants.PreliminaryEligibility:
          currentReviewLevel.reviewLevel = 'REVIEW_2';
          break;
        case reviewLevelConstants.FinalEligibility:
          currentReviewLevel.reviewLevel = 'REVIEW_3';
          break;
      }
      return currentReviewLevel;
    }

    function displayStatus(currentReviewLevel) {
      // if (!$scope.show) return;
      var step = currentReviewLevel.step.split('Step');
      currentReviewLevel.stepNo = step[1];
      if (currentReviewLevel.setById) {
        ApplicationDashboardService.getUserInfoById(
          currentReviewLevel.setById
        ).then(function(res) {
          var userInfo = res.data;
          if (
            userInfo &&
            userInfo.firstName.length > 0 &&
            userInfo.lastName.length > 0
          ) {
            currentReviewLevel.setByInitial =
              userInfo.firstName.charAt(0) + userInfo.lastName.charAt(0);
          }
          $rootScope.$broadcast('adminReviewLevelStatus', currentReviewLevel);
        });
      } else {
        $rootScope.$broadcast('adminReviewLevelStatus', currentReviewLevel);
      }
    }
  }
})();
