// printorderhistory.controller.js - Controller for the Admin Zone Listing Print Order History view

'use strict';

angular
  .module('CfcCharity.admin.zone.listing')
  .controller(
    'AdminZoneListingPrintorderHistoryCtl',
    AdminZoneListingPrintorderHistoryCtl
  );

AdminZoneListingPrintorderHistoryCtl.$inject = [
  '$scope',
  'BrowserEventService',
  'AdminZoneListingService',
  '$log',
  '$state',
  'parameterData',
  'DownloadService',
  'RedirectService'
];

function AdminZoneListingPrintorderHistoryCtl(
  $scope,
  BrowserEventService,
  AdminZoneListingService,
  $log,
  $state,
  parameterData,
  DownloadService,
  RedirectService
) {
  $scope.moduleName = 'CfcCharity.admin.zone.listing';
  $scope.zonePrintOrderHistoryBeanData = null;
  $scope.zone = null;
  $scope.pledgeYear = null;
  $scope.zoneUserRole = null;
  $scope.campaignId = null;
  $scope.showHistoryDetails = false;
  $scope.zoneRandomizationIdSelected = null;
  $scope.zoneRandomizationOrderBeanData = null;

  init();

  function init() {
    if (parameterData) {
      $scope.zone = parameterData.zone;
      $scope.pledgeYear = parameterData.pledgeYear;
      $scope.campaignId = parameterData.campaignId;
      $scope.zoneUserRole = parameterData.zoneUserRole;
    }

    if ($scope.zone && $scope.zone !== '' && $scope.pledgeYear) {
      BrowserEventService.setZone($scope.zone);
      BrowserEventService.setCampaignId($scope.campaignId);
      BrowserEventService.setZoneId($scope.zone.id);
      BrowserEventService.setPledgeYear($scope.pledgeYear);
      BrowserEventService.setZoneUserRole($scope.zoneUserRole);

      // Retrieve the print order history records to populate the table
      AdminZoneListingService.getRandomizationHistory(
        $scope.campaignId,
        $scope.zone.id
      )
        .then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.zonePrintOrderHistoryBeanData = result;
          }
        })
        .catch(function(err) {
          $log.error(
            'Error retrieving zone print order randomization history info in AdminZoneListingService.getRandomizationHistory'
          );
        });
    }
  }

  $scope.downloadRandomizationData = function(randomizationId) {
    var url =
      '/CfcCharity/api/private/admin/zone/randomizationDataReportDownloadById/' +
      $scope.zone.id +
      '/' +
      randomizationId;
    DownloadService.downloadFile(url);
  };

  $scope.populateHistoryDetails = function(zoneRandomizationId) {
    if ($scope.zoneRandomizationIdSelected === zoneRandomizationId) {
      // toggle showHistoryDetails if clicking the view button on the same item since nothing needs to be reloaded
      $scope.showHistoryDetails = !$scope.showHistoryDetails;
    } else {
      // Hide details grid while we retrieve the new data
      $scope.showHistoryDetails = false;

      // Retrieve the updated data and then show the grid again
      AdminZoneListingService.getRandomizationOrder(
        $scope.zone.id,
        zoneRandomizationId
      )
        .then(function(res) {
          var result = res.data;
          $scope.zoneRandomizationOrderBeanData = result;
          $scope.zoneRandomizationIdSelected = zoneRandomizationId;
          $scope.showHistoryDetails = true;
        })
        .catch(function(err) {
          $log.error(
            'Error retrieving zone print order randomization order info in AdminZoneListingService.getRandomizationOrder'
          );
        });
    }
  };

  $scope.gotoPrintorder = function() {
    BrowserEventService.setCampaignId($scope.campaignId);
    BrowserEventService.setZoneId($scope.zone.id);
    BrowserEventService.setPledgeYear($scope.pledgeYear);
    BrowserEventService.setZoneUserRole($scope.zoneUserRole);
    $state.go('admin-zone-listing-printorder', {
      zone: $scope.zone,
      campaignId: $scope.campaignId,
      pledgeYear: $scope.pledgeYear,
      zoneUserRole: $scope.zoneUserRole
    });
  };
}
