// system.service.js - Services for the Admin System module

'use strict';

angular
  .module('CfcCharity.admin.system')
  .factory('AdminSystemService', AdminSystemService);

AdminSystemService.$inject = ['$http', '$q'];

function AdminSystemService($http, $q) {
  var service = {
    getYearsAndZonesList: getYearsAndZonesList,
    isAllListingsRandomized: isAllListingsRandomized,
    isZoneListingsRandomized: isZoneListingsRandomized,
    getPledgeYears: getPledgeYears
  };
  return service;

  function getYearsAndZonesList() {
    var url = '/CfcCharity/api/private/admin/zone/yearsAndZonesAct';
    return $http.get(url);
  }

  function isAllListingsRandomized(campaignId) {
    if (campaignId) {
      var url =
        '/CfcCharity/api/private/admin/zone/isListingsRandomized/' + campaignId;
      return $http.post(url);
    } else {
      return $q.reject('required campaignId param missing');
    }
  }

  function isZoneListingsRandomized(campaignId, zoneId) {
    if (campaignId && zoneId) {
      var url =
        '/CfcCharity/api/private/admin/zone/isListingsRandomized/' +
        campaignId +
        '/' +
        zoneId;
      return $http.post(url);
    } else {
      if (campaignId && !zoneId) {
        return $q.reject('required parameter campaignId is missing');
      }
      if (!campaignId && zoneId) {
        return $q.reject('required parameter zoneId is missing');
      }
      if (!campaignId && !zoneId) {
        return $q.reject(
          'both required parameters campaignId and zoneId are missing'
        );
      }
    }
  }

  function getPledgeYears() {
    var url = '/CfcCharity/api/private/application/pledgeReportsYears';
    return $http.get(url);
  }
}
