(function() {
  var moduleName = 'CfcCharity.directives.breadcrumbs';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, ['ui.router']);
  }
  module.service('BreadcrumbTokenDataService', [
    'CubeSvc',
    'AdminReviewSectionStepDeterminationSessionService',
    'reviewLevelConstants',
    'ZoneCenterService',
    'FederationApplicationReviewService',
    'BrowserEventService',
    function(
      CubeSvc,
      AdminReviewSectionStepDeterminationSessionService,
      reviewLevelConstants,
      ZoneCenterService,
      FederationApplicationReviewService,
      BrowserEventService
    ) {
      var key = '2279a1e2-97dc-4e26-9500-d9f55044a4d7';
      var review = null;
      var isFederation = null;
      var appType = null;
      var setTokenData = function(data) {
        var patchedData = jQuery.extend({}, getTokenData(), data);
        var json = JSON.stringify(patchedData);
        sessionStorage.setItem(key, json);
      };
      var getTokenData = function() {
        var json = {};
        var data = sessionStorage.getItem(key);
        if (data != null) {
          try {
            json = JSON.parse(data);
          } catch (ex) {}
        }
        return json;
      };
      var tokenize = function(token) {
        var data = getTokenData();
        return token.replace(/%(\w*)%/g, function(m, key) {
          return data.hasOwnProperty(key) ? data[key] : '-1';
        });
      };
      var clearTokenData = function() {
        sessionStorage.removeItem(key);
      };
      var setApplicationReviewTokenData = function(applicationId) {
        var data = {
          cfcCode: '',
          pledgeYear: '',
          zoneCode: '',
          zoneId: '',
          userId: '',
          firstTimeUser: false,
          applicationId: applicationId,
          charityName: '',
          federationId: '',
          federationCfc: '',
          appType: ''
        };

        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        data.userId = userObj.id;

        return CubeSvc.get(
          '/CfcCharity/api/private/application/details/' + data.applicationId,
          null,
          false
        ).then(function(res) {
          var response = res.data.value;
          data.cfcCode = response.cfcCode;
          data.pledgeYear = response.pledgeYear;
          data.charityName = response.name;
          data.federationId = response.federationNumber;
          data.federationCfc = response.federationCfcCode;
          data.appType = data.federationId != null ? 'Member' : 'Independent';
          appType = data.appType;
          if (response.canAccess) {
            return CubeSvc.get(
              '/CfcCharity/api/private/application/getZoneDetails?applicationId=' +
                data.applicationId,
              null,
              false
            ).then(function(res) {
              data.zoneId = res.data.zoneId;
              data.zoneCode = res.data.zoneCode;
              setTokenData(data);
              return data;
            });
          }
        });
      };
      var setFederationApplicationReviewTokenData = function(applicationId) {
        if (!isAuthorized()) {
          return;
        }

        var data = {
          cfcCode: '',
          pledgeYear: '',
          zoneCode: '',
          zoneId: '',
          userId: '',
          firstTimeUser: false,
          applicationId: applicationId,
          charityName: '',
          federationId: '',
          federationCfc: '',
          appType: ''
        };

        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        data.userId = userObj.id;

        return CubeSvc.get(
          '/CfcCharity/api/private/federation/' + data.applicationId,
          null,
          false
        ).then(function(res) {
          var resValue = res.data.value;
          data.pledgeYear = resValue.pledgeYear;
          data.zoneId = resValue.federationZone.zoneId;
          data.cfcCode = resValue.cfcCode;
          data.federationCfc = resValue.cfcCode;
          data.federationId = applicationId;
          data.charityName = resValue.federationName;
          data.zoneCode = resValue.federationZone.zoneCode;
          data.appType = 'Federation';
          appType = data.appType;
          BrowserEventService.setZoneCode(data.zoneCode);
          setTokenData(data);
          return data;
        });
      };
      var setIsFederation = function(isFed) {
        isFederation = isFed;
      };
      var getIsFederation = function() {
        return isFederation;
      };
      var getReviewLevelZonesCrumb = function(params) {
        var crumb = {
          crumbName: tokenize('%zoneCode%'),
          crumbRoute: '',
          crumbParams: tokenize(params)
        };
        if (review != null) {
          var currentReviewLevel = review;
          switch (currentReviewLevel) {
            case 'REVIEW_1':
              crumb.crumbName += ' Staff Eligibility';
              crumb.crumbRoute = 'zonereviewlevel1';
              return crumb;
              break;
            case 'REVIEW_2':
              crumb.crumbName += ' Preliminary Eligibility';
              crumb.crumbRoute = 'zonereviewlevel2';
              return crumb;
              break;
            case 'REVIEW_3':
            case 'DENIED':
            case 'APPROVED':
            case 'IN_APPEAL':
              crumb.crumbName += ' Final Eligibility';
              crumb.crumbRoute = 'zonereviewlevel3';
              return crumb;
              break;
            case 'COMPLETENESS':
            default:
              crumb.crumbName += ' Completeness';
              crumb.crumbRoute = 'zonecompletenessreview';
              return crumb;
              break;
          }
        }
      };

      var getReviewLevel = function(appId, isFed) {
        if (isFed) {
          return FederationApplicationReviewService.getFederationReviewDetails(
            appId
          ).then(function(res) {
            var fedAppReviewResponse = res.data;
            review =
              fedAppReviewResponse.federationApplicationReviewBean.currentLevel;
          });
        } else {
          return ZoneCenterService.findApplicationReview(appId).then(function(
            res
          ) {
            var reviewL = res.data.value;
            if (reviewL !== null && reviewL !== '') {
              review = reviewL.currentLevel;
            }
          });
        }
      };

      var getReviewLevelStepsCrumb = function(params, isFed) {
        var crumb = {
          crumbName: '',
          crumbRoute: '',
          crumbParams: tokenize(params)
        };
        var reviewLevelSessionGuid = AdminReviewSectionStepDeterminationSessionService.getCurrentReviewLevel();
        switch (reviewLevelSessionGuid) {
          case reviewLevelConstants.StaffEligibility:
            crumb.crumbName += 'Staff Eligibility';
            crumb.crumbRoute = isFed
              ? 'federationStaffEligibilityReview'
              : 'staffEligibility';
            return crumb;
            break;
          case reviewLevelConstants.PreliminaryEligibility:
            crumb.crumbName += 'Preliminary Eligibility';
            crumb.crumbRoute = isFed
              ? 'federationPreliminaryEligibilityReview'
              : 'preliminaryEligibility';
            return crumb;
            break;
          case reviewLevelConstants.FinalEligibility:
            crumb.crumbName += 'Final Eligibility';
            crumb.crumbRoute = isFed
              ? 'federationFinalEligibilityReview'
              : 'finalEligibility';
            return crumb;
            break;
          case reviewLevelConstants.Completeness:
          default:
            crumb.crumbName += 'Completeness';
            crumb.crumbRoute = isFed
              ? 'federationCompletenessReview'
              : 'reviewCompleteness';
            return crumb;
            break;
        }
      };
      var isAuthorized = function() {
        return true;
      };

      return {
        tokenize: tokenize,
        setTokenData: setTokenData,
        clearTokenData: clearTokenData,
        setApplicationReviewTokenData: setApplicationReviewTokenData,
        setFederationApplicationReviewTokenData: setFederationApplicationReviewTokenData,
        getReviewLevelZonesCrumb: getReviewLevelZonesCrumb,
        getReviewLevelStepsCrumb: getReviewLevelStepsCrumb,
        setIsFederation: setIsFederation,
        getIsFederation: getIsFederation,
        isAuthorized: isAuthorized,
        getReviewLevel: getReviewLevel,
        appType
      };
    }
  ]);
  module.directive('cfcBreadcrumbs', [
    '$rootScope',
    '$state',
    'BreadcrumbTokenDataService',
    function($rootScope, $state, BreadcrumbTokenDataService) {
      return {
        restrict: 'E',
        template:
          '<div class="row"><div class="col-md-12 noprint"><ol class="breadcrumb" data-ng-show="breadcrumbs.length">\
                    <li data-ng-repeat="crumb in breadcrumbs" data-ng-class="{ active: $last }">\
                    <span data-ng-if="!crumb.crumbRoute">{{ crumb.crumbName }}&nbsp;</span>\
                    <a data-ng-if="!!crumb.crumbRoute && !crumb.crumbParams" ui-sref="{{ crumb.crumbRoute }}">{{ crumb.crumbName }}&nbsp;</a>\
                    <a data-ng-if="!!crumb.crumbRoute && !!crumb.crumbParams" ui-sref="{{ crumb.crumbRoute }}({{ crumb.crumbParams }})">{{ crumb.crumbName }}&nbsp;</a>\
                    </li>\
                </ol></div></div>',
        scope: {},
        controller: function($scope, $timeout, ApplicationService) {
          $rootScope.$on('$stateChangeSuccess', function(
            event,
            toState,
            toParams,
            fromState,
            fromParams
          ) {
            $scope.breadcrumbs = [];
            var breadcrumbs;
            if (typeof $state.current.data.breadcrumbs === 'function') {
              if (
                toParams.charityDashboardCfcCode !== undefined &&
                toParams.charityDashboardCfcCode !== null
              ) {
                breadcrumbs = $state.current.data.breadcrumbs(toParams.appType);
              } else if (
                toParams.sourcePageName === 'federationapplicationreview' ||
                toParams.sourcePageName === 'charityAuditDashboard' ||
                toParams.isFederation
              ) {
                breadcrumbs = $state.current.data.breadcrumbs(
                  ApplicationService,
                  toParams.isFederation,
                  toParams.applicationType
                );
              } else {
                breadcrumbs = $state.current.data.breadcrumbs(
                  ApplicationService
                );
              }
            } else {
              breadcrumbs = $state.current.data.breadcrumbs;
            }
            if (!breadcrumbs) {
              return;
            }
            if (!BreadcrumbTokenDataService.isAuthorized()) {
              return;
            }
            breadcrumbs.forEach(function(item, index, breadcrumbAr) {
              if (item.crumbName === 'reviewLevel') {
                // create review level zones crumb
                var reviewLevelCrumb = BreadcrumbTokenDataService.getReviewLevelZonesCrumb(
                  item.crumbParams
                );
                if (reviewLevelCrumb && !angular.equals(reviewLevelCrumb, {})) {
                  $scope.breadcrumbs.push(reviewLevelCrumb);
                }
              } else if (item.crumbName === 'reviewLevelSteps') {
                // charity apps
                var reviewLevelStepsCrumb = BreadcrumbTokenDataService.getReviewLevelStepsCrumb(
                  item.crumbParams,
                  false
                );
                if (
                  reviewLevelStepsCrumb &&
                  !angular.equals(reviewLevelStepsCrumb, {})
                ) {
                  $scope.breadcrumbs.push(reviewLevelStepsCrumb);
                }
              } else if (item.crumbName === 'reviewLevelStepsFed') {
                // federation apps
                var reviewLevelStepsCrumb = BreadcrumbTokenDataService.getReviewLevelStepsCrumb(
                  item.crumbParams,
                  true
                );
                if (
                  reviewLevelStepsCrumb &&
                  !angular.equals(reviewLevelStepsCrumb, {})
                ) {
                  $scope.breadcrumbs.push(reviewLevelStepsCrumb);
                }
              } else {
                if (item.crumbRoute === 'fedOrCharityReviewDashboard') {
                  var params = BreadcrumbTokenDataService.tokenize(
                    item.crumbParams
                  );
                  if (params.indexOf('-1') !== -1) return;
                  var crumbName = BreadcrumbTokenDataService.tokenize(
                    item.crumbName
                  );
                  if (crumbName.indexOf('-1') !== -1) return;
                  // isFed?
                  var rt = BreadcrumbTokenDataService.getIsFederation()
                    ? 'federationapplicationreview'
                    : 'charityAuditDashboard';
                  var opt = params;
                  if (BreadcrumbTokenDataService.getIsFederation()) {
                    opt = params.replace(
                      'applicationId',
                      'federationApplicationId'
                    );
                  }
                  $scope.breadcrumbs.push({
                    crumbName: crumbName,
                    crumbRoute: rt,
                    crumbParams: opt
                  });
                } else if (item.crumbRoute === 'applicationDashboard') {
                  var params = BreadcrumbTokenDataService.tokenize(
                    item.crumbParams
                  );
                  if (params.indexOf('-1') !== -1) return;
                  var crumbName = BreadcrumbTokenDataService.tokenize(
                    item.crumbName
                  );
                  if (crumbName.indexOf('-1') !== -1) return;
                  // isFed?
                  var rt = BreadcrumbTokenDataService.getIsFederation()
                    ? 'federationDashboard'
                    : 'applicationDashboard';
                  var opt = params;
                  if (BreadcrumbTokenDataService.getIsFederation()) {
                    opt = params.replace('applicationId', 'federationId');
                  }
                  $scope.breadcrumbs.push({
                    crumbName: crumbName,
                    crumbRoute: rt,
                    crumbParams: opt
                  });
                } else if (
                  item.crumbRoute === 'fedOrCharityCompletenessReview'
                ) {
                  var params = BreadcrumbTokenDataService.tokenize(
                    item.crumbParams
                  );
                  if (params.indexOf('-1') !== -1) return;
                  var crumbName = BreadcrumbTokenDataService.tokenize(
                    item.crumbName
                  );
                  if (crumbName.indexOf('-1') !== -1) return;
                  // isFed?
                  var rt = BreadcrumbTokenDataService.getIsFederation()
                    ? 'federationCompletenessReview'
                    : 'reviewCompleteness';
                  var opt = params;
                  if (BreadcrumbTokenDataService.getIsFederation()) {
                    opt = params.replace(
                      'applicationId',
                      'federationApplicationId'
                    );
                  }
                  $scope.breadcrumbs.push({
                    crumbName: crumbName,
                    crumbRoute: rt,
                    crumbParams: opt
                  });
                } else {
                  var params = BreadcrumbTokenDataService.tokenize(
                    item.crumbParams
                  );
                  if (params.indexOf('-1') !== -1) return;
                  var crumbName = BreadcrumbTokenDataService.tokenize(
                    item.crumbName
                  );
                  if (crumbName.indexOf('-1') !== -1) return;
                  $scope.breadcrumbs.push({
                    crumbName: crumbName,
                    crumbRoute: item.crumbRoute,
                    crumbParams: params
                  });
                }
              }
              if (index === breadcrumbAr.length - 1) {
                if (null !== BreadcrumbTokenDataService.getIsFederation()) {
                  BreadcrumbTokenDataService.setIsFederation(null);
                }
              }
            });
          });
        }
      };
    }
  ]);
})();
