/*
 * donations.controller.js - Controller declaration for Donations page (and summary in service)
 *
 * warning: there is specific authorization logic per pledgeYear/campaign and CFC
 * this is due to the change in fed/member/ind between years and individual access
 */

'use strict';

(function() {
  angular
    .module('CfcCharity.donations')
    .constant('MonthDisplayByNumber', {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
      13: 'Final Distributions'
    })
    .filter('displayMonthNameFromNumber', [
      'MonthDisplayByNumber',
      function(MonthDisplayByNumber) {
        return function(input) {
          return MonthDisplayByNumber[input] || '';
        };
      }
    ])
    .controller('DonationsCtl', DonationsCtl);

  DonationsCtl.$inject = [
    '$state',
    '$q',
    '$log',
    '$scope',
    'blockUIConfig',
    'blockUI',
    'BrowserEventService',
    'DonationsService',
    'CharityDashboardService',
    'CharityService',
    'ApplicationDashboardService',
    'parameterData',
    'PledgeService',
    'CubeSvc'
  ];

  // controller for Donations page
  function DonationsCtl(
    $state,
    $q,
    $log,
    $scope,
    blockUIConfig,
    blockUI,
    BrowserEventService,
    DonationsService,
    CharityDashboardService,
    CharityService,
    ApplicationDashboardService,
    parameterData,
    PledgeService,
    CubeSvc
  ) {
    blockUIConfig.autoBlock = false;

    var vm = this;

    vm.moduleName = 'CfcCharity.donations';

    vm.cfcCode = $state.params.cfcCode;
    vm.charityName = $state.params.charityName.replace("'", '');
    vm.appType = $state.params.appType;
    vm.isFederation = vm.appType.toUpperCase() === 'FEDERATION';
    vm.isMember = vm.appType.toUpperCase() === 'MEMBER';
    vm.pledgeYear = $state.params.pledgeYear;
    vm.campaignId = null;
    vm.cannotSeeFedDetails = false;
    if (vm.isMember) {
      // add parentFed info for this year
      ApplicationDashboardService.getApplicationByPledgeYearAndCFC(
        vm.pledgeYear,
        vm.cfcCode
      )
        .then(function(res) {
          // get appId
          var appId = res.data.id;
          return appId;
        })
        .then(function(appId) {
          ApplicationDashboardService.applicationDetailsById(appId)
            .then(function(resp) {
              var appData = resp.data.value;
              vm.federationInfo = {
                cfc: appData.federationCfcCode,
                name: appData.federationName
              };

              computeCanMemberSeeFedMemberOverview()
                .then(function(yn) {
                  vm.showFedMemberPages = yn;
                })
                .catch(function(computeFedMemberVisErr) {
                  $log.error(computeFedMemberVisErr);
                  vm.showFedMemberPages = false;
                });
            })
            .catch(function(appByIdErr) {
              $log.error(appByIdErr);
            });
        })
        .catch(function(charityErr) {
          $log.error(charityErr);
        });
    }

    // whether the user, on member page, should see
    function computeCanMemberSeeFedMemberOverview() {
      return $q(function(resolve, reject) {
        var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        if (
          userOb.hasOwnProperty('secondaryRoleList') &&
          Array.isArray(userOb.secondaryRoleList) &&
          userSystemRoleIncludes([
            'OPM_ADMIN',
            'OPM_STAFF',
            'OPM_AUDITOR',
            'CUSTOMER_SERVICE_TIER_1',
            'CUSTOMER_SERVICE_TIER_2_FINANCE',
            'CUSTOMER_SERVICE_MANAGER'
          ])
        ) {
          resolve(true);
        } else {
          getUserRoleForCharity()
            .then(function(data) {
              return (data || '').toUpperCase();
            })
            .then(function(role) {
              if (
                role === 'CHARITY_OWNER' ||
                role === 'OWNER' ||
                role === 'CHARITY_EDITOR' ||
                role === 'EDITOR'
              ) {
                resolve(true);
                isUserFedOwnerEditorOfMember()
                  .then(function(isFedRole) {
                    if (!isFedRole) {
                      vm.cannotSeeFedDetails = true;
                    }
                  })
                  .catch(function(er) {
                    $log.error(er);
                    reject(false);
                  });
              } else {
                isUserFedOwnerEditorOfMember()
                  .then(function(isFedRole) {
                    resolve(isFedRole);
                  })
                  .catch(function(er) {
                    $log.error(er);
                    vm.cannotSeeFedDetails = true;
                    reject(false);
                  });
              }
            })
            .catch(function(err) {
              $log.error(err);
              vm.cannotSeeFedDetails = true;
              reject(false);
            });
        }
      });
    }

    // builds out years to check for fed. role authorization purposes
    function buildYearsToCheckForFederationmemberAccess() {
      var yearsToCheck = [];
      angular.forEach(vm.rawAvailableYears, function(yr) {
        var prom = CharityDashboardService.getCharityFedMemberAccessForYear(
          yr.pledgeYear,
          vm.cfcCode
        )
          .then(function(res) {
            var yn = JSON.parse(res.data);
            if (true === yn) {
              vm.isCharityMemberOfFedWithUserAccessForAnyYear = true;
            }
          })
          .catch(function(err) {
            $log.error(err);
            // nothing really, just default to false
          });
        yearsToCheck.push(prom);
      });
      return $q.all(yearsToCheck);
    }

    // checks if user is a fed role with access to this member
    function isUserFedOwnerEditorOfMember() {
      return $q(function(resolve, reject) {
        if (vm.isMember) {
          vm.isCharityMemberOfFedWithUserAccessForAnyYear = false;
          buildYearsToCheckForFederationmemberAccess()
            .then(function() {
              // check that at least one year is good, overall visibility
              resolve(vm.isCharityMemberOfFedWithUserAccessForAnyYear);
            })
            .catch(function(err) {
              reject(false);
            });
        } else {
          resolve(false);
        }
      });
    }

    // matches against array of values to match for secondaryRoleList
    function userSystemRoleIncludes(roleAr) {
      var yn = false;
      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      if (
        userOb.hasOwnProperty('secondaryRoleList') &&
        Array.isArray(userOb.secondaryRoleList)
      ) {
        angular.forEach(roleAr, function(val) {
          if (userOb.secondaryRoleList.includes(val)) {
            yn = true;
          }
        });
      }
      return yn;
    }

    // the additional, non-system role user check for a CFC
    function getUserRoleForCharity() {
      return $q(function(resolve, reject) {
        if (null == vm.userRole) {
          CharityService.getCharityUserRole(vm.cfcCode)
            .then(function(res) {
              vm.userRole = res.data.userRole;
              resolve(vm.userRole);
            })
            .catch(function(err) {
              reject(err);
            });
        } else {
          resolve(vm.userRole);
        }
      });
    }

    // since it's more advanced than just passing in roles to the state definition
    function performAuthorizationChecks() {
      return $q(function(resolve, reject) {
        // get user information handle
        var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        if (
          userOb.hasOwnProperty('secondaryRoleList') &&
          Array.isArray(userOb.secondaryRoleList) &&
          userSystemRoleIncludes([
            'OPM_ADMIN',
            'OPM_STAFF',
            'OPM_AUDITOR',
            'CUSTOMER_SERVICE_TIER_1',
            'CUSTOMER_SERVICE_TIER_2_FINANCE',
            'CUSTOMER_SERVICE_MANAGER',
            'GB_FINANCE',
            'GB_FINANCE_ADMIN'
          ])
        ) {
          resolve(true);
        } else {
          getUserRoleForCharity()
            .then(function(data) {
              return (data || '').toUpperCase();
            })
            .then(function(role) {
              if (
                role === 'CHARITY_OWNER' ||
                role === 'OWNER' ||
                role === 'CHARITY_EDITOR' ||
                role === 'EDITOR'
              ) {
                resolve(true);
              } else {
                // isFedOwnerEditor
                isUserFedOwnerEditorOfMember()
                  .then(function(yn) {
                    resolve(yn);
                  })
                  .catch(function(err) {
                    $log.error(err);
                    reject(false);
                  });
              }
            })
            .catch(function(err) {
              $log.error(err);
              reject(false);
            });
        }
      });
    }

    // uses the loaded listing year information to get campaignId from pledgeYear
    function getCampaignIdFromPledgeYear(pledgeYear) {
      var campaignId = null;
      angular.forEach(vm.rawAvailableYears, function(yrOb, pos) {
        if (pledgeYear === yrOb.pledgeYear) {
          campaignId = yrOb.pledgeYearId;
        }
      });
      if (null == vm.campaignId) {
        vm.campaignId = campaignId;
      }
      return campaignId;
    }

    // builds out disbursement by-month values
    function buildMonthlyDisbursementValueArray(data) {
      // build array of months for campaign year and matching values
      var currentCampaignYear = vm.pledgeYear;
      var tmpYrDt = new Date(currentCampaignYear, 1, 1);
      var nextCampaignYear = tmpYrDt.getFullYear() + 1;
      var nextNextCampaignYear = tmpYrDt.getFullYear() + 2;
      var disburseMonthAr = [
        { month: 4, year: nextCampaignYear, disbursement: null },
        { month: 5, year: nextCampaignYear, disbursement: null },
        { month: 6, year: nextCampaignYear, disbursement: null },
        { month: 7, year: nextCampaignYear, disbursement: null },
        { month: 8, year: nextCampaignYear, disbursement: null },
        { month: 9, year: nextCampaignYear, disbursement: null },
        { month: 10, year: nextCampaignYear, disbursement: null },
        { month: 11, year: nextCampaignYear, disbursement: null },
        { month: 12, year: nextCampaignYear, disbursement: null },
        { month: 1, year: nextNextCampaignYear, disbursement: null },
        { month: 2, year: nextNextCampaignYear, disbursement: null },
        { month: 3, year: nextNextCampaignYear, disbursement: null },
        { month: 13, year: nextNextCampaignYear, disbursement: null }
      ];

      var otherAmount = 0;
      angular.forEach(data, function(ob) {
        // 1. find match
        var matchMonth = disburseMonthAr.find(function(el) {
          return el.month === ob.month && el.year === ob.pledgeYear;
        });
        // 2. give values
        if (undefined !== matchMonth) {
          // checks for having disbursement value
          var hasDisbursement =
            !Number.isNaN(ob['disbursement']) && ob['disbursement'] > 0;
          matchMonth.hasDisbursement = hasDisbursement;
          if (hasDisbursement) {
            matchMonth['disbursement'] = ob['disbursement'];
          }
        } else {
          //if disbursements made after march next to next year then add all those to other disbursements
          if (ob['month'] > 3 && ob['pledgeYear'] == nextNextCampaignYear) {
            otherAmount += ob['disbursement'];
            var matchMonth = disburseMonthAr.find(function(el) {
              return el.month === 13 && el.year === nextNextCampaignYear;
            });
            if (undefined !== matchMonth) {
              matchMonth['month'] = 13;
              matchMonth['year'] = currentCampaignYear;
              matchMonth['disbursement'] = otherAmount;
              matchMonth.hasDisbursement = true;
            }
          }
        }
      });

      vm.disbursementsByMonth = disburseMonthAr;
      vm.disbursementsLoadingStatus = 'SUCCESS';
    }

    function loadDonationAndDisbursementData() {
      var url =
        '/CfcCharity/api/private/charity/application/donation/summary/' +
        getCampaignIdFromPledgeYear(vm.pledgeYear) +
        '/' +
        vm.cfcCode;
      CubeSvc.get(url)
        .then(function(res) {
          vm.donationsData = res.data.value;
          vm.donationsLoadingStatus = 'SUCCESS';
          buildMonthlyDisbursementValueArray(
            vm.donationsData.monthlyDistributions
          );
        })
        .catch(function(err) {
          $log.error(err);
          vm.charityPledgeDataLoadingStatus = 'ERROR';
          vm.charityPledgeDataLoadingError = err;
        });
      if (!vm.isFederation) {
        vm.showMemberOverviewButton = false;
      } else {
        vm.showMemberOverviewButton = true;
      }
    }

    // nav to parent fed charity dashboard
    vm.goToParentFederationCharityDashboard = function() {
      blockUIConfig.autoBlock = true;
      $state.go('charitydashboard', {
        charityDashboardCfcCode: vm.federationInfo.cfc
      });
    };

    // nav to all members of a fed overview of donations
    vm.goToAllMembersOverview = function() {
      blockUIConfig.autoBlock = true;
      $state.go('memberDonations', {
        parentCfcCode: vm.federationInfo.cfc,
        campaignId: vm.campaignId,
        pledgeYear: vm.pledgeYear,
        parentFedName: vm.federationInfo.name
      });
    };

    vm.navigateToMemberOverview = function() {
      var opts = {
        parentCfcCode: angular.copy(vm.cfcCode),
        campaignId: angular.copy(vm.campaignId),
        pledgeYear: angular.copy(vm.pledgeYear),
        parentFedName: angular.copy(vm.charityName)
      };
      $state.go('memberDonations', opts);
    };

    // available year computation, invoked early init, to be available for performAuth checks
    function computeAvailableYears() {
      return $q(function(res, rej) {
        var promAr = [];

        // get available years, current year already known
        CharityDashboardService.getCharityDashboardData(vm.cfcCode)
          .then(function(resp) {
            // makes getting campaignId easier
            vm.rawAvailableYears = resp.data.value.charityDashboardListingList;

            // loads a simple array of values for the available year picker
            angular.forEach(vm.rawAvailableYears, function(ob) {
              // compute whether user should be allowed a given year
              if (
                userSystemRoleIncludes([
                  'OPM_ADMIN',
                  'OPM_STAFF',
                  'OPM_AUDITOR'
                ])
              ) {
                vm.availableYears.push(ob.pledgeYear);
                promAr.push($q.resolve(ob.pledgeYear));
              } else {
                // check if donation data available date has been reached?
                var prom = DonationsService.isDisplayDonorDataDateForCampaignId(
                  ob.pledgeYearId,
                  vm.cfcCode
                )
                  .then(function(res) {
                    return JSON.parse(res.data);
                  })
                  .then(function(yn) {
                    if (yn) {
                      vm.availableYears.push(ob.pledgeYear);
                    }
                  })
                  .catch(function(yrCheckErr) {
                    $log.error(yrCheckErr);
                  });
                promAr.push(prom);
              }
            });
          })
          .catch(function(charityDashErr) {
            $log.error(charityDashErr);
          })
          .finally(function() {
            return $q
              .all(promAr)
              .then(function(data) {
                res(data);
              })
              .catch(function(outerErr) {
                $log.error(outerErr);
                rej(outerErr);
              });
          });
      });
    }

    // perform init sequence
    function init() {
      // init vars other than state param driven values
      vm.donationsLoadingStatus = 'LOADING';
      vm.donationsData = {};
      vm.donationsErrorMessage = null;
      vm.disbursementsLoadingStatus = 'LOADING';
      vm.disbursementsByMonth = [];
      vm.bankWarningInfo = null; // TODO: get bank warning (depends CCA-1791: future sprint)
      vm.userRole = null;
      vm.rawAvailableYears = [];
      vm.availableYears = [];
      vm.charityPledgeDataLoadingStatus = 'LOADING';

      computeAvailableYears()
        .then(function(data) {
          performAuthorizationChecks()
            .then(function(yn) {
              if (yn) {
                loadDonationAndDisbursementData(); // load other two, possibly member data, dependent on campaign ID data
              } else {
                $state.go(
                  parameterData.previousPage.name,
                  parameterData.previousPage.params
                );
              }
            })
            .catch(function(er) {
              $log.error(er);
            });
        })
        .catch(function(computeYearsErr) {
          $log.error(computeYearsErr);
        })
        .finally(function() {
          // ensure tooltips
          angular.element('[data-toggle="tooltip"]').tooltip();
        });

      // re-enable block UI when leaving this page
      $scope.$on('$destroy', function() {
        // it has to be on $scope, since we can hook into $destroy; otherwise wouldn't need or inject $scope
        blockUIConfig.autoBlock = true;
      });
    }

    init();
  }
})();
