angular
  .module('CfcCharity.editsystemuser', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('editsystemuser', {
        url: '/editsystemuser',
        views: {
          main: {
            template: require('../views/private/admin/editsystemuser.html'),
            controller: 'EditSystemUserCtrl'
          }
        },
        data: {
          pageTitle: 'Edit A System User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor
          ]
        },
        params: {
          userId: null,
          invitedUserId: null
        },
        resolve: {
          editSystemUserData: function($http, $stateParams) {
            if (
              $stateParams.userId != null &&
              $stateParams.invitedUserId != null
            ) {
              var userId = $stateParams.userId;
              var invitedUserId = $stateParams.invitedUserId;
              var url =
                '/CfcCharity/api/private/admin/system/user/' +
                userId +
                '/' +
                invitedUserId;
              return $http.get(url).then(function(res) {
                return res.data;
              }); // end function
            } // end if
          } // end function
        } // end resolve
      }); // end state editsystemuser

      $stateProvider.state('updatesystemuser', {
        url: '/updatesystemuser',
        views: {
          main: {
            template: require('../views/private/admin/systemusers.html'),
            controller: 'ActiveSystemUsersCtrl'
          }
        },
        data: {
          pageTitle: 'Active System Users',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          systemUserAdminData: null
        },
        resolve: {
          systemUserAdminData: function($http, $stateParams) {
            if ($stateParams.systemUserAdminData != null) {
              return $stateParams.systemUserAdminData;
            } // end if
          } // end function
        } // end resolve
      }); // end state updatesystemuser
    } // end function myApp
  ])
  .service('UpdateSystemUserService', [
    '$http',
    function($http) {
      this.updateSystemUser = function(updateSystemUserData) {
        var updateSystemUserURI =
          '/CfcCharity/api/private/admin/system/user/update';
        return $http.post(updateSystemUserURI, updateSystemUserData);
      };
    }
  ])
  .controller('EditSystemUserCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'editSystemUserData',
    'UpdateSystemUserService',
    'BrowserEventService',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      editSystemUserData,
      UpdateSystemUserService,
      BrowserEventService,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.editsystemuser');
      $scope.editSystemUserData = editSystemUserData;

      // Validate and update a system user
      $scope.updateSystemUser = function() {
        if (
          $scope.editSystemUserData === null ||
          $scope.editSystemUserData.editSystemUserBean === undefined ||
          $scope.editSystemUserData.editSystemUserBean.role === null ||
          $scope.editSystemUserData.editSystemUserBean.role === undefined ||
          $scope.editSystemUserData.editSystemUserBean.role === 'Select a Role'
        ) {
          $scope.showUpdateSystemUserErrorMessage('Please select a role');
          return;
        }

        if (
          $scope.editSystemUserData !== null &&
          $scope.editSystemUserData.editSystemUserBean !== undefined &&
          $scope.editSystemUserData.editSystemUserBean.role !== null &&
          $scope.editSystemUserData.editSystemUserBean.role !== undefined &&
          $scope.editSystemUserData.editSystemUserBean.role !==
            'Select a Role' &&
          $scope.editSystemUserData.editSystemUserBean.role ===
            $scope.editSystemUserData.editSystemUserBean.existingRole
        ) {
          $scope.showUpdateSystemUserErrorMessage(
            'The user currently has this role ' +
              "'" +
              $scope.editSystemUserData.editSystemUserBean.existingRole +
              "'" +
              '. If you want to change the existing role, Please select a different role.'
          );
          return;
        }

        $scope.showUpdateSystemUserErrorMessage('');

        // Submit the data to the server side for the Update System User
        // To do : validate email
        var updateSystemUserData = $scope.editSystemUserData.editSystemUserBean;

        updateSystemUserData.role =
          $scope.editSystemUserData.editSystemUserBean.role;

        UpdateSystemUserService.updateSystemUser(updateSystemUserData)
          .then(function(res) {
            var data = res.data;
            var updateSystemUserResponse = data;

            if (
              updateSystemUserResponse.error ||
              updateSystemUserResponse.statusCode === 'ERROR'
            ) {
              $scope.showUpdateSystemUserErrorMessage(
                updateSystemUserResponse.errorResponse.errorCode +
                  ' : ' +
                  updateSystemUserResponse.errorResponse.errorMessage +
                  '. Please try again later!'
              );
              return;
            }

            // if no errors - display active system users list

            var systemUserAdminData =
              updateSystemUserResponse.systemUserAdminBean;
            $state.go('updatesystemuser', {
              systemUserAdminData: systemUserAdminData
            });
          })
          .catch(function(err) {
            $scope.showUpdateSystemUserErrorMessage(
              'Error while updating a system user. Please try again later'
            );
          });
      };

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      $scope.showUpdateSystemUserErrorMessage = function(error) {
        $scope.updateSystemUserErrors = error;
      };
    } // end function
  ]);
