angular
  .module('CfcCharity.addsystemuser', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('addsystemuser', {
        url: '/addsystemuser',
        views: {
          main: {
            template: require('../views/private/admin/addsystemuser.html'),
            controller: 'AddSystemUserCtrl'
          }
        },
        data: {
          pageTitle: 'Add A System User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff
          ]
        },
        params: {
          userId: null
        },
        resolve: {
          inviteRoleListData: function($http, $stateParams) {
            if ($stateParams.userId != null) {
              var userId = $stateParams.userId;
              var url =
                '/CfcCharity/api/private/admin/system/users/inviteroles/' +
                userId;
              return $http.get(url).then(function(res) {
                roleListData = {
                  roles: res.data
                };
                return roleListData;
              }); // end function
            } // end if $stateParams.userId not null
          } // end function
        } // end resolve
      }); // end state addsystemuser
      $stateProvider.state('addsystemusersuccess', {
        url: '/addsystemusersuccess',
        views: {
          main: {
            template: require('../views/private/admin/addsystemusersuccess.html'),
            controller: 'AddSystemUserSuccessCtrl'
          }
        },
        data: {
          pageTitle: 'Successful Adding A System User',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
          ]
        },
        params: {
          addSystemUserResponse: null
        },
        resolve: {
          addSystemUserResponse: function($http, $stateParams) {
            if ($stateParams.addSystemUserResponse != null) {
              return $stateParams.addSystemUserResponse;
            } // end if
          } // end function
        } // end resolve
      }); // end state addsystemusersuccess
      $stateProvider.state('addsystemuserchangeroleconfirmation', {
        url: '/addsystemuserchangeroleconfirmation',
        views: {
          main: {
            template: require('../views/private/admin/addsystemuserchangeroleconfirmation.html'),
            controller: 'AddSystemUserChangeRoleConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Change System User Role Confirmation',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
          ]
        },
        params: {
          addSystemUserResponse: null
        },
        resolve: {
          addSystemUserResponse: function($http, $stateParams) {
            if ($stateParams.addSystemUserResponse != null) {
              return $stateParams.addSystemUserResponse;
            } // end if
          } // end function
        } // end resolve
      }); // end state addsystemuserchangeroleconfirmation
    } // end function myApp
  ])
  .service('AddSystemUserService', [
    '$http',
    function($http) {
      this.addSystemUser = function(addSystemUserData) {
        var addSystemUserURI = '/CfcCharity/api/private/admin/system/user/add';
        return $http.post(addSystemUserURI, addSystemUserData);
      };
    }
  ])
  .controller('AddSystemUserCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'inviteRoleListData',
    'AddSystemUserService',
    'BrowserEventService',
    'blockUI',
    'toastrService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      inviteRoleListData,
      AddSystemUserService,
      BrowserEventService,
      blockUI,
      toastrService
    ) {
      $scope.setModuleName('CfcCharity.addsystemuser');
      $scope.inviteRoleListData = inviteRoleListData;

      //    // $scope.systemUser = {};
      //   $scope.addSystemUserErrors = "";

      // Validate and add a system user also send invitation email to the added user in the server side code]
      $scope.addSystemUser = function() {
        if (
          $scope.addSystemUserData === null ||
          $scope.addSystemUserData === undefined ||
          $scope.addSystemUserData.userName === null ||
          $scope.addSystemUserData.userName === undefined
        ) {
          $scope.showAddSystemUserErrorMessage('User Name is required');
          return;
        }

        if (
          $scope.addSystemUserData !== null &&
          $scope.addSystemUserData !== undefined &&
          $scope.addSystemUserData.userName !== null &&
          $scope.addSystemUserData.userName !== undefined &&
          $scope.addSystemUserData.userName.length > 50
        ) {
          $scope.showAddSystemUserErrorMessage(
            'User Name length should be less than 50 characters'
          );
          return;
        }

        if (
          $scope.addSystemUserData === null ||
          $scope.addSystemUserData === undefined ||
          $scope.addSystemUserData.emailId === null ||
          $scope.addSystemUserData.emailId === undefined
        ) {
          $scope.showAddSystemUserErrorMessage('Email is required');
          return;
        }

        if (
          $scope.addSystemUserData !== null &&
          $scope.addSystemUserData !== undefined &&
          $scope.addSystemUserData.emailId !== null &&
          $scope.addSystemUserData.emailId !== undefined &&
          $scope.addSystemUserData.emailId.length > 60
        ) {
          $scope.showAddSystemUserErrorMessage(
            'Email ID length should be between 5 & 60 characters'
          );
          return;
        }
        //
        //      if ($scope.addSystemUserData.role === null) {
        //        $scope.showAddSystemUserErrorMessage('Role cannot be null');
        //        return;
        //      }
        //
        if (
          $scope.addSystemUserData === null ||
          $scope.addSystemUserData === undefined ||
          $scope.addSystemUserData.role === null ||
          $scope.addSystemUserData.role === undefined ||
          $scope.addSystemUserData.role === 'Select a Role'
        ) {
          $scope.showAddSystemUserErrorMessage('Please select a role');
          return;
        }

        $scope.showAddSystemUserErrorMessage('');

        blockUI.start();

        // Submit the data to the server side for the Add System User
        // To do : validate email
        var addSystemUserData = $scope.addSystemUserData;
        addSystemUserData.invitingUserId = $scope.getCurrentUser().id;
        addSystemUserData.isZoneUser = false;

        AddSystemUserService.addSystemUser(addSystemUserData)
          .then(function(res) {
            var data = res.data;
            var addSystemUserResponse = data;

            if (
              addSystemUserResponse.error ||
              addSystemUserResponse.statusCode === 'ERROR'
            ) {
              $scope.showAddSystemUserErrorMessage(
                addSystemUserResponse.errorResponse.errorCode +
                  ' : ' +
                  addSystemUserResponse.errorResponse.errorMessage +
                  '. Please try again later!'
              );
              return;
            }

            if (
              addSystemUserResponse.statusCode ===
                'SYSTEM_USER_INVITATION_EXIST' ||
              addSystemUserResponse.statusCode === 'USER_DOES_NOT_EXIST' ||
              addSystemUserResponse.statusCode ===
                'USER_SYSTEM_ROLE_DOES_NOT_EXIST' ||
              addSystemUserResponse.statusCode ===
                'USER_SYSTEM_ROLE_INACTIVE' ||
              addSystemUserResponse.statusCode === 'USER_ACCOUNT_DISABLED' ||
              addSystemUserResponse.statusCode === 'USER_ACCOUNT_SUSPENDED' ||
              addSystemUserResponse.statusCode === 'USER_ACCOUNT_BLOCKED'
            ) {
              $state.go('addsystemusersuccess', {
                addSystemUserResponse: addSystemUserResponse
              });
            } else if (
              addSystemUserResponse.statusCode === 'USER_SYSTEM_ROLE_ACTIVE'
            ) {
              $state.go('addsystemuserchangeroleconfirmation', {
                addSystemUserResponse: addSystemUserResponse
              });
            } // end else if
          })
          .catch(function(err) {
            $scope.showAddSystemUserErrorMessage(
              'Error while adding a system user'
            );
          });
        blockUI.stop();
      };

      $scope.showErrorMessage = function(error) {
        toastrService.error(error);
      };

      $scope.showAddSystemUserErrorMessage = function(error) {
        $scope.addSystemUserErrors = error;
      };
    } // end function
  ])
  .controller('AddSystemUserSuccessCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'addSystemUserResponse',
    'AddSystemUserService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      addSystemUserResponse,
      AddSystemUserService,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.addsystemusersuccess');
      if (addSystemUserResponse != null) {
        $scope.addSystemUserResponse = addSystemUserResponse;
      } // end if addSystemUserResponse not null
    } // end function
  ])
  .controller('AddSystemUserChangeRoleConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'addSystemUserResponse',
    'AddSystemUserService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      addSystemUserResponse,
      AddSystemUserService,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.addsystemuserchanageroleconfirmation');
      if (addSystemUserResponse != null) {
        $scope.addSystemUserResponse = addSystemUserResponse;
      } // end if addSystemUserResponse not null
    } // end function
  ]);
