'use strict';

(function() {
  angular
    .module('CfcCharity.admin.system')
    .controller('IrsMasterFileSearchCtrl', IrsMasterFileSearchCtrl);

  IrsMasterFileSearchCtrl.$inject = [
    '$scope',
    '$state',
    '$log',
    'UserService',
    'SYSTEM_USER_SECONDARY_ROLES',
    'IrsMasterFileSearchService',
    'CubeSvc',
    '$location',
    '$anchorScroll',
    'toastrService',
    'ValidationUtil',
    'AdminJobsService'
  ];

  function IrsMasterFileSearchCtrl(
    $scope,
    $state,
    $log,
    UserService,
    SYSTEM_USER_SECONDARY_ROLES,
    IrsMasterFileSearchService,
    CubeSvc,
    $location,
    $anchorScroll,
    toastrService,
    ValidationUtil,
    AdminJobsService
  ) {
    var vm = this;
    var currentdate = new Date();
    vm.opmAuditor = false;
    function init() {
      vm.irsSearchResults = [];
      vm.totalElements = 0;
      vm.irsSearchResultsPerPage = 50;

      $scope.pagination = {
        current: 1
      };
      vm.searchType = null;
      vm.irsSearchValue = null;

      var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      if (userObj.secondaryRoleList.indexOf('OPM_ADMIN') >= 0) {
        vm.opmAdmin = true;
      }
    }

    var activePageNumber = 1;
    var selectedSearchType = null;
    var irsValueSearched = null;

    $scope.searchIrsMasterFile = function(
      pageNumber,
      searchType,
      irsSearchValue
    ) {
      toastr.clear();
      vm.irsSearchResults = [];
      if (searchType && irsSearchValue) {
        if (
          searchType === 'ein' &&
          !ValidationUtil.isEinValidLength(irsSearchValue)
        ) {
          CubeSvc.displayWarningMessage(
            'EIN must be numeric and must contain exactly 9 digits.'
          );
          return;
        }
        getIrsMasterFileSearchResults(pageNumber, searchType, irsSearchValue);
      }
    };

    $scope.clearIrsSearchResults = function() {
      vm.irsSearchResults = [];
      vm.totalElements = 0;
    };

    function getIrsMasterFileSearchResults(
      pageNumber,
      searchType,
      irsSearchValue
    ) {
      activePageNumber = pageNumber;
      $scope.pagination.current = pageNumber;
      selectedSearchType = searchType;
      irsValueSearched = irsSearchValue;
      IrsMasterFileSearchService.getIrsMasterFileSearchResults(
        pageNumber - 1,
        searchType,
        irsSearchValue
      )
        .then(function(res) {
          if (
            res.data.value &&
            res.data.message.type === CubeSvc.messageType.SUCCESS
          ) {
            var results = res.data.value;
            vm.irsSearchResults = results.content;
            vm.totalElements = results.totalElements;
            var old = $location.hash();
            $location.hash('irsMasterSearch');
            $anchorScroll();
            $location.hash(old);
          }
        })
        .catch(function(err) {
          $log.error(err);
        });
    }

    $scope.showHistory = function() {
      //get all the job types first
      AdminJobsService.transferCharityDataTypes()
        .then(function(res) {
          vm.jobTypes = res.data;
          //find the IRS master file type
          angular.forEach(vm.jobTypes, function(value, key) {
            if (value.displayName === 'IRS Master File') {
              vm.displayName = value.displayName;
              //get the record for the current year
              angular.forEach(value.activeJobs, function(value1, key1) {
                vm.jobId = value1.jobId;
              });
            }
          });
          $state.go('admin-system-jobs-history', {
            jobId: vm.jobId,
            campaignYear: currentdate.getFullYear(),
            displayName: vm.displayName
          });
        })
        .catch(function(err) {
          $log.error('error getting all jobs', err);
        });
    };

    $scope.pageChanged = function(newPage) {
      if (activePageNumber !== newPage) {
        getIrsMasterFileSearchResults(
          newPage,
          selectedSearchType,
          irsValueSearched
        );
      }
    };

    init();
  }
})();
