(function() {
  'use strict';
  angular
    .module('CfcCharity.environmentInfoService', [])
    .service('EnvironmentInfoService', EnvironmentInfoService);

  EnvironmentInfoService.$inject = ['$http', '$log', '$q', '$location'];

  function EnvironmentInfoService($http, $log, $q, $location) {
    this.getEnvironmentInfo = function() {
      if ($location.host().indexOf('opm.gov') === -1) {
        var url = '/CfcCharity/api/public/home/environment';
        return $http.get(url);
      } else {
        return $q.resolve({});
      }
    };
    this.getBasicEnvironmentInfo = function() {
      var url = '/CfcCharity/api/private/home/basicEnvironment';
      return $http.get(url);
    };
  }
})();
