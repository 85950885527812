(function() {
  var moduleName = 'CfcCharity.directives.applicationStatus';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcApplicationStatus', [
    'AppealService',
    'AppealStatus',
    '$log',
    function(AppealService, AppealStatus, $log) {
      return {
        restrict: 'E',
        template: require('./applicationStatus.html'),
        scope: {
          applicationId: '@',
          applicationType: '@',
          applicationStatus: '@',
          cfcCode: '@'
        },
        controller: function($scope, BreadcrumbTokenDataService) {
          // CLOSED
          // WITHDRAWN
          // APPROVED
          // DENIED
          // SUBMITTED
          // MORE_INFO_UNSIGNED
          // MORE_INFO
          $scope.statusTooltip = null;
          $scope.showAppealNow = false;

          function init() {
            if (
              $scope.applicationStatus !== 'DENIED' &&
              $scope.applicationStatus !== 'IN_APPEAL'
            ) {
              return;
            }

            AppealService.getApplicationAppeal(
              $scope.applicationType,
              $scope.applicationId,
              $scope.cfcCode
            )
              .then(function(res) {
                var appeal = res.data;
                if (!appeal) {
                  return;
                }
                if ($scope.applicationType === 'FEDERATION') {
                  BreadcrumbTokenDataService.setIsFederation(true);
                  BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                    $scope.applicationId
                  );
                } else {
                  BreadcrumbTokenDataService.setApplicationReviewTokenData(
                    $scope.applicationId
                  );
                }
                $scope.appealDueDate = AppealService.toUTCDate(
                  new Date(appeal.appealDueDate)
                );
                if (
                  $scope.appealDueDate >= AppealService.toUTCDate(new Date())
                ) {
                  $scope.showAppealNow = true;
                  $scope.statusTooltip = 'Appeal due';
                } else {
                  $scope.statusTooltip = 'Appeal closed';
                }
              })
              .catch(function(err) {
                if (err.data) {
                  $log.error('error message: ' + err.data);
                }
                $log.error(
                  'Error code: ' +
                    err.data.errorCode +
                    ' Error getApplicationAppeal(' +
                    $scope.applicationType +
                    '/' +
                    $scope.applicationId +
                    '/' +
                    $scope.cfcCode +
                    ')'
                );
              });
          }

          init();
        }
      };
    }
  ]);

  module.filter('statusDisplayFilter', function() {
    return function(input, customerFacing) {
      if (!input) {
        return input;
      }
      // remove underscores
      input = input.replace(/_/g, ' ');

      if (customerFacing && input == 'DENIED') {
        input = 'Not Approved';
      }

      // capitalize all words
      return input.toLowerCase().replace(/\b./g, function(a) {
        return a.toUpperCase();
      });
    };
  });
})();
