// denial.module.js - Module declaration for Review Denial Reasons Page
// single file as this should be universal across review types and short

'use strict';

(function() {
  angular
    .module('CfcCharity.review.denial', [
      'ui.router',
      'CfcCharity.security',
      'hateoas',
      'CfcCharity.common'
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      'HateoasInterceptorProvider',
      'USER_ROLES',
      'SYSTEM_USER_SECONDARY_ROLES',
      config
    ])
    .service('DenialReasonsService', DenialReasonsService)
    .service('ApplicationWrapperService', ApplicationWrapperService)
    .controller('DenialReasonsCtrl', DenialReasonsCtrl);

  function config(
    $stateProvider,
    $urlRouterProvider,
    HateoasInterceptorProvider,
    USER_ROLES,
    SYSTEM_USER_SECONDARY_ROLES
  ) {
    $stateProvider.state('review-denial-reasons', {
      url: '/review-denial-reasons',
      views: {
        main: {
          template: require('./denials.html'),
          controller: 'DenialReasonsCtrl',
          controllerAs: 'vm'
        }
      },
      data: {
        breadcrumbs: function(ApplicationService, isFed) {
          if (!isFed) {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'reviewLevel',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'reviewLevel',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: 'Member Applications',
                  crumbRoute: 'memberapplication',
                  crumbParams:
                    '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          } else {
            return [
              {
                crumbName: '%zoneCode% Applications',
                crumbRoute: 'zoneApplications',
                crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
              },
              {
                crumbName: 'reviewLevel',
                crumbRoute: 'zonecompletenessreview',
                crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
              },
              {
                crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
              },
              {
                crumbName: 'Federation Application',
                crumbRoute: 'federationDashboard',
                crumbParams: '{federationId: %applicationId%}'
              },
              {
                crumbName: 'Application Review',
                crumbRoute: 'federationapplicationreview',
                crumbParams: '{federationApplicationId: %applicationId%}'
              }
            ];
          }
        },
        pageTitle: 'Review Denial Reasons',
        requireAuthentication: true,
        authorizedRoles: [USER_ROLES.system_user],
        authorizedSecondaryRoles: [
          SYSTEM_USER_SECONDARY_ROLES.opm_admin,
          SYSTEM_USER_SECONDARY_ROLES.opm_staff,
          SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
          SYSTEM_USER_SECONDARY_ROLES.zone_manager,
          SYSTEM_USER_SECONDARY_ROLES.reviewer
        ]
      },
      params: {
        isFederation: null,
        applicationId: null,
        sourcePageName: null,
        sourcePageParams: null,
        charityType: null
      },
      resolve: {
        parameterData: function($stateParams) {
          return {
            isFederation: $stateParams.isFederation,
            applicationId: $stateParams.applicationId || null,
            sourcePageName: $stateParams.sourcePageName || null,
            sourcePageParams: $stateParams.sourcePageParams || null,
            charityType: $stateParams.charityType || null
          };
        },
        tokenData: function($stateParams, BreadcrumbTokenDataService) {
          BreadcrumbTokenDataService.setIsFederation($stateParams.isFederation);
          return BreadcrumbTokenDataService.getReviewLevel(
            $stateParams.applicationId,
            $stateParams.isFederation
          ).then(function(res1) {
            if ($stateParams.isFederation) {
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                $stateParams.applicationId
              );
            } else {
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                $stateParams.applicationId
              );
            }
          });
        }
      }
    });
  }

  DenialReasonsService.$inject = ['$http'];

  function DenialReasonsService($http) {
    // standard available denial reasons
    function getDenialReasonsByPledgeYear(pledgeYear, cfcCode) {
      var url =
        '/CfcCharity/api/private/charity/application/denialreason/lookup/' +
        pledgeYear +
        '/' +
        cfcCode;
      return $http.get(url);
    }

    function getDenialReasonsByPledgeYearAndApplicationType(
      pledgeYear,
      applicationType
    ) {
      var url =
        '/CfcCharity/api/private/charity/application/denialreason/' +
        pledgeYear +
        '/' +
        applicationType;
      return $http.get(url);
    }

    function getDenialReasonsByPledgeYearAndApplicationTypeAndApplicationStep(
      pledgeYear,
      applicationType,
      applicationStep
    ) {
      var url =
        '/CfcCharity/api/private/charity/application/denialreason/' +
        pledgeYear +
        '/' +
        applicationType +
        '/' +
        applicationStep;
      return $http.get(url);
    }

    function getSelectDenialReasonsByYearAndCFC(pledgeYear, cfcCode) {
      var url =
        '/CfcCharity/api/private/charity/application/denialreason/stored/' +
        pledgeYear +
        '/' +
        cfcCode;
      return $http.get(url);
    }

    function getSelectDenialReasonsByAttachmentId(attachmentId) {
      var url =
        '/CfcCharity/api/private/charity/application/denialreason/stored/aos/' +
        attachmentId;
      return $http.get(url);
    }

    function updateSelectDenialReasons(denialReason) {
      var url =
        '/CfcCharity/api/private/charity/application/denialreason/stored/update';
      var tmp = {};
      angular.copy(denialReason, tmp);
      if (tmp.hasOwnProperty('id')) {
        delete tmp.id;
      }
      return $http.post(url, tmp);
    }

    return {
      getDenialReasonsByYear: getDenialReasonsByPledgeYear,
      getDenialReasonsByYearAndType: getDenialReasonsByPledgeYearAndApplicationType,
      getDenialReasonsByYearAndTypeAndStep: getDenialReasonsByPledgeYearAndApplicationTypeAndApplicationStep,
      getSelectDenialReasonsByYearAndCFC: getSelectDenialReasonsByYearAndCFC,
      updateSelectDenialReasons: updateSelectDenialReasons,
      getSelectDenialReasonsByAttachmentId
    };
  }

  ApplicationWrapperService.$inject = [
    '$q',
    'ApplicationDashboardService',
    'FederationDashboardService'
  ];

  function ApplicationWrapperService(
    $q,
    ApplicationDashboardService,
    FederationDashboardService
  ) {
    // async (from $http return in nested service) gets charity/fed application dashboard data
    function getApplicationDashboardData(appId, isFed) {
      if (isFed === true) {
        return FederationDashboardService.getFederationApplication(appId);
      } else {
        return ApplicationDashboardService.applicationDetailsById(appId);
      }
    }

    function getUser(userId) {
      return ApplicationDashboardService.getUserInfoById(userId);
    }

    return {
      getAppData: getApplicationDashboardData,
      getUser: getUser
    };
  }

  DenialReasonsCtrl.$inject = [
    '$state',
    '$log',
    '$q',
    '$http',
    '$scope',
    '$window',
    'parameterData',
    'BrowserEventService',
    'ZoneCenterService',
    'DenialReasonsService',
    'ApplicationWrapperService',
    'tokenData',
    'CubeSvc'
  ];

  function DenialReasonsCtrl(
    $state,
    $log,
    $q,
    $http,
    $scope,
    $window,
    parameterData,
    BrowserEventService,
    ZoneCenterService,
    DenialReasonsService,
    ApplicationWrapperService,
    tokenData,
    CubeSvc
  ) {
    // using controllerAs syntax, both here and in html, `vm` = this context
    var vm = this;
    var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
    $scope.isZoneManagerOrReviewer =
      userOb.secondaryRoleList.includes('ZONE_MANAGER') ||
      userOb.secondaryRoleList.includes('REVIEWER');
    vm.cfcCode = tokenData.cfcCode;

    let mapReason = function(reason) {
      return {
        cfcCode: vm.appDash.cfcCode,
        campaignId: vm.appDash.campaignId,
        applicationType: reason.applicationType || null,
        applicationId: vm.applicationId,
        denialReasonId: reason.id,
        checked: reason.checked,
        updateByUserId: reason.updateByUserId,
        updateByUserInitials: reason.updateByUserInitials,
        updateDate: reason.updateDate,
        appealChecked: reason.appealChecked,
        appealUpdateByUserId: reason.appealUpdateByUserId,
        appealUpdateByUserInitials: reason.appealUpdateByUserInitials,
        appealUpdateDate: reason.appealUpdateDate,
        denialReasonText: reason.denialReasonText,
        applicationStep: reason.applicationStep,
        regionDisplay: reason.attachments
      };
    };

    function loadInitialStateValues() {
      vm.canEdit = false;
      vm.hasAccess = false;
      vm.canEditAppeal = false;
      vm.canEditReview = false;
      vm.appType = null;
      vm.cannedDenialReasons = []; // the standard, pre-defined ones
      vm.selectedReasons = []; // the recording of selected pre-defined and custom entered reasons
      vm.customReasons = []; // just the custom denial reasons, up to 2
    }

    // async prepares parameter data for use
    function loadStateParams() {
      return $q(function(resolve, reject) {
        if (parameterData) {
          // load from resolved parameterData
          vm.isFederation = parameterData.isFederation;
          vm.applicationId = parameterData.applicationId;
          vm.sourcePageName = parameterData.sourcePageName;
          vm.sourcePageParams = parameterData.sourcePageParams;
          vm.charityType = parameterData.charityType;
          ApplicationWrapperService.getAppData(
            vm.applicationId,
            vm.isFederation
          )
            .then(function(res) {
              // set to vm
              vm.appDash = res.data.value;
              if (vm.isFederation) {
                vm.appType = 'FEDERATION';
              } else {
                if (
                  vm.appDash.federationNumber !== null &&
                  vm.appDash.federationNumber !== ''
                ) {
                  vm.appType = 'MEMBER';
                } else {
                  vm.appType = 'INDEPENDENT';
                }
              }
              resolve(
                'successfully loaded state params and dependent app review'
              );
            })
            .catch(function(err) {
              reject(err);
            });
        } else {
          reject('failed loading state parameter data');
        }
      });
    }

    // async shims in values for federations that don't match ind/mem dashboard values
    function shimFederationValues() {
      return $q(function(resolve, reject) {
        if (vm.isFederation) {
          // since pledgeYear and charity name don't come in the federation dashboard response, grab it
          CubeSvc.get(
            '/CfcCharity/api/private/federation/' + vm.applicationId
          ).then(
            function(resp) {
              var respValue = resp.data.value;
              vm.appDash.pledgeYear = respValue.pledgeYear;
              vm.appDash.name = respValue.federationName;
              resolve('fed values are shimmed');
            },
            function(er) {
              reject(er);
            }
          );
        } else {
          resolve('not a fed');
        }
      });
    }

    function performRoleChecks() {
      return $q(function(resolve, reject) {
        vm.user = BrowserEventService.getCurrentUser();
        if (
          vm.user.secondaryRoleList &&
          Array.isArray(vm.user.secondaryRoleList)
        ) {
          if (
            vm.user.secondaryRoleList.includes('OPM_ADMIN') ||
            vm.user.secondaryRoleList.includes('OPM_STAFF')
          ) {
            vm.hasAccess = true;
            vm.canEdit = true;
            vm.canEditAppeal =
              vm.appDash.status === 'IN_APPEAL' ||
              vm.appDash.applicationStatus === 'IN_APPEAL'
                ? true
                : false;
            vm.canEditReview = isInReviewStatus(vm.appDash);
            resolve('has access');
          } else if (vm.user.secondaryRoleList.includes('OPM_AUDITOR')) {
            vm.hasAccess = true;
            vm.canEdit = false;
            vm.canEditAppeal = false;
            vm.canEditReview = false;
            resolve('has access');
          } else if (
            vm.user.secondaryRoleList.includes('ZONE_MANAGER') ||
            vm.user.secondaryRoleList.includes('REVIEWER')
          ) {
            // check for respective zone!
            ZoneCenterService.findCharityAccessEligibility(
              vm.user.id,
              vm.appDash.cfcCode
            ).then(
              function(res) {
                if (res.data === true) {
                  vm.hasAccess = true;
                  vm.canEdit = true;
                  vm.canEditReview = isInReviewStatus(vm.appDash);
                  resolve('has access');
                } else {
                  vm.hasAccess = false;
                  vm.canEdit = false;
                  vm.canEditAppeal = false;
                  vm.canEditReview = false;
                  reject('no access');
                }
              },
              function(err) {
                $log.error('error establishing user access for this zone', err);
                vm.hasAccess = false;
                vm.canEdit = false;
                reject('no access');
              }
            );
          } else {
            reject('no access');
          }
        } else {
          reject('no access');
        }
      });
    }

    function isInReviewStatus(app) {
      var status = ['IN_REVIEW', 'SUBMITTED', 'MORE_INFO'];
      if (
        status.indexOf(app.status) > -1 ||
        status.indexOf(app.applicationStatus) > -1
      ) {
        return true;
      }
      return false;
    }

    // async setter for canned and selected/custom reasons, returns promise handle
    function setDenialReasons() {
      return $q(function(resolve, reject) {
        // get standard/canned denial reasons
        DenialReasonsService.getDenialReasonsByYear(vm.appDash.pledgeYear).then(
          function(resp1) {
            // success
            vm.cannedDenialReasons = resp1.data;
            // get selected/custom denial reasons
            DenialReasonsService.getSelectDenialReasonsByYearAndCFC(
              vm.appDash.pledgeYear,
              vm.appDash.cfcCode
            ).then(
              function(resp2) {
                // success
                vm.selectedReasons = resp2.data;
                mergeSelectedWithCanned();
                resolve('success');
              },
              function(err) {
                // error
                reject(err);
              }
            );
          },
          function(err) {
            // error
            reject(err);
          }
        );
      });
    }

    function mergeSelectedWithCanned() {
      var ar = [];
      vm.cannedDenialReasons.map(cannedDenialReason => {
        var selectedReason = vm.selectedReasons.filter(function(item) {
          return (
            item.denialReasonId === cannedDenialReason.id &&
            (item.checked || item.appealChecked)
          );
        })[0];
        if (selectedReason) {
          if (selectedReason.checked) {
            cannedDenialReason.checked = true;
            cannedDenialReason.denialReasonText =
              selectedReason.denialReasonText;
            cannedDenialReason.updateDate = selectedReason.updateDate;
            cannedDenialReason.updateByUserInitials =
              selectedReason.updateByUserInitials;
          }

          if (selectedReason.appealChecked) {
            cannedDenialReason.appealChecked = true;
            cannedDenialReason.denialReasonText =
              selectedReason.denialReasonText;
            cannedDenialReason.appealUpdateByUserId =
              selectedReason.appealUpdateByUserId;
            cannedDenialReason.appealUpdateByUserInitials =
              selectedReason.appealUpdateByUserInitials;
            cannedDenialReason.appealUpdateDate =
              selectedReason.appealUpdateDate;
          }
          cannedDenialReason.attachments = selectedReason.regionDisplay;
        }
        if (cannedDenialReason.applicationStep === 'Step 4 AOS') {
          cannedDenialReason.appealUpdateByUserInitials = '';
          cannedDenialReason.appealUpdateDate = '';
          cannedDenialReason.updateDate = '';
          cannedDenialReason.canEditAppeal = cannedDenialReason.canEditReview = false;
          ar.push(cannedDenialReason);
        } else {
          cannedDenialReason.canEditAppeal = vm.canEditAppeal;
          cannedDenialReason.canEditReview = vm.canEditReview;
          ar.push(cannedDenialReason);
        }
      });

      vm.customReasons = ar;
    }
    // consistent 'send back' function
    vm.exitToPreviousPage = function() {
      $state.go(vm.sourcePageName, vm.sourcePageParams);
    };

    function confirmDeletingCustomDenialEntry() {
      return $window.confirm(
        'You have elected to remove your custom denial reason. This will delete the current text. Do you wish to continue?'
      );
    }

    vm.goToCharityDashboard = function() {
      var dest = 'charitydashboard';
      var opts = {};

      opts.charityDashboardCfcCode = vm.appDash.cfcCode;
      opts.pledgeYear = vm.appDash.pledgeYear;

      $state.go(dest, opts);
    };

    vm.goToApplicationDashboard = function() {
      var dest = null;
      var opts = {};
      if (vm.isFederation) {
        dest = 'federationDashboard';
        opts.federationId = vm.applicationId;
      } else {
        dest = 'applicationDashboard';
        opts.applicationId = vm.applicationId;
      }
      $state.go(dest, opts);
    };

    function getInitials() {
      var initials = '';
      if (userOb && userOb.firstName && userOb.firstName.length >= 1) {
        initials = userOb.firstName.substring(0, 1).toUpperCase();
      }
      if (userOb && userOb.lastName && userOb.lastName.length >= 1) {
        initials += userOb.lastName.substring(0, 1).toUpperCase();
      }
      return initials;
    }

    // initialization sequence
    function init() {
      loadInitialStateValues();
      loadStateParams().then(
        function(success) {
          shimFederationValues().then(
            function(yep) {
              // role check
              performRoleChecks().then(
                function(success) {
                  // initial state load
                  setDenialReasons().then(
                    function(data) {
                      // resolved/success
                      // page is initialized
                    },
                    function(err) {
                      // rejected/error
                      $log.error(err);
                    }
                  );
                },
                function(err) {
                  // no access
                  vm.exitToPreviousPage();
                }
              );
            },
            function(nope) {
              $log.error(nope);
            }
          );
        },
        function(fail) {
          $log.error(fail);
        }
      );
    }

    vm.onCheckChanged = function(reason) {
      if (
        reason.denialReasonText != null &&
        reason.denialReasonText.length > 0 &&
        (!reason.appealChecked && !reason.checked)
      ) {
        if (!confirmDeletingCustomDenialEntry()) {
          if (reason.appealChecked !== undefined) {
            reason.appealChecked = !reason.appealChecked;
          }
          if (reason.checked !== undefined) {
            reason.checked = !reason.checked;
          }
          return;
        }
        reason.denialReasonText = null;
      }

      reason.appealUpdateDate = reason.appealChecked ? new Date() : null;
      reason.appealUpdateByUserInitials = reason.appealChecked
        ? getInitials()
        : null;
      reason.updateDate = reason.checked ? new Date() : null;
      reason.updateByUserInitials = reason.checked ? getInitials() : null;
      DenialReasonsService.updateSelectDenialReasons(mapReason(reason));
    };

    vm.onCustomDenialReasonSave = function(reason) {
      DenialReasonsService.updateSelectDenialReasons(mapReason(reason));
    };

    // let's begin
    init();
  }
})();
