angular
  .module('CfcCharity.applicationDashboard', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider
        .state('applicationDashboard', {
          url: '/applicationDashboard/:applicationId',
          views: {
            main: {
              template: require('../views/private/application/applicationDashboard.html'),
              controller: 'ApplicationDashboardCtrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
              },
              {
                crumbName: 'Member Applications',
                crumbRoute: 'memberapplication',
                crumbParams:
                  '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
              }
            ],
            pageTitle: 'CFC Application Dashboard Home Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            fromPage: null,
            cfcCode: null,
            pledgeYear: null,
            applicationId: null,
            appType: null
          },
          resolve: {
            r: function(
              CubeSvc,
              AdminReviewSectionStepDeterminationSessionService
            ) {
              CubeSvc.setManualCacheRelease();
              AdminReviewSectionStepDeterminationSessionService.clearCurrentReviewLevel();
              return {};
            },
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService,
              $q
            ) {
              //clearing the session storage for previously viewed items
              BreadcrumbTokenDataService.clearTokenData();
              var applicationId = $stateParams.applicationId;
              if (applicationId != null) {
                ApplicationService.setApplicationId(applicationId);
                return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                  applicationId
                );
              }
            },
            applicationDashboardData: function(
              CubeSvc,
              $stateParams,
              ApplicationService,
              $q
            ) {
              var applicationId = $stateParams.applicationId;
              if (applicationId != null) {
                var url =
                  '/CfcCharity/api/private/application/dashboard/details/' +
                  applicationId;
                return CubeSvc.get(url, null, false);
              }
            }
          }
        })
        .state('withdrawnApplication', {
          url: '/withdrawnApplication/:wapplicationId',
          views: {
            main: {
              template: require('../views/private/application/withdrawnApplication.html'),
              controller: 'ApplicationDashboardCtrl'
            }
          },
          data: {
            pageTitle: 'Withdrawn Application',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService,
              $q
            ) {
              //clearing the session storage for previously viewed items
              BreadcrumbTokenDataService.clearTokenData();
              var applicationId = $stateParams.wapplicationId;
              if (applicationId != null) {
                ApplicationService.setApplicationId(applicationId);
                return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                  applicationId
                );
              }
            },
            applicationDashboardData: function(
              CubeSvc,
              $stateParams,
              ApplicationService,
              $q
            ) {
              var applicationId = $stateParams.wapplicationId;
              if (applicationId != null) {
                var url =
                  '/CfcCharity/api/private/application/dashboard/details/' +
                  applicationId;
                return CubeSvc.get(url, null, false);
              }
            }
          }
        })
        .state('signAndunsign', {
          url: '/signAndunsign',
          views: {
            main: {
              template: require('../views/private/application/signAndunsign.html'),
              controller: 'ApplicationSignCtrl'
            }
          },
          data: {
            pageTitle: 'Unsign Application',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            applicationId: null
          }
        })
        .state('viewSignature', {
          url: '/viewSignature',
          views: {
            main: {
              template: require('../views/private/application/viewSignature.html'),
              controller: 'ApplicationSignCtrl'
            }
          },
          data: {
            pageTitle: 'View Signature',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: { applicationId: null }
        })
        .state('application-checklist', {
          url: '/application/checklist',
          views: {
            main: {
              template: require('./app/application/steps/checklist/checklist.html'),
              controller: 'ChecklistCtrl',
              controllerAs: 'vm'
            }
          },
          data: {
            pageTitle: 'Application Pre-Signature Checklist Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
          },
          params: {
            applicationId: null,
            pledgeYear: null,
            fromPage: null,
            mode: null,
            isFederationPage: false,
            applicationDetails: null
          },
          resolve: {
            parameterData: function($stateParams) {
              return {
                applicationId: $stateParams.applicationId || null,
                pledgeYear: $stateParams.pledgeYear || null,
                fromPage: $stateParams.fromPage || null,
                mode: $stateParams.mode || null,
                isFederationPage: false,
                applicationDetails: $stateParams.applicationDetails || null
              };
            }
          }
        })
        .state('fed-application-checklist', {
          url: '/federationApplication/checklist',
          views: {
            main: {
              template: require('./app/application/steps/checklist/fedChecklist.html'),
              controller: 'ChecklistCtrl',
              controllerAs: 'vm'
            }
          },
          data: {
            pageTitle: 'Application Pre-Signature Checklist Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
          },
          params: {
            applicationId: null,
            pledgeYear: null,
            fromPage: null,
            mode: null,
            isFederationPage: true,
            applicationDetails: null
          },
          resolve: {
            parameterData: function($stateParams) {
              return {
                applicationId: $stateParams.applicationId || null,
                pledgeYear: $stateParams.pledgeYear || null,
                fromPage: $stateParams.fromPage || null,
                mode: $stateParams.mode || null,
                isFederationPage: true,
                applicationDetails: $stateParams.applicationDetails || null
              };
            }
          }
        });
    }
  ])
  .service('ApplicationDashboardService', [
    'CubeSvc',
    function(CubeSvc) {
      this.applicationDetailsById = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/details/' + applicationId,
          null,
          false
        );
      };
      this.findCharityApplicationSessions = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/charity/application/getUserApplicationSessionsByApplicationId',
          { params: { applicationId: applicationId } },
          false
        );
      };
      this.findCharityApplication2 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/step2/' + applicationId,
          null,
          false
        );
      };

      this.findCustomCharityApplication = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/custom/' + applicationId,
          null,
          false
        );
      };

      this.getCharityApplication = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/' + applicationId,
          null,
          false
        );
      };
      this.updateCharityApplication = function(
        applicationId,
        charityApplication
      ) {
        return CubeSvc.put(
          '/CfcCharity/api/private/application/' + applicationId,
          charityApplication,
          null,
          false
        );
      };
      this.updateGroupNumber = function(applicationId, reviewGrpName, isFed) {
        return CubeSvc.put(
          '/CfcCharity/api/private/charity/updateReviewNumber/' +
            applicationId +
            '/' +
            reviewGrpName +
            '/' +
            isFed,
          null,
          false
        );
      };
      this.getUserInfo = function() {
        return CubeSvc.get(
          '/CfcCharity/api/private/users/current',
          null,
          false
        );
      };
      this.getUserInfoById = function(userId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/users/' + userId,
          null,
          false
        );
      };
      this.getApplicationByPledgeYearAndCFC = function(pledgeYear, cfcCode) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/details/' +
            pledgeYear +
            '/' +
            cfcCode,
          null,
          false
        );
      };
      this.getPledgeYears = function() {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/pledgeYears',
          null,
          false
        );
      };
      this.findApplicationFees = function(
        campaignId,
        applicationType,
        applicationId
      ) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/fees',
          {
            params: {
              campaignId: campaignId,
              applicationType: applicationType,
              applicationId: applicationId
            }
          },
          false
        );
      };
      this.isApplicationFeePaid = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/isApplicationFeePaid',
          { params: { applicationId: applicationId } },
          false
        );
      };
      this.getZoneDetails = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/getZoneDetails',
          { params: { applicationId: applicationId } },
          false
        );
      };
      this.getListingZoneDetails = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/getDodZoneListingDetails',
          { params: { applicationId: applicationId } },
          false
        );
      };
      this.getAllApplicationRFIs = function(applicationId) {
        var url =
          '/CfcCharity/api/private/application/review/' +
          applicationId +
          '/all';
        return CubeSvc.get(url, null, false);
      };
      this.getApplicationSignatureHistory = function(applicationId) {
        var url =
          '/CfcCharity/api/private/application/signatureHistory/' +
          applicationId;
        return CubeSvc.get(url, { params: { isFederation: false } }, false);
      };
    }
  ])
  .controller('ApplicationDashboardCtrl', [
    '$state',
    '$scope',
    '$log',
    'CubeSvc',
    'CakeSvc',
    'CharityService',
    'ApplicationDashboardService',
    'ApplicationService',
    'BrowserEventService',
    'FederationDashboardService',
    'AdministrationCenterService',
    'AdminReviewSectionStepDeterminationSessionService',
    'ZoneCenterService',
    'BreadcrumbTokenDataService',
    'tokenData',
    'urlAccess',
    'CharityDashboardService',
    'AuthorizationHelper',
    'SYSTEM_USER_SECONDARY_ROLES',
    '$http',
    'applicationDashboardData',
    'PLEDGE_YEARS',
    function(
      $state,
      $scope,
      $log,
      CubeSvc,
      CakeSvc,
      CharityService,
      ApplicationDashboardService,
      ApplicationService,
      BrowserEventService,
      FederationDashboardService,
      AdministrationCenterService,
      AdminReviewSectionStepDeterminationSessionService,
      ZoneCenterService,
      BreadcrumbTokenDataService,
      tokenData,
      urlAccess,
      CharityDashboardService,
      AuthorizationHelper,
      SYSTEM_USER_SECONDARY_ROLES,
      $http,
      applicationDashboardData,
      PLEDGE_YEARS
    ) {
      $http
        .get(
          `/CfcCharity/api/private/application/step2a/${
            $state.params.applicationId
          }`
        )
        .then(function(response) {
          $scope.step2A_s3_pdf_link = response.data.value
            ? response.data.value.s3Link
            : null;
        })
        .catch(function(error) {
          console.error('Error: ', error);
        });

      var form990_upload_history_url =
        '/CfcCharity/api/private/application/step8/uploadedfiles/' +
        $state.params.applicationId;

      $http
        .get(form990_upload_history_url)
        .then(function(res) {
          $scope.uploadedFileData = res.data.value;
          $scope.irsHistory = $scope.uploadedFileData.irsHistory;
          $scope.eSignHistory = $scope.uploadedFileData.eSignHistory;
          $scope.form990_s3_link = $scope.irsHistory[0]
            ? $scope.irsHistory[0].s3Link
            : null;
        })
        .catch(function(error) {
          console.error('Error: ', error);
        });

      var application_step7_url =
        '/CfcCharity/api/private/application/step7/' +
        $state.params.applicationId;
      $http
        .get(application_step7_url)
        .then(function(res) {
          $scope.audited_financial_s3_link = res.data.value
            ? res.data.value.s3Link
            : null;
        })
        .catch(function(error) {
          console.log('Error: ', error);
        });

      var application_step6_url =
        '/CfcCharity/api/private/application/step6/' +
        $state.params.applicationId;
      $http
        .get(application_step6_url)
        .then(function(res) {
          $scope.irs_determination_s3_link = res.data.value
            ? res.data.value.s3Link
            : null;
        })
        .catch(function(error) {
          console.log('Error: ', error);
        });

      var application_step5_url =
        '/CfcCharity/api/private/application/step5/' +
        $state.params.applicationId;
      $http
        .get(application_step5_url)
        .then(function(res) {
          $scope.exemption_status_s3_link = res.data.value
            ? res.data.value.s3Link
            : null;
        })
        .catch(function(error) {
          console.log('Error: ', error);
        });

      var application_step4_url =
        '/CfcCharity/api/private/application/step4/' +
        $state.params.applicationId;
      $http
        .get(application_step4_url)
        .then(function(res) {
          $scope.web_service_s3_link = res.data.value
            ? res.data.value.s3Link
            : null;
        })
        .catch(function(error) {
          console.log('Error: ', error);
        });

      var step4_history_url =
        '/CfcCharity/api/private/application/step4/' +
        ApplicationService.getApplicationId() +
        '/uploadhistory';
      $http
        .get(step4_history_url)
        .then(function(response) {
          $scope.webBasedServiceHistoryList =
            response.data.webBasedServiceHistoryList;
        })
        .catch(function(error) {
          console.error('Error: ', error);
        });

      $scope.setModuleName('applicationDashboard');
      $scope.displayPayApplicationFeeButton = false;
      $scope.disablePayApplicationFeeButton = true;
      $scope.displayAppealsArea = false;
      AdminReviewSectionStepDeterminationSessionService.clearCurrentReviewLevel();
      $log.debug('ApplicationDashboardCtrl' + $state.params.applicationId);
      $scope.noApplicationMessage = '';
      $scope.appDashboardData = applicationDashboardData.data.value;
      var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.user = userObj;
      $scope.isOpmAdmin =
        userObj.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
      $scope.userId = userObj.id;
      $scope.currentFileVersion = 1;
      $scope.currentStep4FileVersion = 1;
      $scope.showPdfBtn = false;
      if (
        ($scope.appDashboardData.applicationFeePaid ||
          $scope.appDashboardData.application.achPaymentDone) &&
        !$scope.appDashboardData.campaignAvailable
      ) {
        $scope.showPdfBtn = true;
      }

      if (!$scope.appDashboardData.canAccess) {
        $state.go('accessDenied', {
          userId: $scope.userId
        });
      } else {
        if (tokenData != null) {
          var id = tokenData.federationId;
          if (id === null) {
            $scope.$parent.$$childHead.breadcrumbs = [];
          }
        }
        var fromPage = $state.params.fromPage;
        $scope.fromPage = fromPage;
        $scope.isMemberApplication = false;
        $scope.selectedGroupNumber = '';
        var applicationId = $state.params.applicationId;
        $scope.applicationId = applicationId;
        var cfcCode = $state.params.cfcCode;
        var pledgeYear = tokenData.pledgeYear;
        $scope.displayForPriorYears = false;
        if (pledgeYear && pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022) {
          $scope.displayForPriorYears = true;
        }
        var wapplicationId = $state.params.wapplicationId;
        $scope.wapplicationId = wapplicationId;
        var applicationDashboardData = {};
        $scope.appStatus = '';
        $scope.showAdminOnlyBlock = false;
        $scope.showEditGroupNumber = false;
        var currentDate = ApplicationService.toUTCDate(new Date());
        $scope.currentDate = currentDate;

        if ($scope.isAdminUser() || $scope.isAdminAndCharityUser()) {
          AdministrationCenterService.getSystemUserRole($scope.userId).then(
            function(res) {
              var role = res.data;
              if (role !== null && role !== '') {
                if (role.firstLevelRoleList.indexOf('ROLE_SYSTEM_USER') >= 0) {
                  if (
                    role.secondLevelRoleList.indexOf('OPM_ADMIN') >= 0 ||
                    role.secondLevelRoleList.indexOf('OPM_STAFF') >= 0 ||
                    role.secondLevelRoleList.indexOf('CUSTOMER_SERVICE') >= 0 ||
                    role.secondLevelRoleList.indexOf('OPM_AUDITOR') >= 0
                  ) {
                    $scope.showAdminOnlyBlock = true;
                  }
                  if (
                    role.secondLevelRoleList.indexOf('OPM_ADMIN') >= 0 ||
                    role.secondLevelRoleList.indexOf('OPM_STAFF') >= 0
                  ) {
                    $scope.showEditGroupNumber = true;
                  }
                }
              }
            }
          );
        }

        if (applicationId) {
          //this is to set the token data for breadcrumbs for future pages.
          BreadcrumbTokenDataService.setApplicationReviewTokenData(
            applicationId
          );
          var res = $scope.appDashboardData.application;
          ApplicationService.setApplication(res);
          if (!res.listingPeriodNotStarted) {
            $scope.displayAppealsArea = true;
          }
          $scope.applicationType = '';
          if (res !== null && res !== '') {
            $scope.isApplicationAvailable = true;
            applicationDashboardData.applicationDetails = res;
            $scope.cfcCode =
              applicationDashboardData.applicationDetails.cfcCode;
            $scope.campaignId =
              applicationDashboardData.applicationDetails.campaignId;
            BrowserEventService.setCampaignId(
              applicationDashboardData.applicationDetails.campaignId
            );
            $scope.appStatus =
              applicationDashboardData.applicationDetails.status;
            if (
              applicationDashboardData.applicationDetails
                .listingPeriodNotStarted
            ) {
              if ($scope.appStatus === 'DENIED') {
                $scope.appStatus = 'IN_REVIEW';
              }
            }
            if (
              applicationDashboardData.applicationDetails
                .applicationPeriodNotEnded
            ) {
              if ($scope.appStatus === 'CLOSED') {
                $scope.appStatus = 'IN_PROGRESS';
              }
            }
            $scope.selectedGroupNumber =
              applicationDashboardData.applicationDetails.reviewGroup;
            BrowserEventService.setApplicationId(res.applicationId);
            if (res.federationNumber !== null && res.federationNumber !== '') {
              $scope.isMemberApplication = true;
              $scope.applicationType = 'MEMBER';
            } else {
              $scope.applicationType = 'INDEPENDENT';
            }
            var appFeeInfo = $scope.appDashboardData.appFees;
            if (true === appFeeInfo.FEE_PAID) {
              applicationDashboardData.applicationFees =
                appFeeInfo.FEE_PAID_AMOUNT;
              applicationDashboardData.applicationFeePaidFlag = true;
            } else {
              applicationDashboardData.applicationFees =
                appFeeInfo.APPLICATION_FEE;
              applicationDashboardData.applicationFeePaidFlag = false;
            }
            applicationDashboardData.applicationFeePaidFlag =
              $scope.appDashboardData.applicationFeePaid;
            var zoneDetails = $scope.appDashboardData.reviewZoneDetails;
            if (zoneDetails) {
              applicationDashboardData.zoneDetails = zoneDetails;
            }

            var listingZoneDetails = $scope.appDashboardData.listingZoneDetails;
            if (listingZoneDetails) {
              applicationDashboardData.listingZoneDetails = listingZoneDetails;
            }

            if (
              res.applicationChangeEndDate &&
              currentDate > res.applicationChangeEndDate &&
              !res.startedAfterEnrollmentEnd
            ) {
              $scope.isApplicationChangeDateEnded = true;
            } else {
              $scope.isApplicationChangeDateEnded = false;
            }
            applicationDashboardData.charityAuthority = true;
            var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            applicationDashboardData.userId = userObj.id;
            var secondaryRoleList = userObj.secondaryRoleList;
            $scope.secondaryRoleList = secondaryRoleList;
            //if secondary role is null then check if this is a fed owner of a member
            // checks if user is a fed role with access to this member
            if ($scope.secondaryRoleList.length === 0) {
              // return $q(function (resolve, reject) {
              if (
                applicationDashboardData.applicationDetails.federationNumber
              ) {
                $scope.isCharityMemberOfFedWithUserAccessForPledgeYear = false;
                CharityDashboardService.getCharityFedMemberAccessForYear(
                  applicationDashboardData.applicationDetails.pledgeYear,
                  applicationDashboardData.applicationDetails.cfcCode
                )
                  .then(function(res) {
                    var yn = JSON.parse(res.data);
                    if (true === yn) {
                      $scope.isCharityMemberOfFedWithUserAccessForPledgeYear = true;
                    }
                  })
                  .catch(function(err) {
                    $log.error(err);
                    // nothing really, just default to false
                  });
              }
            }

            //check the access to show the breadcrumb links
            if (
              tokenData != null &&
              tokenData != undefined &&
              tokenData.federationId != null
            ) {
              if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
                urlAccess
                  .getFederationApplicationAccess(
                    tokenData.pledgeYear,
                    tokenData.federationCfc,
                    $scope.$parent.$$childHead.breadcrumbs[0]
                  )
                  .then(function(res) {
                    if ($scope.$parent) {
                      $scope.$parent.$$childHead.breadcrumbs[0] = res;
                    }
                  });
                urlAccess
                  .getMemberApplicationAccess(
                    tokenData.pledgeYear,
                    tokenData.federationCfc,
                    $scope.$parent.$$childHead.breadcrumbs[1]
                  )
                  .then(function(res) {
                    if ($scope.$parent) {
                      $scope.$parent.$$childHead.breadcrumbs[1] = res;
                    }
                  });
              }
            }

            // CCA-2325 check for application period being past
            $scope.isZoneReviewerOrManager = false;

            $scope.showZoneReviewData = function() {
              if ($scope.appStatus === 'WITHDRAWN') {
                return false;
              } else {
                if ($scope.isZoneReviewerOrManager) {
                  return applicationDashboardData.zoneDetails.zoneReviewOpen;
                } else {
                  return true;
                }
              }
            };
            // checks for additional role access of Zone Manager / Reviewer
            if (
              secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
              secondaryRoleList.indexOf('REVIEWER') >= 0
            ) {
              $scope.isZoneReviewerOrManager = true;
              ZoneCenterService.findCharityAccessEligibility(
                userObj.id,
                applicationDashboardData.applicationDetails.cfcCode
              ).then(function(accessEligbilityRes) {
                var isAccessEligible = accessEligbilityRes.data;
                if (isAccessEligible) {
                  $scope.showAdminOnlyBlock = true;
                }
              });
            }
            $scope.disableApplicationReviewButton = function() {
              return !$scope.showZoneReviewData();
            };
            var enrollmentStatus = $scope.appDashboardData.campaignAvailable;
            applicationDashboardData.isEnrollmentAvailable = enrollmentStatus;
            CharityService.getCharityUserRole(res.cfcCode).then(function(
              userRoleRes
            ) {
              var userRole = userRoleRes.data;
              if (userRole) {
                $scope.userCharityRole = userRole;
              } else {
                applicationDashboardData.charityAuthority = false;
                if (res.federationNumber) {
                  var federation = $scope.appDashboardData.fedApp;
                  if (federation) {
                    CharityService.getCharityUserRole(federation.cfcCode).then(
                      function(fedUserRes) {
                        var cRole = fedUserRes.data;
                        if (cRole) {
                          applicationDashboardData.charityAuthority = true;
                        } else {
                          applicationDashboardData.charityAuthority = false;
                        }
                      }
                    );
                  }
                }
              }
            });

            var customApplicationSteps =
              $scope.appDashboardData.charityApplicationCustom;

            applicationDashboardData.step2aData = { status: 'NA' };
            applicationDashboardData.step4Data = { status: 'NA' };
            applicationDashboardData.step5Data = { status: 'NA' };
            applicationDashboardData.step6Data = { status: 'NA' };
            applicationDashboardData.step7Data = { status: 'NA' };
            applicationDashboardData.step8Data = { status: 'NA' };

            if (customApplicationSteps.fysaFyspWfr) {
              applicationDashboardData.step2aData = buildStep2aData(
                customApplicationSteps
              );
            } else {
              applicationDashboardData.step4Data = buildStep4Data(
                customApplicationSteps
              );
              applicationDashboardData.step5Data = buildStep5Data(
                customApplicationSteps
              );
              applicationDashboardData.step6Data = buildStep6Data(
                customApplicationSteps
              );
              applicationDashboardData.step7Data = buildStep7Data(
                customApplicationSteps
              );
              applicationDashboardData.step8Data = buildStep8Data(
                customApplicationSteps
              );
            }

            $scope.applicationStatus = $scope.appDashboardData.appStatus;
            if ($scope.applicationStatus) {
              var applicationSessions = $scope.appDashboardData.appSession;
              var totalSec = 0;
              if (applicationSessions) {
                for (var i = 0; i < applicationSessions.length; i++) {
                  var appSession = applicationSessions[i];
                  totalSec += appSession.step1TimeSpentInSeconds || 0;
                  totalSec += appSession.step2TimeSpentInSeconds || 0;
                  // totalSec += appSession.step2aTimeSpentInSeconds || 0;
                  totalSec += appSession.step3TimeSpentInSeconds || 0;
                  totalSec += appSession.step4TimeSpentInSeconds || 0;
                  totalSec += appSession.step5TimeSpentInSeconds || 0;
                  totalSec += appSession.step6TimeSpentInSeconds || 0;
                  totalSec += appSession.step7TimeSpentInSeconds || 0;
                  totalSec += appSession.step8TimeSpentInSeconds || 0;
                  totalSec += appSession.step9TimeSpentInSeconds || 0;
                  totalSec += appSession.step10TimeSpentInSeconds || 0;
                  totalSec += appSession.step11TimeSpentInSeconds || 0;
                }
                var radix = 10;
                var hours = parseInt(totalSec / 3600, radix);
                var minutes = parseInt(totalSec / 60, radix) % 60;
                var seconds = totalSec % 60;
                var totalTimeSpent =
                  (hours < 10 ? '0' + hours : hours) +
                  ':' +
                  (minutes < 10 ? '0' + minutes : minutes) +
                  ':' +
                  (seconds < 10 ? '0' + seconds : seconds);
                applicationDashboardData.totalTimeSpent = totalTimeSpent;
              }
            }
          }
        } else {
          $scope.isApplicationAvailable = false;
          $scope.noApplicationMessage =
            'Currently you do not have any applications created for the selected year';
          var applicationDetails = {};
          applicationDetails.cfcCode = cfcCode;
          applicationDetails.pledgeYear = pledgeYear;
          if (cfcCode) {
            applicationDashboardData.applicationDetails.name =
              $scope.appDashboardData.application.name;
          }
          applicationDashboardData.applicationDetails = applicationDetails;
        }

        function buildStep2aData(customApplicationSteps) {
          var step2aData = {};
          step2aData.status = 'Missing';

          var status = customApplicationSteps.step2aStepStatus;
          var fileName = customApplicationSteps.step2aFileName;

          if (status === 'COMPLETE' && fileName === null) {
            step2aData.status = 'NA';
          } else if (fileName !== null) {
            step2aData.status = 'Received';
          }

          return step2aData;
        }

        function buildStep5Data(customApplicationSteps) {
          var step5Data = {};
          step5Data.status = 'Missing';
          var exemptionStatus = customApplicationSteps.step5ExemptionStatus;
          var fileName = customApplicationSteps.step5FileName;

          $scope.isPledgeYearPriorTo2023 = false;
          if (pledgeYear && pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2023) {
            $scope.isPledgeYearPriorTo2023 = true;
          }

          if (
            applicationDashboardData.applicationDetails.isOffYear ||
            exemptionStatus === 'OPTION_1' ||
            (exemptionStatus === 'OPTION_2' &&
              !$scope.isPledgeYearPriorTo2023 &&
              customApplicationSteps.step5ReligiousAffiliation != null &&
              !customApplicationSteps.step5ReligiousAffiliation)
          ) {
            step5Data.status = 'NA';
          } else if (
            (exemptionStatus === 'OPTION_2' ||
              exemptionStatus === 'OPTION_3') &&
            fileName !== null
          ) {
            step5Data.status = 'Received';
          }

          return step5Data;
        }

        function buildStep6Data(customApplicationSteps) {
          var step6Data = {};
          step6Data.status = 'Missing';
          var status = customApplicationSteps.step6StepStatus;
          var certifyIrsDetermination =
            customApplicationSteps.step6CertifyIrsDetermination;
          var fileName = customApplicationSteps.step6FileName;

          if (status === 'COMPLETE' && !certifyIrsDetermination) {
            step6Data.status = 'NA';
          } else if (
            ((status === 'COMPLETE' && certifyIrsDetermination) ||
              status === 'INCOMPLETE') &&
            fileName !== null
          ) {
            step6Data.status = 'Received';
          }

          return step6Data;
        }

        function buildStep7Data(customApplicationSteps) {
          var step7Data = {};
          step7Data.status = 'Missing';

          var revenueOption = customApplicationSteps.step7RevenueOption;
          var fileName = customApplicationSteps.step7FileName;
          var stepStatus = customApplicationSteps.step7StepStatus;

          if (revenueOption === 'OPTION_3') {
            step7Data.status = 'NA';
          } else if (
            (revenueOption === 'OPTION_1' || revenueOption === 'OPTION_2') &&
            fileName
          ) {
            step7Data.status = 'Received';
          }

          return step7Data;
        }

        function buildStep8Data(customApplicationSteps) {
          var step8Data = {};
          step8Data.status = 'Missing';
          if (customApplicationSteps.step8FileName !== null) {
            step8Data.status = 'Received';
          }
          if ($scope.appDashboardData.form990UploadHistory.length > 0) {
            if (
              $scope.appDashboardData.form990UploadHistory[0].changedFileName !=
                null &&
              $scope.appDashboardData.form990UploadHistory[0].changedFileName !=
                undefined &&
              $scope.appDashboardData.form990UploadHistory[0].changedFileName !=
                ''
            ) {
              var us = $scope.appDashboardData.form990UploadHistory[0].changedFileName.lastIndexOf(
                '_'
              );
              $scope.currentFileVersion = parseInt(
                $scope.appDashboardData.form990UploadHistory[0].changedFileName
                  .substring(
                    us,
                    $scope.appDashboardData.form990UploadHistory[0].length
                  )
                  .replace('_ver', '')
                  .replace(/\.[^\.]+$/, '')
              );
            }
          }
          return step8Data;
        }
        $scope.openFilesModal = function() {
          if (!$scope.displayForPriorYears) {
            var url =
              '/CfcCharity/api/private/application/step8/uploadedfiles/' +
              $state.params.applicationId;
            CubeSvc.get(url, null, false).then(function(res) {
              $scope.uploadedFileData = res.data.value;
              $scope.irsHistory = $scope.uploadedFileData.irsHistory;
              $scope.eSignHistory = $scope.uploadedFileData.eSignHistory;
              $scope.form990_s3_link = $scope.irsHistory[0].s3Link;
            });
          }
          $('#allFiles').modal();
        };

        function buildStep4Data(customApplicationSteps) {
          var step4Data = {};
          step4Data.status = 'Missing';
          if (!customApplicationSteps.webBasedService) {
            step4Data.status = 'NA';
          }
          if (
            customApplicationSteps.webBasedService &&
            customApplicationSteps.step4FileName !== null
          ) {
            step4Data.status = 'Received';
          }
          if (
            customApplicationSteps.step4FileName != null &&
            customApplicationSteps.step4FileName != undefined &&
            customApplicationSteps.step4FileName != ''
          ) {
            var us = customApplicationSteps.step4FileName.lastIndexOf('_');
            $scope.currentStep4FileVersion = parseInt(
              customApplicationSteps.step4FileName
                .substring(us, customApplicationSteps.step4FileName.length)
                .replace('_ver', '')
                .replace(/\.[^\.]+$/, '')
            );
          }
          return step4Data;
        }

        $scope.openFilesForStep4Modal = function() {
          $('#allStep4Files').modal();
        };

        $scope.applicationDashboardData = applicationDashboardData;

        $scope.withdrawApplication = function(wapplicationId) {
          ApplicationDashboardService.getCharityApplication(
            wapplicationId
          ).then(function(res) {
            var charityApplication = res.data.value;
            charityApplication.applicationStatus = 'WITHDRAWN';
            ApplicationDashboardService.updateCharityApplication(
              wapplicationId,
              charityApplication
            ).then(function() {
              $state.go('applicationDashboard', {
                applicationId: wapplicationId
              });
            });
          });
        };
        $scope.gotoApplicationPage = function(
          application,
          isEnrollmentAvailable,
          stepPage,
          charityAuthority
        ) {
          if (
            charityAuthority &&
            (application.status === 'IN_PROGRESS' ||
              (application.status === 'MORE_INFO_UNSIGNED' &&
                applicationDashboardData.applicationDetails.rfiEndDate >
                  currentDate))
          ) {
            gotoStepPageEditable(application, isEnrollmentAvailable, stepPage);
          } else if (
            application.status !== 'WITHDRAWN' &&
            application.status !== 'DISBANDED'
          ) {
            var isSignedOrPaid =
              'SIGNED' === application.status ||
              'SUBMITTED' === application.status;
            if (
              isEnrollmentAvailable &&
              isSignedOrPaid &&
              charityAuthority &&
              !$scope.isApplicationChangeDateEnded &&
              !res.applicationRegulatoryDeadlineElapsed
            ) {
              gotoStepPageEditable(
                application,
                isEnrollmentAvailable,
                stepPage
              );
            } else {
              gotoStepPageReadOnly(application, stepPage);
            }
          } else if (
            (application.status === 'WITHDRAWN' &&
              ($scope.user.secondaryRoleList.length > 0 &&
                !(
                  $scope.user.secondaryRoleList[0].indexOf(
                    'GB_FINANCE_ADMIN'
                  ) >= 0 ||
                  $scope.user.secondaryRoleList[0].indexOf('GB_FINANCE') >= 0
                ))) ||
            ($scope.user.secondaryRoleList.length === 0 &&
              $scope.user.roles[0] === 'CHARITY_USER')
          ) {
            gotoStepPageReadOnly(application, stepPage);
          }
        };

        var gotoStepPageEditable = function(
          application,
          isEnrollmentAvailable,
          stepPage
        ) {
          ApplicationService.reset();
          var applicationId = application.applicationId;
          var parentFedAppId =
            application.federationId ||
            $scope.applicationDashboardData.applicationDetails
              .federationNumber ||
            null;
          var status = application.status;
          var cfcCode = application.cfcCode;
          var pledgeYear = application.pledgeYear;
          var fysa = application.fysaFyspWfr;
          if (
            !isEnrollmentAvailable &&
            status !== 'MORE_INFO_UNSIGNED' &&
            !application.startedAfterEnrollmentEnd
          ) {
            $scope.errorMsg =
              'The Charity Application Period is currently Closed';
          } else {
            $scope.appNavbar.pledgeYear = pledgeYear;
            $scope.appNavbar.cfcCode = cfcCode;
            $scope.appNavbar.fysa = fysa;
            $scope.appNavbar.charityName =
              $scope.appDashboardData.application.name;
            if (stepPage === 'step4') {
              var response = $scope.appDashboardData.charityApplicationStep2;
              $scope.applicationStep2 = response;
            }
            ApplicationService.setCfcCode(cfcCode);
            ApplicationService.setPledgeYear(pledgeYear);
            $scope.appVisibility.viewMode = 'EDIT';
            CharityService.getCharityUserRole(cfcCode).then(function(res) {
              var role = res.data;
              var userSession = ApplicationService.getUserSession();
              userSession.cfcCode = cfcCode;
              //userSession.userId = $scope.getCurrentUser().id;
              userSession.applicationId = applicationId;
              userSession.userRole = role.userRole;
              userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                new Date()
              );
              userSession.$save(function(response) {
                userSession = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  response
                );
              });
            });
            CharityService.getPreApplicationDetails(cfcCode, pledgeYear).then(
              function(res) {
                var data = res.data.value;
                BrowserEventService.setCampaignId(data.campaignId);
                ApplicationService.setPreApplicationDetails(data);
                ApplicationService.editApplication(applicationId);
                $state.go(stepPage, {
                  mode: 'EDIT',
                  applicationId: applicationId,
                  parentFedAppId: parentFedAppId
                });
                $scope.setModuleName('CfcCharity.application');
              }
            );
          }
        };
        var gotoStepPageReadOnly = function(application, stepPage) {
          var applicationId = application.applicationId;
          var parentFedAppId =
            application.federationId ||
            $scope.applicationDashboardData.applicationDetails
              .federationNumber ||
            null;

          var cfcCode = application.cfcCode;
          var pledgeYear = application.pledgeYear;
          var fysa = application.fysaFyspWfr;
          $scope.appNavbar.pledgeYear = pledgeYear;
          $scope.appNavbar.cfcCode = cfcCode;
          $scope.appNavbar.fysa = fysa;
          $scope.appNavbar.charityName =
            $scope.appDashboardData.application.name;
          ApplicationService.setCfcCode(cfcCode);
          ApplicationService.setPledgeYear(pledgeYear);
          $scope.appVisibility.viewMode = 'VIEW';
          CharityService.getPreApplicationDetails(cfcCode, pledgeYear).then(
            function(res) {
              var data = res.data.value;
              BrowserEventService.setCampaignId(data.campaignId);
              ApplicationService.setPreApplicationDetails(data);
              ApplicationService.editApplication(applicationId);
              $state.go(stepPage, {
                mode: 'VIEW',
                applicationId: applicationId,
                parentFedAppId: parentFedAppId
              });
              $scope.setModuleName('CfcCharity.application');
            }
          );
        };

        // CCA-1594 goes through new checklist page before sign/unsign page
        $scope.goToSignProcess = function(application) {
          var errorMsg = '';
          $log.debug(
            'current date: ' +
              currentDate +
              'ApplicationChangeEndDate:: ' +
              application.applicationChangeEndDate
          );
          if (
            application.applicationChangeEndDate != null &&
            currentDate > application.applicationChangeEndDate &&
            !application.startedAfterEnrollmentEnd
          ) {
            errorMsg = 'Requested date passed.';
            $scope.errorMsg = errorMsg;
          } else {
            $state.go('application-checklist', {
              applicationId: application.applicationId,
              pledgeYear: application.pledgeYear,
              applicationDetails: application
            });
          }
        };
        $scope.signOrUnsignPage = function(application) {
          var errorMsg = '';
          $log.debug(
            'current date: ' +
              currentDate +
              'ApplicationChangeEndDate:: ' +
              application.applicationChangeEndDate
          );
          if (
            application.applicationChangeEndDate != null &&
            currentDate > application.applicationChangeEndDate &&
            !application.startedAfterEnrollmentEnd
          ) {
            errorMsg = 'Requested date passed.';
            $scope.errorMsg = errorMsg;
          } else {
            $state.go('signAndunsign', {
              applicationId: application.applicationId
            });
          }
        };
        $scope.updateGroupNumber = function(isFed) {
          ApplicationDashboardService.updateGroupNumber(
            applicationDashboardData.applicationDetails.applicationId,
            $scope.selectedGroupNumber,
            isFed
          ).then(function() {
            applicationDashboardData.applicationDetails.reviewGroup =
              $scope.selectedGroupNumber;
          });
        };
        $scope.resetGroupNumber = function() {
          $scope.selectedGroupNumber =
            applicationDashboardData.applicationDetails.reviewGroup;
        };
        $scope.isEditAppBtnDisabled = function() {
          if (
            !!applicationDashboardData &&
            !!applicationDashboardData.applicationDetails &&
            !!applicationDashboardData.applicationDetails.status
          ) {
            var isEnrollmentAvailable =
              applicationDashboardData.isEnrollmentAvailable;
            var isSignedPaidOrInProgress =
              'IN_PROGRESS' ===
                applicationDashboardData.applicationDetails.status ||
              'SIGNED' === applicationDashboardData.applicationDetails.status ||
              'SUBMITTED' ===
                applicationDashboardData.applicationDetails.status;
            var isRfiOpenAndUnsigned =
              'MORE_INFO_UNSIGNED' ===
                applicationDashboardData.applicationDetails.status &&
              applicationDashboardData.applicationDetails.rfiEndDate >
                currentDate;
            var isSubmittedAndRegulatoryDeadlineElapsed =
              applicationDashboardData.applicationDetails.status ===
                'SUBMITTED' &&
              applicationDashboardData.applicationDetails
                .applicationRegulatoryDeadlineElapsed;
            var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            if (undefined !== userObj && null != userObj) {
              var isUserCharityRole =
                Array.isArray(userObj.roles) &&
                userObj.roles.includes('CHARITY_USER');
              var startedAfterEnrollmentEnd =
                applicationDashboardData.applicationDetails
                  .startedAfterEnrollmentEnd;
              if (
                isUserCharityRole &&
                !$scope.isApplicationChangeDateEnded &&
                !startedAfterEnrollmentEnd
              ) {
                if (isSubmittedAndRegulatoryDeadlineElapsed) {
                  return true;
                }
                return !(
                  (isEnrollmentAvailable && isSignedPaidOrInProgress) ||
                  isRfiOpenAndUnsigned
                );
              } else {
                return (
                  applicationDashboardData.applicationDetails.status ===
                  'SIGNED'
                );
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
        };
        $scope.isEditAppBtnDisplayed = function() {
          if (
            !!$scope.applicationDashboardData &&
            !!$scope.applicationDashboardData.applicationDetails
          ) {
            var startedAfterEnrollmentEnd =
              $scope.applicationDashboardData.applicationDetails
                .startedAfterEnrollmentEnd;
            return (
              $scope.applicationDashboardData.charityAuthority &&
              ($scope.applicationDashboardData.applicationDetails.status ===
                'IN_PROGRESS' ||
                $scope.applicationDashboardData.applicationDetails.status ===
                  'SIGNED' ||
                $scope.applicationDashboardData.applicationDetails.status ===
                  'MORE_INFO_UNSIGNED' ||
                $scope.applicationDashboardData.applicationDetails.status ===
                  'SUBMITTED') &&
              ((!$scope.isApplicationChangeDateEnded &&
                !startedAfterEnrollmentEnd) ||
                (startedAfterEnrollmentEnd &&
                  $scope.applicationDashboardData.applicationDetails.status ===
                    'IN_PROGRESS'))
            );
          } else {
            return false;
          }
        };
        $scope.editAppBtnClick = function() {
          if (!$scope.isEditAppBtnDisabled()) {
            $scope.gotoApplicationPage(
              $scope.applicationDashboardData.applicationDetails,
              $scope.applicationDashboardData.isEnrollmentAvailable,
              'step1',
              $scope.applicationDashboardData.charityAuthority &&
                !$scope.isApplicationChangeDateEnded
            );
          }
        };
        $('.modal-backdrop').remove();

        $scope.withdrawnApplication = function() {
          if ($scope.isOpmAdmin) {
            $state.go('withdrawnApplication', {
              wapplicationId:
                applicationDashboardData.applicationDetails.applicationId
            });
          } else {
            $('#withdrawApplicationModal').modal('show');
          }
        };

        CubeSvc.performManualCacheRelease();

        function configurePayApplicationFeeButton() {
          var isMemberApplication =
            $scope.applicationDashboardData.applicationDetails
              .federationNumber !== null;
          var cfcCode =
            $scope.applicationDashboardData.applicationDetails.cfcCode;
          var applicationStatus =
            $scope.applicationDashboardData.applicationDetails.status;
          var applicationPaid =
            $scope.applicationDashboardData.applicationDetails.applicationPaid;

          if (isMemberApplication) {
            return;
          }

          CharityService.getCharityUserRole(cfcCode)
            .then(function(resp) {
              var result = resp.data;
              if (result && result.userRole) {
                if (
                  result.userRole === 'CHARITY_OWNER' ||
                  result.userRole === 'CHARITY_EDITOR'
                ) {
                  if (
                    applicationStatus === 'IN_PROGRESS' ||
                    applicationStatus === 'SIGNED'
                  ) {
                    $scope.displayPayApplicationFeeButton = true;
                    return;
                  }
                }
              }
            })
            .finally(function() {
              if (applicationStatus === 'SIGNED') {
                if (!applicationPaid) {
                  $scope.disablePayApplicationFeeButton = false;
                }
                if (
                  AuthorizationHelper.userHasAnyRole([
                    SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
                    SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
                    SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
                  ])
                ) {
                  $scope.displayPayApplicationFeeButton = true;
                  return;
                }
              }
              // CCA-4175
              $http
                .get('/CfcCharity/api/private/charity/getCampaignStatus')
                .then(function(resp) {
                  if (!resp.data) {
                    // Application period is closed
                    if (
                      applicationStatus === 'IN_REVIEW' ||
                      applicationStatus === 'SUBMITTED'
                    ) {
                      if (!applicationPaid) {
                        if (
                          AuthorizationHelper.userHasAnyRole([
                            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
                            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
                          ])
                        ) {
                          $scope.displayPayApplicationFeeButton = true;
                          $scope.disablePayApplicationFeeButton = false;
                        }
                      }
                    }
                  }
                });
            });
        }

        angular.element(function() {
          configurePayApplicationFeeButton();
        });
      }
    }
  ])
  .controller('ApplicationSignCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$log',
    '$q',
    'CharityService',
    'ApplicationDashboardService',
    'ApplicationService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $log,
      $q,
      CharityService,
      ApplicationDashboardService,
      ApplicationService,
      BrowserEventService
    ) {
      $scope.applicationSignatureHistory = [];
      $scope.applicationRFIs = [];
      $log.debug($state.params.applicationId);

      // check for sufficient rights to signature history and RFIs
      $scope.hasSufficientSignatureAndRFIviewingRights = false;
      var user = $scope.getCurrentUser();
      if (user.secondaryRoleList && Array.isArray(user.secondaryRoleList)) {
        $scope.hasSufficientSignatureAndRFIviewingRights =
          user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_1') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >=
            0 ||
          user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          user.secondaryRoleList.indexOf('REVIEWER') >= 0;
      }

      var applicationId = $state.params.applicationId;
      if (applicationId) {
        BrowserEventService.setApplicationId(applicationId);
        ApplicationDashboardService.getCharityApplication(applicationId).then(
          function(res) {
            var application = res.data.value;
            $log.debug(application);
            $scope.application = application;
            CharityService.findCharityWithCFC(application.cfcCode).then(
              function(res) {
                var charity = res.data;
                $scope.charity = charity;
                if ($scope.hasSufficientSignatureAndRFIviewingRights) {
                  // load up signature history and RFIs if user has appropriate rights
                  ApplicationDashboardService.getApplicationSignatureHistory(
                    applicationId
                  ).then(
                    function(resp) {
                      var data = resp.data.value;
                      $log.debug(data);
                      $scope.applicationSignatureHistory = data;
                    },
                    function(err) {
                      $log.error(err);
                    }
                  );
                  ApplicationDashboardService.getAllApplicationRFIs(
                    applicationId
                  ).then(
                    function(resp) {
                      var data = resp.data.value;
                      $log.debug(data);
                      $scope.applicationRFIs = data;
                    },
                    function(err) {
                      $log.error(err);
                    }
                  );
                }
              }
            );
          }
        );
      }

      $scope.saveSignature = function(application) {
        $scope.fieldsMissing = [];
        if (!$scope.application.certifyApplication) {
          $scope.fieldsMissing.push('Certify Application');
        }
        if (!$scope.application.signatureFullName) {
          $scope.fieldsMissing.push('Full name');
        }
        if (!$scope.application.signatureInitials) {
          $scope.fieldsMissing.push('Initials');
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          application.applicationStatus = 'SIGNED';
          application.certifyApplication = true;
          application.signedDate = new Date();
          ApplicationDashboardService.updateCharityApplication(
            application.id,
            application
          ).then(function(res) {
            var updateApplication = res.data.value;
            $state.go('applicationDashboard', {
              applicationId: updateApplication.id
            });
          });
        }
      };
      $scope.removeSignature = function(application) {
        application.applicationStatus = 'IN_PROGRESS';
        application.certifyApplication = null;
        application.signatureFullName = null;
        application.signatureInitials = null;
        application.signedDate = null;
        application.signedBy = null;
        ApplicationDashboardService.updateCharityApplication(
          application.id,
          application
        ).then(function(res) {
          var updateApplication = res.data.value;
          $state.go('applicationDashboard', {
            applicationId: updateApplication.id
          });
        });
      };
    }
  ]);
