angular
  .module('CfcCharity.charitylistingpayment', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('charitylistingpayment', {
        url: '/charitylistingpayment',
        views: {
          main: {
            template: require('../views/private/charity/charitylistingpayment.html'),
            controller: 'CharityListingPaymentCtrl'
          }
        },
        data: {
          pageTitle: 'Charity Listing Payment',
          requireAuthentication: true,
          authorizedRoles: [
            USER_ROLES.charity_user,
            USER_ROLES.system_user,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin
          ]
        },
        params: {
          charityListingId: null
        },
        resolve: {
          charityListingFeeResponse: function($http, $stateParams) {
            if ($stateParams.charityListingId) {
              var charityListingId = $stateParams.charityListingId;
              var url =
                '/CfcCharity/api/private/users/charitylisting/feedetails/' +
                charityListingId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('charitylistingpaymentthankyou', {
        url: '/charitylistingpaymentthankyou',
        views: {
          main: {
            template: require('../views/private/charity/charitylistingpaymentthankyou.html'),
            controller: 'CharityListingPaymentThankYouCtrl'
          }
        },
        data: {
          pageTitle: 'Charity Listing Payment Thank You',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          charityDashboardUserId: null,
          charityDashboardCfcCode: null,
          pledgeYear: null,
          paymentid: null,
          paymenttype: null,
          paymentfeeType: null,
          paymentMethod: null
        },
        resolve: {
          charityListingData: function($stateParams) {
            var charityListData = null;
            if (
              $stateParams.charityDashboardUserId &&
              $stateParams.charityDashboardCfcCode &&
              $stateParams.pledgeYear
            ) {
              charityListData = {
                charityDashboardUserId: $stateParams.charityDashboardUserId,
                charityDashboardCfcCode: $stateParams.charityDashboardCfcCode,
                pledgeYear: $stateParams.pledgeYear,
                paymentid: $stateParams.paymentid,
                paymenttype: $stateParams.paymenttype,
                paymentfeeType: $stateParams.paymentfeeType,
                paymentMethod: $stateParams.paymentMethod
              };
            }
            return charityListData;
          }
        }
      });
    }
  ])
  .controller('CharityListingPaymentCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'charityListingFeeResponse',
    'BrowserEventService',
    'CharityService',
    'blockUI',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      charityListingFeeResponse,
      BrowserEventService,
      CharityService,
      blockUI,
      $filter
    ) {
      blockUI.start();
      $scope.setModuleName('CfcCharity.charitylistingpayment');
      $scope.appType = BrowserEventService.getAppType();
      if (
        charityListingFeeResponse &&
        (charityListingFeeResponse.error ||
          charityListingFeeResponse.statusCode === 'ERROR')
      ) {
        $scope.showCharityListingFeeErrorMessage(
          charityListingFeeResponse.errorResponse.errorCode +
            ' : ' +
            charityListingFeeResponse.errorResponse.errorMessage +
            '. Please try again later!'
        );
      } else if (
        charityListingFeeResponse &&
        charityListingFeeResponse.statusCode === 'SUCCESS'
      ) {
        $scope.charityListingFeeData =
          charityListingFeeResponse.charityListingFeeBean;
      }
      CharityService.loadStates().then(function(res) {
        var states = res.data;
        $rootScope.states = states;
      });
      $scope.currentYear = new Date().getFullYear();
      $scope.currentMonth = new Date().getMonth() + 1;
      $scope.months = $locale.DATETIME_FORMATS.MONTH;
      $scope.expiryDateYears = [];
      for (i = 0; i <= 10; i++) {
        $scope.expiryDateYears.push($scope.currentYear + i);
      }
      $scope.processingPayment = false;
      $scope.validatePayment = function() {
        $scope.fieldsMissing = [];
        if (!$scope.charityListingFee) {
          $scope.fieldsMissing.push('Name on card is required.');
          $scope.fieldsMissing.push('Address 1 is required.');
          $scope.fieldsMissing.push('State is required.');
          $scope.fieldsMissing.push('City is required.');
          $scope.fieldsMissing.push('Zip is required.');
          $scope.fieldsMissing.push('Credit Card Number is required.');
          $scope.fieldsMissing.push('Expiration Date is required.');
          $scope.fieldsMissing.push('Security Code is required');
        } else {
          if (!$scope.charityListingFee.nameOnCard) {
            $scope.fieldsMissing.push('Name on card is required.');
          }
          if (!$scope.charityListingFee.billingAddress1) {
            $scope.fieldsMissing.push('Address 1 is required.');
          }
          if (!$scope.charityListingFee.billingState) {
            $scope.fieldsMissing.push('State is required.');
          }
          if (!$scope.charityListingFee.billingCity) {
            $scope.fieldsMissing.push('City is required.');
          }
          if (!$scope.charityListingFee.billingZip) {
            $scope.fieldsMissing.push('Zip is required.');
          }
          if (!$scope.charityListingFee.creditCardNumber) {
            $scope.fieldsMissing.push('Credit Card Number is required.');
          }
          if (
            !$scope.charityListingFee.selectedYear ||
            !$scope.charityListingFee.selectedMonth
          ) {
            $scope.fieldsMissing.push('Expiration Date is required.');
          } else {
            if (
              Number($scope.charityListingFee.selectedYear) <
                $scope.currentYear ||
              (Number($scope.charityListingFee.selectedYear) ===
                $scope.currentYear &&
                Number($scope.charityListingFee.selectedMonth) <
                  $scope.currentMonth)
            ) {
              $scope.fieldsMissing.push('Provided expiration date is invalid');
            }
          }
          if (!$scope.charityListingFee.securityCode) {
            $scope.fieldsMissing.push('Security Code is required');
          }
          if (
            $scope.charityListingFee.securityCode &&
            $scope.charityListingFee.securityCode.length < 3
          ) {
            $scope.fieldsMissing.push(
              'Security Code should be minimum 3 digits'
            );
          }
          if ($scope.charityListingFee.creditCardNumber) {
            var creditCardType = $filter('creditCardTypeFilter')(
              $scope.charityListingFee.creditCardNumber
            );
            if (!creditCardType) {
              $scope.fieldsMissing.push(
                'Invalid Credit Card type. Please enter a valid Visa, MasterCard, Discover or American Express card number.'
              );
            } else {
              $scope.charityListingFee.creditCardType = creditCardType;
            }
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.charityListingFee.charityListing = {};
          $scope.charityListingFee.charityListing.id =
            $scope.charityListingFeeData.charityListingId;
          $scope.charityListingFee.applicationId =
            $scope.charityListingFeeData.applicationId;
          $scope.charityListingFee.listingFee =
            $scope.charityListingFeeData.totalAmountDue;
          // $scope.charityListingFee.expirationDate = ('0' + ($scope.charityListingFee.expirationDate.getMonth() + 1)).slice(-2) + $scope.charityListingFee.expirationDate.getFullYear().toString().substr(2, 2);
          $scope.charityListingFee.expirationDate =
            $scope.charityListingFee.selectedYear +
            '-' +
            $scope.charityListingFee.selectedMonth;
          var charityListingPaymentURI =
            '/CfcCharity/api/private/users/charitylisting/payment';
          $scope.processingPayment = true;
          $http
            .post(charityListingPaymentURI, $scope.charityListingFee)
            .then(function(res) {
              var data = res.data;
              if (data.paymentSuccessful) {
                var userId = data.userId;
                var cfcCode = data.cfcCode;
                var year = data.year;
                $state.go('charitylistingpaymentthankyou', {
                  charityDashboardUserId: userId,
                  charityDashboardCfcCode: cfcCode,
                  pledgeYear: year,
                  paymentid: data.id,
                  paymenttype: 'INDEPENDENT',
                  paymentfeeType: 'Listing',
                  paymentMethod: 'Credit Card'
                });
              } else {
                var error = '';
                if (!data.error) {
                  error =
                    'Credit Card Transaction Failed. Contact Customer Care if the problem persists';
                } else if (data.error || data.statusCode === 'ERROR') {
                  error =
                    data.errorResponse.errorCode +
                    ' : ' +
                    data.errorResponse.errorMessage;
                }
                $scope.charityListingFee.expirationDate = '';
                $scope.fieldsMissing.push(error);
                $('#missingInfo').modal();
              }
            })
            .finally(function() {
              $scope.processingPayment = false;
            })
            .catch(function(error) {
              $scope.fieldsMissing.push(error);
              $('#missingInfo').modal();
            });
        }
      };
      $scope.showCharityListingFeeErrorMessage = function(error) {
        $scope.charityListingPaymentError = error;
      };
      $scope.gotoAchPayment = function() {
        BrowserEventService.setCharityName(
          $scope.charityListingFeeData.charityName
        );
        BrowserEventService.setFedCfcCode($scope.charityListingFeeData.cfcCode);
        BrowserEventService.setCampaignId(
          $scope.charityListingFeeData.campaignId
        );
        BrowserEventService.setListingId(
          $scope.charityListingFeeData.charityListingId
        );
        $state.go('charitylistingachpayment', {
          cfcCode: $scope.charityListingFeeData.cfcCode,
          charityName: $scope.charityListingFeeData.charityName,
          listingId: $scope.charityListingFeeData.charityListingId,
          campaignId: $scope.charityListingFeeData.campaignId
        });
      };
      blockUI.stop();
    }
  ])
  .controller('CharityListingPaymentThankYouCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'charityListingData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      charityListingData,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.charitylistingpaymentthankyou');
      $scope.charityListingData = charityListingData;
      $scope.appType = BrowserEventService.appType;
      $scope.continueToNext = function() {
        $state.go('charitylisting', {
          charityDashboardCfcCode:
            $scope.charityListingData.charityDashboardCfcCode,
          pledgeYear: $scope.charityListingData.pledgeYear,
          appType: $scope.appType
        });
      };
    }
  ]);
