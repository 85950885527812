/*
 * donations.service.js - Service declaration for Donations page (and summary for Charity Dashboard)
 *
 * warning: there is specific authorization logic per pledgeYear/campaign and CFC
 * this is due to the change in fed/member/ind between years and individual access
 *
 * @author: Eric McCormick
 *
 */

(function() {
  angular
    .module('CfcCharity.donations')
    .constant('DonationsReportsDefn', {
      CharitySummaryByYear: 'donation_summary',
      MemberSummaryByYear: 'federation_member_donations',
      CharityDisbursementByMonth: 'disbursement_amount_by_month',
      FedMembersDonationBalances: 'federation_member_balance',
      FedMembersDisbursementsByMonthYear: 'federation_member_disbursement'
    })
    .service('DonationsService', [
      '$q',
      '$http',
      '$parse',
      '$timeout',
      '$rootScope',
      'DonationsReportsDefn',
      'CubeSvc',
      DonationsService
    ]);

  // service for Donations page
  function DonationsService(
    $q,
    $http,
    $parse,
    $timeout,
    $rootScope,
    DonationsReportsDefn,
    CubeSvc
  ) {
    /*
     * wrote operation to perform initiation of athena data request via Charity endpoint
     */
    function initiateAthenaReportRequest(
      reportName,
      cfcCode,
      campaignId,
      bodyOpts
    ) {
      // optional body params
      var body = {};
      if (!angular.equals(bodyOpts, {})) {
        body = {
          params: bodyOpts
        };
      }
      if (!campaignId || !cfcCode || !reportName) {
        return $q.reject(
          'Missing Required Param, campaignId: ' +
            campaignId +
            ', cfcCode: ' +
            cfcCode +
            ', reportName: ' +
            reportName
        );
      }
      return CubeSvc.post(
        '/CfcCharity/api/private/charity/application/pledge/reports/' +
          campaignId +
          '/' +
          cfcCode +
          '/' +
          reportName,
        body,
        null,
        false
      );
    }

    /*
     * wrote operation to check status and/or return response of athena data via Charity endpoint
     *
     * note: this returns the raw response, use it wisely, as you probably could use the
     * abstractGetAthenaPledgeData function instead
     */
    function getAthenaReportStatusOrData(reportExecutionId) {
      if (!reportExecutionId) {
        return $q.reject(
          'Missing Required Param, reportExecutionId: ' + reportExecutionId
        );
      }
      return CubeSvc.get(
        '/CfcCharity/api/private/charity/application/pledge/reports/' +
          reportExecutionId,
        null,
        false
      );
    }

    /*
     * Abstract service method to get data response from Athena reporting endpoint
     * treat as private method to this service! the specific getters should invoke this
     *
     * required: reportName, cfcCode, campaignId
     *
     * optional: bodyOpts (shallow object, key:value pairs only, for additional req. params),
     * maxSec (max time in seconds), intSec (check interval in seconds)
     */
    function abstractGetAthenaPledgeData(
      reportName,
      cfcCode,
      campaignId,
      bodyOpts,
      asArray,
      maxSec,
      intSec,
      throwErrorNoData
    ) {
      return $q(function(resolve, reject) {
        var checkInterval = (intSec || 2) * 1000; // ms, 2sec
        var maxRequestTime = 5 * (maxSec || 60) * 1000; // 5min
        var startTime = new Date();
        var hasStateTransitionOccurred = false;
        var respNeedsArray = asArray || false;
        var body = bodyOpts || {};
        var hardError = throwErrorNoData || false;

        // state change sets hasStateTransitionOccurred
        $rootScope.$on('$stateChangeStart', function() {
          hasStateTransitionOccurred = true;
        });

        // status/response handler
        var checkReportById = function(reportExecutionId) {
          // ensures a resolve/reject handle that persists across timeout calls

          var currentTime = new Date();
          if (currentTime - startTime > maxRequestTime) {
            reject('report request went too long');
          } else {
            getAthenaReportStatusOrData(reportExecutionId)
              .then(function(result) {
                // success
                var status = result.data.value.status.toUpperCase();
                switch (status) {
                  case 'RUNNING':
                  case 'QUEUED':
                  case 'PENDING':
                    // check for state transition
                    if (true === hasStateTransitionOccurred) {
                      reject(
                        'CANCEL: state transition ocurred, no need to complete this promise'
                      );
                    } else {
                      // continue until we have SUCCESS/ERROR
                      $timeout(
                        checkReportById.bind(null, reportExecutionId),
                        checkInterval
                      );
                    }
                    break;
                  case 'SUCCEEDED':
                  case 'SUCCESS':
                    // data from Athena, transformed to a more 'flat' JS object
                    var procData = null;
                    if (respNeedsArray) {
                      procData = transformAthenaResponseDataFormatAsArray(
                        JSON.parse(result.data.value.report)
                      );
                    } else {
                      procData = transformAthenaResponseDataFormatAsFlatObject(
                        JSON.parse(result.data.value.report)
                      );
                    }
                    var len = 0;
                    // inspect response, see if only header row
                    try {
                      var rpt = JSON.parse(result.data.value.report);
                      len = rpt['ResultSet']['Rows'].length;
                    } catch (e) {
                      // don't worry, should be either RUNNING or ERROR
                    }
                    if (hardError) {
                      // a single row would just be the header field names
                      if (len < 2) {
                        reject('No data found for this query.');
                      }
                    }
                    resolve(procData);
                    break;
                  case 'ERROR':
                  default:
                    reject(result.data.value);
                    break;
                }
              })
              .catch(function(er) {
                // error
                reject(er);
              });
          }
        };

        // kick off the sequence
        initiateAthenaReportRequest(reportName, cfcCode, campaignId, body).then(
          function(response) {
            var reportExecutionId = response.data.value;
            return $q(function(resolve, reject) {
              checkReportById(reportExecutionId);
            });
          },
          function(err) {
            reject(err);
          }
        );
      });
    }

    /*
     * transforms values from their metadata's type, since all values come across as a string
     */
    function transformAthenaFieldValues(raw, type) {
      var proc = null;
      switch (type) {
        case 'double':
        case 'integer':
        case 'decimal':
          // all numeric can be parsed by $parse, $parse returns a function wrapper, using IIFE
          if ('0.00' === raw) {
            proc = 0;
          } else {
            proc = $parse(raw)();
          }
          break;
        case 'boolean':
          proc = true === raw.toLowerCase();
          break;
        case 'varchar':
        default:
          proc = raw;
          break;
      }
      // TODO: date?

      return proc;
    }

    /*
     * takes in raw Athena response, transforms to proper JS object
     * (flat, key: value pairs)
     */
    function transformAthenaResponseDataFormatAsFlatObject(raw) {
      var rows = raw['ResultSet']['Rows'];
      var len = rows.length;
      var processedData = {};

      // header (first) row becomes property names
      var headerRow = rows.slice(0);
      var headerProps = (headerRow[0] || {}).hasOwnProperty('Data')
        ? headerRow[0]['Data']
        : [];
      var propertyNames = [];
      // populate w/ headerRow's values, because this is like a horrific CSV
      angular.forEach(headerProps, function(prop) {
        propertyNames.push(prop['VarCharValue']);
      });

      // build type array from metadata set
      var metaDataRow = raw['ResultSet']['ResultSetMetadata']['ColumnInfo'];
      var fieldMetaAr = [];
      angular.forEach(metaDataRow, function(meta) {
        fieldMetaAr.push(meta['Type']);
      });

      // populate the data from all other rows (not metadata) and populate into flat format
      var rawData = rows.slice(1, len);
      // holds transformed array of flat objects
      angular.forEach(rawData, function(row) {
        // iterates rows
        var rowData = row['Data'];
        // iterate properties
        angular.forEach(rowData, function(prop, n) {
          var currentKey = propertyNames[n];
          var rawVal = prop['VarCharValue'];
          var nwVal = null;
          // auto-magic type checking/conversion?!
          if (!((currentKey || '').toLowerCase().indexOf('cfccode') >= 0)) {
            // ignore cfc code, should be a string
            nwVal = transformAthenaFieldValues(rawVal, fieldMetaAr[n]);
          } else {
            nwVal = rawVal;
          }
          processedData[currentKey] = nwVal;
        });
      });
      return processedData;
    }

    /*
     * takes in raw Athena response, transforms to proper JS object
     * (array, such as needing to traverse months of year, for disbursement data)
     */
    function transformAthenaResponseDataFormatAsArray(raw) {
      var rows = raw['ResultSet']['Rows'];
      var len = rows.length;
      var processedData = [];

      // header (first) row becomes property names
      var headerRow = rows.slice(0);
      var headerProps = (headerRow[0] || {}).hasOwnProperty('Data')
        ? headerRow[0]['Data']
        : [];
      var propertyNames = [];
      // populate w/ headerRow's values, because this is like a horrific CSV
      angular.forEach(headerProps, function(prop) {
        propertyNames.push(prop['VarCharValue']);
      });

      // build type array from metadata set
      var metaDataRow = raw['ResultSet']['ResultSetMetadata']['ColumnInfo'];
      var fieldMetaAr = [];
      angular.forEach(metaDataRow, function(meta) {
        fieldMetaAr.push(meta['Type']);
      });

      // populate the data from all other rows (not metadata) and populate into flat format
      var rawData = rows.slice(1, len);
      // holds transformed array of flat objects
      angular.forEach(rawData, function(row) {
        // iterates rows
        var rowData = row['Data'];
        var tmpOb = {};
        // iterate properties
        angular.forEach(rowData, function(prop, n) {
          var currentKey = propertyNames[n];
          var rawVal = prop['VarCharValue'];
          var nwVal = null;
          // auto-magic type checking/conversion?!
          if (!((currentKey || '').toLowerCase().indexOf('cfccode') >= 0)) {
            // ignore cfc code, should be a string
            nwVal = transformAthenaFieldValues(rawVal, fieldMetaAr[n]);
          } else {
            nwVal = rawVal;
          }
          tmpOb[currentKey] = nwVal;
        });
        processedData.push(tmpOb);
      });
      return processedData;
    }

    /*
     * returns a singular promise for a single year/row, by campaign ID
     *
     * if optional isFederation parameter is passed, it uses the boolean for whether to return
     * the combined fed + member donation data, or just the given CFC's donation data
     */
    function getCharitySummaryDonationInfoForPledgeYear(
      cfcCode,
      campaignId,
      isFederation
    ) {
      var isFed = undefined === isFederation ? false : isFederation;
      if (isFed) {
        return getFedMemberDonationInfoForPledgeYear(cfcCode, campaignId);
      } else {
        return abstractGetAthenaPledgeData(
          DonationsReportsDefn.CharitySummaryByYear,
          cfcCode,
          campaignId
        );
      }
    }

    /*
     * returns a promise of a single year's summary info for Members of a Federation
     */
    function getFedMemberDonationInfoForPledgeYear(cfcCode, campaignId) {
      return abstractGetAthenaPledgeData(
        DonationsReportsDefn.MemberSummaryByYear,
        cfcCode,
        campaignId
      );
    }

    /*
     * returns by-month summary of disbursements for a CFC in given year
     */
    function getCharityDisbursementAmountByMonthForPledgeYear(
      cfcCode,
      campaignId
    ) {
      // uses asArray param to ensure array of objects w/ month values, vs as flat object, for summary
      return abstractGetAthenaPledgeData(
        DonationsReportsDefn.CharityDisbursementByMonth,
        cfcCode,
        campaignId,
        {},
        true
      );
    }

    /*
     * checks for whether the given campaign year by id is visible per the date defined
     * in system management
     */
    function isDisplayDonorDataDateForCampaignId(
      campaignId,
      cfcCode,
      isAdminRole
    ) {
      var admin = isAdminRole || false;
      if (admin) {
        return $http.get(
          '/CfcCharity/api/private/charity/' +
            campaignId +
            '/afterDonationStartDate'
        );
      } else {
        return $http.get(
          '/CfcCharity/api/private/charity/' +
            cfcCode +
            '/' +
            campaignId +
            '/displayDonations'
        );
      }
    }

    /*
     * returns federation members with there donation balances for Campaign Year and CFC Code
     */
    function getFedMembersDonationBalances(cfcCode, campaignId, year) {
      return abstractGetAthenaPledgeData(
        DonationsReportsDefn.FedMembersDonationBalances,
        cfcCode,
        campaignId,
        { year: year },
        true
      );
    }

    /*
     * returns members of a parent fed's dibsursements for given year and month
     *
     * params: cfc, campaignId for given year, 4-digit year, month (int)
     */
    function getFedMembersDisbursementsByYearMonth(
      cfcCode,
      campaignId,
      year,
      month
    ) {
      return abstractGetAthenaPledgeData(
        DonationsReportsDefn.FedMembersDisbursementsByMonthYear,
        cfcCode,
        campaignId,
        { year: year, month: month },
        true
      );
    }

    return {
      getCharitySummaryDonationInfoForPledgeYear: getCharitySummaryDonationInfoForPledgeYear,
      getCharityDisbursementAmountByMonthForPledgeYear: getCharityDisbursementAmountByMonthForPledgeYear,
      getFedMemberDonationInfoForPledgeYear: getFedMemberDonationInfoForPledgeYear,
      isDisplayDonorDataDateForCampaignId: isDisplayDonorDataDateForCampaignId,
      getFedMembersDonationBalances: getFedMembersDonationBalances,
      getFedMembersDisbursementsByYearMonth: getFedMembersDisbursementsByYearMonth,
      initiateAthenaReportRequest: initiateAthenaReportRequest
    };
  }
})();
