(function() {
  angular
    .module('CfcCharity.common')
    .factory('notificationService', notificationService);

  notificationService.$inject = ['$rootScope'];

  function notificationService($rootScope) {
    return {
      subscribe: function(scope, callback) {
        var handler = $rootScope.$on('notifying-service-event', callback);
        scope.$on('$destroy', handler);
      },

      notify: function(message) {
        $rootScope.$emit('notifying-service-event', message);
      }
    };
  }
})();
