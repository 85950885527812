'use strict';

angular
  .module('CfcCharity.applicationDashboard')
  .controller('ChecklistCtrl', ChecklistCtrl);

ChecklistCtrl.$inject = [
  '$state',
  '$log',
  'BrowserEventService',
  'parameterData',
  'DashboardService',
  'AppStepsService'
];

function ChecklistCtrl(
  $state,
  $log,
  BrowserEventService,
  parameterData,
  DashboardService,
  AppStepsService
) {
  var vm = this;
  init();

  // for independent or member charity
  vm.goBackToApplicationDashboard = function() {
    $state.go('applicationDashboard', {
      applicationId: vm.appDetails.applicationId,
      pledgeYear: vm.appDetails.pledgeYear,
      cfcCode: vm.appDetails.cfcCode
    });
  };

  // for federation
  vm.goBackToFedApplicationDashboard = function() {
    $state.go('federationDashboard', {
      federationId: vm.appDetails.federationId,
      year: vm.appDetails.pledgeYear,
      cfcCode: vm.appDetails.cfcCode
    });
  };

  // continue to signature page
  vm.proceedToSignPage = function() {
    BrowserEventService.setPledgeYear(vm.appDetails.pledgeYear);
    if (vm.isFederation === true) {
      $state.go('signAndUnsignFederation', {
        applicationId: vm.appDetails.federationId, // named oddly in fed dashboard response
        pledgeYear: vm.appDetails.pledgeYear
      });
    } else {
      $state.go('signAndunsign', {
        applicationId: vm.appDetails.applicationId
      });
    }
  };

  // perform download of appropriate checklist
  vm.downloadChecklistPDF = function() {
    var checklistType;
    var fysaFyspWfr;
    // federation
    if (true === vm.isFederation) {
      checklistType = 'FEDERATION';
      fysaFyspWfr = false;
    } else {
      // independent or member
      checklistType = 'INDEPENDENT_OR_MEMBER';
      fysaFyspWfr = true === vm.appDetails.fysaFyspWfr;
    }
    AppStepsService.getChecklistPDF(checklistType, fysaFyspWfr);
  };

  // setup needed values
  function init() {
    vm.moduleName = 'CfcCharity.applicationDashboard';
    $log.debug('parameterData: ', parameterData);
    vm.appDetails = parameterData.applicationDetails;
    vm.isFederation = parameterData.isFederationPage;
    vm.isEnrollmentAvailable = DashboardService.getEnrollmentAvailable();
    vm.pledgeYear = parameterData.pledgeYear;
    vm.cfcCode = parameterData.applicationDetails.cfcCode;
    vm.isPageLoaded = false;

    if (true === vm.isFederation) {
      // federation
      AppStepsService.getFederationApplicationStatus(
        vm.pledgeYear,
        vm.cfcCode
      ).then(
        function(resp) {
          // success
          var data = resp.data.value;
          vm.fedAppCompleteStatus = data.federationApplicationCompleteStatus;
          vm.fedMemberAppsCompleteStatus =
            data.allMemberApplicationCompleteStatus;
          vm.isPageLoaded = true;
        },
        function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
        }
      );
    } else {
      // independent or member
      AppStepsService.getApplicationStatus(vm.appDetails.applicationId).then(
        function(resp) {
          // success
          var data = resp.data.value;
          vm.appCompleteStatus = data;
          vm.isPageLoaded = true;
        },
        function(err) {
          // error
          $log.error(
            err.data || 'Request failed with status ' + err.status,
            err
          );
        }
      );
    }
  }
}
