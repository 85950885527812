/*jshint loopfunc: true */
/*
  appSummaryByZoneCtrl--Controller for Application Summary All Zone By Year in the  Service/Admin center Page @Author Saju
*/

angular
  .module('CfcCharity.zonecenter', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('zoneCenter', {
        url: '/zoneCenter',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zoneCenter.html'),
            controller: 'ZoneCenterCtrl'
          }
        },
        data: {
          pageTitle: 'Zone Center',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          zoneId: null,
          zoneUserRole: null
        },
        resolve: {
          zoneCenterData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService
          ) {
            if ($stateParams.zoneId != null) {
              var zoneId = $stateParams.zoneId;
              var url = '/CfcCharity/api/private/admin/zone/' + zoneId;
              return $http.get(url).then(function(res) {
                BreadcrumbTokenDataService.setTokenData({
                  zoneId: zoneId,
                  zoneCode: res.data.zoneCode
                });
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('zoneApplicationStatus', {
        url: '/zoneApplicationStatus',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zoneStatus.html'),
            controller: 'ZoneApplicationStatusCtrl'
          }
        },
        data: {
          pageTitle: 'Zone Application Status',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          zoneId: null,
          pledgeYear: null,
          zoneUserRole: null,
          status: null,
          einVal: null
        },
        resolve: {
          zoneApplicationAvailableStatus: function(ZoneCenterService) {
            return ZoneCenterService.getApplicationStatuses();
          },
          availableApplicationZone: function(ZoneCenterService) {
            return ZoneCenterService.getZoneList();
          },
          availableCampaignPeriodList: function(ZoneCenterService) {
            return ZoneCenterService.getCampaignPeriodList();
          },
          activeZonesPerYear: function(ZoneCenterService) {
            return ZoneCenterService.getActiveZonesPerYear();
          }
        }
      });
      $stateProvider.state('zoneApplications', {
        url: '/zoneApplications',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zoneApplications.html'),
            controller: 'ZoneApplicationsCtrl'
          }
        },
        data: {
          pageTitle: 'Zone Applications',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          zoneId: null,
          pledgeYear: null,
          zoneUserRole: null
        },
        resolve: {
          zoneCenterData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService
          ) {
            if ($stateParams.zoneId != null) {
              var zoneId = $stateParams.zoneId;
              var url = '/CfcCharity/api/private/admin/zone/' + zoneId;
              return $http.get(url).then(function(res) {
                BreadcrumbTokenDataService.setTokenData({
                  zoneId: zoneId,
                  zoneCode: res.data.zoneCode
                });
                return res.data;
              });
            }
          },
          zoneApplicationsData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            BrowserEventService
          ) {
            if (
              $stateParams.zoneId != null ||
              BrowserEventService.getZoneId() != null
            ) {
              var zoneId;
              if ($stateParams.zoneId != null) {
                zoneId = $stateParams.zoneId;
              } else if (BrowserEventService.getZoneId() != null) {
                zoneId = BrowserEventService.getZoneId();
              }
              var pledgeYear =
                $stateParams.pledgeYear === null
                  ? BrowserEventService.getPledgeYear()
                  : $stateParams.pledgeYear;
              var url =
                '/CfcCharity/api/private/admin/zone/' +
                zoneId +
                '/' +
                pledgeYear;
              return $http.get(url).then(function(res) {
                BreadcrumbTokenDataService.setTokenData({
                  zoneId: zoneId,
                  pledgeYear: pledgeYear,
                  zoneCode: zoneId !== 0 ? res.data.zoneCode : 'ALL'
                });
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('zoneApplicationsReview', {
        url: '/zoneApplicationsReview',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zoneApplicationsReviewAll.html'),
            controller: 'ZoneApplicationsCtrl'
          }
        },
        data: {
          pageTitle: 'Zone Applications Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager
          ]
        },
        params: {
          zoneId: null,
          pledgeYear: null
        },
        resolve: {
          zoneApplicationsData: function($http, $stateParams) {
            if ($stateParams.zoneId != null) {
              var zoneId = $stateParams.zoneId;
              var pledgeYear = $stateParams.pledgeYear;
              var url =
                '/CfcCharity/api/private/admin/zonereview/' +
                zoneId +
                '/' +
                pledgeYear;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
      /*Review completeness route*/
      $stateProvider.state('reviewCompleteness', {
        url: '/reviewCompleteness',
        views: {
          main: {
            template: require('../views/private/zoneadmin/reviewCompleteness.html'),
            controller: 'CharityAuditDashboardCtrl'
          }
        },
        data: {
          breadcrumbs: function(ApplicationService) {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Completeness',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Completeness',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: 'Member Applications',
                  crumbRoute: 'memberapplication',
                  crumbParams:
                    '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          },
          pageTitle: 'Review Completeness',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager
          ]
        },
        params: {
          applicationId: null,
          reviewLevel: 'COMPLETENESS'
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            ApplicationDashboardService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants,
            ApplicationService
          ) {
            AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
              reviewLevelConstants.Completeness
            );
            if ($stateParams.applicationId != null) {
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                $stateParams.applicationId
              )
                .then(function(res) {
                  return ApplicationService.setApplication(res);
                })
                .catch(function(err) {
                  //failure for settokendata
                });
            }
          },
          applicationReview: function(CubeSvc, $stateParams) {
            var applicationId =
              $stateParams.applicationId ||
              ApplicationService.getApplicationId();
            var url =
              '/CfcCharity/api/private/application/review/getall/' +
              applicationId;
            return CubeSvc.get(url, null, false);
          }
        }
      });

      /*Review Preliminary eligibility route*/
      $stateProvider.state('preliminaryEligibility', {
        url: '/preliminaryEligibility',
        views: {
          main: {
            template: require('../views/private/zoneadmin/preliminaryEligibilityReview.html'),
            controller: 'CharityAuditDashboardCtrl'
          }
        },
        data: {
          breadcrumbs: function(ApplicationService) {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Preliminary Eligibility',
                  crumbRoute: 'zonereviewlevel2',
                  crumbParams: '{ zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Preliminary Eligibility',
                  crumbRoute: 'zonereviewlevel2',
                  crumbParams: '{ zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: 'Member Applications',
                  crumbRoute: 'memberapplication',
                  crumbParams:
                    '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          },
          pageTitle: 'Preliminary Eligibility',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager
          ]
        },
        params: {
          applicationId: null,
          reviewLevel: 'REVIEW_2'
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants,
            ApplicationService
          ) {
            if ($stateParams.applicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.PreliminaryEligibility
              );
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                $stateParams.applicationId
              )
                .then(function(res) {
                  return ApplicationService.setApplication(res);
                })
                .catch(function(err) {
                  //failure for settokendata
                });
            }
          },
          applicationReview: function(CubeSvc, $stateParams) {
            var applicationId =
              $stateParams.applicationId ||
              ApplicationService.getApplicationId();
            var url =
              '/CfcCharity/api/private/application/review/getall/' +
              applicationId;
            return CubeSvc.get(url, null, false);
          }
        }
      });

      /*Review Final Eligibility route*/
      $stateProvider.state('finalEligibility', {
        url: '/finalEligibility',
        views: {
          main: {
            template: require('../views/private/zoneadmin/finalEligibilityReview.html'),
            controller: 'CharityAuditDashboardCtrl'
          }
        },
        data: {
          breadcrumbs: function(ApplicationService) {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Final Eligibility',
                  crumbRoute: 'zonereviewlevel3',
                  crumbParams: '{ zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Final Eligibility',
                  crumbRoute: 'zonereviewlevel3',
                  crumbParams: '{ zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: 'Member Applications',
                  crumbRoute: 'memberapplication',
                  crumbParams:
                    '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          },
          pageTitle: 'Final Eligibility',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager
          ]
        },
        params: {
          applicationId: null,
          reviewLevel: 'REVIEW_3'
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants,
            ApplicationService
          ) {
            if ($stateParams.applicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.FinalEligibility
              );
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                $stateParams.applicationId
              )
                .then(function(res) {
                  return ApplicationService.setApplication(res);
                })
                .catch(function(err) {
                  //failure for settokendata
                });
            }
          },
          applicationReview: function(CubeSvc, $stateParams) {
            var applicationId =
              $stateParams.applicationId ||
              ApplicationService.getApplicationId();
            var url =
              '/CfcCharity/api/private/application/review/getall/' +
              applicationId;
            return CubeSvc.get(url, null, false);
          }
        }
      });

      /* Review Staff Eligibility*/
      $stateProvider.state('staffEligibility', {
        url: '/staffEligibility',
        views: {
          main: {
            template: require('../views/private/zoneadmin/staffEligibilityReview.html'),
            controller: 'CharityAuditDashboardCtrl'
          }
        },
        data: {
          breadcrumbs: function(ApplicationService) {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Staff Eligibility',
                  crumbRoute: 'zonereviewlevel1',
                  crumbParams: '{ zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'Zone Staff Eligibility',
                  crumbRoute: 'zonereviewlevel1',
                  crumbParams: '{ zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: 'Member Applications',
                  crumbRoute: 'memberapplication',
                  crumbParams:
                    '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                },
                {
                  crumbName: 'Application Review',
                  crumbRoute: 'charityAuditDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          },
          pageTitle: 'Staff Eligibility',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager
          ]
        },
        params: {
          applicationId: null,
          reviewLevel: null,
          userId: null
        },
        resolve: {
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            if ($stateParams.applicationId != null) {
              AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
                reviewLevelConstants.StaffEligibility
              );
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                $stateParams.applicationId
              )
                .then(function(res) {
                  return ApplicationService.setApplication(res);
                })
                .catch(function(err) {
                  //failure for settokendata
                });
            }
          },
          applicationReview: function(CubeSvc, $stateParams) {
            var applicationId =
              $stateParams.applicationId ||
              ApplicationService.getApplicationId();
            var url =
              '/CfcCharity/api/private/application/review/getall/' +
              applicationId;
            return CubeSvc.get(url, null, false);
          }
        }
      });

      /* Review All*/
      $stateProvider.state('reviewall', {
        url: '/reviewall',
        views: {
          main: {
            template: require('../views/private/zoneadmin/reviewAll.html'),
            controller: 'CharityAuditDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'Charity Application All Levels Overview',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager
          ]
        },
        params: {
          applicationId: null
        }
      });

      $stateProvider.state('charityAuditDashboard', {
        url: '/charityAuditDashboard',
        views: {
          main: {
            template: require('../views/private/zoneadmin/charityAuditDashboard.html'),
            controller: 'CharityAuditDashboardCtrl'
          }
        },
        data: {
          breadcrumbs: function(ApplicationService) {
            if (
              ApplicationService.getApplication().federationId === null ||
              ApplicationService.getApplication().federationNumber === null
            ) {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'reviewLevel',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Independent Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            } else {
              return [
                {
                  crumbName: '%zoneCode% Applications',
                  crumbRoute: 'zoneApplications',
                  crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
                },
                {
                  crumbName: 'reviewLevel',
                  crumbRoute: 'zonecompletenessreview',
                  crumbParams: '{pledgeYear: %pledgeYear%, zoneId: %zoneId%}'
                },
                {
                  crumbName: '%pledgeYear% #%federationCfc% Federation Package',
                  crumbRoute: 'federationapplication',
                  crumbParams:
                    '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                },
                {
                  crumbName: 'Member Applications',
                  crumbRoute: 'memberapplication',
                  crumbParams:
                    '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                },
                {
                  crumbName: '%pledgeYear% #%cfcCode% Member Application',
                  crumbRoute: 'applicationDashboard',
                  crumbParams: '{applicationId: %applicationId%}'
                }
              ];
            }
          },
          pageTitle: 'Charity Audit Dashboard',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          applicationId: null
        },
        resolve: {
          r: function(BreadcrumbTokenDataService, CubeSvc) {
            CubeSvc.setManualCacheRelease();
            BreadcrumbTokenDataService.setIsFederation(false);
            return {};
          },
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            ApplicationService
          ) {
            var applicationId =
              $stateParams.applicationId ||
              ApplicationService.getApplicationId();
            return BreadcrumbTokenDataService.getReviewLevel(
              applicationId,
              false
            ).then(function(res1) {
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              )
                .then(function(res) {
                  return ApplicationService.setApplication(res);
                })
                .catch(function(err) {
                  //failure for settokendata
                });
            });
          },
          applicationReview: function(CubeSvc, $stateParams) {
            var applicationId =
              $stateParams.applicationId ||
              ApplicationService.getApplicationId();
            var url =
              '/CfcCharity/api/private/application/review/getall/' +
              applicationId;
            return CubeSvc.get(url, null, false);
          }
        }
      });
      $stateProvider.state('singleZoneAppsByYear', {
        url: '/singleZoneAppsByYear',
        views: {
          main: {
            template: require('../views/private/zoneadmin/singleZoneApplicationsByYear.html'),
            controller: 'singleZoneAppsByYearCtrl'
          }
        },
        data: {
          pageTitle: 'Single Zone Application Reports',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          campaignId: null,
          zoneBean: null
        },
        resolve: {
          zoneAppsByYearData: function($http, $stateParams) {
            var campaignId = $stateParams.campaignId;
            var zoneId = $stateParams.zoneId;

            var url =
              '/CfcCharity/api/private/admin/zone/appsByZoneCnt/' +
              campaignId +
              '/' +
              zoneId;
            return $http.get(url).then(function(res) {
              return res.data;
            });
          }
        }
      });
      $stateProvider.state('appSummaryByZone', {
        url: '/appSummaryByZone',
        views: {
          main: {
            template: require('../views/private/zoneadmin/applicationSummaryByzone.html'),
            controller: 'appSummaryByZoneCtrl'
          }
        },
        data: {
          pageTitle: 'Application Summary By Zone',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          pledgeYear: null
        },
        resolve: {
          summaryzoneAppsByYearData: function($http, $stateParams) {
            var pledgeYear = $stateParams.pledgeYear;
            var url =
              '/CfcCharity/api/private/admin/zone/summaryByZoneYear/' +
              pledgeYear;
            return $http.get(url).then(function(res) {
              return res.data;
            });
          }
        }
      });
    }
  ])
  .constant('currentLevelType', {
    Completeness: 'COMPLETENESS',
    StaffEligibility: 'REVIEW_1',
    PreliminaryEligibility: 'REVIEW_2',
    FinalEligibility: 'REVIEW_3'
  })
  .filter('systemUserRoleFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, userRoleToFilter) {
        if (!dataArray) {
          return;
        } else if (!userRoleToFilter) {
          return dataArray;
        } else {
          return dataArray.filter(function(item) {
            var userRoleFound = false;

            for (var i = 0; i < userRoleToFilter.length; i++) {
              if (item && item.role && item.role === userRoleToFilter[i]) {
                userRoleFound = true;
                break;
              } // end if
            } // end for loop

            return userRoleFound;
          });
        }
      };
    }
  ])
  .filter('charityApplicationStatusReviewFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, charityApplicationStatusToFilter) {
        // If no array is given, exit.
        if (!dataArray) {
          return;
        }
        // If no search term exists, return the array unfiltered.
        else if (
          !charityApplicationStatusToFilter ||
          charityApplicationStatusToFilter === 'All'
        ) {
          return dataArray;
        }
        // Otherwise, continue.
        else {
          // Return the array and filter it by looking for any occurrences of the search term in each items id or name.
          return dataArray.filter(function(item) {
            var charityApplicationStatusFound = false;

            for (var i = 0; i < charityApplicationStatusToFilter.length; i++) {
              if (
                item &&
                item.applicationStatus &&
                item.applicationStatus === charityApplicationStatusToFilter[i]
              ) {
                charityApplicationStatusFound = true;
                break;
              } // end if
            } // end for loop

            return charityApplicationStatusFound;
          });
        }
      };
    }
  ])
  .service('ZoneCenterService', [
    '$http',
    '$q',
    '$state',
    'CubeSvc',
    'ApplicationService',
    'ApplicationDashboardService',
    'AppealService',
    function(
      $http,
      $q,
      $state,
      CubeSvc,
      ApplicationService,
      ApplicationDashboardService,
      AppealService
    ) {
      this.getZoneApplicationData = function(pledgeYear, zoneId) {
        var url =
          '/CfcCharity/api/private/admin/zonereview/' +
          zoneId +
          '/' +
          pledgeYear;
        return $http.get(url);
      };

      this.getUserById = function(userId) {
        var url = '/CfcCharity/api/private/users/' + userId;
        return $http.get(url);
      };

      this.getZoneApplicationDataselectYear = function(pledgeYear, zoneId) {
        var url =
          '/CfcCharity/api/private/admin/zone/' + zoneId + '/' + pledgeYear;
        return $http.get(url);
      };
      this.getZonesByUser = function(userId) {
        var url = '/CfcCharity/api/private/admin/zones/' + userId;
        return $http.get(url);
      };
      this.getZoneDetails = function(applicationId) {
        var url =
          '/CfcCharity/api/private/application/getZoneDetails?applicationId=' +
          applicationId;
        return $http.get(url);
      };
      this.getDODZoneDetails = function(applicationId) {
        var url =
          '/CfcCharity/api/private/charity/zoneListingDetails/' + applicationId;
        return $http.get(url);
      };
      this.updateCharityZone = function(applicationId, zoneId) {
        var url =
          '/CfcCharity/api/private/application/updateCharityZone/' +
          applicationId +
          '/' +
          zoneId;
        return $http.get(url);
      };
      this.updateCharityZoneListing = function(
        applicationId,
        zoneId,
        prevZoneId
      ) {
        var url =
          '/CfcCharity/api/private/application/updateCharityZoneListing/' +
          applicationId +
          '/' +
          zoneId;
        return $http.get(url);
      };
      this.overrideCharityListingZone = function(
        applicationId,
        overrideZoneid
      ) {
        var url =
          '/CfcCharity/api/private/charity/zoneforlisting/override/' +
          applicationId +
          '/' +
          overrideZoneid;
        return CubeSvc.post(url);
      };
      this.findApplicationStep2 = function(applicationId) {
        var url = '/CfcCharity/api/private/application/step2/' + applicationId;
        return $http.get(url);
      };
      this.getZoneActiveAdminUsers = function(zoneId, applicationId) {
        var url =
          '/CfcCharity/api/private/admin/zone/users/activeadmin/' +
          zoneId +
          '/' +
          applicationId;
        return $http.get(url);
      };
      this.getReviewerLevelData = function(applicationId) {
        var url =
          '/CfcCharity/api/private/application/review/reviewerassign/' +
          applicationId;
        return CubeSvc.get(url, null, false);
      };
      this.updateChangeReviewer = function(reviewer) {
        var url = '/CfcCharity/api/private/application/review/changereviewer';
        return CubeSvc.put(url, reviewer, false);
      };
      this.findCharityAccessEligibility = function(userId, cfcCode) {
        var url =
          '/CfcCharity/api/private/admin/findCharityAccessEligibility/' +
          userId +
          '/' +
          cfcCode;
        return $http.get(url);
      };
      this.checkAccess = function(fedId) {
        var url =
          '/CfcCharity/api/private/federation/application/access/' + fedId;
        return $http.get(url);
      };
      this.findApplicationStep9 = function(applicationId) {
        var url = '/CfcCharity/api/private/application/step9/' + applicationId;
        return CubeSvc.get(url);
      };
      this.updateApplicationStep9 = function(applicationId, applicationStep9) {
        var url = '/CfcCharity/api/private/application/step9/' + applicationId;
        return CubeSvc.put(url, applicationStep9);
      };
      this.findApplicationReview = function(applicationId) {
        var url = '/CfcCharity/api/private/application/review/' + applicationId;
        return CubeSvc.get(url, null, false);
      };
      this.updateApplicationReview = function(
        applicationId,
        type,
        applicationreview
      ) {
        var url =
          '/CfcCharity/api/private/application/review/' +
          applicationId +
          '/' +
          type;
        return CubeSvc.put(url, applicationreview, false);
      };
      this.getApplicationReviewLogs = function(applicationId) {
        var url =
          '/CfcCharity/api/private/application/review/comments/' +
          applicationId;
        return CubeSvc.get(url, null, false);
      };
      this.addReviewComment = function(applicationId, comment) {
        var url =
          '/CfcCharity/api/private/application/review/addcomment/' +
          applicationId;
        return CubeSvc.put(
          url,
          {
            comment: comment
          },
          false
        );
      };
      this.getSystemUserById = function(userId) {
        return $http.get('/CfcCharity/api/private/systemuser/' + userId);
      };

      this.findCharityApplication9 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/application/step9/' + applicationId
        );
      };

      this.addWarningNote = function(applicationId, application) {
        var url =
          '/CfcCharity/api/private/application/review/addWarningNote/' +
          applicationId;
        return $http.put(url, {
          warningNote: application.warningNote,
          approvedWithWarning: application.approvedWithWarning
        });
      };

      this.addDenialOverturnedWarningNote = function(
        applicationId,
        comment,
        application
      ) {
        var url =
          '/CfcCharity/api/private/application/review/addDenialOverturnedWarningNote/' +
          applicationId;
        return $http.put(url, {
          denialWarningNote: application.denialOverturnedWarningNote,
          denialOverturnedWithWarning: application.denialOverturnedWithWarning,
          appealComment: comment
        });
      };

      /*Set application step review status*/
      this.setStepReviewStatus = function(
        applicationId,
        step,
        reviewLevel,
        status
      ) {
        var url =
          '/CfcCharity/api/private/charity/review/step/' + applicationId;
        return CubeSvc.put(url, {
          step: step,
          reviewLevel: reviewLevel,
          status: status
        });
      };

      this.getYearsAndZonesActList = function() {
        var url = '/CfcCharity/api/private/admin/zone/yearsAndZonesAct';
        return $http.get(url);
      };
      this.getUserRoleByZone = function(zoneId) {
        var url = '/CfcCharity/api/private/getUserRoleByZone/' + zoneId;
        return $http.get(url);
      };
      this.getZoneAppdataUpdateResults = function(
        zoneId,
        selectedYear,
        status,
        ein,
        pageNumber,
        filteredWord
      ) {
        var url =
          '/CfcCharity/api/private/admin/getZoneApplicationUpdateResults/' +
          zoneId +
          '/' +
          selectedYear +
          '/' +
          status +
          '/' +
          ein +
          '/' +
          pageNumber;

        if (filteredWord !== undefined) {
          url = url + '?filteredWord=' + filteredWord;
        }
        return $http.get(url);
      };

      this.getApplicationStatuses = function() {
        var url = '/CfcCharity/api/private/admin/applicationstatuses';
        return $http.get(url);
      };
      this.getCampaignPeriodList = function() {
        var url = '/CfcCharity/api/private/admin/campaignPeriodList';
        return $http.get(url);
      };
      this.getZoneReviewDateDetails = function(zoneId) {
        var url =
          '/CfcCharity/api/private/admin/zone/reviewDateDetails/' + zoneId;
        return CubeSvc.get(url);
      };
      this.getZoneList = function() {
        var url = '/CfcCharity/api/private/admin/zoneList';
        return $http.get(url);
      };
      this.getActiveZonesPerYear = function() {
        var url = '/CfcCharity/api/private/admin/zoneActiveListPerYear';
        return $http.get(url);
      };
      this.downloadFileByReportURL = function(url) {
        var form = document.getElementById('downloadDataForm');
        var downloadURL = url;
        form.setAttribute('action', downloadURL);
        form.setAttribute('method', 'post');
        form.setAttribute('target', '_blank');
        form.submit();
      };
      this.goToCharityAuditDashboard = function(applicationId, userId) {
        ApplicationDashboardService.applicationDetailsById(applicationId).then(
          function(resp) {
            var res = resp.data.value;
            if (res.canAccess) {
              ApplicationService.setApplication(res);
              $state.go('charityAuditDashboard', {
                applicationId: applicationId
              });
            } else {
              $state.go('accessDenied', {
                userId: userId
              });
            }
          }
        );
      };
      this.updateZoneReviewStartDate = function(
        zoneId,
        reviewStartDate,
        pledgeYear
      ) {
        var url = '/CfcCharity/api/private/admin/zone/updateReviewStartDate';
        var zoneUpdateReviewStartDateBean = {
          zoneId: zoneId,
          reviewStartDate: reviewStartDate,
          pledgeYear: pledgeYear
        };
        return CubeSvc.put(url, zoneUpdateReviewStartDateBean);
      };
    }
  ])
  .controller('ZoneCenterCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'zoneCenterData',
    'BrowserEventService',
    'AdministrationCenterService',
    'ZoneCenterService',
    '$log',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      zoneCenterData,
      BrowserEventService,
      AdministrationCenterService,
      ZoneCenterService,
      $log
    ) {
      $scope.setModuleName('CfcCharity.zonecenter');
      $scope.userId = $scope.getCurrentUser().id;
      $scope.showAdminOnlyBlock = false;
      if (zoneCenterData != null && zoneCenterData !== 'undefined') {
        $scope.zoneCenterData = zoneCenterData;
        $scope.zoneCenterData.zoneUserRole = $state.params.zoneUserRole;
        BrowserEventService.setZoneCode($scope.zoneCenterData.zoneCode);
      }
      $scope.isManageUserVisible = true;
      $scope.isListingsAccess = true;
      $scope.isApplicationsAccess = true;
      ZoneCenterService.getUserRoleByZone($state.params.zoneId).then(function(
        res
      ) {
        var role = res.data;
        if (role !== null && role !== '') {
          if (
            role.secondLevelRoleList.length === 1 &&
            role.secondLevelRoleList[0] === 'REVIEWER'
          ) {
            $scope.isManageUserVisible = false;
          }
          if (
            role.secondLevelRoleList.indexOf('OPM_ADMIN') >= 0 ||
            role.secondLevelRoleList.indexOf('GB_ADMIN') >= 0 ||
            role.secondLevelRoleList.indexOf('OPM_AUDITOR') >= 0 ||
            role.secondLevelRoleList.indexOf('ZONE_MANAGER') >= 0 ||
            role.secondLevelRoleList.indexOf('REVIEWER') >= 0 ||
            role.secondLevelRoleList.indexOf('ZONE_COORDINATOR') >= 0
          ) {
            $scope.isListingsAccess = true;
            if (role.secondLevelRoleList.indexOf('ZONE_COORDINATOR') >= 0) {
              $scope.isApplicationsAccess = false;
            } else {
              $scope.isApplicationsAccess = true;
            }
          } else {
            $scope.isListingsAccess = false;
            $scope.isApplicationsAccess = false;
          }
          if (
            role.secondLevelRoleList.indexOf('OPM_ADMIN') >= 0 ||
            role.secondLevelRoleList.indexOf('OPM_STAFF') >= 0 ||
            role.secondLevelRoleList.indexOf('CUSTOMER_SERVICE') >= 0 ||
            role.secondLevelRoleList.indexOf('OPM_AUDITOR') >= 0
          ) {
            $scope.showAdminOnlyBlock = true;
          }
          if (role.secondLevelRoleList.indexOf('OPM_STAFF') >= 0) {
            $scope.isApplicationsAccess = true;
            if (
              $scope.zoneCenterData !== null &&
              $scope.zoneCenterData !== 'undefined'
            ) {
              if (
                $scope.zoneCenterData.zoneCode === 'INT' ||
                $scope.zoneCenterData.zoneCode === 'FED' ||
                $scope.zoneCenterData.zoneCode === 'NAT'
              ) {
                $scope.isListingsAccess = true;
              }
            }
          }
        }
      });
      $scope.gotoZoneApplications = function(pledgeYear, zoneId, zoneUserRole) {
        BrowserEventService.setPledgeYear(pledgeYear);
        BrowserEventService.setZoneId(zoneId);
        BrowserEventService.setZoneUserRole(zoneUserRole);
        $state.go('zoneApplications', {
          pledgeYear: pledgeYear,
          zoneId: zoneId,
          zoneUserRole: zoneUserRole
        });
      };
    }
  ])
  .controller('ZoneApplicationsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'zoneApplicationsData',
    'zoneCenterData',
    'BrowserEventService',
    'CharityService',
    'ZoneCenterService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      zoneApplicationsData,
      zoneCenterData,
      BrowserEventService,
      CharityService,
      ZoneCenterService
    ) {
      $scope.zoneCenterData = zoneCenterData;
      $scope.updatedReviewStartDate =
        zoneApplicationsData.currentReviewStartDate;
      if (zoneCenterData != null && zoneCenterData !== 'undefined') {
        $scope.zoneCenterData = zoneCenterData;
        $scope.zoneCenterData.zoneUserRole = $state.params.zoneUserRole;
        BrowserEventService.setZoneCode($scope.zoneCenterData.zoneCode);
      }
      $scope.setModuleName('CfcCharity.zoneapplications');
      $scope.userId = $scope.getCurrentUser().id;

      var pledgeYear = '';

      if ($state.params.pledgeYear) {
        pledgeYear = $state.params.pledgeYear;
      } else if (BrowserEventService.getPledgeYear()) {
        pledgeYear = BrowserEventService.getPledgeYear();
      }

      var zoneId = '';
      if ($state.params.zoneId >= 0) {
        zoneId = $state.params.zoneId;
      } else if (BrowserEventService.getZoneId()) {
        zoneId = BrowserEventService.getZoneId();
      }

      BrowserEventService.setPledgeYear(pledgeYear);
      BrowserEventService.setZoneId(zoneId);

      BrowserEventService.setZoneUserRole($state.params.zoneUserRole);
      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
      $scope.secondaryRoleList = secondaryRoleList;
      $scope.zoneUserRole = $state.params.zoneUserRole;

      $scope.gotoZoneCenter = function(zoneId, role) {
        BrowserEventService.setZoneUserRole(role);
        BrowserEventService.setZoneId(zoneId);
        $state.go('zoneCenter', {
          zoneId: zoneId,
          zoneUserRole: role
        });
      };

      // CCA-3308
      $scope.isApplicationPeriodAccessButtonDisabled = function() {
        // CCA-2977 move period closed check to review start open
        var isAfterAppPeriodClosed =
          $scope.zoneApplicationsData.afterApplicationPeriodReviewStart;
        var isZoneReviewerOrManager = false;
        if (
          secondaryRoleList.includes('ZONE_MANAGER') ||
          secondaryRoleList.includes('REVIEWER')
        ) {
          isZoneReviewerOrManager = true;
        } else {
          isZoneReviewerOrManager = false;
        }

        return isZoneReviewerOrManager && !isAfterAppPeriodClosed;
      };

      if (
        zoneApplicationsData != null &&
        zoneApplicationsData !== 'undefined'
      ) {
        $scope.zoneApplicationsData = zoneApplicationsData;
        if (zoneId === 0) {
          $scope.zoneApplicationsData.zoneId = 0;
          $scope.zoneApplicationsData.zoneCode = 'ALL';
          $scope.zoneApplicationsData.zoneName = 'All Zones';
        }
        $scope.zoneApplicationsData.zoneUserRole = $state.params.zoneUserRole;
        BrowserEventService.setZoneCode(zoneApplicationsData.zoneCode);
        if (
          zoneApplicationsData.zoneCode !== null &&
          (zoneApplicationsData.zoneCode === 'INT' ||
            zoneApplicationsData.zoneCode === 'NAT')
        ) {
          $scope.staffEligibilityVisible = true;
        } else {
          $scope.staffEligibilityVisible = false;
        }
        $scope.isApplicationChangeDateEnded =
          zoneApplicationsData.afterApplicationPeriodEndDate;
      }

      $scope.charityAdmin = function() {
        $state.go('administrationcenter', {
          userId: $scope.userId
        });
      };

      $scope.setCharityApplicationStatusCode = function(
        charityApplicationStatusCodes
      ) {
        $scope.selectedCharityApplicationStatusCodes = charityApplicationStatusCodes;
      };

      $scope.changePledgeYear = function(pledgeYear, zoneId) {
        $scope.zoneApplicationsData = {};
        ZoneCenterService.getZoneApplicationData(pledgeYear, zoneId).then(
          function(res) {
            var result = res.data;
            if (result !== null && result !== '') {
              $scope.zoneApplicationsData = result;
            }
          }
        );
      };
      $scope.findCharityByAdmin = function(cfcORein) {
        var userId = $scope.getCurrentUser().id;
        var charitiesObj = {};
        $scope.noResultsErrorMessage = '';
        CharityService.findCharityWithCFCByList(cfcORein).then(function(res) {
          var charity = res.data;
          if (charity !== null && charity.length !== 0) {
            var userRoles = $scope.userRoles;
            var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
            $scope.charities = charity;
            $state.go('charitySearchResults', {
              cfcCode: cfcORein,
              userId: userId,
              charitiesObj: charity
            });
          } else {
            CharityService.findCharityWithEIN(cfcORein).then(function(res) {
              var charities = res.data;
              if (charities !== null && charities.length !== 0) {
                $scope.charities = charities;
                $state.go('charitySearchResults', {
                  cfcCode: cfcORein,
                  userId: userId,
                  charitiesObj: charities
                });
              } else {
                $scope.noResultsErrorMessage = 'No Results Found';
              }
            });
          }
        });
      };
      $scope.saveReviewStartDate = function() {
        var reviewStartDate = new Date($scope.updatedReviewStartDate);
        var zoneId = $scope.zoneCenterData.zoneId;
        var pledgeYear = BrowserEventService.getPledgeYear();
        ZoneCenterService.updateZoneReviewStartDate(
          zoneId,
          reviewStartDate,
          pledgeYear
        )
          .then(function() {
            ZoneCenterService.getZoneReviewDateDetails(zoneId)
              .then(function(res) {
                var result = res.data.value;
                $scope.zoneApplicationsData.currentReviewStartDate =
                  result.reviewStartDate;
                $scope.zoneApplicationsData.reviewStartDateSetBy =
                  result.reviewStartDateCreatedBy;
                $scope.zoneApplicationsData.reviewStartDateCreatedDate =
                  result.reviewStartDateCreatedDate;
              })
              .catch(function(err) {
                $log.error(err);
              });
            $scope.zoneApplicationsData.currentReviewStartDate =
              $scope.updatedReviewStartDate;
            $scope.updateReviewStartDateModal.reviewStartDate.$pristine = true;
            $scope.updateReviewStartDateModal.reviewStartDate.$untouched = true;
          })
          .catch(function(error) {
            $log.error(error);
          });
        // reload page
        $state.reload();
      };
    }
  ])
  .controller('CharityAuditDashboardCtrl', [
    '$state',
    '$stateParams',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    'ZoneCenterService',
    'ApplicationDashboardService',
    'ApplicationService',
    'CharityApplicationStep9',
    'CharityDashboardService',
    'CharityService',
    'FederationApplicationService',
    'FederationDashboardService',
    'DownloadService',
    'BreadcrumbTokenDataService',
    'EmailService',
    'AppealService',
    '$log',
    'DenialReasonsService',
    'ValidationUtil',
    'CubeSvc',
    'currentLevelType',
    'ZoneService',
    'applicationReview',
    'CharityReviewService',
    '$window',
    function(
      $state,
      $stateParams,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      ZoneCenterService,
      ApplicationDashboardService,
      ApplicationService,
      CharityApplicationStep9,
      CharityDashboardService,
      CharityService,
      FederationApplicationService,
      FederationDashboardService,
      DownloadService,
      BreadcrumbTokenDataService,
      EmailService,
      AppealService,
      $log,
      DenialReasonsService,
      ValidationUtil,
      CubeSvc,
      currentLevelType,
      ZoneService,
      applicationReview,
      CharityReviewService,
      $window
    ) {
      $scope.setModuleName('CfcCharity.zonecenter');
      $scope.pledgeYear = BrowserEventService.getPledgeYear();
      $scope.zoneId = BrowserEventService.getZoneId();
      $scope.zoneUserRole = BrowserEventService.getZoneUserRole();
      $scope.zoneCode = BrowserEventService.getZoneCode();
      $scope.applicationId = $state.params.applicationId;
      $scope.applicationReview = applicationReview.data.value;

      var reviewLevel = $state.params.reviewLevel;

      $scope.reviewLevel = reviewLevel;
      $scope.determinationUpdateCounter = 0;

      if ($scope.applicationId) {
        BreadcrumbTokenDataService.setApplicationReviewTokenData(
          $scope.applicationId
        );
        BrowserEventService.setIndependentApplicationReviewApplicationId(
          $scope.applicationId
        );
      } else if (
        BrowserEventService.getIndependentApplicationReviewApplicationId()
      ) {
        $scope.applicationId = BrowserEventService.getIndependentApplicationReviewApplicationId();
        BreadcrumbTokenDataService.setApplicationReviewTokenData(
          $scope.applicationId
        );
      }

      var user = $scope.getCurrentUser();
      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
      $scope.isZoneManagerOrReviewer =
        secondaryRoleList.includes('ZONE_MANAGER') ||
        secondaryRoleList.includes('REVIEWER');
      var canEdit = true;

      $scope.hasWarningNoteAccess = false;
      if (
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('REVIEWER') >= 0 ||
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
        secondaryRoleList.indexOf('OPM_STAFF') >= 0
      ) {
        $scope.hasWarningNoteAccess = true;
      }

      if (
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
        (secondaryRoleList.indexOf('GB_ADMIN') >= 0 &&
          !(
            $scope.zoneCode === 'INT' ||
            $scope.zoneCode === 'FED' ||
            $scope.zoneCode === 'NAT'
          ))
      ) {
        canEdit = false;
      }

      $scope.isZoneReviewerOrManager = false;
      if (
        secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('REVIEWER') >= 0
      ) {
        $scope.isZoneReviewerOrManager = true;
      }

      $scope.isOpmStaff = secondaryRoleList.indexOf('OPM_STAFF') >= 0;

      $scope.isOpmAdmin = secondaryRoleList.indexOf('OPM_ADMIN') >= 0;

      // CCA-1692 editing application status only for OPM Admin role
      $scope.canEditApplicationStatus =
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0;

      $scope.isOpmAdminOrStaff = false;
      if (
        secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0
      ) {
        $scope.isOpmAdminOrStaff = true;
      }

      $scope.isOpmStaffOrAuditor = false;
      if (
        secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
      ) {
        $scope.isOpmStaffOrAuditor = true;
      }

      $scope.canEdit = canEdit;
      $scope.cfcDodZoneListing = $scope.applicationReview.cfcListingZone;
      $scope.cfcZone = $scope.applicationReview.cfcReviewZone;

      if (secondaryRoleList.indexOf('OPM_STAFF') >= 0) {
        $scope.zoneUserRole = 'OPM_STAFF';
      } else if (secondaryRoleList.indexOf('OPM_ADMIN') >= 0) {
        $scope.zoneUserRole = 'OPM_ADMIN';
      } else if (secondaryRoleList.indexOf('OPM_AUDITOR') >= 0) {
        $scope.zoneUserRole = 'OPM_AUDITOR';
      } else if (secondaryRoleList.indexOf('GB_ADMIN') >= 0) {
        $scope.zoneUserRole = 'GB_ADMIN';
      }

      $scope.applicationSubmissionStatus = true;
      $scope.application = $scope.applicationReview.application;
      var currentDate = ApplicationService.toUTCDate(new Date());
      if (
        $scope.application.applicationChangeEndDate &&
        currentDate > $scope.application.applicationChangeEndDate &&
        !$scope.application.startedAfterEnrollmentEnd
      ) {
        $scope.isApplicationChangeDateEnded = true;
      } else {
        $scope.isApplicationChangeDateEnded = false;
      }

      loadDenialReasons(
        $scope.application.cfcCode,
        $scope.application.pledgeYear,
        $scope.application.status
      );

      BrowserEventService.setCampaignId($scope.application.campaignId);
      $scope.userId = user.id;
      if (
        $scope.application.federationNumber !== null &&
        $scope.application.federationNumber !== ''
      ) {
        $scope.isMemberApplication = true;
      } else {
        $scope.isMemberApplication = false;
      }
      $scope.selectedReviewGroup =
        $scope.applicationReview.charityApplicationReview.reviewGroup;
      $scope.appStatusLastUpdatedDate = $scope.application.lastUpdatedDate;

      $scope.applicationStatus = '';
      ApplicationService.getApplicationStatus($scope.applicationId, function(
        data
      ) {
        //revenue mismatch check
        CubeSvc.get(
          '/CfcCharity/api/private/application/revenuedetails/' +
            $scope.applicationId,
          null,
          false
        ).then(function(res) {
          $scope.validRevenue = res.data.value;

          angular.forEach(data, function(part, index, theArray) {
            if (part.stepNo === '7' || part.stepNo === '9') {
              data[index].validRevenue = $scope.validRevenue;
              if (!$scope.validRevenue) {
                data[index].stepStatus = 'Incomplete';
              }
            }
            if ($scope.applicationSubmissionStatus) {
              if (part.stepStatus === 'Incomplete') {
                $scope.applicationSubmissionStatus = false;
              }
            }
            if (part.completenessSetBy) {
              ZoneCenterService.getUserById(part.completenessSetBy).then(
                function(res) {
                  var user = res.data;
                  data[index].completenessSetByFN = user.firstName;
                  data[index].completenessSetByLN = user.lastName;
                }
              );
            }
            if (part.preliminarySetBy) {
              ZoneCenterService.getUserById(part.preliminarySetBy).then(
                function(res) {
                  var user = res.data;
                  data[index].preliminarySetByFN = user.firstName;
                  data[index].preliminarySetByLN = user.lastName;
                }
              );
            }
            if (part.staffSetBy) {
              ZoneCenterService.getUserById(part.staffSetBy).then(function(
                res
              ) {
                var user = res.data;
                data[index].staffSetByFN = user.firstName;
                data[index].staffSetByLN = user.lastName;
              });
            }
            if (part.finalSetBy) {
              ZoneCenterService.getUserById(part.finalSetBy).then(function(
                res
              ) {
                var user = res.data;
                data[index].finalSetByFN = user.firstName;
                data[index].finalSetByLN = user.lastName;
              });
            }
          });
        });
        $scope.applicationStatus = data;
      });

      $scope.downloadChecklist = function(zoneCode) {
        var fileUrl =
          '/CfcCharity/api/private/application/review/checklist_pdf?zoneCode=' +
          zoneCode;
        return DownloadService.getFileDownload(fileUrl);
      };

      /* set step review status */
      $scope.setStepReviewLevelStatus = function(
        applicationId,
        step,
        reviewLevel,
        status
      ) {
        ZoneCenterService.setStepReviewStatus(
          applicationId,
          'Step' + step,
          reviewLevel,
          status
        )
          .then(function(res) {
            var res = res.data.value;
            /*update current binding*/
            angular.forEach($scope.applicationStatus, function(
              part,
              index,
              theArray
            ) {
              if (part.stepNo === step) {
                if (reviewLevel === 'completenessReviewStatus') {
                  $scope.applicationStatus[index].completenessReviewStatus =
                    res.completenessReviewStatus;
                  $scope.applicationStatus[index].completenessSetBy =
                    res.completenessSetBy;
                  $scope.applicationStatus[index].completenessSetByDate =
                    res.completenessSetByDate;

                  ZoneCenterService.getUserById(res.completenessSetBy).then(
                    function(res) {
                      var user = res.data;
                      $scope.applicationStatus[index].completenessSetByFN =
                        user.firstName;
                      $scope.applicationStatus[index].completenessSetByLN =
                        user.lastName;
                    }
                  );
                } else if (reviewLevel === 'preliminaryReviewStatus') {
                  $scope.applicationStatus[index].preliminaryReviewStatus =
                    res.preliminaryReviewStatus;
                  $scope.applicationStatus[index].preliminarySetBy =
                    res.preliminarySetBy;
                  $scope.applicationStatus[index].preliminarySetByDate =
                    res.preliminarySetByDate;

                  ZoneCenterService.getUserById(res.preliminarySetBy).then(
                    function(res) {
                      var user = res.data;
                      $scope.applicationStatus[index].preliminarySetByFN =
                        user.firstName;
                      $scope.applicationStatus[index].preliminarySetByLN =
                        user.lastName;
                    }
                  );
                } else if (reviewLevel === 'staffReviewStatus') {
                  $scope.applicationStatus[index].staffReviewStatus =
                    res.staffReviewStatus;
                  $scope.applicationStatus[index].staffSetBy = res.staffSetBy;
                  $scope.applicationStatus[index].staffSetByDate =
                    res.staffSetByDate;

                  ZoneCenterService.getUserById(res.staffSetBy).then(function(
                    res
                  ) {
                    var user = res.data;
                    $scope.applicationStatus[index].staffSetByFN =
                      user.firstName;
                    $scope.applicationStatus[index].staffSetByLN =
                      user.lastName;
                  });
                } else if (reviewLevel === 'finalReviewStatus') {
                  $scope.applicationStatus[index].finalReviewStatus =
                    res.finalReviewStatus;
                  $scope.applicationStatus[index].finalSetBy = res.finalSetBy;
                  $scope.applicationStatus[index].finalSetByDate =
                    res.finalSetByDate;

                  ZoneCenterService.getUserById(res.finalSetBy).then(function(
                    res
                  ) {
                    var user = res.data;
                    $scope.applicationStatus[index].finalSetByFN =
                      user.firstName;
                    $scope.applicationStatus[index].finalSetByLN =
                      user.lastName;
                  });
                }
              }
            });
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      /*Get Charity contact information */
      CharityDashboardService.findCharityWithCFC($scope.application.cfcCode)
        .then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.charityInformation = result;
          }
        })
        .catch(function(err) {
          $log.error(err);
        });

      $scope.gotoApplicationStepPage = function(
        application,
        isEnrollmentAvailable,
        stepPage,
        charityAuthority
      ) {
        if (
          charityAuthority &&
          (application.status === 'IN_PROGRESS' ||
            application.status === 'MORE_INFO_UNSIGNED')
        ) {
          gotoStepPageEditable(application, isEnrollmentAvailable, stepPage);
        } else if (
          application.status !== 'WITHDRAWN' &&
          application.status !== 'DISBANDED'
        ) {
          var isSignedOrPaid =
            'SIGNED' === application.status ||
            'SUBMITTED' === application.status;
          var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
          var isUserCharityRole =
            Array.isArray(userObj.roles) &&
            userObj.roles.includes('CHARITY_USER');
          if (
            isEnrollmentAvailable &&
            isSignedOrPaid &&
            isUserCharityRole &&
            !$scope.isApplicationChangeDateEnded
          ) {
            gotoStepPageEditable(application, isEnrollmentAvailable, stepPage);
          } else {
            gotoStepPageReadOnly(application, stepPage);
          }
        }
      };

      var gotoStepPageEditable = function(
        application,
        isEnrollmentAvailable,
        stepPage
      ) {
        ApplicationService.reset();
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        if (
          !isEnrollmentAvailable &&
          status !== 'MORE_INFO_UNSIGNED' &&
          !application.startedAfterEnrollmentEnd
        ) {
          $scope.errorMsg =
            'The Charity Application Period is currently Closed';
        } else {
          $scope.appNavbar.pledgeYear = pledgeYear;
          $scope.appNavbar.cfcCode = cfcCode;
          $scope.appNavbar.fysa = fysa;
          var charity = $scope.charityInformation;
          $scope.appNavbar.charityName = charity.charityName;

          if (stepPage === 'step4') {
            $scope.applicationStep2 =
              $scope.applicationReview.charityApplicationStep2;
          }
          ApplicationService.setCfcCode(cfcCode);
          ApplicationService.setPledgeYear(pledgeYear);
          $scope.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode)
            .then(function(res) {
              var role = res.data;
              var userSession = ApplicationService.getUserSession();
              userSession.cfcCode = cfcCode;
              userSession.applicationId = applicationId;
              userSession.userRole = role.userRole;
              userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                new Date()
              );
              userSession.$save(function(response) {
                ApplicationService.userSession = response;
              });
            })
            .catch(function(err) {
              $log.error(err);
            });
          CharityService.getPreApplicationDetails(cfcCode, pledgeYear)
            .then(function(res) {
              var data = res.data.value;
              ApplicationService.setPreApplicationDetails(data);
              ApplicationService.editApplication(applicationId);
              $state.go(stepPage, {
                mode: 'EDIT'
              });
              $scope.setModuleName('CfcCharity.application');
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
      };
      var gotoStepPageReadOnly = function(application, stepPage) {
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        $scope.appNavbar.pledgeYear = pledgeYear;
        $scope.appNavbar.cfcCode = cfcCode;
        $scope.appNavbar.fysa = fysa;
        var charity = $scope.charityInformation;
        $scope.appNavbar.charityName = charity.charityName;
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setPledgeYear(pledgeYear);
        $scope.appVisibility.viewMode = 'VIEW';
        CharityService.getPreApplicationDetails(cfcCode, pledgeYear)
          .then(function(res) {
            var data = res.data.value;
            ApplicationService.setPreApplicationDetails(data);
            ApplicationService.editApplication(applicationId);
            $state.go(stepPage, {
              mode: 'VIEW'
            });
            $scope.setModuleName('CfcCharity.application');
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      var emailHistory = [];
      var keepGoing = true;
      $scope.emailHistoryResponse = $scope.applicationReview.emailActivity;
      angular.forEach($scope.emailHistoryResponse, function(arrList) {
        if (keepGoing) {
          if (arrList.status.toLowerCase() === 'sent') {
            emailHistory.push({
              canDelete: arrList.canDelete,
              canEdit: arrList.canEdit,
              emailType: arrList.emailType,
              id: arrList.id,
              sendDate: arrList.sendDate,
              sentBy: arrList.sentBy,
              status: arrList.status,
              statusAtSend: arrList.statusAtSend
            });
            $scope.emailHistory = emailHistory;
            keepGoing = false;
          }
        }
      });

      /* Show contact information modal */
      $scope.showContactInformation = function() {
        $('#contactInfo').modal('toggle');
      };

      /* Get enrollment status */
      CharityService.findEnrollmentStatus()
        .then(function(res) {
          var enrollmentStatus = res.data;
          $scope.isEnrollmentAvailable = enrollmentStatus;
        })
        .catch(function(err) {
          $log.error(err);
        });

      CharityService.getCharityUserRole($scope.application.cfcCode)
        .then(function(res) {
          var userRole = res.data;
          if (userRole) {
            $scope.userCharityRole = userRole;
          } else {
            $scope.charityAuthority = false;
            if ($scope.application.federationNumber) {
              FederationDashboardService.getFederationApplication(
                $scope.application.federationNumber
              ).then(function(res) {
                var federation = res.data.value;
                if (federation) {
                  CharityService.getCharityUserRole(federation.cfcCode).then(
                    function(res) {
                      var cRole = res.data;
                      if (cRole) {
                        $scope.charityAuthority = true;
                      } else {
                        $scope.charityAuthority = false;
                      }
                    }
                  );
                }
              });
            }
          }
        })
        .catch(function(err) {
          $log.error(err);
        })
        .finally(function() {
          CubeSvc.performManualCacheRelease();
        });

      /* Go to step page */
      $scope.gotoApplicationPage = function(stepPage) {
        if (
          $scope.charityAuthority &&
          ($scope.application.status === 'IN_PROGRESS' ||
            $scope.application.status === 'MORE_INFO_UNSIGNED')
        ) {
          gotoStepPage1(
            $scope.application,
            $scope.isEnrollmentAvailable,
            stepPage
          );
        } else if (
          $scope.application.status !== 'WITHDRAWN' &&
          $scope.application.status !== 'DISBANDED'
        ) {
          gotoStepPage2($scope.application, stepPage);
        }
      };

      /* Go to step page 1 */
      var gotoStepPage1 = function(
        application,
        isEnrollmentAvailable,
        stepPage
      ) {
        ApplicationService.reset();
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        if (!isEnrollmentAvailable && status !== 'MORE_INFO_UNSIGNED') {
          $scope.errorMsg =
            'The Charity Application Period is currently Closed';
        } else {
          $scope.appNavbar.pledgeYear = pledgeYear;
          $scope.appNavbar.cfcCode = cfcCode;
          $scope.appNavbar.fysa = fysa;
          CharityService.findCharityWithCFC(application.cfcCode).then(function(
            res
          ) {
            var charity = res.data;
            $scope.appNavbar.charityName = charity.charityName;
          });
          ApplicationService.setCfcCode(cfcCode);
          $scope.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode)
            .then(function(res) {
              var role = res.data;
              var userSession = ApplicationService.getUserSession();
              userSession.cfcCode = cfcCode;
              //userSession.userId = $scope.getCurrentUser().id;
              userSession.applicationId = applicationId;
              userSession.userRole = role.userRole;
              userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                new Date()
              );
              userSession.$save(function(response) {
                ApplicationService.userSession = response;
              });
            })
            .catch(function(err) {
              $log.error(err);
            });
          var currentPledgeYear = sessionStorage.getItem(
            'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
          );
          CharityService.getPreApplicationDetails(cfcCode, currentPledgeYear)
            .then(function(res) {
              var data = res.data.value;
              ApplicationService.setPreApplicationDetails(data);
              ApplicationService.editApplication(applicationId);
              $state.go(stepPage, {
                mode: 'EDIT'
              });
              $scope.setModuleName('CfcCharity.application');
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
      };
      /* go to step page 2*/
      var gotoStepPage2 = function(application, stepPage) {
        var applicationId = application.applicationId;
        var status = application.status;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        var fysa = application.fysaFyspWfr;
        $scope.appNavbar.pledgeYear = pledgeYear;
        $scope.appNavbar.cfcCode = cfcCode;
        $scope.appNavbar.fysa = fysa;
        CharityService.findCharityWithCFC(application.cfcCode)
          .then(function(res) {
            var charity = res.data;
            $scope.appNavbar.charityName = charity.charityName;
          })
          .catch(function(err) {
            $log.error(err);
          });
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setPledgeYear(pledgeYear);
        $scope.appVisibility.viewMode = 'VIEW';
        var currentPledgeYear = sessionStorage.getItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
        );
        CharityService.getPreApplicationDetails(cfcCode, currentPledgeYear)
          .then(function(res) {
            var data = res.data.value;
            ApplicationService.setPreApplicationDetails(data);
            ApplicationService.editApplication(applicationId);
            $state.go(stepPage, {
              mode: 'VIEW'
            });
            $scope.setModuleName('CfcCharity.application');
          })
          .catch(function(err) {
            $log.error(err);
          });
      }; /* End of go to step page 2*/

      var reviewerLevelData =
        $scope.applicationReview.charityApplicationReviewerAssign;
      if (reviewerLevelData != null && reviewerLevelData !== 'undefined') {
        $scope.reviewerLevelDataList = reviewerLevelData;

        angular.forEach(reviewerLevelData, function(obj) {
          if ($scope.applicationId.toString() === obj.applicationId) {
            if ('Completeness' === obj.reviewLevelType) {
              $scope.completenessReviewer = obj;
            } else if (
              'Review 1' === obj.reviewLevelType ||
              'Staff Eligibility' === obj.reviewLevelType
            ) {
              $scope.review1Reviewer = obj;
            } else if (
              'Review 2' === obj.reviewLevelType ||
              'Preliminary Eligibility' === obj.reviewLevelType
            ) {
              $scope.review2Reviewer = obj;
            } else if (
              'Review 3' === obj.reviewLevelType ||
              'Final Eligibility' === obj.reviewLevelType
            ) {
              $scope.review3Reviewer = obj;
            }
          }
        });
      }

      function setDeterminationStatus(reviewStatus) {
        if (reviewStatus === 'PASS') {
          return 'Pass';
        } else if (reviewStatus === 'FAIL') {
          return 'Fail';
        } else if (reviewStatus === 'NOT_SET') {
          return 'Not Set';
        }
      }

      var type = null;
      if ($scope.isMemberApplication) {
        type = 'MEMBER';
      } else {
        type = 'INDEPENDENT';
      }
      $scope.appType = type;
      $scope.appealData = $scope.applicationReview.charityAppeal;
      if ($scope.appealData !== null || $scope.appealData !== undefined) {
        $scope.appealStatusOriginalValue = null;
      } else {
        $scope.appealStatusOriginalValue = $scope.appealData.status;
      }
      $scope.appealStatusUpdateReason = null;

      $scope.updateApplicationAppeal = function() {
        AppealService.UpdateApplicationAppealStaus(
          $scope.appealData.appealId,
          $scope.appealData.status,
          $scope.appealStatusUpdateReason
        )
          .then(function(res) {
            var status = res.data.appealStatus.toUpperCase();
            $scope.appealStatusOriginalValue = null;
            $scope.appealStatusUpdateReason = null;
            switch (status) {
              case 'PENDING':
                status = 'DENIED';
                break;
              case 'IN_REVIEW':
                status = 'IN_APPEAL';
                break;
            }
            $scope.updateApplicationReview(status, $scope.review);

            AppealService.sendAppealStatusUpdateEmail(
              $scope.application.cfcCode,
              $scope.application.campaignId,
              $scope.application.applicationId,
              $scope.applicationStep2.applicationType,
              $scope.appealData.status,
              $scope.application.denialOverturnedWithWarning
            );
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.cancelUpdateApplicationAppeal = function() {
        if ($scope.previousStatus) {
          $scope.appealData.status = $scope.previousStatus;
        }
        $('#charityAppealStatus').modal('hide');
      };

      $('#charityAppealStatus').on('show.bs.modal', function(e) {
        if ($scope.appealData && $scope.appealData.status) {
          $scope.previousStatus = $scope.appealData.status;
        }
      });

      $('#charityAppealStatus').on('hidden.bs.modal', function(e) {
        $scope.previousStatus = null;
      });

      $scope.review = $scope.applicationReview.charityApplicationReview;
      if ($scope.review !== null && $scope.review !== '') {
        $scope.reviewStatus = $scope.review.applicationReviewStatus;
        $scope.reviewerFundRate = $scope.review.reviewerFundRate;
        $scope.tempReviewerFundRate = $scope.review.reviewerFundRate;
        $scope.reviewerTotalRevenue = $scope.review.reviewerTotalRevenue;
        $scope.tempReviewerTotalRevenue = $scope.review.reviewerTotalRevenue;

        if ($scope.application.lastUpdatedBy != null) {
          ZoneCenterService.getSystemUserById($scope.application.lastUpdatedBy)
            .then(function(res) {
              var user = res.data;
              if (user !== null && user !== '') {
                $scope.userDetails = user;
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
        if ($scope.review.completenessStatusUpdatedBy != null) {
          ZoneCenterService.getSystemUserById(
            $scope.review.completenessStatusUpdatedBy
          )
            .then(function(res) {
              var user = res.data;
              if (user !== null && user !== '') {
                $scope.completenessStatusUpdatedBy = user;
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
        if ($scope.review.review1StatusUpdatedBy != null) {
          ZoneCenterService.getSystemUserById(
            $scope.review.review1StatusUpdatedBy
          )
            .then(function(res) {
              var user = res.data;
              if (user !== null && user !== '') {
                $scope.review1StatusUpdatedBy = user;
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
        if ($scope.review.review2StatusUpdatedBy != null) {
          ZoneCenterService.getSystemUserById(
            $scope.review.review2StatusUpdatedBy
          )
            .then(function(res) {
              var user = res.data;
              if (user !== null && user !== '') {
                $scope.review2StatusUpdatedBy = user;
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
        if ($scope.review.review3StatusUpdatedBy != null) {
          ZoneCenterService.getSystemUserById(
            $scope.review.review3StatusUpdatedBy
          )
            .then(function(res) {
              var user = res.data;
              if (user !== null && user !== '') {
                $scope.review3StatusUpdatedBy = user;
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
        if ($scope.review.completenessReviewStatus == null) {
          $scope.review.completenessReviewStatus = 'NOT_SET';
        }
        if ($scope.review.review1Status == null) {
          $scope.review.review1Status = 'NOT_SET';
        }
        if ($scope.review.review2Status == null) {
          $scope.review.review2Status = 'NOT_SET';
        }
        if ($scope.review.review3Status == null) {
          $scope.review.review3Status = 'NOT_SET';
        }

        if ($scope.reviewLevel != null) {
          if ($scope.reviewLevel === 'REVIEW_2') {
            $scope.determinationStatus = setDeterminationStatus(
              $scope.review.review2Status
            );
          } else if (
            $scope.reviewLevel === 'REVIEW_1' ||
            $scope.reviewLevel === 'STAFF'
          ) {
            $scope.determinationStatus = setDeterminationStatus(
              $scope.review.review1Status
            );
          } else if ($scope.reviewLevel === 'REVIEW_3') {
            $scope.determinationStatus = setDeterminationStatus(
              $scope.review.review3Status
            );
          } else if ($scope.reviewLevel === 'COMPLETENESS') {
            $scope.determinationStatus = setDeterminationStatus(
              $scope.review.completenessReviewStatus
            );
          }
        }
      } else {
        $scope.review = {};
        $scope.review.applicationId = applicationId;
        $scope.review.applicationReviewStatus = 'IN_REVIEW';
        $scope.review.currentLevel = 'COMPLETENESS';
        $scope.review.completenessReviewStatus = 'NOT_SET';
        $scope.review.review1Status = 'NOT_SET';
        $scope.review.review2Status = 'NOT_SET';
        $scope.review.review3Status = 'NOT_SET';
        $scope.determinationStatus = 'Not Set';
      }
      setReviewLevels();
      $scope.reviewLogs = $scope.applicationReview.charityApplicationReviewLog;

      function setReviewLevels() {
        $scope.applicationStep2 =
          $scope.applicationReview.charityApplicationStep2;
        if (
          $scope.applicationStep2.fysaFyspWfr === 1 ||
          $scope.applicationStep2.fysaFyspWfr === true
        ) {
          $scope.charityType = 'DOD';
        } else if (
          $scope.applicationStep2.fysaFyspWfr == null ||
          $scope.applicationStep2.fysaFyspWfr === 0 ||
          $scope.applicationStep2.fysaFyspWfr === false
        ) {
          $scope.charityType = $scope.applicationStep2.charityType;
        }
        $scope.canOverrideListingZone =
          $scope.charityType === 'NATIONAL' ||
          $scope.charityType === 'INTERNATIONAL'
            ? false
            : true;
        getZones();

        $scope.reviewLevels = [];
        var currentLevel = $scope.review.currentLevel;

        // completeness
        $scope.reviewLevels.push({
          display: 'Completeness',
          value: currentLevelType.Completeness,
          isCurrentReviewLevel: currentLevel === currentLevelType.Completeness
        });

        // staff
        var tmpCharityType = ($scope.charityType || '').toLocaleLowerCase();
        if (
          tmpCharityType === 'national' ||
          tmpCharityType === 'international'
        ) {
          $scope.reviewLevels.push({
            display: 'Staff Eligibility',
            value: currentLevelType.StaffEligibility,
            isCurrentReviewLevel:
              currentLevel === currentLevelType.StaffEligibility
          });
        }

        // preliminary
        if ($scope.cfcZone.zoneCode !== 'DOD') {
          $scope.reviewLevels.push({
            display: 'Preliminary Eligibility',
            value: currentLevelType.PreliminaryEligibility,
            isCurrentReviewLevel:
              currentLevel === currentLevelType.PreliminaryEligibility
          });
        }

        // final
        $scope.reviewLevels.push({
          display: 'Final Eligibility',
          value: currentLevelType.FinalEligibility,
          isCurrentReviewLevel:
            currentLevel === currentLevelType.FinalEligibility ||
            currentLevel.toLowerCase() === 'approved' ||
            currentLevel.toLowerCase() === 'in_appeal' ||
            currentLevel.toLowerCase() === 'denied'
        });
      }

      $scope.viewPopup = function(popupId) {
        $('#' + popupId).modal();
      };
      $scope.populateZoneModel = function(popupId, year) {
        ZoneCenterService.getYearsAndZonesActList().then(function(res) {
          var data = res.data;
          if (data != null && data !== 'undefined') {
            $scope.yearsAndZonesActList = data;
          }
          $scope.zoneBeanList = [];
          angular.forEach($scope.yearsAndZonesActList.zoneBeanList, function(
            czData
          ) {
            angular.forEach(czData.pledgeYear, function(cz) {
              if (cz.toString() === year.toString()) {
                $scope.zoneBeanList.push(czData);
              }
            });
          });
          var zoneId = $scope.cfcZone.zoneId;
          var zones = {};
          zones.selectedZone = String(zoneId);
          $scope.zones = zones;
          $('#' + popupId).modal();
        });
      };
      $scope.populateZoneListingModel = function(popupId, year) {
        ZoneCenterService.getYearsAndZonesActList().then(function(res) {
          var data = res.data;
          if (data != null && data !== 'undefined') {
            $scope.yearsAndZonesActList = data;
          }
          $scope.zoneBeanList = [];
          angular.forEach($scope.yearsAndZonesActList.zoneBeanList, function(
            czData
          ) {
            angular.forEach(czData.pledgeYear, function(cz) {
              if (cz.toString() === year.toString()) {
                $scope.zoneBeanList.push(czData);
              }
            });
          });
          var zoneId = $scope.cfcDodZoneListing.zoneId;
          var campaignYear = {};
          var zonesListing = {};
          zonesListing.selectedZone = String(zoneId);
          $scope.zonesListing = zonesListing;
          $('#' + popupId).modal();
        });
      };
      $scope.populateChangeReviewer = function(reviewerType) {
        if ($scope.canEdit === false) {
          return;
        }

        if (
          $scope.zoneActiveUserList == null ||
          $scope.zoneActiveUserList === 'undefined'
        ) {
          ZoneCenterService.getZoneActiveAdminUsers(
            $scope.cfcZone.zoneId,
            $scope.applicationId
          )
            .then(function(res) {
              var zoneActiveUsersData = res.data;
              if (
                zoneActiveUsersData != null &&
                zoneActiveUsersData !== 'undefined'
              ) {
                $scope.zoneActiveUserList =
                  zoneActiveUsersData.activeSystemUserBeanList;
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }

        $scope.selectedReviewer = null;
        $scope.selectedAssigner = {};
        $scope.reviewerType = reviewerType;
        if (reviewerType === 'Completeness') {
          $scope.selectedReviewer = $scope.completenessReviewer;
        } else if (
          reviewerType === 'Review 1' ||
          'Staff Eligibility' === reviewerType
        ) {
          $scope.selectedReviewer = $scope.review1Reviewer;
        } else if (
          reviewerType === 'Review 2' ||
          'Preliminary Eligibility' === reviewerType
        ) {
          $scope.selectedReviewer = $scope.review2Reviewer;
        } else if (
          reviewerType === 'Review 3' ||
          'Final Eligibility' === reviewerType
        ) {
          $scope.selectedReviewer = $scope.review3Reviewer;
        }
        if ($scope.selectedReviewer != null) {
          var selectedAssigner = {};
          selectedAssigner.userId = String(
            $scope.selectedReviewer.applicationReviewerId
          );
          $scope.selectedAssigner = selectedAssigner;
        }
        $('#changeReviewer').modal();
      };
      $scope.updateCharityZone = function(zoneId) {
        if ($scope.cfcZone.zoneId !== zoneId) {
          ZoneCenterService.updateCharityZone(
            $scope.applicationId,
            zoneId
          ).then(function() {
            //go to application details page
            $state.go('zoneApplications', {
              zoneId: zoneId,
              pledgeYear: $scope.application.pledgeYear
            });
          });
        }
      };
      $scope.updateCharityZoneListing = function(zoneId) {
        var selectedZoneListingId = $scope.cfcDodZoneListing.zoneId;
        if (
          zoneId != null &&
          zoneId !== 'undefined' &&
          selectedZoneListingId !== zoneId
        ) {
          ZoneCenterService.updateCharityZoneListing(
            $scope.applicationId,
            zoneId
          )
            .then(function(res) {
              var newCfcZone = res.data;
              $scope.cfcDodZoneListing = newCfcZone;
              $scope.prevZoneId = newCfcZone.zoneId;
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
      };
      $scope.updateCharityReviewGroup = function(reviewGroup) {
        if ($scope.application.reviewGroup !== reviewGroup) {
          ApplicationDashboardService.updateGroupNumber(
            $scope.applicationId,
            reviewGroup,
            false
          ).then(function(res) {
            var result = res.data;
            if (result.message.type === CubeSvc.messageType.SUCCESS) {
              $scope.application.reviewGroup = reviewGroup;
              $scope.selectedReviewGroup = reviewGroup;
            }
          });
        }
      };
      $scope.updateChangeReviewer = function() {
        if ($scope.selectedAssigner.userId != null) {
          if (
            $scope.selectedReviewer != null &&
            $scope.selectedAssigner.userId ===
              $scope.selectedReviewer.applicationReviewerId
          ) {
            return;
          }
          var selectedUserId = $scope.selectedAssigner.userId;

          var selectedUser = null;
          ZoneCenterService.getSystemUserById(selectedUserId)
            .then(function(res) {
              var user = res.data;
              if (user !== null && user !== '') {
                selectedUser = user;
              }

              if ($scope.selectedReviewer == null) {
                $scope.selectedReviewer = {};
                $scope.selectedReviewer.zoneId = $scope.cfcZone.zoneId;
                $scope.selectedReviewer.reviewLevelType = $scope.reviewerType;
                $scope.selectedReviewer.applicationId =
                  $scope.application.applicationId;
              }
              $scope.selectedReviewer.assignedDate = ApplicationService.toUTCDate(
                new Date()
              );
              $scope.selectedReviewer.applicationReviewerId = selectedUserId;

              ZoneCenterService.updateChangeReviewer($scope.selectedReviewer)
                .then(function(res) {
                  var newReviewerData = res.data.value;
                  newReviewerData.reviewerFirstName = selectedUser.firstName;
                  newReviewerData.reviewerLastName = selectedUser.lastName;

                  if ($scope.reviewerType === 'Completeness') {
                    $scope.completenessReviewer = newReviewerData;
                  } else if ('Review 1' === $scope.reviewerType) {
                    $scope.review1Reviewer = newReviewerData;
                  } else if ('Review 2' === $scope.reviewerType) {
                    $scope.review2Reviewer = newReviewerData;
                  } else if ('Review 3' === $scope.reviewerType) {
                    $scope.review3Reviewer = newReviewerData;
                  }
                  $scope.selectedReviewer = newReviewerData;
                })
                .catch(function(err) {
                  $log.error(err);
                });
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
      };
      $scope.resetFundRate = function() {
        $scope.fundErrors = [];
        $scope.tempReviewerFundRate = $scope.reviewerFundRate;
      };
      $scope.resetTotalRevenue = function() {
        $scope.totRevenueErrors = [];
      };

      $scope.changeFundRaising = function() {
        $scope.fundErrors = [];
        if (!$scope.tempReviewerFundRate) {
          $scope.tempReviewerFundRate = null;
        } else if (
          $scope.tempReviewerFundRate &&
          !ValidationUtil.isNumeric($scope.tempReviewerFundRate)
        ) {
          $scope.fundErrors.push('Please enter a valid rate.');
          return;
        }
        $scope.review.reviewerFundRate = parseFloat(
          $scope.tempReviewerFundRate
        ).toFixed(1);
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'REVIEWER_FUND_RATE',
          $scope.review
        )
          .then(function(res) {
            $scope.review = res.data.value;
            ZoneCenterService.getApplicationReviewLogs(
              $scope.applicationId
            ).then(
              function(res) {
                $scope.reviewLogs = res.data.value;
                $('#changeFundRaising').modal('toggle');
              },
              function(err) {
                $log.error(err);
              }
            );
          })
          .catch(function(err) {
            $log.error(err);
            $scope.fundErrors.push('Error updating the Fundraising Rate.');
          });
      };
      $scope.deleteAFROverride = function() {
        $scope.fundErrors = [];
        $scope.review.reviewerFundRate = null;
        $scope.reviewerFundRate = null;
        $scope.tempReviewerFundRate = null;
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'REVIEWER_FUND_RATE',
          $scope.review
        )
          .then(function() {
            ZoneCenterService.getApplicationReviewLogs(
              $scope.applicationId
            ).then(
              function(res) {
                $scope.reviewLogs = res.data.value;
              },
              function(err) {
                $log.error(err);
              }
            );
          })
          .catch(function(err) {
            $log.error(err);
          });
      };
      $scope.changeTotalRevenue = function() {
        $scope.totRevenueErrors = [];
        if (!$scope.tempReviewerTotalRevenue) {
          $scope.review.reviewerTotalRevenue = null;
        } else if (!ValidationUtil.isNumeric($scope.tempReviewerTotalRevenue)) {
          $scope.totRevenueErrors.push('Please enter a valid rate.');
          return;
        }

        $scope.tempReviewerTotalRevenue = $scope.tempReviewerTotalRevenue;
        $scope.review.reviewerTotalRevenue = $scope.tempReviewerTotalRevenue;
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'REVIEWER_TOTAL_REVENUE',
          $scope.review
        )
          .then(function(res) {
            $scope.review = res.data.value;
            ZoneCenterService.getApplicationReviewLogs(
              $scope.applicationId
            ).then(
              function(res) {
                var reviewLogs = res.data.value;
                $scope.reviewLogs = reviewLogs;
              },
              function(err) {
                $log.error(err);
              }
            );
            $('#changeTotalRevenue').modal('toggle');
          })
          .catch(function(err) {
            $scope.totRevenueErrors.push('Error updating the Total Revenue.');
          });
      };

      $scope.deleteTotalRevenue = function() {
        $scope.totRevenueErrors = [];
        $scope.review.reviewerTotalRevenue = null;
        $scope.tempReviewerTotalRevenue = null;
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'REVIEWER_TOTAL_REVENUE',
          $scope.review
        )
          .then(function(res) {
            var ureview = res.data.value;
            $scope.review = ureview;
            ZoneCenterService.getApplicationReviewLogs(
              $scope.applicationId
            ).then(
              function(res) {
                var reviewLogs = res.data.value;
                $scope.reviewLogs = reviewLogs;
              },
              function(err) {
                $log.error(err);
              }
            );
          })
          .catch(function(err) {
            $scope.totRevenueErrors.push('Error updating the Total Revenue.');
          });
      };

      $scope.onChangeNumericValues = function(reviewerTotalRevenue) {
        if (ValidationUtil.isNumeric(reviewerTotalRevenue)) {
          var com = '.';
          if (reviewerTotalRevenue.includes(com)) {
            $scope.reviewerTotalRevenue = reviewerTotalRevenue.replace('.', '');
          }
        } else {
          $scope.reviewerTotalRevenue = null;
        }
      };

      $scope.resetRequestInformation = function() {
        $scope.applicationRequestFormErrors = [];
      };
      $scope.updateRequestInformation = function(
        requestdate,
        requestedBy,
        review
      ) {
        var applicationRequestFormErrors = [];
        $scope.applicationRequestFormErrors = applicationRequestFormErrors;
        if (!requestdate) {
          applicationRequestFormErrors.push('Date of Request is required.');
        }
        if (!requestedBy) {
          applicationRequestFormErrors.push('Requested By is required.');
        }
        if (applicationRequestFormErrors.length > 0) {
          $scope.applicationRequestFormErrors = applicationRequestFormErrors;
        } else {
          review.applicationId = $scope.applicationId;
          review.requestedBy = requestedBy;
          var d = new Date(requestdate);
          review.requestedDate = ApplicationService.toUTCDate(d);
          ZoneCenterService.updateApplicationReview(
            $scope.applicationId,
            'REQUEST_INFORMATION',
            review
          )
            .then(function(res) {
              var ureview = res.data.value;
              $scope.review = ureview;

              $('#requestInformation').modal('toggle');
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
      };
      $scope.resetIndependentApplicationReviewStatus = function() {
        $scope.updateIndependentApplicationReviewStatusErrors = [];
      };
      $scope.updateApplicationReview = function(reviewStatus, review) {
        $scope.updateIndependentApplicationReviewStatusErrors = [];
        if (!reviewStatus) {
          $scope.updateIndependentApplicationReviewStatusErrors.push(
            'Please select the application status.'
          );
        } else if (reviewStatus && reviewStatus === 'In Review') {
          $scope.updateIndependentApplicationReviewStatusErrors.push(
            'Please select the application status.'
          );
        } else if (
          reviewStatus &&
          review &&
          reviewStatus === review.applicationReviewStatus
        ) {
          $scope.updateIndependentApplicationReviewStatusErrors.push(
            "The selected application status is same as the existing 'Application Status'. Please select the different application status."
          );
        } else {
          review.applicationId = $scope.applicationId;
          review.applicationReviewStatus = reviewStatus;
          review.applicationReviewStatusUpdatedBy = $scope.getCurrentUser().id;
          review.applicationReviewStatusUpdatedDate = ApplicationService.toUTCDate(
            new Date()
          );
          ZoneCenterService.updateApplicationReview(
            $scope.applicationId,
            'APPLICATION_REVIEW',
            review
          )
            .then(function(res) {
              var ureview = res.data.value;
              $scope.review = ureview;
              $scope.appStatusLastUpdatedDate =
                review.applicationReviewStatusUpdatedDate;
              if (ureview.applicationReviewStatusUpdatedBy != null) {
                ZoneCenterService.getSystemUserById(
                  ureview.applicationReviewStatusUpdatedBy
                )
                  .then(function(res) {
                    var user = res.data;
                    if (user !== null && user !== '') {
                      $scope.userDetails = user;
                      $scope.reviewStatus = ureview.applicationReviewStatus;
                      ZoneCenterService.getApplicationReviewLogs(
                        $scope.applicationId
                      )
                        .then(function(res) {
                          var reviewLogs = res.data.value;
                          $scope.reviewLogs = reviewLogs;
                        })
                        .catch(function(errr) {
                          $log.error('Error getting app review logs: ' + errr);
                        });
                    }
                  })
                  .catch(function(er) {
                    $log.error('Error getting system user info: ' + er);
                  });
              }
            })
            .catch(function(err) {
              $log.error('Error updating app reivew: ' + err);
            });
          angular.element('#charityStatus').modal('hide');
          performRoleChecks($scope.application.cfcCode, reviewStatus);
        } // end else
      };
      $scope.updateCompletenessReview = function(reviewStatus, review) {
        review.applicationId = $scope.applicationId;
        review.completenessReviewStatus = reviewStatus;
        review.currentLevel = 'REVIEW_1';
        if ($scope.charityType === 'LOCAL') {
          review.currentLevel = 'REVIEW_2';
        }
        if ($scope.cfcZone.zoneCode === 'DOD') {
          review.currentLevel = 'REVIEW_3';
        }
        review.completenessStatusUpdatedBy = $scope.getCurrentUser().id;
        review.completenessStatusUpdatedDate = ApplicationService.toUTCDate(
          new Date()
        );
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'COMPLETENESS',
          review
        )
          .then(function(res) {
            var ureview = res.data.value;
            $scope.review = ureview;
            setReviewLevels();
            $scope.determinationUpdateCounter += 1;
            if (ureview.completenessStatusUpdatedBy != null) {
              ZoneCenterService.getSystemUserById(
                ureview.completenessStatusUpdatedBy
              ).then(function(res) {
                var user = res.data;
                if (user !== null && user !== '') {
                  $scope.completenessStatusUpdatedBy = user;
                  ZoneCenterService.getApplicationReviewLogs(
                    $scope.applicationId
                  ).then(function(res) {
                    var reviewLogs = res.data.value;
                    $scope.reviewLogs = reviewLogs;
                    $scope.determinationStatus = setDeterminationStatus(
                      reviewStatus
                    );
                  });
                }
              });
            }
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.getLinkToCharityReview =
        CharityReviewService.getLinkToCharityReview;

      function shouldDisable(
        reviewLevel,
        checkListReviewLevels,
        currentLevelStatus,
        previousLevelStatus
      ) {
        const previousLevelNotSet = previousLevelStatus === 'NOT_SET';
        const currentLevelSet = ['PASS', 'FAIL'].includes(currentLevelStatus);
        const reviewed = checkListReviewLevels.includes(reviewLevel);
        return previousLevelNotSet || (currentLevelSet && !reviewed);
      }

      $scope.shouldDisableLinkToReview = function(
        reviewLevel,
        review,
        charityType,
        cfcZoneCode
      ) {
        const isNationalOrInternationalCharity =
          charityType === 'NATIONAL' || charityType === 'INTERNATIONAL';
        const {
          checkListReviewLevels,
          completenessReviewStatus,
          review1Status,
          review2Status,
          review3Status
        } = review;

        switch (reviewLevel) {
          case 'COMPLETENESS':
            return shouldDisable(
              reviewLevel,
              checkListReviewLevels,
              completenessReviewStatus
            );
          case 'STAFF_ELIGIBILITY':
            return shouldDisable(
              reviewLevel,
              checkListReviewLevels,
              review1Status,
              completenessReviewStatus
            );
          case 'PRELIMINARY_ELIGIBILITY':
            if (isNationalOrInternationalCharity) {
              return shouldDisable(
                reviewLevel,
                checkListReviewLevels,
                review2Status,
                review1Status
              );
            } else {
              return shouldDisable(
                reviewLevel,
                checkListReviewLevels,
                review2Status,
                completenessReviewStatus
              );
            }
          case 'FINAL_ELIGIBILITY':
            if (cfcZoneCode !== 'DOD') {
              return shouldDisable(
                reviewLevel,
                checkListReviewLevels,
                review3Status,
                review2Status
              );
            } else {
              if (isNationalOrInternationalCharity) {
                return shouldDisable(
                  reviewLevel,
                  checkListReviewLevels,
                  review3Status,
                  review1Status
                );
              } else {
                return shouldDisable(
                  reviewLevel,
                  checkListReviewLevels,
                  review3Status,
                  completenessReviewStatus
                );
              }
            }
        }
      };

      $scope.navigateToReview = function(applicationId, reviewLevel) {
        $window.location.href = CharityReviewService.getLinkToCharityReview(
          applicationId,
          reviewLevel
        );
      };

      $scope.updateReview3 = function(reviewStatus, review) {
        review.applicationId = $scope.applicationId;
        review.review3Status = reviewStatus;
        review.currentLevel = 'REVIEW_3';
        review.review3StatusUpdatedBy = $scope.getCurrentUser().id;
        review.review3StatusUpdatedDate = ApplicationService.toUTCDate(
          new Date()
        );
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'REVIEW3',
          review
        )
          .then(function(res) {
            var ureview = res.data.value;
            $scope.review = ureview;
            setReviewLevels();
            $scope.determinationUpdateCounter += 1;
            if (ureview.review3StatusUpdatedBy != null) {
              ZoneCenterService.getSystemUserById(
                ureview.review3StatusUpdatedBy
              )
                .then(function(res) {
                  var user = res.data;
                  if (user !== null && user !== '') {
                    $scope.review3StatusUpdatedBy = user;
                    ZoneCenterService.getApplicationReviewLogs(
                      $scope.applicationId
                    )
                      .then(function(res) {
                        var reviewLogs = res.data.value;
                        $scope.reviewLogs = reviewLogs;
                        $scope.determinationStatus = setDeterminationStatus(
                          reviewStatus
                        );
                      })
                      .catch(function(err) {
                        $log.error(err);
                      });
                  }
                })
                .catch(function(err) {
                  $log.error(err);
                });
            }
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.goToEmailPage = function(isFed) {
        // review-email-dashboard
        $state.go('email-dashboard', {
          isFederation: isFed,
          applicationId: $scope.applicationId,
          sourcePageName: $state.current.name,
          sourcePageParams: $stateParams,
          charityType: $scope.charityType
        });
      };

      $scope.goToAppealAdminPage = function(type) {
        // review-admin-appeal-dashboard
        $state.go('admin-appeal-dashboard', {
          applicationType: type,
          applicationId: $scope.review.applicationId
        });
      };

      $scope.goToDenialReasonsPage = function(isFed) {
        // review-denial-reasons
        $state.go('review-denial-reasons', {
          isFederation: isFed,
          applicationId: $scope.review.applicationId,
          sourcePageName: $state.current.name,
          sourcePageParams: $stateParams,
          charityType: $scope.charityType
        });
      };

      $scope.updateReview2 = function(reviewStatus, review) {
        review.applicationId = $scope.applicationId;
        review.review2Status = reviewStatus;
        review.currentLevel = 'REVIEW_3';
        review.review2StatusUpdatedBy = $scope.getCurrentUser().id;
        review.review2StatusUpdatedDate = ApplicationService.toUTCDate(
          new Date()
        );
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'REVIEW2',
          review
        ).then(function(res) {
          var ureview = res.data.value;
          $scope.review = ureview;
          setReviewLevels();
          $scope.determinationUpdateCounter += 1;
          if (ureview.review2StatusUpdatedBy != null) {
            ZoneCenterService.getSystemUserById(ureview.review2StatusUpdatedBy)
              .then(function(res) {
                var user = res.data;
                if (user !== null && user !== '') {
                  $scope.review2StatusUpdatedBy = user;
                  ZoneCenterService.getApplicationReviewLogs(
                    $scope.applicationId
                  )
                    .then(function(res) {
                      var reviewLogs = res.data.value;
                      $scope.reviewLogs = reviewLogs;
                      $scope.determinationStatus = setDeterminationStatus(
                        reviewStatus
                      );
                    })
                    .catch(function(err) {
                      $log.error(err);
                    });
                }
              })
              .catch(function(err) {
                $log.error(err);
              });
          }
        });
      };
      $scope.updateReview1 = function(reviewStatus, review) {
        review.applicationId = $scope.applicationId;
        review.review1Status = reviewStatus;
        review.currentLevel = 'REVIEW_2';
        review.review1StatusUpdatedBy = $scope.getCurrentUser().id;
        review.review1StatusUpdatedDate = ApplicationService.toUTCDate(
          new Date()
        );
        ZoneCenterService.updateApplicationReview(
          $scope.applicationId,
          'REVIEW1',
          review
        )
          .then(function(res) {
            var ureview = res.data.value;
            $scope.review = ureview;
            setReviewLevels();
            $scope.determinationUpdateCounter += 1;
            if (ureview.review1StatusUpdatedBy != null) {
              ZoneCenterService.getSystemUserById(
                ureview.review1StatusUpdatedBy
              )
                .then(function(res) {
                  var user = res.data;
                  if (user !== null && user !== '') {
                    $scope.review1StatusUpdatedBy = user;
                    ZoneCenterService.getApplicationReviewLogs(
                      $scope.applicationId
                    )
                      .then(function(res) {
                        var reviewLogs = res.data.value;
                        $scope.reviewLogs = reviewLogs;
                        $scope.determinationStatus = setDeterminationStatus(
                          reviewStatus
                        );
                      })
                      .catch(function(err) {
                        $log.error(err);
                      });
                  }
                })
                .catch(function(err) {
                  $log.error(err);
                });
            }
          })
          .catch(function(err) {
            $log.error(err);
          });
      };
      $scope.addReviewComment = function(comment) {
        $scope.addReviewerCommentErrors = [];
        if (
          !comment ||
          ((comment && comment.trim().length < 1) || comment.length > 2000)
        ) {
          $scope.addReviewerCommentErrors.push(
            'Please enter a comment between 1 and 2000 characters.'
          );
        } else if (comment && (comment.length > 0 && comment.length <= 2000)) {
          ZoneCenterService.addReviewComment(
            $scope.applicationId,
            comment
          ).then(function() {
            $scope.comments = '';
            $scope.addReviewerCommentErrors = [];
            $('#comment').modal('toggle');
            ZoneCenterService.getApplicationReviewLogs(
              $scope.applicationId
            ).then(function(res) {
              var reviewLogs = res.data.value;
              $scope.reviewLogs = reviewLogs;
            });
          });
        }
      };

      $scope.resetReviewerComment = function() {
        $scope.comments = '';
        $scope.addReviewerCommentErrors = [];
      };

      $scope.onChangeApprovedWithWarning = function() {
        if (!$scope.application) {
          return;
        }
        if ($scope.application.approvedWithWarning) {
          angular.element('#addExceptions').modal('show');
        } else {
          angular
            .element('#confirmDeleteApprovedWithWarningsException')
            .modal('show');
        }
      };

      $scope.addViewApprovedWithWarningReasons = function() {
        if (!$scope.application) {
          return;
        }
        angular.element('#addExceptions').modal('show');
      };

      $scope.approvedWithWarningModalCancel = function() {
        ApplicationDashboardService.applicationDetailsById($scope.applicationId)
          .then(function(res) {
            var application = res.data.value;
            $scope.application = application;
          })
          .catch(function(err) {
            $log.error(err);
          });
        angular.element('#addExceptions').modal('hide');
      };

      $scope.approvedWithWarningModalSave = function() {
        $scope.application.approvedWithWarning = $scope.application.warningNote
          ? true
          : false;
        var comment = $scope.application.approvedWithWarning
          ? 'Approved with warning: ' + $scope.application.warningNote
          : 'Approved with warning: flag removed';
        ZoneCenterService.addWarningNote(
          $scope.application.applicationId,
          $scope.application
        )
          .then(function() {
            //success
            addComment($scope.application.applicationId, comment);
          })
          .catch(function(er) {
            $log.error(er);
          });
        angular.element('#addExceptions').modal('hide');
      };

      $scope.cancelDeleteApprovedWithWarningsException = function() {
        if (!$scope.application) {
          return;
        }
        ApplicationDashboardService.applicationDetailsById(
          $scope.applicationId
        ).then(function(res) {
          var application = res.data.value;
          $scope.application = application;
        });
        angular
          .element('#confirmDeleteApprovedWithWarningsException')
          .modal('hide');
      };

      $scope.deleteApprovedWithWarningsException = function() {
        $scope.application.warningNote = null;
        $scope.application.approvedWithWarning = false;
        $scope.approvedWithWarningModalSave();
        angular
          .element('#confirmDeleteApprovedWithWarningsException')
          .modal('hide');
      };

      $scope.onChangedenialOverturnedWithWarning = function() {
        if (!$scope.application) {
          return;
        }
        if ($scope.application.denialOverturnedWithWarning) {
          angular.element('#addDenialExceptions').modal('show');
        } else {
          angular
            .element('#confirmDeleteDenialOverturnedWithWarningsException')
            .modal('show');
        }
      };

      $scope.denialOverturnedWithWarningModalCancel = function() {
        ApplicationDashboardService.applicationDetailsById($scope.applicationId)
          .then(function(res) {
            var application = res.data.value;
            $scope.application = application;
          })
          .catch(function(err) {
            $log.error(err);
          });
        angular.element('#addDenialExceptions').modal('hide');
      };

      $scope.denialOverturnedWithWarningModalSave = function() {
        $scope.application.denialOverturnedWithWarning = $scope.application
          .denialOverturnedWarningNote
          ? true
          : false;
        var comment = $scope.application.denialOverturnedWithWarning
          ? 'Denial overturned with warning: ' +
            $scope.application.denialOverturnedWarningNote
          : 'Denial overturned with warning: flag removed';
        ZoneCenterService.addDenialOverturnedWarningNote(
          $scope.application.applicationId,
          comment,
          $scope.application
        )
          .then(function(res) {
            //success
            $scope.reviewLogs = res.data.value;
          })
          .catch(function(er) {
            $log.error(er);
          });
        angular.element('#addDenialExceptions').modal('hide');
      };

      $scope.cancelDeleteDenialOverturnedWithWarningsException = function() {
        if (!$scope.application) {
          return;
        }
        ApplicationDashboardService.applicationDetailsById(
          $scope.applicationId
        ).then(function(res) {
          var application = res.data.value;
          $scope.application = application;
        });
        angular
          .element('#confirmDeleteDenialOverturnedWithWarningsException')
          .modal('hide');
      };

      $scope.deleteDenialOverturnedWithWarningsException = function() {
        $scope.application.denialOverturnedWarningNote = null;
        $scope.application.denialOverturnedWithWarning = false;
        $scope.denialOverturnedWithWarningModalSave();
        angular
          .element('#confirmDeleteDenialOverturnedWithWarningsException')
          .modal('hide');
      };

      $('#requestInformation').on('show.bs.modal', function(e) {
        var currentUser = BrowserEventService.getCurrentUser();
        $('#requestedBy').val(
          currentUser.firstName + ' ' + currentUser.lastName
        );
        $('#requestedBy').trigger('change');
      });

      function loadDenialReasons(cfcCode, pledgeYear, applicationStatus) {
        performRoleChecks(cfcCode, applicationStatus);
        var cannedDenialReasons =
          $scope.applicationReview.applicationDenialReasonLookup;

        $scope.selectedDenialReasons =
          $scope.applicationReview.charityDenialReasons;

        var filteredDR = [];
        angular.forEach($scope.selectedDenialReasons, function(
          selectedDenialReason,
          key
        ) {
          var cannedDenialReason = cannedDenialReasons.filter(function(item) {
            return item.id === selectedDenialReason.denialReasonId;
          })[0];
          if (cannedDenialReason != null || cannedDenialReason != undefined) {
            selectedDenialReason.reason = cannedDenialReason.reason;
            selectedDenialReason.applicationStep =
              cannedDenialReason.applicationStep;
            selectedDenialReason.issueName = cannedDenialReason.issueName;
            selectedDenialReason.applicationType =
              cannedDenialReason.applicationType;

            if (selectedDenialReason.issueName === 'CUSTOM') {
              selectedDenialReason.reason =
                selectedDenialReason.denialReasonText;
            }
            filteredDR.push(selectedDenialReason);
          }
        });
        $scope.selectedDenialReasons = filteredDR;
      }

      function performRoleChecks(cfcCode, applicationStatus) {
        $scope.canEditReview = false;
        $scope.canEditAppeal = false;
        var user = BrowserEventService.getCurrentUser();
        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        if (
          secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('OPM_STAFF') >= 0
        ) {
          $scope.canEditReview = isInReviewStatus(applicationStatus);
          $scope.canEditAppeal =
            applicationStatus === 'IN_APPEAL' ? true : false;
        } else if (
          secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0
        ) {
          ZoneCenterService.findCharityAccessEligibility(user.id, cfcCode)
            .then(function(res) {
              var isAccessEligible = res.data;
              if (isAccessEligible) {
                $scope.canEditReview = isInReviewStatus(applicationStatus);
              }
            })
            .catch(function(err) {
              $log.error(err);
            });
        }
      }

      $scope.onCheckChanged = function(reason) {
        CubeSvc.post(
          '/CfcCharity/api/private/charity/application/denialreason/stored/update',
          reason,
          null,
          false
        )
          .then(function(res) {
            // success
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      function isInReviewStatus(appStatus) {
        var status = ['IN_REVIEW', 'SUBMITTED', 'MORE_INFO'];
        if (status.indexOf(appStatus) > -1) {
          return true;
        }
        return false;
      }

      angular.element('#overrideReviewZone').on('show.bs.modal', function() {
        getZones();
      });

      angular.element('#overrideListingZone').on('show.bs.modal', function() {
        getZones();
      });

      function getZones() {
        if ($scope.overrideZones) {
          return;
        }
        ZoneService.getListingOverrideZones(
          $scope.charityType,
          $scope.application.pledgeYear
        )
          .then(function(res) {
            $scope.overrideZones = res;
            $scope.selectedListingZone = $scope.cfcDodZoneListing;
          })
          .catch(function(err) {
            $log.error(err);
          });
      }

      $scope.overrideReviewZone = function(selectedZone) {
        $scope.editZoneForReviewErrors = [];

        if (!selectedZone) {
          $scope.editZoneForReviewErrors.push('Please select the zone.');
          return;
        }

        if ($scope.cfcZone.zoneId === selectedZone.zoneId) {
          $scope.editZoneForReviewErrors.push(
            'The zone selected is same as current zone for review. Please select a different zone.'
          );
          return;
        }

        ZoneCenterService.overrideCharityReviewZone(
          $scope.applicationId,
          selectedZone.zoneId
        )
          .then(function() {
            ZoneCenterService.getZoneDetails($scope.applicationId)
              .then(function(res) {
                $scope.cfcZone = res.data;
                angular.element('#overrideReviewZone').modal('hide');
              })
              .catch(function(err) {
                $log.error(err);
              });
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.overrideListingZone = function(selectedZone) {
        $scope.editZoneForListingErrors = [];

        if (!selectedZone) {
          $scope.editZoneForListingErrors.push('Please select the zone.');
          return;
        }

        if ($scope.cfcDodZoneListing.zoneId === selectedZone.zoneId) {
          $scope.editZoneForListingErrors.push(
            'The zone selected is same as current zone for listing. Please select a different zone.'
          );
          return;
        }

        ZoneCenterService.overrideCharityListingZone(
          $scope.applicationId,
          selectedZone.zoneId
        )
          .then(function() {
            ZoneCenterService.getDODZoneDetails($scope.applicationId)
              .then(function(res) {
                $scope.cfcDodZoneListing = res.data;
                angular.element('#overrideListingZone').modal('hide');
              })
              .catch(function(err) {
                $log.error(err);
              });
            var comment =
              'Override Zone for Listing with - ' + selectedZone.zoneName;
            addComment($scope.applicationId, comment);
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.deleteOverriddenReviewZone = function() {
        $scope.deleteZoneForReviewErrors = [];
        ZoneCenterService.overrideCharityReviewZone($scope.applicationId, 0)
          .then(function() {
            ZoneCenterService.getZoneDetails($scope.applicationId)
              .then(function(res) {
                $scope.cfcZone = res.data;
                $scope.selectedReviewZone = null;
                angular
                  .element('#overrideReviewZoneConfirmDelete')
                  .modal('hide');
              })
              .catch(function(err) {
                $log.error(err);
              });
          })
          .catch(function(err) {
            $scope.deleteZoneForReviewErrors.push('Delete failed');
            $log.error(err);
          });
      };

      $scope.deleteOverriddenListingZone = function() {
        $scope.deleteZoneForListingErrors = [];
        ZoneCenterService.overrideCharityListingZone($scope.applicationId, 0)
          .then(function() {
            ZoneCenterService.getDODZoneDetails$scope($scope.applicationId)
              .then(function(res) {
                $scope.cfcDodZoneListing = res.data;
                $scope.selectedListingZone = null;
                angular
                  .element('#overrideListingZoneConfirmDelete')
                  .modal('hide');
              })
              .catch(function(err) {
                $log.error(err);
              });
            var comment = 'Override Zone for Listing deleted';
            addComment($scope.applicationId, comment);
          })
          .catch(function(err) {
            $scope.deleteZoneForListingErrors.push('Delete failed');
            $log.error(err);
          });
      };

      function addComment(applicationId, comment) {
        ZoneCenterService.addReviewComment(applicationId, comment).then(
          function() {
            ZoneCenterService.getApplicationReviewLogs(applicationId).then(
              function(res) {
                var reviewLogs = res.data.value;
                $scope.reviewLogs = reviewLogs;
              }
            );
          }
        );
      }
    }
  ])
  .controller('singleZoneAppsByYearCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    'ZoneCenterService',
    'zoneAppsByYearData',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      ZoneCenterService,
      zoneAppsByYearData
    ) {
      $scope.setModuleName('CfcCharity.zonecenter');
      if (zoneAppsByYearData != null && zoneAppsByYearData !== 'undefined') {
        $scope.zoneAppsData = zoneAppsByYearData;
      }

      $scope.zoneBean = $state.params.zoneBean;

      if ($scope.zoneBean.zoneCode == null) {
        BrowserEventService.setZoneId(zoneAppsByYearData.zoneId);
        BrowserEventService.setZoneCode(zoneAppsByYearData.zoneCode);
        BrowserEventService.setZoneName(zoneAppsByYearData.zoneName);
        $scope.zoneBean.zoneCode = zoneAppsByYearData.zoneCode;
        $scope.zoneBean.zoneName = zoneAppsByYearData.zoneName;
      } else {
        BrowserEventService.setZoneId($scope.zoneBean.zoneId);
        BrowserEventService.setZoneCode($scope.zoneBean.zoneCode);
        BrowserEventService.setZoneName($scope.zoneBean.zoneName);
      }
      $('.modal-backdrop').remove();
    }
  ])
  .controller('appSummaryByZoneCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'summaryzoneAppsByYearData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      summaryzoneAppsByYearData,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.zonecenter');
      $scope.summaryzoneAppsByYearData = summaryzoneAppsByYearData;
      $('.modal-backdrop').remove();
    }
  ])
  .controller('ZoneApplicationStatusCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$stateParams',
    '$log',
    'zoneApplicationAvailableStatus',
    'availableApplicationZone',
    'availableCampaignPeriodList',
    'BrowserEventService',
    'ZoneCenterService',
    'CharityService',
    'CommonService',
    'RedirectService',
    'ApplicationDashboardService',
    'ApplicationService',
    'activeZonesPerYear',
    '$anchorScroll',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $stateParams,
      $log,
      zoneApplicationAvailableStatus,
      availableApplicationZone,
      availableCampaignPeriodList,
      BrowserEventService,
      ZoneCenterService,
      CharityService,
      CommonService,
      RedirectService,
      ApplicationDashboardService,
      ApplicationService,
      activeZonesPerYear,
      anchorScroll
    ) {
      $scope.setModuleName('CfcCharity.zoneapplicationStatus');
      $scope.userId = $scope.getCurrentUser().id;
      $scope.statusValues = [];
      $scope.zoneApplicationStatusList = [];
      $scope.matchedStatus = undefined;
      $scope.statCheck = 'checked';
      if (angular.element('#navbar-admin-search-input')[0]) {
        angular.element('#navbar-admin-search-input')[0].value = '';
      }
      var zoneId = null;
      var selectedYear = null;
      var status = null;
      var einVal = null;

      $scope.zoneApplicationAvailableStatus =
        zoneApplicationAvailableStatus.data.selectOptionsList || [];
      var anyStatusOption = $scope.zoneApplicationAvailableStatus.find(function(
        stat
      ) {
        return stat.selected === true;
      });

      $scope.availableApplicationZone =
        availableApplicationZone.data.selectOptionsList || [];
      $scope.secondaryList = $scope.availableApplicationZone;
      var anyZoneOption = $scope.availableApplicationZone.find(function(zone) {
        return zone.selected === true;
      });

      $scope.activeZonesPerYear = activeZonesPerYear.data.value;

      $scope.availableCampaignPeriodList =
        availableCampaignPeriodList.data.selectOptionsList || [];
      var currentCampaignPeriodOption = $scope.availableCampaignPeriodList.find(
        function(campPeriod) {
          return campPeriod.selected === true;
        }
      );

      var savedSearchCriteria = JSON.parse(
        sessionStorage.getItem('SEARCH_CRITERIA')
      );
      if (savedSearchCriteria) {
        sessionStorage.removeItem('SEARCH_CRITERIA');
      }

      if ($stateParams.zoneId && $stateParams.zoneId >= 0) {
        selectedYear = $stateParams.pledgeYear;
        zoneId = $stateParams.zoneId;
        status = $stateParams.status;
        einVal = $stateParams.einVal;
      } else if (BrowserEventService.getZoneId()) {
        selectedYear = BrowserEventService.getPledgeYear();
        zoneId = BrowserEventService.getZoneId();
        status = BrowserEventService.getStatusName();
        einVal = BrowserEventService.getEinVal();
      } else if (savedSearchCriteria) {
        selectedYear = savedSearchCriteria.pledgeYear;
        zoneId = savedSearchCriteria.zoneId;
        status = savedSearchCriteria.status;
        einVal = savedSearchCriteria.einVal;
      }

      if (einVal === undefined || einVal == null || einVal.length <= 0) {
        einVal = null;
      }

      if (einVal === null && zoneId === '0') {
        return;
      }

      BrowserEventService.setPledgeYear(selectedYear);
      BrowserEventService.setZoneId(zoneId);
      BrowserEventService.setStatusName(status);
      BrowserEventService.setEinVal(einVal);

      $scope.isZoneReviewerOrManager = false;
      $scope.isZoneCoordinator = false;
      var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      var secondaryRoleList = userObj.secondaryRoleList;
      if (
        secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('REVIEWER') >= 0
      ) {
        $scope.isZoneReviewerOrManager = true;
      }
      if (secondaryRoleList.indexOf('ZONE_COORDINATOR') >= 0) {
        $scope.isZoneCoordinator = true;
      }

      var zoneApplicationStatusData = null;
      $scope.searchResultsPerPage = 50;
      $scope.totalElements = 0;
      $scope.pagination = {
        current: 1
      };
      $scope.slectedZone = null;
      $scope.selectedYear = null;
      $scope.selectedStatus = null;
      $scope.selectedEIN = null;
      var activePageNumber = 1;

      if (zoneId >= 0) {
        ZoneCenterService.getZoneAppdataUpdateResults(
          zoneId,
          selectedYear,
          status,
          einVal,
          0
        ).then(function(res) {
          var data = res.data;
          $scope.totalElements = data.limitedMasterCharityDataListTotalCount;
          if (data !== null && data !== '') {
            zoneApplicationStatusData = data;
            $scope.selectedEIN = zoneApplicationStatusData.ein;
            if (
              undefined === zoneApplicationStatusData.statusName ||
              zoneApplicationStatusData.statusName == null
            ) {
              zoneApplicationStatusData.statusName = anyStatusOption;
            } else {
              var tempStatusName = zoneApplicationStatusData.statusName;
              zoneApplicationStatusData.statusName = $scope.zoneApplicationAvailableStatus.find(
                function(stat) {
                  if (stat.key === tempStatusName) {
                    $scope.selectedStatus = stat;
                  }
                  return stat.key === tempStatusName;
                }
              );
            }
            if (
              undefined === zoneApplicationStatusData.zoneId ||
              zoneApplicationStatusData.zoneId == null
            ) {
              zoneApplicationStatusData.zoneId = anyZoneOption;
            } else {
              var tmpZoneID = zoneApplicationStatusData.zoneId.toString();
              zoneApplicationStatusData.zoneId = $scope.availableApplicationZone.find(
                function(zone) {
                  if (zone.key === tmpZoneID) {
                    $scope.slectedZone = zone;
                  }
                  return zone.key === tmpZoneID;
                }
              );
            }
            if (
              undefined === zoneApplicationStatusData.pledgeYear ||
              zoneApplicationStatusData.pledgeYear == null
            ) {
              zoneApplicationStatusData.pledgeYear = currentCampaignPeriodOption;
            } else {
              var tempPledgeYear = zoneApplicationStatusData.pledgeYear.toString();
              zoneApplicationStatusData.pledgeYear = $scope.availableCampaignPeriodList.find(
                function(plYear) {
                  if (plYear.key === tempPledgeYear) {
                    $scope.selectedYear = plYear;
                  }
                  return plYear.key === tempPledgeYear;
                }
              );
              zoneApplicationStatusData.pledgeYear.value = parseInt(
                zoneApplicationStatusData.pledgeYear.value,
                10
              );
            }
            // sort the zone list now based on pledge year
            $scope.showZones();

            $scope.downloadsearchBeanList =
              zoneApplicationStatusData.zoneApplicationStatusList;

            if (
              zoneApplicationStatusData != null &&
              zoneApplicationStatusData !== 'undefined'
            ) {
              $scope.zoneApplicationStatusData = zoneApplicationStatusData;
              var tmpZoneId = $scope.zoneApplicationStatusData.zoneId.key;
              var zones = {};
              zones.selectedZone = $scope.availableApplicationZone.find(
                function(zone) {
                  return zone.key === tmpZoneId;
                }
              );
              $scope.zones = zones;
              einVal = $stateParams.einVal;
              $scope.selectedEIN = einVal;
              $scope.userId = $scope.getCurrentUser().id;

              if (zoneApplicationStatusData.statusName === 'DENIED') {
                zoneApplicationStatusData.statusName = 'Denied';
              } else if (zoneApplicationStatusData.statusName === 'APPROVED') {
                zoneApplicationStatusData.statusName = 'Approved';
              } else if (zoneApplicationStatusData.statusName === 'IN_APPEAL') {
                zoneApplicationStatusData.statusName = 'In Appeal';
              }

              if (
                zoneApplicationStatusData.limitedMasterCharityDataList
                  .length === 0
              ) {
                $scope.noResultsErrorMessage = 'No Results Found';
              }
            }
          }
        });
      } // end if zoneId

      $scope.showZones = function() {
        var year = zoneApplicationStatusData.pledgeYear.key;
        $scope.activeZoneIds = [];
        angular.forEach($scope.activeZonesPerYear, function(czData) {
          angular.forEach(czData.pledgeYear, function(cz) {
            if (cz.toString() === year) {
              $scope.activeZoneIds.push(czData.zoneId);
            }
          });
        });
        $scope.availableApplicationZone = [];
        for (var i = 0; i < $scope.activeZoneIds.length; i++) {
          for (var j = 0; j < $scope.secondaryList.length; j++) {
            if (
              $scope.activeZoneIds[i].toString() === $scope.secondaryList[j].key
            ) {
              $scope.availableApplicationZone.push($scope.secondaryList[j]);
            }
          }
        }
        $scope.availableApplicationZone.splice(0, 0, $scope.secondaryList[0]);
      };

      $scope.UpdateResults = function(
        zone,
        selectedYear,
        status,
        einVal,
        pageNumber
      ) {
        if (
          ($scope.selectedEIN === null || $scope.selectedEIN === '') &&
          zone.value === 'All Zones' &&
          status.value === 'Any'
        ) {
          $scope.zoneApplicationStatusData.limitedMasterCharityDataList = null;
          $scope.noResultsErrorMessage = 'Please Narrow Your Search Criteria';
          return;
        }

        $scope.slectedZone = zone;
        $scope.selectedYear = selectedYear;
        $scope.selectedStatus = status;
        $scope.selectedEIN = einVal;
        activePageNumber = pageNumber;
        if (pageNumber === undefined) {
          pageNumber = 1;
        }
        $scope.noResultsErrorMessage = '';
        var zoneApplicationStatusData = null;
        if (einVal === undefined || einVal == null || einVal.length <= 0) {
          einVal = null;
        }
        ZoneCenterService.getZoneAppdataUpdateResults(
          zone.key,
          selectedYear.key,
          status.key,
          einVal,
          pageNumber - 1
        )
          .then(function(res) {
            var data = res.data;
            $scope.totalElements = data.limitedMasterCharityDataListTotalCount;
            if (data !== null && data !== '') {
              zoneApplicationStatusData = data;
              if (
                undefined === zoneApplicationStatusData.statusName ||
                zoneApplicationStatusData.statusName == null
              ) {
                zoneApplicationStatusData.statusName = anyStatusOption;
              } else {
                var tempStatusName = zoneApplicationStatusData.statusName;
                zoneApplicationStatusData.statusName = $scope.zoneApplicationAvailableStatus.find(
                  function(stat) {
                    return stat.key === tempStatusName;
                  }
                );
              }
              if (
                undefined === zoneApplicationStatusData.zoneId ||
                zoneApplicationStatusData.zoneId == null
              ) {
                zoneApplicationStatusData.zoneId = anyZoneOption;
              } else {
                var tempZoneName = zoneApplicationStatusData.zoneId;
                zoneApplicationStatusData.zoneId = $scope.availableApplicationZone.find(
                  function(zone) {
                    return zone.key === tempZoneName;
                  }
                );
              }
              if (
                undefined === zoneApplicationStatusData.pledgeYear ||
                zoneApplicationStatusData.pledgeYear == null
              ) {
                zoneApplicationStatusData.pledgeYear = currentCampaignPeriodOption;
              } else {
                var tempPledgeYear = zoneApplicationStatusData.pledgeYear.toString();
                zoneApplicationStatusData.pledgeYear = $scope.availableCampaignPeriodList.find(
                  function(plYear) {
                    return plYear.key === tempPledgeYear;
                  }
                );
              }
              $scope.zoneApplicationStatusData = data;
              $scope.downloadsearchBeanList =
                zoneApplicationStatusData.zoneApplicationStatusList;
              if (
                zoneApplicationStatusData != null &&
                zoneApplicationStatusData !== 'undefined' &&
                zoneApplicationStatusData.limitedMasterCharityDataList
                  .length === 0
              ) {
                $scope.noResultsErrorMessage = 'No Results Found';
              }
            }
          })
          .finally(function() {
            window.scroll(0, 0);
          })
          .catch(function(err) {
            $scope.noResultsErrorMessage =
              'Something went wrong. Please try again later.';
          });
      };

      $scope.pageChanged = function(newPage) {
        if (activePageNumber !== newPage) {
          $scope.UpdateResults(
            $scope.slectedZone,
            $scope.selectedYear,
            $scope.selectedStatus,
            $scope.selectedEIN,
            newPage
          );
        }
      };

      CharityService.findListingStatus().then(function(res) {
        var result = res.data;
        if (result) {
          $scope.isListingAvailable = true;
        } else {
          $scope.isListingAvailable = false;
        }
      });

      $scope.goToCharityListing = function(
        pledgeYear,
        cfcCode,
        applicationType
      ) {
        RedirectService.goToCharityListing(
          pledgeYear,
          cfcCode,
          applicationType
        );
      };

      $scope.goToFederationListing = function(pledgeYear, federationCfcCode) {
        RedirectService.goToFederationListing(pledgeYear, federationCfcCode);
      };

      $scope.selectAll = function(pledgeYear, zoneId, statusName, checked) {
        $scope.statCheck = 'checked';
      };

      $scope.gotToCharityDashboard = function(
        cfcCode,
        userId,
        applicationType
      ) {
        $scope.leftSideNavbar.userId = userId;
        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        if (
          secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
        ) {
          $state.go('charitydashboard', {
            charityDashboardCfcCode: cfcCode
          });
        } else if (
          secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0
        ) {
          ZoneCenterService.findCharityAccessEligibility(userId, cfcCode).then(
            function(res) {
              var isAccessEligible = res.data;
              if (isAccessEligible) {
                $state.go('charitydashboard', {
                  charityDashboardCfcCode: cfcCode
                });
              } else {
                $state.go('accessDenied', {
                  userId: userId
                });
              }
            }
          );
        } else if (secondaryRoleList.indexOf('ZONE_COORDINATOR') >= 0) {
          $state.go('accessDenied', {
            userId: userId
          });
        } else {
          $state.go('charitydashboard', {
            charityDashboardCfcCode: cfcCode
          });
        }
      };

      $scope.gotoCharityAuditDashboard = function(
        applicationId,
        cfcCode,
        userId
      ) {
        $scope.leftSideNavbar.userId = userId;
        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        if (
          secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
        ) {
          ZoneCenterService.goToCharityAuditDashboard(applicationId, userId);
        } else if (
          secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0
        ) {
          ZoneCenterService.findCharityAccessEligibility(userId, cfcCode).then(
            function(res) {
              var isAccessEligible = res.data;
              if (isAccessEligible) {
                ZoneCenterService.goToCharityAuditDashboard(
                  applicationId,
                  userId
                );
              } else {
                $state.go('accessDenied', {
                  userId: userId
                });
              }
            }
          );
        } else {
          $state.go('accessDenied', {
            userId: userId
          });
        }
      };

      $scope.gotoFederationapplicationreview = function(
        federationApplicationId,
        cfcCode,
        userId
      ) {
        $scope.leftSideNavbar.userId = userId;
        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        if (
          secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
          secondaryRoleList.indexOf('GB_FINANCE_ADMIN') >= 0
        ) {
          $state.go('federationapplicationreview', {
            federationApplicationId: federationApplicationId
          });
        } else if (
          secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0
        ) {
          ZoneCenterService.findCharityAccessEligibility(userId, cfcCode).then(
            function(res) {
              var isAccessEligible = res.data;
              if (isAccessEligible) {
                ZoneCenterService.checkAccess(federationApplicationId).then(
                  function(res) {
                    var canAccess = res.data.value;
                    if (canAccess) {
                      $state.go('federationapplicationreview', {
                        federationApplicationId: federationApplicationId
                      });
                    } else {
                      $state.go('accessDenied', {
                        userId: userId
                      });
                    }
                  }
                );
              } else {
                $state.go('accessDenied', {
                  userId: userId
                });
              }
            }
          );
        } else {
          $state.go('accessDenied', {
            userId: userId
          });
        }
      };
    }
  ]);
