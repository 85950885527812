(function() {
  'use strict';

  angular.module('CfcCharity.admin.system').factory('cmsService', cmsService);

  cmsService.$inject = ['$http'];

  function cmsService($http) {
    var getList = function() {
      return $http.get('/CfcCharity/api/public/cms/list');
    };

    var getDetail = function(id) {
      return $http.get('/CfcCharity/api/public/cms/detail/id/' + id);
    };

    var getContent = function(contentKey) {
      return $http.get('/CfcCharity/api/public/cms/detail/key/' + contentKey);
    };

    var saveContent = function(content) {
      return $http.put('/CfcCharity/api/public/cms/detail/', content);
    };

    var deleteContent = function(id) {
      return $http.put('/CfcCharity/api/public/cms/delete/' + id);
    };

    var getCategories = function() {
      return $http.get('/CfcCharity/api/public/cms/category');
    };

    var getContentByKeyAndCampaignId = function(contentKey, campaignId) {
      return $http.get(
        '/CfcCharity/api/public/cms/detail/key/' + contentKey + '/' + campaignId
      );
    };

    var getActiveCampaignContent = function(contentKey, campaignId) {
      return $http.get(
        '/CfcCharity/api/public/cms/activecampaign/' +
          contentKey +
          '/' +
          campaignId
      );
    };

    return {
      getList: getList,
      getDetail: getDetail,
      getContent: getContent,
      saveContent: saveContent,
      deleteContent: deleteContent,
      getCategories: getCategories,
      getContentByKeyAndCampaignId: getContentByKeyAndCampaignId,
      getActiveCampaignContent: getActiveCampaignContent
    };
  }
})();
