(function() {
  var moduleName = 'CfcCharity.directives.videoLink';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    module = angular.module(moduleName, []);
  }

  module.directive('cfcVideoLink', [
    'cmsService',
    'BrowserEventService',
    '$log',
    function(cmsService, BrowserEventService, $log) {
      return {
        restrict: 'E',
        template: require('./videoLink.html'),
        scope: { contentKey: '@', campaignId: '@' },
        controller: function($scope) {
          function init() {
            cmsService
              .getActiveCampaignContent($scope.contentKey, $scope.campaignId)
              .then(function(resp) {
                if (resp.data) {
                  $scope.content = resp.data.content;
                }
              })
              .catch(function(err) {
                $log.error(
                  err.data || 'Request failed with status ' + err.status,
                  err
                );
              })
              .finally(function() {});
          }
          init();
        }
      };
    }
  ]);
})();
