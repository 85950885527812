/*
 * cube.service.js
 *
 * 'Cube' service wrapper for $http, named after the 'weighted companion cube' from Portal.
 *
 * This is a wrapper for $http, for the standardized format of the OPM CFC Charity app endpoints
 * which return a response of or extending 'gov.opm.charity.core.common.ValidationDetailResponse'.
 *
 * The available methods are: get, post, and put. Parameters follow their $http.<method> counter-
 * parts and we only have uniquely added the provideNotifications optional param, boolean.
 *
 * On error, a standardized error message will be displayed, via 'toastrService.error', alerting
 * the user, requiring the user to interact to acknowledge, and provide message information from
 * the server response.
 *
 * This separate service (factory, no instance created by a constructor) was determined to be the
 * best approach, as extending the native $http service is undesirable (such as via an injector),
 * and AngularJS (1.x) has no instancing of the $http service itself.
 *
 */
(function() {
  angular.module('CfcCharity.common').service('CubeSvc', CubeService);

  CubeService.$inject = [
    '$q',
    '$http',
    '$cacheFactory',
    '$timeout',
    'toastrService'
  ];

  function CubeService($q, $http, $cacheFactory, $timeout, toastrService) {
    var isInStateTransition = false;
    var isManualReleaseUsed = false;
    // the cake is a lie
    var cakeOb = $cacheFactory('cfc-war-cake-cache');
    // this is slightly hacky but it should prevent in-flight network requests from losing their cache ob
    var REINSTANTIATE_TIME_DELAY = 500;

    var messageType = {
      SUCCESS: 'SUCCESS',
      FAILURE: 'FAILURE',
      ERROR: 'ERROR',
      WARN: 'WARN',
      INFO: 'INFO'
    };

    var errorToastrOptions = {
      autoDismiss: false,
      closeButton: true,
      extendedTimeOut: 0,
      positionClass: 'toast-top-full-width',
      tapToDismiss: true,
      timeOut: 0,
      progressBar: false
    };

    return {
      get: cubeGet,
      post: cubePost,
      put: cubePut,
      delete: cubeDelete,
      messageType: messageType,
      displayMessages: displayMessages,
      setStateTransition: setStateTransition,
      setManualCacheRelease: setManualCacheRelease,
      performManualCacheRelease: performManualCacheRelease,
      displayWarningMessage: displayWarningMessage
    };

    // call, setting to true, in resolve block for state, then you MUST call performManaulCacheRelease after controller load (no matter what!)
    function setManualCacheRelease() {
      isManualReleaseUsed = true;
    }

    // clears the feature flag for setManualCacheRelease, which if set, this MUST BE CALLED
    function performManualCacheRelease() {
      isManualReleaseUsed = false;
      setStateTransition(false);
    }

    function setStateTransition(yn) {
      isInStateTransition = yn;
      if (false === yn && true === !isManualReleaseUsed) {
        $timeout(reinstantiateCakeOb, REINSTANTIATE_TIME_DELAY);
      }
    }

    function reinstantiateCakeOb() {
      cakeOb.removeAll();
    }

    function getCakeOb() {
      if (true === isInStateTransition || true === isManualReleaseUsed) {
        return cakeOb;
      } else {
        return false;
      }
    }

    function displayMessages(dataInput) {
      var data = dataInput.data;
      var i;
      var messages = '';
      if (data) {
        if (angular.isArray(data.validationMessageList)) {
          for (i in data.validationMessageList) {
            messages += data.validationMessageList[i].variableMessage + '</br>';
          }
        }
        if (data.message !== undefined) {
          if (data.message.type === messageType.FAILURE) {
            displayFailureMessage(messages);
          } else if (data.message.type === messageType.ERROR) {
            displayErrorMessage(data.message.description);
          } else if (data.message.type === messageType.WARN) {
            displayWarningMessage(messages);
          } else if (data.message.type === messageType.INFO) {
            displayInfoMessage(messages);
          }
        } else {
          if (data.errorCode != null) {
            displayErrorMessage(data.errorMessage);
          }
        }
      }
    }

    function displayWarningMessage(message) {
      toastrService.warning(message, 'WARNING: ', errorToastrOptions);
    }

    function displayFailureMessage(message) {
      toastrService.warning(
        message,
        'Please correct the errors',
        errorToastrOptions
      );
    }

    function displayErrorMessage(message) {
      toastrService.error(message, 'ERROR:', errorToastrOptions);
    }

    function displayInfoMessage(message) {
      toastrService.info(message, 'INFO', errorToastrOptions);
    }

    /**
     * Extends `$http.get`. String url is required, config object is optional. An additional
     * param of a boolean for provideNotifications is optional, defaults to true and the
     * param of a boolean for usePageLoadCaching defaults to true (but respects if you pass
     * a config object w/ `cache: false` set).
     *
     * @param {string} url
     * @param {{}=} config
     * @param {boolean=true} provideNotifications
     * @param {boolean=true} usePageLoadCaching
     */
    function cubeGet(url, config, provideNotifications, usePageLoadCaching) {
      var useCacheFromProps =
        true === isInStateTransition || true === isManualReleaseUsed;
      return $q(function(resolve, reject) {
        if (undefined === config) {
          if (useCacheFromProps) {
            if (false !== usePageLoadCaching) {
              config = { cache: getCakeOb() };
            }
          } else {
            config = null;
          }
        } else {
          if (false !== usePageLoadCaching && useCacheFromProps) {
            // object property spread, if the passed config ob contains a cache prop value, it wins
            config = { ...{ cache: getCakeOb() }, ...config };
          }
        }
        if (undefined === provideNotifications) {
          provideNotifications = true;
        } else {
          if (true !== provideNotifications && false !== provideNotifications) {
            // bad param, use default behavior
            provideNotifications = true;
          }
        }

        $http
          .get(url, config)
          .then(function(res) {
            if (true === provideNotifications) {
              displayMessages(res);
            }
            resolve(res);
          })
          .catch(function(err) {
            if (
              err != null &&
              err != undefined &&
              (err.data != null && err.data !== undefined)
            ) {
              if (true === provideNotifications) {
                displayMessages(err);
              }
              reject(err);
            }
          });
      });
    }

    /**
     * Extends `$http.delete`. String url is required, config object is optional. An additional
     * param of a boolean for provideNotifications is optional, defaults to true and the
     * param of a boolean for usePageLoadCaching defaults to true (but respects if you pass
     * a config object w/ `cache: false` set).
     *
     * @param {string} url
     * @param {{}=} config
     * @param {boolean=true} provideNotifications
     * @param {boolean=true} usePageLoadCaching
     */
    function cubeDelete(url, config, provideNotifications, usePageLoadCaching) {
      var useCacheFromProps =
        true === isInStateTransition || true === isManualReleaseUsed;
      return $q(function(resolve, reject) {
        if (undefined === config) {
          if (useCacheFromProps) {
            if (false !== usePageLoadCaching) {
              config = { cache: getCakeOb() };
            }
          } else {
            config = null;
          }
        } else {
          if (false !== usePageLoadCaching && useCacheFromProps) {
            // object property spread, if the passed config ob contains a cache prop value, it wins
            config = { ...{ cache: getCakeOb() }, ...config };
          }
        }
        if (undefined === provideNotifications) {
          provideNotifications = true;
        } else {
          if (true !== provideNotifications && false !== provideNotifications) {
            // bad param, use default behavior
            provideNotifications = true;
          }
        }

        $http
          .delete(url, config)
          .then(function(res) {
            if (true === provideNotifications) {
              displayMessages(res);
            }
            resolve(res);
          })
          .catch(function(err) {
            if (
              err != null &&
              err != undefined &&
              (err.data != null && err.data !== undefined)
            ) {
              if (true === provideNotifications) {
                displayMessages(err);
              }
              reject(err);
            }
          });
      });
    }

    /**
     * Extends `$http.post`. String url is required, data object is required,
     * config object is optional. Additional  param of a boolean for
     * provideNotifications is optional, defaults to true.
     *
     * @param {string} url
     * @param {{}} body
     * @param {{}=} config
     * @param {boolean=true} provideNotifications
     */
    function cubePost(url, body, config, provideNotifications) {
      return $q(function(resolve, reject) {
        if (undefined === config) {
          config = null;
        }
        if (undefined === provideNotifications) {
          provideNotifications = true;
        } else {
          if (true !== provideNotifications && false !== provideNotifications) {
            // bad param, use default behavior
            provideNotifications = true;
          }
        }

        $http
          .post(url, body, config)
          .then(function(res) {
            if (true === provideNotifications) {
              displayMessages(res);
            }
            resolve(res);
          })
          .catch(function(err) {
            // error encountered
            if (
              err != null &&
              err != undefined &&
              (err.data != null && err.data !== undefined)
            ) {
              if (true === provideNotifications) {
                displayMessages(err);
              }
              reject(err);
            }
          });
      });
    }

    /**
     * Extends `$http.put`. String url is required, data object is required,
     * config object is optional. Additional  param of a boolean for
     * provideNotifications is optional, defaults to true.
     *
     * @param {string} url
     * @param {{}} body
     * @param {{}=} config
     * @param {boolean=true} provideNotifications
     */
    function cubePut(url, body, config, provideNotifications) {
      return $q(function(resolve, reject) {
        if (undefined === config) {
          config = null;
        }
        if (undefined === provideNotifications) {
          provideNotifications = true;
        } else {
          if (true !== provideNotifications && false !== provideNotifications) {
            // bad param, use default behavior
            provideNotifications = true;
          }
        }

        $http
          .put(url, body, config)
          .then(function(res) {
            if (true === provideNotifications) {
              displayMessages(res);
            }
            resolve(res);
          })
          .catch(function(err) {
            if (
              err != null &&
              err != undefined &&
              (err.data != null && err.data !== undefined)
            ) {
              if (true === provideNotifications) {
                displayMessages(err);
              }
              reject(err);
            }
          });
      });
    }
  }
})();
