(function() {
  angular.module('CfcCharity.common').factory('CakeSvc', CakeService);

  CakeService.$inject = [];

  function CakeService() {
    return {
      transformGenericResponseToFlatResourceResponse: transformGenericResponseToFlatResourceResponse,
      addPropsTo: addPropsTo
    };
  }

  /**
   * Provides transformation for GenericResponseEntities into a flat object. GenericResponseEntities places
   * the body of the response under a `value` property, this moves those properties up and removes the artifacts
   * of the GenericResponseEntities response, specifically `validationmessageList`, `message`, and `value` properties.
   * This is achieved through moving the `value` into a temp var, removing the properties by name, then re-applying
   * the `value` contents through the object spread operator (ES6+);
   *
   * Note: the `message` and `validationMessageList` contents will be destroyed in this process, if you need to make
   * use of the messaging, invoke `CubeSvc.displayMessages(res)` on the `$http` (or `$resource`) response object first.
   *
   * @param {Object} srcOb
   */
  function transformGenericResponseToFlatResourceResponse(srcOb) {
    if (
      'value' in srcOb &&
      'validationMessageList' in srcOb &&
      'message' in srcOb
    ) {
      var tmpOb = angular.copy(srcOb.value);
      delete srcOb.validationMessageList;
      delete srcOb.message;
      delete srcOb.value;
      srcOb = addPropsTo(srcOb, tmpOb);
    }
    return srcOb;
  }

  /**
   * Applies properties from one object to another, optional third param of whether to preserve the prototype defaults
   * to true.
   *
   * @param {Object} srcOb
   * @param {Object} addOb
   * @param {boolean} keepProto
   */
  function addPropsTo(srcOb, addOb, keepProto = true) {
    var tmpProto = Object.getPrototypeOf(srcOb);
    if (true === keepProto) {
      return (srcOb = { __proto__: tmpProto, ...addOb, ...srcOb });
    } else {
      return (srcOb = { ...addOb, ...srcOb });
    }
  }
})();
