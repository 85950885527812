angular
  .module('CfcCharity.login', [
    'ui.router',
    'CfcCharity.security',
    'ngMessages',
    'ngIdle',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'KeepaliveProvider',
    'IdleProvider',
    'HateoasInterceptorProvider',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      KeepaliveProvider,
      IdleProvider,
      HateoasInterceptorProvider
    ) {
      $stateProvider.state('login', {
        url: '/login',
        views: {
          main: {
            template: require('../views/public/login.html'),
            controller: 'LoginCtrl'
          }
        },
        data: {
          pageTitle: 'Login'
        },
        params: {
          errorMessage: ''
        }
      });
      IdleProvider.idle(840); // 14 mins
      IdleProvider.timeout(60); // 1 min
    }
  ])
  .service('Session', function() {
    this.create = function(userId, userName, userRole, secondaryRoleList) {
      this.userId = userId;
      this.userName = userName;
      this.userRole = userRole;
      this.secondaryRoleList = secondaryRoleList;
    };
    this.update = function(user, secondaryRoleList) {
      if (user) {
        user.secondaryRoleList = secondaryRoleList;
        this.secondaryRoleList = secondaryRoleList;
      }
    };
    this.destroy = function() {
      this.userId = null;
      this.userName = null;
      this.userRole = null;
      this.secondaryRoleList = null;
    };
  })
  .factory('AuthService', [
    '$http',
    'Session',
    'TokenStorage',
    '$state',
    'SYSTEM_USER_SECONDARY_ROLES',
    'BrowserEventService',
    'UserService',
    'AppService',
    '$window',
    function(
      $http,
      Session,
      TokenStorage,
      $state,
      SYSTEM_USER_SECONDARY_ROLES,
      BrowserEventService,
      UserService,
      AppService,
      $window
    ) {
      var authService = {};
      authService.startSession = async function() {
        $http
          .post('/CfcCharity/api/private/user/startSession')
          .then(function() {})
          .catch(function() {});
      };

      authService.logout = function() {
        const cognitoDomain = AppService.getUserPoolDomain();
        const clientId = AppService.getCognitoClientId();
        const logoutUrl = AppService.getLogoutUrl();
        $http
          .post('/CfcCharity/api/private/logoutUser')
          .then(function(res) {
            $('.modal-backdrop').remove();
            TokenStorage.clear();
            BrowserEventService.setUserDashboardCurrentPage(1);
            sessionStorage.clear();
            $window.location.href = `https://${cognitoDomain}.auth.us-east-1.amazoncognito.com/logout?client_id=${clientId}&logout_uri=${logoutUrl}`;
          })
          .catch(function(response) {
            console.log(response);
          });
        Session.destroy();
      };
      authService.logoutStayOnPage = function() {
        $http
          .post('/CfcCharity/api/private/logoutUser')
          .then(function(res) {
            AppService.getAuth().signOut();
            TokenStorage.clear();
            BrowserEventService.setUserDashboardCurrentPage(1);
            sessionStorage.clear();
          })
          .catch(function(err) {
            console.log(err);
          });
        Session.destroy();
      };
      authService.getAuthenticatedUser = function(callback) {
        $http
          .get('/CfcCharity/api/private/users/current')
          .then(function(res) {
            var data = res.data;
            if (!data.errorCode) {
              Session.create(data.id, '', data.roles, data.secondaryRoleList);
              sessionStorage.setItem('USER_OBJ', JSON.stringify(data));
              UserService.setUser(data);
            } else if (data.errorCode && data.accountEnabled === 'false') {
              data.errorCode = undefined;
              data.accountEnabled = false;
            } else {
              Session.destroy();
            }
            if (callback != null) {
              callback(data);
            }
          })
          .catch(function(err) {
            Session.destroy();
            callback();
          });
      };

      authService.acceptSystemRoles = function(callback) {
        return $http
          .post('/CfcCharity/api/private/user/acceptSystemRoles')
          .then(function(res) {
            authService.getAuthenticatedUser(function(user) {
              if (!user) {
                throw new Error('error getting user');
              }
              var data = res.data;
              callback({ user, acceptSystemRolesResponse: data });
            });
          })
          .catch(function(err) {
            Session.destroy();
            callback();
          });
      };

      authService.isAuthenticated = function() {
        return !!Session.userId;
      };
      authService.isAuthorized = function(authorizedRoles) {
        if (!angular.isArray(authorizedRoles)) {
          authorizedRoles = [authorizedRoles];
        }
        var commonRoles = authorizedRoles.filter(function(n) {
          return Session.userRole && Session.userRole.indexOf(n) !== -1;
        });
        return (
          authService.isAuthenticated() &&
          commonRoles != null &&
          commonRoles.length > 0
        );
      };
      authService.isUserHavingSecondaryRole = function(
        authorizedSecondaryRoles
      ) {
        if (!angular.isArray(authorizedSecondaryRoles)) {
          authorizedSecondaryRoles = [authorizedSecondaryRoles];
        }
        var commonRoles = null;
        if (authorizedSecondaryRoles.indexOf('*') !== -1) {
          commonRoles = [];
          angular.forEach(SYSTEM_USER_SECONDARY_ROLES, function(v, n) {
            if (Session.secondaryRoleList.indexOf(v) !== -1) {
              commonRoles.push(v);
            }
          });
        } else {
          commonRoles = authorizedSecondaryRoles.filter(function(n) {
            return Session.secondaryRoleList.indexOf(n) !== -1;
          });
        }
        return (
          authService.isAuthenticated() &&
          commonRoles != null &&
          commonRoles.length > 0
        );
      };
      return authService;
    }
  ])
  .controller('LoginCtrl', [
    '$scope',
    '$state',
    '$window',
    '$http',
    '$location',
    'AppService',
    'TokenStorage',
    function(
      $scope,
      $state,
      $window,
      $http,
      $location,
      AppService,
      TokenStorage
    ) {
      $scope.loginErrors = $state.params.errorMessage;

      $scope.conditionsOfUse = function() {
        $state.go('termsofUse', {
          fromPage: $state.current.name
        });
      };
      $scope.privacyNotice = function() {
        $state.go('privacyNotice', {
          fromPage: $state.current.name
        });
      };
    }
  ]);
