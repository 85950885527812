// listingOverrideAfterClose.service.js - Services for the Admin Zone Reports module

'use strict';

angular
  .module('CfcCharity.charitydashboard')
  .factory(
    'ListingOverrideAfterCloseService',
    ListingOverrideAfterCloseService
  );

ListingOverrideAfterCloseService.$inject = ['$http'];

function ListingOverrideAfterCloseService($http) {
  var service = {
    getListingStartEndOverride: getListingStartEndOverride,
    setListingStartEndOverride: setListingStartEndOverride,
    isListingAvailableWithOverride: isListingAvailableWithOverride
  };
  return service;

  function getListingStartEndOverride(campaignId, cfcCode) {
    var url =
      '/CfcCharity/api/private/charity/' +
      cfcCode +
      '/' +
      campaignId +
      '/getListingStartEndOverride';
    return $http.get(url);
  }

  function setListingStartEndOverride(
    campaignId,
    cfcCode,
    startOverrideDate,
    endOverrideDate
  ) {
    var url =
      '/CfcCharity/api/private/charity/' +
      cfcCode +
      '/' +
      campaignId +
      '/updateListingStartEndOverride';
    return $http({
      method: 'POST',
      url: url,
      params: {
        listingStartOverride: startOverrideDate,
        listingEndOverride: endOverrideDate
      }
    });
  }

  function isListingAvailableWithOverride(campaignId, cfcCode) {
    var url =
      '/CfcCharity/api/private/charity/' +
      cfcCode +
      '/' +
      campaignId +
      '/getListingStatus';
    return $http.get(url);
  }
}
