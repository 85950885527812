(function() {
  var moduleName = 'CfcCharity.directives.adminReviewSectionStepDetermination';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }
  module.service('AdminReviewSectionStepDeterminationSessionService', [
    function() {
      var key = '673fd917-f56d-4458-aa26-24455a922e24';
      var setCurrentReviewLevel = function(reviewLevel) {
        var json = JSON.stringify(reviewLevel);
        sessionStorage.setItem(key, json);
      };
      var getCurrentReviewLevel = function() {
        var json = {};
        var data = sessionStorage.getItem(key);
        if (data != null) {
          try {
            json = JSON.parse(data);
          } catch (ex) {}
        }
        return json;
      };
      var clearCurrentReviewLevel = function() {
        sessionStorage.removeItem(key);
      };
      return {
        setCurrentReviewLevel: setCurrentReviewLevel,
        getCurrentReviewLevel: getCurrentReviewLevel,
        clearCurrentReviewLevel: clearCurrentReviewLevel
      };
    }
  ]);
  module.service('AdminReviewSectionStepDeterminationService', [
    '$http',
    'CubeSvc',
    'ZoneCenterService',
    function($http, CubeSvc, ZoneCenterService) {
      var getReviewLevelData = function(
        applicationId,
        applicationStep,
        isFedApp
      ) {
        if (isFedApp) {
          return retrieveFedAppStepReviewLevelData(
            applicationId,
            applicationStep
          );
        } else {
          return retrieveAppStepReviewLevelData(applicationId, applicationStep);
        }
      };

      function retrieveAppStepReviewLevelData(applicationId, applicationStep) {
        var data = {
          cfcCode: null,
          pledgeYear: null,
          zoneCode: null,
          reviewLevels: {},
          charityType: null,
          currentLevel: null
        };
        return CubeSvc.get(
          '/CfcCharity/api/private/application/details/' + applicationId
        ).then(function(response) {
          data.cfcCode = response.data.value.cfcCode;
          data.pledgeYear = response.data.value.pledgeYear;
          return $http
            .get(
              '/CfcCharity/api/private/application/getZoneDetails?applicationId=' +
                applicationId
            )
            .then(function(response) {
              data.zoneCode = response.data.zoneCode;
              return CubeSvc.get(
                '/CfcCharity/api/private/application/status/' + applicationId,
                null,
                false
              ).then(function(response) {
                var reviewLevels = response.data.value;
                for (var i = 0; i < reviewLevels.length; i++) {
                  if (applicationStep === '3' || applicationStep === '2a') {
                    applicationStep = 3;
                  }
                  if (reviewLevels[i].applicationStep === applicationStep) {
                    data.reviewLevels = reviewLevels[i];
                  }
                }
                return CubeSvc.get(
                  '/CfcCharity/api/private/charity/findCurrentCharityType?cfcCode=' +
                    data.cfcCode +
                    '&pledgeYear=' +
                    data.pledgeYear
                ).then(function(response) {
                  data.charityType = response.data.value.charityType;
                  return ZoneCenterService.findApplicationReview(
                    applicationId
                  ).then(function(response) {
                    data.currentLevel = response.data.value.currentLevel;
                    return data;
                  });
                });
              });
            });
        });
      }

      function retrieveFedAppStepReviewLevelData(
        applicationId,
        applicationStep
      ) {
        var data = {
          cfcCode: null,
          pledgeYear: null,
          zoneCode: null,
          reviewLevels: {},
          charityType: null,
          currentLevel: null
        };
        return $http
          .get(
            '/CfcCharity/api/private/federation/application/review/' +
              applicationId
          )
          .then(function(response) {
            if (response) {
              data.pledgeYear = response.data.year;
              data.cfcCode = response.data.cfcCode;
              data.currentLevel = response.data.currentLevel;
              return CubeSvc.get(
                '/CfcCharity/api/private/federation_application/status/' +
                  applicationId
              ).then(function(response) {
                var reviewLevels = response.data.value;
                for (var i = 0; i < reviewLevels.length; i++) {
                  if (reviewLevels[i].applicationStep === applicationStep) {
                    data.reviewLevels = reviewLevels[i];
                  }
                }

                return CubeSvc.get(
                  '/CfcCharity/api/private/federation/' + applicationId
                ).then(function(response) {
                  data.zoneCode =
                    null != response.data.value.zoneList
                      ? response.data.value.zoneList[0].zoneCode
                      : null;
                  return CubeSvc.get(
                    '/CfcCharity/api/private/charity/findCurrentCharityType?cfcCode=' +
                      data.cfcCode +
                      '&pledgeYear=' +
                      data.pledgeYear
                  ).then(function(response) {
                    data.charityType = response.data.value.charityType;
                    return data;
                  });
                });
              });
            }
          });
      }

      var isAuthorized = function() {
        var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));

        if (
          !user.secondaryRoleList ||
          !Array.isArray(user.secondaryRoleList) ||
          user.secondaryRoleList.length === 0
        ) {
          return false;
        }

        if (
          user.secondaryRoleList.indexOf('FEDERATION_OWNER') >= 0 ||
          user.secondaryRoleList.indexOf('CHARITY_OWNER') >= 0 ||
          user.secondaryRoleList.indexOf('OWNER') >= 0
        ) {
          return false;
        }
        if (
          user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          user.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
          user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
        ) {
          return true;
        }
        return false;
      };

      return {
        getReviewLevelData: getReviewLevelData,
        isAuthorized: isAuthorized
      };
    }
  ]);
  module.directive('cfcAdminReviewSectionStepDetermination', [
    '$log',
    '$state',
    '$rootScope',
    '$filter',
    'FederationApplicationService',
    'ApplicationService',
    'AdminReviewSectionStepDeterminationService',
    'ZoneCenterService',
    'FederationApplicationReviewService',
    'AdminReviewSectionStepDeterminationSessionService',
    'reviewLevelConstants',
    'ApplicationDashboardService',
    'reviewLevelService',
    'CubeSvc',
    function(
      $log,
      $state,
      $rootScope,
      $filter,
      FederationApplicationService,
      ApplicationService,
      AdminReviewSectionStepDeterminationService,
      ZoneCenterService,
      FederationApplicationReviewService,
      AdminReviewSectionStepDeterminationSessionService,
      reviewLevelConstants,
      ApplicationDashboardService,
      reviewLevelService,
      CubeSvc
    ) {
      return {
        restrict: 'E',
        template: require('./adminReviewSectionStepDetermination.html'),
        scope: true,
        link: function($scope, $element, $attrs) {},
        controller: function($scope, $element, $attrs) {
          $scope.show = false;
          $scope.isOpen = false;
          $scope.isReadOnly = false;
          var applicationId = null;
          var isFedApp = $scope.federationNavbar.step != null;
          $scope.isFedApp = isFedApp;
          var currentStep =
            $scope.appNavbar.step || $scope.federationNavbar.step;
          $scope.currentReviewStatus = {};
          $scope.reviewStatuses = [];

          function performStepStatusUpdate(isFederation, reviewOb) {
            if (isFederation) {
              return FederationApplicationReviewService.setStepReviewStatus(
                reviewOb.appId,
                reviewOb.step,
                reviewOb.level,
                reviewOb.status
              );
            } else {
              return ZoneCenterService.setStepReviewStatus(
                reviewOb.appId,
                reviewOb.step,
                reviewOb.level,
                reviewOb.status
              );
            }
          }

          function init() {
            var user = $scope.getCurrentUser();
            if (
              user.secondaryRoleList &&
              Array.isArray(user.secondaryRoleList)
            ) {
              $scope.show = AdminReviewSectionStepDeterminationService.isAuthorized();
              $scope.isReadOnly =
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0;
            }
            applicationId = isFedApp
              ? FederationApplicationService.getApplicationId()
              : ApplicationService.getApplicationId();
            loadReviewLevels(applicationId, currentStep, isFedApp);
            if (isFedApp) {
              if (applicationId != null) {
                url = '/CfcCharity/api/private/federation/' + applicationId;
                CubeSvc.get(url).then(function(res) {
                  $scope.result = res.data.value;
                  $scope.appStatus = $scope.result.applicationStatus;
                });
              }
            }
          }

          function loadReviewLevels(applicationId, currentStep, isFedApp) {
            if (
              AdminReviewSectionStepDeterminationService.isAuthorized() === true
            ) {
              AdminReviewSectionStepDeterminationService.getReviewLevelData(
                applicationId,
                currentStep,
                isFedApp
              ).then(function(result) {
                $scope.currentReviewStatus = reviewLevelService.getCurrentReviewLevel(
                  result
                );
                $scope.reviewStatuses = getAllReviewLevels(result);
              });
            }
          }

          function getAllReviewLevels(reviewLevel) {
            var data = [];
            data.push({
              displayLevel: 'Completeness',
              status:
                reviewLevel.reviewLevels.completenessReviewStatus || 'NotSet',
              level: 'completenessReviewStatus'
            });
            if (
              reviewLevel.zoneCode !== 'DOD' &&
              (reviewLevel.charityType === 'NATIONAL' ||
                reviewLevel.charityType === 'INTERNATIONAL')
            ) {
              data.push({
                displayLevel: 'Staff Eligibility',
                status: reviewLevel.reviewLevels.staffReviewStatus || 'NotSet',
                level: 'staffReviewStatus'
              });
            }
            if (reviewLevel.zoneCode !== 'DOD') {
              data.push({
                displayLevel: 'Preliminary Eligibility',
                status:
                  reviewLevel.reviewLevels.preliminaryReviewStatus || 'NotSet',
                level: 'preliminaryReviewStatus'
              });
            }
            data.push({
              displayLevel: 'Final Eligibility',
              status: reviewLevel.reviewLevels.finalReviewStatus || 'NotSet',
              level: 'finalReviewStatus'
            });
            return data;
          }

          init();

          $scope.setStepReviewLevelStatus = function(reviewLevel, status) {
            if (
              $scope.appStatus !== 'DISBANDED' &&
              $scope.appStatus !== 'WITHDRAWN'
            ) {
              performStepStatusUpdate(isFedApp, {
                appId: reviewLevel.applicationId,
                step: reviewLevel.step,
                level: reviewLevel.reviewLevel,
                status: status
              }).then(
                function(res) {
                  $scope.currentReviewStatus.status = status;
                  for (var i = 0; i < $scope.reviewStatuses.length; i++) {
                    if (
                      $scope.reviewStatuses[i].level === reviewLevel.reviewLevel
                    ) {
                      $scope.reviewStatuses[i].status = status;
                    }
                  }
                  $rootScope.$broadcast(
                    'reviewLevelStatusUpdateStep',
                    res.data.value
                  );
                },
                function(error) {
                  $log.error(error);
                }
              );
            }
          };
        }
      };
    }
  ]);
})();
