angular
  .module('CfcCharity.charitydashboard', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      /* pledge url (charity) */
      $stateProvider.state('charityPledgeDashboard', {
        url: '/charityPledgeDashboards',
        views: {
          main: {
            template: require('.//app/admin/charity/pledge/pledgeDashboard.html'),
            controller: 'CharityPledgeCtl'
          }
        },
        data: {
          pageTitle: 'Charity Pledge Dashboard',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          cfcCode: null,
          pledgeYear: null,
          isFederation: false,
          charityName: null,
          applicationType: null
        }
      });

      /* pledge url (federation)*/
      $stateProvider.state('federationPledgeDashboard', {
        url: '/federationPledgeDashboard',
        views: {
          main: {
            template: require('./app/admin/charity/pledge/federationPledgeDashboard.html'),
            controller: 'CharityPledgeCtl'
          }
        },
        data: {
          pageTitle: 'Charity Pledge Dashboard',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          cfcCode: null,
          pledgeYear: null,
          isFederation: false,
          charityName: null
        }
      });

      /* pledge url (Member)*/
      $stateProvider.state('memberPledgeDashboard', {
        url: '/memberPledgeDashboard',
        views: {
          main: {
            template: require('./app/admin/charity/pledge/memberPledgeDashboard.html'),
            controller: 'CharityPledgeCtl'
          }
        },
        data: {
          pageTitle: 'Charity Pledge Dashboard',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          cfcCode: null,
          pledgeYear: null,
          charityName: null,
          isMemberData: false
        }
      });

      $stateProvider.state('charitydashboard', {
        url: '/charityDashboard',
        views: {
          main: {
            template: require('../views/private/charity/charityDashboard.html'),
            controller: 'CharityDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Charity Home Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          charityDashboardCfcCode: null
        },
        resolve: {
          charityDashboardData: function($http, $stateParams, CubeSvc) {
            CubeSvc.setManualCacheRelease();
            if ($stateParams.charityDashboardCfcCode != null) {
              var cfcCode = $stateParams.charityDashboardCfcCode;
              var url =
                '/CfcCharity/api/private/users/charitydashboard/' + cfcCode;
              return CubeSvc.get(url).then(function(res) {
                return res.data.value;
              });
            }
          }
        }
      });
      $stateProvider.state('charitylisting', {
        url: '/charitylisting',
        views: {
          main: {
            template: require('../views/private/charity/charityListing.html'),
            controller: 'CharityListingCtrl'
          }
        },
        data: {
          breadcrumbs: function(appType) {
            if (appType.toLowerCase() === 'member') {
              return [
                {
                  crumbName: '#%federationCfc% Federation Listing Package',
                  crumbRoute: 'federationlisting',
                  crumbParams:
                    '{charityDashboardCfcCode: %federationCfc%, pledgeYear: %pledgeYear% }'
                },
                {
                  crumbName: 'Member Listings',
                  crumbRoute: 'federationmemberlistings',
                  crumbParams:
                    '{charityDashboardCfcCode: %cfcCode%, pledgeYear: %pledgeYear%, federationCfc: %federationCfc%}'
                }
              ];
            } else if (appType.toLowerCase() === 'federation') {
              return [
                {
                  crumbName: '#%federationCfc% Federation Listing Package',
                  crumbRoute: 'federationlisting',
                  crumbParams:
                    '{charityDashboardCfcCode: %federationCfc%, pledgeYear: %pledgeYear% }'
                }
              ];
            }
          },
          pageTitle: 'CFC Charity Listing',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          charityDashboardCfcCode: null,
          pledgeYear: null,
          userRole: null,
          appType: null
        },
        resolve: {
          selectedCharityData: function($http, $stateParams) {
            if ($stateParams.cfcCode && $stateParams.pledgeYear) {
              var data = {};
              data.charityDashboardCfcCode =
                $stateParams.charityDashboardCfcCode;
              data.pledgeYear = $stateParams.pledgeYear;
              return data;
            }
          },
          tokenData: function(
            $stateParams,
            BreadcrumbTokenDataService,
            $http,
            CubeSvc
          ) {
            var isFed = $stateParams.appType === 'FEDERATION';
            BreadcrumbTokenDataService.setIsFederation(isFed);
            if (isFed) {
              return CubeSvc.get(
                '/CfcCharity/api/private/federationapplication/' +
                  $stateParams.pledgeYear +
                  '/' +
                  $stateParams.charityDashboardCfcCode
              ).then(function(res) {
                var appId = res.data.value.federationId;
                if (appId !== undefined) {
                  return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                    appId
                  );
                }
              });
            } else {
              return $http
                .get(
                  '/CfcCharity/api/private/application/details/' +
                    $stateParams.pledgeYear +
                    '/' +
                    $stateParams.charityDashboardCfcCode
                )
                .then(function(res) {
                  var appId = res.data.id;
                  if (appId !== undefined) {
                    return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                      appId
                    );
                  }
                });
            }
          }
        }
      });
    }
  ])
  .service('ListingService', [
    '$http',
    function($http) {
      this.getCampaignInformation = function(campaignId) {
        var url = '/CfcCharity/api/private/admin/editcampaign/' + campaignId;
        return $http.get(url);
      };
    }
  ])
  .service('logoFileService', [
    'CubeSvc',
    function(CubeSvc) {
      this.getLogoURL = function(cfcCode, campaignId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/charitylogo/' + cfcCode + '/' + campaignId,
          null,
          false
        );
      };
    }
  ])
  .service('CharityDashboardService', [
    '$http',
    '$log',
    'CubeSvc',
    function($http, $log, CubeSvc) {
      var svc = this;
      /**
       * find Charity object with cfcCode
       */
      this.findCharityWithCFC = function(cfcCode) {
        var url = '/CfcCharity/api/private/charity/findCharityWithCFC';
        // CCA-3226: disables toaster notification now, to be re-implemented in future sprint
        return CubeSvc.get(url, { params: { cfcCode: cfcCode } }, false);
      };
      /**
       * updateCharity is used to update charity information
       */
      this.updateCharity = function(charity, updateType) {
        if (updateType === 'updateName') {
          return CubeSvc.post(
            '/CfcCharity/api/private/charity/updateCharityName',
            charity
          );
        } else if (updateType === 'updateStatus') {
          return CubeSvc.post(
            '/CfcCharity/api/private/charity/updateCharityStatus',
            charity
          );
        } else if (updateType === 'updateAddress') {
          return CubeSvc.post(
            '/CfcCharity/api/private/charity/updateCharityAddress',
            charity
          );
        } else if (updateType === 'updateContact') {
          return CubeSvc.post(
            '/CfcCharity/api/private/charity/updateCharityContact',
            charity
          );
        } else if (updateType === 'updateBank') {
          return CubeSvc.post(
            '/CfcCharity/api/private/charity/updateCharityBankInfo',
            charity
          );
        } else if (updateType === 'updateEin') {
          return CubeSvc.post(
            '/CfcCharity/api/private/charity/updateCharityEin',
            charity
          );
        }
      };
      this.updateInvalidBankingReasonCode = function(charity) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/updateInvalidBankingReasonCode',
          charity
        );
      };
      this.getCharityDashboardData = function(cfcCode) {
        var url = '/CfcCharity/api/private/users/charitydashboard/' + cfcCode;
        return CubeSvc.get(url);
      };
      /**
       * Get Charity Listing page information based on pledgeYear and cfcCode
       */
      this.getCharityListingPageInfo = function(pledgeYear, cfcCode) {
        var url =
          '/CfcCharity/api/private/charity/charitylistingpageinfo/' +
          pledgeYear +
          '/' +
          cfcCode;
        return CubeSvc.get(url);
      };
      /**
       * get Charity Listing Object based on pledge Year and cfcCode
       */
      this.getCharityListingObject = function(pledgeYear, cfcCode) {
        var url =
          '/CfcCharity/api/private/charity/charitylisting/' +
          pledgeYear +
          '/' +
          cfcCode;
        return CubeSvc.get(url);
      };

      /**
       * get access to Charity for given year for user as fed role for member charity
       */
      this.getCharityFedMemberAccessForYear = function(pledgeYear, cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/users/charitydashboard/memberauthbyfeduser/' +
            pledgeYear +
            '/' +
            cfcCode
        );
      };

      this.getCharityStatusHistory = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/viewCharityStatushistory/' + cfcCode
        );
      };

      this.getCharityOfficeAddressHistory = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/viewCharityAddresshistory/' + cfcCode
        );
      };

      this.getCharityBankInfoHistory = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/viewCharityBankInfohistory/' +
            cfcCode
        );
      };

      this.getCharityContactInfoHistory = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/viewCharityContactInfohistory/' +
            cfcCode
        );
      };
      /*
       * get Charity's Pledge summary data
       */
      /*    this.getCharitySummaryPledgeData = function(cfcCode) {
      if($stateParams.charityDashboardCfcCode != null){
        var cfcCode = $stateParams.charityDashboardCfcCode;
        var url = '/CfcCharity/api/private/users/charitydashboardpledges/' + cfcCode;
        return $http.get(url);
      }
    }*/

      /*Get DBA name approval histroy*/
      this.getDbaNameApprovalHistory = function(campaignId, cfcCode) {
        var url =
          '/CfcCharity/api/private/charity/' +
          cfcCode +
          '/' +
          campaignId +
          '/getCharityDbaNameChangeStatusHistory';
        return CubeSvc.get(url);
      };
      /*Get DBA Auth Letters*/
      this.getCharityDbaAuthLetters = function(pledgeYear, cfcCode, listingId) {
        var url =
          '/CfcCharity/api/private/charity/charitylisting/dbaauthletters/' +
          pledgeYear +
          '/' +
          cfcCode +
          '/' +
          listingId;
        return $http.get(url);
      };
      /**
       * updateCharityListing is used to update Charity Listing information
       */
      this.updateCharityListing = function(charityListing) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/updateCharityListing',
          charityListing
        );
      };

      this.updateDBAName = function(cfcCode, campaignId, _dbaName, irsName) {
        if (_dbaName == null) {
          _dbaName = '';
        }
        var url =
          '/CfcCharity/api/private/charity/listing/' +
          cfcCode +
          '/' +
          campaignId +
          '/updateDbaName';
        var queryParams = {
          dbaName: _dbaName,
          irsName: irsName
        };
        return CubeSvc.post(url, null, {
          params: queryParams,
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
      };

      /**
       * Update Bank Info in the Charity Listingpage
       */
      this.saveOrUpdateBankInfor = function(charityListingPageInfo) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/updateBankInfo',
          charityListingPageInfo
        );
      };
      /**
       * Update Listing Fee in the Charity Listingpage
       */
      this.updateCharityListingFee = function(
        charityListingPageInfo,
        campaignId
      ) {
        return $http.post(
          '/CfcCharity/api/private/charity/' +
            charityListingPageInfo.cfcCode +
            '/' +
            campaignId +
            '/updateListingFee?listingFee=' +
            charityListingPageInfo.overrideListingFee
        );
      };
      /**
       * Update Listing Fee in the Charity Listingpage
       */
      this.updateCharityAFR = function(charityListingPageInfo, campaignId) {
        return $http.post(
          '/CfcCharity/api/private/charity/' +
            charityListingPageInfo.cfcCode +
            '/' +
            campaignId +
            '/updateAfr?afr='
        );
      };
      /**
       * Update Listing Volunteer
       */
      this.updateCharityVolunteer = function(
        cfcCode,
        pledgeYear,
        volunteerData
      ) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/' +
            cfcCode +
            '/' +
            pledgeYear +
            '/updateVolunteerTrackingQuestions',
          volunteerData
        );
      };

      this.toggleUseDBAOnlyFlag = function(
        cfcCode,
        campaignId,
        useOnlyDbaFlag
      ) {
        return $http.post(
          '/CfcCharity/api/private/charity/' +
            cfcCode +
            '/' +
            campaignId +
            '/updateCharityUseOnlyDbaFlag/' +
            '?useOnlyDbaFlag=' +
            useOnlyDbaFlag
        );
      };

      this.saveSocialMediaHandles = function(cfcCode, socialMediaInfo) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/listing/' +
            cfcCode +
            '/' +
            'saveSocialMediaInfo',
          socialMediaInfo
        );
      };

      this.changeDbaNameApprovalStatusService = function(
        cfcCode,
        campaignId,
        status,
        reason
      ) {
        var url =
          '/CfcCharity/api/private/charity/' +
          cfcCode +
          '/' +
          campaignId +
          '/updateCharityDbaNameChangeStatus/' +
          '?dbaNameChangeStatus=' +
          status;
        if (reason !== undefined) {
          url =
            '/CfcCharity/api/private/charity/' +
            cfcCode +
            '/' +
            campaignId +
            '/updateCharityDbaNameChangeStatus/' +
            '?dbaNameChangeStatus=' +
            status +
            '&dbaStatusReason=' +
            reason;
        }
        return $http.post(url);
      };
    }
  ])
  .constant('LISTING_CATEGORY_CODES', {
    A: 'Arts, Culture, and Humanities',
    B: 'Education',
    C: 'Environment',
    D: 'Animal Related',
    E: 'Health Care',
    F: 'Mental Health & Crisis Intervention',
    G: 'Voluntary Health Associations & Medical Disciplines',
    H: 'Medical Research',
    I: 'Crime & Legal Related',
    J: 'Employment',
    K: 'Food, Agriculture & Nutrition',
    L: 'Housing & Shelter',
    M: 'Public Safety, Disaster Preparedness & Relief',
    N: 'Recreation & Sports',
    O: 'Youth Development',
    P: 'Human Services',
    Q: 'International, Foreign Affairs & National Security',
    R: 'Civil Rights, Social Action & Advocacy',
    S: 'Community Improvement & Capacity Building',
    T: 'Philanthropy, Voluntarism & Grantmaking Foundations',
    U: 'Science & Technology',
    V: 'Social Science',
    W: 'Public & Societal Benefit',
    X: 'Religion-Related',
    Y: 'Mutual $ Membership Benefit',
    Z: 'Unknown'
  })
  .controller('CharityDashboardCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$http',
    '$log',
    '$q',
    'charityDashboardData',
    'BrowserEventService',
    'CharityDashboardService',
    'AdministrationCenterService',
    'CharityService',
    'ApplicationService',
    'FederationApplicationService',
    'UserCharityRole',
    'ListingService',
    'Session',
    'blockUI',
    'blockUIConfig',
    'CommonService',
    'PledgeService',
    'DonationsService',
    'CubeSvc',
    'AnalyticsService',
    'AuthorizationHelper',
    'SYSTEM_USER_SECONDARY_ROLES',
    'toastrService',
    '$anchorScroll',
    'PaymentHistoryService',
    'ValidationService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $http,
      $log,
      $q,
      charityDashboardData,
      BrowserEventService,
      CharityDashboardService,
      AdministrationCenterService,
      CharityService,
      ApplicationService,
      FederationApplicationService,
      UserCharityRole,
      ListingService,
      session,
      blockUI,
      blockUIConfig,
      CommonService,
      PledgeService,
      DonationsService,
      CubeSvc,
      AnalyticsService,
      AuthorizationHelper,
      SYSTEM_USER_SECONDARY_ROLES,
      toastrService,
      $anchorScroll,
      PaymentHistoryService,
      ValidationService
    ) {
      $scope.setModuleName('CfcCharity.charitydashboard');
      blockUI.start();
      $scope.charityDashboardData = charityDashboardData;

      if (!charityDashboardData.charityStatus) {
        charityDashboardData.charityStatus = 'ACTIVE';
      }
      if ($scope.charityDashboardData.contactEmailAddress != null) {
        $scope.charityDashboardData.contactEmailAddress = $scope.charityDashboardData.contactEmailAddress.split(
          ';'
        );
      }
      $scope.editCharityAdress = false;
      $scope.editBankInfo = false;
      $scope.editContactInfo = false;
      $scope.updateLogo = false;
      $scope.disableEditAddress = false;
      $scope.charityInformation = null;
      $scope.bankInfo = null;
      $scope.showUpdateButton = false;
      $scope.hideEditLogoButton = false;
      $scope.isListingAvailable = false;
      $scope.isNotZoneManagerOrReviewerOrCoordinator = false;
      $scope.showDonations = false;
      $scope.isOpmAdmin = false;
      $scope.isOpmAdminOrOpmStaff = false;
      $scope.isFederationRole = false;
      $scope.isSystemUser = false;
      $scope.isDistributionActive = false;
      $scope.showListingLink = false;
      $scope.donationsAr = [];
      $scope.showHistoryButton = false;
      $scope.limit = 5;

      if (
        $scope.isSecondaryRoleSystemAdminUser() ||
        $scope.isSecondaryRoleFinanceUser() ||
        $scope.isSecondaryRoleOPMAuditor() ||
        $scope.isSecondaryRoleCSTier1()
      ) {
        $scope.isSystemUser = true;
      }
      if (
        $scope.isSecondaryRoleOPMAdmin() ||
        $scope.isSecondaryRoleOPMAuditor() ||
        $scope.isSecondaryRoleOPMStaff() ||
        $scope.isSecondaryRoleCSTier1() ||
        $scope.isSecondaryRoleCustomerServiceTier2Finance() ||
        $scope.isSecondaryRoleCustomerServiceManager()
      ) {
        $scope.showHistoryButton = true;
      }

      var init = function() {
        if (user.secondaryRoleList && Array.isArray(user.secondaryRoleList)) {
          if (user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0) {
            $scope.isOpmAdmin = true;
          }

          if (
            user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
            user.secondaryRoleList.indexOf('OPM_STAFF') >= 0
          ) {
            $scope.isOpmAdminOrOpmStaff = true;
          }

          angular.forEach(
            $scope.charityDashboardData.charityApplicationBeanList,
            function(value, key) {
              if (value.distributionPeriodActive) {
                $scope.isDistributionActive = true;
              } else {
                $scope.isDistributionActive = false;
              }
            }
          );

          // Hide pledges from roles: ZONE_MANAGER, REVIEWER or ZONE_COORDINATOR
          $scope.isNotZoneManagerOrReviewerOrCoordinator = !(
            user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
            user.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
            user.secondaryRoleList.indexOf('ZONE_COORDINATOR') >= 0
          );

          $scope.isCharityOwnerOrEditor =
            $scope.charityDashboardData.userRole === 'CHARITY_OWNER' ||
            $scope.charityDashboardData.userRole === 'CHARITY_EDITOR';
        }

        // handles navigation to charityDonations page
        $scope.goToDonationDashboard = function(donateOb) {
          $state.go('charityDonations', {
            pledgeYear: donateOb.pledgeYear,
            cfcCode: $scope.charityDashboardData.cfcCode,
            appType: getApplicationType(
              $scope.charityDashboardData.cfcCode,
              donateOb.pledgeYear
            ),
            charityName: $scope.charityDashboardData.charityName
          });
        };

        // CCA-3610
        $scope.canViewBankingInfoInReadonlyMode = AuthorizationHelper.userHasAnyRole(
          [
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
            SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance,
            SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
          ]
        );
        if ($scope.isOpmAdmin) {
          CharityService.getInvalidBankReasonCodes().then(function(res) {
            $scope.invalidBankingReasons = {
              selectedCode: '0',
              availableCodes: [{ code: '0', name: 'SELECT REASON:' }]
            };
            angular.forEach(res.data, function(item) {
              if (item.display) {
                $scope.invalidBankingReasons.availableCodes.push({
                  code: item.lookupCd,
                  name: item.lookupCd + ' - ' + item.lookupName
                });
              }
            });

            if ($scope.charityDashboardData.invalidBankingReasonCode == null) {
              $scope.invalidBankingReasons.selectedCode =
                $scope.invalidBankingReasons.availableCodes[0].code;

              $scope.invalidBankingReasonsEnabledFlag = true;
            } else {
              $scope.invalidBankingReasonsEnabledFlag = false;
              var savedReason = $scope.invalidBankingReasons.availableCodes.filter(
                function(item) {
                  return (
                    item.code ===
                    $scope.charityDashboardData.invalidBankingReasonCode
                  );
                }
              )[0];

              if (savedReason) {
                $scope.invalidBankingReasons.selectedCode = savedReason.code;
              }
            }
          });
        }
        // doesn't work for zone reviewer, etc., only those identified above per endpoint
        CharityService.getUsersWithPendingAccess(
          $scope.charityDashboardData.cfcCode
        )
          .then(function(resp) {
            $scope.pendingAccessRequest = resp.data ? resp.data.length : 0;
          })
          .catch(function(err) {
            $scope.pendingAccessRequest = null;
            $log.error(err);
          });

        $scope.displayViewPaymentHistoryButton = configureViewPaymentHistoryButton();
        if ($scope.displayViewPaymentHistoryButton) {
          //check if no history of ind and one of its app in the same year is having some payment history
          if (
            $scope.charityDashboardData.userId &&
            $scope.charityDashboardData.cfcCode &&
            $scope.charityDashboardData.charityName
          ) {
            PaymentHistoryService.retrieveConfirmedPayments(
              $scope.charityDashboardData.userId,
              $scope.charityDashboardData.cfcCode,
              $scope.charityDashboardData.charityName
            )
              .then(function(res) {
                var data = res.data;

                var paymentHistoryResponse = data;
                if (
                  paymentHistoryResponse &&
                  paymentHistoryResponse.statusCode === 'SUCCESS'
                ) {
                  $scope.paymentHistoryData =
                    paymentHistoryResponse.paymentHistoryBean;

                  angular.forEach(
                    $scope.paymentHistoryData.paymentBeanList,
                    function(app) {
                      if (
                        app.type.toLowerCase() === 'independent' ||
                        app.type.toLowerCase() === 'federation'
                      ) {
                        $scope.displayViewPaymentHistoryButton = true;
                      }
                    }
                  );
                }
              })
              .catch(function(err) {
                $log.error(err);
              }); // end error
          }
        }

        setTimeout(() => {
          angular.element('[data-toggle="tooltip"]').tooltip();
        });
      }; // end INIT

      function configureViewPaymentHistoryButton() {
        var applicationType = charityDashboardData.applicationType.toLowerCase();

        var neverIndependentOrFederation = true;
        angular.forEach(
          charityDashboardData.charityApplicationBeanList,
          function(app) {
            if (
              app.applicationId !== null &&
              (app.applicationType.toLowerCase() === 'independent' ||
                app.applicationType.toLowerCase() === 'federation')
            ) {
              neverIndependentOrFederation = false;
              return;
            }
          }
        );

        if (applicationType === 'member' && neverIndependentOrFederation) {
          return false;
        }

        if (
          charityDashboardData.adminWithCharityEditAccess ||
          $scope.isSecondaryRoleGbFinanceUser() ||
          $scope.isSecondaryRoleGBAdmin() ||
          $scope.isSecondaryRoleCustomerServiceManager() ||
          $scope.isSecondaryRoleOPMAdmin() ||
          $scope.isSecondaryRoleOPMAuditor() ||
          $scope.isSecondaryRoleOPMStaff() ||
          $scope.isSecondaryRoleCSTier1() ||
          $scope.isSecondaryRoleCustomerServiceTier2Finance()
        ) {
          return true;
        }

        return false;
      }

      $scope.goToEmailDashboard = function() {
        $state.go('charity-email-dashboard', {
          cfcCode: $scope.charityDashboardData.cfcCode
        });
      };

      $scope.updateInvalidBankAccountReasonCode = function(reasonCode) {
        var data = {
          cfcCode: $scope.charityDashboardData.cfcCode,
          invalidBankingReasonCode: reasonCode
        };
        CharityDashboardService.updateInvalidBankingReasonCode(
          JSON.stringify(data)
        )
          .then(function() {
            $scope.invalidBankingReasonsEnabledFlag = false;
            $scope.charityDashboardData.invalidBankingReasonCode = reasonCode;
            toastrService.success('Invalid Banking Reason saved.');
          })
          .catch(function(err) {
            $scope.tempInvalidBankingReasonCode = null;
            $log.error(err);
            toastrService.error('Invalid Banking Reason did not update.');
          });
      };

      CharityService.loadStates().then(function(res) {
        $rootScope.states = res.data;
      });

      var listingData = charityDashboardData.charityDashboardListingList;
      var listingViewData = [];

      angular.forEach(listingData, function(value, key) {
        var appStatus = '';
        angular.forEach(
          charityDashboardData.charityApplicationBeanList,
          function(beanValue, beanKey) {
            if (value.cfcCode === beanValue.cfcCode) {
              value.applicationStatus = beanValue.applicationStatus;
            }
          }
        );

        if (null != value.cfcCode) {
          listingViewData.push(value);
        }
        $scope.listingViewData = listingViewData;
      });

      var pledgeData = charityDashboardData.charityDashboardPledgeList;
      var pledgeViewData = [];

      angular.forEach(pledgeData, function(value, key) {
        angular.forEach($scope.listingViewData, function(beanValue, beanKey) {
          if (
            value.pledgeYear === beanValue.pledgeYear &&
            beanValue.listingStatus === 'Listing Added/Paid'
          ) {
            pledgeViewData.push(value);
          }
        });
        $scope.pledgeViewData = pledgeViewData;
      });

      var donationData = charityDashboardData.charityDashboardDonationList;
      var donationViewData = [];

      angular.forEach(donationData, function(value, key) {
        angular.forEach($scope.pledgeViewData, function(beanValue, beanKey) {
          if (value.pledgeYear === beanValue.pledgeYear) {
            donationViewData.push(value);
          }
        });
        $scope.donationViewData = donationViewData;
      });

      /* Check if pledge row is federation or independent */
      $scope.isFederation = function(cfcCode, year) {
        if (
          cfcCode != null &&
          year != null &&
          charityDashboardData.charityApplicationBeanList.length > 0
        ) {
          charityDashboardData.charityApplicationBeanList.sort(function(a, b) {
            if (a.pledgeYear !== b.pledgeYear) {
              return a.pledgeYear - b.pledgeYear;
            }
            return a.createdDate - b.createdDate;
          });
          for (
            var i = charityDashboardData.charityApplicationBeanList.length - 1;
            i >= 0;
            i--
          ) {
            if (
              charityDashboardData.charityApplicationBeanList[i].pledgeYear ===
                year &&
              charityDashboardData.charityApplicationBeanList[i].cfcCode ===
                cfcCode
            ) {
              if (
                charityDashboardData.charityApplicationBeanList[i]
                  .applicationType === 'FEDERATION'
              ) {
                return true;
              } else {
                return false;
              }
            }
          }
        }
      };

      /* Check for app type for cfc by given year */
      function getApplicationType(cfcCode, year) {
        if (
          cfcCode != null &&
          year != null &&
          charityDashboardData.charityApplicationBeanList.length > 0
        ) {
          for (
            var i = charityDashboardData.charityApplicationBeanList.length - 1;
            i >= 0;
            i--
          ) {
            if (
              charityDashboardData.charityApplicationBeanList[i].pledgeYear ===
                year &&
              charityDashboardData.charityApplicationBeanList[i].cfcCode ===
                cfcCode
            ) {
              return charityDashboardData.charityApplicationBeanList[i]
                .applicationType;
            }
          }
        }
      }

      var user = $scope.getCurrentUser();
      var secondLevelRoleList = user.secondaryRoleList;
      if (secondLevelRoleList && secondLevelRoleList.length > 0) {
        for (var j = 0; j < secondLevelRoleList.length; j++) {
          var secondLevelValue = secondLevelRoleList[j];
          if (
            secondLevelValue === 'OPM_ADMIN' ||
            secondLevelValue === 'OPM_STAFF' ||
            secondLevelValue === 'GB_ADMIN'
          ) {
            $scope.showUpdateButton = true;
          }
          if (
            secondLevelValue === 'OPM_ADMIN' ||
            secondLevelValue === 'OPM_STAFF'
          ) {
            $scope.charityDashboardData.adminWithCharityEditAccess = true;
          }
        }
      } else {
        if ($scope.isFederationRole) {
          $scope.showUpdateButton = true;
        }
      }

      if (user.secondaryRoleList && user.secondaryRoleList.length > 0) {
        for (var j = 0; j < user.secondaryRoleList.length; j++) {
          var secondRole = user.secondaryRoleList[j];
          if (
            secondLevelValue === 'OPM_ADMIN' ||
            secondLevelValue === 'OPM_STAFF' ||
            secondLevelValue === 'ZONE_MANAGER' ||
            secondLevelValue === 'REVIEWER' ||
            secondLevelValue === 'CUSTOMER_SERVICE_TIER_1' ||
            secondLevelValue === 'CUSTOMER_SERVICE_TIER_2_FINANCE' ||
            secondLevelValue === 'CUSTOMER_SERVICE_MANAGER' ||
            secondLevelValue === 'OPM_AUDITOR' ||
            secondLevelValue === 'GB_ADMIN' ||
            secondLevelValue === 'GB_FINANCE_ADMIN' ||
            secondLevelValue === 'GB_FINANCE'
          ) {
            $scope.isListingAvailable = true;
            $scope.showListingLink = true;
          }
        }
      } else {
        if (
          charityDashboardData.userRole == null ||
          charityDashboardData.userRole === undefined
        ) {
          charityDashboardData.userRole = 'NONE';
        }
        if (
          charityDashboardData.userRole.toUpperCase() === 'CHARITY_OWNER' ||
          charityDashboardData.userRole.toUpperCase() === 'OWNER' ||
          charityDashboardData.userRole.toUpperCase() === 'CHARITY_EDITOR' ||
          charityDashboardData.userRole.toUpperCase() === 'EDITOR' ||
          charityDashboardData.isFederationOwner ||
          charityDashboardData.currentFederationMember ||
          charityDashboardData.federationUser ||
          charityDashboardData.adminWithCharityEditAccess
        ) {
          $scope.isListingAvailable = true;
          $scope.showListingLinkIfListingOpen = true;
        } else {
          $scope.isListingAvailable = false;
          $scope.showListingLinkIfListingOpen = false;
        }
      }

      if (
        user.userRole === 'CHARITY_OWNER' ||
        user.userRole === 'CHARITY_EDITOR' ||
        $scope.charityDashboardData.userRole === 'CHARITY_OWNER' ||
        $scope.charityDashboardData.userRole === 'CHARITY_EDITOR'
      ) {
        $scope.showUpdateButton = true;
      }

      if (
        user.userRole === 'CHARITY_EDITOR' ||
        $scope.charityDashboardData.userRole === 'CHARITY_EDITOR'
      ) {
        $scope.isCharityEditor = true;
      }

      if (
        user.userRole === 'CHARITY_OWNER' ||
        $scope.charityDashboardData.userRole === 'CHARITY_OWNER'
      ) {
        $scope.isCharityOwner = true;
      }
      CharityDashboardService.getCharityFedMemberAccessForYear(
        $scope.charityDashboardData.latestPledgeYear,
        $scope.charityDashboardData.cfcCode
      )
        .then(function(res) {
          var yn = 'true' === res.data;
          if (yn) {
            //federation owner/editor
            $scope.isCharityOwner = false;
            $scope.showUpdateButton = true;
            $scope.isFederationRole = true;
          }
        })
        .catch(function(err) {
          $log.error(err);
        });

      if (
        $scope.charityDashboardData != null &&
        $scope.charityDashboardData !== undefined
      ) {
        BrowserEventService.setCharityName(
          $scope.charityDashboardData.charityName
        );
        BrowserEventService.setCfcCode($scope.charityDashboardData.cfcCode);
        if ($scope.charityDashboardData.charityApplicationBeanList) {
          if (
            $scope.charityDashboardData.charityApplicationBeanList[0] !==
              undefined &&
            ($scope.charityDashboardData.charityApplicationBeanList[0]
              .applicationStatus === 'IN_PROGRESS' ||
              $scope.charityDashboardData.charityApplicationBeanList[0]
                .applicationStatus === 'SIGNED')
          ) {
            $scope.hideEditLogoButton = true;
          }
        }
        if (
          $scope.charityDashboardData.bankName != null &&
          $scope.charityDashboardData.bankName !== '' &&
          $scope.charityDashboardData.bankAccountNumber != null &&
          $scope.charityDashboardData.bankAccountNumber !== ''
        ) {
          var bankName = $scope.charityDashboardData.bankName;
          var bankAccNum = $scope.charityDashboardData.bankAccountNumber;
          bankAccNum =
            bankAccNum && bankAccNum.length > 4
              ? bankAccNum.substring(bankAccNum.length - 4, bankAccNum.length)
              : bankAccNum;
          if (bankAccNum.length > 0) {
            $scope.bankInfo = bankName + ' - account ending ' + bankAccNum;
          } else {
            $scope.bankInfo = null;
          }
        } else {
          $scope.bankInfo = null;
        }
      }
      blockUI.stop();
      $scope.inviteUser = function() {
        $state.go('inviteUserToCharity', {
          charityDashboardCfcCode: $scope.charityDashboardData.cfcCode
        });
      };
      $scope.getCharityUsers = function() {
        $state.go('manageCharityUsers', {
          cfcCode: $scope.charityDashboardData.cfcCode,
          charityName: $scope.charityDashboardData.charityName
        });
      };
      /**
       * Reload charity Dash board function to reload Dash board with latest data
       * */
      $scope.reloadCharityDashboard = function() {
        var toState = {
          name: 'charityDashboard'
        };
        AnalyticsService.addUserPageActivity(toState, '', $http);
        $scope.editCharityAdress = false;
        $scope.editBankInfo = false;
        $scope.editContactInfo = false;
        $state.go('charitydashboard', {
          charityDashboardCfcCode: $scope.charityDashboardData.cfcCode
        });
      };
      /**
       * Edit Charity Address function to show address fields in edit mode.
       */
      $scope.editCharityAddress = function() {
        var toState = {
          name: 'editCharityAddress'
        };
        AnalyticsService.addUserPageActivity(toState, '', $http);
        $scope.disableEditAddress = false;

        CharityDashboardService.findCharityWithCFC(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.charityInformation = result;
          }
        });
        $scope.editCharityAdress = true;
        $scope.editBankInfo = false;
        $scope.editContactInfo = false;
      };
      /**
       * Update Charity address to update charity address in charity table
       */
      $scope.updateCharityAddress = function(charityInformation, updateType) {
        $scope.fieldsMissing = [];
        $scope.emailsValid = validateContactEmail(
          charityInformation.contactEmailAddress
        );
        if (!$scope.emailsValid) {
          angular.element('#charityContactInfoValidationModal').modal('show');
          return;
        }

        CharityDashboardService.updateCharity(
          charityInformation,
          updateType
        ).then(function(res) {
          CubeSvc.performManualCacheRelease();
          var result = res.data;
          if (result.message.type !== CubeSvc.messageType.FAILURE) {
            $scope.isCharityUpdated = true;

            CharityDashboardService.getCharityDashboardData(
              $scope.charityDashboardData.cfcCode
            ).then(function(resp) {
              var toState = {
                name: 'charityDashboard'
              };
              AnalyticsService.addUserPageActivity(toState, '', $http);
              $scope.charityDashboardData = resp.data.value;
              if ($scope.charityDashboardData.contactEmailAddress != null) {
                $scope.charityDashboardData.contactEmailAddress = $scope.charityDashboardData.contactEmailAddress.split(
                  ';'
                );
              }
              $('#statusCancelBtn').click();
              $('#nameCancelBtn').click();
              $('#einCancelBtn').click();
              $scope.editCharityAdress = false;
              $scope.editBankInfo = false;
              $scope.editContactInfo = false;
              if (secondLevelRoleList && secondLevelRoleList.length > 0) {
                for (var j = 0; j < secondLevelRoleList.length; j++) {
                  var secondLevelValue = secondLevelRoleList[j];
                  if (
                    secondLevelValue === 'OPM_ADMIN' ||
                    secondLevelValue === 'OPM_STAFF' ||
                    secondLevelValue === 'GB_ADMIN'
                  ) {
                    $scope.showUpdateButton = true;
                  }
                  if (
                    secondLevelValue === 'OPM_ADMIN' ||
                    secondLevelValue === 'OPM_STAFF'
                  ) {
                    $scope.charityDashboardData.adminWithCharityEditAccess = true;
                  }
                }
              } else {
                if ($scope.isFederationRole) {
                  $scope.showUpdateButton = true;
                }
              }
            });
          }
        });
      };

      function validateContactEmail(emails) {
        if (!emails || emails.length === 0) {
          $scope.fieldsMissing.push('Contact Email is not filled in');
          return false;
        }

        var validateEmail = ValidationService.validateMultipleEmails(
          'Contact Email',
          emails
        );

        if (validateEmail) {
          $scope.fieldsMissing.push(validateEmail);
          return false;
        }

        return true;
      }

      $scope.updateCharityName = function(charityInformation) {
        if (charityInformation.charityName.length < 1) {
          $scope.errorMessage = 'Charity Name is Required';
          return;
        }
        $scope.updateCharityAddress(charityInformation, 'updateName');
      };

      $scope.updateCharityEin = function(charityInformation) {
        if (charityInformation.charityEin.length < 1) {
          $scope.errorMessage = 'Charity EIN is Required';
          return;
        }
        $scope.updateCharityAddress(charityInformation, 'updateEin');
      };

      /**
       * Update Charity Bank Info to update Charity Banking Information
       */
      $scope.updateCharityBankInfo = function(charityInformation) {
        CharityDashboardService.updateCharity(
          charityInformation,
          'updateBank'
        ).then(function(res) {
          CubeSvc.performManualCacheRelease();
          var result = res.data;
          if (result.message.type !== CubeSvc.messageType.FAILURE) {
            $scope.isCharityUpdated = true;
            $scope.charity = result.charityData;
            CharityDashboardService.getCharityDashboardData(
              $scope.charityDashboardData.cfcCode
            ).then(function(resp) {
              var toState = {
                name: 'charityDashboard'
              };
              $scope.invalidBankingReasonsEnabledFlag = true;
              if ($scope.invalidBankingReasons) {
                $scope.invalidBankingReasons.selectedCode = '0';
              }
              AnalyticsService.addUserPageActivity(toState, '', $http);
              $scope.charityDashboardData = resp.data.value;
              $scope.charityDashboardData.contactEmailAddress = $scope.charityDashboardData.contactEmailAddress.split(
                ';'
              );
              $scope.editCharityAdress = false;
              $scope.editBankInfo = false;
              $scope.editContactInfo = false;
              if (secondLevelRoleList && secondLevelRoleList.length > 0) {
                for (var j = 0; j < secondLevelRoleList.length; j++) {
                  var secondLevelValue = secondLevelRoleList[j];
                  if (
                    secondLevelValue === 'OPM_ADMIN' ||
                    secondLevelValue === 'OPM_STAFF' ||
                    secondLevelValue === 'GB_ADMIN'
                  ) {
                    $scope.showUpdateButton = true;
                  }
                  if (
                    secondLevelValue === 'OPM_ADMIN' ||
                    secondLevelValue === 'OPM_STAFF'
                  ) {
                    $scope.charityDashboardData.adminWithCharityEditAccess = true;
                  }
                }
              } else {
                if ($scope.isFederationRole) {
                  $scope.showUpdateButton = true;
                }
              }
              if (
                $scope.charityDashboardData.bankName != null &&
                $scope.charityDashboardData.bankName !== '' &&
                $scope.charityDashboardData.bankAccountNumber != null &&
                $scope.charityDashboardData.bankAccountNumber !== ''
              ) {
                var bankName = $scope.charityDashboardData.bankName;
                var bankAccNum = $scope.charityDashboardData.bankAccountNumber;
                if (bankAccNum.length > 0) {
                  $scope.bankInfo =
                    bankName +
                    ' - account ending ' +
                    bankAccNum.substring(
                      bankAccNum.length - 4,
                      bankAccNum.length
                    );
                } else {
                  $scope.bankInfo = null;
                }
              } else {
                $scope.bankInfo = null;
              }
            });
          }
        });
      };
      $scope.editBankDetails = function() {
        var toState = {
          name: 'editCharityBankDetails'
        };
        AnalyticsService.addUserPageActivity(toState, '', $http);
        CharityDashboardService.findCharityWithCFC(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.charityInformation = result;
          }
        });
        $scope.editBankInfo = true;
        $scope.editCharityAdress = false;
        $scope.editContactInfo = false;
      };

      $scope.editContactDetails = function() {
        var toState = {
          name: 'editCharityContactDetails'
        };
        AnalyticsService.addUserPageActivity(toState, '', $http);
        CharityDashboardService.findCharityWithCFC(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.charityInformation = result;
          }
        });
        $scope.editBankInfo = false;
        $scope.editCharityAdress = false;
        $scope.editContactInfo = true;
      };

      $scope.viewOfficeAddressHistory = function() {
        //get the office address history
        CharityDashboardService.getCharityOfficeAddressHistory(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          $scope.limit = 5;
          $scope.viewOfficeAddressHistoryList = res.data.value;
          $('#viewOfficeHistory').modal('show');
        });
      };

      $scope.viewBankInfoHistory = function() {
        //get the status history
        CharityDashboardService.getCharityBankInfoHistory(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          $scope.limit = 5;
          $scope.viewBankInfoHistoryList = res.data.value;
          $('#viewHistoryBankAccount').modal('show');
        });
      };

      $scope.viewContactInfoHistory = function() {
        //get the status history
        CharityDashboardService.getCharityContactInfoHistory(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          $scope.limit = 5;
          $scope.viewContactInfoHistoryList = res.data.value;
          $scope.totalElements = $scope.viewContactInfoHistoryList.length;
          $('#viewCharityContactHistory').modal('show');
        });
      };

      $scope.setLimit = function(lim, items) {
        $scope.limit = lim <= 0 ? items.length : lim;
      };

      $scope.editCharityName = function() {
        var toState = {
          name: 'editCharityName'
        };
        AnalyticsService.addUserPageActivity(toState, '', $http);
        $scope.errorMessage = '';
        CharityDashboardService.findCharityWithCFC(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.charityInformation = result;
          }
        });
      };
      $scope.editCharityEin = function() {
        var toState = {
          name: 'editCharityEin'
        };
        AnalyticsService.addUserPageActivity(toState, '', $http);
        $scope.errorMessage = '';
        CharityDashboardService.findCharityWithCFC(
          $scope.charityDashboardData.cfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.charityInformation = result;
          }
        });
      };
      /**
       * GoToCharityListing Function is used to navigate to CharityListing page using userId, cfcCode and pledgeYear
       */
      $scope.goToCharityListing = function(pledgeYear, cfcCode, appType) {
        if (pledgeYear && pledgeYear != null && pledgeYear !== '') {
          BrowserEventService.setPledgeYear(pledgeYear);
          BrowserEventService.setAppType(appType);
          $state.go('charitylisting', {
            charityDashboardCfcCode: $scope.charityDashboardData.cfcCode,
            pledgeYear: pledgeYear,
            appType: appType
          });
        }
      };
      /**
       * GoToFederationListing Function is used to navigate to FederationListing page using userId, federationCfcCode and pledgeYear
       */
      $scope.goToFederationListing = function(pledgeYear, federationCfcCode) {
        if (pledgeYear && pledgeYear != null && pledgeYear !== '') {
          if (federationCfcCode == null || federationCfcCode === '') {
            federationCfcCode = $scope.charityDashboardData.cfcCode;
          }
          $state.go('federationlisting', {
            charityDashboardCfcCode: federationCfcCode,
            pledgeYear: pledgeYear
          });
        }
      };
      $scope.scrollTo = function(id) {
        var old = $location.hash();
        $location.hash(id);
        $anchorScroll();
        $location.hash(old);
      };
      $scope.startApplication = function(cfcCode) {
        ApplicationService.reset();
        FederationApplicationService.reset();
        var currentPledgeYear = sessionStorage.getItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
        );
        CharityService.getPreApplicationDetails(
          cfcCode,
          currentPledgeYear
        ).then(function(res) {
          var data = res.data.value;
          ApplicationService.setPreApplicationDetails(data);
          FederationApplicationService.setPreApplicationDetails(data);
          $scope.applicationType = data.applicationType;
          var prevYrOb = {};
          CharityService.getPrevYearApplicationStatus(
            cfcCode,
            currentPledgeYear
          )
            .then(function(res) {
              prevYrOb = res.data.value;
            })
            .catch(function(err) {
              $log.error(err);
              prevYrOb = {
                hasPrevYearApplication: false,
                applicationType: null,
                parentFederationCfcCode: null
              };
            })
            .finally(function() {
              $state.go('applicationTypeHome', { prevYearObj: prevYrOb });
            });
        });
      };
      $scope.createApplication = function() {
        $('.modal-backdrop').remove();
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var userId = userObj.id;
        var campaignId = ApplicationService.getPreApplicationDetails()
          .campaignId;
        var pledgeYear = ApplicationService.getPreApplicationDetails()
          .pledgeYear;
        var cfcCode = ApplicationService.getPreApplicationDetails().cfcCode;
        $scope.setModuleName('CfcCharity.application');
        ApplicationService.setPledgeYear(pledgeYear);
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setCampaignId(campaignId);
        $scope.setAppPledgeYear(pledgeYear);
        $scope.setAppCfcCode(cfcCode);
        var application = ApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.campaignId = campaignId;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        application.createdBy = userId;
        application.createdDate = ApplicationService.toUTCDate(new Date());
        var userSession = ApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        userSession.userId = userId;
        application.$save(
          function(response) {
            userSession.applicationId = response.id;
            ApplicationService.setApplicationId(response.id);
            ApplicationService.setParentFederationApplicationId(
              response.federationId || null
            );
            var role = UserCharityRole.get(
              {
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                  new Date()
                );
                userSession.$save(
                  function(response) {
                    $state.go('step1', {
                      applicationId: ApplicationService.getApplicationId(),
                      mode: 'EDIT',
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  },
                  function(failedResponse) {
                    /*Todo: Log error*/
                  }
                );
              }
            );
          },
          function(failedResponse) {
            /*Todo: Log error*/
          }
        );
      };
      $scope.createFederationApplication = function() {
        $('.modal-backdrop').remove();
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var userId = userObj.id;
        var campaignId = FederationApplicationService.getPreApplicationDetails()
          .campaignId;
        var pledgeYear = FederationApplicationService.getPreApplicationDetails()
          .pledgeYear;
        var cfcCode = FederationApplicationService.getPreApplicationDetails()
          .cfcCode;
        $scope.setModuleName('CfcCharity.federation_application');
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        FederationApplicationService.setCampaignId(campaignId);
        $scope.setFederationAppPledgeYear(pledgeYear);
        $scope.setFederationAppCfcCode(cfcCode);
        var application = FederationApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.campaignId = campaignId;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        application.createdDate = FederationApplicationService.toUTCDate(
          new Date()
        );
        var userSession = FederationApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        application.$save(
          function(response) {
            userSession.applicationId = response.id;
            var role = UserCharityRole.get(
              {
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
                  new Date()
                );
                userSession.$save(
                  function(response) {
                    FederationApplicationService.setUserSession(response);
                    $state.go('federationStep1');
                  },
                  function(failedResponse) {
                    /*Todo: Log error*/
                  }
                );
              }
            );
          },
          function(failedResponse) {
            /*Todo: Log error*/
          }
        );
      };

      $scope.goToAppealPage = function(charityApplicationBean) {
        $state.go('appeal-dashboard', {
          isFederation: charityApplicationBean.applicationType === 'FEDERATION',
          applicationId: charityApplicationBean.applicationId
        });
      };

      init();
    }
  ])
  .controller('CharityListingCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$timeout',
    '$log',
    '$filter',
    '$http',
    'LISTING_CATEGORY_CODES',
    'toastrService',
    'DashboardService',
    'CharityService',
    'CharityDashboardService',
    'BrowserEventService',
    'Session',
    'blockUI',
    'DownloadService',
    'ListingOverrideAfterCloseService',
    'CubeSvc',
    'urlAccess',
    'tokenData',
    'logoFileService',
    'PLEDGE_YEARS',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $timeout,
      $log,
      $filter,
      $http,
      LISTING_CATEGORY_CODES,
      toastrService,
      DashboardService,
      CharityService,
      CharityDashboardService,
      BrowserEventService,
      session,
      blockUI,
      DownloadService,
      ListingOverrideAfterCloseService,
      CubeSvc,
      urlAccess,
      tokenData,
      logoFileService,
      PLEDGE_YEARS
    ) {
      $scope.setModuleName('CfcCharity.charityListings');
      $scope.category_codes = LISTING_CATEGORY_CODES;
      var user = $scope.getCurrentUser();
      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
      var montext;
      $scope.DBAnotRequested = true;

      $scope.displaySocialMediaHandles = true;
      if (
        tokenData.pledgeYear &&
        tokenData.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2023
      ) {
        $scope.displaySocialMediaHandles = false;
      }

      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationListingAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          if ($scope.$parent.$$childHead.breadcrumbs.length > 1) {
            urlAccess
              .getMemberListingAccess(
                tokenData.pledgeYear,
                tokenData.federationCfc,
                $scope.$parent.$$childHead.breadcrumbs[1]
              )
              .then(function(res) {
                $scope.$parent.$$childHead.breadcrumbs[1] = res;
              });
          }
        }
      }
      $('#editStartDate').datepicker({ dateFormat: 'yy-mm-dd' });
      $('#editEndDate').datepicker({ dateFormat: 'yy-mm-dd' });

      $scope.irsName = BrowserEventService.getCharityName();
      $scope.secondaryRoleList = secondaryRoleList;
      $scope.charityInformation = null;
      $scope.charityDbaAuthLetters = null;
      $scope.charityListingPageInfo = null;
      $scope.charityListingObject = null;
      $scope.charityListingViewObject = null;
      $scope.dbaletter = {};
      $scope.pledgeYear = null;
      $scope.selectedPledgeYearObject = null;
      $scope.showListingPeriodErrorMessage = false;
      $scope.applicationInProgress = false;
      $scope.listingPeriodEndedMessage = '';
      $scope.charityListingSuccessMsg = '';
      $scope.allowEditListingInfo = true;
      $scope.enablePayButton = false;
      $scope.campaignId = null;
      $scope.isCharityOwner = false;
      $scope.canEditDbaInfo = false;
      $scope.dashboardLoading = true;
      $scope.canGetDbaNameApprovalHistory = false;
      $scope.showIndependentListingFee = false;
      $scope.volunteerSkill = null;
      $scope.tempVolunteerSkills = [];
      $scope.selectedVolunteerSkill = null;
      $scope.volunteerDirty = false;

      $http
        .get('/CfcCharity/api/private/charity/skillsJobCategories')
        .then(function(res) {
          $scope.categories = res.data;
        })
        .catch(function(err) {
          $log.error(err);
        });

      $scope.findCategoryName = function(id) {
        return $scope.categories.filter(i => i.id == id)[0].categoryName;
      };

      $http
        .get('/CfcCharity/api/private/charity/skillsJobSubcategories')
        .then(function(res) {
          $scope.subcategories = res.data;
        })
        .catch(function(err) {
          $log.error(err);
        });

      $scope.findSubCategoryName = function(id) {
        return $scope.subcategories.filter(i => i.id == id)[0].subcategoryName;
      };

      $scope.displaySubCategoryName = function(id, otherSkill) {
        const subcategoryName = $scope.subcategories.filter(i => i.id == id)[0]
          .subcategoryName;
        if (subcategoryName === 'Other') {
          return otherSkill;
        }

        return subcategoryName;
      };

      $scope.isOtherSkillRequired = function(id) {
        return (
          id &&
          $scope.subcategories.filter(i => i.id == id)[0].subcategoryName ==
            'Other'
        );
      };

      $http
        .get('/CfcCharity/api/private/charity/skillsActivityTypes')
        .then(function(res) {
          $scope.skillsActivityTypes = res.data;
        })
        .catch(function(err) {
          $log.error(err);
        });

      $scope.findActivityTypeName = function(id) {
        return $scope.skillsActivityTypes.filter(i => i.id == id)[0].typeName;
      };

      $scope.filterSubCategories = function() {
        $scope.filteredSubcategories = $scope.subcategories.filter(
          i => i.jobCategory.id == $scope.volunteerSkill.jobCategory
        );
        // if category is 'Other', select 'Other' automatically for subcategory
        if (
          $scope.categories.filter(
            i => i.id == $scope.volunteerSkill.jobCategory
          )[0].categoryName === 'Other'
        ) {
          $scope.volunteerSkill.jobSubcategory = $scope.filteredSubcategories.filter(
            i => i.subcategoryName === 'Other'
          )[0].id;
        }
      };

      $http
        .get('/CfcCharity/api/private/charity/skillsLocations')
        .then(function(res) {
          $scope.skillsCampaignZones = res.data;
        })
        .catch(function(err) {
          $log.error(err);
        });

      $scope.findZoneName = function(id) {
        return $scope.skillsCampaignZones.filter(i => i.id == id)[0]
          .locationName;
      };

      $scope.cancelVolunteerSkill = function() {
        $scope.selectedVolunteerSkill = null;
        $scope.volunteerSkill = null;
        $('#volunteerSkills').modal('hide');
      };

      $scope.removeVolunteerSkill = function(skillIndex) {
        $scope.volunteerDirty = true;
        $scope.tempVolunteerSkills.splice(skillIndex, 1);
      };

      $scope.editVolunteerSkill = function(skillIndex) {
        $scope.selectedVolunteerSkill = skillIndex;
        $scope.volunteerSkill = angular.copy(
          $scope.tempVolunteerSkills[skillIndex]
        );
        this.filterSubCategories();
        $('#volunteerSkills').modal('show');
      };

      $scope.addVolunteerSkill = function() {
        $scope.selectedVolunteerSkill = null;
        $scope.volunteerSkill = null;
        $('#volunteerSkills').modal('show');
      };

      $scope.saveVolunteerSkill = function(formVolunteerSkill) {
        $scope.volunteerDirty = true;

        if (!$scope.isOtherSkillRequired(formVolunteerSkill.jobSubcategory)) {
          formVolunteerSkill.otherSkill = null;
        }
        if ($scope.selectedVolunteerSkill == null) {
          $scope.tempVolunteerSkills.push(formVolunteerSkill);
        } else {
          $scope.tempVolunteerSkills[
            $scope.selectedVolunteerSkill
          ] = formVolunteerSkill;
        }

        $scope.selectedVolunteerSkill = null;
        $scope.volunteerSkill = null;
        $('#volunteerSkills').modal('hide');
      };

      $scope.facebookHandle = null;
      $scope.linkedInHandle = null;
      $scope.youTubeHandle = null;

      /**
       * Controller Init method to load initial page loading information
       */
      var init = function() {
        $scope.charityDashboardCfcCode = $state.params.charityDashboardCfcCode;
        $scope.pledgeYear = $state.params.pledgeYear;
        $scope.role = $state.params.userRole;
        if ($scope.role && $scope.role !== '') {
          if ($scope.role === 'FEDERATION_OWNER') {
            $scope.canEditDbaInfo = true;
          }
        }
        if ($scope.pledgeYear && $scope.pledgeYear !== '') {
          BrowserEventService.setUserId(user.id);
          BrowserEventService.setCfcCode($scope.charityDashboardCfcCode);
          BrowserEventService.setPledgeYear($scope.pledgeYear);
        }
        CharityService.getCharityUserRole($scope.charityDashboardCfcCode).then(
          function(res) {
            var result = res.data;
            if (result !== null && result !== '') {
              if (
                result.userRole === 'CHARITY_OWNER' ||
                result.userRole === 'CHARITY_EDITOR'
              ) {
                $scope.enablePayButton = true;
              }
              if (result.userRole === 'CHARITY_OWNER') {
                $scope.isCharityOwner = true;
              }
              if (result.userRole === 'CHARITY_EDITOR') {
                $scope.isCharityEditor = true;
              }
              if (
                result.userRole === 'CHARITY_OWNER' ||
                result.userRole === 'CHARITY_EDITOR' ||
                result.userRole === 'FEDERATION_OWNER' ||
                result.userRole === 'FEDERATION_EDITOR'
              ) {
                $scope.canEditDbaInfo = true;
              }
            }
          }
        );
        /* check if user is opm admin */
        $scope.isOpmAdmin = false;
        $scope.isOpmAuditor = false;
        $scope.isZoneManagerReviewer = false;
        $scope.isZoneCoordinator = false;

        if (secondaryRoleList.indexOf('OPM_ADMIN') >= 0) {
          $scope.isOpmAdmin = true;
          $scope.canGetDbaNameApprovalHistory = true;
        }

        if (
          secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0
        ) {
          $scope.isZoneManagerReviewer = true;
          $scope.canGetDbaNameApprovalHistory = true;
        }

        if (secondaryRoleList.indexOf('ZONE_COORDINATOR') >= 0) {
          $scope.isZoneCoordinator = true;
          $scope.isListingEditable = false;
        }

        if (secondaryRoleList.indexOf('OPM_AUDITOR') >= 0) {
          $scope.isOpmAuditor = true;
          $scope.canGetDbaNameApprovalHistory = true;
        }

        if (secondaryRoleList.indexOf('OPM_STAFF') >= 0) {
          $scope.canGetDbaNameApprovalHistory = true;
        }

        if (
          $scope.isSecondaryRoleCustomerServiceManager() ||
          $scope.isSecondaryRoleCustomerServiceTier2Finance() ||
          $scope.isSecondaryRoleGbFinanceAdmin() ||
          $scope.isSecondaryRoleOPMAuditor()
        ) {
          $scope.enablePayButton = true;
        }
        if (
          user != null &&
          user.secondaryRoleList != null &&
          user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 &&
          !$scope.isListingEditable
        ) {
          $scope.allowEditListingInfo = false;
        }
        CharityDashboardService.findCharityWithCFC(
          $scope.charityDashboardCfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.charityInformation = result;
          }
        });

        loadCharityListingPageInfo();

        if ($scope.pledgeYear && $scope.charityDashboardCfcCode) {
          CharityDashboardService.getCharityListingObject(
            $scope.pledgeYear,
            $scope.charityDashboardCfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;
              if ($scope.charityListingObject.charityDBAName) {
                $scope.DBAnotRequested = false;
              }
              $scope.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
              // set temp array for skills (only save on button click)
              for (let skill of $scope.charityListingObject
                .charityListingSkills) {
                const skillObject = {
                  charityListing: $scope.charityListingObject.id,
                  id: skill.id,
                  jobCategory: skill.jobCategory.id,
                  jobSubcategory: skill.jobSubcategory.id,
                  otherSkill: skill.otherSkill,
                  charityListingSkillsZones: skill.charityListingSkillsZones.map(
                    zone => zone.campaignZone
                  ),
                  charityListingSkillsActivityTypes: skill.charityListingSkillsActivityTypes.map(
                    activityType => activityType.skillsActivityType
                  )
                };
                $scope.tempVolunteerSkills.push(skillObject);
              }

              $scope.campaignId = result.campaignId;
              $scope.isFederation = 'FEDERATION' === result.applicationType;
              $scope.cfcCode = result.cfcCode;
              $scope.applicationId = result.applicationId;
              loadListingStartEndOverrideObject();
              if ($scope.canGetDbaNameApprovalHistory) {
                CharityDashboardService.getDbaNameApprovalHistory(
                  result.campaignId,
                  result.cfcCode
                ).then(function(res) {
                  var result = res.data;
                  if (result.message.type === CubeSvc.messageType.SUCCESS) {
                    var history = result.value;
                    $scope.dbaNameApprovalHistory = result.value;

                    if (history && history.length > 0) {
                      $scope.latestHistory = $filter('orderBy')(
                        history,
                        'dbaNameApprovalDate',
                        true
                      )[0];
                    }
                  }
                });
              }

              loadListingEditableInfo();
            }
          });
        }

        if (secondaryRoleList.indexOf('ZONE_COORDINATOR') >= 0) {
          $scope.isListingEditable = false;
        }
      };

      $scope.handlePayNowClick = function() {
        if (
          !this.editCharityStatementForm.$valid ||
          !this.editCharityPhoneForm.$valid
        ) {
          $('#missingListingInformation').modal('show');
        } else {
          $state.go('charitylistingpayment', {
            charityListingId: $scope.charityListingPageInfo.id
          });
        }
      };

      var loadListingEditableInfo = function() {
        ListingOverrideAfterCloseService.isListingAvailableWithOverride(
          $scope.campaignId,
          $scope.charityDashboardCfcCode
        ).then(function(res) {
          var response = res.data;
          $scope.isListingEditable = response;
          $scope.isListingEditableAfterClose = response;
        });
      };

      var loadListingStartEndOverrideObject = function() {
        if ($scope.campaignId && $scope.charityDashboardCfcCode) {
          ListingOverrideAfterCloseService.getListingStartEndOverride(
            $scope.campaignId,
            $scope.charityDashboardCfcCode
          ).then(function(res) {
            var response = res.data;
            $scope.listingStartEndOverrideObject = response;
            $scope.listingStartEndOverrideObjectEditor = angular.copy(
              response,
              $scope.listingStartEndOverrideObjectEditor
            );
            if (response.listingOverrideStart) {
              $scope.listingStartEndOverrideObjectEditor.listingOverrideStart = new Date(
                response.listingOverrideStart
              );
            }

            if (response.listingOverrideEnd) {
              $scope.listingStartEndOverrideObjectEditor.listingOverrideEnd = new Date(
                $scope.listingStartEndOverrideObject.listingOverrideEnd
              );
            }
          });
        } else {
          $log.info('campaignId or cfc code not found');
        }
      };

      $scope.resetOverrideModal = function() {
        $scope.showOverrideDatesErrorMessage = false;
        if ($scope.listingStartEndOverrideObject.listingOverrideStart) {
          $scope.listingStartEndOverrideObjectEditor.listingOverrideStart = new Date(
            $scope.listingStartEndOverrideObject.listingOverrideStart
          );
        } else {
          $scope.listingStartEndOverrideObjectEditor.listingOverrideStart = null;
        }

        if ($scope.listingStartEndOverrideObject.listingOverrideEnd) {
          $scope.listingStartEndOverrideObjectEditor.listingOverrideEnd = new Date(
            $scope.listingStartEndOverrideObject.listingOverrideEnd
          );
        } else {
          $scope.listingStartEndOverrideObjectEditor.listingOverrideEnd = null;
        }
      };

      $scope.setListingStartEndOverride = function() {
        var startDate = Date.parse(
          $scope.listingStartEndOverrideObjectEditor.listingOverrideStart
        );
        var endDate = Date.parse(
          $scope.listingStartEndOverrideObjectEditor.listingOverrideEnd
        );

        if (startDate && endDate) {
          if (!(startDate >= endDate)) {
            ListingOverrideAfterCloseService.setListingStartEndOverride(
              $scope.campaignId,
              $scope.charityDashboardCfcCode,
              startDate,
              endDate
            )
              .then(function(res) {
                var response = res.data;
                loadListingStartEndOverrideObject();
                loadListingEditableInfo();
                $('#editAfterCloseDatesModal').modal('hide');
              })
              .catch(function(error) {
                $scope.showOverrideDatesErrorMessage = true;
                $scope.overrideDatesErrorMessage =
                  'Something went wrong while saving the override dates.';
              });
          } else {
            $scope.showOverrideDatesErrorMessage = true;
            $scope.overrideDatesErrorMessage =
              'End date must be after start date.';
          }
        } else {
          $scope.showOverrideDatesErrorMessage = true;
          $scope.overrideDatesErrorMessage =
            'Start and End dates must have a valid date value.';
        }
      };

      var loadCharityListingPageInfo = function() {
        CubeSvc.performManualCacheRelease();
        if ($scope.pledgeYear && $scope.charityDashboardCfcCode) {
          CharityDashboardService.getCharityListingPageInfo(
            $scope.pledgeYear,
            $scope.charityDashboardCfcCode
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.ListingCampaignId = result.campaignId;
              $scope.charityListingPageInfo = result;
              $scope.showIndependentListingFee =
                (!result.overrideListingFee ||
                  result.overrideListingFee === 0) &&
                !!result.independentListingFee &&
                result.independentListingFee !== 0;

              var socialMediaLinksList =
                $scope.charityListingPageInfo.charitySocialMediaBeanList;
              angular.forEach(socialMediaLinksList, function(socialMedia) {
                if (socialMedia.socialMediaTypeName === 'Facebook') {
                  $scope.facebookHandle = socialMedia.socialMediaLink;
                } else if (socialMedia.socialMediaTypeName === 'LinkedIn') {
                  $scope.linkedInHandle = socialMedia.socialMediaLink;
                } else if (socialMedia.socialMediaTypeName === 'YouTube') {
                  $scope.youTubeHandle = socialMedia.socialMediaLink;
                }
              });

              getLogo(
                $scope.charityDashboardCfcCode,
                $scope.charityListingPageInfo.campaignId
              );
              $scope.charityListingViewPageInfo = angular.copy(
                $scope.charityListingPageInfo
              );
              if (
                $scope.charityListingPageInfo &&
                $scope.charityListingPageInfo.dashboardListingList
              ) {
                CharityDashboardService.getCharityDbaAuthLetters(
                  $scope.pledgeYear,
                  $scope.charityDashboardCfcCode,
                  $scope.charityListingPageInfo.id
                ).then(function(resp) {
                  if (resp.data.value != null) {
                    $scope.charityDbaAuthLetters = resp.data.value;
                    if (resp.data.value[0] !== undefined) {
                      $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                    }
                  }
                });
                angular.forEach(
                  $scope.charityListingPageInfo.dashboardListingList,
                  function(obj) {
                    if ($scope.pledgeYear === obj.pledgeYear) {
                      $scope.selectedPledgeYearObject = obj;
                      $scope.pledgeYearId = obj.pledgeYearId;
                    }
                  }
                );
              }
              if (
                $scope.charityListingPageInfo.applicationStatus ===
                'IN_PROGRESS'
              ) {
                $scope.applicationInProgress = true;
              }
            }
            $scope.dashboardLoading = false;
          });
        }
      };
      /* Toggle useDBANameOnly Flag */
      $scope.dbaNameOnly = function(cfcCode, useOnlyDbaFlag) {
        CharityDashboardService.toggleUseDBAOnlyFlag(
          cfcCode,
          $scope.selectedPledgeYearObject.pledgeYearId,
          useOnlyDbaFlag
        ).then(function(res) {
          /*Update charityListingViewObject */
          CharityDashboardService.getCharityListingObject(
            $scope.pledgeYear,
            $scope.charityDashboardCfcCode
          ).then(function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;

              $scope.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
            }
          });
        });
      };
      $scope.downloadDbaFile = function(type, applicationId) {
        $log.debug(type);
        var url = type + '/' + applicationId;
        CharityDashboardService.downloadDbaFile(url);
      };

      function getLogo(cfcCode, campaignId) {
        logoFileService.getLogoURL(cfcCode, campaignId).then(function(res) {
          $scope.logoUrl = res.data.value ? res.data.value : null;
        });
      }

      /**
       * Application Fundraising Rate (AFR) Update
       */
      $scope.saveOrUpdateAFR = function(charityListingPageInfo, cfcCode) {
        charityListingPageInfo.bankRoutingNumber =
          $scope.charityListingViewPageInfo.bankRoutingNumber;
        charityListingPageInfo.accountEnding =
          $scope.charityListingViewPageInfo.accountEnding;
        charityListingPageInfo.bankName =
          $scope.charityListingViewPageInfo.bankName;
        charityListingPageInfo.bankId =
          $scope.charityListingViewPageInfo.bankId;
        charityListingPageInfo.cfcCode = cfcCode;
        CharityDashboardService.saveOrUpdateBankInfor(
          charityListingPageInfo
        ).then(function(res) {
          var result = res.data;
          if (result.message.type === CubeSvc.messageType.SUCCESS) {
            $scope.charityListingPageInfo = result.charityListingPageInfo;
          }
          $('#editBankingInfoCancelId').click();
        });
      };
      /**
       * Listing Fee Update
       */
      $scope.cancelListingFee = function() {
        loadCharityListingPageInfo();
        angular.element('#editFeeModal').modal('hide');
      };

      $scope.saveOrUpdateCharityListingFee = function(
        charityListingPageInfo,
        cfcCode
      ) {
        charityListingPageInfo.cfcCode = cfcCode;
        charityListingPageInfo.overrideListingFee = !charityListingPageInfo.overrideListingFee
          ? 0
          : charityListingPageInfo.overrideListingFee;
        CharityDashboardService.updateCharityListingFee(
          charityListingPageInfo,
          $scope.selectedPledgeYearObject.pledgeYearId
        )
          .then(function(res) {
            loadCharityListingPageInfo();
            $('#editListingFeeCancel').click();
          })
          .catch(function(err) {
            $log.error(err);
            var data = err.data;
            if (data.errorMessage && data.errorMessage.trim() !== '') {
              toastrService.error(data.errorMessage);
            }
          });
      };
      /**
       * Listing AFR Update
       */
      $scope.saveOrUpdateCharityAFR = function(
        charityListingPageInfo,
        cfcCode
      ) {
        charityListingPageInfo.cfcCode = cfcCode;
        CharityDashboardService.updateCharityAFR(
          charityListingPageInfo,
          $scope.selectedPledgeYearObject.pledgeYearId
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
          }
          $('#editAFRCancel').click();
        });
      };
      /**
       * Listing Volunteer Toggle
       */
      $scope.toggleCharityVolunteer = function() {
        $scope.showVolunteerMonetoryValueWarning = false;
        $scope.volunteerForm.volunteerMonetoryValue.$setValidity('max', true);
        if (false === $scope.dashboardLoading) {
          var showValE = true;
          $('#volunteerSaveStatus').html('');
          if (montext) $timeout.cancel(montext);

          if (
            !$scope.charityListingViewObject.volunteerOpportunities ||
            $scope.charityListingViewObject.volunteerOpportunities === 'No'
          ) {
            $scope.charityListingViewObject.solicitVolunteerTime = 'No';
            $scope.charityListingViewObject.volunteerMonetoryValue = null;
          }
          if (
            !$scope.charityListingViewObject.solicitVolunteerTime ||
            $scope.charityListingViewObject.solicitVolunteerTime === 'No'
          ) {
            $scope.charityListingViewObject.volunteerMonetoryValue = null;
          } else {
            // warning for over $200, error for over $500
            if ($scope.charityListingViewObject.volunteerMonetoryValue > 200) {
              $scope.showVolunteerMonetoryValueWarning = true;
            }
            if ($scope.charityListingViewObject.volunteerMonetoryValue > 500) {
              $scope.showVolunteerMonetoryValueWarning = false;
              $scope.volunteerForm.volunteerMonetoryValue.$setValidity(
                'max',
                false
              );
            }
          }
        }
      };
      /**
       * Listing Volunteer Update
       */
      $scope.saveOrUpdateCharityVolunteer = function(cfcCode) {
        if (false === $scope.dashboardLoading) {
          if ($scope.charityListingViewObject.volunteerMonetoryValue > 500) {
            return;
          }
          $scope.charityListingViewObject.charityListingSkills =
            $scope.tempVolunteerSkills;

          var ucv = true;
          var showValE = true;
          $('#volunteerSaveStatus').html('');
          if (montext) $timeout.cancel(montext);

          if (
            !$scope.charityListingViewObject.volunteerOpportunities ||
            $scope.charityListingViewObject.volunteerOpportunities === 'No'
          ) {
            $scope.charityListingViewObject.solicitVolunteerTime = 'No';
            $scope.charityListingViewObject.volunteerMonetoryValue = null;
          } else {
            ucv = false;
          }
          if (
            !$scope.charityListingViewObject.solicitVolunteerTime ||
            $scope.charityListingViewObject.solicitVolunteerTime === 'No'
          ) {
            $scope.charityListingViewObject.volunteerMonetoryValue = null;
            if ($scope.charityListingViewObject.solicitVolunteerTime === 'No') {
              ucv = true;
            }
          } else {
            ucv = false;
            showValE = false;
            if (
              $scope.charityListingViewObject.volunteerMonetoryValue &&
              $scope.charityListingViewObject.volunteerMonetoryValue !== ''
            ) {
              montext = $timeout(function() {
                CharityDashboardService.updateCharityVolunteer(
                  cfcCode,
                  $scope.pledgeYear,
                  $scope.charityListingViewObject
                ).then(function(res) {
                  var result = res.data;
                  if (result.message.type === CubeSvc.messageType.SUCCESS) {
                    var dt = new Date();
                    $('#volunteerSaveStatus')
                      .removeClass('text-danger')
                      .addClass('text-success')
                      .html('Last saved: ' + dt.toLocaleTimeString());
                    // reload page with updates
                    $state.reload();
                  }
                });
              }, 2000);
            }
          }

          if (ucv) {
            CharityDashboardService.updateCharityVolunteer(
              cfcCode,
              $scope.pledgeYear,
              $scope.charityListingViewObject
            ).then(function(res) {
              var result = res.data;
              if (result.message.type === CubeSvc.messageType.SUCCESS) {
                var dt = new Date();
                $('#volunteerSaveStatus')
                  .removeClass('text-danger')
                  .addClass('text-success')
                  .html('Last saved: ' + dt.toLocaleTimeString());
                // reload page with updates
                $state.reload();
              }
            });
          } else if (showValE) {
            $('#volunteerSaveStatus')
              .removeClass('text-success')
              .addClass('text-danger')
              .html('Not saved, please fix required fields');
          }
        }
      };
      /**
       * Bank Info Update
       */
      $scope.saveOrUpdateBankInfo = function(charityListingPageInfo, cfcCode) {
        charityListingPageInfo.bankRoutingNumber =
          $scope.charityListingViewPageInfo.bankRoutingNumber;
        charityListingPageInfo.accountEnding =
          $scope.charityListingViewPageInfo.accountEnding;
        charityListingPageInfo.bankName =
          $scope.charityListingViewPageInfo.bankName;
        charityListingPageInfo.bankId =
          $scope.charityListingViewPageInfo.bankId;
        charityListingPageInfo.cfcCode = cfcCode;
        CharityDashboardService.saveOrUpdateBankInfor(
          charityListingPageInfo
        ).then(function(res) {
          var result = res.data;
          if (result.message.type === CubeSvc.messageType.SUCCESS) {
            $scope.charityListingPageInfo = result.charityListingPageInfo;
            $scope.charityInformation.invalidBankingReasonCode = null;
          }
          $('#editBankingInfoCancelId').click();
        });
      };

      function getHttpsUrl(url) {
        if (url) {
          if (url.startsWith('http://')) {
            url = 'https://' + url.substr(7);
          } else if (url.startsWith('www')) {
            url = 'https://' + url;
          }
        }
        return url;
      }

      $scope.saveSocialMediaHandle = function(charityListingPageInfo) {
        var socialMediaLinks = {};
        var socialMediaLinksList =
          charityListingPageInfo.charitySocialMediaBeanList;
        angular.forEach(socialMediaLinksList, function(socialMedia) {
          if (socialMedia.socialMediaTypeName === 'Facebook') {
            socialMediaLinks.facebookSocialMediaLink = getHttpsUrl(
              socialMedia.socialMediaLink
            );
          } else if (socialMedia.socialMediaTypeName === 'LinkedIn') {
            socialMediaLinks.linkedinSocialMediaLink = getHttpsUrl(
              socialMedia.socialMediaLink
            );
          } else if (socialMedia.socialMediaTypeName === 'YouTube') {
            socialMediaLinks.youtubeSocialMediaLink = getHttpsUrl(
              socialMedia.socialMediaLink
            );
          }
        });
        socialMediaLinks.listingId = charityListingPageInfo.id;
        CharityDashboardService.saveSocialMediaHandles(
          $scope.cfcCode,
          socialMediaLinks
        ).then(function(res) {
          var result = res.data;
          if (result.message.type === CubeSvc.messageType.SUCCESS) {
            $scope.facebookHandle = socialMediaLinks.facebookSocialMediaLink;
            $scope.linkedInHandle = socialMediaLinks.linkedinSocialMediaLink;
            $scope.youTubeHandle = socialMediaLinks.youtubeSocialMediaLink;
            $scope.charityListingPageInfo = charityListingPageInfo;
            $('#editHandleCancel').click();
          }
        });
      };

      /**
       * Controller Method to reload charity listing screen while changing pledge year.
       */
      $scope.pledgeYearChange = function() {
        $scope.showListingPeriodErrorMessage = false;
        CharityDashboardService.getCharityListingPageInfo(
          $scope.pledgeYear,
          $scope.charityDashboardCfcCode
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            $scope.charityListingPageInfo = result;
            if (
              $scope.charityListingPageInfo &&
              $scope.charityListingPageInfo.dashboardListingList
            ) {
              angular.forEach(
                $scope.charityListingPageInfo.dashboardListingList,
                function(obj) {
                  if ($scope.pledgeYear === obj.pledgeYear) {
                    $scope.selectedPledgeYearObject = obj;
                  }
                }
              );
            }
          }
        });
        CharityDashboardService.getCharityListingObject(
          $scope.pledgeYear,
          $scope.charityDashboardCfcCode
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            $scope.charityListingObject = result;
            $scope.charityListingViewObject = angular.copy(
              $scope.charityListingObject
            );
          }
        });
      };

      /* Change dbaName Approval Status */
      $scope.changeDbaNameApprovalStatus = function(cfcCode, status, reason) {
        CharityDashboardService.changeDbaNameApprovalStatusService(
          cfcCode,
          $scope.selectedPledgeYearObject.pledgeYearId,
          status,
          reason
        ).then(function(res) {
          var result = res.data;
          if ($scope.canGetDbaNameApprovalHistory) {
            CharityDashboardService.getDbaNameApprovalHistory(
              $scope.charityListingPageInfo.campaignId,
              $scope.charityDashboardCfcCode
            ).then(function(res) {
              var result = res.data;
              if (result.message.type === CubeSvc.messageType.SUCCESS) {
                var history = result.value;
                $scope.dbaNameApprovalHistory = result.value;
                if (history && history.length > 0) {
                  $scope.latestHistory = $filter('orderBy')(
                    history,
                    'dbaNameApprovalDate',
                    true
                  )[0];
                }
              }
            });
          }
          updateListingObject();
        });
      };

      $scope.onChangeDBADenial = function(status) {
        if (!$scope.charityListingViewObject) {
          return;
        }
        if (status === 'Denied') {
          angular.element('#DBAdenial').modal('show');
        } else if (
          $scope.charityListingViewObject.dbanameChangeStatus === 'Denied' &&
          status === 'Denied'
        ) {
          return;
        } else {
          $scope.changeDbaNameApprovalStatus(
            $scope.charityInformation.cfcCode,
            status
          );
        }
      };

      $scope.cancelDBADenial = function() {
        updateListingObject();
        angular.element('#DBAdenial').modal('hide');
      };

      var updateListingObject = function() {
        CharityDashboardService.getCharityListingObject(
          $scope.pledgeYear,
          $scope.charityDashboardCfcCode
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            $scope.charityListingObject = result;
            $scope.charityListingViewObject = angular.copy(
              $scope.charityListingObject
            );

            // uncheck the DBA name only checkbox and disable it when status is pending or denied
            if (
              $scope.charityListingViewObject.dbanameChangeStatus ===
                'Pending' ||
              $scope.charityListingViewObject.dbanameChangeStatus === 'Denied'
            ) {
              $scope.dbaNameOnly(
                $scope.charityListingViewObject.cfcCode,
                false
              );
            }
          }
        });
      };

      /**
       * Controller method to update Charity Listing
       */
      $scope.saveOrUpdateCharityListing = function(charityListing) {
        if (charityListing.id == null) {
          charityListing.cfcCode = $scope.charityDashboardCfcCode;
          charityListing.campaignId =
            $scope.selectedPledgeYearObject.pledgeYearId;
        }
        CharityDashboardService.updateCharityListing(charityListing).then(
          function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;
              if (
                $scope.charityListingPageInfo.paymentStatus === 'Unpaid' &&
                $scope.charityListingPageInfo.applicationType ===
                  'Independent' &&
                $scope.charityListingPageInfo.applicationStatus === 'APPROVED'
              ) {
                $scope.charityListingPageInfo.showPayListingFee = true;
              }
              $scope.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
            }

            $('#editCharityListingStmtCancelId').click();
            $('#editCharityListingCancelId').click();
            $('#editNumberCancelId').click();
            $('#editDBANameCancelId').click();
            $('#editCharityCareCancelBtnId').click();
          }
        );
      };

      $scope.updateDBAName = function(charityListing) {
        if (charityListing) {
          CharityDashboardService.updateDBAName(
            charityListing.cfcCode,
            charityListing.campaignId,
            charityListing.charityDBAName,
            $scope.charityInformation.charityIrsName
          ).then(function(res) {
            var result = res.data.value;
            $('#editDBANameCancelId').click();
            updateListingObject();
            $scope.DBAnotRequested = true;
            if ($scope.charityListingObject.charityDBAName) {
              $scope.DBAnotRequested = false;
            }
            if (
              user.secondaryRoleList &&
              Array.isArray(user.secondaryRoleList) &&
              $location.path() !== '/charitylisting'
            ) {
              if (
                user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
                user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
                user.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
              ) {
                $scope.$broadcast('reloadCfcAdminReview');
              }
              if ($scope.canGetDbaNameApprovalHistory) {
                CharityDashboardService.getDbaNameApprovalHistory(
                  $scope.campaignId,
                  $scope.cfcCode
                ).then(function(res) {
                  var result = res.data;
                  if (result.message.type === CubeSvc.messageType.SUCCESS) {
                    var history = result.value;
                    $scope.dbaNameApprovalHistory = result.value;

                    if (history && history.length > 0) {
                      $scope.latestHistory = $filter('orderBy')(
                        history,
                        'dbaNameApprovalDate',
                        true
                      )[0];
                    }
                  }
                });
              }
            }
            if (
              ($scope.isOpmAdmin || $scope.isZoneManagerReviewer) &&
              $location.path() === '/charitylisting'
            ) {
              CharityDashboardService.getDbaNameApprovalHistory(
                result.campaignId,
                result.cfcCode
              ).then(function(res) {
                var response = res.data;
                if (response.message.type === CubeSvc.messageType.SUCCESS) {
                  var history = response.value;
                  $scope.dbaNameApprovalHistory = response.value;

                  if (history && history.length > 0) {
                    $scope.latestHistory = $filter('orderBy')(
                      history,
                      'dbaNameApprovalDate',
                      true
                    )[0];
                  }
                }
              });
            }
          });
        }
      };

      $scope.loadEditObject = function() {
        $scope.charityListingObject = angular.copy(
          $scope.charityListingViewObject
        );
      };
      $scope.loadEditBankObject = function() {
        $scope.charityListingViewPageInfo = angular.copy(
          $scope.charityListingPageInfo
        );
      };

      $scope.loadSocialMediaInfo = function() {
        $scope.charityListingSocialMediaInfo = angular.copy(
          $scope.charityListingPageInfo
        );
      };
      /**
       * Reusable method to Show success message on the screen.
       */
      $scope.setSuccessMessage = function(message) {
        $scope.listingPeriodEndedMessage = '';
        $scope.charityListingSuccessMsg = message;
      };
      /**
       * Reusable method to Show error message on the screen
       */
      $scope.setErrorMessage = function(message) {
        $scope.listingPeriodEndedMessage = message;
        $scope.charityListingSuccessMsg = '';
      };
      $scope.charityDashboard = function() {
        $state.go('charitydashboard', {
          charityDashboardCfcCode: $scope.charityDashboardCfcCode
        });
      };
      $scope.showListingPeriodEndedMessage = function() {
        if ($scope.applicationInProgress) {
          $scope.setErrorMessage(
            'Please sign the Application to make any changes to the Listing'
          );
        }
        if (!$scope.charityListingPageInfo.listingPeriodEnabled) {
          $scope.showListingPeriodErrorMessage = true;
          $scope.setErrorMessage('The Listing Period is currently Closed.');
        }
      };
      /**
       * Controller method to redirect charity listing payment screen.
       */
      $scope.payCharityListingFee = function(charityListing) {
        if (charityListing.id == null) {
          charityListing.cfcCode = $scope.charityDashboardCfcCode;
          charityListing.campaignId =
            $scope.selectedPledgeYearObject.pledgeYearId;
          CharityDashboardService.updateCharityListing(charityListing).then(
            function(res) {
              var result = res.data.value;
              if (result !== null && result !== '') {
                $scope.charityListingObject = result;
                $scope.charityListingViewObject = angular.copy(
                  $scope.charityListingObject
                );
                BrowserEventService.setListingId(
                  $scope.charityListingObject.id
                );
                $state.go('charitylistingpayment', {
                  charityListingId: $scope.charityListingObject.id
                });
              }
            }
          );
        } else {
          BrowserEventService.setListingId(charityListing.id);
          $state.go('charitylistingpayment', {
            charityListingId: charityListing.id
          });
        }
      };
      /**
       * Controller method to redirect federation listing screen.
       */
      $scope.goToFederationListing = function(pledgeYear, federationCfcCode) {
        if (pledgeYear && pledgeYear != null && pledgeYear !== '') {
          if (federationCfcCode == null || federationCfcCode === '') {
            federationCfcCode = $scope.charityDashboardCfcCode;
          }
          $state.go('federationlisting', {
            charityDashboardCfcCode: federationCfcCode,
            pledgeYear: pledgeYear
          });
        }
      };

      // Handle upload of authorized DBA file.
      $scope.uploadAuthorizedDBA = function(event) {
        blockUI.start();
        $scope.errorMsg = '';
        if (event.target.files.length > 0) {
          if (
            typeof event.target.files[0].type !== 'undefined' &&
            event.target.files[0].type !== null &&
            event.target.files[0].type !== 'application/pdf'
          ) {
            $scope.errorMsg = 'File upload must be PDF format.';
            blockUI.stop();
          } else {
            var fd = new FormData();
            for (i = 0; i < event.target.files.length; i++) {
              fd.append('supportingFile', event.target.files[i]);
            }
            var uploadUrl =
              '/CfcCharity/api/private/charitylisting/' +
              $scope.charityDashboardCfcCode +
              '/' +
              $scope.selectedPledgeYearObject.pledgeYearId +
              '/uploadDBA';
            CubeSvc.post(uploadUrl, fd, {
              transformRequest: angular.identity,
              headers: {
                'Content-Type': undefined,
                'X-AUTH-TOKEN': $scope.xAuthToken
              }
            })
              .then(function(res) {
                var data = res.data.value;
                if (
                  data &&
                  data.statusCode !== undefined &&
                  data.statusCode === 'ERROR'
                ) {
                  $scope.setErrorMessage(data.message);
                  $('#DBAauthorization').val('');
                } else if (data !== null && data !== '') {
                  $scope.setSuccessMessage('File uploaded successfully.');
                  $scope.charityListingPageInfo.charityDBAAuthUploaded = true;
                  $scope.listingPeriodEndedMessage = '';
                  $scope.charityListingObject = data;
                  if (
                    $scope.charityListingPageInfo.paymentStatus === 'Unpaid' &&
                    $scope.charityListingPageInfo.applicationType ===
                      'Independent' &&
                    $scope.charityListingPageInfo.applicationStatus ===
                      'APPROVED'
                  ) {
                    $scope.charityListingPageInfo.showPayListingFee = true;
                  }
                  $scope.charityListingViewObject = angular.copy(
                    $scope.charityListingObject
                  );
                  CharityDashboardService.getCharityDbaAuthLetters(
                    $scope.pledgeYear,
                    $scope.charityDashboardCfcCode,
                    $scope.charityListingPageInfo.id
                  ).then(function(resp) {
                    if (resp.data.value != null) {
                      $scope.charityDbaAuthLetters = resp.data.value;
                      if (resp.data.value[0] !== undefined) {
                        $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                      }
                    }
                  });
                }
              })
              .catch(function(data) {
                $('#DBAauthorization').val('');
              });
            blockUI.stop();
          }
        }
        blockUI.stop();
      };

      // Handle deletion of authorized DBA file.
      $scope.deleteDbaAuthLetter = function(dbaAuthLetterId) {
        var url =
          '/CfcCharity/api/public/delete/charity/dbaAuth/' +
          $scope.charityDashboardCfcCode +
          '/' +
          $scope.charityListingPageInfo.campaignId +
          '/' +
          dbaAuthLetterId;
        $http({
          method: 'POST',
          url: url,
          data: {
            cfcCode: $scope.charityDashboardCfcCode,
            campaignId: $scope.charityListingPageInfo.campaignId,
            dbaAuthLetterId: dbaAuthLetterId
          }
        })
          .then(function(res) {
            var result = res.data;
            if (result) {
              CharityDashboardService.getCharityDbaAuthLetters(
                $scope.pledgeYear,
                $scope.charityDashboardCfcCode,
                $scope.charityListingPageInfo.id
              ).then(function(resp) {
                if (resp.data.value != null) {
                  $scope.charityDbaAuthLetters = resp.data.value;
                  if (resp.data.value[0] !== undefined) {
                    $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                  }
                }
              });
            }
          })
          .catch(function(data) {
            /*Todo: Log error*/
          });
      };

      // Handle upload of Charity Logo file.
      $scope.uploadCharityLogo = function(event) {
        blockUI.start();
        $scope.logoErrorMsg = '';
        if (event.target.files.length === 0) {
          blockUI.stop();
          return;
        }
        if (
          event.target.files[0].type !== 'image/jpeg' &&
          event.target.files[0].type !== 'image/png'
        ) {
          $scope.logoErrorMsg = 'File upload must be JPEG, JPG, or PNG format.';
          blockUI.stop();
          return;
        }

        if (event.target.files[0].size > 500000) {
          $scope.logoErrorMsg = 'Logo cannot be larger than 500KB.';
          blockUI.stop();
          return;
        }

        var fd = new FormData();
        for (var i = 0; i < event.target.files.length; i++) {
          fd.append('supportingFile', event.target.files[i]);
        }
        var uploadUrl =
          '/CfcCharity/api/private/charity/' +
          $scope.charityDashboardCfcCode +
          '/' +
          $scope.selectedPledgeYearObject.pledgeYearId +
          '/uploadLogo';
        CubeSvc.post(uploadUrl, fd, {
          transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
            'X-AUTH-TOKEN': $scope.xAuthToken
          }
        })
          .then(function(res) {
            var data = res.data.value;
            if (
              data &&
              data.statusCode !== undefined &&
              data.statusCode === 'ERROR'
            ) {
              $scope.setErrorMessage(data.message);
              $('#uploadCharityLogo').val('');
            } else if (data !== null && data !== '') {
              $scope.setSuccessMessage('Charity Logo uploaded successfully.');
              getLogo(
                $scope.charityDashboardCfcCode,
                $scope.charityListingPageInfo.campaignId
              );
              $scope.charityListingPageInfo.charityLogoUploaded = true;
              if (
                $scope.charityListingPageInfo.paymentStatus === 'Unpaid' &&
                $scope.charityListingPageInfo.applicationType ===
                  'Independent' &&
                $scope.charityListingPageInfo.applicationStatus === 'APPROVED'
              ) {
                $scope.charityListingPageInfo.showPayListingFee = true;
              }
              $scope.listingPeriodEndedMessage = '';
            }
          })
          .catch(function() {});
        blockUI.stop();
      };

      // Handle download of Charity Logo file.
      $scope.downloadCharityLogo = function() {
        var url =
          '/CfcCharity/api/public/cfcdownload/charity/logo/' +
          $scope.charityDashboardCfcCode +
          '/' +
          $scope.charityListingPageInfo.campaignId;
        DownloadService.downloadFile(url);
      };

      // Handle deletion of Charity Logo file.
      $scope.deleteCharityLogo = function() {
        var url =
          '/CfcCharity/api/public/delete/charity/logo/' +
          $scope.charityDashboardCfcCode +
          '/' +
          $scope.charityListingPageInfo.campaignId;
        $http({
          method: 'POST',
          url: url,
          data: {
            cfcCode: $scope.charityDashboardCfcCode,
            campaignId: $scope.charityListingPageInfo.campaignId
          }
        })
          .then(function(res) {
            var result = res.data;
            if (result) {
              $scope.charityListingPageInfo.charityLogoUploaded = false;
              getLogo(
                $scope.charityDashboardCfcCode,
                $scope.charityListingPageInfo.campaignId
              );
            }
          })
          .catch(function(data) {
            /*Todo: Log error*/
          });
      };

      $scope.showNoFileToDownloadEndedMessage = function() {
        $scope.showListingPeriodErrorMessage = true;
        $scope.setErrorMessage('No File to Download.');
      };
      init();
    }
  ])
  .filter('appStatusFilter', function() {
    return function(status, customerFacing) {
      if (status === 'IN_PROGRESS' || status === 'In Progress') {
        return 'In Progress';
      } else if (status === 'SIGNED') {
        return 'Signed';
      } else if (status === 'SUBMITTED') {
        return 'In Review';
      } else if (status === 'IN_REVIEW') {
        return 'In Review';
      } else if (status === 'APPROVED') {
        return 'Approved';
      } else if (status === 'DENIED') {
        return customerFacing ? 'Not Approved' : 'Denied';
      } else if (status === 'WITHDRAWN') {
        return 'Withdrawn';
      } else if (status === 'COMPLETED') {
        return 'Completed';
      } else if (status === 'DISBANDED') {
        return 'Disbanded';
      } else if (status === 'IN_APPEAL') {
        return 'Appealed';
      } else if (status === 'COMPLETE') {
        return 'Signed';
      } else if (status === 'MORE_INFO') {
        return 'In Review';
      } else if (status === 'CLOSED') {
        return 'Closed';
      } else if (status === 'START_APPLICATION' || status === null) {
        return 'Start Application';
      } else if (status === 'NOT_STARTED') {
        return 'Not Started';
      } else {
        return status;
      }
    };
  })
  .filter('applicationTypeFilter', function() {
    return function(appType) {
      if (appType === 'FEDERATION') {
        return 'Federation';
      } else if (appType === 'MEMBER') {
        return 'Member';
      } else if (appType === 'INDEPENDENT') {
        return 'Independent';
      } else {
        return status;
      }
    };
  })
  .filter('capitalize', function() {
    return function(input) {
      return !!input
        ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase()
        : '';
    };
  })
  .filter('tel', function() {
    return function(tel) {
      if (!tel) {
        return '';
      }

      var value = tel
        .toString()
        .trim()
        .replace(/^\+/, '');

      if (value.match(/[^0-9]/)) {
        return tel;
      }

      var country, city, number;

      switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;

        case 11: // +CPPP####### -> CCC (PP) ###-####
          country = value[0];
          city = value.slice(1, 4);
          number = value.slice(4);
          break;

        case 12: // +CCCPP####### -> CCC (PP) ###-####
          country = value.slice(0, 3);
          city = value.slice(3, 5);
          number = value.slice(5);
          break;

        default:
          return tel;
      }

      if (country === 1) {
        country = '';
      }

      number = number.slice(0, 3) + '-' + number.slice(3);

      return (country + ' (' + city + ') ' + number).trim();
    };
  })
  .directive('fileUploadChange', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        var onChangeHandler = scope.$eval(attrs.fileUploadChange);
        element.bind('change', onChangeHandler);
      }
    };
  })
  .directive('decimals', function($filter) {
    return {
      restrict: 'A', // Only usable as an attribute of another HTML element
      require: '?ngModel',
      scope: {
        decimals: '@',
        decimalPoint: '@'
      },
      link: function(scope, element, attr, ngModel) {
        var decimalCount = parseInt(scope.decimals) || 2;
        var decimalPoint = scope.decimalPoint || '.';

        // Run when the model is first rendered and when the model is changed from code
        ngModel.$render = function() {
          if (ngModel.$modelValue != null && ngModel.$modelValue >= 0) {
            if (typeof decimalCount === 'number') {
              element.val(ngModel.$modelValue.toFixed(decimalCount));
            } else {
              element.val(ngModel.$modelValue);
            }
          }
        };

        // Run when the view value changes - after each keypress
        // The returned value is then written to the model
        ngModel.$parsers.unshift(function(newValue) {
          if (typeof decimalCount === 'number') {
            var floatValue = parseFloat(newValue);
            if (decimalCount === 0) {
              return parseInt(floatValue);
            }
            return floatValue.toFixed(decimalCount);
          }

          return parseFloat(newValue);
        });

        // Formats the displayed value when the input field loses focus
        element.on('change', function(e) {
          var floatValue = parseFloat(element.val());
          if (!isNaN(floatValue) && typeof decimalCount === 'number') {
            if (decimalCount === 0) {
              element.val(parseInt(floatValue));
            } else {
              var strValue = floatValue.toFixed(decimalCount);
              element.val(strValue.replace('.', decimalPoint));
            }
          }
        });
      }
    };
  })
  .directive('validatePhoneNumber', function() {
    return {
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators.myValidator = function(modelValue, viewValue) {
          if (!viewValue || viewValue.length === 0 || viewValue.length !== 12) {
            return false;
          }
          var numberArray = viewValue
            .replace(new RegExp('-', 'g'), '')
            .toString(12)
            .split('');
          var unique = numberArray.filter(function onlyUnique(
            value,
            index,
            self
          ) {
            return self.indexOf(value) === index;
          });
          if (unique && unique.length < 2) {
            return false;
          }
          return true;
        };
      }
    };
  })
  .config([
    '$logProvider',
    function($logProvider) {
      $logProvider.debugEnabled(false);
    }
  ]);
