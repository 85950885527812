angular
  .module('CfcCharity.zonereviewlevel', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('zonecompletenessreview', {
        url: '/zonecompletenessreview?zoneId&pledgeYear',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zonecompletenessreview.html'),
            controller: 'ZoneReviewLevelCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Zone %zoneCode%',
              crumbRoute: 'zoneCenter',
              crumbParams: '{zoneId: %zoneId%}'
            },
            {
              crumbName: 'Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            }
          ],
          pageTitle: 'Completeness Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: { zoneId: null, pledgeYear: null },
        resolve: {
          r: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            ApplicationDashboardService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
              reviewLevelConstants.Completeness
            );
            return {};
          }
        }
      });

      // Added By Prem
      $stateProvider.state('zonereviewlevel1', {
        url: '/zonereviewlevel1?zoneId&pledgeYear',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zoneReviewLevel1.html'),
            controller: 'ZoneReviewLevelCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Zone %zoneCode%',
              crumbRoute: 'zoneCenter',
              crumbParams: '{zoneId: %zoneId%}'
            },
            {
              crumbName: 'Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            }
          ],
          pageTitle: 'Zone Review 1',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          zoneId: null,
          pledgeYear: null
        },
        resolve: {}
      });
      $stateProvider.state('zonereviewlevel2', {
        url: '/zonereviewlevel2?zoneId&pledgeYear',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zoneReviewLevel2.html'),
            controller: 'ZoneReviewLevelCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Zone %zoneCode%',
              crumbRoute: 'zoneCenter',
              crumbParams: '{zoneId: %zoneId%}'
            },
            {
              crumbName: 'Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            }
          ],
          pageTitle: 'Zone Review 2',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          zoneId: null,
          pledgeYear: null
        },
        resolve: {
          r: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            ApplicationDashboardService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
              reviewLevelConstants.PreliminaryEligibility
            );
            return {};
          }
        }
      });
      $stateProvider.state('zonereviewlevel3', {
        url: '/zonereviewlevel3?zoneId&pledgeYear',
        views: {
          main: {
            template: require('../views/private/zoneadmin/zoneReviewLevel3.html'),
            controller: 'ZoneReviewLevelCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: 'Zone %zoneCode%',
              crumbRoute: 'zoneCenter',
              crumbParams: '{zoneId: %zoneId%}'
            },
            {
              crumbName: 'Applications',
              crumbRoute: 'zoneApplications',
              crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
            }
          ],
          pageTitle: 'Zone Review 3',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
        },
        params: {
          zoneId: null,
          pledgeYear: null
        },
        resolve: {
          r: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            ApplicationDashboardService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
              reviewLevelConstants.FinalEligibility
            );
            return {};
          }
        }
      });
    }
  ])
  .filter('userFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, userIdToFilter) {
        if (!dataArray) {
          return;
        } else if (!userIdToFilter || userIdToFilter === 'All') {
          return dataArray;
        } else if (userIdToFilter === 'Unassigned') {
          return dataArray.filter(function(item) {
            var userFound =
              item &&
              (item.reviewerUserId === '' || item.reviewerUserId === null);
            return userFound;
          });
        } else {
          return dataArray.filter(function(item) {
            var userFound = item && item.reviewerUserId === userIdToFilter;
            return userFound;
          });
        } // end else
      };
    }
  ])
  .filter('reviewLevelFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, statusToFilter) {
        if (!dataArray) {
          return;
        } else if (
          !statusToFilter ||
          statusToFilter === undefined ||
          statusToFilter.status === undefined
        ) {
          return dataArray;
        } else {
          return dataArray.filter(function(item) {
            var prevReviewLevel = statusToFilter.previousStatus;
            if (prevReviewLevel === 'COMPLETENESS') {
              var statusFound =
                item && item.completenessReviewStatus === statusToFilter.status;
              return statusFound;
            } else if (prevReviewLevel === 'STAFF_ELIGIBILITY') {
              var statusFound =
                item && item.staffEligibilityStatus === statusToFilter.status;
              return statusFound;
            } else if (prevReviewLevel === 'PRELIMINARY_ELIGIBILITY') {
              var statusFound =
                item &&
                item.preliminaryEligibilityStatus === statusToFilter.status;
              return statusFound;
            }
          });
        }
      };
    }
  ])
  .filter('completenessFilter', [
    '$filter',
    function($filter) {
      return function(dataArray, statusToFilter) {
        if (!dataArray) {
          return;
        } else if (!statusToFilter || statusToFilter === undefined) {
          return dataArray;
        } else {
          return dataArray.filter(function(item) {
            if (statusToFilter === 'PASS') {
              var statusFound =
                item && item.completenessReviewStatus === statusToFilter;
              return statusFound;
            } else if (statusToFilter === 'FAIL') {
              var statusFound =
                item && item.completenessReviewStatus === statusToFilter;
              return statusFound;
            } else if (statusToFilter === 'NOT_SET') {
              var statusFound =
                item && item.completenessReviewStatus === statusToFilter;
              return statusFound;
            }
          });
        }
      };
    }
  ])
  .service('ZoneReviewLevelService', [
    '$http',
    '$log',
    'CubeSvc',
    function($http, $log, CubeSvc) {
      this.getZoneReviewLevelDetails = function(
        reviewLevel,
        zoneId,
        pledgeYear
      ) {
        var url =
          '/CfcCharity/api/private/zone/' +
          reviewLevel +
          '/' +
          zoneId +
          '/' +
          pledgeYear;
        return $http.get(url);
      };

      this.updateZoneApplicationDetails = function(
        zoneReviewLevelRequest,
        pledgeYear
      ) {
        var url = '/CfcCharity/api/private/zone/applicationreview/update';
        var configOb = !!pledgeYear
          ? { params: { pledgeYear: pledgeYear } }
          : undefined;
        return $http.post(url, zoneReviewLevelRequest, configOb);
      };

      this.continueUpdatingZoneApplications = function(
        jobId,
        zoneId,
        pledgeYear
      ) {
        var url =
          '/CfcCharity/api/private/zone/applicationreview/update/' +
          jobId +
          '/' +
          zoneId +
          '/' +
          pledgeYear;
        return $http.post(url);
      };

      this.continueSendingEmailForUpdatedReviewLevelApplication = function(
        jobId,
        zoneId
      ) {
        var url =
          '/CfcCharity/api/private/zone/applicationreview/continue/update/process/sendemail/' +
          jobId +
          '/' +
          zoneId;
        return CubeSvc.post(url);
      };

      this.sendEmailsForUpdatedZoneApplication = function(
        zoneReviewLevelRequest
      ) {
        var url =
          '/CfcCharity/api/private/zone/applicationreview/update/sendemail';
        return CubeSvc.post(url, zoneReviewLevelRequest);
      };

      this.getZoneReviewLevelBulkJobDetails = function(
        reviewLevel,
        zoneId,
        pledgeYear
      ) {
        var url =
          '/CfcCharity/api/private/processJob/getZoneReviewLevelBulkJobStatus/' +
          reviewLevel +
          '/' +
          zoneId +
          '/' +
          pledgeYear;
        return CubeSvc.get(url, null, true);
      };
    }
  ])
  .constant(
    'ZONE_FILTERS',
    (function() {
      return {
        SUBMISSION: 'Submission',
        GROUP: 'Group',
        ASSIGNEE: 'Assignee',
        TYPE: 'Type',
        COMPLETENESS: 'Completeness',
        STAFF_ELIGIBILITY: 'StaffEligibility',
        PRE_ELIGIBILITY: 'PreliminaryEligibility',
        FINAL_ELIGIBILITY: 'FinalEligibility'
      };
    })()
  )
  .controller('ZoneReviewLevelCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'ZoneReviewLevelService',
    'BrowserEventService',
    'AdminReviewSectionStepDeterminationSessionService',
    'reviewLevelConstants',
    '$filter',
    '$interval',
    'blockUIConfig',
    'CharityReviewService',
    'ZONE_FILTERS',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      ZoneReviewLevelService,
      BrowserEventService,
      AdminReviewSectionStepDeterminationSessionService,
      reviewLevelConstants,
      $filter,
      $interval,
      blockUIConfig,
      CharityReviewService,
      ZONE_FILTERS
    ) {
      var moduleName = $state.current.name;
      var reviewLevelToUse = null;
      $scope.displayBulkArea = false;
      $scope.reviewLevelSelections = {};
      $scope.reviewLevelSelectedData = {};
      $scope.timeOutmessage = null;
      $scope.showClearFilters = false;
      switch (moduleName) {
        case 'zonereviewlevel1':
          reviewLevelToUse = reviewLevelConstants.StaffEligibility;
          break;
        case 'zonereviewlevel2':
          reviewLevelToUse = reviewLevelConstants.PreliminaryEligibility;
          break;
        case 'zonereviewlevel3':
          reviewLevelToUse = reviewLevelConstants.FinalEligibility;
          break;
        case 'zonecompletenessreview':
        default:
          reviewLevelToUse = reviewLevelConstants.Completeness;
          break;
      }
      AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
        reviewLevelToUse
      );

      $scope.setModuleName('CfcCharity.' + moduleName);

      var zoneId = $state.params.zoneId;
      var pledgeYear = $state.params.pledgeYear;

      if (!pledgeYear) {
        pledgeYear = BrowserEventService.getPledgeYear();
      } else {
        BrowserEventService.setPledgeYear(pledgeYear);
      }

      $scope.showZoneReviewLevelErrorMessage = function(error) {
        $scope.zoneReviewLevelError = error;
      };
      $scope.showZoneReviewLevelSuccessMessage = function(successMsg) {
        $scope.zoneReviewLevelSuccess = successMsg;
      };

      $scope.clearDisplayMessages = function() {
        $scope.zoneReviewLevelError = '';
        $scope.zoneReviewLevelSuccess = '';
      };
      $scope.pledgeYear = pledgeYear;
      var error = '';
      if (!zoneId) {
        if (zoneId === 0) {
          BrowserEventService.setZoneCompletenessReviewZoneId(zoneId);
        } else {
          zoneId = BrowserEventService.getZoneCompletenessReviewZoneId();
          if (!zoneId) {
            error =
              'Cannot retrieve the zone review level as the zone id is : ' +
              zoneId;
            $scope.showZoneReviewLevelErrorMessage(error);
          }
        }
      } else {
        BrowserEventService.setZoneCompletenessReviewZoneId(zoneId);
      }
      $scope.zoneReviewLevelData = {};
      var reviewLevel = '';

      if (moduleName && moduleName === 'zonecompletenessreview') {
        reviewLevel = 'Completeness';
      } else if (moduleName && moduleName === 'zonereviewlevel1') {
        reviewLevel = 'StaffEligibility';
      } else if (moduleName && moduleName === 'zonereviewlevel2') {
        reviewLevel = 'PreliminaryEligibility';
      } else if (moduleName && moduleName === 'zonereviewlevel3') {
        reviewLevel = 'FinalEligibility';
      }

      $scope.reviewLevel = reviewLevel;

      function init() {
        ZoneReviewLevelService.getZoneReviewLevelDetails(
          reviewLevel,
          zoneId,
          pledgeYear
        )
          .then(function(res) {
            var data = res.data;
            if (data && (data.error || data.statusCode === 'ERROR')) {
              $scope.showZoneReviewLevelErrorMessage(
                data.errorResponse.errorCode +
                  ' : ' +
                  data.errorResponse.errorMessage +
                  '. Please try later!'
              );
            } else if (data && data.statusCode === 'SUCCESS') {
              $scope.zoneReviewLevelData = data.zoneReviewLevelBean;
              $scope.visibleItems =
                $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList;
              var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
              $scope.zoneUserRole = secondaryRoleList[0];
              var zoneCode = $scope.zoneReviewLevelData.zoneCode;
              var canEdit = true;
              if (
                secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
                secondaryRoleList.indexOf('GB_ADMIN') >= 0 ||
                secondaryRoleList.indexOf('OPM_STAFF') >= 0
              ) {
                canEdit = false;
              }

              $scope.canEdit = canEdit;

              // set filters if available in localStorage
              $scope.setFilterOptionsIfInStorage();
            }
          })
          .then(function() {
            $scope.getZoneReviewLevelBulkJobDetails();
          })
          .catch(function(err) {
            if (err.status !== 504) {
              error =
                'Error retrieving the zone ' +
                $scope.reviewLevel +
                'review for the zone id : ' +
                zoneId;
              $scope.showZoneReviewLevelErrorMessage(error);
            } else {
              $scope.timeOutmessage =
                'The files you selected are processing. This may take some time. You must leave this page and click back in to see when the processing is complete or to continue working on other applications.';
            }
          });
      }

      var intId = null;
      var isTimerStated = false;

      $scope.getZoneReviewLevelBulkJobDetails = function() {
        blockUIConfig.autoBlock = false;
        ZoneReviewLevelService.getZoneReviewLevelBulkJobDetails(
          reviewLevel,
          zoneId,
          pledgeYear
        )
          .then(function(res) {
            var data = res.data.value;
            $scope.displayBulkArea = false;
            if (data) {
              if (
                data.reviewLevelSelectionsBeanList &&
                data.reviewLevelSelectionsBeanList.length > 0
              ) {
                $scope.reviewLevelSelections =
                  data.reviewLevelSelectionsBeanList;
                if (
                  $scope.zoneReviewLevelData &&
                  $scope.zoneReviewLevelData
                    .zoneReviewLevelApplicationBeanList &&
                  $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
                    .length > 0
                ) {
                  angular.forEach(
                    $scope.zoneReviewLevelData
                      .zoneReviewLevelApplicationBeanList,
                    function(reviewLevelBean) {
                      angular.forEach($scope.reviewLevelSelections, function(
                        reviewLevelSelection
                      ) {
                        if (
                          reviewLevelBean.cfcCode ===
                          reviewLevelSelection.cfcCode
                        ) {
                          reviewLevelBean.isSelected = true;
                          reviewLevelBean.isDisabled = true;
                        }
                      });
                    }
                  );
                  $scope.reviewLevelSelectedData = $scope.getSelectedRowsDataList();
                }
              }
              if (
                data.processJobStatusBeanSet &&
                data.processJobStatusBeanSet.length > 0
              ) {
                $scope.zoneReviewLevelBulkProcessData =
                  data.processJobStatusBeanSet;
                $scope.displayBulkArea = true;
              }

              if (!isTimerStated && data.processJobStatusBeanSet.length > 0) {
                $scope.startTimer();
                isTimerStated = true;
              }
            }
          })
          .catch(function(err) {
            if (err.status !== 504) {
              error =
                'Error retrieving the zone ' +
                $scope.reviewLevel +
                'review for the zone id : ' +
                zoneId;
              $scope.showZoneReviewLevelErrorMessage(error);
            } else {
              $scope.timeOutmessage =
                'The files you selected are processing. This may take some time. You must leave this page and click back in to see when the processing is complete or to continue working on other applications.';
            }
          });
      };

      // start polling the bulk data every interval
      $scope.startTimer = function() {
        var timer = 0;
        intId = $interval(function() {
          timer++;
          //after 1 refresh stop the refreshing of the page
          if (timer <= 1) {
            $scope.timeOutmessage = null;
            init();
          } else {
            $interval.cancel(intId);
          }
        }, 5000);
      };

      // stop job types polling
      $scope.stopTimer = function() {
        $interval.cancel(intId);
      };

      $scope.continueBulkUpdate = function(id) {
        ZoneReviewLevelService.continueUpdatingZoneApplications(
          id,
          zoneId,
          $scope.pledgeYear
        )
          .then(function(res) {
            var data = res.data;
            var zoneReviewLevelResponse = data;
            if (data && (data.error || data.statusCode === 'ERROR')) {
              $scope.showZoneReviewLevelErrorMessage(
                data.errorResponse.errorCode +
                  ' : ' +
                  data.errorResponse.errorMessage +
                  '. Please try later!'
              );
            } else if (data && data.statusCode === 'SUCCESS') {
              if (zoneReviewLevelResponse.statusChange) {
                $scope.showZoneReviewLevelSuccessMessage(
                  'Successfully completed update process for the selected job.'
                );
                //remove updated data from display
                $scope.timeOutmessage = null;
                init();
                //clear all selection
                $scope.clearAllSelection();
                //$scope.deleteSelectedRowsFromDisplay();
                //now call the end  point to send emails for the status changed apps
                blockUIConfig.autoBlock = false;
                ZoneReviewLevelService.continueSendingEmailForUpdatedReviewLevelApplication(
                  id,
                  zoneId
                );
              } else {
                $scope.showZoneReviewLevelSuccessMessage(
                  'Successfully completed the update process for the selected job.'
                );
                //remove updated data from display
                $scope.timeOutmessage = null;
                init();
                //clear all selection
                $scope.clearAllSelection();
                $scope.rowCount = 0;
              }
            }
          })
          .catch(function(err) {
            if (err.status !== 504) {
              $scope.showZoneReviewLevelErrorMessage(
                'Error continuing the update process for the selected job'
              );
            } else {
              $scope.timeOutmessage =
                'The files you selected are processing. This may take some time. You must leave this page and click back in to see when the processing is complete or to continue working on other applications.';
            }
          });
      };

      //register event to stop polling if user navigates away. Also re-enable block UI
      $rootScope.$on('$destroy', function() {
        $scope.stopTimer();
        blockUIConfig.autoBlock = true;
      });

      //when you go out of the page start loading again
      $rootScope.$on('$locationChangeStart', function(event, next, current) {
        blockUIConfig.autoBlock = true;
      });

      $scope.gotoZoneCenter = function(zoneId, role) {
        BrowserEventService.setZoneUserRole(role);
        BrowserEventService.setZoneId(zoneId);
        $state.go('zoneCenter', {
          zoneId: zoneId,
          zoneUserRole: role
        });
      };
      //Added by Prem
      // var pledgeYear = $state.params.pledgeYear;

      var user = $scope.getCurrentUser();

      $scope.selectedAll = 'checked';

      $scope.getLinkToCharityReview =
        CharityReviewService.getLinkToCharityReview;

      $scope.goToCharityReview = function() {
        // persist filters in local storage
        if ($scope.selectedFilterUserId !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.ASSIGNEE,
            $scope.selectedFilterUserId
          );
        }

        if ($scope.selectedMatchedStatus !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.COMPLETENESS,
            $scope.selectedMatchedStatus
          );
        }

        if ($scope.selectedMatchedType !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.TYPE,
            $scope.selectedMatchedType
          );
        }

        if ($scope.selectedMatchedSubmittedStatus !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.SUBMISSION,
            $scope.selectedMatchedSubmittedStatus
          );
        }

        if ($scope.selectedGroupId !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.GROUP,
            $scope.selectedGroupId
          );
        }

        if ($scope.reviewLevelType.status !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.STAFF_ELIGIBILITY,
            $scope.reviewLevelType.status
          );
        }

        if ($scope.reviewLevelType.status !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.PRE_ELIGIBILITY,
            $scope.reviewLevelType.status
          );
        }

        if ($scope.selectedMatchedFinalStatus !== undefined) {
          $scope.setLocalStorageItem(
            ZONE_FILTERS.FINAL_ELIGIBILITY,
            $scope.selectedMatchedFinalStatus
          );
        }
      };

      //on click of Assign button
      $scope.chooseAssigner = function(zoneReviewLevelApplicationBean) {
        if (
          $scope.canEdit === false ||
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
            .length === 0
        ) {
          return;
        }

        $scope.selectedAssigner = {};

        var selectedRowsDataList = [];
        $scope.clearDisplayMessages();

        $scope.zoneReviewLevelData.saveEnabled = false;
        var selectedUserFromList = {};
        if (zoneReviewLevelApplicationBean) {
          //when click on individual row Assign

          $scope.selectedRows = 1;
          $scope.clearAllSelection();
          zoneReviewLevelApplicationBean.isSelected = true;
          //if the actual data size is 1 then select all box
          if (
            $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
              .length === 1
          ) {
            $scope.selectedAll.isSelected = true;
          } else {
            $scope.selectedAll.isSelected = false;
          }
          selectedUserFromList.reviewerUserId = String(
            zoneReviewLevelApplicationBean.reviewerUserId
          );
          $scope.selectedAssigner = selectedUserFromList;
        } else {
          //when click on Assign button
          selectedRowsDataList = $scope.getSelectedRowsDataList();
          $scope.selectedRows = selectedRowsDataList.length;
          if (
            $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
              .length === 1
          ) {
            //only one row of the actual data..
            $scope.selectedAll.isSelected = true;
            $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList[0].isSelected = true;
            selectedUserFromList.reviewerUserId = String(
              $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList[0]
                .reviewerUserId
            );
            $scope.selectedAssigner = selectedUserFromList;
          } else if (selectedRowsDataList.length === 0) {
            //error message to select atleast one charity
            $scope.showZoneReviewLevelErrorMessage(
              'Please select a single or multiple charities to assign.'
            );
            return;
          } else if (selectedRowsDataList.length === 1) {
            //only one row selected -- set selectedAssigner to selected one
            selectedUserFromList.reviewerUserId = String(
              selectedRowsDataList[0].reviewerUserId
            );
            $scope.selectedAssigner = selectedUserFromList;
            //if the actual data size is 1 then select all box
            if (
              $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
                .length === 1
            ) {
              $scope.selectedAll.isSelected = true;
            }
          }
        }
        $('#assignAuditor').modal();
      };
      $scope.selectedAll = {};
      $scope.selectedAll.isSelected = false;

      //on click of single row
      $scope.selectedRow = function(item) {
        var allDataList =
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList;
        if (allDataList.length === 1) {
          $scope.selectedAll.isSelected = allDataList[0].isSelected;
        } else {
          $scope.selectedAll.isSelected = false;
        }
        $scope.clearDisplayMessages();
        angular.forEach(
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList,
          function(reviewLevelBean) {
            if (
              reviewLevelBean.cfcCode ===
              item.zoneReviewLevelApplicationBean.cfcCode
            ) {
              reviewLevelBean.isSelected = !reviewLevelBean.isSelected;
            }
          }
        );
        var selectedRowsDataList = $scope.getSelectedRowsDataList();
        if (allDataList.length === selectedRowsDataList.length) {
          $scope.selectedAll.isSelected = true;
        } else {
          $scope.selectedAll.isSelected = false;
        }
      };

      $scope.onSelectAllChanged = function(visibleItems) {
        angular.forEach(visibleItems, function(item) {
          item.isSelected = $scope.selectedAll.isSelected;
        });
        $scope.getSelectedCount();
      };

      $scope.checkAllFromPage = function() {
        $scope.clearAllSelection();
        $scope.clearDisplayMessages();
        var visibleRows = $('#zoneTable')
          .children('tbody')
          .children('tr');
        var visibleRowIds = [];
        $(visibleRows).each(function() {
          visibleRowIds.push($(this).data('id'));
        });
        angular.forEach(
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList,
          function(item) {
            if (visibleRowIds.includes(item.id)) {
              if (item.isDisabled === undefined) {
                item.isSelected = true;
              }
            }
          }
        );
        $scope.getSelectedCount();
      };

      $scope.getSelectedCount = function() {
        var selectedRows = $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList.filter(
          function(obj) {
            if (obj.isDisabled === undefined) {
              return obj.isSelected === true;
            }
          }
        );
        $scope.rowCount = selectedRows === null ? 0 : selectedRows.length;
      };

      $scope.checkAll = function(visibleItems) {
        $scope.clearDisplayMessages();
        $scope.clearAllSelection();
        angular.forEach(visibleItems, function(item) {
          if (item.isDisabled === undefined) {
            item.isSelected = true;
          }
        });
        $scope.getSelectedCount();
      };

      //clears all selection
      $scope.clearAllSelection = function() {
        angular.forEach(
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList,
          function(reviewLevelBean) {
            if (reviewLevelBean.isDisabled === undefined) {
              reviewLevelBean.isSelected = false;
            }
          }
        );
        $scope.getSelectedCount();
      };

      //ng-click of determination or status button
      $scope.selectData = function() {
        if (
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
            .length === 1
        ) {
          //only one row of the actual data..
          $scope.selectedAll.isSelected = true;
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList[0].isSelected = true;
        }
      };

      // Remove ability to set Application Status from Zone Manager and Zone Reviewers
      $scope.canEditApplicationStatus = true;
      const userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      const secondaryRoleList = userObj.secondaryRoleList;
      if (
        secondaryRoleList &&
        (secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0)
      ) {
        $scope.canEditApplicationStatus = false;
      }

      // CCA-1691 - only allow bulk approval for OPM Admin roles
      $scope.isBulkApprovalAllowed = function() {
        var yn = false;
        // get current user
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        // is OPM Admin
        yn = userObj.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
        return yn;
      };

      $scope.clearLocalStorageFilters = function() {
        localStorage.removeItem(ZONE_FILTERS.SUBMISSION);
        localStorage.removeItem(ZONE_FILTERS.GROUP);
        localStorage.removeItem(ZONE_FILTERS.ASSIGNEE);
        localStorage.removeItem(ZONE_FILTERS.TYPE);
        localStorage.removeItem(ZONE_FILTERS.COMPLETENESS);
        localStorage.removeItem(ZONE_FILTERS.STAFF_ELIGIBILITY);
        localStorage.removeItem(ZONE_FILTERS.PRE_ELIGIBILITY);
        localStorage.removeItem(ZONE_FILTERS.FINAL_ELIGIBILITY);
      };

      $scope.clearFilters = function() {
        $scope.clearLocalStorageFilters();
        $scope.showClearFilters = false;

        $scope.rowCount = 0;

        // reset filter options to all
        $scope.selectedGroupId = undefined;
        $scope.selectedMatchedType = undefined;
        $scope.selectedFilterUserId = undefined;
        $scope.selectedMatchedSubmittedStatus = undefined;
        $scope.selectedCharityApplicationStatusCodes = undefined;
        $scope.selectedMatchedStatus = undefined;
        $scope.selectedMatchedFinalStatus = undefined;
        $scope.reviewLevelType.status = undefined;

        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;

        return;
      };

      $scope.setLocalStorageItem = function(itemName, itemValue) {
        localStorage.setItem(itemName, itemValue);
        return;
      };

      $scope.getLocalStorageItem = function(itemName) {
        return localStorage.getItem(itemName);
      };

      //on click of determination or status with value selection
      $scope.updateDeterminationStatus = function(type, selectedValue) {
        // $scope.timeOutmessage =
        //   'The files you selected are processing. This may take some time. If you would like to continue processing other applications in this zone page, you may leave this page and click back in or click on another zone to continue processing other zone applications.';
        if (type === 'STATUS' && !$scope.isBulkApprovalAllowed()) {
          $scope.showZoneReviewLevelErrorMessage(
            'Error. You should not be able to update the status of these records in bulk.'
          );
          return;
        }

        $scope.clearDisplayMessages();

        if (
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
            .length === 0
        ) {
          return;
        }

        var selectedRowsDataList = $scope.getSelectedRowsDataList();
        if (
          !$scope.reviewLevelSelectedData &&
          !$scope.reviewLevelSelectedData.length > 0 &&
          selectedRowsDataList &&
          selectedRowsDataList.length > 0
        ) {
          selectedRowsDataList = selectedRowsDataList.filter(
            srdl =>
              !$scope.reviewLevelSelectedData.find(
                rlsd => rlsd.cfcCode === srdl.cfcCode
              )
          );
        }
        if (selectedRowsDataList.length === 0) {
          $scope.showZoneReviewLevelErrorMessage(
            'Please select a single or multiple charities to assign.'
          );
          return;
        } else {
          //update determination or status based on type
          //create request to update determination or status
          var zoneReviewLevelRequest = {};
          zoneReviewLevelRequest.zoneReviewLevelRequestBean = {};
          zoneReviewLevelRequest.zoneReviewLevelRequestBean.reviewLevel =
            $scope.zoneReviewLevelData.reviewLevel;
          zoneReviewLevelRequest.zoneReviewLevelRequestBean.zoneId = zoneId;

          //zoneReviewLevelRequest.zoneReviewLevelRequestBean.applicationReviewerUserId =
          zoneReviewLevelRequest.zoneReviewLevelRequestBean.zoneReviewLevelApplicationRequestBeanList = [];

          angular.forEach(selectedRowsDataList, function(reviewLevelBean) {
            var zoneReviewLevelApplicationRequestBean = {};
            zoneReviewLevelApplicationRequestBean.applicationId =
              reviewLevelBean.id;
            zoneReviewLevelApplicationRequestBean.applicationType =
              reviewLevelBean.charityApplicationType;
            zoneReviewLevelApplicationRequestBean.cfcCode =
              reviewLevelBean.cfcCode;
            zoneReviewLevelRequest.zoneReviewLevelRequestBean.zoneReviewLevelApplicationRequestBeanList.push(
              zoneReviewLevelApplicationRequestBean
            );
          });
          if (type === 'DETERMINATION') {
            zoneReviewLevelRequest.zoneReviewLevelRequestBean.determination = selectedValue;

            ZoneReviewLevelService.updateZoneApplicationDetails(
              zoneReviewLevelRequest,
              $scope.pledgeYear
            )
              .then(function(res) {
                var data = res.data;
                var zoneReviewLevelResponse = data;
                if (data && (data.error || data.statusCode === 'ERROR')) {
                  $scope.showZoneReviewLevelErrorMessage(
                    data.errorResponse.errorCode +
                      ' : ' +
                      data.errorResponse.errorMessage +
                      '. Please try later!'
                  );
                } else if (data && data.statusCode === 'SUCCESS') {
                  if (
                    $scope.zoneReviewLevelData.reviewLevel !==
                    'Final Eligibility'
                  ) {
                    $scope.showZoneReviewLevelSuccessMessage(
                      'Successfully updated determination (' +
                        selectedValue +
                        ') for selected Charities.'
                    );
                    //remove updated data from display
                    $scope.timeOutmessage = null;
                    init();
                    //clear all selection
                    $scope.clearAllSelection();
                  } else {
                    //after service call success ..update data for display for final eligibility.
                    angular.forEach(
                      $scope.zoneReviewLevelData
                        .zoneReviewLevelApplicationBeanList,
                      function(reviewLevelBean) {
                        if (reviewLevelBean.isSelected) {
                          reviewLevelBean.finalEligibilityStatus = selectedValue.toUpperCase();
                          //after determination on the final eligin=bility level uncheck the selected cfcs
                          if (reviewLevelBean.isDisabled === undefined) {
                            reviewLevelBean.isSelected = false;
                          }
                        }
                      }
                    );
                  }
                }
              })
              .catch(function(err) {
                if (err.status !== 504) {
                  $scope.showZoneReviewLevelErrorMessage(
                    'Error updating determination (' +
                      selectedValue +
                      ') for selected Charities'
                  );
                } else {
                  $scope.timeOutmessage =
                    'The files you selected are processing. This may take some time. You must leave this page and click back in to see when the processing is complete or to continue working on other applications.';
                }
              });
            $scope.rowCount = 0;
          } else if (type === 'STATUS') {
            zoneReviewLevelRequest.zoneReviewLevelRequestBean.applicationReviewStatus = selectedValue;
            $scope.zoneRequest = zoneReviewLevelRequest;
            ZoneReviewLevelService.updateZoneApplicationDetails(
              zoneReviewLevelRequest,
              $scope.pledgeYear
            )
              .then(function(res) {
                var data = res.data;
                var zoneReviewLevelResponse = data;
                if (data && (data.error || data.statusCode === 'ERROR')) {
                  $scope.showZoneReviewLevelErrorMessage(
                    data.errorResponse.errorCode +
                      ' : ' +
                      data.errorResponse.errorMessage +
                      '. Please try later!'
                  );
                } else if (data.failedCfcCodesForEmail.length > 0) {
                  $scope.failedCfcCodes = data.failedCfcCodesForEmail;
                } else if (data && data.statusCode === 'SUCCESS') {
                  $scope.showZoneReviewLevelSuccessMessage(
                    'Successfully updated status (' +
                      selectedValue +
                      ')  for selected Charities.'
                  );
                  //remove updated data from display
                  $scope.timeOutmessage = null;
                  init();
                  //clear all selection
                  $scope.clearAllSelection();
                }
              })
              .catch(function(err) {
                if (err.status !== 504) {
                  $scope.showZoneReviewLevelErrorMessage(
                    'Error updating status (' +
                      selectedValue +
                      ') for selected Charities'
                  );
                } else {
                  $scope.timeOutmessage =
                    'The files you selected are processing. This may take some time. You must leave this page and click back in to see when the processing is complete or to continue working on other applications.';
                }
              });
            $scope.rowCount = 0;
          }
        }
      };

      //Removes selected rows from display
      $scope.deleteSelectedRowsFromDisplay = function() {
        if ($scope.selectedAll.isSelected) {
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList = [];
        } else {
          for (
            i =
              $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList
                .length;
            i--;

          ) {
            if (
              $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList[i]
                .isSelected
            ) {
              $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList.splice(
                i,
                1
              );
            }
          }
        }
        $scope.timeOutmessage = null;
      };

      //on click of save button of Assign reviewer
      $scope.updateAssignee = function() {
        // $scope.timeOutmessage =
        //   'The files you selected are processing. This may take some time. If you would like to continue processing other applications in this zone page, you may leave this page and click back in or click on another zone to continue processing other zone applications.';
        var selectedRowsDataList = $scope.getSelectedRowsDataList();
        if (
          !$scope.reviewLevelSelectedData &&
          !$scope.reviewLevelSelectedData.length > 0 &&
          selectedRowsDataList &&
          selectedRowsDataList.length > 0
        ) {
          selectedRowsDataList = selectedRowsDataList.filter(
            srdl =>
              !$scope.reviewLevelSelectedData.find(
                rlsd => rlsd.cfcCode === srdl.cfcCode
              )
          );
        }
        //get current selected user id and assign this user to all selectedRowsDataList
        var selectedUserId = $scope.selectedAssigner.reviewerUserId;

        //
        //create request to update determination or status
        var zoneReviewLevelRequest = {};
        zoneReviewLevelRequest.zoneReviewLevelRequestBean = {};
        zoneReviewLevelRequest.zoneReviewLevelRequestBean.reviewLevel =
          $scope.zoneReviewLevelData.reviewLevel;
        zoneReviewLevelRequest.zoneReviewLevelRequestBean.applicationReviewerUserId = selectedUserId;
        zoneReviewLevelRequest.zoneReviewLevelRequestBean.zoneReviewLevelApplicationRequestBeanList = [];
        zoneReviewLevelRequest.zoneReviewLevelRequestBean.zoneId = zoneId;

        angular.forEach(selectedRowsDataList, function(reviewLevelBean) {
          var zoneReviewLevelApplicationRequestBean = {};
          zoneReviewLevelApplicationRequestBean.applicationId =
            reviewLevelBean.id;
          zoneReviewLevelApplicationRequestBean.applicationType =
            reviewLevelBean.charityApplicationType;
          zoneReviewLevelApplicationRequestBean.cfcCode =
            reviewLevelBean.cfcCode;
          zoneReviewLevelRequest.zoneReviewLevelRequestBean.zoneReviewLevelApplicationRequestBeanList.push(
            zoneReviewLevelApplicationRequestBean
          );
        });
        //call the service to update/assign selected Reviewer
        ZoneReviewLevelService.updateZoneApplicationDetails(
          zoneReviewLevelRequest,
          $scope.pledgeYear
        )
          .then(function(res) {
            var data = res.data;
            $scope.timeOutmessage = null;
            if (data && (data.error || data.statusCode === 'ERROR')) {
              $scope.showZoneReviewLevelErrorMessage(
                data.errorResponse.errorCode +
                  ' : ' +
                  data.errorResponse.errorMessage +
                  '. Please try later!'
              );
            } else if (data && data.statusCode === 'SUCCESS') {
              var selectedUser = $scope.getUserDetails(
                $scope.zoneReviewLevelData.reviewerUserBeanList,
                selectedUserId
              );

              //after service call success ..update data for display.
              $scope.timeOutmessage = null;
              init();
              //clear all selection
              $scope.clearAllSelection();
            }
          })
          .catch(function(err) {
            if (err.status !== 504) {
              $scope.showZoneReviewLevelErrorMessage(
                'Error updating Assignee for selected Charities'
              );
            } else {
              $scope.timeOutmessage =
                'The files you selected are processing. This may take some time. You must leave this page and click back in to see when the processing is complete or to continue working on other applications.';
            }
          });
      };

      //return list of selected rows from all data  list
      $scope.getSelectedRowsDataList = function() {
        var selectedRowsDataList = [];
        angular.forEach(
          $scope.zoneReviewLevelData.zoneReviewLevelApplicationBeanList,
          function(reviewLevelBean) {
            if (reviewLevelBean.isSelected) {
              if (reviewLevelBean.isDisabled === undefined) {
                selectedRowsDataList.push(reviewLevelBean);
              } else {
                //dont count the deactivated rows
                if (reviewLevelBean.isDisabled !== true) {
                  selectedRowsDataList.push(reviewLevelBean);
                }
              }
            }
          }
        );
        $scope.rowCount = selectedRowsDataList.length;
        return selectedRowsDataList;
      };

      //return user details from users list by userid.
      $scope.getUserDetails = function(zoneReviewerUsersList, userId) {
        var matchedUserBean = '';
        angular.forEach(zoneReviewerUsersList, function(zoneReviewUserBean) {
          if (zoneReviewUserBean.reviewerUserId.toString() === userId) {
            matchedUserBean = zoneReviewUserBean;
          }
        });

        return matchedUserBean;
      };

      $scope.setCharityApplicationStatusCode = function(
        charityApplicationStatusCodes
      ) {
        $scope.rowCount = 0;
        $scope.selectedCharityApplicationStatusCodes = charityApplicationStatusCodes;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };

      /*     $scope.matchCharityApplicationStatus = function(charityApplicationStatusCodes) {
               return function(zoneReviewLevelData) {

                    if (!charityApplicationStatusCodes) {
                       $scope.setCharityApplicationStatusCode(undefined);
                       return zoneReviewLevelData; 
                   } else if (zoneReviewLevelData && zoneReviewLevelData.zoneReviewLevelApplicationBean && 
                       zoneReviewLevelData.zoneReviewLevelApplicationBean.currentState) {
                       
                      for (var i = 0; i < charityApplicationStatusCodes.length; i++) {      
                          
                          var charityApplicationStatusCode = charityApplicationStatusCodes[i];
                          if (zoneReviewLevelData.zoneReviewLevelApplicationBean.currentState === charityApplicationStatusCode)
                        {
                       $scope.setCharityApplicationStatusCode(undefined);
                       return zoneReviewLevelData; 
                        } // end if
                   }  // end for
                      } // end if
               };
                 
           };*/

      $scope.setFilterOptionsIfInStorage = function() {
        // first, filter data if local storage filters available

        // Submission
        const filterSubmission = $scope.getLocalStorageItem(
          ZONE_FILTERS.SUBMISSION
        );
        if (filterSubmission) {
          $scope.setMatchedSubmittedStatus(
            filterSubmission === 'undefined'
              ? undefined
              : filterSubmission == 'true'
          );
        }

        // Group
        const filterGroup = $scope.getLocalStorageItem(ZONE_FILTERS.GROUP);
        if (filterGroup) {
          $scope.setSelectedGroup(
            filterGroup === 'All' ? filterGroup : parseInt(filterGroup)
          );
        }

        // Type
        const filterType = $scope.getLocalStorageItem(ZONE_FILTERS.TYPE);
        if (filterType) {
          $scope.setMatchedType(
            filterType === 'undefined' ? undefined : filterType
          );
        }

        // Assignee
        const filterAssignee = $scope.getLocalStorageItem(
          ZONE_FILTERS.ASSIGNEE
        );
        if (filterAssignee) {
          $scope.setSelectedUser(
            filterAssignee === 'All' ? filterAssignee : parseInt(filterAssignee)
          );
        }

        // Completeness
        const completenessType = $scope.getLocalStorageItem(
          ZONE_FILTERS.COMPLETENESS
        );
        if (completenessType) {
          $scope.setMatchedStatus(
            completenessType === 'undefined' ? undefined : completenessType
          );
        }

        // Staff Eligibility
        const staffEligibility = $scope.getLocalStorageItem(
          ZONE_FILTERS.STAFF_ELIGIBILITY
        );
        if (staffEligibility) {
          $scope.setReviewLevelStatus(
            staffEligibility === 'undefined' ? undefined : staffEligibility
          );
        }

        // Pre Eligibility
        const preEligibility = $scope.getLocalStorageItem(
          ZONE_FILTERS.PRE_ELIGIBILITY
        );
        if (preEligibility) {
          $scope.setReviewLevelStatus(
            preEligibility === 'undefined' ? undefined : preEligibility
          );
        }

        // Final Eligibility
        const finalEligibility = $scope.getLocalStorageItem(
          ZONE_FILTERS.FINAL_ELIGIBILITY
        );
        if (finalEligibility) {
          $scope.setMatchedFinalStatus(
            finalEligibility === 'undefined' ? undefined : finalEligibility
          );
        }

        // finally, clear all filters in local storage
        $scope.clearLocalStorageFilters();
      };

      //on click of filter of group
      $scope.setSelectedGroup = function(selectedGroupId) {
        $scope.rowCount = 0;
        $scope.selectedGroupId = selectedGroupId;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };

      $scope.setMatchedSubmittedStatus = function(
        selectedMatchedSubmittedStatus
      ) {
        $scope.rowCount = 0;
        $scope.selectedMatchedSubmittedStatus = selectedMatchedSubmittedStatus;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };

      $scope.setMatchedFinalStatus = function(selectedMatchedFinalStatus) {
        $scope.rowCount = 0;
        $scope.selectedMatchedFinalStatus = selectedMatchedFinalStatus;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };

      $scope.setMatchedSystemStatus = function(selectedMatchedSystemStatus) {
        $scope.rowCount = 0;
        $scope.selectedMatchedSystemStatus = selectedMatchedSystemStatus;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
      };

      $scope.setMatchedType = function(selectedMatchedType) {
        $scope.rowCount = 0;
        $scope.selectedMatchedType = selectedMatchedType;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };

      $scope.setMatchedStatus = function(selectedMatchedStatus) {
        $scope.rowCount = 0;
        $scope.selectedMatchedStatus = selectedMatchedStatus;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };

      //on click of filter of user
      $scope.setSelectedUser = function(selectedUserId) {
        $scope.rowCount = 0;
        $scope.selectedFilterUserId = selectedUserId;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };

      //on click of filter of user
      $scope.setReviewLevelStatus = function(selectedStatus) {
        $scope.rowCount = 0;
        $scope.reviewLevelType = {};
        $scope.reviewLevelType.status = selectedStatus;
        $scope.reviewLevelType.previousStatus =
          $scope.zoneReviewLevelData.previousReviewLevel;
        $scope.clearAllSelection();
        $scope.selectedAll.isSelected = false;
        $scope.showClearFilters = true;
      };
      //End - Prem
      init();
    }
  ]);
