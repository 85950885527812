// redirect.service.js - Common $state Redirect Service for the application

'use strict';

angular.module('CfcCharity.common').factory('ValidationUtil', ValidationUtil);

ValidationUtil.$inject = ['moment'];

function ValidationUtil(moment) {
  var service = {
    validateEmail: validateEmail,
    validatePhone: validatePhone,
    isStringEmpty: isStringEmpty,
    isNumeric: isNumeric,
    containsDecimal: containsDecimal,
    isEinValidLength: isEinValidLength,
    validateStartEndDates: validateStartEndDates,
    isDependentFieldBlank: isDependentFieldBlank,
    validateDatesAreWithinPeriod: validateDatesAreWithinPeriod,
    validateDate: validateDate,
    validateDateInRespectedPeriod: validateDateInRespectedPeriod,
    validateDatesAreWithinPeriod: validateDatesAreWithinPeriod,
    validateFiscalPeriod: validateFiscalPeriod
  };
  return service;

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validatePhone(phone) {
    var re = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/;
    return re.test(phone);
  }

  function isStringEmpty(value) {
    if (value == null || value.length <= 0 || value === '') {
      return true;
    }
    return false;
  }

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  function containsDecimal(value) {
    return String(value).includes('.');
  }

  // checks that a value is 9-digit
  function isEinValidLength(value) {
    var re = /^\d{9}$/i;
    return re.test(value);
  }

  function validateStartEndDates(documentStartDate, documentEndDate) {
    var dateValidationErrors = [];
    try {
      if (documentStartDate) {
        var startDate = moment(new Date(documentStartDate).toISOString());
        if (startDate && !startDate.isValid()) {
          dateValidationErrors.push('Document Start Date is not valid ');
        }
      }
    } catch (error) {
      dateValidationErrors.push('Document Start Date is not valid ');
    }

    try {
      if (documentEndDate) {
        var endDate = moment(new Date(documentEndDate).toISOString());
        if (endDate && !endDate.isValid()) {
          dateValidationErrors.push('Document End Date is not valid ');
        }
      }
    } catch (error) {
      dateValidationErrors.push('Document End Date is not valid ');
    }

    if (documentStartDate && documentEndDate) {
      if (moment(startDate).isAfter(endDate)) {
        dateValidationErrors.push(
          'Document Start Date must be before Document End Date '
        );
      }
    }
    return dateValidationErrors;
  }

  function isDependentFieldBlank(...fields) {
    for (let i of fields) {
      if (isStringEmpty(i) === true) {
        return true;
      }
    }
    return false;
  }

  function validateDatesAreWithinPeriod(
    documentStartDate,
    documentEndDate,
    documentValidEndDate,
    validStartDateMonthsPrior,
    validStartDateDaysPrior
  ) {
    var priorValidStartDate = moment(documentValidEndDate)
      .subtract(validStartDateMonthsPrior, 'months')
      .subtract(validStartDateDaysPrior, 'days')
      .format('MM/DD/YYYY');
    var documentValidStartDate = moment(
      new Date(priorValidStartDate).toISOString()
    );
    var startDate = moment(new Date(documentStartDate).toISOString());
    var endDate = moment(new Date(documentEndDate).toISOString());
    if (
      !moment(startDate).isBetween(
        documentValidStartDate,
        documentValidEndDate,
        undefined,
        '[]'
      ) ||
      !moment(endDate).isBetween(
        documentValidStartDate,
        documentValidEndDate,
        undefined,
        '[]'
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  function validateDate(documentDate) {
    var valid = false;
    try {
      if (documentDate) {
        var date = moment(new Date(documentDate).toISOString());
        if (date && !date.isValid()) {
        } else {
          valid = true;
        }
      } else {
        valid = false;
      }
    } catch (error) {
      valid = false;
    }
    return valid;
  }

  function validateDateInRespectedPeriod(
    documentStartDate,
    documentEndDate,
    documentValidStartDate,
    documentValidEndDate
  ) {
    var startDate = null;
    var endDate = null;
    if (documentStartDate) {
      startDate = moment(new Date(documentStartDate).toISOString());
    }
    if (documentEndDate) {
      endDate = moment(new Date(documentEndDate).toISOString());
    }
    if (
      (startDate != null &&
        !moment(startDate).isBetween(
          documentValidStartDate,
          documentValidEndDate,
          undefined,
          '[]'
        )) ||
      (endDate != null &&
        !moment(endDate).isBetween(
          documentValidStartDate,
          documentValidEndDate,
          undefined,
          '[]'
        ))
    ) {
      return false;
    } else {
      return true;
    }
  }

  function validateFiscalPeriod(
    documentStartDate,
    documentEndDate,
    documentValidBeginDate,
    documentValidEndDate
  ) {
    var startDate = moment(new Date(documentStartDate).toISOString());
    var endDate = moment(new Date(documentEndDate).toISOString());
    var date1 = new Date(documentStartDate);
    var date2 = new Date(documentEndDate);

    var time = date2.getTime() - date1.getTime();
    //Math.round is needed here to add up all the extra timings needed for the leap year
    var days = Math.round(time / (1000 * 3600 * 24));
    if (
      days !== 365 ||
      !moment(endDate).isBetween(
        documentValidBeginDate,
        documentValidEndDate,
        undefined,
        '[]'
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
}
