angular
  .module('CfcCharity.updateuserinfo', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('updateuserinfo', {
        url: '/updateUserInfo',
        views: {
          main: {
            template: require('../views/private/charity/updateuserinfo.html'),
            controller: 'UpdateUserInfoCtrl'
          }
        },
        data: {
          pageTitle: 'Update User Information Page',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        resolve: {
          userInfoData: function($http, $stateParams) {
            var url = '/CfcCharity/api/private/users/current';
            return $http.get(url).then(function(res) {
              return res.data;
            });
          }
        }
      });
    }
  ])
  .service('UpdateUserInfoService', [
    '$http',
    function($http) {
      this.userInfo = function(url) {
        return $http.get(url).then(function(res) {
          return res.data;
        });
      };
    }
  ])
  .controller('UpdateUserInfoCtrl', [
    '$state',
    '$scope',
    '$location',
    'userInfoData',
    '$http',
    'UserService',
    'SYSTEM_USER_SECONDARY_ROLES',
    function(
      $state,
      $scope,
      $location,
      userInfoData,
      $http,
      UserService,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $scope.setModuleName('CfcCharity.updateuserinfo');
      $scope.userProfile = {};
      $scope.mustUpdate = false;
      if (userInfoData.firstName === ' ') {
        $scope.mustUpdate = true;
        userInfoData.firstName = '';
        userInfoData.lastName = '';
      }
      $scope.updateUserInfoData = userInfoData;
      $scope.userProfile.firstName = $scope.updateUserInfoData.firstName;
      $scope.userProfile.lastName = $scope.updateUserInfoData.lastName;
      $scope.userProfile.username = $scope.updateUserInfoData.username;
      $scope.updateUserInfo = async function() {
        if ($scope.userProfile.firstName.length > 50) {
          $scope.updateUserInfoErrors =
            'First Name length should be less than 20 characters';
          return;
        }
        if ($scope.userProfile.lastName.length > 50) {
          $scope.updateUserInfoErrors =
            'Last Name length should be less than 15 characters';
          return;
        }
        if (
          $scope.userProfile.firstName.includes('<') ||
          $scope.userProfile.firstName.includes('>') ||
          $scope.userProfile.lastName.includes('<') ||
          $scope.userProfile.lastName.includes('>')
        ) {
          $scope.updateUserInfoErrors = 'An error has occurred';
          return;
        }
        $http
          .post('/CfcCharity/api/private/users/update', $scope.userProfile)
          .then(function(res) {
            var data = res.data;
            $scope.updateUserInfoData = data;
            $scope.setCurrentUser($scope.updateUserInfoData);
            $scope.updateUserInfoErrors = '';
            $scope.updateUserEmailErrors = '';
            $scope.mustUpdate = false;
            postSaveNavigation(data.id);
          })
          .catch(function(err) {
            $scope.updateUserInfoMessages = 'An error has occurred';
          });
      };

      $scope.$on('$stateChangeStart', function(event, next, current) {
        if ($scope.mustUpdate) {
          $scope.updateUserInfoErrors =
            'Please save your name before continuing';
          event.preventDefault();
        }
      });

      $scope.gotoHomePage = function() {
        $scope.setModuleName('CfcCharity.userdashboard');
        $state.go('userDashboard', { userId: userInfoData.id });
      };

      postSaveNavigation = function(userId) {
        if (UserService.isSystemUser()) {
          if (
            UserService.hasSystemRole([
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
              SYSTEM_USER_SECONDARY_ROLES.zone_manager,
              SYSTEM_USER_SECONDARY_ROLES.reviewer, // zone
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
              SYSTEM_USER_SECONDARY_ROLES.gb_finance,
              SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
            ])
          ) {
            $scope.setModuleName('CfcCharity.administrationcenter');
            $state.go('administrationcenter', {
              userId: userId
            });
          } else if (
            UserService.hasSystemRole([
              SYSTEM_USER_SECONDARY_ROLES.national_campaign_manager
            ])
          ) {
            $scope.navigateToReports();
          } else {
            $scope.setLeftSideNavbarMenuItemSelected('userhomepage');
            $scope.setModuleName('CfcCharity.userdashboard');
            $state.go('userDashboard', {
              userId: userId
            });
          }
        } else if (UserService.isCharityUser()) {
          $scope.setModuleName('CfcCharity.userdashboard');
          $state.go('userDashboard', {
            userId: userId
          });
        }
      };
    }
  ]);
