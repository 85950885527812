angular
  .module('CfcCharity.federationDashboard', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('federationapplication', {
        url: '/federationapplication',
        views: {
          main: {
            template: require('../views/private/federation/federationapplicationdashboard.html'),
            controller: 'FederationApplicationCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Application',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          cfcCode: null,
          fedAppId: null
        },
        resolve: {
          federationApplicationData: function(
            $http,
            $stateParams,
            AdminReviewSectionStepDeterminationSessionService,
            CubeSvc
          ) {
            AdminReviewSectionStepDeterminationSessionService.clearCurrentReviewLevel();
            if ($stateParams.year != null && $stateParams.cfcCode != null) {
              var year = $stateParams.year;
              var cfcCode = $stateParams.cfcCode;
              var url = null;
              if ($stateParams.fedAppId != null) {
                var fedAppId = $stateParams.fedAppId;
                url =
                  '/CfcCharity/api/private/users/federation/application/' +
                  year +
                  '/' +
                  cfcCode +
                  '/' +
                  fedAppId;
              } else {
                url =
                  '/CfcCharity/api/private/users/federation/application/' +
                  year +
                  '/' +
                  cfcCode;
              }
              return CubeSvc.get(url).then(function(res) {
                return res.data.value;
              });
            }
          }
        }
      });
      $stateProvider.state('memberapplication', {
        url: '/memberapplication',
        views: {
          main: {
            template: require('../views/private/federation/memberapplicationdashboard.html'),
            controller: 'MemberApplicationCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '%pledgeYear% #%federationCfc% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
            }
          ],
          pageTitle: 'Member Applications',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          cfcCode: null
        },
        resolve: {
          memberApplicationData: function($http, $stateParams, CubeSvc) {
            if ($stateParams.year != null && $stateParams.cfcCode != null) {
              var year = $stateParams.year;
              var cfcCode = $stateParams.cfcCode;
              var url =
                '/CfcCharity/api/private/users/federation/memberapplications/' +
                year +
                '/' +
                cfcCode +
                '?page=' +
                0;
              return CubeSvc.get(url).then(function(res) {
                return res.data.value;
              });
            }
          },
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            FederationApplicationService,
            CubeSvc
          ) {
            var app;
            url =
              '/CfcCharity/api/private/federationapplication/' +
              $stateParams.year +
              '/' +
              $stateParams.cfcCode;
            return CubeSvc.get(url).then(function(res) {
              app = res.data.value;
              if (app != null) {
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  app.federationId
                );
              }
            });
          }
        }
      });
      $stateProvider.state('deletememberapplicationconfirmation', {
        url: '/deletememberapplicationconfirmation',
        views: {
          main: {
            template: require('../views/private/federation/deletememberapplicationconfirmation.html'),
            controller: 'DeleteMemberApplicationConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Delete Member Application Confirmation',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          federationName: null,
          memberCharityName: null,
          federationApplicationCfcCode: null,
          memberApplicationCfcCode: null,
          memberApplicationStatus: null,
          statusCode: null,
          errorCode: null,
          errorMessage: null
        },
        resolve: {
          deleteMemberApplicationData: function($stateParams) {
            var memberAppData = null;
            if (
              $stateParams.year != null &&
              $stateParams.federationName != null &&
              $stateParams.memberCharityName != null &&
              $stateParams.federationApplicationCfcCode != null &&
              $stateParams.memberApplicationCfcCode != null &&
              $stateParams.memberApplicationStatus != null
            ) {
              var year = $stateParams.year;
              var federationName = $stateParams.federationName;
              var memberCharityName = $stateParams.memberCharityName;
              var federationApplicationCfcCode =
                $stateParams.federationApplicationCfcCode;
              var memberApplicationCfcCode =
                $stateParams.memberApplicationCfcCode;
              var memberApplicationStatus =
                $stateParams.memberApplicationStatus;
              if (memberApplicationStatus.toLowerCase() === 'in progress') {
                memberApplicationStatus = 'IN_PROGRESS';
              }
              var statusCode = $stateParams.statusCode;
              var errorCode = $stateParams.errorCode;
              var errorMessage = $stateParams.errorMessage;
              memberAppData = {
                year: year,
                federationName: federationName,
                memberCharityName: memberCharityName,
                federationApplicationCfcCode: federationApplicationCfcCode,
                memberApplicationCfcCode: memberApplicationCfcCode,
                memberApplicationStatus: memberApplicationStatus,
                statusCode: statusCode,
                errorCode: errorCode,
                errorMessage: errorMessage
              };
            }
            return memberAppData;
          }
        }
      });
      $stateProvider.state('deletememberapplication', {
        url: '/deletememberapplication',
        views: {
          main: {
            template: require('../views/private/federation/memberapplicationdashboard.html'),
            controller: 'MemberApplicationCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '%pledgeYear% #%federationCfc% Federation Package',
              crumbRoute: 'federationapplication',
              crumbParams:
                '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
            }
          ],
          pageTitle: 'Delete Member Application',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          federationName: null,
          memberCharityName: null,
          federationApplicationCfcCode: null,
          memberApplicationCfcCode: null,
          memberApplicationStatus: null
        },
        resolve: {
          memberApplicationData: function($stateParams, $state, CubeSvc) {
            if (
              $stateParams.year != null &&
              $stateParams.federationApplicationCfcCode != null &&
              $stateParams.memberApplicationCfcCode != null &&
              $stateParams.memberApplicationStatus != null
            ) {
              var year = $stateParams.year;
              var federationApplicationCfcCode =
                $stateParams.federationApplicationCfcCode;
              var memberApplicationCfcCode =
                $stateParams.memberApplicationCfcCode;
              var memberApplicationStatus =
                $stateParams.memberApplicationStatus;
              var url =
                '/CfcCharity/api/private/users/federation/memberapplication/delete/' +
                year +
                '/' +
                federationApplicationCfcCode +
                '/' +
                memberApplicationCfcCode +
                '/' +
                memberApplicationStatus;
              return CubeSvc.get(url, null, true)
                .then(function(res) {
                  var memberAppData = res.data.value;
                  if (
                    memberAppData.error === true ||
                    memberAppData.statusCode === 'ERROR'
                  ) {
                    $state.go('deletememberapplicationconfirmation', {
                      year: year,
                      federationName: $stateParams.federationName,
                      memberCharityName: $stateParams.memberCharityName,
                      federationApplicationCfcCode: federationApplicationCfcCode,
                      memberApplicationCfcCode: memberApplicationCfcCode,
                      memberApplicationStatus: memberApplicationStatus,
                      statusCode: memberAppData.statusCode,
                      errorCode: memberAppData.errorResponse.errorCode,
                      errorMessage: memberAppData.errorResponse.errorMessage
                    });
                  }
                  // end if
                  return res.data.value;
                }) // end then
                .catch(function(res) {
                  var errorMessage =
                    'An Error occurred during transfer, Please Contact System Administrator.';

                  if (res.data.errorCode === 403) {
                    errorMessage = 'Access is denied';
                  }

                  $state.go('deletememberapplicationconfirmation', {
                    year: year,
                    federationName: $stateParams.federationName,
                    memberCharityName: $stateParams.memberCharityName,
                    federationApplicationCfcCode: federationApplicationCfcCode,
                    memberApplicationCfcCode: memberApplicationCfcCode,
                    statusCode: res.statusCode,
                    errorCode: res.data.errorCode,
                    errorMessage: errorMessage
                  });
                }); // end catch
            }
          },
          tokenData: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            FederationApplicationService
          ) {
            BreadcrumbTokenDataService.clearTokenData();
            var appId =
              $stateParams.federationId ||
              FederationApplicationService.getApplicationId();
            if (appId != null) {
              return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                appId
              );
            }
          }
        }
      });
      $stateProvider.state('withdrawfederationapplicationconfirmation', {
        url: '/withdrawfederationapplicationconfirmation',
        views: {
          main: {
            template: require('../views/private/federation/withdrawfederationapplicationconfirmation.html'),
            controller: 'WithdrawFederationApplicationConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Withdraw Federation Application',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          federationName: null,
          federationApplicationCfcCode: null,
          federationApplicationId: null,
          statusCode: null,
          errorCode: null,
          errorMessage: null
        },
        resolve: {
          withdrawFederationApplicationData: function($stateParams) {
            var withdrawFederationAppData = null;
            if (
              $stateParams.year != null &&
              $stateParams.federationName != null &&
              $stateParams.federationApplicationCfcCode != null &&
              $stateParams.federationApplicationId != null
            ) {
              var year = $stateParams.year;
              var federationName = $stateParams.federationName;
              var federationApplicationCfcCode =
                $stateParams.federationApplicationCfcCode;
              var federationApplicationId =
                $stateParams.federationApplicationId;
              withdrawFederationAppData = {
                year: year,
                federationName: federationName,
                federationApplicationCfcCode: federationApplicationCfcCode,
                federationApplicationId: federationApplicationId,
                statusCode: $stateParams.statusCode,
                errorCode: $stateParams.errorCode,
                errorMessage: $stateParams.errorMessage
              };
            }
            return withdrawFederationAppData;
          }
        }
      });
      $stateProvider.state('withdrawfederationapplication', {
        url: '/withdrawfederationapplication',
        views: {
          main: {
            template: require('../views/private/charity/userdashboard.html'),
            controller: 'UserDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Charities',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          federationName: null,
          federationApplicationCfcCode: null,
          federationApplicationId: null
        },
        resolve: {
          userDashboardData: function(CubeSvc, $stateParams, $state) {
            if (
              $stateParams.year != null &&
              $stateParams.federationApplicationCfcCode != null &&
              $stateParams.federationApplicationId != null
            ) {
              var year = $stateParams.year;
              var federationApplicationCfcCode =
                $stateParams.federationApplicationCfcCode;
              var federationApplicationId =
                $stateParams.federationApplicationId;
              var url =
                '/CfcCharity/api/private/users/federation/withdraw?federationApplicationId=' +
                federationApplicationId;
              return CubeSvc.get(url).then(function(res) {
                var userDBData = res.data.value;
                if (
                  userDBData.error === true ||
                  userDBData.statusCode === 'ERROR'
                ) {
                  $state.go('withdrawfederationapplicationconfirmation', {
                    year: year,
                    federationName: $stateParams.federationName,
                    federationApplicationCfcCode: federationApplicationCfcCode,
                    federationApplicationId: federationApplicationId,
                    statusCode: userDBData.statusCode,
                    errorCode: userDBData.errorResponse.errorCode,
                    errorMessage: userDBData.errorResponse.errorMessage
                  });
                }
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider.state('disbandfederationapplicationconfirmation', {
        url: '/disbandfederationapplicationconfirmation',
        views: {
          main: {
            template: require('../views/private/federation/disbandfederationapplicationconfirmation.html'),
            controller: 'DisbandFederationApplicationConfirmationCtrl'
          }
        },
        data: {
          pageTitle: 'Disband Federation Application',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          federationName: null,
          federationApplicationCfcCode: null,
          federationApplicationId: null,
          statusCode: null,
          errorCode: null,
          errorMessage: null
        },
        resolve: {
          disbandFederationApplicationData: function($stateParams) {
            var disbandFederationAppData = null;
            if (
              $stateParams.year != null &&
              $stateParams.federationName != null &&
              $stateParams.federationApplicationCfcCode != null &&
              $stateParams.federationApplicationId != null
            ) {
              var year = $stateParams.year;
              var federationName = $stateParams.federationName;
              var federationApplicationCfcCode =
                $stateParams.federationApplicationCfcCode;
              var federationApplicationId =
                $stateParams.federationApplicationId;
              disbandFederationAppData = {
                year: year,
                federationName: federationName,
                federationApplicationCfcCode: federationApplicationCfcCode,
                federationApplicationId: federationApplicationId,
                statusCode: $stateParams.statusCode,
                errorCode: $stateParams.errorCode,
                errorMessage: $stateParams.errorMessage
              };
            }
            return disbandFederationAppData;
          }
        }
      });
      $stateProvider.state('disbandfederationapplication', {
        url: '/disbandfederationapplication',
        views: {
          main: {
            template: require('../views/private/charity/userdashboard.html'),
            controller: 'UserDashboardCtrl'
          }
        },
        data: {
          pageTitle: 'CFC Charities',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          year: null,
          federationName: null,
          federationApplicationCfcCode: null,
          federationApplicationId: null
        },
        resolve: {
          userDashboardData: function(CubeSvc, $stateParams, $state) {
            if (
              $stateParams.year != null &&
              $stateParams.federationApplicationCfcCode != null &&
              $stateParams.federationApplicationId != null
            ) {
              var year = $stateParams.year;
              var federationApplicationCfcCode =
                $stateParams.federationApplicationCfcCode;
              var federationApplicationId =
                $stateParams.federationApplicationId;
              var url =
                '/CfcCharity/api/private/users/federation/disband?federationApplicationId=' +
                federationApplicationId;
              return CubeSvc.get(url).then(function(res) {
                var userDBData = res.data.value;
                if (
                  userDBData.error === true ||
                  userDBData.statusCode === 'ERROR'
                ) {
                  $state.go('disbandfederationapplicationconfirmation', {
                    year: year,
                    federationName: $stateParams.federationName,
                    federationApplicationCfcCode: federationApplicationCfcCode,
                    federationApplicationId: federationApplicationId,
                    statusCode: userDBData.statusCode,
                    errorCode: userDBData.errorResponse.errorCode,
                    errorMessage: userDBData.errorResponse.errorMessage
                  });
                }
                return res.data;
              });
            }
          }
        }
      });
      $stateProvider
        .state('federationDashboard', {
          url: '/federationDashboard',
          views: {
            main: {
              template: require('../views/private/federation/federationDashboard.html'),
              controller: 'FederationDashboardCtrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: '%pledgeYear% #%cfcCode% Federation Package',
                crumbRoute: 'federationapplication',
                crumbParams:
                  '{year: %pledgeYear%, cfcCode: %cfcCode%, fedAppId: %applicationId%}'
              }
            ],
            pageTitle: 'Federation Dashboard',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            federationId: null,
            year: null,
            cfcCode: null
          },
          resolve: {
            federationDetailsData: function(
              $http,
              $stateParams,
              CubeSvc,
              AdminReviewSectionStepDeterminationSessionService
            ) {
              CubeSvc.setManualCacheRelease();
              var federationId = null;
              var url = null;
              AdminReviewSectionStepDeterminationSessionService.clearCurrentReviewLevel();
              if ($stateParams.federationId != null) {
                federationId = $stateParams.federationId;
                url =
                  '/CfcCharity/api/private/federation/dashboard/' +
                  federationId;
                return CubeSvc.get(url).then(function(res) {
                  return res.data.value;
                });
              } else if (
                $stateParams.federationId === null &&
                $stateParams.year &&
                $stateParams.cfcCode
              ) {
                url =
                  '/CfcCharity/api/private/federationapplication/' +
                  $stateParams.year +
                  '/' +
                  $stateParams.cfcCode;
                return CubeSvc.get(url).then(function(res) {
                  return res.data.value;
                });
              }
            },
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              FederationApplicationService
            ) {
              BreadcrumbTokenDataService.clearTokenData();
              var appId =
                $stateParams.federationId ||
                FederationApplicationService.getApplicationId();
              if (appId != null) {
                return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                  appId
                );
              }
            }
          }
        })
        .state('signAndUnsignFederation', {
          url: '/signAndUnsignFederation',
          views: {
            main: {
              template: require('../views/private/federation/signAndunsignFederation.html'),
              controller: 'FederationSignCtrl'
            }
          },
          data: {
            pageTitle: 'Unsign Application',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            applicationId: null,
            pledgeYear: null
          }
        })
        .state('viewSignatureFederation', {
          url: '/viewSignatureFederation',
          views: {
            main: {
              template: require('../views/private/federation/viewSignatureFederation.html'),
              controller: 'FederationSignCtrl'
            }
          },
          data: {
            pageTitle: 'View Signature',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            applicationId: null,
            pledgeYear: null
          }
        });
    }
  ])
  .service('DashboardService', [
    '$http',
    function($http) {
      this.acceptedInvitation = function(inviteUser) {
        return $http.post(
          '/CfcCharity/api/private/charity/acceptInvitation',
          inviteUser
        );
      };
      this.declinedInvitation = function(inviteUser) {
        return $http.post(
          '/CfcCharity/api/private/charity/declineInvitation',
          inviteUser
        );
      };
      this.setEnrollmentAvailable = function(isEnrollmentAvailable) {
        this.isEnrollmentAvailable = isEnrollmentAvailable;
      };
      this.getEnrollmentAvailable = function() {
        return this.isEnrollmentAvailable;
      };
      this.setListingAvailable = function(isListingAvailable) {
        this.isListingAvailable = isListingAvailable;
      };
      this.getListingAvailable = function() {
        return this.isListingAvailable;
      };
    }
  ])
  .service('FederationDashboardService', [
    '$http',
    'CubeSvc',
    function($http, CubeSvc) {
      this.findCharityFederationSessions = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/charity/federation/session?applicationId=' +
            applicationId
        );
      };
      this.findCustomFederationApplication = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/federation_application/custom/' +
            applicationId,
          null,
          true
        );
      };
      this.findFederationApplication3 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/federation_application/step3/custom/' +
            applicationId,
          null,
          false
        );
      };
      this.findFederationApplication4 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/federation_application/step4/custom/' +
            applicationId,
          null,
          false
        );
      };
      this.findFederationApplication6 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/federation_application/step6/custom/' +
            applicationId,
          null,
          false
        );
      };
      this.findFederationApplication7 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/federation_application/step7/custom/' +
            applicationId,
          null,
          false
        );
      };
      this.findFederationApplication9 = function(applicationId) {
        return CubeSvc.get(
          '/CfcCharity/api/private/federation_application/step9/custom/' +
            applicationId,
          null,
          false
        );
      };
      this.updateFederationApplication = function(
        applicationId,
        federationApplication
      ) {
        return CubeSvc.put(
          '/CfcCharity/api/private/federation_application/' + applicationId,
          federationApplication
        );
      };
      this.getFederationApplication = function(applicationId) {
        var url =
          '/CfcCharity/api/private/federation_application/' + applicationId;
        return CubeSvc.get(url, null, false);
      };
      this.getAllApplicationRFIs = function(applicationId) {
        var url =
          '/CfcCharity/api/private/federation/review/' + applicationId + '/all';
        return $http.get(url);
      };
      this.getApplicationSignatureHistory = function(applicationId) {
        var url =
          '/CfcCharity/api/private/application/signatureHistory/' +
          applicationId;
        return CubeSvc.get(url, { params: { isFederation: true } }, false);
      };
    }
  ])
  .controller('FederationApplicationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'federationApplicationData',
    'CharityService',
    'BrowserEventService',
    '$http',
    'BreadcrumbTokenDataService',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      federationApplicationData,
      CharityService,
      BrowserEventService,
      $http,
      BreadcrumbTokenDataService,
      CubeSvc
    ) {
      $scope.setModuleName('CfcCharity.federationDashboard');
      $scope.disablePayPackageFeeButton = true;
      CubeSvc.performManualCacheRelease();
      if (
        federationApplicationData != null &&
        federationApplicationData !== 'undefined'
      ) {
        $scope.federationApplicationData = federationApplicationData;
        BrowserEventService.setCfcCode(
          $scope.federationApplicationData.cfcCode
        );
        BrowserEventService.setFederationId(
          $scope.federationApplicationData.federationId
        );
      }
      var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));

      if (!$scope.federationApplicationData.hasAccessToFedApp) {
        $state.go('accessDenied', {
          userId: userObj.id
        });
      }

      function setBreadcrumb(selectedYear, cfcCode) {
        url =
          '/CfcCharity/api/private/federationapplication/' +
          selectedYear +
          '/' +
          cfcCode;
        return CubeSvc.get(url, null, false);
      }
      function setBrTokenData(fedId) {
        return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
          fedId
        );
      }
      $scope.setWithdrawFederationApplicationData = function(
        userId,
        year,
        federationName,
        federationApplicationCfcCode,
        federationApplicationId
      ) {
        $scope.federationApplicationUserId = userId;
        $scope.federationApplicationYear = year;
        $scope.federationName = federationName;
        $scope.federationApplicationCfcCode = federationApplicationCfcCode;
        $scope.federationApplicationId = federationApplicationId;
        $state.go('withdrawfederationapplicationconfirmation', {
          year: year,
          federationName: federationName,
          federationApplicationCfcCode: federationApplicationCfcCode,
          federationApplicationId: federationApplicationId,
          errorCode: null,
          errorMessage: null
        });
      };
      $scope.setDisbandFederationApplicationData = function(
        userId,
        year,
        federationName,
        federationApplicationCfcCode,
        federationApplicationId
      ) {
        $scope.federationApplicationUserId = userId;
        $scope.federationApplicationYear = year;
        $scope.federationName = federationName;
        $scope.federationApplicationCfcCode = federationApplicationCfcCode;
        $scope.federationApplicationId = federationApplicationId;
        $state.go('disbandfederationapplicationconfirmation', {
          year: year,
          federationName: federationName,
          federationApplicationCfcCode: federationApplicationCfcCode,
          federationApplicationId: federationApplicationId
        });
      };
      $('.modal-backdrop').remove();

      $scope.withdrawnApplicationPackage = function() {
        if (!$scope.federationApplicationData) return;
        if (
          federationApplicationData.userSecondaryRoleList.indexOf(
            'OPM_ADMIN'
          ) >= 0
        ) {
          $scope.setWithdrawFederationApplicationData(
            $scope.federationApplicationData.userId,
            $scope.federationApplicationData.year,
            $scope.federationApplicationData.federationName,
            $scope.federationApplicationData.cfcCode,
            $scope.federationApplicationData.federationId
          );
        } else {
          $('#withdrawApplicationPackageModal').modal('show');
        }
      };

      $scope.disbandApplicationPackage = function() {
        if (!$scope.federationApplicationData) return;
        if (
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'OPM_ADMIN'
          ) >= 0
        ) {
          $scope.setDisbandFederationApplicationData(
            $scope.federationApplicationData.userId,
            $scope.federationApplicationData.year,
            $scope.federationApplicationData.federationName,
            $scope.federationApplicationData.cfcCode,
            $scope.federationApplicationData.federationId
          );
        } else {
          $('#disbandApplicationPackageModal').modal('show');
        }
      };

      function configurePayPackageFeeButtonForCharityUsers() {
        if (
          !$scope.federationApplicationData ||
          !$scope.federationApplicationData.userSecondaryRoleList
        ) {
          return;
        }

        if (
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'GB_FINANCE_ADMIN'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'CUSTOMER_SERVICE_MANAGER'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'CUSTOMER_SERVICE_TIER_2_FINANCE'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'OPM_AUDITOR'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'FEDERATION_OWNER'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'FEDERATION_EDITOR'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'CHARITY_OWNER'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'CHARITY_EDITOR'
          ) < 0
        ) {
          return;
        }

        var federationApplicationSignedStatus =
          $scope.federationApplicationData.federationApplicationSignedStatus;
        var totalMemberApplications =
          $scope.federationApplicationData.totalMemberApplications;
        var allMemberApplicationSignedStatus =
          $scope.federationApplicationData.allMemberApplicationSignedStatus;
        var feePaidSuccess = $scope.federationApplicationData.feePaidSuccess;
        var achPaymentDone = $scope.federationApplicationData.achPaymentDone;
        var federationApplicationStatus =
          $scope.federationApplicationData.federationApplicationStatus;

        if (federationApplicationStatus === 'WITHDRAWN') {
          return;
        }
        if (feePaidSuccess) {
          return;
        }
        if (achPaymentDone) {
          return;
        }

        if (!federationApplicationSignedStatus) {
          return;
        }

        if (!allMemberApplicationSignedStatus && totalMemberApplications > 0) {
          return;
        }

        $http
          .get('/CfcCharity/api/private/charity/getCampaignStatus')
          .then(function(resp) {
            if (resp.data) {
              // application period is open
              $scope.disablePayPackageFeeButton = false;
            }
          });
      }

      function configurePayPackageFeeButtonForCharityAdmins() {
        if ($scope.disablePayPackageFeeButton === false) {
          return;
        }

        if (!$scope.federationApplicationData) {
          return;
        }

        if (
          $scope.federationApplicationData.userSecondaryRoleList != null &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'OPM_ADMIN'
          ) < 0 &&
          $scope.federationApplicationData.userSecondaryRoleList.indexOf(
            'GB_FINANCE_ADMIN'
          ) < 0
        ) {
          // not OPM Admin or GB Finance Admin
          return;
        }

        var federationApplicationSignedStatus =
          $scope.federationApplicationData.federationApplicationSignedStatus;
        var totalMemberApplications =
          $scope.federationApplicationData.totalMemberApplications;
        var allMemberApplicationSignedStatus =
          $scope.federationApplicationData.allMemberApplicationSignedStatus;
        var feePaidSuccess = $scope.federationApplicationData.feePaidSuccess;
        var achPaymentDone = $scope.federationApplicationData.achPaymentDone;
        var federationApplicationStatus =
          $scope.federationApplicationData.federationApplicationStatus;

        if (!federationApplicationSignedStatus) {
          return;
        }

        if (feePaidSuccess === true || achPaymentDone === true) {
          return;
        }

        if (
          totalMemberApplications === 0 &&
          federationApplicationStatus !== 'SUBMITTED'
        ) {
          return;
        }

        if (totalMemberApplications > 0 && !allMemberApplicationSignedStatus) {
          return;
        }

        $http
          .get('/CfcCharity/api/private/charity/getCampaignStatus')
          .then(function(resp) {
            if (!resp.data) {
              // Application period is closed
              $scope.disablePayPackageFeeButton = false;
            }
          });
      }

      angular.element(function() {
        configurePayPackageFeeButtonForCharityUsers();
        configurePayPackageFeeButtonForCharityAdmins();
      });
    }
  ])
  .controller('FederationDashboardCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$log',
    'CubeSvc',
    '$http',
    'federationDetailsData',
    'CharityService',
    'FederationDashboardService',
    'ApplicationDashboardService',
    'FederationApplicationService',
    'BrowserEventService',
    'AdministrationCenterService',
    'ZoneCenterService',
    'BreadcrumbTokenDataService',
    'tokenData',
    'urlAccess',
    'PLEDGE_YEARS',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $log,
      CubeSvc,
      $http,
      federationDetailsData,
      CharityService,
      FederationDashboardService,
      ApplicationDashboardService,
      FederationApplicationService,
      BrowserEventService,
      AdministrationCenterService,
      ZoneCenterService,
      BreadcrumbTokenDataService,
      tokenData,
      urlAccess,
      PLEDGE_YEARS
    ) {
      $scope.cfcCode = $state.params.cfcCode;
      $scope.setModuleName('CfcCharity.federationDashboard');
      var federationDashboardData = {};
      $scope.selectedGroupNumber = '';
      $scope.applicationType;
      federationDashboardData.fedAppDashboardDetails = federationDetailsData;
      federationDashboardData.federationDetails =
        federationDetailsData.application;
      $scope.displayAppealsArea = false;
      if (!federationDashboardData.federationDetails.listingPeriodNotStarted) {
        $scope.displayAppealsArea = true;
      }
      var currentDate = FederationApplicationService.toUTCDate(new Date());
      $scope.currentDate = currentDate;
      $scope.currentFileVersion = 1;

      $scope.showPdfBtn = false;
      if (
        federationDetailsData.application.applicationPaid &&
        !federationDetailsData.campaignAvailable
      ) {
        $scope.showPdfBtn = true;
      }

      console.log(federationDetailsData);
      $scope.irsHistory_latest_upload_link = null;
      if (
        federationDetailsData.form990UploadHistory.length > 0 &&
        federationDetailsData.form990UploadHistory[0].s3Link != null
      ) {
        $scope.irsHistory_latest_upload_link =
          federationDetailsData.form990UploadHistory[0].s3Link;
      }
      CubeSvc.performManualCacheRelease();
      if (
        federationDashboardData.fedAppDashboardDetails.form990UploadHistory
          .length > 0
      ) {
        if (
          federationDashboardData.fedAppDashboardDetails.form990UploadHistory[0]
            .changedFileName != null &&
          federationDashboardData.fedAppDashboardDetails.form990UploadHistory[0]
            .changedFileName != undefined &&
          federationDashboardData.fedAppDashboardDetails.form990UploadHistory[0]
            .changedFileName != ''
        ) {
          var us = federationDashboardData.fedAppDashboardDetails.form990UploadHistory[0].changedFileName.lastIndexOf(
            '_'
          );
          $scope.currentFileVersion = parseInt(
            federationDashboardData.fedAppDashboardDetails.form990UploadHistory[0].changedFileName
              .substring(
                us,
                federationDashboardData.fedAppDashboardDetails
                  .form990UploadHistory[0].length
              )
              .replace('_ver', '')
              .replace(/\.[^\.]+$/, '')
          );
        }
      }
      $scope.openFilesModal = function() {
        if (!$scope.displayForPriorYears) {
          var url =
            '/CfcCharity/api/private/federation_application/step7/uploadedfiles/' +
            $scope.applicationId;
          CubeSvc.get(url, null, false).then(function(res) {
            $scope.uploadedFileData = res.data.value;
            $scope.irsHistory = $scope.uploadedFileData.irsHistory;
            $scope.eSignHistory = $scope.uploadedFileData.eSignHistory;
          });
        }
        $('#allFiles').modal();
      };

      $scope.displayForPriorYears = false;
      if (
        tokenData.pledgeYear &&
        tokenData.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }
      //check the access to show the breadcrumb links for member applications
      if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
        urlAccess
          .getFederationApplicationAccess(
            tokenData.pledgeYear,
            tokenData.federationCfc,
            $scope.$parent.$$childHead.breadcrumbs[0]
          )
          .then(function(res) {
            $scope.$parent.$$childHead.breadcrumbs[0] = res;
          });
      }
      var data = federationDashboardData.fedAppDashboardDetails.preAppDetails;
      $scope.applicationType = data.applicationType;
      $scope.campaignId = data.campaignId;
      BrowserEventService.setCampaignId(data.campaignId);
      if (!$scope.cfcCode) {
        $scope.cfcCode = data.cfcCode;
      }
      FederationApplicationService.setPreApplicationDetails(data);
      if (
        federationDashboardData &&
        federationDashboardData.federationDetails &&
        federationDashboardData.federationDetails.federationName &&
        federationDashboardData.federationDetails.cfcCode
      ) {
        var federationZone =
          federationDashboardData.federationDetails.federationZone;
        if (
          federationZone &&
          null != federationZone.zoneCode &&
          null != federationZone.zoneName
        ) {
          $scope.assignedZoneName =
            federationZone.zoneCode + ', ' + federationZone.zoneName;
        }
        var listingZoneList =
          federationDashboardData.federationDetails.listingZoneList;
        if (listingZoneList && listingZoneList.length > 0) {
          $scope.listingZoneName =
            listingZoneList.length === 1
              ? listingZoneList[0].zoneCode + ', ' + listingZoneList[0].zoneName
              : 'Multiple';
        }
        federationDashboardData.federationAuthority = true;
        $scope.disableApplicationReviewButton = function() {
          return !$scope.showZoneReviewData();
        };
        $scope.appStatus =
          federationDashboardData.federationDetails.applicationStatus;
        if (federationDashboardData.federationDetails.listingPeriodNotStarted) {
          if ($scope.appStatus === 'DENIED') {
            $scope.appStatus = 'IN_REVIEW';
          }
        }
        if (
          federationDashboardData.federationDetails.applicationPeriodNotEnded
        ) {
          if ($scope.appStatus === 'CLOSED') {
            $scope.appStatus = 'IN_PROGRESS';
          }
        }
        $scope.selectedGroupNumber =
          federationDashboardData.federationDetails.reviewGroup;
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        $scope.user = userObj;
        $scope.userId = userObj.id;
        $scope.showAdminOnlyBlock = false;
        $scope.showEditGroupNumber = false;

        if ($scope.isAdminUser() || $scope.isAdminAndCharityUser()) {
          AdministrationCenterService.getSystemUserRole($scope.userId).then(
            function(res) {
              var role = res.data;
              if (role !== null && role !== '') {
                if (role.firstLevelRoleList.indexOf('ROLE_SYSTEM_USER') >= 0) {
                  if (
                    role.secondLevelRoleList.indexOf('OPM_ADMIN') >= 0 ||
                    role.secondLevelRoleList.indexOf('OPM_STAFF') >= 0 ||
                    role.secondLevelRoleList.indexOf('CUSTOMER_SERVICE') >= 0 ||
                    role.secondLevelRoleList.indexOf('OPM_AUDITOR') >= 0
                  ) {
                    $scope.showAdminOnlyBlock = true;
                  }
                  if (
                    role.secondLevelRoleList.indexOf('OPM_ADMIN') >= 0 ||
                    role.secondLevelRoleList.indexOf('OPM_STAFF') >= 0
                  ) {
                    $scope.showEditGroupNumber = true;
                  }
                }
              }
            }
          );
        }
        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        $scope.secondaryRoleList = secondaryRoleList;
        $scope.isZoneReviewerOrManager = false;

        $scope.showZoneReviewData = function() {
          if (
            $scope.appStatus === 'DISBANDED' ||
            $scope.appStatus === 'WITHDRAWN'
          ) {
            return false;
          } else {
            if ($scope.isZoneReviewerOrManager) {
              return federationDashboardData.federationDetails.federationZone
                .zoneReviewOpen;
            } else {
              return true;
            }
          }
        };

        // checks for additional role access of Zone Manager / Reviewer
        if (
          secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('REVIEWER') >= 0
        ) {
          $scope.isZoneReviewerOrManager = true;
          ZoneCenterService.findCharityAccessEligibility(
            userObj.id,
            federationDashboardData.federationDetails.cfcCode
          ).then(function(res) {
            var isAccessEligible = res.data;
            if (isAccessEligible) {
              $scope.showAdminOnlyBlock = true;
            }
          });
        }
        federationDashboardData.userId = userObj.id;

        var enrollmentStatus =
          federationDashboardData.fedAppDashboardDetails.campaignAvailable;
        federationDashboardData.isEnrollmentAvailable = enrollmentStatus;
        $scope.mode = 'VIEW';
        if (
          federationDashboardData &&
          federationDashboardData.federationDetails &&
          federationDashboardData.federationDetails.applicationStatus ===
            'IN_PROGRESS' &&
          federationDashboardData.isEnrollmentAvailable
        ) {
          $scope.mode = 'EDIT';
        } // end if

        CharityService.getCharityUserRole(
          federationDetailsData.application.cfcCode
        ).then(function(roleResp) {
          var userRole = roleResp.data;
          if (userRole) {
            if (userRole !== null && userRole.userRole === 'CHARITY_OWNER') {
              federationDashboardData.isUsersButtonVisible = true;
            }
          } else {
            federationDashboardData.federationAuthority = false;
          }
        });
        if (
          federationDetailsData.application.applicationChangeEndDate &&
          currentDate >
            federationDetailsData.application.applicationChangeEndDate &&
          !federationDetailsData.application.startedAfterEnrollmentEnd
        ) {
          $scope.isApplicationChangeDateEnded = true;
        } else {
          $scope.isApplicationChangeDateEnded = false;
        }
        var applicationId = federationDetailsData.application.federationId;
        $scope.applicationId = applicationId;
        BreadcrumbTokenDataService.setIsFederation(true);
        BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
          applicationId
        );

        var federation_step6_url =
          '/CfcCharity/api/private/federation_application/step6/' +
          $scope.applicationId;
        $http
          .get(federation_step6_url)
          .then(function(res) {
            $scope.federation_audited_financial_s3_link = res.data.value
              ? res.data.value.s3Link
              : null;
          })
          .catch(function(error) {
            console.log('Error: ', error);
          });

        var federation_step4_url =
          '/CfcCharity/api/private/federation_application/step4/' +
          $scope.applicationId;
        $http
          .get(federation_step4_url)
          .then(function(res) {
            $scope.federation_ir_determination_s3_link = res.data.value
              ? res.data.value.s3Link
              : null;
          })
          .catch(function(error) {
            console.log('Error: ', error);
          });

        var federation_step9_url =
          '/CfcCharity/api/private/federation_application/step9/' +
          $scope.applicationId;

        $http
          .get(federation_step9_url)
          .then(
            response =>
              ($scope.governing_body_s3_link = response.data.value
                ? response.data.value.s3Link
                : null)
          )
          .catch(error => console.log('Error: ', error));

        var federation_step3_url =
          '/CfcCharity/api/private/federation_application/step3/' +
          $scope.applicationId;

        $http
          .get(federation_step3_url)
          .then(response => {
            $scope.member_list_s3_link = response.data.value
              ? response.data.value.s3Link
              : null;
          })
          .catch(error => console.log('Error: ', error));

        var applicationSessions =
          federationDashboardData.fedAppDashboardDetails.appSession;
        var totalSec = 0;
        for (var i = 0; i < applicationSessions.length; i++) {
          var appSession = applicationSessions[i];
          totalSec += appSession.step1TimeSpentInSeconds || 0;
          totalSec += appSession.step2TimeSpentInSeconds || 0;
          totalSec += appSession.step3TimeSpentInSeconds || 0;
          totalSec += appSession.step4TimeSpentInSeconds || 0;
          totalSec += appSession.step5TimeSpentInSeconds || 0;
          totalSec += appSession.step6TimeSpentInSeconds || 0;
          totalSec += appSession.step7TimeSpentInSeconds || 0;
          totalSec += appSession.step8TimeSpentInSeconds || 0;
          totalSec += appSession.step9TimeSpentInSeconds || 0;
          totalSec += appSession.step10TimeSpentInSeconds || 0;
        }
        var radix = 10;
        var hours = parseInt(totalSec / 3600, radix);
        var minutes = parseInt(totalSec / 60, radix) % 60;
        var seconds = totalSec % 60;
        var totalTimeSpent =
          (hours < 10 ? '0' + hours : hours) +
          ':' +
          (minutes < 10 ? '0' + minutes : minutes) +
          ':' +
          (seconds < 10 ? '0' + seconds : seconds);
        federationDashboardData.totalTimeSpent = totalTimeSpent;
        var result =
          federationDashboardData.fedAppDashboardDetails
            .charityApplicationCustom;

        var step3Data = { status: 'Missing' };
        var step4Data = { status: 'Missing' };
        var step6Data = { status: 'Missing' };
        var step7Data = { status: 'Missing' };
        var step9Data = { status: 'Missing' };

        $scope.displayPriorTo2023 = false;

        if (result) {
          //step 3
          if (
            tokenData.pledgeYear &&
            tokenData.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2023
          ) {
            $scope.displayPriorTo2023 = true;
            if (
              result.step3Status === 'COMPLETE' &&
              result.step3fileName === null
            ) {
              step3Data.status = 'NA';
            } else if (result.step3fileName !== null) {
              step3Data.status = 'Received';
            }
          }

          //step 4
          if (
            result.step4Status === 'COMPLETE' &&
            !result.step4certifyIrsDetermination
          ) {
            step4Data.status = 'NA';
          } else if (
            ((result.step4Status === 'COMPLETE' &&
              result.step4certifyIrsDetermination) ||
              result.step4Status === 'INCOMPLETE') &&
            result.step4fileName !== null
          ) {
            step4Data.status = 'Received';
          }

          //step 6
          if (result.step6gaapOption === 'OPTION_2') {
            step6Data.status = 'NA';
          } else if (
            result.step6gaapOption === 'OPTION_1' &&
            result.step6StepStatus &&
            result.step6fileName
          ) {
            step6Data.status = 'Received';
          }

          //step 7
          step7Data.status = 'Missing';
          if (result.step7fileName !== null) {
            step7Data.status = 'Received';
          }

          //step 9
          step9Data.isAvailable = true;
          if (
            result.step9Status === 'COMPLETE' &&
            result.step9fileName === null
          ) {
            step9Data.status = 'NA';
          } else if (result.step9fileName !== null) {
            step9Data.status = 'Received';
          }
        }

        federationDashboardData.step3Data = step3Data;
        federationDashboardData.step4Data = step4Data;
        federationDashboardData.step6Data = step6Data;
        federationDashboardData.step7Data = step7Data;
        federationDashboardData.step9Data = step9Data;

        $scope.applicationStatus =
          federationDashboardData.fedAppDashboardDetails.appStatus;
      }
      // end if federationDashboardData && federationDashboardData.federationDetails && federationDashboardData.federationDetails.federationName && federationDashboardData.federationDetails.cfcCode
      $scope.error = '';
      if (
        federationDashboardData &&
        federationDashboardData.federationDetails &&
        (!federationDashboardData.federationDetails.federationName ||
          !federationDashboardData.federationDetails.cfcCode)
      ) {
        $scope.error =
          'We could not find a Federation Application for year ' +
          federationDashboardData.federationDetails.pledgeYear;
      }
      // end if !federationDashboardData || !federationDashboardData.federationDetails || !federationDashboardData.federationDetails.federationName || !federationDashboardData.federationDetails.cfcCode
      // End - code added for new sprint
      $scope.showFederationStepPage = function(stepNo, applicationId, mode) {
        $state.go('federationStep' + stepNo, { mode: mode });
      };

      function setBreadcrumb(selectedYear, cfcCode) {
        url =
          '/CfcCharity/api/private/federationapplication/' +
          selectedYear +
          '/' +
          cfcCode;
        return CubeSvc.get(url, null, false);
      }
      function setBrTokenData(fedId) {
        return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
          fedId
        );
      }
      $scope.updateGroupNumber = function(isFed) {
        ApplicationDashboardService.updateGroupNumber(
          federationDashboardData.federationDetails.federationId,
          $scope.selectedGroupNumber,
          isFed
        ).then(function(res) {
          federationDashboardData.federationDetails.reviewGroup =
            $scope.selectedGroupNumber;
        });
      };
      $scope.resetGroupNumber = function() {
        $scope.selectedGroupNumber =
          federationDashboardData.federationDetails.reviewGroup;
      };
      $scope.gotoFederationPackage = function() {
        var pledgeYear = FederationApplicationService.getPledgeYear();
        var cfcCode = FederationApplicationService.getCfcCode();
        var fedAppId = federationDashboardData.federationDetails.federationId;
        FederationApplicationService.reset();
        $state.go('federationapplication', {
          userId: $scope.getCurrentUser().id,
          year: pledgeYear,
          cfcCode: cfcCode,
          fedAppId: fedAppId
        });
      };
      // end function
      // End - code added for new sprint

      $scope.federationDashboardData = federationDashboardData;

      $scope.gotoFederationReviewPage = function(
        application,
        stepNo,
        isEnrollmentAvailable
      ) {
        FederationApplicationService.reset();
        var applicationId = application.federationId;
        var status = application.applicationStatus;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        if (!isEnrollmentAvailable && status !== 'MORE_INFO_UNSIGNED') {
          $scope.error = 'The Charity Application Period is currently Closed';
        } else {
          $scope.federationNavbar.pledgeYear = pledgeYear;
          $scope.federationNavbar.cfcCode = cfcCode;
          $scope.federationNavbar.charityName =
            federationDashboardData.federationDetails.federationName;
          FederationApplicationService.setPledgeYear(pledgeYear);
          FederationApplicationService.setCfcCode(cfcCode);
          $scope.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode).then(function(res) {
            var role = res.data;
            var userSession = FederationApplicationService.getUserSession();
            userSession.cfcCode = cfcCode;
            userSession.applicationId = applicationId;
            userSession.userRole = role.userRole;
            userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
              new Date()
            );
            userSession.$save(function(response) {
              FederationApplicationService.setUserSession(response);
            });
          });
          FederationApplicationService.editFederationApplication(applicationId);
          $scope.setModuleName('CfcCharity.federation_application');
          $state.go('federationStep' + stepNo, { mode: 'EDIT' });
        }
      };
      $scope.gotoFederationViewPage = function(application, stepNo) {
        var applicationId = application.federationId;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        $scope.federationNavbar.pledgeYear = pledgeYear;
        $scope.federationNavbar.cfcCode = cfcCode;

        $scope.federationNavbar.charityName =
          federationDashboardData.federationDetails.federationName;
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        $scope.appVisibility.viewMode = 'VIEW';
        FederationApplicationService.editFederationApplication(applicationId);
        $scope.setModuleName('CfcCharity.federation_application');
        $state.go('federationStep' + stepNo, { mode: 'VIEW' });
      };
      $scope.gotoApplicationPage = function(
        application,
        isEnrollmentAvailable,
        stepPage,
        federationAuthority
      ) {
        if (
          federationAuthority &&
          (application.applicationStatus === 'IN_PROGRESS' ||
            (application.applicationStatus === 'MORE_INFO_UNSIGNED' &&
              federationDashboardData.federationDetails.rfiEndDate >
                currentDate))
        ) {
          gotoStepPageEditable(application, isEnrollmentAvailable, stepPage);
        } else if (
          application.applicationStatus !== 'WITHDRAWN' &&
          application.applicationStatus !== 'DISBANDED'
        ) {
          var isSignedOrPaid =
            'SIGNED' === application.applicationStatus ||
            'SUBMITTED' === application.applicationStatus;
          if (
            isEnrollmentAvailable &&
            isSignedOrPaid &&
            federationAuthority &&
            !$scope.isApplicationChangeDateEnded &&
            !federationDetailsData.application
              .applicationRegulatoryDeadlineElapsed
          ) {
            gotoStepPageEditable(application, isEnrollmentAvailable, stepPage);
          } else {
            gotoStepPageReadOnly(application, stepPage);
          }
        } else if (
          ((application.applicationStatus === 'DISBANDED' ||
            application.applicationStatus === 'WITHDRAWN') &&
            ($scope.user.secondaryRoleList.length > 0 &&
              !(
                $scope.user.secondaryRoleList[0].indexOf('GB_FINANCE_ADMIN') >=
                  0 ||
                $scope.user.secondaryRoleList[0].indexOf('GB_FINANCE') >= 0
              ))) ||
          ($scope.user.secondaryRoleList.length === 0 &&
            $scope.user.roles[0] === 'CHARITY_USER')
        ) {
          gotoStepPageReadOnly(application, stepPage);
        }
      };
      var gotoStepPageEditable = function(
        application,
        isEnrollmentAvailable,
        stepPage
      ) {
        FederationApplicationService.reset();
        var applicationId = application.federationId;
        var status = application.applicationStatus;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        if (
          !isEnrollmentAvailable &&
          status !== 'MORE_INFO_UNSIGNED' &&
          !application.startedAfterEnrollmentEnd
        ) {
          $scope.error = 'The Charity Application Period is currently Closed';
        } else {
          $scope.federationNavbar.pledgeYear = pledgeYear;
          $scope.federationNavbar.cfcCode = cfcCode;
          $scope.federationNavbar.charityName =
            federationDashboardData.federationDetails.federationName;
          FederationApplicationService.setPledgeYear(pledgeYear);
          FederationApplicationService.setCfcCode(cfcCode);
          $scope.appVisibility.viewMode = 'EDIT';
          CharityService.getCharityUserRole(cfcCode).then(function(res) {
            var role = res.data;
            var userSession = FederationApplicationService.getUserSession();
            userSession.cfcCode = cfcCode;
            //userSession.userId = $scope.getCurrentUser().id;
            userSession.applicationId = applicationId;
            userSession.userRole = role.userRole;
            userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
              new Date()
            );
            userSession.$save(function(response) {
              FederationApplicationService.setUserSession(response);
            });
          });
          FederationApplicationService.editFederationApplication(applicationId);
          $state.go('federationStep' + stepPage, { mode: 'EDIT' });
          $scope.setModuleName('CfcCharity.federation_application');
        }
      };
      var gotoStepPageReadOnly = function(application, stepPage) {
        var applicationId = application.federationId;
        var cfcCode = application.cfcCode;
        var pledgeYear = application.pledgeYear;
        $scope.federationNavbar.pledgeYear = pledgeYear;
        $scope.federationNavbar.cfcCode = cfcCode;
        CharityService.findCharityWithCFC(application.cfcCode).then(function(
          res
        ) {
          var charity = res.data;
          $scope.federationNavbar.charityName = charity.charityName;
        });
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        $scope.appVisibility.viewMode = 'VIEW';
        FederationApplicationService.editFederationApplication(applicationId);
        $state.go('federationStep' + stepPage, { mode: 'VIEW' });
        $scope.setModuleName('CfcCharity.federation_application');
      };
      $scope.goToSignProcess = function(federationId, isEnrollmentAvailable) {
        var errorMsg = '';
        if (
          !isEnrollmentAvailable &&
          !(
            $scope.appStatus === 'MORE_INFO' ||
            $scope.appStatus === 'MORE_INFO_UNSIGNED'
          ) &&
          !federationDashboardData.federationDetails.startedAfterEnrollmentEnd
        ) {
          $scope.error = 'The Charity Application Period is currently Closed';
        } else {
          if (federationDashboardData.federationDetails.pledgeYear) {
            BrowserEventService.setPledgeYear(
              federationDashboardData.federationDetails.pledgeYear
            );
          }
          $state.go('fed-application-checklist', {
            applicationId:
              federationDashboardData.federationDetails.federationId,
            pledgeYear: federationDashboardData.federationDetails.pledgeYear,
            applicationDetails: federationDashboardData.federationDetails
          });
        }
        $('.modal-backdrop').remove();
      };
      $scope.gotoSignAndUnsignFederation = function(
        federation,
        isEnrollmentAvailable
      ) {
        if (
          !isEnrollmentAvailable &&
          !(
            $scope.appStatus === 'MORE_INFO' ||
            $scope.appStatus === 'MORE_INFO_UNSIGNED'
          ) &&
          !federationDashboardData.federationDetails.startedAfterEnrollmentEnd
        ) {
          $scope.error = 'The Charity Application Period is currently Closed';
        } else {
          if (federationDashboardData.federationDetails.pledgeYear) {
            BrowserEventService.setPledgeYear(
              federationDashboardData.federationDetails.pledgeYear
            );
          }
          $state.go('signAndUnsignFederation', {
            applicationId: federation.federationId,
            pledgeYear: federation.pledgeYear
          });
        }
      };
      $scope.showEditAppBtn = function() {
        var startedAfterEnrollmentEnd =
          federationDashboardData.federationDetails.startedAfterEnrollmentEnd;
        return (
          (federationDashboardData.federationDetails.userSecondaryRoleList.indexOf(
            'FEDERATION_OWNER'
          ) >= 0 ||
            federationDashboardData.federationDetails.userSecondaryRoleList.indexOf(
              'FEDERATION_EDITOR'
            ) >= 0 ||
            federationDashboardData.federationDetails.userSecondaryRoleList.indexOf(
              'CHARITY_OWNER'
            ) >= 0 ||
            federationDashboardData.federationDetails.userSecondaryRoleList.indexOf(
              'CHARITY_EDITOR'
            ) >= 0) &&
          (federationDashboardData.federationDetails.applicationStatus ===
            'IN_PROGRESS' ||
            federationDashboardData.federationDetails.applicationStatus ===
              'MORE_INFO_UNSIGNED' ||
            federationDashboardData.federationDetails.applicationStatus ===
              'SIGNED' ||
            federationDashboardData.federationDetails.applicationStatus ===
              'SUBMITTED') &&
          federationDashboardData.federationDetails.applicationStatus !==
            'WITHDRAWN' &&
          federationDashboardData.federationDetails.applicationStatus !==
            'DISBANDED' &&
          ((!$scope.isApplicationChangeDateEnded &&
            !startedAfterEnrollmentEnd) ||
            (startedAfterEnrollmentEnd &&
              federationDashboardData.federationDetails.applicationStatus ===
                'IN_PROGRESS'))
        );
      };
      $scope.isEditAppBtnDisabled = function() {
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var isUserCharityRole =
          Array.isArray(userObj.roles) &&
          userObj.roles.includes('CHARITY_USER');
        if (
          !!federationDashboardData &&
          federationDashboardData.federationDetails
        ) {
          var isEnrollmentAvailable =
            federationDashboardData.isEnrollmentAvailable;
          var isSignedPaidOrInProgress =
            'IN_PROGRESS' ===
              federationDashboardData.federationDetails.applicationStatus ||
            'SIGNED' ===
              federationDashboardData.federationDetails.applicationStatus ||
            'SUBMITTED' ===
              federationDashboardData.federationDetails.applicationStatus;
          var isRfiOpenAndUnsigned =
            'MORE_INFO_UNSIGNED' ===
              federationDashboardData.federationDetails.applicationStatus &&
            federationDashboardData.federationDetails.rfiEndDate > currentDate;
          var startedAfterEnrollmentEnd =
            federationDashboardData.federationDetails.startedAfterEnrollmentEnd;
          var isSubmittedAndRegulatoryDeadlineElapsed =
            federationDashboardData.federationDetails.applicationStatus ===
              'SUBMITTED' &&
            federationDashboardData.federationDetails
              .applicationRegulatoryDeadlineElapsed;
          if (
            isUserCharityRole &&
            !$scope.isApplicationChangeDateEnded &&
            !startedAfterEnrollmentEnd
          ) {
            if (isSubmittedAndRegulatoryDeadlineElapsed) {
              return true;
            }
            return !(
              (isEnrollmentAvailable && isSignedPaidOrInProgress) ||
              isRfiOpenAndUnsigned
            );
          } else {
            return (
              federationDashboardData.federationDetails.applicationStatus ===
              'SIGNED'
            );
          }
        } else {
          return true;
        }
      };
      $scope.performEditAppBtn = function() {
        if (!$scope.isEditAppBtnDisabled()) {
          $scope.gotoApplicationPage(
            federationDashboardData.federationDetails,
            federationDashboardData.isEnrollmentAvailable,
            '1',
            federationDashboardData.federationAuthority &&
              !$scope.isApplicationChangeDateEnded
          );
        }
      };
      $('.modal-backdrop').remove();
    }
  ])
  .controller('FederationSignCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$q',
    '$log',
    'CharityService',
    'FederationDashboardService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $q,
      $log,
      CharityService,
      FederationDashboardService,
      BrowserEventService
    ) {
      var applicationId = $state.params.applicationId;
      $scope.pledgeYear = $state.params.pledgeYear;
      if (applicationId) {
        BrowserEventService.setFederationId(applicationId);
        FederationDashboardService.getFederationApplication(applicationId).then(
          function(res) {
            var application = res.data.value;

            $scope.application = application;
            CharityService.findCharityWithCFC(application.cfcCode).then(
              function(resp) {
                var charity = resp.data;
                $scope.federationCfcCode = charity.cfcCode;
              }
            );
          }
        );
      }
      $scope.applicationSignatureHistory = [];
      $scope.applicationRFIs = [];

      // check for sufficient rights to signature history and RFIs
      $scope.hasSufficientSignatureAndRFIviewingRights = false;
      var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      var user = $scope.getCurrentUser() || userObj;
      if (user.secondaryRoleList && Array.isArray(user.secondaryRoleList)) {
        $scope.hasSufficientSignatureAndRFIviewingRights =
          user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_1') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >=
            0 ||
          user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          user.secondaryRoleList.indexOf('REVIEWER') >= 0;
      }

      if ($scope.hasSufficientSignatureAndRFIviewingRights) {
        // load up signature history and RFIs if user has appropriate rights
        FederationDashboardService.getApplicationSignatureHistory(
          applicationId
        ).then(
          function(resp) {
            var data = resp.data.value;
            $log.debug(data);
            $scope.applicationSignatureHistory = data;
          },
          function(err) {
            $log.error(err);
          }
        );
        FederationDashboardService.getAllApplicationRFIs(applicationId).then(
          function(resp) {
            var data = resp.data.value;
            $log.debug(data);
            $scope.applicationRFIs = data;
          },
          function(err) {
            $log.error(err);
          }
        );
      }

      $scope.saveSignature = function(application) {
        $scope.fieldsMissing = [];
        if (!$scope.application.certifyApplication) {
          $scope.fieldsMissing.push('Certify Application');
        }
        if (!$scope.application.signatureFullName) {
          $scope.fieldsMissing.push('Full name');
        }
        if (!$scope.application.signatureInitials) {
          $scope.fieldsMissing.push('Initials');
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          application.applicationStatus = 'SIGNED';
          application.certifyApplication = true;
          application.signedDate = new Date();
          var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
          application.signedBy = userObj.id;
          FederationDashboardService.updateFederationApplication(
            application.id,
            application
          ).then(function(res) {
            var updateApplication = res.data.value;
            $('#fedSignThankyou').modal();
          });
        }
      };
      $scope.removeSignature = function(application) {
        application.applicationStatus = 'IN_PROGRESS';
        application.certifyApplication = null;
        application.signatureFullName = null;
        application.signatureInitials = null;
        application.signedDate = null;
        application.signedBy = null;
        FederationDashboardService.updateFederationApplication(
          application.id,
          application
        ).then(function(res) {
          var updateApplication = res.data.value;
          $state.go('federationDashboard', {
            federationId: updateApplication.id
          });
        });
      };
      $scope.gotoFederationApplication = function() {
        $('.modal-backdrop').remove();
        $state.go('federationapplication', {
          userId: $scope.currentUser.id,
          year: $scope.pledgeYear,
          cfcCode: $scope.federationCfcCode,
          fedAppId: $scope.application.id
        });
      };
    }
  ])
  .controller('MemberApplicationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'memberApplicationData',
    'BrowserEventService',
    '$http',
    'DashboardService',
    'tokenData',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      memberApplicationData,
      BrowserEventService,
      $http,
      DashboardService,
      tokenData,
      CubeSvc
    ) {
      $scope.setModuleName('CfcCharity.federationDashboard');
      $scope.memberApplicationData = memberApplicationData;
      $scope.resultsPerPage = 50;
      $scope.totalElements = $scope.memberApplicationData.totalMembers;
      $scope.pagination = {
        current: 1
      };
      var activePageNumber = 1;
      $scope.isEnrollmentAvailable = DashboardService.getEnrollmentAvailable();
      $scope.setMemberApplicationType('MEMBER');
      if (
        memberApplicationData != null &&
        memberApplicationData !== 'undefined'
      ) {
        $scope.setMemberApplicationParentCfcCode(
          memberApplicationData.federationCfcCode
        );
        $scope.setMemberApplicationParentCharityType(
          memberApplicationData.federationCharityType
        );
        if (!memberApplicationData.federationCfcCode) {
          memberApplicationData.federationCfcCode = tokenData.federationCfc;
        }
        if (!memberApplicationData.fedAppId) {
          memberApplicationData.fedAppId = tokenData.federationId;
        }
        if (!memberApplicationData.federationName) {
          memberApplicationData.federationName = tokenData.charityName;
        }
      }

      $scope.pageChanged = function(newPage) {
        if (activePageNumber !== newPage) {
          $scope.UpdateResults(
            $scope.memberApplicationData.year,
            $scope.memberApplicationData.federationCfcCode,
            newPage,
            $scope.matchedMemberApplication
          );
        }
      };

      $scope.filterSearchResults = function(searchField) {
        $scope.UpdateResults(
          $scope.memberApplicationData.year,
          $scope.memberApplicationData.federationCfcCode,
          1,
          searchField
        );
      };

      $scope.UpdateResults = function(
        year,
        federationCfcCode,
        pageNumber,
        filteredWord
      ) {
        activePageNumber = pageNumber;
        if (pageNumber === undefined) {
          pageNumber = 1;
        }
        var num = pageNumber - 1;
        var url =
          '/CfcCharity/api/private/users/federation/memberapplicationsperpage/' +
          year +
          '/' +
          federationCfcCode +
          '?page=' +
          num;
        if (filteredWord !== undefined) {
          url = url + '&filteredWord=' + filteredWord;
        }
        CubeSvc.get(url)
          .then(function(res) {
            $scope.memberApplicationData.memberApplicationBeanList =
              res.data.value.memberApplicationBeanList;
            $scope.totalElements = res.data.value.totalMembers;
          })
          .finally(function() {
            window.scroll(0, 0);
          })
          .catch(function(err) {
            $scope.noResultsErrorMessage =
              'Something went wrong. Please try again later.';
          });
      };

      $scope.setDeleteMemberApplicationData = function(
        userId,
        year,
        federationName,
        memberCharityName,
        federationApplicationCfcCode,
        memberApplicationCfcCode,
        memberApplicationStatus
      ) {
        $scope.memberApplicationUserId = userId;
        $scope.memberApplicationYear = year;
        $scope.federationName = federationName;
        $scope.federationApplicationCfcCode = federationApplicationCfcCode;
        $scope.memberCharityName = memberCharityName;
        $scope.memberApplicationCfcCode = memberApplicationCfcCode;
        $scope.memberApplicationStatus = memberApplicationStatus;
        $state.go('deletememberapplicationconfirmation', {
          userId: userId,
          year: year,
          federationName: federationName,
          memberCharityName: memberCharityName,
          federationApplicationCfcCode: federationApplicationCfcCode,
          memberApplicationCfcCode: memberApplicationCfcCode,
          memberApplicationStatus: memberApplicationStatus
        });
      };
    }
  ])
  .controller('DeleteMemberApplicationConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'deleteMemberApplicationData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      deleteMemberApplicationData,
      BrowserEventService
    ) {
      $scope.deleteMemberApplicationData = deleteMemberApplicationData;
      $scope.deleteMemberApplicationData.userId = $scope.getCurrentUser().id;
      $scope.cancel = function(userId, year, federationApplicationCfcCode) {
        $state.go('memberapplication', {
          userId: userId,
          year: year,
          cfcCode: federationApplicationCfcCode
        });
      };
    }
  ])
  .controller('WithdrawFederationApplicationConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'withdrawFederationApplicationData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      withdrawFederationApplicationData,
      BrowserEventService
    ) {
      $scope.withdrawFederationApplicationData = withdrawFederationApplicationData;
      $scope.cancel = function(userId, year, federationApplicationCfcCode) {
        $state.go('federationapplication', {
          year: year,
          cfcCode: federationApplicationCfcCode,
          fedAppId: withdrawFederationApplicationData.federationApplicationId
        });
      };
    }
  ])
  .controller('DisbandFederationApplicationConfirmationCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'disbandFederationApplicationData',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      disbandFederationApplicationData,
      BrowserEventService
    ) {
      $scope.disbandFederationApplicationData = disbandFederationApplicationData;
      $scope.cancel = function(userId, year, federationApplicationCfcCode) {
        $state.go('federationapplication', {
          year: year,
          cfcCode: federationApplicationCfcCode,
          fedAppId: disbandFederationApplicationData.federationApplicationId
        });
      };
    }
  ]);
