/**
 * @ngdoc Module
 * @author Sarveswar (MS)
 * @date Mar 6, 2016
 * @description This is security module for CFC Charity Application. The module will take care of authentication and role based authorization for UI resources
 *
 */
angular
  .module('CfcCharity.security', [])
  .constant('AUTH_EVENTS', {
    loginSuccess: 'auth-login-success',
    loginFailed: 'auth-login-failed',
    logoutSuccess: 'auth-logout-success',
    sessionTimeout: 'auth-session-timeout',
    notAuthenticated: 'auth-not-authenticated',
    notAuthorized: 'auth-not-authorized'
  })
  .constant('USER_ROLES', {
    all: '*',
    charity_user: 'CHARITY_USER',
    system_user: 'SYSTEM_USER'
  })
  .constant('SYSTEM_USER_SECONDARY_ROLES', {
    all: '*',
    gb_admin: 'GB_ADMIN',
    opm_admin: 'OPM_ADMIN',
    opm_staff: 'OPM_STAFF',
    opm_auditor: 'OPM_AUDITOR',
    customer_service_manager: 'CUSTOMER_SERVICE_MANAGER',
    gb_finance: 'GB_FINANCE',
    gb_finance_admin: 'GB_FINANCE_ADMIN',
    customer_service_tier_1: 'CUSTOMER_SERVICE_TIER_1',
    customer_service_tier_2: 'CUSTOMER_SERVICE_TIER_2', // DO NOT USE, added to keep assigned role for test account from breaking
    customer_service_tier_2_finance: 'CUSTOMER_SERVICE_TIER_2_FINANCE',
    zone_manager: 'ZONE_MANAGER',
    reviewer: 'REVIEWER',
    zone_coordinator: 'ZONE_COORDINATOR',
    national_campaign_manager: 'NATIONAL_CAMPAIGN_MANAGER'
  })
  .constant('LOOKUP_CODE', {
    states: 'STATES'
  })
  .constant('MODULE_NAME', {
    home: 'home',
    application: 'application'
  })
  .constant('APPLICATION_TYPE', {
    independent: 'INDEPENDENT',
    federation: 'FEDERATION',
    member_of_federation: 'MEMBER'
  })
  .constant('CHARITY_TYPE', {
    local: 'LOCAL',
    national: 'NATIONAL',
    international: 'INTERNATIONAL'
  })
  .constant('APPLICATION_STEP_STATUS', {
    incomplete: 'INCOMPLETE',
    complete: 'COMPLETE'
  })
  .constant('APPLICATION_STATUS', {
    inprogress: 'IN_PROGRESS',
    signed: 'SIGNED',
    submitted: 'SUBMITTED',
    withdrwan: 'WITHDRAWN',
    approved: 'APPROVED',
    rejected: 'REJECTED',
    review: 'UNDER_REVIEW',
    moreinfo: 'NEED_MORE_INFO',
    disbanded: 'DISBANDED'
  })
  .constant('IRS_FORM990_OPTION', {
    option1: 'OPTION_1',
    option2: 'OPTION_2'
  })
  .constant('EXEMPTION_STATUS', {
    option1: 'OPTION_1',
    option2: 'OPTION_2',
    option3: 'OPTION_3'
  })
  .constant('REVENUE_OPTION', {
    option1: 'OPTION_1',
    option2: 'OPTION_2',
    option3: 'OPTION_3'
  })
  .constant('GAAP_OPTION', {
    option1: 'OPTION_1',
    option2: 'OPTION_2'
  })
  .factory('TokenStorage', function() {
    var storageKey = 'auth_token';

    return {
      store: function(token) {
        return sessionStorage.setItem(storageKey, token);
      },
      retrieve: function() {
        return sessionStorage.getItem(storageKey);
      },
      clear: function() {
        return sessionStorage.removeItem(storageKey);
      }
    };
  })
  .factory('TokenAuthInterceptor', [
    '$q',
    '$window',
    'TokenStorage',
    function($q, $window, TokenStorage) {
      return {
        request: function(config) {
          var authToken = TokenStorage.retrieve();
          if (authToken) {
            config.headers['X-AUTH-TOKEN'] = authToken;
          }
          return config;
        },
        response: function(response) {
          // two factor auth errors that are returned with a status code of 200
          const data = response.data || {};
          const errorCode = data.errorCode;
          const config = response.config || {};
          const url = config.url || '';

          // if there is a two factor error and request was not part of auth flow, force a login
          if (
            errorCode &&
            url.indexOf('/current') === -1 &&
            url.indexOf('/login') === -1
          ) {
            TokenStorage.clear();
            $window.location.href = '/app';
          }
          return response;
        },
        responseError: function(error) {
          // authentication error (API not currently returning 401 when authentication fails)
          const authToken = TokenStorage.retrieve();
          if (!authToken) {
            TokenStorage.clear();
          }

          // authorization error
          if (error.status === 401) {
            TokenStorage.clear();
            const url = ((error || {}).config || {}).url || '';
            // if error was on login, no need to show acess denied popup
            if (url.indexOf('/login') >= 0) {
              /*Todo: Log error*/
            } else {
              $('#accessDeniedPopup').modal();
            }
          }

          // log out if any token/s timeout expired
          if (error.status === -1) {
            $window.location.href = '/app';
          }

          return $q.reject(error);
        }
      };
    }
  ])
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('TokenAuthInterceptor');
  })
  .run([
    '$templateCache',
    function($templateCache) {
      $templateCache.put(
        'error-messages',
        require('../views/public/messages.html')
      );
    }
  ])
  .run([
    '$rootScope',
    'AUTH_EVENTS',
    'AuthService',
    '$state',
    '$location',
    'USER_ROLES',
    'Session',
    'Idle',
    'UserService',
    function(
      $rootScope,
      AUTH_EVENTS,
      AuthService,
      $state,
      $location,
      USER_ROLES,
      Session,
      Idle,
      UserService
    ) {
      $rootScope.$state = $state;
      $rootScope.$on('$stateChangeStart', function(
        event,
        next,
        nextParams,
        from
      ) {
        var authorizedRoles = next.data.authorizedRoles;
        // Start - Added the code for second level role security
        var authorizedSecondaryRoles = next.data.authorizedSecondaryRoles;
        // End - Added the code for second level role security
        var requireAuthentication = next.data.requireAuthentication;
        if (requireAuthentication == null) {
          requireAuthentication = false;
        }
        if (requireAuthentication) {
          if (!Session.userId || Session.userId === null) {
            var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            if (user != null) {
              Session.create(user.id, '', user.roles, user.secondaryRoleList);
            }
          }
          if (!AuthService.isAuthenticated()) {
            event.preventDefault();
            $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
            $state.transitionTo('login');
          } else {
            if (AuthService.isAuthorized(authorizedRoles)) {
              if (
                authorizedSecondaryRoles &&
                !AuthService.isUserHavingSecondaryRole(authorizedSecondaryRoles)
              ) {
                event.preventDefault();
                $('#authPopup').modal();
                $rootScope.$broadcast(AUTH_EVENTS.notAuthorized);
              } else {
                Idle.watch();
                const key = 'ng2Idle.main.expiry';
                $rootScope.$on('IdleInterrupt', function() {
                  const ng2Idle = localStorage.getItem(key);
                  if (ng2Idle) {
                    localStorage.setItem(key, new Date().getTime());
                  }
                });
                $rootScope.$on('IdleStart', function() {
                  $rootScope.sessionTimeOutDialog();
                });
                $rootScope.$on('IdleEnd', function() {
                  toastr.clear();
                });
                $rootScope.$on('IdleTimeout', function() {
                  UserService.setUser({});
                  $rootScope.sessionTimeOutClose();
                  AuthService.logout();
                  $rootScope.$broadcast('logout');
                });
                $rootScope.$on('logout', function() {
                  localStorage.clear();
                  Idle.unwatch();
                });
              } // end if not user having secondary role
            } else {
              event.preventDefault();
              $('#authPopup').modal();
              $rootScope.$broadcast(AUTH_EVENTS.notAuthorized);
            } // end else
            // End - Added the code for second level role security
          } // end else
        }
      });
    }
  ])
  .directive('loginDialog', [
    'AUTH_EVENTS',
    function(AUTH_EVENTS) {
      return {
        restrict: 'A',
        template: '<div ng-if="visible" ng - include = "\'login.html\'" > ',
        link: function(scope) {
          var showDialog = function() {
            scope.visible = true;
          };
          scope.visible = false;
          scope.$on(AUTH_EVENTS.notAuthenticated, showDialog);
          scope.$on(AUTH_EVENTS.sessionTimeout, showDialog);
        }
      };
    }
  ])
  .factory('AuthResolver', [
    '$q',
    '$rootScope',
    '$state',
    function($q, $rootScope, $state) {
      return {
        resolve: function() {
          var deferred = $q.defer();
          var unwatch = $rootScope.$watch('currentUser', function(currentUser) {
            if (angular.isDefined(currentUser)) {
              if (currentUser) {
                deferred.resolve(currentUser);
              } else {
                deferred.reject();
                $state.transitionTo('login');
              }
              unwatch();
            }
          });
          return deferred.promise;
        }
      };
    }
  ]);
