angular
  .module('CfcCharity.federationlistingfee', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',

    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('federationlistingfee', {
        url: '/federationlistingfee',
        views: {
          main: {
            template: require('../views/private/federation/federationlistingfee.html'),
            controller: 'FederationListingFeeCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Listing Fee',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
        },
        params: {
          cfcCode: null,
          pledgeYear: null
        },
        resolve: {
          federationListingFeeResponse: function($stateParams, CubeSvc) {
            if (
              $stateParams.cfcCode != null &&
              $stateParams.pledgeYear != null
            ) {
              var cfcCode = $stateParams.cfcCode;
              var pledgeYear = $stateParams.pledgeYear;
              var url =
                '/CfcCharity/api/private/charity/getFederationListingPaymentInfo/' +
                pledgeYear +
                '/' +
                cfcCode;
              return CubeSvc.get(url).then(function(res) {
                return res.data.value;
              });
            }
          }
        }
      });

      $stateProvider.state('federationCClistingpayment', {
        url: '/federationCClistingpayment',
        views: {
          main: {
            template: require('../views/private/federation/federationCClistingpayment.html'),
            controller: 'FederationListingFeeCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Listing Credit Card Payment',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
        },
        params: {
          cfcCode: null,
          pledgeYear: null
        },
        resolve: {
          federationListingFeeResponse: function($stateParams, CubeSvc) {
            if (
              $stateParams.cfcCode != null &&
              $stateParams.pledgeYear != null
            ) {
              var cfcCode = $stateParams.cfcCode;
              var pledgeYear = $stateParams.pledgeYear;
              var url =
                '/CfcCharity/api/private/charity/getFederationListingPaymentInfo/' +
                pledgeYear +
                '/' +
                cfcCode;
              return CubeSvc.get(url).then(function(res) {
                return res.data.value;
              });
            }
          }
        }
      });

      $stateProvider.state('federationACHlistingpayment', {
        url: '/federationACHlistingpayment',
        views: {
          main: {
            template: require('../views/private/federation/federationACHlistingpayment.html'),
            controller: 'FederationListingFeeCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Listing ACH Payment',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
        },
        params: {
          cfcCode: null,
          pledgeYear: null
        },
        resolve: {
          federationListingFeeResponse: function($stateParams, CubeSvc) {
            if (
              $stateParams.cfcCode != null &&
              $stateParams.pledgeYear != null
            ) {
              var cfcCode = $stateParams.cfcCode;
              var pledgeYear = $stateParams.pledgeYear;
              var url =
                '/CfcCharity/api/private/charity/getFederationListingPaymentInfo/' +
                pledgeYear +
                '/' +
                cfcCode;
              return CubeSvc.get(url).then(function(res) {
                return res.data.value;
              });
            }
          }
        }
      });

      $stateProvider.state('recordListingPayment', {
        url: '/recordListingPayment',
        views: {
          main: {
            template: require('../views/private/federation/recordListingPayment.html'),
            controller: 'FederationListingFeeCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Listing Record Payment',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
        },
        params: {
          cfcCode: null,
          pledgeYear: null
        },
        resolve: {
          federationListingFeeResponse: function($stateParams, CubeSvc) {
            if (
              $stateParams.cfcCode != null &&
              $stateParams.pledgeYear != null
            ) {
              var cfcCode = $stateParams.cfcCode;
              var pledgeYear = $stateParams.pledgeYear;
              var url =
                '/CfcCharity/api/private/charity/getFederationListingPaymentInfo/' +
                pledgeYear +
                '/' +
                cfcCode;
              return CubeSvc.get(url).then(function(res) {
                return res.data.value;
              });
            }
          }
        }
      });
    }
  ])
  .controller('FederationListingFeeCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'federationListingFeeResponse',
    'BrowserEventService',
    'ApplicationService',
    'CharityService',
    '$filter',
    'DownloadService',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      federationListingFeeResponse,
      BrowserEventService,
      ApplicationService,
      CharityService,
      $filter,
      DownloadService,
      CubeSvc
    ) {
      $scope.setModuleName('CfcCharity.federationListingFee');
      $scope.federationListingVisible = true;
      $scope.federationApplicationVisible = false;
      $scope.creditCardTypeSelected = false;
      $scope.isCharityUser = false;
      $scope.federationListingFee = {};
      $scope.successPayment = {};
      $scope.federationListingFeeData = federationListingFeeResponse;

      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
      $scope.secondaryRoleList = secondaryRoleList;

      $scope.enablePayByCCAch = false;
      $scope.enableRecordPayment = false;

      if (
        secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >= 0 ||
        secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('GB_FINANCE_ADMIN') >= 0
      ) {
        $scope.enablePayByCCAch = true;
      } else {
        CharityService.getCharityUserRole(
          $scope.federationListingFeeData.cfcCode
        ).then(function(res) {
          if (res.data !== null && res.data !== '') {
            if (
              res.data.userRole === 'CHARITY_OWNER' ||
              res.data.userRole === 'CHARITY_EDITOR'
            ) {
              $scope.enablePayByCCAch = true;
            }
          }
        });
      }

      if (
        secondaryRoleList.indexOf('GB_FINANCE_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >= 0 ||
        secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0
      ) {
        $scope.showRecordPayment = true;
      }

      CharityService.getCharityUserRole(
        $scope.federationListingFeeData.cfcCode
      ).then(function(res) {
        if (res.data !== null && res.data !== '') {
          if (
            res.data.userRole === 'CHARITY_OWNER' ||
            res.data.userRole === 'CHARITY_EDITOR'
          ) {
            $scope.isCharityUser = true;
          }
        }
      });

      ApplicationService.getStates(function(data) {
        $scope.states = data;
      });

      $scope.goToPaymentPage = function(page) {
        $('.modal-backdrop').remove();
        $state.go(page, {
          cfcCode: $scope.federationListingFeeData.cfcCode,
          pledgeYear: $scope.federationListingFeeData.year,
          charityDashboardCfcCode: $scope.federationListingFeeData.cfcCode
        });
      };

      $scope.goToPaymentReceipt = function(page) {
        $('.modal-backdrop').remove();
        $state.go('paymentreceipt', {
          userId: $scope.successPayment.userId,
          id: $scope.successPayment.id,
          type: $scope.successPayment.type,
          feeType: $scope.successPayment.feeType,
          paymentMethod: $scope.successPayment.paymentMethod,
          year: $scope.successPayment.year
        });
      };
      if (
        federationListingFeeResponse &&
        (federationListingFeeResponse.error ||
          federationListingFeeResponse.statusCode === 'ERROR')
      ) {
        $scope.showFederationListingFeeErrorMessage(
          federationListingFeeResponse.errorResponse.errorCode +
            ' : ' +
            federationListingFeeResponse.errorResponse.errorMessage +
            '. Please try again later!'
        );
      } else if (
        federationListingFeeResponse &&
        federationListingFeeResponse.statusCode === 'SUCCESS'
      ) {
        ApplicationService.getStates(function(data) {
          $scope.states = data;
        });
        //$scope.federationListingFeeData = federationListingFeeResponse.federationListingFeeBean;
      }
      $scope.currentYear = new Date().getFullYear();
      $scope.currentMonth = new Date().getMonth() + 1;
      $scope.months = $locale.DATETIME_FORMATS.MONTH;

      $scope.expiryDateYears = [];
      for (i = 0; i <= 10; i++) {
        $scope.expiryDateYears.push($scope.currentYear + i);
      }

      $scope.validatePayment = function() {
        $scope.fieldsMissing = [];
        if (!$scope.federationListingFee) {
          $scope.fieldsMissing.push('Name on card is required.');
          $scope.fieldsMissing.push('Address 1 is required.');
          $scope.fieldsMissing.push('State is required.');
          $scope.fieldsMissing.push('City is required.');
          $scope.fieldsMissing.push('Zip is required.');
          $scope.fieldsMissing.push('Credit Card Number is required.');
          $scope.fieldsMissing.push('Expiration Date is required.');
          $scope.fieldsMissing.push('Security Code is required');
        } else {
          if (!$scope.federationListingFee.nameOnCard) {
            $scope.fieldsMissing.push('Name on card is required.');
          }
          if (!$scope.federationListingFee.billingAddress1) {
            $scope.fieldsMissing.push('Address 1 is required.');
          }
          if (!$scope.federationListingFee.billingState) {
            $scope.fieldsMissing.push('State is required.');
          }
          if (!$scope.federationListingFee.billingCity) {
            $scope.fieldsMissing.push('City is required.');
          }
          if (!$scope.federationListingFee.billingZip) {
            $scope.fieldsMissing.push('Zip is required.');
          }
          if (!$scope.federationListingFee.creditCardNumber) {
            $scope.fieldsMissing.push('Credit Card Number is required.');
          }
          if (!$scope.federationListingFee.securityCode) {
            $scope.fieldsMissing.push('Security Code is required');
          }
          if (
            $scope.federationListingFee.securityCode &&
            $scope.federationListingFee.securityCode.length < 3
          ) {
            $scope.fieldsMissing.push(
              'Security Code should be minimum 3 digits'
            );
          }
          if (
            !$scope.federationListingFee.selectedYear ||
            !$scope.federationListingFee.selectedMonth
          ) {
            $scope.fieldsMissing.push('Expiration Date is required.');
          } else {
            if (
              Number($scope.federationListingFee.selectedYear) <
                $scope.currentYear ||
              (Number($scope.federationListingFee.selectedYear) ===
                $scope.currentYear &&
                Number($scope.federationListingFee.selectedMonth) <
                  $scope.currentMonth)
            ) {
              $scope.fieldsMissing.push('Provided expiration date is invalid');
            }
          }
          if ($scope.federationListingFee.creditCardNumber) {
            var creditCardType = $filter('creditCardTypeFilter')(
              $scope.federationListingFee.creditCardNumber
            );
            if (!creditCardType) {
              $scope.fieldsMissing.push(
                'Invalid Credit Card type. Please enter a valid Visa, MasterCard, Discover or American Express card number.'
              );
            } else {
              $scope.federationListingFee.creditCardType = creditCardType;
            }
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.federationListingFee.listingId =
            $scope.federationListingFeeData.fedListingId;
          $scope.federationListingFee.federationId =
            $scope.federationListingFeeData.federationId;
          $scope.federationListingFee.listingFee =
            $scope.federationListingFeeData.totalAmountDue;
          $scope.federationListingFee.federationListingFee =
            $scope.federationListingFeeData.federationListingFee;
          $scope.federationListingFee.noOfMembers =
            $scope.federationListingFeeData.totalMemberListings;
          $scope.federationListingFee.totalMemberListingFee =
            $scope.federationListingFeeData.totalMemberListingFee;
          $scope.federationListingFee.cfcFee =
            $scope.federationListingFeeFeeWithOutPercent +
            $scope.totalMemberListingsFeeWithOutPercent;
          $scope.federationListingFee.processingFee =
            $scope.federationListingFeePercentAmt +
            $scope.totalMemberListingsFeePercentAmt;
          $scope.federationListingFee.listingIdList =
            $scope.federationListingFeeData.listingIdList;
          $scope.federationListingFee.applicationId =
            $scope.federationListingFeeData.fedApplicationId;
          // $scope.federationListingFee.expirationDate = ('0' + ($scope.federationListingFee.expirationDate.getMonth() + 1)).slice(-2) + $scope.federationListingFee.expirationDate.getFullYear().toString().substr(2, 2);
          $scope.federationListingFee.expirationDate =
            $scope.federationListingFee.selectedYear +
            '-' +
            $scope.federationListingFee.selectedMonth;
          var federationListingPaymentURI =
            '/CfcCharity/api/private/users/federation/listing/payment';
          $http
            .post(federationListingPaymentURI, $scope.federationListingFee)
            .then(function(res) {
              var data = res.data;
              if (data.paymentSuccessful) {
                var cfcCode = data.cfcCode;
                $state.go('federationlistingthankyou', {
                  federationListingCfcCode: cfcCode,
                  paymentid: data.id,
                  paymenttype: 'FEDERATION',
                  paymentfeeType: 'Listing',
                  paymentMethod: 'Credit Card',
                  paymentUserid: data.userId,
                  paymentYear: $scope.currentYear
                });
              } else {
                var error = '';
                if (!data.error) {
                  error =
                    'Credit Card Transaction Failed. Contact Customer Care if the problem persists';
                } else if (data.error || data.statusCode === 'ERROR') {
                  error =
                    data.errorResponse.errorCode +
                    ' : ' +
                    data.errorResponse.errorMessage;
                }
                $scope.federationListingFee.expirationDate = '';
                $scope.fieldsMissing.push(error);
                $('#missingInfo').modal();
              }
            })
            .catch(function(err) {
              $scope.fieldsMissing.push(err.data);
              $('#missingInfo').modal();
            });
        }
      };
      $scope.validateACHPayment = function() {
        $scope.fieldsMissing = [];
        if (!$scope.federationListingFee) {
          $scope.fieldsMissing.push('Name on Account is required.');
          $scope.fieldsMissing.push('Routing number is required.');
          $scope.fieldsMissing.push('Account number is required.');
          $scope.fieldsMissing.push('Bank Name is required.');
        } else {
          if (!$scope.federationListingFee.nameOnAccount) {
            $scope.fieldsMissing.push('Name on Account is required.');
          }
          if (!$scope.federationListingFee.routingNo) {
            $scope.fieldsMissing.push('Routing Number is required.');
          }
          var accounNoDigit = /^\d+$/.test(
            $scope.federationListingFee.accountNo
          );
          if (!$scope.federationListingFee.accountNo) {
            $scope.fieldsMissing.push('Account Number is required.');
          } else if (!accounNoDigit) {
            $scope.fieldsMissing.push('Account Number must be numeric');
          }
          var routingNineDigit = /^[0-9]{9}$/.test(
            $scope.federationListingFee.routingNo
          );
          if (
            ($scope.federationListingFee.routingNo &&
              $scope.federationListingFee.routingNo.length !== 9) ||
            !routingNineDigit
          ) {
            $scope.fieldsMissing.push('Routing Number should be 9 digits.');
          }
          if (!$scope.federationListingFee.bankName) {
            $scope.fieldsMissing.push('Bank Name is required.');
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.federationListingFee.listingId =
            $scope.federationListingFeeData.fedListingId;
          $scope.federationListingFee.federationId =
            $scope.federationListingFeeData.fedApplicationId;
          $scope.federationListingFee.listingFee =
            $scope.federationListingFeeData.totalAmountDue;
          $scope.federationListingFee.federationListingFee =
            $scope.federationListingFeeData.federationListingFee;
          $scope.federationListingFee.noOfMembers =
            $scope.federationListingFeeData.totalApprovedMemberListings;
          $scope.federationListingFee.totalMemberListingFee =
            $scope.federationListingFeeData.totalMemberListingFee;
          $scope.federationListingFee.cfcFee =
            $scope.federationListingFeeData.federationListingFee +
            $scope.federationListingFeeData.totalMemberListingFee;
          $scope.federationListingFee.processingFee =
            $scope.federationListingFeeData.memberProcessingPercentageFee;
          $scope.federationListingFee.listingIdList =
            $scope.federationListingFeeData.listingIdList;

          var federationApplicationPaymentURI =
            '/CfcCharity/api/private/users/federation/listing/achpayment';
          CubeSvc.post(
            federationApplicationPaymentURI,
            $scope.federationListingFee
          )
            .then(function(res) {
              var data = res.data;
              var msg = data.message.split(',');
              if (msg.length > 1) {
                $scope.successPayment.id = msg[1].trim();
              }
              $scope.successPayment.feeType = 'Listing';
              $scope.successPayment.type = 'FEDERATION';
              $scope.successPayment.userId = $scope.getCurrentUser().id;
              $scope.successPayment.paymentMethod = 'ACH Pull';
              $scope.successPayment.year = $scope.currentYear;

              if (msg[0] === 'SAVE_SUCCESS') {
                $scope.successMsg =
                  'This payment has been received and all listings are submitted.';
                $('#achForm')[0].reset();
                $('#thankyou').modal();
              } else if (data.statusCode === 'ACH_PAYMENT_ALREADY_DONE') {
                $scope.fieldsMissing.push(
                  'ACH Payment is already made for this listing'
                );
                $('#missingInfo').modal();
              }
            })
            .catch(function(err) {
              $scope.fieldsMissing.push(err.data);
              $('#missingInfo').modal();
            });
        }
      };
      $scope.showFederationListingFeeErrorMessage = function(error) {
        $scope.federationListingFeeError = error;
      };
      $scope.submittingPayment = false;
      $scope.recordPayment = function() {
        $scope.federationListingFee.transactionAmountData =
          $scope.federationListingFeeData.totalAmountDue;
        $scope.fieldsMissing = [];
        if (!$scope.federationListingFee) {
          $scope.fieldsMissing.push('Transaction Amount is required.');
          $scope.fieldsMissing.push('Transaction Type is required.');
          $scope.fieldsMissing.push('Transaction Number is required.');
          $scope.fieldsMissing.push('Date of Payment is required.');
        } else {
          if (!$scope.federationListingFee.transactionAmountData) {
            $scope.fieldsMissing.push('Transaction Amount is required.');
          } else {
            $scope.federationListingFee.transactionAmount = parseFloat(
              $scope.federationListingFee.transactionAmountData
            );
          }
          if (!$scope.federationListingFee.transactionType) {
            $scope.fieldsMissing.push('Transaction Type is required.');
          }
          if (!$scope.federationListingFee.transactionNumber) {
            $scope.fieldsMissing.push('Transaction Number is required.');
          }
          if (!$scope.federationListingFee.paymentDate) {
            $scope.fieldsMissing.push('Date of Payment is required.');
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.federationListingFee.listingId =
            $scope.federationListingFeeData.fedListingId;
          $scope.federationListingFee.applicationId =
            $scope.federationListingFeeData.fedApplicationId;
          $scope.federationListingFee.cfcCode =
            $scope.federationListingFeeData.cfcCode;
          $scope.federationListingFee.campaignId =
            $scope.federationListingFeeData.campaignId;
          $scope.federationListingFee.feeAmount =
            $scope.federationListingFeeData.totalAmountDue;
          $scope.federationListingFee.procFeeAmount =
            $scope.federationListingFeeData.memberProcessingPercentageFee;
          $scope.federationListingFee.listingIdList =
            $scope.federationListingFeeData.listingIdList;
          $scope.federationListingFee.feeType = 'LISTING'; //??? todo: is this correct?
          $scope.federationListingFee.user = null;
          $scope.federationListingFee.paymentDate = new Date(
            $scope.federationListingFee.paymentDate
          );
          $scope.submittingPayment = true;
          var federationApplicationPaymentURI =
            '/CfcCharity/api/private/admin/recordFederationAchPayment';
          $http
            .post(federationApplicationPaymentURI, $scope.federationListingFee)
            .then(function(res) {
              $scope.successPayment = res.data;
              var msg = res.data.message.split(',');
              $scope.successPayment.id = msg[1].trim();
              $scope.successPayment.feeType = 'Listing';
              $scope.successPayment.type = 'FEDERATION';
              $scope.successPayment.userId = $scope.getCurrentUser().id;
              $scope.successPayment.paymentMethod = 'ACH Push';
              $scope.successPayment.year = $scope.federationListingFee.paymentDate.getFullYear();
              $scope.successMsg =
                'Record of this payment has been received and all listings are submitted.';
              $('#recordPaymentForm')[0].reset();
              $('#thankyou').modal();
            })
            .finally(function() {
              $scope.submittingPayment = false;
            })
            .catch(function(err) {
              $scope.fieldsMissing.push(err.data);
              $('#missingInfo').modal();
            });
        }
      };
      $scope.gotoAchPayment = function() {
        BrowserEventService.setCampaignId(
          $scope.federationListingFeeData.campaignId
        );
        BrowserEventService.setListingId(
          $scope.federationListingFeeData.listingId
        );
        BrowserEventService.setApplicationId(
          $scope.federationListingFeeData.federationId
        );
        BrowserEventService.setTotalMemberListingFee(
          $scope.federationListingFeeData.totalMemberListingFee
        );
        BrowserEventService.setFedCfcCode(
          $scope.federationListingFeeData.cfcCode
        );
        BrowserEventService.setFederationName(
          $scope.federationListingFeeData.federationName
        );
        BrowserEventService.setListingIdList(
          $scope.federationListingFeeData.listingIdList
        );
        $state.go('federationlistingachpayment', {
          cfcCode: $scope.federationListingFeeData.cfcCode,
          federationName: $scope.federationListingFeeData.federationName,
          listingId: $scope.federationListingFeeData.listingId,
          federationId: $scope.federationListingFeeData.federationId,
          totalMemberListingFee:
            $scope.federationListingFeeData.totalMemberListingFee,
          campaignId: $scope.federationListingFeeData.campaignId,
          listingIdList: $scope.federationListingFeeData.listingIdList
        });
      };
      $scope.gotoFedListingAchPaymentByCharityUser = function() {
        BrowserEventService.setCampaignId(
          $scope.federationListingFeeData.campaignId
        );
        BrowserEventService.setApplicationId(
          $scope.federationListingFeeData.federationId
        );
        BrowserEventService.setListingId(
          $scope.federationListingFeeData.listingId
        );
        BrowserEventService.setTotalMemberListings(
          $scope.federationListingFeeData.totalMemberListings
        );
        BrowserEventService.setFedCfcCode(
          $scope.federationListingFeeData.cfcCode
        );
        BrowserEventService.setFederationName(
          $scope.federationListingFeeData.federationName
        );
        BrowserEventService.setListingIdList(
          $scope.federationListingFeeData.listingIdList
        );
        $state.go('federationlistingachpaymentcharityuser', {
          cfcCode: $scope.federationListingFeeData.cfcCode,
          federationName: $scope.federationListingFeeData.federationName,
          listingId: $scope.federationListingFeeData.listingId,
          federationId: $scope.federationListingFeeData.federationId,
          totalMemberListings:
            $scope.federationListingFeeData.totalMemberListings,
          campaignId: $scope.federationListingFeeData.campaignId,
          listingIdList: $scope.federationListingFeeData.listingIdList
        });
      };

      $scope.listingFeeDetailsReportDownload = function() {
        var url =
          '/CfcCharity/api/private/federation/listing/payment/fee-details/' +
          $scope.federationListingFeeData.campaignId +
          '/' +
          $scope.federationListingFeeData.cfcCode +
          '/' +
          $scope.federationListingFeeData.fedApplicationId;
        DownloadService.downloadFile(url);
      };
    }
  ]);
