// jobs.controller.js - Controller for the Admin System Jobs view

'use strict';

angular
  .module('CfcCharity.admin.system')
  .controller('SystemJobsCtl', SystemJobsCtl);

SystemJobsCtl.$inject = [
  '$scope',
  'AdminJobsService',
  'BrowserEventService',
  '$state',
  '$interval',
  '$log',
  'blockUIConfig',
  'moment',
  '$q',
  '$anchorScroll',
  '$location',
  '$timeout'
];

function SystemJobsCtl(
  $scope,
  AdminJobsService,
  BrowserEventService,
  $state,
  $interval,
  $log,
  blockUIConfig,
  moment,
  $q,
  $anchorScroll,
  $location,
  $timeout
) {
  blockUIConfig.autoBlock = false;
  $scope.moduleName = 'CfcCharity.admin.system';
  $scope.ids = [];
  var loadTime = 15000;
  $scope.PXrunning = false;

  $scope.setStatusOptions = [
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Failed', label: 'Failed' }
  ];
  $scope.setStatusSelectedStatus = 'Complete';

  $('#jobAlredyRunningError').hide();
  $('#serverError').hide();
  $('#setStatusError').hide();

  // get job types
  $scope.getJobTypes = function() {
    AdminJobsService.transferCharityDataTypes()
      .then(function(res) {
        //filter out anonymized pledge report job
        $scope.jobTypes = res.data.filter(
          jobType => jobType.enumId !== 101 && jobType.enumId !== 102
        );
        if ($scope.ids.length > 0 && $scope.attemptRunId != undefined) {
          angular.forEach($scope.jobTypes, function(job) {
            if (job.id === 4) {
              angular.forEach(job.activeJobs, function(value) {
                if (value.jobId === $scope.attemptRunId) {
                  if (value.jobHistory.status.toLowerCase() === 'running') {
                    $scope.PXrunning = true;
                  } else {
                    $scope.PXrunning = false;
                  }
                }
              });
            }
          });
        }
      })
      .finally(function() {
        if (scrollToIRSMAsterFile) {
          $location.hash('IRSMasterFile');
          $timeout(function() {
            $anchorScroll();
          }, 500);
        }
      })
      .catch(function(err) {
        $('#serverError').show();
      });
  };
  // store response from interval in order to be able to cancel it
  var intervalReturn = null;

  // start polling the job types every interval
  $scope.startTimer = function() {
    intervalReturn = $interval(function() {
      $scope.getJobTypes();
    }, loadTime);
  };

  // stop job types polling
  $scope.stopTimer = function() {
    $interval.cancel(intervalReturn);
  };

  // register event to stop polling if user navigates away. Also re-enable block UI
  $scope.$on('$destroy', function() {
    $scope.stopTimer();
    blockUIConfig.autoBlock = true;
  });

  /* Initiation logic */
  function init() {
    $scope.getJobTypes();
    $scope.startTimer();
    $('#changeRunFromDateModal').on('show.bs.modal', function(e) {
      $scope.changedRunFromDate = $scope.runFromDate;
    });
  }

  init();

  /* Attempt to run command if it's not already running */
  $scope.attemptRunCommand = function(id, year, type, event) {
    $scope.buttonSelector = '#' + event.target.id;
    $scope.attemptJobId = id;
    if (!$scope.PXrunning) {
      if (type === 'Pledge Transfer') {
        angular.forEach($scope.jobTypes, function(job) {
          if (job.id === 4) {
            angular.forEach(job.activeJobs, function(value) {
              $scope.ids.push('#job-' + value.jobId);
            });
          }
        });
      }

      // check with server to see if the job is already running
      AdminJobsService.transferCharityDataStatus(id)
        .then(function(res) {
          var status = res.data;

          if (status === 'Running') {
            // display modal saying job is alredy in running status
            $('#jobAlredyRunningError').show();
            return;
          }

          $scope.attemptRunId = id;
          $scope.attemptRunYear = year;
          $scope.attemptRunType = type;

          $q.resolve(
            AdminJobsService.getLastCompletedDate(id).then(function(res) {
              var lastRunDate = moment.utc(res.data).format('L');
              $scope.lastRunDate = lastRunDate;
              $scope.lastRunDateDisplay = lastRunDate;
              $scope.runFromDate = lastRunDate;
            })
          ).then(function() {
            $('#runJobConfirmationModal').modal();
          });
        })
        .catch(function(err) {
          $('#serverError').show();
        });
    } else {
      $('#jobAlredyRunningError').show();
      return;
    }
  };

  /* Run command by Id */
  $scope.runCommand = function(id) {
    var runNowButton = angular.element($scope.buttonSelector);
    $(runNowButton).prop('disabled', true);

    //if it is pledge xfer job then deactivate all other campaign when one campaign is running
    if ($scope.attemptRunType === 'Pledge Transfer') {
      angular.forEach($scope.ids, function(value) {
        var runNowBtn = angular.element(value);
        $(runNowBtn).prop('disabled', true);
      });
    }
    // check AGAIN with server to see if the job is already running
    AdminJobsService.transferCharityDataStatus(id)
      .then(function(res) {
        var status = res.data;
        if (status === 'Running') {
          // display modal saying job is alredy in running status
          $('#jobAlredyRunningError').show();
          return;
        }

        // run command
        AdminJobsService.transferCharityData(
          id,
          moment.utc($scope.runFromDate).format('YYYY-MM-DD')
        );
      })
      .catch(function(err) {
        // alert something went wrong
        $('#serverError').show();
      });
  };

  // prepare the show status modal
  $scope.showStatus = function(id, year, type) {
    $scope.statusModalJobId = id;
    $scope.statusModalYear = year;
    $scope.statusModalType = type;

    var startTime,
      endTime,
      jobDuration = null;

    // get job status and populate modal vars
    AdminJobsService.transferCharityDataStatus(id).then(function(res) {
      var data = res.data;

      if (data) {
        $scope.statusModalJobStatus = data.status;
        $scope.statusModalJobStart = data.startTime;
        $scope.statusModalJobEnd = data.endTime;
        $scope.statusModalRunDate = data.runDateAsString;
        $scope.statusModalResponseData = data.jobRespData;
        $scope.statusModalJobHistroyId = data.id;

        if (data.startTime) {
          startTime = new Date(data.startTime);
        }

        if (data.endTime) {
          endTime = new Date(data.endTime);
        }

        if (startTime && data.endTime) {
          $scope.statusModalJobRunTime = getJobDurationString(
            data.startTime,
            data.endTime
          );
        }

        if (data.runByUserId) {
          AdminJobsService.getUserById(data.runByUserId).then(function(resp) {
            var user = resp.data;
            $scope.statusModalJobStatusUserFirstName = user.firstName;
            $scope.statusModalJobStatusUserLastName = user.lastName;
          });
        } else {
          $scope.statusModalJobStatusUserFirstName = 'Automated Run';
          $scope.statusModalJobStatusUserLastName = '';
        }
      }
    });
  };

  $scope.setStatus = function() {
    AdminJobsService.transferCharityDataSetStatus(
      $scope.statusModalJobHistroyId,
      $scope.setStatusSelectedStatus
    ).then(function(res) {
      $scope.getJobTypes();
    });
  };

  function getJobDurationString(start, end) {
    var difference = new Date(end).getTime() - new Date(start).getTime();

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    var secondsDifference = Math.floor(difference / 1000);

    return (
      daysDifference +
      ' day/s ' +
      hoursDifference +
      ' hour/s ' +
      minutesDifference +
      ' minute/s ' +
      secondsDifference +
      ' second/s '
    );
  }

  $scope.showHistory = function(id, year, type) {
    $state.go('admin-system-jobs-history', {
      jobId: id,
      campaignYear: year,
      displayName: type
    });
  };

  $scope.closeAlert = function(alertId) {
    $('#' + alertId).hide();
  };

  $scope.clearRunNow = function() {
    $scope.attemptRunId = '';
    $scope.attemptRunYear = '';
    $scope.attemptRunType = '';
    $scope.attemptJobId = '';
  };

  $scope.openChangeRunFromDateModal = function() {
    $('#changeRunFromDateModal').modal();
  };

  $scope.changeRunFromDate = function() {
    $scope.validationMessage = null;
    $scope.lastRunDate;
    var changedRunFromDate = moment.utc($scope.changedRunFromDate);

    if (!changedRunFromDate.isValid()) {
      $scope.validationMessage = 'RUN FROM DATE is not valid.';
      return;
    }

    if (changedRunFromDate.isAfter(moment.utc($scope.lastRunDate))) {
      $scope.validationMessage =
        'RUN FROM DATE can not be after LAST RUN DATE, (' +
        moment.utc($scope.lastRunDate).format('L') +
        ').';
      return;
    }

    $scope.runFromDate = $scope.changedRunFromDate;
    $('#changeRunFromDateModal').modal('hide');
  };

  var scrollToIRSMAsterFile = false;
  $scope.$on('$locationChangeSuccess', function(evt, newUrl, oldUrl) {
    if (oldUrl.indexOf('admin-upload-region-files') > -1) {
      scrollToIRSMAsterFile = true;
    }
  });
}
