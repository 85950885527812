// pledge.service.js - Service calls to get charity pledge data

'use strict';

angular
  .module('CfcCharity.charitydashboard')
  .factory('PledgeService', PledgeService);

PledgeService.$inject = ['$http'];

function PledgeService($http) {
  var service = {
    getCharitySummaryPledgeData: getCharitySummaryPledgeData,
    getCharityDashboardData: getCharityDashboardData,
    getFederationDashboardData: getFederationDashboardData,
    getMembersDashboardData: getMembersDashboardData,
    getMemberNewpageData: getMemberNewpageData
  };
  return service;

  /*
   * get Charity's Pledge summary data
   */
  function getCharitySummaryPledgeData(cfcCode) {
    var url =
      '/CfcCharity/api/private/users/charitydashboardpledges/' + cfcCode;
    return $http.get(url);
  }

  /* get Charity dashboard pledge details */
  function getCharityDashboardData(cfcCode, campaignYear) {
    var url =
      '/CfcCharity/api/private/charity/pledges/' + campaignYear + '/' + cfcCode;
    return $http.get(url);
  }

  /* get federation dashboard pledge details */
  function getFederationDashboardData(cfcCode, campaignYear) {
    var url =
      '/CfcCharity/api/private/federation/pledges/' +
      campaignYear +
      '/' +
      cfcCode;
    return $http.get(url);
  }

  function getMembersDashboardData(cfcCode, campaignYear) {
    var url =
      '/CfcCharity/api/private/federation/members/pledges/' +
      campaignYear +
      '/' +
      cfcCode;
    return $http.get(url);
  }

  function getMemberNewpageData(cfcCode, campaignYear, page) {
    var url =
      '/CfcCharity/api/private/federation/members/pledges/' +
      campaignYear +
      '/' +
      cfcCode +
      '/' +
      page;
    return $http.get(url);
  }
}
