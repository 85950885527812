// overview.controller.js - Controller for the Admin Zone Donation Overview view

'use strict';

angular
  .module('CfcCharity.admin.zone.pledges')
  .controller('AdminZonePledgesOverviewCtl', AdminZonePledgesOverviewCtl);

AdminZonePledgesOverviewCtl.$inject = [
  '$scope',
  'BrowserEventService',
  'AdminZoneListingService',
  'AdminZonePledgesService',
  '$log',
  '$state',
  'parameterData',
  'DownloadService',
  'CharityService',
  'RedirectService'
];

function AdminZonePledgesOverviewCtl(
  $scope,
  BrowserEventService,
  AdminZoneListingService,
  AdminZonePledgesService,
  $log,
  $state,
  parameterData,
  DownloadService,
  CharityService,
  RedirectService
) {
  $scope.moduleName = 'CfcCharity.admin.zone.pledges';
  $scope.zoneCharityPledgesInfo = null;
  $scope.zoneId = null;
  $scope.pledgeYear = null;
  $scope.zoneUserRole = null;
  $scope.selectedListingCampaignId = null;
  $scope.listingStatus = null;
  $scope.isListingAvailable = null;
  $scope.DAOonly = true;
  $scope.selectedListingCampaignId = null;
  $scope.zoneCode = null;
  init();

  function init() {
    if (parameterData) {
      $scope.zoneId = parameterData.zoneId;
      $scope.zoneName = parameterData.zoneName;
      $scope.pledgeYear = parameterData.pledgeYear;
      $scope.zoneUserRole = parameterData.zoneUserRole;
      $scope.selectedListingCampaignId = parameterData.campaignId;
      $scope.zoneCode = parameterData.zoneCode;
      $scope.zoneCharityPledgesInfo = null;
      $scope.zoneCharityPledgesDetails = [];
    }
    if ($scope.zoneId && $scope.zoneId !== '' && $scope.pledgeYear) {
      BrowserEventService.setZoneId($scope.zoneId);
      !!$scope.zoneName
        ? BrowserEventService.setZoneName($scope.zoneName)
        : null;
      BrowserEventService.setPledgeYear($scope.pledgeYear);
      BrowserEventService.setZoneUserRole($scope.zoneUserRole);

      // Retrieve the charity pledges summary & detail information
      AdminZonePledgesService.getZoneCharityPledgesInfo(
        $scope.selectedListingCampaignId,
        $scope.zoneId,
        true
      )
        .then(function(resp) {
          var result = resp.data;
          if (result !== null && result !== '') {
            $scope.zoneCharityPledgesInfo = result;

            AdminZonePledgesService.getZoneCharityPledgesDetails(
              $scope.selectedListingCampaignId,
              $scope.zoneId
            )
              .then(function(resp) {
                var detailResult = resp.data;
                // do the things!!
                if (null !== detailResult && '' !== detailResult) {
                  $scope.zoneCharityPledgesDetails = detailResult.data;
                }
              })
              .catch(function(err) {
                $log.error(
                  'Error retrieving charity pledges details in AdminZonePledgesService.getZoneCharityPledgesDetails'
                );
              });
          } else {
            $log.error('nothing to see for the zone pledges summary');
          }
        })
        .catch(function(err) {
          $log.error(
            'Error retrieving charity pledges info in AdminZonePledgesService.getZoneCharityPledgesInfo'
          );
        });
    }
  }

  // additional functions, actions, etc.
  $scope.selectChangedCampaignId = function() {
    // Use the campaignId to get the pledge year
    var campaignYear = null;
    angular.forEach(
      $scope.zoneCharityListingInfo.listingCampaignPeriods,
      function(value, key) {
        if (value.campaignId.toString() === $scope.selectedListingCampaignId) {
          campaignYear = value.year.toString();
        }
      }
    );

    $state.go('admin-zone-pledges-overview', {
      pledgeYear: parseInt(campaignYear),
      zoneId: $scope.zoneId,
      zoneUserRole: $scope.zoneUserRole,
      zoneName: $scope.zoneName,
      zoneCode: $scope.zoneCode,
      campaignId: $scope.selectedListingCampaignId
    });
  };

  $scope.gotoZoneCenter = function() {
    $state.go('zoneCenter', {
      zoneId: $scope.zoneId,
      zoneUserRole: $scope.zoneUserRole
    });
  };

  $scope.downloadPledgeSummaryReport = function() {
    AdminZonePledgesService.getYearsAndZonesList()
      .then(function(res) {
        var data = res.data;
        if (data != null && data !== 'undefined') {
          // Figure out the zone information
          var campaignId;
          var zoneId = parameterData.zoneId;
          var zoneCode;
          var zoneName;
          angular.forEach(data.zoneBeanList, function(value, key) {
            if (zoneId.toString() === value.zoneId.toString()) {
              zoneCode = value.zoneCode;
              zoneName = value.zoneName;
            }
          });

          // Figure out the camapaignId
          angular.forEach(data.campaignBeanList, function(value, key) {
            if (parameterData.pledgeYear.toString() === value.year.toString()) {
              campaignId = value.campaignId;
            }
          });

          var url =
            '/CfcCharity/api/private/admin/zone/pledgesummaryzone/' +
            campaignId +
            '/' +
            zoneId +
            '/' +
            $scope.DAOonly +
            '?&zoneCode=' +
            zoneCode +
            '&zoneName=' +
            zoneName;
          DownloadService.downloadFile(url);
        }
      })
      .catch(function(err) {
        $log.error('Error retreiving the Pledge Summary Report.');
      });
  };
}
