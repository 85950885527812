angular
  .module('CfcCharity.zoneadmin', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider
        .state('zoneadmin', {
          url: '/zoneAdmin',
          views: {
            main: {
              template: require('../views/private/zoneadmin/zoneAdmin.html'),
              controller: 'ZoneAdminCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Zone Admin Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            userId: null
          },
          resolve: {
            zoneAdminData: function($http, $stateParams) {
              if ($stateParams.userId != null) {
                var userId = $stateParams.userId;
                var url =
                  '/CfcCharity/api/private/users/' +
                  userId +
                  '/' +
                  '/zoneAdmin';
                return $http.get(url).then(function(res) {
                  return res.data;
                });
              }
            }
          }
        })
        .state('addzone', {
          url: '/addZone',
          views: {
            main: {
              template: require('../views/private/zoneadmin/zoneAdd.html'),
              controller: 'ZoneAddCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Add Zone Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            userId: null
          }
        })
        .state('addzip', {
          url: '/addZip',
          views: {
            main: {
              template: require('../views/private/zoneadmin/zipcodeAdd.html'),
              controller: 'ZipcodeAddCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Add Zipcode Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            zoneId: null,
            zoneCode: null,
            zoneName: null,
            pledgeYear: null
          }
        })
        .state('editzone', {
          url: '/editZone',
          views: {
            main: {
              template: require('../views/private/zoneadmin/zoneEdit.html'),
              controller: 'ZoneEditCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Edit Zone Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            userId: null,
            zoneCode: null,
            zoneName: null,
            zoneUrl: null,
            zoneEmails: null,
            zoneContactName: null,
            zoneContactPhone: null,
            status: null,
            zoneId: null,
            prepopulate: null
          },
          resolve: {
            zoneAdminPerCampaignData: function($http, $stateParams) {
              if (
                $stateParams.zoneId != null &&
                ($stateParams.prepopulate === 'INACTIVE' ||
                  $stateParams.prepopulate === false)
              ) {
                var zoneId = $stateParams.zoneId;
                var url =
                  '/CfcCharity/api/private/admin/zonespercampaign/' + zoneId;
                return $http.get(url).then(function(res) {
                  return res.data.value;
                });
              }
            }
          }
        })
        .state('editzipzone', {
          url: '/editZipZone',
          views: {
            main: {
              template: require('../views/private/zoneadmin/zipZoneEdit.html'),
              controller: 'ZipZoneEditCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Edit Zip Zones Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            zoneId: null,
            zoneCode: null,
            zoneName: null,
            zipcodeId: null,
            zipCode: null,
            pledgeYear: null
          },
          resolve: {
            zoneListData: function($http, $stateParams) {
              var zoneId = $stateParams.zoneId;
              var zoneCode = $stateParams.zoneCode;
              var zoneName = $stateParams.zoneName;
              zoneName = zoneName.replace('/', ' ');
              var zipcodeId = $stateParams.zipcodeId;
              var userId = $stateParams.userId;
              var pledgeYear = $stateParams.pledgeYear;
              var url =
                '/CfcCharity/api/private/users/' +
                '/' +
                zoneId +
                '/' +
                zoneCode +
                '/' +
                zoneName +
                '/' +
                zipcodeId +
                '/' +
                pledgeYear +
                '/zipZoneList';
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        })
        .state('zipSearchResults', {
          url: '/zipSearchResults',
          views: {
            main: {
              template: require('../views/private/zoneadmin/zipSearchResults.html'),
              controller: 'ZipSearchCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Zipcode Seacrh Results Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            zipCode: null
          },
          resolve: {
            zipSearchData: function($http, $stateParams) {
              var zipCode = $stateParams.zipCode;
              if ($stateParams.zipCode != null) {
                var url = '/CfcCharity/api/private/zone/zipsearch/' + zipCode;
                return $http.get(url).then(function(res) {
                  return res.data.value;
                });
              }
            }
          }
        })
        .state('zipadmin', {
          url: '/zipAdmin',
          views: {
            main: {
              template: require('../views/private/zoneadmin/zipcodeAdmin.html'),
              controller: 'ZipcodeAdminCtrl'
            }
          },
          data: {
            pageTitle: 'CFC Zipcode Admin Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.gb_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_staff,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            zoneId: null,
            pledgeYear: null
          },
          resolve: {
            zipAdminData: function($http, $stateParams) {
              var zoneId = $stateParams.zoneId;
              var pledgeYear = $stateParams.pledgeYear;
              if (
                $stateParams.zoneId != null &&
                $stateParams.pledgeYear != null
              ) {
                var url =
                  '/CfcCharity/api/private/users/' +
                  zoneId +
                  '/' +
                  pledgeYear +
                  '/zipAdmin';
                return $http.get(url).then(function(res) {
                  return res.data;
                });
              }
            }
          }
        });
    }
    //ZoneAdminCtrl controller is invoked to fetch the Zones data.
  ])
  .controller('ZoneAdminCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'zoneAdminData',
    'BrowserEventService',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      zoneAdminData,
      BrowserEventService,
      $http
    ) {
      $scope.setModuleName('CfcCharity.zoneadmin');
      var user = $scope.getCurrentUser();
      if ($scope.getCurrentUser() !== null) {
        BrowserEventService.setUserId($scope.getCurrentUser().id);
      }
      if (zoneAdminData != null && zoneAdminData !== 'undefined') {
        $scope.zoneData = zoneAdminData;
        $scope.zoneBeanList = [];
        $scope.zoneBeanList = $scope.zoneBeanList.concat(
          $scope.zoneData.cfcZoneBeanList
        );
        $scope.zoneBeanListLimit = $scope.zoneBeanList.length;
        $scope.loadMoreZones = function() {
          if ($scope.zoneBeanListLimit <= $scope.zoneBeanList.length) {
            $scope.zoneBeanListLimit += 100;
          }
        };
      }
    }
    //ZoneAddCtrl controller is invoked to insert a Zone into Database.
  ])
  .controller('ZoneAddCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'fileUpload',
    'BrowserEventService',
    '$http',
    'ValidationUtil',
    function(
      $log,
      $state,
      $scope,
      $location,
      $rootScope,
      fileUpload,
      BrowserEventService,
      $http,
      ValidationUtil
    ) {
      $scope.setModuleName('CfcCharity.zoneadmin');
      $scope.zone = {};
      $scope.zone.status = true;

      var validateZoneInfo = function() {
        var response = { message: '', valid: true };
        $scope.fieldsMissing = [];
        if (!$scope.zone.zoneCode) {
          $scope.fieldsMissing.push('Zone Code is not entered.');
        }
        if (!$scope.zone.zoneName) {
          $scope.fieldsMissing.push('Zone Name is not entered.');
        }
        if ($scope.zone.zoneEmails) {
          var emails = $scope.zone.zoneEmails.split(';');
          var invalidEmails = [];

          for (i = 0; i < emails.length; i++) {
            if (!ValidationUtil.validateEmail(emails[i].trim())) {
              invalidEmails.push(emails[i].trim());
            }
          }

          if (invalidEmails.length > 0) {
            $scope.fieldsMissing.push('Invalid emails string');
          }
        }

        if ($scope.zone.zoneContactPhone) {
          if (!ValidationUtil.validatePhone($scope.zone.zoneContactPhone)) {
            $scope.fieldsMissing.push('Invalid phone number');
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          response.valid = false;
          $('#addZoneMissingInfo').modal();
        }
        return response;
      };

      $scope.addNewZone = function() {
        var userId = $scope.getCurrentUser().id;
        var validationResponse = validateZoneInfo();
        if (validationResponse.valid) {
          $http
            .post('/CfcCharity/api/private/users/addZone', {
              zoneName: $scope.zone.zoneName,
              zoneUrl: $scope.zone.zoneUrl,
              zoneEmails: $scope.zone.zoneEmails,
              zoneContactName: $scope.zone.zoneContactName,
              zoneContactPhone: $scope.zone.zoneContactPhone,
              zoneCode: $scope.zone.zoneCode,
              status: $scope.zone.status,
              createdBy: userId
            })
            .then(function(res) {
              var data = res.data;
              if (
                data.statusCode !== undefined &&
                data.statusCode === 'ERROR'
              ) {
                $scope.errorMsg = data.message;
              } else {
                $state.go('zoneadmin', {
                  userId: userId
                });
              }
            })
            .catch(function(err) {
              $scope.errorMsg = err.data.message;
            });
        } else {
          $scope.errorMsg = validationResponse.message;
        }
      };
    }
    //ZipcodeAddCtrl controller is invoked to insert a Zipcode into Database.
  ])
  .controller('ZipcodeAddCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    '$http',
    'CharityService',
    'ValidationService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      $http,
      CharityService,
      ValidationService
    ) {
      $scope.setModuleName('CfcCharity.zoneadmin');
      $scope.zoneId = $state.params.zoneId;
      $scope.zoneCode = $state.params.zoneCode;
      $scope.zoneName = $state.params.zoneName;
      $scope.pledgeYear = $state.params.pledgeYear;
      var user = $scope.getCurrentUser();
      var secondaryRoleList = user.secondaryRoleList;
      $scope.secondaryRoleList = secondaryRoleList;

      //get the states
      CharityService.loadStates().then(function(res) {
        $scope.states = res.data;
      });

      $scope.gotoZoneCenter = function(zoneId) {
        BrowserEventService.setZoneUserRole($scope.secondaryRoleList[0]);
        BrowserEventService.setZoneId(zoneId);
        $state.go('zoneCenter', {
          zoneId: zoneId,
          zoneUserRole: $scope.secondaryRoleList[0]
        });
      };

      var validateZipInfo = function() {
        var response = { message: '', valid: true };
        $scope.fieldsMissing = [];
        if (!$scope.zipCode) {
          $scope.fieldsMissing.push('5 digit zip code required.');
        }
        if (!$scope.city) {
          $scope.fieldsMissing.push('City is required.');
        }
        if (!$scope.state) {
          $scope.fieldsMissing.push('2 character state required.');
        }
        if (!$scope.county) {
          $scope.fieldsMissing.push('County is required.');
        }
        if (!$scope.country) {
          $scope.fieldsMissing.push('2 character country required.');
        }
        if (ValidationService.validateZip('Contact Zip', $scope.zipCode)) {
          $scope.fieldsMissing.push('Invalid Zip');
        }
        if ($scope.state) {
          var foundItem = false;
          angular.forEach($scope.states, function(st) {
            if (st.lookupCd.toLowerCase() === $scope.state.toLowerCase()) {
              foundItem = true;
            }
          });
          if (!foundItem) {
            $scope.fieldsMissing.push('Invalid state');
          }
        }
        if ($scope.country && $scope.country.length !== 2) {
          $scope.fieldsMissing.push('Invalid country');
        }
        if ($scope.fieldsMissing.length > 0) {
          response.valid = false;
          $('#addZipMissingInfo').modal();
        }
        return response;
      };

      $scope.addNewZipcode = function() {
        var userId = $scope.getCurrentUser().id;
        var validationResponse = validateZipInfo();
        if (validationResponse.valid) {
          $http
            .post('/CfcCharity/api/private/users/addZipcode', {
              zipCode: $scope.zipCode,
              city: $scope.city,
              state: $scope.state,
              county: $scope.county,
              country: $scope.country,
              createdBy: userId,
              zoneId: $scope.zoneId
            })
            .then(function(res) {
              var data = res.data;
              if (
                data.statusCode !== undefined &&
                data.statusCode === 'ERROR'
              ) {
                $scope.errorMsg = data.message;
              } else {
                $state.go('zipadmin', {
                  userId: userId,
                  zoneId: $scope.zoneId,
                  pledgeYear: $scope.pledgeYear
                });
              }
            })
            .catch(function(err) {
              /*Todo: Log error*/
            });
        } else {
        }
      };
    }
    //ZipcodeDeleteCtrl controller is invoked to Delete a Zipcode from Database.
  ])
  .controller('ZipZoneEditCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'zoneListData',
    'BrowserEventService',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      zoneListData,
      BrowserEventService,
      $http
    ) {
      $scope.setModuleName('CfcCharity.zoneadmin');
      if (zoneListData != null && zoneListData !== 'undefined') {
        $scope.zipZoneListData = zoneListData;
      }
      var zone = {};
      zone.selectedZone = '';
      $scope.zone = zone;
      $scope.zoneId = $state.params.zoneId;
      $scope.zipcode = $state.params.zipCode;
      $scope.zoneCode = $state.params.zoneCode;
      $scope.zoneName = $state.params.zoneName;
      $scope.pledgeYear = $state.params.pledgeYear;

      $scope.editZipZone = function() {
        var userId = $scope.getCurrentUser().id;
        var zipcodeId = $state.params.zipcodeId;
        var selectedZone = $scope.zone.selectedZone;
        var url =
          '/CfcCharity/api/private/users/editZipcodeZone/' + $scope.pledgeYear;
        $http
          .post(url, {
            createdBy: userId,
            zoneId: $scope.zoneId,
            zipcodeId: zipcodeId,
            selectedZoneId: selectedZone
          })
          .then(function(res) {
            $state.go('zipadmin', {
              userId: userId,
              zoneId: $scope.zoneId,
              pledgeYear: $scope.pledgeYear
            });
          })
          .catch(function(err) {
            /*Todo: Log error*/
          });
      };
    }
    //ZoneEditCtrl controller is invoked to edit an existing Zone.
  ])
  .controller('ZoneEditCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    '$http',
    'ValidationUtil',
    'zoneAdminPerCampaignData',
    'DownloadService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      $http,
      ValidationUtil,
      zoneAdminPerCampaignData,
      DownloadService
    ) {
      $scope.setModuleName('CfcCharity.zoneadmin');
      $scope.editZoneData = {};
      $scope.editZoneData.zoneCode = $state.params.zoneCode;
      $scope.editZoneData.zoneName = $state.params.zoneName;
      $scope.editZoneData.status = $state.params.status;
      $scope.editZoneData.zoneId = $state.params.zoneId;
      $scope.editZoneData.prepopulate = $state.params.prepopulate;
      $scope.editZoneData.zoneUrl = $state.params.zoneUrl;
      $scope.editZoneData.zoneEmails = $state.params.zoneEmails;
      $scope.editZoneData.zoneContactName = $state.params.zoneContactName;
      $scope.editZoneData.zoneContactPhone = $state.params.zoneContactPhone;

      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
      $scope.secondaryRoleList = secondaryRoleList;
      $scope.campaignZoneData = zoneAdminPerCampaignData;
      $scope.campaignZoneBeanListLimit =
        $scope.campaignZoneData === undefined
          ? 0
          : $scope.campaignZoneData.length;

      if (
        $scope.editZoneData != null &&
        $scope.editZoneData.status === 'ACTIVE'
      ) {
        $scope.status = true;
      } else {
        $scope.status = false;
      }
      $scope.editZoneData.status = $scope.status;
      var oldZoneCode = $state.params.zoneCode;

      var validateZoneInfo = function() {
        var response = { message: '', valid: true };
        if ($scope.editZoneData.zoneEmails) {
          var emails = $scope.editZoneData.zoneEmails.split(';');
          var invalidEmails = [];

          for (i = 0; i < emails.length; i++) {
            if (!ValidationUtil.validateEmail(emails[i].trim())) {
              invalidEmails.push(emails[i].trim());
            }
          }

          if (invalidEmails.length > 0) {
            response.valid = false;
            response.message = 'Invalid emails string';
          }
        }

        if ($scope.editZoneData.zoneContactPhone) {
          if (
            !ValidationUtil.validatePhone($scope.editZoneData.zoneContactPhone)
          ) {
            response.valid = false;
            response.message = 'Invalid phone number';
          }
        }

        return response;
      };
      $scope.gotoZoneCenter = function(zoneId) {
        BrowserEventService.setZoneUserRole($scope.secondaryRoleList[0]);
        BrowserEventService.setZoneId(zoneId);
        $state.go('zoneCenter', {
          zoneId: zoneId,
          zoneUserRole: $scope.secondaryRoleList[0]
        });
      };

      $scope.updateCampaignZone = function(pledgeYear, zoneId, active) {
        var userId = $scope.getCurrentUser().id;
        var url =
          '/CfcCharity/api/private/users/editCampaignZone/' +
          pledgeYear +
          '/' +
          zoneId +
          '/' +
          active;

        $http
          .post(url)
          .then(function(res) {
            var data = res.data;
            if (data.statusCode !== undefined && data.statusCode === 'ERROR') {
              $scope.errorMsg = data.message;
            } else {
              //after service call success ..update data for display.
              angular.forEach($scope.campaignZoneData, function(czData) {
                if (czData.pledgeYear === pledgeYear) {
                  czData.active = active;
                }
              });
            }
          })
          .catch(function(err) {
            $scope.errorMsg =
              'Something went wrong while saving the campaign zone changes, please try again.';
          });
      };

      $scope.downloadZipcodeFile = function(pledgeYear) {
        var url =
          '/CfcCharity/api/private/admin/zone/downloadZipcodeFile/' +
          pledgeYear +
          '/' +
          $scope.editZoneData.zoneId;
        DownloadService.downloadFile(url);
      };

      $scope.editZone = function() {
        var userId = $scope.getCurrentUser().id;
        var validationResponse = validateZoneInfo();

        if (validationResponse.valid) {
          $http
            .post('/CfcCharity/api/private/users/editZone', {
              zoneId: $scope.editZoneData.zoneId,
              zoneName: $scope.editZoneData.zoneName,
              oldZoneCode: oldZoneCode,
              zoneCode: $scope.editZoneData.zoneCode,
              zoneUrl: $scope.editZoneData.zoneUrl,
              zoneEmails: $scope.editZoneData.zoneEmails,
              zoneContactName: $scope.editZoneData.zoneContactName,
              zoneContactPhone: $scope.editZoneData.zoneContactPhone,
              status: $scope.editZoneData.status,
              createdBy: userId
            })
            .then(function(res) {
              var data = res.data;
              if (
                data.statusCode !== undefined &&
                data.statusCode === 'ERROR'
              ) {
                $scope.errorMsg = data.message;
              } else {
                $state.go('zoneadmin', { userId: userId });
              }
            })
            .catch(function(err) {
              $scope.errorMsg =
                'Something went wrong while saving the zone changes, please try again.';
            });
        } else {
          $scope.errorMsg = validationResponse.message;
        }
      };
    }
    //ZipcodeAdminCtrl controller is invoked to edit an existing Zipcode.
  ])
  .controller('ZipSearchCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    '$http',
    'zipSearchData',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      $http,
      zipSearchData
    ) {
      $scope.setModuleName('CfcCharity.zoneadmin');
      $scope.searchZip = zipSearchData;
      $scope.searchBeanListLimit = $scope.searchZip.length;
      $scope.zipSearchValue = null;
      $scope.zipcode = $state.params.zipCode;
    }
  ])
  .controller('ZipcodeAdminCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'zipAdminData',
    'fileUpload',
    'BrowserEventService',
    'DownloadService',
    '$http',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      zipAdminData,
      fileUpload,
      BrowserEventService,
      DownloadService,
      $http
    ) {
      $scope.setModuleName('CfcCharity.zoneadmin');
      $scope.zipSearchValue = null;
      $scope.successMsg = [];
      var user = $scope.getCurrentUser();
      var secondaryRoleList = user.secondaryRoleList;
      $scope.secondaryRoleList = secondaryRoleList;
      $scope.pledgeYear = $state.params.pledgeYear;

      if (zipAdminData != null && zipAdminData !== 'undefined') {
        BrowserEventService.setZoneId(zipAdminData.zoneId);
        $scope.zipData = zipAdminData;
        $scope.zipBeanList = [];
        $scope.zipBeanList = $scope.zipBeanList.concat(
          $scope.zipData.cfcZipcodeBeanList
        );
        $scope.zipcodeBeanListLimit = $scope.zipBeanList.length;
        $scope.loadMoreZipcodes = function() {
          if ($scope.zipcodeBeanListLimit <= $scope.zipBeanList.length) {
            $scope.zipcodeBeanListLimit += 100;
          }
        };

        $scope.gotoZoneCenter = function(zoneId) {
          BrowserEventService.setZoneUserRole($scope.secondaryRoleList[0]);
          BrowserEventService.setZoneId(zoneId);
          $state.go('zoneCenter', {
            zoneId: zoneId,
            zoneUserRole: $scope.secondaryRoleList[0]
          });
        };

        $scope.getZipcode = function(zipcode) {
          $scope.successMsg = [];
          $scope.zipBeanList = [];
          $scope.errorMsg = '';
          angular.forEach($scope.zipData.cfcZipcodeBeanList, function(czData) {
            if (czData.zipCode === zipcode) {
              $scope.zipBeanList.push(czData);
              $scope.zipcodeBeanListLimit = $scope.zipBeanList.length;
            }
          });
          if ($scope.zipBeanList.length == 0) {
            $scope.errorMsg =
              'No zipcode found in this zone with the search value.';
          }
        };

        $scope.showAll = function() {
          $scope.successMsg = [];
          $scope.zipSearchValue = '';
          $scope.zipBeanList = [];
          $scope.zipBeanList = $scope.zipBeanList.concat(
            $scope.zipData.cfcZipcodeBeanList
          );
          $scope.zipcodeBeanListLimit = $scope.zipBeanList.length;
          $scope.errorMsg = '';
        };

        $scope.getModal = function(zipcode, zipcodeId) {
          $scope.successMsg = [];
          $scope.selecteZip = zipcode;
          $scope.selectedZipId = zipcodeId;
          $('#deleteSingle').modal();
        };

        $scope.deleteSingleZipcode = function() {
          var userId = $scope.getCurrentUser().id;
          var zipcodeId = $scope.selectedZipId;
          var zoneId = $state.params.zoneId;
          var url =
            '/CfcCharity/api/private/users/deleteZipcode/' + $scope.pledgeYear;
          $http
            .post(url, {
              zipcodeId: zipcodeId,
              createdBy: userId
            })
            .then(function(res) {
              //after service call success ..update data list for display.
              $scope.zipBeanList = [];
              $scope.errorMsg = '';
              var index = null;
              angular.forEach($scope.zipData.cfcZipcodeBeanList, function(
                czData,
                ind
              ) {
                if (czData.zipcodeId === zipcodeId) {
                  index = ind;
                }
                if (czData.zipcodeId !== zipcodeId) {
                  $scope.zipBeanList.push(czData);
                }
              });
              $scope.zipData.cfcZipcodeBeanList.splice(index, 1);
              $scope.zipcodeBeanListLimit = $scope.zipBeanList.length;
            })
            .catch(function(err) {
              $scope.errorMsg =
                'Something went wrong while deleting this zipcode, please try again.';
            });
        };

        $scope.deleteAllZipcodes = function() {
          $scope.successMsg = [];
          var zoneId = $state.params.zoneId;
          var pledgeYear = $state.params.pledgeYear;
          var url =
            '/CfcCharity/api/private/zone/deletezipcodes/' +
            zoneId +
            '/' +
            pledgeYear;
          $http
            .post(url)
            .then(function(res) {
              var data = res.data;
              if (
                data.statusCode !== undefined &&
                data.statusCode === 'ERROR'
              ) {
                $scope.errorMsg = data.message;
              } else {
                $scope.zipBeanList = [];
                $scope.errorMsg = '';
                $scope.zipcodeBeanListLimit = $scope.zipBeanList.length;
              }
            })
            .catch(function(err) {
              $scope.errorMsg =
                'Something went wrong while deleting all zipcodes of this zone for the given campaign, please try again.';
            });
        };

        $scope.uploadZipCodesList = function() {
          $scope.successMsg = [];
          $scope.errorMsg = '';
          var userId = $scope.getCurrentUser().id;
          var zoneId = $state.params.zoneId;
          if ($scope.zipcode.supportingFile != null) {
            fileUpload
              .createFileData(
                '/CfcCharity/api/private/users/' +
                  $scope.pledgeYear +
                  '/' +
                  zoneId +
                  '/' +
                  '/zipcodeFile',
                $scope.zipcode
              )
              .then(function(res) {
                var data = res.data;
                $scope.zipData = data;
                if (
                  data.status.statusCode !== undefined &&
                  data.status.statusCode === 'ERROR'
                ) {
                  $scope.errorMsg = data.status.message;
                  $('#zipcodeList').val('');
                  $scope.zipcode.supportingFile = null;
                } else {
                  $('#zipcodeList').val('');
                  $scope.zipcode.supportingFile = null;
                  $scope.zipBeanList = [];
                  angular.forEach($scope.zipData.cfcZipcodeBeanList, function(
                    czData
                  ) {
                    $scope.zipBeanList.push(czData);
                  });
                  $scope.zipcodeBeanListLimit = $scope.zipBeanList.length;
                  //add the success message
                  var msg = data.status.message.split('<br>');
                  $scope.successMsg = msg;
                }
              })
              .catch(function(err) {
                /*Todo: Log error*/
              });
          }
        };
      }

      $scope.downloadTemplate = function() {
        return DownloadService.getFileDownload(
          '/CfcCharity/api/private/admin/zone/downloadZipcodeUploadTemplate'
        );
      };
    }
  ])
  .directive('httpPrefix', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, controller) {
        function ensureHttpPrefix(value) {
          // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
          if (
            value &&
            !/^(https?):\/\//i.test(value) &&
            'http://'.indexOf(value) !== 0 &&
            'https://'.indexOf(value) !== 0
          ) {
            controller.$setViewValue('https://' + value);
            controller.$render();
            return 'https://' + value;
          } else return value;
        }
        controller.$formatters.push(ensureHttpPrefix);
        controller.$parsers.splice(0, 0, ensureHttpPrefix);
      }
    };
  });
