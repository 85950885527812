// donations.module.js - Module declaration for Admin Zone Donations area

'use strict';

angular
  .module('CfcCharity.admin.zone.pledges', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    config
  ]);

function config(
  $stateProvider,
  $urlRouterProvider,
  HateoasInterceptorProvider,
  USER_ROLES,
  SYSTEM_USER_SECONDARY_ROLES
) {
  $stateProvider.state('admin-zone-pledges-overview', {
    url: '/admin-zone-pledges-overview',
    views: {
      main: {
        template: require('./overview/overview.html'),
        controller: 'AdminZonePledgesOverviewCtl'
      }
    },
    data: {
      pageTitle: 'Admin Zone Pledges Overview',
      requireAuthentication: true,
      authorizedRoles: [USER_ROLES.system_user],
      authorizedSecondaryRoles: [SYSTEM_USER_SECONDARY_ROLES.all]
    },
    params: {
      zoneId: null,
      pledgeYear: null,
      zoneUserRole: null,
      campaignId: null,
      zoneCode: null,
      zoneName: null
    },
    resolve: {
      parameterData: function($http, $stateParams) {
        // TODO: Handle the route from /administrationcenter
        if ($stateParams.zoneId && $stateParams.pledgeYear) {
          var data = {};
          data.zoneId = $stateParams.zoneId;
          data.pledgeYear = $stateParams.pledgeYear;
          data.zoneUserRole = $stateParams.zoneUserRole;
          data.campaignId = $stateParams.campaignId;
          data.zoneCode = $stateParams.zoneCode;
          data.zoneName = $stateParams.zoneName;
          return data;
        }
      }
    }
  });
}
