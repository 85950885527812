angular
  .module('CfcCharity.loginredirect', [
    'ui.router',
    'CfcCharity.security',
    'ngMessages',
    'ngIdle',
    'hateoas'
    // '$locationProvider'
  ])
  .config([
    '$stateProvider',
    function myAppConfig($stateProvider) {
      $stateProvider.state('loginRedirect', {
        url: '/loginRedirect',
        views: {
          main: {
            template: require('../views/public/loginRedirect.html'),
            controller: 'LoginRedirectCtrl'
          }
        },
        data: {
          pageTitle: 'Login Redirect'
        }
      });
    }
  ])
  .controller('LoginRedirectCtrl', [
    '$location',
    'AppService',
    'AuthService',
    'TokenStorage',
    'UserService',
    'SYSTEM_USER_SECONDARY_ROLES',
    '$state',
    '$scope',
    async function(
      $location,
      AppService,
      AuthService,
      TokenStorage,
      UserService,
      SYSTEM_USER_SECONDARY_ROLES,
      $state,
      $scope
    ) {
      postLoginUserSetup = async function() {
        try {
          const authedUser = await AppService.getAuth().currentAuthenticatedUser();
          const token = authedUser
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken();

          TokenStorage.store(token);

          authenticate();
        } catch (err) {
          console.log(err);
        }
      };

      async function authenticate() {
        AuthService.getAuthenticatedUser(async function(user) {
          if (AuthService.isAuthenticated) {
            AuthService.acceptSystemRoles(async function(res) {
              if (!res) {
                $scope.loginErrors = 'Something went wrong. Please try again.';
                return;
              }

              $scope.setCurrentUser(res.user);
              await AuthService.startSession();

              if (UserService.isSystemUser()) {
                if (UserService.isCharityUser()) {
                  $scope.setAdminUser(false);
                  $scope.setAdminAndCharityUser(true);
                } else {
                  $scope.setAdminUser(true);
                  $scope.setAdminAndCharityUser(false);
                }
                setScopeParams();
                if (user.firstName === ' ') {
                  $scope.setModuleName('CfcCharity.updateuserinfo');
                  $state.go('updateuserinfo');
                } else if (
                  UserService.hasSystemRole([
                    SYSTEM_USER_SECONDARY_ROLES.opm_admin,
                    SYSTEM_USER_SECONDARY_ROLES.opm_staff,
                    SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
                    SYSTEM_USER_SECONDARY_ROLES.zone_manager,
                    SYSTEM_USER_SECONDARY_ROLES.reviewer, // zone
                    SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
                    SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1,
                    SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2,
                    SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
                    SYSTEM_USER_SECONDARY_ROLES.gb_finance,
                    SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
                  ])
                ) {
                  $scope.setModuleName('CfcCharity.administrationcenter');
                  $state.go('administrationcenter', {
                    userId: user.id
                  });
                } else if (
                  UserService.hasSystemRole([
                    SYSTEM_USER_SECONDARY_ROLES.national_campaign_manager
                  ])
                ) {
                  $scope.navigateToReports();
                } else {
                  $scope.setLeftSideNavbarMenuItemSelected('userhomepage');
                  $scope.setModuleName('CfcCharity.userdashboard');
                  $state.go('userDashboard', {
                    userId: user.id
                  });
                }
              } else if (UserService.isCharityUser()) {
                $scope.setAdminAndCharityUser(false);
                $scope.setAdminUser(false);
                setScopeParams();
                if (user.firstName === ' ') {
                  $scope.setModuleName('CfcCharity.updateuserinfo');
                  $state.go('updateuserinfo');
                } else {
                  $scope.setModuleName('CfcCharity.userdashboard');
                  $state.go('userDashboard', {
                    userId: user.id
                  });
                }
              } else {
                $scope.loginErrors =
                  'Your access roles have not been assigned. Contact the administrator.';
              }
            });
          } else {
            $state.path('login');
            TokenStorage.clear();
          }
        });
      }

      function setScopeParams() {
        var scopeParams = {
          adminUser: $scope.isAdminUser(),
          adminAndCharityUser: $scope.isAdminAndCharityUser()
        };
        sessionStorage.setItem('SCOPE_PARAMS', JSON.stringify(scopeParams));
      }

      AppService.initializeAmplify(postLoginUserSetup);
    }
  ]);
