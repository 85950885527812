(function() {
  var moduleName = 'CfcCharity.directives.areaOfServiceReview';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcAreaOfServiceReview', [
    'AreaOfServiceReviewService',
    '$q',
    '$log',
    'ApplicationService',
    'ApplicationDashboardService',
    'applicationTypes',
    'AdminReviewSectionService',
    'reviewLevelConstants',
    'AdminReviewSectionStepDeterminationService',
    'AdminReviewSectionStepDeterminationSessionService',
    '$state',
    'BrowserEventService',
    'CubeSvc',
    function(
      AreaOfServiceReviewService,
      $q,
      $log,
      ApplicationService,
      ApplicationDashboardService,
      applicationTypes,
      AdminReviewSectionService,
      reviewLevelConstants,
      AdminReviewSectionStepDeterminationService,
      AdminReviewSectionStepDeterminationSessionService,
      $state,
      BrowserEventService,
      CubeSvc
    ) {
      return {
        restrict: 'E',
        template: require('./areaOfServiceReview.html'),
        scope: {
          applicationId: '@',
          step4DetailId: '@'
        },
        controller: function($scope, $rootScope, CakeSvc) {
          $scope.disableNextAOSBtn = false;
          $scope.currentAOS = 1;
          if ($scope.$parent.appNavbar.step) {
            $scope.appNavbar = $scope.$parent.appNavbar;
            $scope.appNavbar.step = $scope.appNavbar.step + ' AOS';
          }
          if ($scope.$parent.federationNavbar.step) {
            $scope.federationNavbar = $scope.$parent.federationNavbar;
            $scope.federationNavbar.step =
              $scope.federationNavbar.step + ' AOS';
          }
          $scope.getCurrentUser = $scope.$parent.getCurrentUser;
          $scope.aosAttachmentId = $scope.step4DetailId;
          $scope.isSecondaryRoleOPMAdmin =
            $scope.$parent.isSecondaryRoleOPMAdmin;
          function init() {
            $scope.isOpen = false;
            AreaOfServiceReviewService.getApplicationStep2($scope.applicationId)
              .then(function(res) {
                var data = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  res.data.value
                );
                $scope.applicationType = data.applicationType;
                $scope.charity_type = data.charityType;

                const nationalInternationalCharityTypes = [
                  'NATIONAL',
                  'INTERNATIONAL'
                ];
                const nationalInternationalCombinedLabel =
                  'National-International';

                $scope.appType = data.charityType;

                if (
                  nationalInternationalCharityTypes.includes(data.charityType)
                ) {
                  $scope.appType = nationalInternationalCombinedLabel;
                }

                setReviewLevelsAccess(data.charityType);
                $scope.fysaFyspWfr = data.fysaFyspWfr;
                return;
              })
              .then(function() {
                return AreaOfServiceReviewService.getAppReviewPermission(
                  $scope.applicationId,
                  $scope.applicationType
                );
              })
              .then(function(res) {
                $scope.editClaim = res.data.value === 'EDIT';
                $scope.viewClaim = res.data.value === 'VIEW';
                if (!$scope.editClaim && !$scope.viewClaim) {
                  return $q.reject();
                }
                // authorized so proceed
                return AreaOfServiceReviewService.getDeterminationCodes();
              })
              .then(function(res) {
                $scope.determinationCodes = res.data;
                return AreaOfServiceReviewService.getStep4ReviewDetail(
                  $scope.applicationId,
                  $scope.step4DetailId
                );
              })
              .then(function(res) {
                $scope.areaOfServiceReview = res.data.value;
                setCurrentReview();
                $scope.applicationId = ApplicationService.getApplicationId();
                $scope.app = ApplicationService.getApplication();
                $scope.parentFedAppId = $scope.app.federationNumber;
                $scope.mode = BrowserEventService.mode;
                ApplicationDashboardService.applicationDetailsById(
                  $scope.applicationId
                ).then(function(resp) {
                  var res = resp.data.value;
                  $scope.cfcCode = res.cfcCode;
                  $scope.pledgeYear = res.pledgeYear;
                  $scope.federationId = res.federationNumber;
                  $scope.federationCfc = res.federationCfcCode;
                  if ($scope.federationId != null) {
                    $scope.applicationType = applicationTypes.Member;
                  } else {
                    $scope.applicationType = applicationTypes.Independent;
                  }
                });
                getZoneDetails();
                var url =
                  '/CfcCharity/api/private/application/step4/details/AOS/' +
                  $scope.applicationId;
                CubeSvc.get(url).then(function(res) {
                  $scope.services = res.data.value;
                  angular.forEach($scope.services, function(service) {
                    if (
                      service.id.toString() === $scope.step4DetailId.toString()
                    ) {
                      $scope.currentAOS = service.aosNumber;
                      if (service.last) {
                        $scope.disableNextAOSBtn = true;
                      } else {
                        $scope.disableNextAOSBtn = false;
                      }
                    }
                  });
                });
              })
              .catch(function() {});
          }

          $scope.findAOS = function(aosNo) {
            angular.forEach($scope.services, function(service) {
              if (service.aosNumber === aosNo) {
                $scope.currentAOS = service.aosNumber;
                $scope.step4DetailId = service.id;
              }
            });
          };

          $scope.goToPriorAOS = function(aos) {
            var newAOS = aos - 1;
            $scope.findAOS(newAOS);
            if (newAOS != 0) {
              if ($scope.charity_type === 'LOCAL') {
                $state.go('step4LocalAttachment', {
                  id: $scope.step4DetailId,
                  parentFedAppId: $scope.federationId
                });
              } else {
                $state.go('step4NationalAttachment', {
                  id: $scope.step4DetailId,
                  parentFedAppId: $scope.federationId
                });
              }
            }
          };

          $scope.goToNextAOS = function(aos) {
            var newAOS = aos + 1;
            $scope.findAOS(newAOS);
            if ($scope.charity_type === 'LOCAL') {
              $state.go('step4LocalAttachment', {
                id: $scope.step4DetailId,
                parentFedAppId: $scope.federationId
              });
            } else {
              $state.go('step4NationalAttachment', {
                id: $scope.step4DetailId,
                parentFedAppId: $scope.federationId
              });
            }
          };

          $scope.getDeterminationCode = function(id) {
            if (!id) {
              return {
                lookupName: 'Not Set',
                auxiliary: 'fa fa-circle-o fa-lg text-grey'
              };
            }
            return $scope.determinationCodes.find(function(determinationCode) {
              return determinationCode.id === id;
            });
          };

          function getZoneDetails() {
            if (
              AdminReviewSectionStepDeterminationService.isAuthorized() === true
            ) {
              AdminReviewSectionService.zoneDetails($scope.applicationId).then(
                function(result) {
                  $scope.zoneId = result.zoneId;
                  $scope.zoneCode = result.zoneCode;
                }
              );
            }
          }

          $scope.goToRD = function() {
            $state.go('charityAuditDashboard', {
              applicationId: $scope.applicationId
            });
            return;
          };

          $scope.goToZoneApplications = function() {
            $state.go('zoneApplications', {
              zoneId: $scope.zoneId,
              pledgeYear: $scope.pledgeYear
            });
          };

          $scope.goToStep4 = function() {
            $state.go('step4', {
              mode: $scope.mode,
              parentFedAppId: $scope.parentFedAppId
            });
            return;
          };

          $scope.goToDenialReasonsPage = function() {
            // review-denial-reasons
            $state.go('review-denial-reasons', {
              isFederation: false,
              applicationId: $scope.applicationId,
              sourcePageName: $state.current.name,
              sourcePageParams: $state,
              charityType: $scope.charity_type
            });
          };

          $scope.goToFederationAppPackage = function() {
            // review-denial-reasons
            $state.go('federationapplication', {
              year: $scope.pledgeYear,
              cfcCode: $scope.federationCfc,
              fedAppId: $scope.federationId
            });
          };

          $scope.goToMemberApplications = function() {
            // review-denial-reasons
            $state.go('memberapplication', {
              userId: $scope.userId,
              year: $scope.pledgeYear,
              cfcCode: $scope.federationCfc
            });
          };

          $scope.goToReviewLevelApplications = function() {
            switch ($scope.reviewLevel) {
              case 'Review Completeness':
                $state.go('zonecompletenessreview', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              case 'Staff Eligibility':
                $state.go('zonereviewlevel1', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              case 'Preliminary Eligibility':
                $state.go('zonereviewlevel2', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              case 'Final Eligibility':
                $state.go('zonereviewlevel3', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
              default:
                $state.go('zonecompletenessreview', {
                  pledgeYear: $scope.pledgeYear,
                  zoneId: $scope.zoneId
                });
                break;
            }
          };

          $scope.goToReviewLevel = function() {
            switch ($scope.reviewLevel) {
              case 'Review Completeness':
                $state.go('reviewCompleteness', {
                  applicationId: $scope.applicationId
                });
                return;
                break;
              case 'Staff Eligibility':
                $state.go('staffEligibility', {
                  applicationId: $scope.applicationId
                });
                return;

                break;
              case 'Preliminary Eligibility':
                $state.go('preliminaryEligibility', {
                  applicationId: $scope.applicationId
                });
                return;
                break;
              case 'Final Eligibility':
                $state.go('finalEligibility', {
                  applicationId: $scope.applicationId
                });
                return;
                break;
              default:
                $state.go('reviewCompleteness', {
                  applicationId: $scope.applicationId
                });
                return;
            }
          };

          $scope.saveReview = function(determinationCode) {
            var saveReview = {
              applicationId: $scope.applicationId,
              step4DetailId: $scope.step4DetailId,
              reviewLevel: $scope.areaOfServiceReview.currentReviewLevel,
              reviewValue: determinationCode.id
            };

            AreaOfServiceReviewService.saveStep4ReviewDetail(saveReview)
              .then(function(res) {
                var data = res.data.value;
                if (data && res.data.message.type !== 'SUCCESS') {
                  return;
                }
                $scope.areaOfServiceReview = data;
                setCurrentReview();
                $scope.$broadcast('aosSaveReview', determinationCode);
              })
              .catch(function(err) {
                $log.error(
                  err.data || 'Request failed with status ' + err.status,
                  err
                );
              });
          };

          function setCurrentReview() {
            currentReviewLevelOverrideMap();

            $scope.currentReview = {
              level: setCurrentReviewLevelDisplayTest(
                $scope.areaOfServiceReview.currentReviewLevel
              ),
              status: null,
              statusIcon: null,
              setByInitial: null,
              setById: null
            };

            switch (
              $scope.areaOfServiceReview.currentReviewLevel.toLowerCase()
            ) {
              case 'Completeness'.toLowerCase():
                var determinationCode = $scope.getDeterminationCode(
                  $scope.areaOfServiceReview.completenessReviewStatus
                );
                $scope.currentReview.status = determinationCode.lookupName;
                $scope.currentReview.statusIcon = determinationCode.auxiliary;
                $scope.currentReview.setByInitial =
                  $scope.areaOfServiceReview.completenessStatusSetByInitial;
                $scope.currentReview.setById =
                  $scope.areaOfServiceReview.completenessStatusSetBy;
                break;
              case 'Preliminary'.toLowerCase():
                var determinationCode = $scope.getDeterminationCode(
                  $scope.areaOfServiceReview.preliminaryReviewStatus
                );
                $scope.currentReview.status = determinationCode.lookupName;
                $scope.currentReview.statusIcon = determinationCode.auxiliary;
                $scope.currentReview.setByInitial =
                  $scope.areaOfServiceReview.preliminaryStatusSetByInitial;
                $scope.currentReview.setById =
                  $scope.areaOfServiceReview.preliminaryStatusSetBy;
                break;
              case 'Staff'.toLowerCase():
                var determinationCode = $scope.getDeterminationCode(
                  $scope.areaOfServiceReview.staffReviewStatus
                );
                $scope.currentReview.status = determinationCode.lookupName;
                $scope.currentReview.statusIcon = determinationCode.auxiliary;
                $scope.currentReview.setByInitial =
                  $scope.areaOfServiceReview.staffStatusSetByInitial;
                $scope.currentReview.setById =
                  $scope.areaOfServiceReview.staffStatusSetBy;
                break;
              case 'Final'.toLowerCase():
                var determinationCode = $scope.getDeterminationCode(
                  $scope.areaOfServiceReview.finalReviewStatus
                );
                $scope.currentReview.status = determinationCode.lookupName;
                $scope.currentReview.statusIcon = determinationCode.auxiliary;
                $scope.currentReview.setByInitial =
                  $scope.areaOfServiceReview.finalStatusSetByInitial;
                $scope.currentReview.setById =
                  $scope.areaOfServiceReview.finalStatusSetBy;
                break;
              default:
                var notSetCode = $scope.determinationCodes.find(function(
                  determinationCode
                ) {
                  return determinationCode.lookupCd === 'NOT_SET';
                });
                // initialize current level to Completeness when reviewing for the first time
                // current review level will be null
                $scope.areaOfServiceReview.currentReviewLevel = 'Completeness';
                $scope.currentReview.level = 'Completeness';
                $scope.currentReview.status = notSetCode.lookupName;
                $scope.currentReview.statusIcon = notSetCode.auxiliary;
            }

            if ($scope.editClaim || $scope.viewClaim) {
              $rootScope.$broadcast(
                'areaOfServiceReviewStatusUpdate',
                $scope.currentReview
              );
            }
          }

          function setCurrentReviewLevelDisplayTest(level) {
            switch (level.toLowerCase()) {
              case 'Completeness'.toLowerCase():
                return level;
              default:
                return level + ' Eligibility';
            }
          }

          function setReviewLevelsAccess(charityType) {
            $scope.showStaff =
              charityType === 'NATIONAL' || charityType === 'INTERNATIONAL';
          }

          function currentReviewLevelOverrideMap() {
            // Override the application's current review level with reviewer's manually selection.
            var sessionReviewLevel = AdminReviewSectionStepDeterminationSessionService.getCurrentReviewLevel();
            switch (sessionReviewLevel) {
              case reviewLevelConstants.Completeness:
                $scope.areaOfServiceReview.currentReviewLevel = 'Completeness';
                break;
              case reviewLevelConstants.StaffEligibility:
                $scope.areaOfServiceReview.currentReviewLevel = 'Staff';
                break;
              case reviewLevelConstants.PreliminaryEligibility:
                $scope.areaOfServiceReview.currentReviewLevel = 'Preliminary';
                break;
              case reviewLevelConstants.FinalEligibility:
                $scope.areaOfServiceReview.currentReviewLevel = 'Final';
                break;
            }
          }

          init();
        }
      };
    }
  ]);
})();
