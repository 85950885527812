(function() {
  var moduleName = 'CfcCharity.directives.adminReviewComments';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcAdminReviewComments', [
    'ZoneCenterService',
    'UserService',
    'SYSTEM_USER_SECONDARY_ROLES',
    'FederationApplicationReviewService',
    '$http',
    'CubeSvc',
    function(
      ZoneCenterService,
      UserService,
      SYSTEM_USER_SECONDARY_ROLES,
      FederationApplicationReviewService,
      $http,
      CubeSvc
    ) {
      return {
        restrict: 'E',
        template: require('./adminReviewComments.html'),
        scope: {
          applicationId: '@'
        },
        controller: function($scope) {
          $scope.commentLimit = 2;

          function init() {
            if (
              UserService.hasSystemRole([
                SYSTEM_USER_SECONDARY_ROLES.opm_admin,
                SYSTEM_USER_SECONDARY_ROLES.opm_staff,
                SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
                SYSTEM_USER_SECONDARY_ROLES.zone_manager,
                SYSTEM_USER_SECONDARY_ROLES.reviewer // zone
              ])
            ) {
              $scope.canEditComments = true;
              if (
                UserService.hasSystemRole([
                  SYSTEM_USER_SECONDARY_ROLES.opm_auditor
                ])
              ) {
                $scope.canEditComments = false;
              }
              if ($scope.$parent.applicationType === 'Federation') {
                FederationApplicationReviewService.getFederationReviewDetails(
                  $scope.applicationId
                ).then(function(res) {
                  var federationApplicationReviewResponse = res.data;
                  $scope.federationApplicationReviewData =
                    federationApplicationReviewResponse.federationApplicationReviewBean;
                  $scope.reviewLogs =
                    $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList;
                  if ($scope.applicationId != null) {
                    url =
                      '/CfcCharity/api/private/federation/' +
                      $scope.applicationId;
                    CubeSvc.get(url).then(function(res) {
                      $scope.result = res.data.value;
                      $scope.appStatus = $scope.result.applicationStatus;
                    });
                  }
                });
              } else {
                ZoneCenterService.getApplicationReviewLogs(
                  $scope.applicationId
                ).then(function(res) {
                  var reviewLogs = res.data.value;
                  $scope.reviewLogs = reviewLogs;
                  $scope.appStatus = $scope.$parent.appStatus;
                });
              }
            }
          }

          $scope.addReviewComment = function(comment) {
            $scope.addReviewerCommentErrors = [];
            if (
              !comment ||
              ((comment && comment.trim().length < 1) || comment.length > 2000)
            ) {
              $scope.addReviewerCommentErrors.push(
                'Please enter a comment between 1 and 2000 characters.'
              );
            } else if (
              comment &&
              (comment.length > 0 && comment.length <= 2000)
            ) {
              if ($scope.$parent.applicationType === 'Federation') {
                FederationApplicationReviewService.addReviewerComment(
                  $scope.applicationId,
                  $scope.federationApplicationReviewData.zoneIdForReview,
                  comment
                ).then(function(res) {
                  var federationApplicationReviewResponse = res.data;
                  if (
                    federationApplicationReviewResponse &&
                    (federationApplicationReviewResponse.error ||
                      federationApplicationReviewResponse.statusCode ===
                        'ERROR')
                  ) {
                    $scope.setAddReviewerCommentErrorMessage(
                      federationApplicationReviewResponse.errorResponse
                        .errorCode +
                        ' : ' +
                        federationApplicationReviewResponse.errorResponse
                          .errorMessage +
                        '. Please try later!'
                    );
                  } else if (
                    federationApplicationReviewResponse &&
                    federationApplicationReviewResponse.statusCode === 'SUCCESS'
                  ) {
                    $scope.reviewLogs =
                      federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                    $('#comment').modal('toggle');
                    $scope.comments = '';
                  }
                });
              } else {
                ZoneCenterService.addReviewComment(
                  $scope.applicationId,
                  comment
                ).then(function() {
                  $scope.comments = '';
                  $scope.addReviewerCommentErrors = [];
                  $('#comment').modal('toggle');
                  ZoneCenterService.getApplicationReviewLogs(
                    $scope.applicationId
                  ).then(function(res) {
                    var reviewLogs = res.data.value;
                    $scope.reviewLogs = reviewLogs;
                  });
                });
              }
            }

            $scope.setAddReviewerCommentErrorMessage = function(error) {
              $scope.addReviewerCommentErrors = [];
              $scope.addReviewerCommentErrors.push(error);
            };
          };

          $scope.resetReviewerComment = function() {
            $scope.comments = '';
            $scope.addReviewerCommentErrors = [];
          };

          init();
        }
      };
    }
  ]);
})();
