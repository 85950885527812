angular
  .module('CfcCharity.federationlisting', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('federationlisting', {
        url: '/federationlisting',
        views: {
          main: {
            template: require('../views/private/charity/federationListingPackage.html'),
            controller: 'FederationListingCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Listing Package',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          charityDashboardCfcCode: null,
          pledgeYear: null
        },
        resolve: {
          selectedCharityData: function($http, $stateParams) {
            if ($stateParams.charityDashboardCfcCode != null) {
              var data = {};
              if (
                $stateParams.charityDashboardCfcCode &&
                $stateParams.pledgeYear
              ) {
                data.charityDashboardCfcCode =
                  $stateParams.charityDashboardCfcCode;
                data.pledgeYear = $stateParams.pledgeYear;
              }
              return data;
            }
          }
        }
      });
      $stateProvider.state('federationmemberlistings', {
        url: '/federationmemberlistings',
        views: {
          main: {
            template: require('../views/private/charity/memberCharityListings.html'),
            controller: 'FederationMemberListingCtrl'
          }
        },
        data: {
          breadcrumbs: [
            {
              crumbName: '#%federationCfc% Federation Listing Package',
              crumbRoute: 'federationlisting',
              crumbParams:
                '{charityDashboardCfcCode: %federationCfc%, pledgeYear: %pledgeYear%}'
            }
          ],
          pageTitle: 'Federation Member Listings',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user]
        },
        params: {
          charityDashboardCfcCode: null,
          federationCfc: null,
          pledgeYear: null,
          appType: null
        },
        resolve: {
          selectedCharityData: function($http, $stateParams) {
            if ($stateParams.charityDashboardCfcCode != null) {
              var data = {};
              if ($stateParams.pledgeYear != null) {
                data.charityDashboardCfcCode =
                  $stateParams.charityDashboardCfcCode;
                data.pledgeYear = $stateParams.pledgeYear;
              }
              return data;
            }
          },
          tokenData: function(
            $stateParams,
            BreadcrumbTokenDataService,
            $http,
            CubeSvc
          ) {
            var isFed = $stateParams.appType === 'FEDERATION';
            BreadcrumbTokenDataService.setIsFederation(isFed);
            if (isFed) {
              return CubeSvc.get(
                '/CfcCharity/api/private/federationapplication/' +
                  $stateParams.pledgeYear +
                  '/' +
                  $stateParams.charityDashboardCfcCode
              ).then(function(res) {
                var appId = res.data.value.federationId;
                if (appId !== undefined) {
                  return BreadcrumbTokenDataService.setFederationApplicationReviewTokenData(
                    appId
                  );
                }
              });
            } else {
              return $http
                .get(
                  '/CfcCharity/api/private/application/details/' +
                    $stateParams.pledgeYear +
                    '/' +
                    $stateParams.charityDashboardCfcCode
                )
                .then(function(res) {
                  var appId = res.data.id;
                  if (appId !== undefined) {
                    return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                      appId
                    );
                  }
                });
            }
          }
        }
      });
    }
  ])
  .service('FederationListingDashboardService', [
    '$http',
    'CubeSvc',
    function($http, CubeSvc) {
      this.loadFederationListingPageInfo = function(pledgeYear, cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/federationListingPage/' +
            pledgeYear +
            '/' +
            cfcCode
        );
      };
      this.loadMemberCharityListingPageInfo = function(pledgeYear, cfcCode) {
        return CubeSvc.get(
          '/CfcCharity/api/private/charity/memberCharityListingPage/' +
            pledgeYear +
            '/' +
            cfcCode
        );
      };
      this.loadZoneMemberCharityListingInfoInit = function(
        pledgeYear,
        federationId,
        zoneId
      ) {
        return CubeSvc.get(
          '/CfcCharity/api/private/charity/zoneMemberCharityListingsInit/' +
            pledgeYear +
            '/' +
            federationId +
            '/' +
            zoneId
        );
      };
      this.searchZoneMemberCharityListingInfo = function(
        pledgeYear,
        federationId,
        zoneId,
        searchStr
      ) {
        return CubeSvc.get(
          '/CfcCharity/api/private/charity/zoneMemberCharityListings/' +
            pledgeYear +
            '/' +
            federationId +
            '/' +
            zoneId +
            '/' +
            searchStr
        );
      };
      this.updatePrintOrder = function(
        pledgeYear,
        federationId,
        zoneId,
        printOrderObj
      ) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/updateZoneMemberPrintOrder/' +
            pledgeYear +
            '/' +
            federationId +
            '/' +
            zoneId,
          printOrderObj
        );
      };
      this.resetPrintOrder = function(pledgeYear, federationId, zoneId) {
        return $http.post(
          '/CfcCharity/api/private/charity/resetZoneMemberPrintOrder/' +
            pledgeYear +
            '/' +
            federationId +
            '/' +
            zoneId
        );
      };
      this.revertPrintOrder = function(pledgeYear, federationId, zoneId) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/revertZoneMemberPrintOrder/' +
            pledgeYear +
            '/' +
            federationId +
            '/' +
            zoneId
        );
      };

      //get the valid listing fee for members
      this.getFedMemberValidListingFee = function(pledgeYear, cfcCode) {
        var url =
          '/CfcCharity/api/private/charity/federationListingPackageFeeValid/' +
          pledgeYear +
          '/' +
          cfcCode;
        return $http.get(url);
      };
    }
  ])
  .controller('FederationListingCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    'CharityDashboardService',
    'FederationListingDashboardService',
    'CharityService',
    'Session',
    'blockUI',
    'ListingOverrideAfterCloseService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      CharityDashboardService,
      FederationListingDashboardService,
      CharityService,
      session,
      blockUI,
      ListingOverrideAfterCloseService
    ) {
      $scope.federationListingPkgPageInfo = null;
      $scope.allowEditListingInfo = false;
      $scope.isCharityUser = false;
      var user = $scope.getCurrentUser();
      $scope.userId = user.id;
      $scope.disablePayPackageBtn = true;
      $scope.showInvalidListingFeeDiv = false;
      $scope.showInvalidMemberAppDiv = false;
      $scope.disablePayBtn = true;

      var init = function() {
        if ($state.params.charityDashboardCfcCode && $state.params.pledgeYear) {
          $scope.charityDashboardCfcCode =
            $state.params.charityDashboardCfcCode;
          $scope.pledgeYear = $state.params.pledgeYear;
          BrowserEventService.setUserId(user.id);
          BrowserEventService.setFedCfcCode($scope.charityDashboardCfcCode);
          BrowserEventService.setPledgeYear($scope.pledgeYear);

          CharityService.getCharityUserRole(
            $scope.charityDashboardCfcCode
          ).then(function(res) {
            var result = res.data;
            if (result !== null && result !== '') {
              if (
                result.userRole === 'CHARITY_OWNER' ||
                result.userRole === 'CHARITY_EDITOR'
              ) {
                $scope.allowEditListingInfo = true;
                $scope.isCharityUser = true;
                $scope.disablePayPackageBtn = false;
              }
            }
          });
          if (
            $scope.isSecondaryRoleCustomerServiceManager() ||
            $scope.isSecondaryRoleCustomerServiceTier2Finance() ||
            $scope.isSecondaryRoleGbFinanceAdmin() ||
            $scope.isSecondaryRoleOPMAuditor()
          ) {
            $scope.allowEditListingInfo = true;
          }
          //check the listing fee value
          FederationListingDashboardService.loadFederationListingPageInfo(
            $scope.pledgeYear,
            $scope.charityDashboardCfcCode
          )
            .then(function(res) {
              var result = res.data;
              if (result !== null && result !== '') {
                $scope.federationListingPkgPageInfo = result;
                FederationListingDashboardService.getFedMemberValidListingFee(
                  $scope.pledgeYear,
                  $scope.charityDashboardCfcCode
                ).then(function(res) {
                  var result = res.data;
                  if (result !== null && result !== '') {
                    $scope.invalidFederationListing = null;

                    $scope.fedMemberValidListingFee =
                      result.fedMemberValidListingFee;

                    $scope.invalidMemberListings = result.invalidListings.filter(
                      function(item) {
                        return item.applicationType.toUpperCase() === 'MEMBER';
                      }
                    );

                    $scope.invalidFederationListing = result.invalidListings.filter(
                      function(item) {
                        return (
                          item.applicationType.toUpperCase() === 'FEDERATION'
                        );
                      }
                    );
                  }
                });
              }
            })
            .then(function() {
              CharityDashboardService.getCharityListingObject(
                $scope.pledgeYear,
                $scope.charityDashboardCfcCode
              ).then(function(res) {
                var result = res.data.value;
                if (result) {
                  loadListingEditableInfo(result.campaignId, result.cfcCode);
                }
              });
            });
        }
      };

      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
      $scope.secondaryRoleList = secondaryRoleList;

      if (
        secondaryRoleList.indexOf('GB_FINANCE_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('GB_FINANCE') >= 0 ||
        secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
        secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >= 0 ||
        secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 ||
        secondaryRoleList.indexOf('FEDERATION_EDITOR') >= 0 ||
        secondaryRoleList.indexOf('FEDERATION_OWNER') >= 0 ||
        secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
        secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
        secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_1') >= 0
      ) {
        $scope.disablePayPackageBtn = false;
      }

      var loadListingEditableInfo = function(campaignId, cfcCode) {
        FederationListingDashboardService.loadFederationListingPageInfo(
          $scope.pledgeYear,
          $scope.charityDashboardCfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.federationListingPkgPageInfo = result;
            ListingOverrideAfterCloseService.isListingAvailableWithOverride(
              campaignId,
              cfcCode
            ).then(function(response) {
              $scope.isListingEditable = response.data;
              //set the memapp divif the members r not approved
              if ($scope.federationListingPkgPageInfo != null) {
                if (
                  ($scope.federationListingPkgPageInfo.listingPeriodEnabled ||
                    $scope.isListingEditable) &&
                  (!$scope.federationListingPkgPageInfo.enablePayPackageFee ||
                    $scope.disablePayPackageBtn) &&
                  !$scope.federationListingPkgPageInfo.packageFeePaid &&
                  $scope.federationListingPkgPageInfo.noOfMembers > 0
                ) {
                  $scope.showInvalidMemberAppDiv = true;
                }
                if (
                  ($scope.federationListingPkgPageInfo.listingPeriodEnabled ||
                    $scope.isListingEditable) &&
                  !$scope.fedMemberValidListingFee
                ) {
                  $scope.showInvalidListingFeeDiv = true;
                }
              }
              if (
                !$scope.federationListingPkgPageInfo.enablePayPackageFee ||
                $scope.federationListingPkgPageInfo.packageFeePaid ||
                $scope.disablePayPackageBtn ||
                !$scope.isListingEditable ||
                !$scope.fedMemberValidListingFee ||
                $scope.showInvalidMemberAppDiv ||
                $scope.showInvalidListingFeeDiv
              ) {
                $scope.disablePayBtn = true;
              } else {
                $scope.disablePayBtn = false;
              }
            });
          }
        });
      };

      $scope.goToMemberCharities = function() {
        $state.go('federationmemberlistings', {
          charityDashboardCfcCode: $scope.charityDashboardCfcCode,
          pledgeYear: $scope.pledgeYear,
          appType: 'FEDERATION'
        });
      };

      $scope.goToCharityDashboard = function() {
        $state.go('charitydashboard', {
          charityDashboardCfcCode: $scope.charityDashboardCfcCode
        });
      };

      $scope.goToCharityListing = function(pledgeYear) {
        if (pledgeYear && pledgeYear != null && pledgeYear !== '') {
          BrowserEventService.setAppType('Federation');
          $state.go('charitylisting', {
            charityDashboardCfcCode: $scope.charityDashboardCfcCode,
            pledgeYear: pledgeYear,
            appType: 'FEDERATION'
          });
        }
      };
      $scope.goToFederationListingPaymentInfo = function() {
        $state.go('federationlistingfee', {
          cfcCode: $scope.charityDashboardCfcCode,
          pledgeYear: $scope.pledgeYear
        });
      };
      init();
    }
  ])
  .controller('FederationMemberListingCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'BrowserEventService',
    'CharityDashboardService',
    'FederationListingDashboardService',
    'CharityService',
    'Session',
    'blockUI',
    '$anchorScroll',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      BrowserEventService,
      CharityDashboardService,
      FederationListingDashboardService,
      CharityService,
      session,
      blockUI,
      $anchorScroll
    ) {
      $scope.memberCharityListingPageInfo = null;
      $scope.zoneMemberCharityListingsInfo = null;
      $scope.showMemberCharityInfo = false;
      $scope.sortByCharity = true;
      $scope.selectedZoneName = '';
      $scope.selectedZoneId = '';
      $scope.selectedFederationId = '';
      $scope.matchedStatus = undefined;
      $scope.listingPeriodOpen = false;

      var init = function() {
        var user = $scope.getCurrentUser();
        $scope.charityDashboardCfcCode = $state.params.charityDashboardCfcCode;
        $scope.federationCfc = $state.params.federationCfc;
        if ($scope.federationCfc == null) {
          $scope.federationCfc = $scope.charityDashboardCfcCode;
        }
        $scope.pledgeYear = $state.params.pledgeYear;
        if ($scope.pledgeYear && $scope.pledgeYear !== '') {
          BrowserEventService.setUserId(user.id);
          BrowserEventService.setFedCfcCode($scope.federationCfc);
          BrowserEventService.setPledgeYear($scope.pledgeYear);
        }
        if ($scope.pledgeYear && $scope.federationCfc) {
          FederationListingDashboardService.loadMemberCharityListingPageInfo(
            $scope.pledgeYear,
            $scope.federationCfc
          ).then(function(res) {
            var result = res.data.value;
            if (result !== null && result !== '') {
              $scope.memberCharityListingPageInfo = result;
              $scope.listingPeriodOpen = result.listingActive;
            }
          });
        }
      };
      var setZoneMemberCharityObject = function(result) {
        $scope.zoneMemberCharityListingsInfo = result;
        if (
          $scope.zoneMemberCharityListingsInfo.memberApplications &&
          $scope.zoneMemberCharityListingsInfo.memberApplications.length > 0
        ) {
          for (
            i = 0;
            i < $scope.zoneMemberCharityListingsInfo.memberApplications.length;
            i++
          ) {
            if (
              $scope.zoneMemberCharityListingsInfo.memberApplications[i]
                .printOrder.printOrder < 10000
            ) {
              $scope.sortByCharity = false;
              break;
            }
            $scope.sortByCharity = true;
          }
        }
      };
      $scope.confirmRevert = function() {
        FederationListingDashboardService.revertPrintOrder(
          $scope.pledgeYear,
          $scope.selectedFederationId,
          $scope.selectedZoneId
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            setZoneMemberCharityObject(result);
          }
        });
      };
      $scope.confirmReset = function() {
        FederationListingDashboardService.resetPrintOrder(
          $scope.pledgeYear,
          $scope.selectedFederationId,
          $scope.selectedZoneId
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            setZoneMemberCharityObject(result);
          }
        });
      };
      $scope.populateLearnMoreModal = function() {
        $('#learnMoreModal').modal();
      };
      $scope.showZoneMemberCharities = function(
        federationId,
        zoneId,
        zoneName
      ) {
        $scope.showMemberCharityInfo = true;
        $scope.selectedZoneName = zoneName;
        $scope.selectedZoneId = zoneId;
        $scope.selectedFederationId = federationId;
        $scope.matchedStatus = undefined;
        FederationListingDashboardService.loadZoneMemberCharityListingInfoInit(
          $scope.pledgeYear,
          federationId,
          zoneId
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            setZoneMemberCharityObject(result);
            $location.hash('memberCharityInfo');
            $anchorScroll();
          }
        });
      };
      $scope.searchZoneMemberCharities = function(searchStr) {
        $scope.showMemberCharityInfo = true;
        $scope.matchedStatus = undefined;
        FederationListingDashboardService.searchZoneMemberCharityListingInfo(
          $scope.pledgeYear,
          $scope.selectedFederationId,
          $scope.selectedZoneId,
          searchStr
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            setZoneMemberCharityObject(result);
          }
        });
      };
      $scope.goToFederationListingPackage = function() {
        $state.go('federationlisting', {
          charityDashboardCfcCode: $scope.charityDashboardCfcCode,
          pledgeYear: $scope.pledgeYear
        });
      };
      $scope.goToCharityListing = function(
        pledgeYear,
        charityDashboardCfcCode,
        appType
      ) {
        if (pledgeYear && pledgeYear != null && pledgeYear !== '') {
          BrowserEventService.setAppType('Member');
          $state.go('charitylisting', {
            charityDashboardCfcCode: charityDashboardCfcCode,
            pledgeYear: pledgeYear,
            userRole: 'FEDERATION_OWNER',
            appType: appType
          });
        }
      };
      $scope.updatePrintOrder = function(federationId, zoneId, printOrderObj) {
        FederationListingDashboardService.updatePrintOrder(
          $scope.pledgeYear,
          federationId,
          zoneId,
          printOrderObj
        ).then(function(res) {
          var result = res.data.value;
          if (result !== null && result !== '') {
            setZoneMemberCharityObject(result);
          }
        });
      };
      init();
    }
  ]);
