angular
  .module('CfcCharity.zonecompletenessreview', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider.state('zonecompletenessreview', {
        url: '/zonecompletenessreview',
        views: {
          main: {
            template: require('../views/private/zoneadmin/reviewCompleteness.html'),
            controller: 'ZoneCompletenessReviewCtrl'
          }
        },
        data: {
          pageTitle: 'Completeness Review',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.system_user],
          authorizedSecondaryRoles: [
            SYSTEM_USER_SECONDARY_ROLES.gb_admin,
            SYSTEM_USER_SECONDARY_ROLES.zone_manager,
            SYSTEM_USER_SECONDARY_ROLES.opm_admin,
            SYSTEM_USER_SECONDARY_ROLES.opm_staff,
            SYSTEM_USER_SECONDARY_ROLES.opm_auditor,
            SYSTEM_USER_SECONDARY_ROLES.reviewer
          ]
        },
        params: {
          zoneId: null,
          applicationId: null
        },
        resolve: {
          r: function(
            $http,
            $stateParams,
            BreadcrumbTokenDataService,
            ApplicationDashboardService,
            AdminReviewSectionStepDeterminationSessionService,
            reviewLevelConstants
          ) {
            AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
              reviewLevelConstants.Completeness
            );
            return {};
          }
        }
      });
    }
  ])
  .service('ZoneCompletenessReviewService', [
    '$http',
    function($http) {
      this.getZoneCompletenessReviewDetails = function(zoneId) {
        var url = '/CfcCharity/api/private/zone/review/' + zoneId;
        return $http.get(url);
      };

      this.retrieveReviewGroups = function() {
        var url = '/CfcCharity/api/private/admin/review/groups';
        return $http.get(url);
      };

      this.retrieveReviewers = function(
        federationApplicationId,
        zoneIdForReview
      ) {
        var retrieveReviewersURL =
          '/CfcCharity/api/private/admin/federation/review/reviewers/';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;

        return $http.post(
          retrieveReviewersURL,
          federationApplicationReviewRequest
        );
      };

      this.retrieveActiveZones = function() {
        var url = '/CfcCharity/api/private/admin/zones/active';
        return $http.get(url);
      };

      this.updateFederationRequestForInformation = function(
        federationApplicationId,
        federationRequestForInformationData
      ) {
        var federationApplicationReviewUpdateRequestForInformationURI =
          '/CfcCharity/api/private/federation/requestforinformation/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.requestedInformationDate =
          federationRequestForInformationData.dateOfRequest;
        federationApplicationReviewRequest.federationApplicationReviewBean.requestedInformationBy =
          federationRequestForInformationData.requestedBy;
        return $http.post(
          federationApplicationReviewUpdateRequestForInformationURI,
          federationApplicationReviewRequest
        );
      };
      this.updateFederationAFR = function(
        federationApplicationId,
        fundRaisingRate
      ) {
        var federationApplicationReviewUpdateAFRURI =
          '/CfcCharity/api/private/federation/afr/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.fundRaisingRate = fundRaisingRate;
        return $http.post(
          federationApplicationReviewUpdateAFRURI,
          federationApplicationReviewRequest
        );
      };
      this.updateFederationApplicationReviewStatus = function(
        federationApplicationId,
        applicationReviewStatus
      ) {
        var federationApplicationReviewUpdateApplicationReviewStatusURI =
          '/CfcCharity/api/private/federation/applicationreviewstatus/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.applicationReviewStatus = applicationReviewStatus;
        return $http.post(
          federationApplicationReviewUpdateApplicationReviewStatusURI,
          federationApplicationReviewRequest
        );
      };

      this.assignReviewer = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel,
        reviewerUserId
      ) {
        var federationApplicationReviewerAssignReviewerURI =
          '/CfcCharity/api/private/federation/reviewer/assign';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewLevel = reviewLevel;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewerUserId = reviewerUserId;
        return $http.post(
          federationApplicationReviewerAssignReviewerURI,
          federationApplicationReviewRequest
        );
      };

      this.addReviewerComment = function(
        federationApplicationId,
        zoneIdForReview,
        reviewerComment
      ) {
        var federationApplicationReviewerAddReviewerCommentURI =
          '/CfcCharity/api/private/federation/review/reviewercomment/add';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewerComment = reviewerComment;
        return $http.post(
          federationApplicationReviewerAddReviewerCommentURI,
          federationApplicationReviewRequest
        );
      }; // end addReviewerComment comment

      this.updateDetermination = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel,
        determination
      ) {
        var federationApplicationReviewUpdateDeterminationURI =
          '/CfcCharity/api/private/federation/determination/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewLevel = reviewLevel;
        federationApplicationReviewRequest.federationApplicationReviewBean.determination = determination;
        return $http.post(
          federationApplicationReviewUpdateDeterminationURI,
          federationApplicationReviewRequest
        );
      };

      this.updateZoneForReview = function(
        federationApplicationId,
        zoneIdForReview
      ) {
        var federationApplicationReviewUpdateZoneForReviewURI =
          '/CfcCharity/api/private/federation/zoneforreview/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForReview = zoneIdForReview;
        return $http.post(
          federationApplicationReviewUpdateZoneForReviewURI,
          federationApplicationReviewRequest
        );
      };

      this.updateZoneForListing = function(
        federationApplicationId,
        zoneIdForListing
      ) {
        var federationApplicationReviewUpdateZoneForListingURI =
          '/CfcCharity/api/private/federation/zoneforlisting/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.zoneIdForListing = zoneIdForListing;
        return $http.post(
          federationApplicationReviewUpdateZoneForListingURI,
          federationApplicationReviewRequest
        );
      };

      this.updateReviewGroup = function(
        federationApplicationId,
        reviewGroupId
      ) {
        var federationApplicationReviewUpdateReviewGroupURI =
          '/CfcCharity/api/private/federation/reviewgroup/update';
        var federationApplicationReviewRequest = {};
        federationApplicationReviewRequest.federationApplicationReviewBean = {};
        federationApplicationReviewRequest.federationApplicationReviewBean.federationId = federationApplicationId;
        federationApplicationReviewRequest.federationApplicationReviewBean.reviewGroupId = reviewGroupId;

        return $http.post(
          federationApplicationReviewUpdateReviewGroupURI,
          federationApplicationReviewRequest
        );
      };
    }
  ])
  .controller('ZoneCompletenessReviewCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'ZoneCompletenessReviewService',
    'BrowserEventService',
    'AdminReviewSectionStepDeterminationSessionService',
    'reviewLevelConstants',
    '$filter',
    function(
      $log,
      $state,
      $scope,
      $location,
      $rootScope,
      ZoneCompletenessReviewService,
      BrowserEventService,
      AdminReviewSectionStepDeterminationSessionService,
      reviewLevelConstants,
      $filter
    ) {
      AdminReviewSectionStepDeterminationSessionService.setCurrentReviewLevel(
        reviewLevelConstants.Completeness
      );
      $scope.setModuleName('CfcCharity.zonecompletenessreview');
      var zoneId = $state.params.zoneId;
      var applicationId = $state.params.applicationId;
      $log.info('zone Id =====>: ' + zoneId);
      $log.info('application Id =====>: ' + applicationId);

      if (zoneId) {
        BrowserEventService.setZoneCompletenessReviewZoneId(zoneId);
      }
      var error = '';
      if (!zoneId) {
        error =
          'Cannot retrieve the zone completeness review as the zone id is : ' +
          zoneId;
        $scope.showZoneCompletenessReviewErrorMessage(error);
      }
      $scope.zoneCompletenessReviewData = {};
      ZoneCompletenessReviewService.getZoneCompletenessReviewDetails(zoneId)
        .then(function(res) {
          var data = res.data;
          $log.info('Zone review success ' + JSON.stringify(data));
          var zoneCompletenesReviewResponse = data;
          if (
            zoneCompletenessReviewResponse &&
            (zoneCompletenessReviewResponse.error ||
              zoneCompletenesReviewResponse.statusCode === 'ERROR')
          ) {
            $scope.showCompletenessReviewErrorMessage(
              zoneCompletenesReviewResponse.errorResponse.errorCode +
                ' : ' +
                zoneCompletenessReviewResponse.errorResponse.errorMessage +
                '. Please try later!'
            );
          } else if (
            zoneCompletenessReviewResponse &&
            zoneCompletenessReviewResponse.statusCode === 'SUCCESS'
          ) {
            $scope.zoneCompletenessReviewData =
              zoneCompletenessReviewResponse.zoneCompletenessReviewBean;
          }
        })
        .catch(function(err) {
          error =
            'Error retrieving the zone completeness review for the zone id : ' +
            zoneId;
          $scope.showCompletenessReviewErrorMessage(error);
          $log.error(error + ' ' + JSON.stringify(error));
        });

      $scope.federationApplicationReviewMemberApplicationBeanListLimit = 10;
      $scope.loadMoreFederationApplicationReviewMemberApplications = function() {
        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData
            .federationApplicationReviewMemberApplicationBeanList &&
          $scope.federationApplicationReviewMemberApplicationBeanListLimit <
            $scope.federationApplicationReviewData
              .federationApplicationReviewMemberApplicationBeanList.length
        ) {
          $scope.federationApplicationReviewMemberApplicationBeanListLimit += 10;
          $log.info(
            'Increase federationApplicationReviewMemberApplicationBeanListLimit Limit',
            $scope.federationApplicationReviewMemberApplicationBeanListLimit
          );
        }
      };

      $scope.federationApplicationReviewCommentBeanListLimit = 10;
      $scope.loadMoreFederationApplicationReviewComments = function() {
        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData
            .federationApplicationReviewCommentBeanList &&
          $scope.federationApplicationReviewCommentBeanListLimit <
            $scope.federationApplicationReviewData
              .federationApplicationReviewCommentBeanList.length
        ) {
          $scope.federationApplicationReviewCommentBeanListLimit += 10;
          $log.info(
            'Increase federationApplicationReviewMemberApplicationBeanListLimit Limit',
            $scope.federationApplicationReviewCommentBeanListLimit
          );
        }
      };
      $scope.resetFederationApplicationRequestForInformation = function() {
        $scope.updateRequestForInformationErrors = [];
        $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewDateOfRequest = $filter(
          'date'
        )(
          $scope.federationApplicationReviewData.requestedInformationDate,
          'MM/dd/yyyy'
        );
        $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewRequestedBy =
          $scope.federationApplicationReviewData.requestedInformationBy;
      };
      $scope.updateFederationRequestForInformation = function() {
        $scope.updateRequestForInformationErrors = [];
        if (!$scope.federationApplicationReviewRequestForInformation) {
          $scope.updateRequestForInformationErrors.push(
            'Date of Request is required.'
          );
          $scope.updateRequestForInformationErrors.push(
            'Requested By is required.'
          );
        } else if (
          !$scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest
        ) {
          $scope.updateRequestForInformationErrors.push(
            'Date of Request is required.'
          );
        } else if (
          !$scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy
        ) {
          $scope.updateRequestForInformationErrors.push(
            'Requested By is required.'
          );
        } else if (
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest &&
          $scope.federationApplicationReviewData.requestedInformationDate &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy &&
          $scope.federationApplicationReviewData.requestedInformationBy &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest ===
            $scope.federationApplicationReviewData.requestedInformationDate &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy ===
            $scope.federationApplicationReviewData.requestedInformationBy
        ) {
          $scope.updateRequestForInformationErrors.push(
            "The entered 'Date of Request' and 'Requested By' rate is same as the existing 'Request For Information'."
          );
        } else if (
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewDateOfRequest &&
          $scope.federationApplicationReviewRequestForInformation
            .federationApplicationReviewRequestedBy
        ) {
          $log.info(
            'New RequestForInformation date of request = ' +
              $scope.federationApplicationReviewRequestForInformation
                .federationApplicationReviewDateOfRequest
          );
          $log.info(
            'New RequestForInformation requested by = ' +
              $scope.federationApplicationReviewRequestForInformation
                .federationApplicationReviewRequestedBy
          );
          var dateOfRequest = new Date(
            $scope.federationApplicationReviewRequestForInformation.federationApplicationReviewDateOfRequest
          );
          var requestedBy =
            $scope.federationApplicationReviewRequestForInformation
              .federationApplicationReviewRequestedBy;
          federationRequestForInformationData = {
            dateOfRequest: FederationApplicationService.toUTCDate(
              dateOfRequest
            ),
            requestedBy: requestedBy
          };
          FederationApplicationReviewService.updateFederationRequestForInformation(
            federationApplicationId,
            federationRequestForInformationData
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Application Review RequestForInformation update success ' +
                  JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                $scope.federationApplicationReviewData &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $scope.updateRequestForInformationErrors = [];
                $scope.federationApplicationReviewData.requestedInformationDate = $filter(
                  'date'
                )(
                  federationApplicationReviewResponse
                    .federationApplicationReviewBean.requestedInformationDate,
                  'MM/dd/yyyy'
                );
                $scope.federationApplicationReviewData.requestedInformationBy =
                  federationApplicationReviewResponse.federationApplicationReviewBean.requestedInformationBy;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $('#requestForInformation').modal('toggle'); // $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                "Error updating the Federation Application 'Request for Information' for the federation application id : " +
                federationApplicationId;
              $scope.showFederationApplicationReviewErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        } /* if ($scope.updateRequestForInformationErrors.length > 0) {
        $('#requestForInformation').modal();
      }*/
      };
      $scope.resetAFR = function() {
        $scope.federationApplicationReviewAFR.afr =
          $scope.federationApplicationReviewData.fundRaisingRate;
        $scope.updateAFRErrors = [];
      };
      $scope.updateAFR = function() {
        $scope.updateAFRErrors = [];
        if (
          !$scope.federationApplicationReviewAFR ||
          !$scope.federationApplicationReviewAFR.afr
        ) {
          $scope.updateAFRErrors.push('Fundraising Rate is required.');
        } else if (
          $scope.federationApplicationReviewAFR &&
          $scope.federationApplicationReviewAFR.afr &&
          $scope.federationApplicationReviewAFR.afr ===
            $scope.federationApplicationReviewData.fundRaisingRate
        ) {
          $scope.updateAFRErrors.push(
            'The entered fundraising rate is same as the current fundraising rate.'
          );
        } else if ($scope.federationApplicationReviewAFR.afr) {
          $log.info('New AFR = ' + $scope.federationApplicationReviewAFR.afr);
          var newFundRaisingRate = $scope.federationApplicationReviewAFR.afr;
          FederationApplicationReviewService.updateFederationAFR(
            federationApplicationId,
            newFundRaisingRate
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Review AFR update success ' + JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                $scope.federationApplicationReviewData &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $scope.updateAFRErrors = [];
                $scope.federationApplicationReviewData.fundRaisingRate =
                  federationApplicationReviewResponse.federationApplicationReviewBean.fundRaisingRate;
                $('#updateAFR').modal('toggle');
              }
            })
            .catch(function(err) {
              var error =
                "Error updating the Federation Application 'Fund Raising Rate' for the federation application id : " +
                federationApplicationId;
              $scope.showFederationApplicationReviewErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        }
      };
      $scope.resetFederationApplicationReviewStatus = function() {
        $scope.federationApplicationReviewStatus =
          $scope.federationApplicationReviewData.applicationReviewStatus;
        $scope.updateFederationApplicationReviewStatusErrors = [];
      };
      $scope.updateFederationApplicationReviewStatus = function() {
        $scope.updateFederationApplicationReviewStatusErrors = [];
        if (!$scope.federationApplicationReviewStatus) {
          $scope.updateFederationApplicationReviewStatusErrors.push(
            'Please select the application status.'
          );
        } else if (
          $scope.federationApplicationReviewStatus &&
          $scope.federationApplicationReviewStatus === 'In Review'
        ) {
          $scope.updateFederationApplicationReviewStatusErrors.push(
            'Please select the application status.'
          );
        } else if (
          $scope.federationApplicationReviewStatus &&
          $scope.federationApplicationReviewData.applicationReviewStatus &&
          $scope.federationApplicationReviewStatus ===
            $scope.federationApplicationReviewData.applicationReviewStatus
        ) {
          $scope.updateFederationApplicationReviewStatusErrors.push(
            "The selected application status is same as the existing 'Application Status'. Please select the different application status."
          );
        } else if ($scope.federationApplicationReviewStatus) {
          $log.info(
            'New Application Status = ' +
              $scope.federationApplicationReviewStatus
          );
          var newFederationApplicationReviewStatus =
            $scope.federationApplicationReviewStatus;
          FederationApplicationReviewService.updateFederationApplicationReviewStatus(
            federationApplicationId,
            newFederationApplicationReviewStatus
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Review Application status update success ' +
                  JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                $scope.federationApplicationReviewData &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $('#applicationReviewStatus').modal('toggle');
                $scope.federationApplicationReviewData.applicationReviewStatus =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatus;
                $scope.federationApplicationReviewData.applicationReviewStatusBy =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatusBy;
                $scope.federationApplicationReviewData.applicationReviewStatusDate =
                  federationApplicationReviewResponse.federationApplicationReviewBean.applicationReviewStatusDate;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.updateFederationApplicationReviewStatusErrors = []; //  $('#applicationReviewStatus').modal().hide();
                //  $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the Federation Application Review status for the federation application ID : ' +
                federationApplicationId;
              $scope.showFederationApplicationReviewErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        }
      };

      // Start - edit and update zone for review

      $scope.retrieveActiveZonesForReview = function() {
        FederationApplicationReviewService.retrieveActiveZones()
          .then(function(res) {
            var data = res.data;
            $log.info(
              'Federation Review Application retrieve active zones for review ' +
                JSON.stringify(data)
            );
            var federationApplicationReviewResponse = data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $('#editZoneForReview').modal('toggle');
              $scope.setEditZoneForReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $('#editZoneForReview').modal('toggle');
              $scope.federationApplicationReviewData.availableZonesForReview =
                federationApplicationReviewResponse.cfcZoneBeanList;
              $scope.federationApplicationReviewData.selectedZoneForReview = {};
              $scope.federationApplicationReviewData.selectedZoneForReview.zoneId =
                $scope.federationApplicationReviewData.zoneIdForReview;
              $scope.federationApplicationReviewData.selectedZoneForReview.zoneName =
                $scope.federationApplicationReviewData.zoneNameForReview;
              $scope.setEditZoneForReviewErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the zones. Please try later!';
            $scope.setFederationApplicationReviewErrorMessage(error);
            $log.error(error + ' ' + JSON.stringify(err.data));
          });
      }; // end retrieveActiveZones

      $scope.resetEditZoneForReview = function() {
        $scope.federationApplicationReviewData.selectedZoneForReview.zoneId =
          $scope.federationApplicationReviewData.zoneIdForListing;
        $scope.federationApplicationReviewData.selectedZoneForReview.zoneName =
          $scope.federationApplicationReviewData.zoneNameForListing;

        $scope.setEditZoneForReviewErrorMessage('');
      };

      $scope.updateZoneForReview = function() {
        $scope.editZoneForReviewErrors = [];
        $log.info(
          'selected zone id for review : ' +
            $scope.federationApplicationReviewData.selectedZoneForReview.zoneId
        );
        $log.info(
          'selected zone name  for review : ' +
            $scope.federationApplicationReviewData.selectedZoneForReview
              .zoneName
        );
        if (!$scope.federationApplicationReviewData) {
          $scope.editZoneForReviewErrors.push('Please select the zone.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.zoneIdForReview &&
          $scope.federationApplicationReviewData.selectedZoneForReview &&
          $scope.federationApplicationReviewData.zoneIdForReview ===
            $scope.federationApplicationReviewData.selectedZoneForReview.zoneId
        ) {
          $scope.editZoneForReviewErrors.push(
            'The zone selected is same as current zone for review. Please select a different zone.'
          );
        } else if (
          $scope.federationApplicationReviewData.selectedZoneForReview &&
          $scope.federationApplicationReviewData.selectedZoneForReview.zoneId
        ) {
          var newSelectedZoneId =
            $scope.federationApplicationReviewData.selectedZoneForReview.zoneId;
          $log.info('New zone id selected = ' + newSelectedZoneId);
          FederationApplicationReviewService.updateZoneForReview(
            federationApplicationId,
            newSelectedZoneId
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Review Application new zone for review - update success ' +
                  JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setEditZoneForReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.zoneIdForReview =
                  $scope.federationApplicationReviewData.selectedZoneForReview.zoneId;
                $scope.federationApplicationReviewData.zoneNameForReview =
                  $scope.federationApplicationReviewData.selectedZoneForReview.zoneName;

                $('#editZoneForReview')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();

                /* $state.go('zonememberapplications', {'federationApplicationId' : $scope.federationApplicationReviewData.federationId, 'zoneId' : newSelectedZoneId});
                 */
                $state.go('zoneApplications', {
                  zoneId: newSelectedZoneId,
                  pledgeYear: $scope.federationApplicationReviewData.year,
                  zoneUserRole: null
                });
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the new zone for review for the federation application Id : ' +
                federationApplicationId;
              $scope.setEditZoneForReviewErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        } // end else if
      };

      $scope.setEditZoneForReviewErrorMessage = function(error) {
        $scope.editZoneForReviewErrors = [];
        $scope.editZoneForReviewErrors = error;
      };

      // End - edit and update zone for review

      // Start - edit and update zone for listing

      $scope.retrieveActiveZonesForListing = function() {
        FederationApplicationReviewService.retrieveActiveZones()
          .then(function(res) {
            var data = res.data;
            $log.info(
              'Federation Review Application retrieve active zones for listing success ' +
                JSON.stringify(data)
            );
            var federationApplicationReviewResponse = data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $('#editZoneForListing').modal('toggle');
              $scope.setEditZoneForReviewErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $('#editZoneForListing').modal('toggle');
              $scope.federationApplicationReviewData.availableZonesForListing =
                federationApplicationReviewResponse.cfcZoneBeanList;
              $scope.federationApplicationReviewData.selectedZoneForListing = {};
              $scope.federationApplicationReviewData.selectedZoneForListing.zoneId =
                $scope.federationApplicationReviewData.zoneIdForListing;
              $scope.federationApplicationReviewData.selectedZoneForListing.zoneName =
                $scope.federationApplicationReviewData.zoneNameForListing;
              $scope.setEditZoneForReviewErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the zones. Please try later!';
            $scope.setFederationApplicationReviewErrorMessage(error);
            $log.error(error + ' ' + JSON.stringify(err.data));
          });
      }; // end retrieveActiveZones

      $scope.resetEditZoneForListing = function() {
        $scope.federationApplicationReviewData.selectedZoneForListing.zoneId =
          $scope.federationApplicationReviewData.zoneIdForListing;
        $scope.federationApplicationReviewData.selectedZoneForListing.zoneName =
          $scope.federationApplicationReviewData.zoneNameForListing;
        $scope.setEditZoneForListingErrorMessage('');
      };

      $scope.updateZoneForListing = function() {
        $scope.editZoneForListingErrors = [];
        $log.info(
          'selected zone id : ' +
            $scope.federationApplicationReviewData.selectedZoneForListing.zoneId
        );
        $log.info(
          'selected zone name : ' +
            $scope.federationApplicationReviewData.selectedZoneForListing
              .zoneName
        );
        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.selectedZoneForListing &&
          ($scope.federationApplicationReviewData.selectedZoneForListing
            .zoneId === 0 ||
            !$scope.federationApplicationReviewData.selectedZoneForListing
              .zoneName)
        ) {
          $scope.editZoneForListingErrors.push('Please select the zone.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.zoneIdForListing &&
          $scope.federationApplicationReviewData.selectedZoneForListing &&
          $scope.federationApplicationReviewData.zoneIdForListing ===
            $scope.federationApplicationReviewData.selectedZoneForListing.zoneId
        ) {
          $scope.editZoneForListingErrors.push(
            'The zone selected is same as current zone for review. Please select a different zone.'
          );
        } else if (
          $scope.federationApplicationReviewData.selectedZoneForListing &&
          $scope.federationApplicationReviewData.selectedZoneForListing.zoneId
        ) {
          var newSelectedZoneId =
            $scope.federationApplicationReviewData.selectedZoneForListing
              .zoneId;
          $log.info('New zone id selected = ' + newSelectedZoneId);
          FederationApplicationReviewService.updateZoneForListing(
            federationApplicationId,
            newSelectedZoneId
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Review Application new zone for listing - update success ' +
                  JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setEditZoneForListingErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.zoneIdForListing =
                  $scope.federationApplicationReviewData.selectedZoneForListing.zoneId;
                $scope.federationApplicationReviewData.zoneNameForListing =
                  $scope.federationApplicationReviewData.selectedZoneForListing.zoneName;

                $('#editZoneForListing')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();

                /*   $state.go('zonememberapplications', {'federationApplicationId' : $scope.federationApplicationReviewData.federationId, 'zoneId' : newSelectedZoneId});
                 */
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the new zone for listing for the federation application Id : ' +
                federationApplicationId;
              $scope.setEditZoneForReviewErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        } // end else if
      };

      $scope.setEditZoneForListingErrorMessage = function(error) {
        $scope.editZoneForListingErrors = [];
        $scope.editZoneForListingErrors = error;
      };

      // End - edit and update zone for listing

      // Start - edit and update review group

      $scope.retrieveReviewGroups = function() {
        FederationApplicationReviewService.retrieveReviewGroups()
          .then(function(res) {
            var data = res.data;
            $log.info(
              'Federation Review Application retrieve review groups ' +
                JSON.stringify(data)
            );
            var federationApplicationReviewResponse = data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $('#editReviewGroup').modal('toggle');
              $scope.setEditReviewGroupErrorMessage(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $('#editReviewGroup').modal('toggle');
              $scope.federationApplicationReviewData.availableReviewGroups =
                federationApplicationReviewResponse.reviewGroupBeanList;
              $scope.federationApplicationReviewData.selectedReviewGroup = {};
              $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupId =
                $scope.federationApplicationReviewData.reviewGroupId;
              $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupName =
                $scope.federationApplicationReviewData.reviewGroupName;
              $scope.setEditReviewGroupErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the review groups. Please try later!';
            $scope.setEditReviewGroupErrorMessage(error);
            $log.error(error + ' ' + JSON.stringify(err.data));
          });
      }; // end retrieveActiveZonesr

      // start - edit review group
      $scope.resetEditReviewGroup = function() {
        $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupId =
          $scope.federationApplicationReviewData.reviewGroupId;
        $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupName =
          $scope.federationApplicationReviewData.reviewGroupName;
        $scope.setEditReviewGroupErrorMessage('');
      };

      $scope.updateReviewGroup = function() {
        $scope.editReviewGroupErrors = [];
        $log.info(
          'selected review group id : ' +
            $scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupId
        );
        $log.info(
          'selected review group name : ' +
            $scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupName
        );
        if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.selectedReviewGroup &&
          ($scope.federationApplicationReviewData.selectedReviewGroup
            .reviewGroupId === 0 ||
            !$scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupName)
        ) {
          $scope.editReviewGroupErrors.push('Please select the review group.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.reviewGroupId &&
          $scope.federationApplicationReviewData.selectedReviewGroup &&
          $scope.federationApplicationReviewData.reviewGroupId ===
            $scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupId
        ) {
          $scope.editReviewGroupErrors.push(
            'The review group selected is same as current review group for review. Please select a review group.'
          );
        } else if (
          $scope.federationApplicationReviewData.selectedReviewGroup &&
          $scope.federationApplicationReviewData.selectedReviewGroup
            .reviewGroupId
        ) {
          var newReviewGroupId =
            $scope.federationApplicationReviewData.selectedReviewGroup
              .reviewGroupId;
          $log.info('New review group id selected = ' + newReviewGroupId);
          FederationApplicationReviewService.updateReviewGroup(
            federationApplicationId,
            newReviewGroupId
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Review Application new review group - update success ' +
                  JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setEditReviewGroupErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.reviewGroupId =
                  $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupId;
                $scope.federationApplicationReviewData.reviewGroupName =
                  $scope.federationApplicationReviewData.selectedReviewGroup.reviewGroupName;

                $('#editReviewGroup')
                  .modal()
                  .hide();
                $('.modal-backdrop').remove();
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the new review group for the federation application Id : ' +
                federationApplicationId;
              $scope.setEditReviewGroupErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        } // end else if
      };

      $scope.setEditReviewGroupErrorMessage = function(error) {
        $scope.editReviewGroupErrors = [];
        $scope.editReviewGroupErrors = error;
      };

      // end - edit and update review group

      // start - retrieve reviewers list

      $scope.retrieveReviewers = function(
        federationApplicationId,
        zoneIdForReview,
        federationApplicationReviewOverviewByLevelBean
      ) {
        $scope.assignReviewerErrors = [];
        FederationApplicationReviewService.retrieveReviewers(
          federationApplicationId,
          zoneIdForReview
        )
          .then(function(res) {
            var data = res.data;
            $log.info(
              'Federation Review Application retrieve reviewers ' +
                JSON.stringify(data)
            );
            var federationApplicationReviewResponse = data;
            if (
              federationApplicationReviewResponse &&
              (federationApplicationReviewResponse.error ||
                federationApplicationReviewResponse.statusCode === 'ERROR')
            ) {
              $scope.assignReviewerErrors.push(
                federationApplicationReviewResponse.errorResponse.errorCode +
                  ' : ' +
                  federationApplicationReviewResponse.errorResponse
                    .errorMessage +
                  '. Please try later!'
              );
            } else if (
              federationApplicationReviewResponse &&
              federationApplicationReviewResponse.statusCode === 'SUCCESS'
            ) {
              $scope.federationApplicationReviewData.availableReviewers =
                federationApplicationReviewResponse.reviewerUserBeanList;

              var reviewLevel =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewLevel
                  : null;

              $scope.federationApplicationReviewData.reviewLevel = reviewLevel;

              $scope.federationApplicationReviewData.selectedReviewer = {};
              var reviewerUserId =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerUserId
                  : null;

              var reviewerFirstName =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerFirstName
                  : null;
              var reviewerLastName =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerLastName
                  : null;

              var reviewerAssignedDate =
                federationApplicationReviewOverviewByLevelBean !== null
                  ? federationApplicationReviewOverviewByLevelBean.reviewerAssignedDate
                  : null;

              $scope.federationApplicationReviewData.selectedReviewer.reviewerUserId = reviewerUserId;
              $scope.federationApplicationReviewData.reviewerUserId = reviewerUserId;

              var reviewerUserName =
                reviewerFirstName != null ? reviewerFirstName : '';
              reviewerUserName +=
                reviewerLastName != null ? ' ' + reviewerLastName : '';

              $scope.federationApplicationReviewData.selectedReviewer.reviewerUserName = reviewerUserName;
              $scope.federationApplicationReviewData.reviewerUserName = reviewerUserName;

              $scope.federationApplicationReviewData.reviewerAssignedDate = reviewerAssignedDate;

              $scope.setAssignReviewerErrorMessage('');
            }
          })
          .catch(function(err) {
            var error = 'Error retrieving the reviewers. Please try later!';
            $scope.setAssignReviewerErrorMessage(error);
            $log.error(error + ' ' + JSON.stringify(err.data));
          });
      }; // end retrieveReviewers

      // end - retrieve reviewers list

      // start - reset eviewer
      $scope.resetReviewer = function() {
        $scope.federationApplicationReviewData.selectedReviewer.reviewerUserId =
          '';
        $scope.federationApplicationReviewData.selectedReviewer.reviewerUserName =
          '';
        $scope.setAssignReviewerErrorMessage('');
      };
      // end - reset reviewer

      // start - assign reviewer

      $scope.assignReviewer = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel
      ) {
        $scope.assignReviewerErrors = [];
        $log.info(
          'selected reviewer user id : ' +
            $scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserId
        );
        $log.info(
          'selected reviewer user name : ' +
            $scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserName
        );
        if (
          $scope.federationApplicationReviewData.selectedReviewer &&
          (!$scope.federationApplicationReviewData.selectedReviewer
            .reviewerUserId ||
            !$scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserName)
        ) {
          $scope.assignReviewerErrors.push('Please select the reviewer.');
        } else if (
          $scope.federationApplicationReviewData &&
          $scope.federationApplicationReviewData.selectedReviewer &&
          $scope.federationApplicationReviewData.reviewerUserId &&
          $scope.federationApplicationReviewData.selectedReviewer
            .reviewerUserId &&
          $scope.federationApplicationReviewData.reviewerUserId ===
            $scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserId
        ) {
          $scope.assignReviewerErrors.push(
            'The reviewer selected is same as current reviewer for federation application review. Please select a different reviewer.'
          );
        } else if (
          federationApplicationId &&
          zoneIdForReview &&
          reviewLevel &&
          $scope.federationApplicationReviewData.selectedReviewer
        ) {
          FederationApplicationReviewService.assignReviewer(
            federationApplicationId,
            zoneIdForReview,
            reviewLevel,
            $scope.federationApplicationReviewData.selectedReviewer
              .reviewerUserId
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Review Application assign reviewer - success ' +
                  JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.assignReviewerErrors.push(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.federationApplicationReviewOverviewByLevelBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewOverviewByLevelBeanList;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $('#assignReviewer').modal('toggle');
              }
            })
            .catch(function(err) {
              var error =
                'Error assigning the reviewer for the ' +
                reviewLevel +
                ' for the federation application Id : ' +
                federationApplicationId +
                ' and the zone id ' +
                zoneIdForReview;
              $scope.assignReviewerErrors.push(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        } // end else if
      };

      $scope.setAssignReviewerErrorMessage = function(error) {
        //  $scope.assignReviewerErrors = [];
        $scope.assignReviewerErrors = error;
      };

      // end - add reviewer comment

      // start - reset reviewer comment
      $scope.resetReviewerComment = function() {
        $scope.federationApplicationReviewData.reviewerComment = '';
        $scope.setAddReviewerCommentErrorMessage('');
      };

      // end - reset reviewer

      // start - add reviewer comment

      $scope.addReviewerComment = function(
        federationApplicationId,
        zoneIdForReview
      ) {
        $scope.addReviewerCommentErrors = [];
        if (
          !$scope.federationApplicationReviewData.reviewerComment ||
          (($scope.federationApplicationReviewData.reviewerComment &&
            $scope.federationApplicationReviewData.reviewerComment.trim()
              .length < 1) ||
            $scope.federationApplicationReviewData.reviewerComment.length >
              2000)
        ) {
          $scope.addReviewerCommentErrors.push(
            'Please enter a comment between 1 and 2000 characters.'
          );
        } else if (
          $scope.federationApplicationReviewData.reviewerComment &&
          ($scope.federationApplicationReviewData.reviewerComment.length > 0 &&
            $scope.federationApplicationReviewData.reviewerComment.length <=
              2000)
        ) {
          FederationApplicationReviewService.addReviewerComment(
            federationApplicationId,
            zoneIdForReview,
            $scope.federationApplicationReviewData.reviewerComment
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'Federation Review Application add reviewer comment - success ' +
                  JSON.stringify(data)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.setAddReviewerCommentErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS'
              ) {
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.resetReviewerComment();
                $('#comment').modal('toggle');
              }
            })
            .catch(function(err) {
              var error =
                'Error adding the reviewer comment for the federation application Id : ' +
                federationApplicationId +
                ' and the zone id ' +
                zoneIdForReview;
              $scope.setAddReviewerCommentErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        } // end else if
      };

      $scope.setAddReviewerCommentErrorMessage = function(error) {
        $scope.addReviewerCommentErrors = [];
        $scope.addReviewerCommentErrors.push(error);
      };

      // end - add reviewer comment

      // Start - updateDetermination
      $scope.updateDetermination = function(
        federationApplicationId,
        zoneIdForReview,
        reviewLevel,
        determination
      ) {
        $scope.updateDeterminationsErrors = [];
        if (!determination) {
          $scope.updateDeterminationsErrors.push(
            'Please select the determination for the ' + reviewLevel
          );
        } else if (determination) {
          $log.info(
            'New determination for the ' + reviewLevel + ' = ' + determination
          );
          var newFederationApplicationCompletenessReviewStatus =
            $scope.federationCompletenessReviewStatus;
          FederationApplicationReviewService.updateDetermination(
            federationApplicationId,
            zoneIdForReview,
            reviewLevel,
            determination
          )
            .then(function(res) {
              var data = res.data;
              $log.info(
                'update determination for the ' +
                  reviewLevel +
                  ' for the federation application id ' +
                  federationApplicationId +
                  JSON.stringify(res)
              );
              var federationApplicationReviewResponse = data;
              if (
                federationApplicationReviewResponse &&
                (federationApplicationReviewResponse.error ||
                  federationApplicationReviewResponse.statusCode === 'ERROR')
              ) {
                $scope.showFederationApplicationReviewErrorMessage(
                  federationApplicationReviewResponse.errorResponse.errorCode +
                    ' : ' +
                    federationApplicationReviewResponse.errorResponse
                      .errorMessage +
                    '. Please try again later!'
                );
              } else if (
                federationApplicationReviewResponse &&
                federationApplicationReviewResponse.statusCode === 'SUCCESS' &&
                $scope.federationApplicationReviewData &&
                federationApplicationReviewResponse.federationApplicationReviewBean
              ) {
                $scope.federationApplicationReviewData.federationApplicationReviewOverviewByLevelBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewOverviewByLevelBeanList;
                $scope.federationApplicationReviewData.federationApplicationReviewCommentBeanList =
                  federationApplicationReviewResponse.federationApplicationReviewBean.federationApplicationReviewCommentBeanList;
                $scope.federationApplicationReviewError = '';
              }
            })
            .catch(function(err) {
              var error =
                'Error updating the determination for the ' +
                reviewLevel +
                ' for the federation application id ' +
                federationApplicationId;
              $scope.showFederationApplicationReviewErrorMessage(error);
              $log.error(error + ' ' + JSON.stringify(err.data));
            });
        }
        if ($scope.updateDeterminationsErrors.length > 0) {
          $scope.showFederationApplicationReviewErrorMessage(
            $scope.updateDeterminationsErrors.join()
          );
        } else {
        }
      };
      $scope.showFederationApplicationReviewErrorMessage = function(error) {
        $scope.federationApplicationReviewError = error;
      };

      // End - updateDetermination
      $scope.showZoneCompletenessReviewErrorMessage = function(error) {
        $scope.showZoneCompletenessReviewError = error;
      };
    }
  ]);
