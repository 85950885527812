(function() {
  var moduleName = 'CfcCharity.directives.federationCard';
  var module;
  try {
    module = angular.module(moduleName);
  } catch (err) {
    // named module does not exist, so create one
    module = angular.module(moduleName, []);
  }

  module.directive('cfcFederationCard', [
    '$log',
    '$http',
    '$q',
    'CharityService',
    'SYSTEM_USER_SECONDARY_ROLES',
    'CubeSvc',
    function(
      $log,
      $http,
      $q,
      CharityService,
      SYSTEM_USER_SECONDARY_ROLES,
      CubeSvc
    ) {
      return {
        restrict: 'E',
        template: require('./federationCard.html'),
        scope: {
          cfcCode: '@',
          applicationId: '@'
        },
        controller: function($scope) {
          function init() {
            CharityService.getCharityUserRole($scope.cfcCode)
              .then(function(res) {
                return res.data && res.data.userRole
                  ? res.data.userRole.toUpperCase()
                  : null;
              })
              .then(function(role) {
                switch (role) {
                  case 'CHARITY_OWNER':
                  case SYSTEM_USER_SECONDARY_ROLES.zone_manager:
                  case SYSTEM_USER_SECONDARY_ROLES.reviewer:
                  case SYSTEM_USER_SECONDARY_ROLES.opm_admin:
                  case SYSTEM_USER_SECONDARY_ROLES.opm_staff:
                  case SYSTEM_USER_SECONDARY_ROLES.opm_auditor:
                  case SYSTEM_USER_SECONDARY_ROLES.gb_finance:
                  case SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin:
                  case SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1:
                  case SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2:
                  case SYSTEM_USER_SECONDARY_ROLES.customer_service_manager:
                  case 'CHARITY_EDITOR':
                  case SYSTEM_USER_SECONDARY_ROLES.zone_coordinator:
                    return true;
                  default:
                    return false;
                }
              })
              .then(function(isUserAuthorized) {
                if (!isUserAuthorized) {
                  isUserAuthorized = userAuthorization();
                  $scope.userAuthorized = isUserAuthorized;
                }
                if (!isUserAuthorized) {
                  return;
                }

                $scope.userAuthorized = isUserAuthorized;
                if ($scope.applicationId) {
                  getApplicationInformation($scope.applicationId)
                    .then(function(response) {
                      var totalInfo = response.data.value;
                      $scope.applicationInformation = totalInfo.application;
                      $scope.isMember =
                        $scope.applicationInformation.federationCfcCode == null
                          ? false
                          : true;
                      if (
                        $scope.applicationInformation.listingPeriodNotStarted
                      ) {
                        if (
                          $scope.applicationInformation.applicationStatus ===
                          'DENIED'
                        ) {
                          $scope.applicationInformation.applicationStatus =
                            'IN_REVIEW';
                        }
                      }
                      $scope.parentCfcCode =
                        $scope.applicationInformation.parentCfcCode;
                      $scope.pledgeYear =
                        $scope.applicationInformation.pledgeYear;
                      $scope.federationApplicationId =
                        $scope.applicationInformation.federationNumber;
                      $scope.applicationPaymentStatus = $scope
                        .applicationInformation.applicationPaid
                        ? 'Paid'
                        : 'Unpaid';
                      if (
                        $scope.applicationInformation.listingZoneList &&
                        $scope.applicationInformation.listingZoneList.length > 0
                      ) {
                        $scope.listingZone =
                          $scope.applicationInformation.listingZoneList.length >
                          1
                            ? 'Multiple'
                            : $scope.applicationInformation.listingZoneList[0]
                                .zoneCode +
                              ' ' +
                              $scope.applicationInformation.listingZoneList[0]
                                .zoneName;
                      }
                      if ($scope.applicationInformation.federationZone) {
                        $scope.revivewZone =
                          $scope.applicationInformation.federationZone
                            .zoneCode +
                          ' ' +
                          $scope.applicationInformation.federationZone.zoneName;
                      }

                      $scope.listAndZoneInformation = Object.assign(
                        {},
                        totalInfo.charityListingPageInfo,
                        totalInfo.charityListing,
                        totalInfo.reviewCfcZone
                      );

                      $scope.generalInformation = totalInfo.cfcCharityMaster;
                      $scope.generalInformation.contactEmailAddress =
                        $scope.generalInformation.contactEmailAddress == null
                          ? []
                          : $scope.generalInformation.contactEmailAddress.split(
                              ';'
                            );

                      $scope.legalName =
                        totalInfo.legalName.charityIrsName != null
                          ? totalInfo.legalName.charityIrsName
                          : $scope.generalInformation.charityName;
                    })
                    .catch(function(err) {
                      $log.error(
                        err.data || 'Request failed with status ' + err.status,
                        err
                      );
                    });
                } else {
                  getGeneralInformation($scope.cfcCode)
                    .then(function(response) {
                      $scope.generalInformation = response.data;
                      $scope.generalInformation.contactEmailAddress =
                        $scope.generalInformation.contactEmailAddress == null
                          ? []
                          : $scope.generalInformation.contactEmailAddress.split(
                              ';'
                            );

                      if (!$scope.applicationId) {
                        return;
                      }
                    })
                    .catch(function(err) {
                      $log.error(
                        err.data || 'Request failed with status ' + err.status,
                        err
                      );
                    });
                }
              })
              .catch(function(err) {
                $log.error(err);
              });
          }

          function getGeneralInformation(cfcCode) {
            // CCA-3226: disables toaster notification now, to be re-implemented in future sprint
            return CubeSvc.get(
              '/CfcCharity/api/private/charity/findCharityWithCFC',
              { params: { cfcCode: cfcCode } },
              false
            );
          }

          function getApplicationInformation(applicationId) {
            return CubeSvc.get(
              '/CfcCharity/api/private/federation/cardInfo/' + applicationId,
              null,
              false
            );
          }

          function userAuthorization() {
            var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            $scope.userAuthorized = false;
            if (
              !user.secondaryRoleList ||
              !Array.isArray(user.secondaryRoleList) ||
              user.secondaryRoleList.length === 0
            ) {
              return false;
            }

            // Charity Owner / Editor,
            // Federation Owner / Editor of the charity if it is a member federation at any time in the past of that federation,
            // Zone Manager/Reviewer of any Listing or Review Zone that the charity has been a member of in the past,
            // OPM Admin, OPM Staff, or Auditor
            // GB Finance, GB Finance Admin
            // Customer Service 1, 2 or Manager

            if (
              user.secondaryRoleList.indexOf('CHARITY_OWNER') >= 0 ||
              user.secondaryRoleList.indexOf('CHARITY_EDITOR') >= 0 ||
              user.secondaryRoleList.indexOf('FEDERATION_OWNER') >= 0 ||
              user.secondaryRoleList.indexOf('FEDERATION_EDITOR') >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.zone_manager
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.reviewer
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.opm_admin
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.opm_staff
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.opm_auditor
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.gb_finance
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_1
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance
              ) >= 0 ||
              user.secondaryRoleList.indexOf(
                SYSTEM_USER_SECONDARY_ROLES.customer_service_manager
              ) >= 0
            ) {
              $scope.userAuthorized = true;
              return $scope.userAuthorized;
            }

            return false;
          }

          init();
        }
      };
    }
  ]);
})();
